import React from "react";
import {TextField, Typography} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import axios from "axios";
import {addSubscribersXlsError} from "app/crud/subscribers.crud";
import styles from "./index.module.scss";
import IconColorMUI from "app/partials/components/IconColorMUI";
import cx from "classnames";
import InputCheckbox from "app/partials/components/InputCheckbox";
import {downloadFileFromUrl} from "app/utils/helpers";
import {toAbsoluteUrl} from "_metronic";
import {sharePropertyParticulars} from "app/crud/propertyParticulars/offices.crud";
import {shareIndustrialPropertyParticulars} from "app/crud/propertyParticulars/industrial.crud";
import Button from "../../Button";
import Icon from "../../Icon";

class FileUpload extends React.Component {
  static defaultProps = {
    multiple: false,
  };

  constructor(props) {
    super(props);
    this.fileUploadRef = React.createRef();
  }

  handleAddOfficePhoto = () => {
    this.fileUploadRef.current.click();
  };

  handleChangeFiles = (e) => {
    const { target } = e;

    if (target.files) {
      Object.values(target.files).forEach((file) => {
        const reader = new FileReader();
        reader.onload = (readerEvent) => {
          this.props.onAddFile({
            data: readerEvent.target.result,
            type: file.type,
            name: file.name,
          });
        };
        reader.readAsArrayBuffer(file);
      });
    }
  };

  render() {
    return (
      <>
        <input
          ref={this.fileUploadRef}
          type="file"
          id="file"
          accept=".xlsx,.xls"
          style={{ display: "none" }}
          multiple={this.props.multiple}
          onChange={this.handleChangeFiles}
        />
        {this.props.caller(this.handleAddOfficePhoto)}
      </>
    );
  }
}

class Tab1 extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    const teamName = props.team && props.team.name;
    this.state = {
      open: false,
      subscriberEmail: "",
      subscribersList: [],
      selectedSubscribers: [],
      addMessage: false,
      messageContent: props.intl.formatMessage(
        { id: "PROPERTY_PARTICULARS.PHRASE.SHARE_INITIAL_MESSAGE" },
        { teamName: teamName }
      ),
      sharedSuccess: false,
    };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { target } = this.props;
    const sharedData = target === "industrial" ? "industrialShared" : "shared";

    if (
      this.props[sharedData] &&
      !prevProps[sharedData] &&
      prevState.sharedSuccess !== this.props[sharedData]
    ) {
      const teamName = this.props.team && this.props.team.name;
      this.setState(
        {
          sharedSuccess: this.props[sharedData],
        },
        () =>
          setTimeout(() => {
            this.setState({
              subscriberEmail: "",
              subscribersList: [],
              selectedSubscribers: [],
              addMessage: false,
              messageContent: this.props.intl.formatMessage(
                { id: "PROPERTY_PARTICULARS.PHRASE.SHARE_INITIAL_MESSAGE" },
                { teamName: teamName }
              ),
              sharedSuccess: false,
            });
          }, 5000)
      );
    }
  }

  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleChange = (event) => {
    if (event.target.value.length <= 512) {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  };
  handleAddSubscriberEmail = (event) => {
    event.preventDefault();
    const mailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,8})+$/;
    const { subscribersList, subscriberEmail } = this.state;
    if (subscriberEmail.length && subscriberEmail.match(mailFormat)) {
      subscribersList.push(subscriberEmail);
      this.setState({
        subscriberEmail: "",
      });
    }
  };
  handleAddFile = ({ data, type }) => {
    const { addSubscribersXlsError } = this.props;
    const headers = {
      "Content-Type": type,
      "Content-Disposition": `attachment; filename=upload-subscribers`,
    };
    return axios
      .post(`/subscribers-v2/emails_from_xls/`, data, {
        headers,
      })
      .then(({ data }) => {
        if (data && data.emails) {
          this.setState({
            subscribersList: [...this.state.subscribersList, ...data.emails],
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error);
        }
        addSubscribersXlsError();
      });
  };
  handleRemoveFollower = (follower) => {
    this.setState({
      subscribersList: this.state.subscribersList.filter(
        (subscriber) => subscriber !== follower
      ),
    });
  };
  handleCheckboxChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };
  handleSharePP = () => {
    const {
      target,
      shareIndustrialPropertyParticulars,
      sharePropertyParticulars,
      userId,
      team,
    } = this.props;
    const { subscribersList, messageContent, addMessage } = this.state;
    const onShare =
      target === "industrial"
        ? shareIndustrialPropertyParticulars
        : sharePropertyParticulars;
    const data = {
      userPkSharing: userId,
      usersToSendEmail: subscribersList,
      message: addMessage ? messageContent : "",
    };
    const teamID = team && team.id;
    onShare && onShare(data, teamID);
  };

  render() {
    const {
      target,
      team,
      shareLoading,
      industrialShareLoading,
      shared,
      industrialShared,
    } = this.props;
    const sharedData = target === "industrial" ? industrialShared : shared;
    const sharedDataLoading =
      target === "industrial" ? industrialShareLoading : shareLoading;

    if (team !== null) {
      const {
        subscriberEmail,
        subscribersList,
        addMessage,
        messageContent,
        sharedSuccess,
      } = this.state;
      if (sharedData && sharedSuccess) {
        return (
          <div className={styles.addFollowersSharedSuccessWrapper}>
            <span className={styles.sharedSuccessIcon}>
              <IconColorMUI icon="check" color="danger" />
            </span>
            <span className={styles.sharedSuccessMessage}>
              <FormattedMessage
                id="You successfully shared PP Online with provided recipients."
                values={{ strong: <strong>PP Online</strong> }}
              />
            </span>
          </div>
        );
      }
      return (
        <>
          <div className={styles.addFollowerWrapper}>
            <form onSubmit={this.handleAddSubscriberEmail}>
              <TextField
                id="invite_new_subscriber"
                name="subscriberEmail"
                label={
                  <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.ADD_RECIPIENT_ADDRESS_EMAIL" />
                }
                value={subscriberEmail}
                onChange={this.handleChange}
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </form>
            <Button color={"simple"} onClick={this.handleAddSubscriberEmail}>
              <FormattedMessage id="GENERAL.PHRASE.ADD" />
            </Button>
            <FileUpload
              onAddFile={this.handleAddFile}
              caller={(onClick) => (
                <Button
                  color={"success"}
                  textIcon={<Icon icon={"add-clean"}/>}
                  onClick={onClick}
                >
                  <FormattedMessage id="GENERAL.PHRASE.UPLOAD_A_XLS_FILE" />
                </Button>
              )}
            />
          </div>
          <div className={styles.downloadSampleWrapper}>
            <span
              className={styles.btnDownload}
              onClick={() =>
                downloadFileFromUrl(
                  toAbsoluteUrl("media/REDD_Import_Followers_Sample_File.xls")
                )
              }
            >
              <IconColorMUI icon="file_download" />
              <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.DOWNLOAD_SAMPLE_FILE" />
            </span>
          </div>
          <div
            className={cx(
              styles.listFollowers,
              !subscribersList.length && styles.flex
            )}
          >
            {subscribersList && subscribersList.length ? (
              subscribersList.map((subscriber, index) => {
                return (
                  <div key={index} className={styles.follower}>
                    <span
                      className={styles.followerRemove}
                      onClick={() => this.handleRemoveFollower(subscriber)}
                    >
                      <IconColorMUI icon="close" color="danger" />
                    </span>
                    {subscriber}
                  </div>
                );
              })
            ) : (
              <Typography variant="body2">
                <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.ADD_PEOPLE_MESSAGE" />
              </Typography>
            )}
          </div>
          {addMessage && (
            <TextField
              id="messageContent"
              name="messageContent"
              label={
                <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.MESSAGE" />
              }
              value={messageContent}
              onChange={this.handleChange}
              margin="normal"
              variant="outlined"
              fullWidth
              multiline
              rows="4"
            />
          )}
          <div className={styles.shareActions}>
            <InputCheckbox
              label={
                <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.ADD_MESSAGE" />
              }
              name="addMessage"
              value={addMessage}
              onCheckboxChange={this.handleCheckboxChange}
            />
            <Button
              loading={sharedDataLoading}
              inactive={subscribersList && subscribersList.length <= 0}
              onClick={() => this.handleSharePP()}
            >
              <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.SHARE" />
            </Button>
          </div>
        </>
      );
    }
    return true;
  }
}

const mapStateToProps = ({ user, ppOnlineOffices, ppOnlineIndustrial }) => ({
  userId: user.data.pk,
  team: user.data.team,
  shared: ppOnlineOffices.shared,
  shareLoading: ppOnlineOffices.shareLoading,
  industrialShared: ppOnlineIndustrial.shared,
  industrialShareLoading: ppOnlineIndustrial.shareLoading,
});
const mapDispatchToProps = {
  addSubscribersXlsError: () => addSubscribersXlsError(),
  sharePropertyParticulars: (data, teamID) =>
    sharePropertyParticulars(data, teamID),
  shareIndustrialPropertyParticulars: (data, teamID) =>
    shareIndustrialPropertyParticulars(data, teamID),
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Tab1));

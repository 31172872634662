import React from "react";
import MenuSection from "./MenuSection";
import MenuItemSeparator from "./MenuItemSeparator";
import MenuItem from "./MenuItem";
import IsAccountType from "../../../app/utils/IsAccountType";

export default class MenuSubmenu extends React.Component {
  render() {
    const {
      item,
      currentUrl,
      layoutConfig,
      userFollowersCount,
      onMenuItemClick,
      isDummyViewActive
    } = this.props;

    return (
      <ul className="kt-menu__subnav">
        {item.submenu
          .filter((item) => {
            const accountTypePermission = item?.permissions?.accountType;
            return accountTypePermission ? IsAccountType({expectedAccountType: accountTypePermission}) : true;
          })
          .map((child, index) => (
            <React.Fragment key={index}>
              {child.section && (
                <MenuSection
                  item={child}
                  parentItem={item}
                  currentUrl={currentUrl}
                />
              )}

              {child.separator && (
                <MenuItemSeparator
                  item={child}
                  parentItem={item}
                  currentUrl={currentUrl}
                />
              )}

              {child.title && (
                <MenuItem
                  item={child}
                  parentItem={item}
                  currentUrl={currentUrl}
                  layoutConfig={layoutConfig}
                  userFollowersCount={userFollowersCount}
                  onMenuItemClick={onMenuItemClick}
                  isDummyViewActive={isDummyViewActive}
                />
              )}
            </React.Fragment>
          ))}
      </ul>
    );
  }
}

import React from "react";
import {Document, Font, Image, Page, StyleSheet, Text, View} from "@react-pdf/renderer";
import {toAbsoluteUrl} from "_metronic";
import FONTS_ALLOWED from "../../../../constants/FONTS_ALLOWED";

const colorPrimary = "#5D6472";
const colorSecondary = "#d9d9d9";
const pdfFontFamily = "Poppins";

const numberFormat = (number, fixedMin = 0, fixedMax = 2) => {
  return Number(number).toLocaleString('en-US', {minimumFractionDigits: fixedMin, maximumFractionDigits: fixedMax}).replace(/,/g, ' ');
};
class Footer extends React.Component {
  render() {
    const {states, paddingRight, paddingRightDouble} = this.props;
    const styles = StyleSheet.create({
      footer: {
        position: 'absolute',
        bottom: 30,
        left: 30,
        right: 30,
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%"
      },
      paddingRight: {
        paddingRight: 35
      },
      paddingRightDouble: {
        paddingRight: 70
      },
      footerText: {
        fontSize: 8,
        fontWeight: 300
      },
      pageNumber: {
        fontSize: 8,
        color: states.textColor,
      }
    });
    return(
      <View style={[styles.footer, paddingRight && styles.paddingRight, paddingRightDouble && styles.paddingRightDouble]} fixed>
        <Text style={styles.footerText}>All rights reserved. Presentation generated from REDD Platform.</Text>
        <View style={{flexGrow: 1, height: 1, marginHorizontal: 10, backgroundColor: colorSecondary}}/>
        <Text style={styles.pageNumber} render={({ pageNumber }) => (`${pageNumber}`)}/>
      </View>
    );
  }
}
class FrontPage extends React.Component {
  render() {
    const {pdfStyles, user, renderTranslation, data, states} = this.props;
    const {companyName, companyNip, companyKrs, companyRegon, year} = data;
    const styles = StyleSheet.create({
      frontPage: {
        paddingLeft: 0
      },
      firstPage: {
        position: "relative",
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start"
      },
      companyLogoBox: {
        position: "absolute",
        top: 0,
        right: 0,
        display: "block",
        width: "auto",
        height: 52
      },
      companyLogo: {
        maxWidth: 150,
        maxHeight: 52,
        width: "auto",
        height: "100%",
      },
      pdfTitleContainer: {
        position: "relative",
        width: "80%",
        height: 180,
        display: "block",
        backgroundColor: states.frontBoxBgColor
      },
      pdfTitleContent: {
        flex: 1,
        flexDirection: "column",
        position: "absolute",
        left: 35,
        right: 30,
        top: 4
      },
      titleBackground: {
        width: "100%"
      },
      defaultTitle: {
        color: "#ffffff",
        display: "block",
        lineHeight: 1
      },
      commonTitle: {
        fontSize: 18,
        fontWeight: 300,
        color: states.frontBoxTextColor,
      },
      spvTitle: {
        fontSize: 16,
        fontWeight: 600,
        lineHeight: 1.2,
        marginTop: 20,
        marginBottom: 10,
        color: states.frontBoxTextColor,
      },
      dataTitle: {
        fontSize: 14,
        fontWeight: 300,
        marginVertical: 2,
        color: states.frontBoxTextColor,
      }
    });
    return(
      <Page style={[pdfStyles.body, styles.frontPage]} size="A4" wrap>
        <View style={styles.firstPage}>
          <View style={styles.companyLogoBox}>
            {user.companyLogoThumb &&
            <Image
              style={styles.companyLogo}
              src={user.companyLogoThumb}
            />
            }
          </View>
          <View style={styles.pdfTitleContainer}>
            <View style={styles.pdfTitleContent}>
              <Text style={[styles.defaultTitle, styles.commonTitle]}>{`${renderTranslation("OWNERS.PHRASE.FINANCIAL_STATEMENT")} ${year}`}</Text>
              <Text style={[styles.defaultTitle, styles.spvTitle]}>{companyName}</Text>
              <Text style={[styles.defaultTitle, styles.dataTitle]}>NIP: {companyNip ? <Text style={{fontWeight: 600}}>{companyNip}</Text> : "N/A"}</Text>
              <Text style={[styles.defaultTitle, styles.dataTitle]}>KRS: {companyKrs ? <Text style={{fontWeight: 600}}>{companyKrs}</Text> : "N/A"}</Text>
              <Text style={[styles.defaultTitle, styles.dataTitle]}>REGON: {companyRegon ? <Text style={{fontWeight: 600}}>{companyRegon}</Text> : "N/A"}</Text>
            </View>
          </View>
        </View>
        <Footer states paddingRight/>
      </Page>
    );
  }
}
class BuildingsListPage extends React.Component {
  render() {
    const {pdfStyles, data, renderTranslation} = this.props;
    const styles = StyleSheet.create({
      buildingTitleContainer: {
        borderTopWidth: 1,
        borderStyle: "solid",
        borderColor: "#d9d9d9",
        paddingTop: 5,
        marginBottom: 5,
        alignItems: "flex-end"
      },
      buildingTitle: {
        fontSize: 13,
        fontWeight: 600,
      },
      buildingLocation: {
        fontSize: 11,
        fontWeight: 400
      },
      buildingSummaryTable: {
        marginBottom: 25
      },
      tableThead: {
        width: "100%",
        alignItems: "center"
      },
      tableBodyRow: {
        width: "100%"
      },
      thValue: {
        fontSize: 7,
        fontWeight: 400,
        textTransform: "uppercase"
      },
      tdValue: {
        fontSize: 9,
        fontWeight: 600,
        paddingHorizontal: 5,
        paddingVertical: 5
      },
      col1: {
        width: "19%"
      },
      col2: {
        width: "15%"
      },
      col3: {
        width: "18%"
      },
      col4: {
        width: "18%"
      },
      col5: {
        width: "15%"
      },
      col6: {
        width: "15%"
      },
    });

    return(
      <Page style={pdfStyles.body} size="A4" wrap>
        <Text style={pdfStyles.subtitle}>{renderTranslation("OWNERS.PHRASE.SPV_BUILDINGS")}</Text>
        {data && data.map((building, index) =>
        <View key={Math.random()+index} wrap={false}>
        <View style={[pdfStyles.row, styles.buildingTitleContainer]}>
          <Text style={styles.buildingTitle}>{building.name}</Text>
          <Text style={styles.buildingLocation}>{building.addressCity} | {building.district} | {building.addressStreet}</Text>
        </View>
        <View style={[pdfStyles.row, styles.buildingSummaryTable]}>
          <View style={{width: "10%"}}>
            <Image
              style={styles.buildingImage}
              src={building.mainPhotoThumbnail ? building.mainPhotoThumbnail : toAbsoluteUrl("media/office-placeholder.jpg")}
            />
          </View>
          <View style={{width: "90%"}}>
            <View style={[pdfStyles.row, styles.tableThead]}>
              <Text style={[pdfStyles.labelWithBg, styles.thValue, styles.col1]}>{renderTranslation("PDF.PHRASE.RENT_FROM", "\n")}</Text>
              <Text style={[pdfStyles.labelWithBg, styles.thValue, styles.col2]}>{renderTranslation("PDF.PHRASE.SERVICE_CHARGE", "\n")}</Text>
              <Text style={[pdfStyles.labelWithBg, styles.thValue, styles.col3]}>{renderTranslation("PDF.PHRASE.TOTAL_AVAILABLE_AREA", "\n")}</Text>
              <Text style={[pdfStyles.labelWithBg, styles.thValue, styles.col4]}>{renderTranslation("PDF.PHRASE.FOR_SALE", "\n")}</Text>
              <Text style={[pdfStyles.labelWithBg, styles.thValue, styles.col6]}>{renderTranslation("PDF.PHRASE.COMMON_AREA_FACTOR_SHORT", "\n")}</Text>
              <Text style={[pdfStyles.labelWithBg, styles.thValue, styles.col5]}>{renderTranslation("PDF.PHRASE.MIN_RENTAL_YEARS_SHORT", "\n")}</Text>
            </View>
            <View style={[pdfStyles.row, styles.tableBodyRow]}>
              <Text style={[styles.tdValue, styles.col1]}>{building.rentFromEur ? `€ ${building.rentFromEur}` : 'N/A'}</Text>
              <Text style={[styles.tdValue, styles.col2]}>{building.serviceChargesPln ? `PLN ${building.serviceChargesPln}` : 'N/A'}</Text>
              <Text style={[styles.tdValue, styles.col4]}>{building.totalAreaAvailable ? <Text>{numberFormat(building.totalAreaAvailable)} m&sup2;</Text> : 'N/A'}</Text>
              <Text style={[styles.tdValue, styles.col3]}>{building.forSale ? renderTranslation("GENERAL.PHRASE.YES") : renderTranslation("GENERAL.PHRASE.NO")}</Text>
              <Text style={[styles.tdValue, styles.col6]}>{building.commonAreaFactor ? `${building.commonAreaFactor}%` : 'N/A'}</Text>
              <Text style={[styles.tdValue, styles.col5]}>{building.minRentalYears ? building.minRentalYears : 'N/A'}</Text>
            </View>
          </View>
        </View>
        </View>
        )}
        <Footer states/>
      </Page>
    );
  }
}
class FinancialStatementPreviewPage extends React.Component {
  render() {
    const {pdfStyles, data, renderTranslation} = this.props;
    const {
      totalAssets,
      assets,
      liabilities,
      ebit,
      totalOperatingRevenue,
      grossProfitMargin,
      operatingProfitMarginRos,
      ebitda,
      roaMargin,
      debtToEquity,
      salesProfit
    } = data;
    const styles = StyleSheet.create({
          buildingTitle: {
            fontSize: 22,
            fontWeight: 600,
            textTransform: "uppercase"
          },
          buildingDetails: {
            width: "35%",
            paddingTop: 10,
            borderTopWidth: 1,
            borderStyle: "solid",
            borderColor: "#d9d9d9",
            marginRight: 10
          },
          buildingDetailsPreview: {
            width: "100%",
            marginVertical: 15,
            paddingVertical: 10,
            borderTopWidth: 1,
            borderBottomWidth: 1,
            borderStyle: "solid",
            borderColor: "#d9d9d9",
          },
          buildingImageBox: {
            width: "60%",
            height: 297,
            overflow: "hidden"
          },
          buildingImage: {
            width: "100%",
            height: "auto"
          },
          city: {
            fontSize: 20,
            fontWeight: 600
          },
          district: {
            fontSize: 18,
            fontWeight: 300
          },
          street: {
            fontSize: 13,
            fontWeight: 300,
            marginBottom: 10
          },

          unitsContainer: {
            width: "100%",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            marginBottom: 30
          },
          tableThead: {
            width: "100%"
          },
          tableBodyRow: {
            width: "100%"
          },
          thValue: {
            fontSize: 9,
            textAlign: "right",
            textTransform: "uppercase"
          },
          tdValue: {
            fontSize: 11,
            textAlign: "right",
            borderBottomWidth: 1,
            borderStyle: "solid",
            borderColor: "#d9d9d9",
            paddingHorizontal: 5
          },
          col0: {
            width: "3%"
          },
          col1: {
            width: "20%"
          },
          col2: {
            width: "8%"
          },
          col3: {
            width: "12%"
          },
          col4: {
            width: "17%"
          },
          col5: {
            width: "15%"
          },
          col6: {
            width: "23%"
          },

          leaseTermsContainer: {
            width: "100%"
          },
          buildingMapStatic: {
            width: "50%",
            maxHeight: 220,
            overflow: "hidden"
          },
          buildingMapStaticImage: {
            width: "100%",
            height: "auto"
          },
          leaseRow: {
            width: "100%",
            borderBottomWidth: 1,
            borderStyle: "solid",
            borderColor: "#d9d9d9",
            paddingTop: 25,
            paddingBottom: 10,
            alignItems: "center"
          },
          leaseLabel: {
            width: "47%",
            fontSize: 9,
            lineHeight: 1
          },
          leaseValue: {
            width: "49%",
            fontSize: 12,
            fontWeight: 600,
            lineHeight: 1,
            textAlign: "right",
            letterSpacing: 1
          },
          valuePrefixSufix: {
            fontSize: 9,
            fontWeight: 400
          }
        });
    const renderFinancialRow = (label, value, prefix, sufix) => {
      return(
        <View style={[pdfStyles.row, styles.leaseRow]}>
          <Text style={[pdfStyles.label, styles.leaseLabel]}>{label}</Text>
          <Text style={[pdfStyles.label, styles.leaseValue]}>{prefix && <Text style={styles.valuePrefixSufix}>{prefix} </Text>}{value}{sufix && <Text style={styles.valuePrefixSufix}> {sufix}</Text>}</Text>
        </View>
      );
    };

    return(
      <Page style={pdfStyles.body} size="A4" wrap>
        <View style={pdfStyles.row}>
          <View style={[pdfStyles.column, styles.leaseTermsContainer]}>
            <Text style={pdfStyles.subtitle}>{renderTranslation("OWNERS.PHRASE.PRIMARY_FINANCIAL_DATA")}</Text>
            {renderFinancialRow(renderTranslation("OWNERS.PHRASE.TOTAL_OPERATING_REVENUE"), totalOperatingRevenue ? numberFormat(totalOperatingRevenue, 2) : 'N/A', "PLN")}
            {renderFinancialRow(renderTranslation("OWNERS.PHRASE.PROFIT_LOSS_FROM_SALES", "\n"), salesProfit ? numberFormat(salesProfit, 2) : 'N/A', "PLN")}
            {renderFinancialRow(renderTranslation("OWNERS.PHRASE.GROSS_PROFIT_MARGIN", "\n"), grossProfitMargin ? numberFormat(grossProfitMargin, 2) : 'N/A', null, "%")}
            {renderFinancialRow(renderTranslation("OWNERS.PHRASE.OPERATING_PROFIT_EBIT", "\n"), ebit ? numberFormat(ebit, 2) : 'N/A', "PLN")}
            {renderFinancialRow(renderTranslation("OWNERS.PHRASE.OPERATING_PROFIT_MARGIN_ROS", "\n"), operatingProfitMarginRos ? numberFormat(operatingProfitMarginRos) : 'N/A', null, "%")}
            {renderFinancialRow("EBITDA", ebitda ? numberFormat(ebitda, 2) : 'N/A', "PLN")}
            {renderFinancialRow(renderTranslation("OWNERS.PHRASE.TOTAL_ASSETS", "\n"), totalAssets ? numberFormat(totalAssets, 2) : 'N/A', "PLN")}
            {renderFinancialRow(renderTranslation("OWNERS.PHRASE.CURRENT_ASSETS", "\n"), assets ? numberFormat(assets, 2) : 'N/A', "PLN")}
            {renderFinancialRow(renderTranslation("OWNERS.PHRASE.TOTAL_LIABILITIES", "\n"), liabilities ? numberFormat(liabilities, 2) : 'N/A', "PLN")}
            {renderFinancialRow("ROA", roaMargin ? numberFormat(roaMargin) : 'N/A', null, "%")}
            {renderFinancialRow(renderTranslation("OWNERS.PHRASE.DEBT_TO_EQUITY"), debtToEquity ? numberFormat(debtToEquity, 2) : 'N/A')}
          </View>
        </View>
        <Footer states/>
      </Page>
    );
  }
}
class FinancialStatementDetailsPage extends React.Component {
  render() {
    const {pdfStyles, data, renderTranslation} = this.props;
    const { snapshot } = data;
    const styles = StyleSheet.create({
          buildingTitle: {
            fontSize: 22,
            fontWeight: 600,
            textTransform: "uppercase"
          },
          buildingDetails: {
            width: "35%",
            paddingTop: 10,
            borderTopWidth: 1,
            borderStyle: "solid",
            borderColor: "#d9d9d9",
            marginRight: 10
          },
          buildingDetailsPreview: {
            width: "100%",
            marginVertical: 15,
            paddingVertical: 10,
            borderTopWidth: 1,
            borderBottomWidth: 1,
            borderStyle: "solid",
            borderColor: "#d9d9d9",
          },
          buildingImageBox: {
            width: "60%",
            height: 297,
            overflow: "hidden"
          },
          buildingImage: {
            width: "100%",
            height: "auto"
          },
          city: {
            fontSize: 20,
            fontWeight: 600
          },
          district: {
            fontSize: 18,
            fontWeight: 300
          },
          street: {
            fontSize: 13,
            fontWeight: 300,
            marginBottom: 10
          },

          unitsContainer: {
            width: "100%",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            marginBottom: 30
          },
          tableThead: {
            width: "100%"
          },
          tableBodyRow: {
            width: "100%"
          },
          thValue: {
            fontSize: 9,
            textAlign: "right",
            textTransform: "uppercase"
          },
          tdValue: {
            fontSize: 11,
            textAlign: "right",
            borderBottomWidth: 1,
            borderStyle: "solid",
            borderColor: "#d9d9d9",
            paddingHorizontal: 5
          },
          col0: {
            width: "3%"
          },
          col1: {
            width: "20%"
          },
          col2: {
            width: "8%"
          },
          col3: {
            width: "12%"
          },
          col4: {
            width: "17%"
          },
          col5: {
            width: "15%"
          },
          col6: {
            width: "23%"
          },

          leaseTermsContainer: {
            width: "100%"
          },
          buildingMapStatic: {
            width: "50%",
            maxHeight: 220,
            overflow: "hidden"
          },
          buildingMapStaticImage: {
            width: "100%",
            height: "auto"
          },
          leaseRow: {
            width: "100%",
            borderBottomWidth: 1,
            borderStyle: "solid",
            borderColor: "#d9d9d9",
            paddingTop: 10,
            paddingBottom: 5,
            alignItems: "center"
          },
          leaseLabel: {
            width: "68%",
            fontSize: 8,
            lineHeight: 1
          },
          leaseValue: {
            width: "30%",
            fontSize: 10,
            fontWeight: 600,
            lineHeight: 1,
            textAlign: "right",
            letterSpacing: 1
          },
          valuePrefixSufix: {
            fontSize: 8,
            fontWeight: 400
          },
        disclaimerText: {
            fontSize: 8,
            fontWeight: 300,
            marginTop: 25
          },
        });
    const renderFinancialRow = (label, value, prefix, sufix, index) => {
      return(
        <View key={index} style={[pdfStyles.row, styles.leaseRow]} wrap={false}>
          <Text style={[pdfStyles.label, styles.leaseLabel]}>{label}</Text>
          <Text style={[pdfStyles.label, styles.leaseValue]}>{prefix && <Text style={styles.valuePrefixSufix}>{prefix} </Text>}{value}{sufix && <Text style={styles.valuePrefixSufix}> {sufix}</Text>}</Text>
        </View>
      );
    };

    return(
      <Page style={pdfStyles.body} size="A4" wrap>
        <View style={pdfStyles.row}>
          <View style={[pdfStyles.column, styles.leaseTermsContainer]}>
            <Text style={pdfStyles.subtitle}>{renderTranslation("OWNERS.PHRASE.COMPLETE_FINANCIAL_STATEMENT")}</Text>
            {snapshot && snapshot.length && snapshot.map((item, index) => {
              const label = Object.keys(item)[0];
              const value = Object.values(item)[0];
              return renderFinancialRow(label, numberFormat(value, 2), "PLN", "", index);
            })}
            <Text style={styles.disclaimerText}>{renderTranslation("PDF.PHRASE.FINANCIAL_STATEMENT_DISCLAIMER", "\n")}</Text>
          </View>
        </View>
        <Footer states/>
      </Page>
    );
  }
}

export default class OwnerFinancialPdfDocument extends React.Component {
  constructor(props) {
    super(props);
    const {user} = props;
    this.state = {
      frontBoxBgColor: user.pdfFrontPageBoxBg || colorPrimary,
      frontBoxTextColor: user.pdfFrontPageBoxText || "#ffffff",
      grayBoxesBgColor: user.pdfGreyBoxesBg || colorSecondary,
      grayBoxesTextColor: user.pdfGreyBoxesText || colorPrimary,
      h1Color: user.pdfH1Text || colorPrimary,
      h2Color: user.pdfH2Text || colorPrimary,
      textColor: user.pdfPText || colorPrimary,
      textFontFamily: user.pdfFontFamily || pdfFontFamily
    }
  }
  render() {
    const {intl} = this.props;
    const {textFontFamily} = this.state;
    // Register fonts
    FONTS_ALLOWED.map(font => Font.register(font.code));

    const styles = StyleSheet.create({
      body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
        fontFamily: textFontFamily,
        color: this.state.textColor
      },
      subtitle: {
        fontSize: 16,
        fontWeight: 400,
        marginBottom: 5
      },
      row: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start"
      },
      column: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-start"
      },
      label: {
        fontSize: 10,
        fontWeight: 300,
        textTransform: "uppercase"
      },
      labelWithBg: {
        display: "block",
        width: "100%",
        fontSize: 12,
        fontWeight: 300,
        backgroundColor: this.state.grayBoxesBgColor,
        color: this.state.grayBoxesTextColor,
        padding: 3
      },
      labelValue: {
        fontSize: 13,
        fontWeight: 400
      },
    });
    const { officeFinances, ownerPortfolio, user } = this.props;
    const financesDetailsEnable = officeFinances && officeFinances.snapshot && officeFinances.snapshot.length > 0;

    let financesSPVBuildings;
    if(ownerPortfolio && officeFinances) {
        financesSPVBuildings = ownerPortfolio.filter(building => building.ownerName === officeFinances.companyName);
    }
    const handleStringTranslation = (id, br) => {
      return intl.formatMessage({id: id}, {br})
    };
    return(
      <Document>
        <FrontPage states={this.state} data={officeFinances} pdfStyles={styles} user={user} intl={intl} renderTranslation={handleStringTranslation}/>
        <BuildingsListPage states={this.state} data={financesSPVBuildings} pdfStyles={styles} renderTranslation={handleStringTranslation}/>
        <FinancialStatementPreviewPage states={this.state} data={officeFinances} pdfStyles={styles} renderTranslation={handleStringTranslation}/>
        {financesDetailsEnable && <FinancialStatementDetailsPage states={this.state} data={officeFinances} pdfStyles={styles} renderTranslation={handleStringTranslation}/>}
      </Document>
    );
  }
}
import React, {createRef} from "react";
import {FormattedMessage} from "react-intl";
import {Grid, Typography} from "@material-ui/core";
import {Portlet, PortletBody, PortletHeader} from "../../../partials/content/Portlet";
import styles from "./index.module.scss";
import ReactVisibilitySensor from "react-visibility-sensor";
import IconColorMUI from "../../../partials/components/IconColorMUI";
import LoadingScreen from "../../../partials/components/LoadingScreen";
import cn from "classnames";
import TransactionsView from "./components/TransactionsView";
import TransactionDetailsView from "./components/TransactionDetailsView";
import store from "../../../store/store";
import TransactionsDynamicData from "./components/TransactionsDynamicData";
import TransactionsDatabaseStatus from "./components/TransactionsDatabaseStatus";
import {scrollToElement} from "../../../utils/helpers";
import APPLICATION_VIEWS from "../../../constants/APPLICATION_VIEWS";
import IsPro from "../../../utils/IsPro";
import IsApplicationView from "../../../utils/IsApplicationView";

export default class SymmcoPage extends React.Component {
  constructor(props) {
    super(props);
    this.tableRef = createRef();
    this.state = {
      ownedOnly: false,
      activeTransactionIndex: null,
      activeTransactionId: null,
      prevTransactionId: null,
      nextTransactionId: null,
      isLoaded: false,
      isDynamicDataLoaded: false,
      isDatabaseStatusLoaded: false
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.items !== this.props.items) {
      this.handleCloseDetails();
    }
  }

  // Handlers
  handleOnViewportFetch = (isVisible) => {
    const {
      loading,
      fetchTransactions,
      dynamicDataLoading,
      fetchTransactionsDynamicData
    } = this.props;
    const {isLoaded, isDynamicDataLoaded} = this.state;

    if(isVisible && !loading && !isLoaded && fetchTransactions) {
      const params = {
        page: 1
      };
      this.setState({
        isLoaded: true
      }, () => fetchTransactions(params));
    }
    if(isVisible && !dynamicDataLoading && !isDynamicDataLoaded && fetchTransactionsDynamicData) {
      const params = {
        page: 1
      };
      this.setState({
        isDynamicDataLoaded: true
      }, () => fetchTransactionsDynamicData(params));
    }
  }
  handleOnViewportDatabaseFetch = (isVisible) => {
    const {
      databaseStatusLoading,
      fetchTransactionsDatabaseStatus
    } = this.props;
    const {isDatabaseStatusLoaded} = this.state;

    if(isVisible && !databaseStatusLoading && !isDatabaseStatusLoaded && fetchTransactionsDatabaseStatus) {
      this.setState({
        isDatabaseStatusLoaded: true
      }, () => fetchTransactionsDatabaseStatus());
    }
  }
  handleDownloadXls = () => {
    const {transactionsXlsLoading, fetchTransactionsXls, callReddClickEvent, user} = this.props;
    const teamName = user?.team?.name;

    if(!transactionsXlsLoading && fetchTransactionsXls) {
      callReddClickEvent(
        'finne-leads-offices-lead-download-csv-click',
        'symmcotransaction'
      );
      fetchTransactionsXls(teamName, store.getState().filters);
    }
  }
  handleFilterOwned = (isOwned) => {
    const{fetchTransactions, fetchTransactionsDynamicData, filterByModules} = this.props;
    filterByModules({isEditable: isOwned ? "true" : "false"});
    const params = {
      ...store.getState().filters,
      page: 1,
      ...isOwned ? {isEditable: "true"} : {}
    };

    if(fetchTransactions && params) {
      this.setState({
        ownedOnly: isOwned
      }, () => {
        fetchTransactions(params);
        fetchTransactionsDynamicData(params);
      });
    }
  }
  handleOpenDetails = (id, index) => {
    const {fetchTransaction, items} = this.props;

    this.setState({
      activeTransactionIndex: index,
      activeTransactionId: id,
      prevTransactionId: items?.[index-1]?.id,
      nextTransactionId: items?.[index+1]?.id
    }, () => {
      scrollToElement(this.tableRef.current, 150)
      fetchTransaction && fetchTransaction(id);
    });
  }
  handleCloseDetails = () => {
    this.setState({
      activeTransactionIndex: null,
      activeTransactionId: null,
      prevTransactionId: null,
      nextTransactionId: null
    });
  }
  handleChangeDetails = (direction) => {
    const {fetchTransaction, items} = this.props;
    const newTransactionId = direction === "prev" ? this.state.prevTransactionId :
      direction === "next" ? this.state.nextTransactionId : null;
    const activeTransactionIndex = direction === "prev" ? this.state.activeTransactionIndex - 1 :
      direction === "next" ? this.state.activeTransactionIndex + 1 : null;

    newTransactionId && fetchTransaction(newTransactionId);
    this.setState({
      activeTransactionIndex,
      activeTransactionId: items?.[activeTransactionIndex]?.id,
      prevTransactionId: items?.[activeTransactionIndex-1]?.id,
      nextTransactionId: items?.[activeTransactionIndex+1]?.id
    });
  }

  render() {
    const {items, transactionsXlsLoading, transactionLoading, itemsCount, itemsPerPage, user} = this.props;
    const {ownedOnly, activeTransactionId, activeTransactionIndex} = this.state;
    const detailsNavLimit = itemsCount > itemsPerPage ? itemsPerPage : itemsCount;

    return(
      <IsPro paywall additionalCondition={user?.proData?.symmcoAccess}>
        <IsApplicationView expectedAppView={APPLICATION_VIEWS.INDUSTRIAL}>
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item xs={12}>
              <ReactVisibilitySensor
                onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
                scrollCheck
                partialVisibility
              >
                <Portlet style={{backgroundColor: "#FFF4F3"}}>
                  <PortletBody>
                    <TransactionsDynamicData {...this.props}/>
                  </PortletBody>
                </Portlet>
              </ReactVisibilitySensor>
            </Grid>
            <Grid item xs={activeTransactionId ? 8 : 12}>
              <Portlet fluidHeight ref={this.tableRef}>
                <PortletHeader
                  title={<Typography variant="h3"><FormattedMessage id="VOCABULARY.Lease comps"/></Typography>}
                  style={{borderBottom: 0}}
                />
                <PortletBody style={{paddingTop: 0}}>
                  <div className={styles.topbarWrapper}>
                    <div className={styles.selectViewWrapper}>
                      <button
                        className={!ownedOnly ? styles.active : undefined}
                        onClick={() => this.handleFilterOwned(false)}
                      >
                        <FormattedMessage id="VOCABULARY.All transactions"/>
                      </button>
                      <button
                        className={ownedOnly ? styles.active : undefined}
                        onClick={() => this.handleFilterOwned(true)}
                      >
                        <FormattedMessage id="VOCABULARY.Owned transactions"/>
                      </button>
                    </div>
                    {items && items?.length > 0 &&
                    <button
                      className={cn(
                        styles.btnDownload,
                        transactionsXlsLoading ? styles.inactive : undefined
                      )}
                      onClick={() => this.handleDownloadXls()}
                    >
                      {transactionsXlsLoading ? <LoadingScreen loaderStyle={{width: "16px", height: "16px", marginRight: "5px"}}/> :
                      <IconColorMUI
                        icon="download"
                        size="18px"
                        style={{paddingRight: "5px", lineHeight: "0"}}
                      />
                      }
                      <FormattedMessage id="LEADS.PHRASE.DOWNLOAD_XLS"/>
                    </button>
                    }
                  </div>
                  <TransactionsView
                    activeTransactionId={activeTransactionId}
                    onToggleDetails={this.handleOpenDetails}
                    {...this.props}
                  />
                </PortletBody>
              </Portlet>
            </Grid>
            {activeTransactionId &&
            <Grid item xs={4}>
              <Portlet fluidHeight>
                <PortletHeader
                  title={
                  <div className={styles.detailsTitleWrapper}>
                    <button
                      className={styles.btnCloseDetails}
                      onClick={() => this.handleCloseDetails()}
                    >
                      <IconColorMUI
                        icon="close"
                        size="2rem"
                        style={{lineHeight: 0}}
                      />
                    </button>
                  <Typography variant="h3"><FormattedMessage id="VOCABULARY.Transaction details"/></Typography>
                  </div>
                  }
                  style={{borderBottom: 0}}
                  toolbar={
                  itemsCount > 1 &&
                  <div className={styles.transactionDetailsNav}>
                    {activeTransactionIndex > 0 &&
                    <button onClick={() => this.handleChangeDetails("prev")}>
                      <IconColorMUI
                        icon="chevron_left"
                        size="2.4rem"
                        style={{lineHeight: 0}}
                      />
                    </button>
                    }
                    <span>{`${activeTransactionIndex+1}/${detailsNavLimit}`}</span>
                    {activeTransactionIndex+1 < detailsNavLimit &&
                    <button onClick={() => this.handleChangeDetails("next")}>
                      <IconColorMUI
                        icon="chevron_right"
                        size="2.4rem"
                        style={{lineHeight: 0}}
                      />
                    </button>
                    }
                  </div>
                  }
                />
                <PortletBody style={{paddingTop: 0}}>
                  {transactionLoading ? <LoadingScreen/>:
                  <TransactionDetailsView {...this.props}/>
                  }
                </PortletBody>
              </Portlet>
            </Grid>
            }
            <Grid item xs={12}>
              <ReactVisibilitySensor
                onChange={(isVisible) => this.handleOnViewportDatabaseFetch(isVisible)}
                scrollCheck
                partialVisibility
              >
                <Portlet>
                  <PortletHeader
                    title={<Typography variant="h3"><FormattedMessage id="VOCABULARY.Database Status"/></Typography>}
                    style={{borderBottom: 0}}
                  />
                  <PortletBody>
                    <TransactionsDatabaseStatus {...this.props}/>
                  </PortletBody>
                </Portlet>
              </ReactVisibilitySensor>
            </Grid>
          </Grid>
        </IsApplicationView>
      </IsPro>
    );
  }
}

import React from "react";
import {Typography} from "@material-ui/core";
import ModalCustom from "app/partials/components/ModalCustom";
import {deleteOffer} from "app/crud/offers.crud";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {callReddClickEvent} from "../../../crud/user.crud";
import Button from "../Button";
import Icon from "../Icon";

class OfferRemove extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
  }
  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleSave = () => {
    const {deleteOffer, offerID} = this.props;
    deleteOffer({offerID});
    this.handleCloseModal();
  };

  render() {
    const {triggerBtn, offerName, offerID} = this.props;
    return(
      <ModalCustom
        ref={this.child}
        btn={
        triggerBtn ? triggerBtn :
          <Button
            type={"icon"}
            color={"simple"}
            tooltip={<FormattedMessage id="VOCABULARY.Delete offer"/>}
          >
            <Icon icon={"trash"}/>
          </Button>
        }
        title={<FormattedMessage id="VOCABULARY.Delete offer"/>}
        handleSave={this.handleSave}
        btnReject={<FormattedMessage id="GENERAL.PHRASE.CANCEL"/>}
        btnConfirm={<FormattedMessage id="GENERAL.PHRASE.DELETE"/>}
        onOpen={() => this.props.dispatch(callReddClickEvent(
          "offers-v2-delete-offer-open",
          "offer",
          null,
          offerID
        ))}
      >
        <Typography>
          <FormattedMessage
            id="SHORTLIST.PHRASE.REMOVE_SHORTLIST_MESSAGE"
            values={{offerName: <strong>{offerName || "-"}</strong>}}
          />
          <br/>
          <FormattedMessage id="GENERAL.PHRASE.ACTION_CONFIRMATION_MESSAGE"/>
        </Typography>
      </ModalCustom>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  deleteOffer: offerID => dispatch(deleteOffer(offerID)),
  dispatch
});

export default injectIntl(
  connect(
    null,
    mapDispatchToProps
  )(OfferRemove)
);
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {Dropdown} from "react-bootstrap";
import {connect} from "react-redux";
import HeaderDropdownToggle from "../../content/CustomDropdowns/HeaderDropdownToggle";
import {ROUTES} from "app/constants";
import {FormattedMessage} from "react-intl";
import TeamSetAsActive from "app/partials/components/Team/TeamSetAsActive";
import UserDropdownMenuItem from "app/partials/layout/UserProfile/UserDropdownMenuItem";
import {Typography} from "@material-ui/core";
import {clearCacheAndStorage} from "../../../utils/helpers";
import styles from "./index.module.scss";
import LoginAsUser from "../../components/LoginAsUser";
import IsAdmin from "../../../utils/IsAdmin";
import IsPro from "../../../utils/IsPro";
import UserPremiumButton from "./UserPremiumButton";
import IsAccountType from "../../../utils/IsAccountType";
import ACCOUNT_TYPE from "../../../constants/ACCOUNT_TYPE";
import BadgeLabel from "../../components/BadgeLabel";

class UserProfile extends React.Component {
  handleLogout = () => {
    clearCacheAndStorage();
  };

  render() {
    const { user, showHi, showAvatar, showBadge } = this.props;
    const userData = user;

    return (
      <Dropdown
        className="kt-header__topbar-item kt-header__topbar-item--user"
        drop="down"
        alignRight
      >
        <Dropdown.Toggle
          inactive={user?.dummyViewActive}
          as={HeaderDropdownToggle}
          id="dropdown-toggle-user-profile"
        >
          <div className="kt-header__topbar-user">
            {showHi && (
              <span className="kt-header__topbar-welcome kt-hidden-mobile">
                <FormattedMessage id="AUTH.GENERAL.HELLO" />,
              </span>
            )}

            {showHi && (
              <span className="kt-header__topbar-username kt-hidden-mobile">
                {userData?.firstName ? userData?.firstName : ""}
              </span>
            )}

            {showAvatar && userData?.avatar && (
              <img alt="Pic" src={userData?.avatar} />
            )}

            {showBadge && <UserPremiumButton user={userData} />}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu
          className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl"
          onClick={() => document.body.click()}
        >
          {/** ClassName should be 'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          <div className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x">
            <div className="kt-user-card__avatar">
              {userData?.avatar && (
                <img alt="User Avatar" src={userData?.avatar} />
              )}
              <div>
                <p>{`${userData && userData?.firstName} ${userData?.lastName}`}</p>
                <p>{userData?.email}</p>
                <p>{userData?.team && userData?.team?.name}</p>
              </div>
            </div>
          </div>
          <div className="kt-notification">
            <UserDropdownMenuItem
              link={`${ROUTES.USER_PROFILE}/edit`}
              title="USER.PHRASE.EDIT_PROFILE"
            />
            <IsAdmin loggedInAs={true}>
              <UserDropdownMenuItem
                link={`${ROUTES.USER_PROFILE}/pdf-customize`}
                title="PDF.PHRASE.PERSONALIZE_PDF"
                badge={<BadgeLabel label="AO" type="admin"/>}
              />
            </IsAdmin>
            <IsPro>
              <IsAccountType expectedAccountType={ACCOUNT_TYPE.LANDLORD}>
                {userData?.isTeamOwner && (
                  <UserDropdownMenuItem
                    link={`${ROUTES.USER_PROFILE}/pp-online-customize`}
                    title="PROPERTY_PARTICULARS.PERSONALIZE.TITLE"
                  />
                )}
              </IsAccountType>
            </IsPro>
            <IsPro>
              {userData?.isTeamOwner && (
                <UserDropdownMenuItem
                  link={`${ROUTES.USER_PROFILE}/offers-online-customize`}
                  title="OFFERS.PERSONALIZE.TITLE"
                />
              )}
            </IsPro>
            <UserDropdownMenuItem
              link={`${ROUTES.USER_PROFILE}/change-password`}
              title="USER.PHRASE.CHANGE_PASSWORD"
            />
            <IsAdmin>
              <TeamSetAsActive />
            </IsAdmin>
            <IsAdmin>
              <LoginAsUser />
            </IsAdmin>
            <UserDropdownMenuItem
              title="MENU.HELP_CENTER"
              hyperlink="https://www.notion.so/reddgroup/PODSTAWOWA-BAZA-WIEDZY-87da329dafc246d39f7d98f05b3e86ef"
            />
            <div className="kt-notification__custom">
              <Typography className={styles.logout} onClick={this.handleLogout}>
                <FormattedMessage id="USER.MENU.SIGN_OUT" />
              </Typography>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default connect()(UserProfile);

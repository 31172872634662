import React, {useEffect, useState} from 'react';
import PropertySectionTitle from "../../../common/components/PropertyPage/PropertySectionTitle";
import LoadingScreen from "../../../../partials/components/LoadingScreen";
import NoData from "../../../../partials/components/NoData/NoData";
import TableCustom from "../../../../partials/components/TableCustom";
import {FormattedMessage} from "react-intl";
import PropertyCardPreview from "../../../../partials/components/_DataParts/PropertyCardPreview";
import {ROUTES, UNIT_STATUS} from "../../../../constants";
import {toNumberFormat} from "../../../../utils/helpers";
import UnitAvailabilityPreview from "../../../../partials/components/_DataParts/UnitAvailabilityPreview";
import OfferRemoveUnit from "./actions/OfferRemoveUnit";
import HighlightLabel from "../../../../partials/components/HighlightLabel";
import Button from "../../../../partials/components/Button";
import Icon from "../../../../partials/components/Icon";

export default function OfferUnits({loading = false, units, ...props}) {
  const offerID = props?.data?.id;
  const [tBody, setTBody] = useState(null);
  const tHead = [
    {
      "label": "",
      "number": false
    },
    {
      "label": <FormattedMessage id="VOCABULARY.Availability"/>,
      "number": false
    },
    {
      "label": <FormattedMessage id="VOCABULARY.Area"/>,
      "number": true
    },
    {
      "label": <FormattedMessage id="VOCABULARY.Floor"/>,
      "number": true
    },
    {
      "label": "",
      "number": true
    }
  ];
  const reddClickHandler = props.callReddClickEvent;

  // Init
  useEffect(() => {
    const handlePrepTableBody = () => {
      if (units?.length > 0) {
        return units?.map((unit, index) => {
          const address = [
            ...unit?.assetStreet ? [unit?.assetStreet] : [],
            ...unit?.assetCity ? [unit?.assetCity] : []
          ].join(", ");

          return {
            isPrimary: unit?.isPrimary,
            isGrouped: unit?.isGrouped,
            data: [
              <PropertyCardPreview
                propertyID={unit?.assetId}
                title={unit?.assetName}
                semiTitle={address}
                thumb={unit?.assetThumbnail}
                route={ROUTES.BUILDING_PAGE}
                routeNewTab
                noOverflow
                gridStyle={!unit?.isPrimary ? {
                  opacity: 0,
                  pointerEvents: "none",
                } : undefined}
                onClick={() => reddClickHandler(
                  "offers-v2-property-redirect-click",
                  "offer",
                  null,
                  offerID,
                  {property: unit?.assetId}
                )}
              />,
              <UnitAvailabilityPreview
                date={unit?.availableFrom}
                isReserved={unit?.status === UNIT_STATUS.RESERVED}
                isUnavailable={unit?.status === UNIT_STATUS.UNAVAILABLE}
                availableInMonths={unit?.availableFromAdditionalTime}
              />,
              <HighlightLabel color="neutral2">
                {toNumberFormat(unit?.area, 'area')}
              </HighlightLabel>,
              toNumberFormat(unit?.floor || 0),
              <OfferRemoveUnit
                offerID={offerID}
                unitID={unit?.id}
              />
            ]
          }
        });
      }
    }
    const updatedTBody = handlePrepTableBody();
    setTBody(updatedTBody);
  }, [units, offerID, reddClickHandler]);

  return (
    <div>
      <PropertySectionTitle
        title={<FormattedMessage id="VOCABULARY.Featured properties"/>}
        toolbar={
          <Button
            color={"simple"}
            textIcon={<Icon icon={"add-circle"}/>}
            redirect={ROUTES.MODULES_LIST_PAGE}
            redirectTarget={"_blank"}
          >
            <FormattedMessage id="VOCABULARY.Add unit"/>
          </Button>
        }
      />
      {loading ? <LoadingScreen/> :
        <TableCustom
          tHead={tHead}
          tBody={tBody}
          responsive
          offerGroupedTdStyle={{
            borderBottom: "none"
          }}
          offerPrimaryTdStyle={{
            borderTop: "1px solid #E6E6E6"
          }}
          noData={
            <NoData
              message={<FormattedMessage id="VOCABULARY.You do not yet have units and buildings added to this offer."/>}
              action={
              <Button
                redirect={ROUTES.MODULES_LIST_PAGE}
                redirectTarget={"_blank"}
              >
                <FormattedMessage id="VOCABULARY.Add unit"/>
              </Button>
              }
              limitedHeight
              withIcon
            />
          }
        />
      }
    </div>
  )
}
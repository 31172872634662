import React from "react";
import styles from "../../../../../common/external/components/index.module.scss";
import {FormattedMessage} from "react-intl";
import SliderCustom from "app/partials/components/SliderCustom";
import ModalCustom from "app/partials/components/ModalCustom";

export default class OffersOnlineSingleNavigation extends React.Component {
    render() {
        const {onScrollToAnchor, data, photos, reference, intl} = this.props;
        let images = [];
        photos && photos.length && photos.map(photo => images.push(photo));
        return(
            <div ref={reference} className={styles.contentNavigation}>
                <button className={styles.contentNavButton} onClick={() => onScrollToAnchor("details")}><FormattedMessage id="PROPERTY_PARTICULARS.TAB.DETAILS"/></button>
                {data?.planImage && <button className={styles.contentNavButton} onClick={() => onScrollToAnchor("siteplan")}><FormattedMessage id="PROPERTY_PARTICULARS.TAB.SITEPLAN"/></button>}
                {data.amenities && data.amenities.length ? <button className={styles.contentNavButton} onClick={() => onScrollToAnchor("amenities")}><FormattedMessage id="PROPERTY_PARTICULARS.TAB.AMENITIES"/></button> : null}
                {data.warehouses && data.warehouses.length ? <button className={styles.contentNavButton} onClick={() => onScrollToAnchor("buildings")}><FormattedMessage id="INDUSTRIAL.PHRASE.AVAILABLE_BUILDINGS"/></button> : null}
                <button className={styles.contentNavButton} onClick={() => onScrollToAnchor("location")}><FormattedMessage id="PROPERTY_PARTICULARS.TAB.LOCATION"/></button>
                {(
                    (intl.locale === "pl" && (data?.description || data?.descriptionLocation)) ||
                    (intl.locale === "en" && (data?.descriptionEn || data?.descriptionLocationEn))
                ) && <button className={styles.contentNavButton} onClick={() => onScrollToAnchor("propertyDescription")}><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.DESCRIPTION"/></button>}
                {images.length ? (
                <ModalCustom
                  btn={
                    <button className={styles.contentNavButton}><FormattedMessage id="PROPERTY_PARTICULARS.TAB.GALLERY"/></button>
                  }
                  title=""
                  btnReject={<FormattedMessage id="GENERAL.PHRASE.CLOSE"/>}
                  btnFlex
                  fullScreen
                  inactive={!images || (images && images.length === 0)}
                >
                  <SliderCustom images={images}/>
                </ModalCustom>
                ) : null}
            </div>
        );
    }
}
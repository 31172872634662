import React from "react";
import {connect} from "react-redux";
import * as builder from "../../ducks/builder";
import Brand from "../brand/Brand";
import PerfectScrollbar from "react-perfect-scrollbar";
import Menu from "./Menu";
import KTOffcanvas from "../../_assets/js/offcanvas";
import {ThemeProvider} from "../../index";
import ApplicationViewSwitcher from "../../../app/partials/components/ApplicationViewSwitcher";
import {ROUTES} from "../../../app/constants";
import REDD_TOWER_DUMMY_DATA from "../../../app/pages/offices/external/PropertyPageDemo/REDD_TOWER_DUMMY_DATA";

class AsideLeft extends React.Component {
  asideOffCanvasRef = React.createRef();

  componentDidMount() {
    // eslint-disable-next-line
    const ktoffConvas = new KTOffcanvas(
      this.asideOffCanvasRef.current,
      this.props.menuCanvasOptions
    );
  }

  render() {
    const {user, navMetrics} = this.props;
    const today = new Date().getFullYear();

    return (
      <>
        <button className="kt-aside-close" id="kt_aside_close_btn">
          <i className="la la-close" />
        </button>
        <div
          id="kt_aside"
          ref={this.asideOffCanvasRef}
          className={`kt-aside ${this.props.asideClassesFromConfig} kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop`}
        >
          <Brand />
          {user?.proData?.canChangeApplicationView &&
          <ThemeProvider dark>
            <ApplicationViewSwitcher/>
          </ThemeProvider>
          }
          <div
            id="kt_aside_menu_wrapper"
            className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid"
          >
            {this.props.disableScroll && (
              <Menu
                htmlClassService={this.props.htmlClassService}
                navMetrics={navMetrics}
                isDummyViewActive={this.props.dummyData}
              />
            )}
            {!this.props.disableScroll && (
              <PerfectScrollbar
                className={!user?.proData?.canChangeApplicationView ? "appSwitchDisabled" : undefined}
                options={{ wheelSpeed: 2, wheelPropagation: false }}
              >
                <Menu
                  htmlClassService={this.props.htmlClassService}
                  navMetrics={navMetrics}
                  isDummyViewActive={this.props.dummyData}
                />
                <div className="kt_aside_footer">
                  &nbsp;&copy;&nbsp;
                  {today.toString()}
                  <a
                    href={ROUTES.REDD_LANDING_PAGE}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    REDD Platform
                  </a>
                </div>
              </PerfectScrollbar>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (store, ownProps) => ({
  disableAsideSelfDisplay:
    builder?.selectors.getConfig(store, "aside.self.display") === false,
  disableScroll:
    builder?.selectors.getConfig(store, "aside.menu.dropdown") === "true" ||
    false,
  asideClassesFromConfig: builder?.selectors?.getClasses(store, {
    path: "aside",
    toString: true,
  }),
  menuCanvasOptions: {
    baseClass: "kt-aside",
    overlay: true,
    closeBy: "kt_aside_close_btn",
    toggleBy: {
      target: "kt_aside_mobile_toggler",
      state: "kt-header-mobile__toolbar-toggler--active",
    },
  },
  user: ownProps?.dummyData ? REDD_TOWER_DUMMY_DATA.user : (store.user.data ? store.user.data : store.authApp.user),
});

export default connect(mapStateToProps)(AsideLeft);

import React from "react";
import clsx from "clsx";

export default class DashboardExtendedDataWrapper extends React.Component {
  render() {
    const {children, direction, cols, className, style} = this.props;
    const itemCols = cols === 1 ? "col1" : cols === 3 ? "col3" : cols === 4 ? "col4" : "";
    return (
      <div className="kt-widget12" style={style}>
        <div className="kt-widget12__content">
          <div className={clsx("kt-widget12__item", itemCols, direction === "column" ? "direction_column" : "", className)}>
            {children}
          </div>
        </div>
      </div>
    );
  }
}
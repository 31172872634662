import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {put, takeLatest} from "redux-saga/effects";
import axios from "axios";
import {actionNotification} from "app/utils/notifications";
import {setFilters} from "../../utils/filters";

const initialState = {
  data: null,
  itemsPerPage: 20,
  itemsCount: null,
  total: null,
  loading: false,
  updating: false,
  rejecting: false
};

export const reducer = persistReducer(
  { storage, key: "accountRequest" },
  (state = initialState, action) => {
    switch (action.type) {
      // Reset User Loading States
      case 'RESET_ACCOUNT_REQUESTS_STATES_REQUEST': {
        return {
          ...state,
          loading: false,
          updating: false,
          rejecting: false
        };
      }
      // FETCH
      case 'FETCH_ACCOUNT_REQUESTS_REQUEST': {
        return {
          ...state,
          loading: true
        };
      }
      case 'FETCH_ACCOUNT_REQUESTS_SUCCESS': {
        return {
          ...state,
          data: action?.payload?.items,
          itemsPerPage: action?.payload?.itemsPerPage,
          itemsCount: action?.payload?.itemsCount,
          total: action?.payload?.total,
          loading: false
        };
      }
      case 'FETCH_ACCOUNT_REQUESTS_FAILED': {
        return {
          ...state,
          loading: false
        };
      }
      // UPDATE
      case 'UPDATE_ACCOUNT_REQUEST_REQUEST': {
        return {
          ...state,
          updating: true
        };
      }
      case 'UPDATE_ACCOUNT_REQUEST_SUCCESS': {
        return {
          ...state,
          updating: false
        }
      }
      case 'UPDATE_ACCOUNT_REQUEST_FAILED': {
        return {
          ...state,
          updating: false
        };
      }
      // REJECT
      case 'REJECT_ACCOUNT_REQUEST_REQUEST': {
        return {
          ...state,
          rejecting: true
        };
      }
      case 'REJECT_ACCOUNT_REQUEST_SUCCESS': {
        return {
          ...state,
          rejecting: false
        }
      }
      case 'REJECT_ACCOUNT_REQUEST_FAILED': {
        return {
          ...state,
          rejecting: false
        };
      }

      default:
        return state;
    }
  }
);

// FETCH DATA
function* fetchAccountRequests(action) {
  try {
    const params = action.payload.params;
    const filters = setFilters(params);
    const accountRequests = yield axios.get(`/account-request-management/${filters}`);
    yield put({ type: "FETCH_ACCOUNT_REQUESTS_SUCCESS", payload: accountRequests.data || [{ error: accountRequests.statusText }] });
  }
  catch(err) {
    console.log(err);
    yield put({ type: "FETCH_ACCOUNT_REQUESTS_FAILED" });
  }
}

// USER ACTIONS
function* updateAccountRequest(action) {
  try {
    const {requestID, data} = action.payload;
    const update = yield axios.patch(`/account-request-management/${requestID}/`, data);
    yield put({
      type: "UPDATE_ACCOUNT_REQUEST_SUCCESS",
      payload: {
        data: update?.data
      }
    });
  }
  catch(err) {
    yield put({
      type: "UPDATE_ACCOUNT_REQUEST_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* rejectAccountRequest(action) {
  try {
    const {requestID} = action.payload;
    const update = yield axios.post(`/account-request-management/${requestID}/reject/`);
    yield put({
      type: "REJECT_ACCOUNT_REQUEST_SUCCESS",
      payload: {
        data: update?.data
      }
    });
  }
  catch(err) {
    yield put({
      type: "REJECT_ACCOUNT_REQUEST_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}

export function* saga() {
  yield takeLatest('FETCH_ACCOUNT_REQUESTS_REQUEST', fetchAccountRequests);
  // UPDATE DATA
  yield takeLatest('UPDATE_ACCOUNT_REQUEST_REQUEST', updateAccountRequest);
  yield takeLatest('REJECT_ACCOUNT_REQUEST_REQUEST', rejectAccountRequest);
}

/* eslint-disable no-use-before-define */
import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid/Grid";
import FormControl from "@material-ui/core/FormControl/FormControl";
import {TextField, withStyles} from "@material-ui/core";
import PropTypes from "prop-types";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import stylesScss from "./index.module.scss";
import {createVisibleArray} from "app/utils/helpers";
import _ from "lodash";
import LoadingScreen from "../LoadingScreen";
import {injectIntl} from "react-intl";
import cn from "classnames";

const styles = theme => ({
  formControlMargin: {
    minWidth: 120,
    width: '100%',
    marginTop: 16,
    marginBottom: 8
  },
  formControl: {
    minWidth: 120,
    width: '100%',
    marginTop: 0,
    marginBottom: 0
  }
});

class SelectSearch extends React.Component {
  constructor(props) {
    super(props);
    const {value, multiple, optionsFull, optionFullValue, optionFullLabel} = props;
    let setValue = value;
    if (optionsFull && Array.isArray(value)) {
      const matchSelects = optionsFull.filter(item => {
        return item[optionFullValue] === Number(value.filter(select => select === item[optionFullValue]).join())
      });
      setValue = createVisibleArray(matchSelects, optionFullLabel);
    }
    this.state = {
      searchVal: multiple ? (
        Array.isArray(setValue) ? setValue : [setValue]
      ) : (
        Array.isArray(setValue) ? setValue?.[0] : setValue
      ),
      inputFocused: false
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value === undefined || nextProps.value === null || nextProps.value.length === 0) {
      this.setState({
        searchVal: this.props.multiple ? [] : ''
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {name, value, multiple, optionsFull, optionFullValue, optionFullLabel} = this.props;
    // On OptionsFull change
    if(
      (!_.isEqual(prevProps.optionsFull, optionsFull) && optionsFull && Array.isArray(value)) ||
      (!_.isEqual(prevProps.value, value) && Array.isArray(value) && Array.isArray(prevProps.value) && prevProps.value.length > value.length)
    ) {
      let setValue = value;
      if (optionsFull && Array.isArray(value)) {
        const matchSelects = optionsFull.filter(item => {
          return item[optionFullValue] === Number(value.filter(select => select === item[optionFullValue]).join())
        });
        setValue = createVisibleArray(matchSelects, optionFullLabel);
      }
      this.setState({
        searchVal: multiple ? (Array.isArray(setValue) ? setValue : [setValue]) : setValue
      }, () => this.props.onChange({name, value: value, label: value}));
    }
  }

  handleChange = (event, value) => {
    const {optionValue, multiple, optionsFull, optionFullLabel} = this.props;
    const selectedValues = value !== null ? (optionValue ? value[optionValue] : value) : (multiple ? [] : ''); // (optionValue ? this.state.searchVal[optionValue] : this.state.searchVal)
    let sendVal = selectedValues;
    if (optionsFull) {
      const findMatch = optionsFull.filter(item => {
        return item[optionFullLabel] === (Array.isArray(selectedValues) ? selectedValues.filter(select => select === item[optionFullLabel]).join() : selectedValues)
      });
      sendVal = createVisibleArray(findMatch, "id");
    }

    this.setState({
      searchVal: value
    }, () => {
      this.props.onChange({name: this.props.name, value: sendVal, label: value});
    });
  };

  render() {
    const {
      icon,
      options,
      label,
      name,
      width,
      size,
      classes,
      premium = true,
      optionLabel,
      multiple,
      marginOff,
      limitTags,
      isDisabled,
      disabledLabel,
      loading,
      intl,
      value,
      inputValue,
      minLength,
      inputVariant,
      onInputChange,
      searchMode,
      onInputFocus,
      optionLabelTranslate
    } = this.props;
    const {searchVal, inputFocused} = this.state;

    return (
      <Grid
        container
        spacing={icon ? 1 : 0}
        alignItems="flex-end"
        wrap="nowrap"
        style={{width: width, position: "relative"}}
      >
        {icon ?
          <>
            <Grid item>
              {loading ? <LoadingScreen loaderStyle={{width: "2.2rem"}}/> : icon}
            </Grid>
            <Grid item style={{flexGrow: '1'}}>
              <Autocomplete
                multiple={multiple}
                limitTags={limitTags || 1}
                disableCloseOnSelect={multiple}
                id={name}
                options={options}
                disabled={isDisabled}
                getOptionLabel={option => optionLabel ? option[optionLabel] : option}
                debug
                renderOption={multiple && ((option, {selected}) => (
                  <React.Fragment>
                    <Checkbox
                      checked={selected}
                      className={stylesScss.selectCheckbox}
                      color="primary"
                    />
                    {optionLabel ? option[optionLabel] : option}
                  </React.Fragment>
                ))}
                renderInput={params =>
                  <TextField
                    {...params}
                    label={isDisabled ? disabledLabel : label}
                    name={name}
                    inputProps={{size: size || 15, ...params.inputProps}}
                    fullWidth
                    disabled={!premium || isDisabled}
                    className={cn(
                      stylesScss.selectInput,
                      searchMode ? stylesScss.searchMode : undefined
                    )}
                    variant={inputVariant || "standard"}
                    onFocus={() => {
                      this.setState({inputFocused: true});
                      onInputFocus && onInputFocus();
                    }}
                    onBlur={() => this.setState({inputFocused: false})}
                  />
                }
                ChipProps={{className: stylesScss.selectChip}}
                value={searchVal}
                inputValue={inputValue}
                noOptionsText={intl?.formatMessage({id: "No options"})}
                blurOnSelect={searchMode}
                open={minLength > 0 ? ((_.isEmpty(value) && inputFocused && inputValue?.length >= minLength) || false) : undefined}
                popupIcon={searchMode ? "" : undefined}
                onChange={this.handleChange}
                onInputChange={(event, value, reason) => {
                  if(reason === "input" || reason === "clear") {
                    onInputChange && onInputChange(value);
                  }
                }}
              />
            </Grid>
          </> :
          <Grid item xs={12}>
            <FormControl className={marginOff ? classes.formControl : classes.formControlMargin}>
              <Autocomplete
                multiple={multiple}
                limitTags={limitTags || 1}
                disableCloseOnSelect={multiple}
                disabled={isDisabled}
                id={name}
                options={options || []}
                getOptionLabel={option => {
                  const optionName = typeof option === 'object' ? (optionLabel ? option[optionLabel] : option) : option;
                  if(optionLabelTranslate && optionName) {
                    return intl.formatMessage({id: optionName});
                  }
                  return optionName;
                }}
                debug
                renderOption={multiple && ((option, {selected}) => (
                  <React.Fragment>
                    <Checkbox
                      checked={selected}
                      className={stylesScss.selectCheckbox}
                      color="primary"
                    />
                    {optionLabel ? option[optionLabel] : option}
                  </React.Fragment>
                ))}
                renderInput={params =>
                  <TextField
                    {...params}
                    label={label}
                    inputProps={{size: size || 15, ...params.inputProps}}
                    fullWidth
                    disabled={!premium || isDisabled}
                    className={cn(
                      stylesScss.selectInput,
                      searchMode ? stylesScss.searchMode : undefined
                    )}
                    variant={inputVariant || "standard"}
                    onFocus={() => {
                      this.setState({inputFocused: true});
                      onInputFocus && onInputFocus();
                    }}
                    onBlur={() => this.setState({inputFocused: false})}
                  />
                }
                ChipProps={{className: stylesScss.selectChip}}
                value={this.state.searchVal}
                inputValue={inputValue}
                noOptionsText={intl?.formatMessage({id: "No options"})}
                blurOnSelect={searchMode}
                open={minLength > 0 ? ((_.isEmpty(value) && inputFocused && inputValue?.length >= minLength) || false) : undefined}
                popupIcon={searchMode ? "" : undefined}
                onChange={this.handleChange}
                onInputChange={(event, value, reason) => {
                  if(reason === "input" || reason === "clear") {
                    onInputChange && onInputChange(value);
                  }
                }}
              />
            </FormControl>
          </Grid>
        }
        {loading && !icon && <LoadingScreen absolute loaderStyle={{width: "2.2rem"}}/>}
      </Grid>
    );
  }
}

SelectSearch.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default injectIntl(withStyles(styles)(SelectSearch));
import React from "react";
import {Grid, Typography} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {deleteTeamMember} from "../../../../../crud/team.crud";
import Button from "../../../Button";

class EditTeamMemberRemove extends React.Component {
  // Handlers
  handleSave = () => {
    const {deleteTeamMember, data} = this.props;
    const memberId = data?.id;

    if(memberId) {
      deleteTeamMember(memberId);
    }
  };

  render() {
    return(
      <Grid container spacing={2} justifyContent="center" alignItems="center" direction="column" style={{height: "100%"}}>
        <Grid item>
          <Typography variant="h3" style={{textAlign: "center"}}>
            <FormattedMessage id="TEAM.PHRASE.REMOVE_MEMBER_MESSAGE"/>
            <br/>
            <FormattedMessage id="GENERAL.PHRASE.ACTION_CONFIRMATION_MESSAGE"/>
          </Typography>
        </Grid>
        <Grid item>
          <Button onClick={() => this.handleSave()}>
            <FormattedMessage id="GENERAL.PHRASE.REMOVE"/>
          </Button>
        </Grid>
      </Grid>
    );
  }
}

const mapDispatchToProps = {
  deleteTeamMember: memberId => deleteTeamMember(memberId)
};

export default injectIntl(
  connect(
    null,
    mapDispatchToProps
  )(EditTeamMemberRemove)
);
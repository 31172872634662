import React from "react";
import {Container, Grid, Table, TableBody, TableCell, TableRow, Typography} from "@material-ui/core";
import {Portlet, PortletBody} from "app/partials/content/Portlet";
import Thumbnail from "app/partials/components/Thumbnail";
import NavItem from "app/partials/components/Navigations/NavItem";
import NavContainer from "app/partials/components/Navigations/NavContainer";
import IconColorMUI from "app/partials/components/IconColorMUI";
import UserEditProfile from "app/pages/common/UserProfile/UserOptions/UserEditProfile";
import UserChangePassword from "app/pages/common/UserProfile/UserOptions/UserChangePassword";
import UserDeleteAccount from "app/pages/common/UserProfile/UserOptions/UserDeleteAccount";
import {FormattedMessage} from "react-intl";
import UserPersonalizePDF from "app/pages/common/UserProfile/UserOptions/UserPersonalizePDF";
import UserPersonalizePP from "./UserOptions/UserPersonalizePP";
import UserPersonalizeOffers from "./UserOptions/UserPersonalizeOffers";
import IsPro from "../../../utils/IsPro";
import IsAccountType from "../../../utils/IsAccountType";
import IsAdmin from "../../../utils/IsAdmin";
import ACCOUNT_TYPE from "../../../constants/ACCOUNT_TYPE";

class ProfilePreview extends React.Component {
  render() {
    const {user: {firstName, lastName, email, phoneNumber, team, avatar, isTeamOwner}, onClick, onDelete} = this.props;
    return(
      <Portlet fluidHeight>
        <PortletBody>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Grid container spacing={2}>
                {avatar && (
                <Grid item>
                  <Thumbnail src={avatar} alt={`${firstName}_${lastName}`} size={80}/>
                </Grid>
                )}
                <Grid item>
                  {firstName && lastName &&  <Typography variant="h4" style={{marginBottom: 5}}>{`${firstName} ${lastName}`}</Typography> }
                  <Typography variant="body2">{team !== null && team.name}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item xs={12}>
                  <Table size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell><Typography variant="body2"><FormattedMessage id="FORM.FIELD.EMAIL_ADDRESS"/>:</Typography></TableCell>
                        <TableCell align="right"><Typography>{email}</Typography></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell><Typography variant="body2"><FormattedMessage id="FORM.FIELD.PHONE_NUMBER"/>:</Typography></TableCell>
                        <TableCell align="right"><Typography>{phoneNumber}</Typography></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <NavContainer>
                <NavItem
                  name="editProfile"
                  title={<FormattedMessage id="USER.PHRASE.EDIT_PROFILE"/>}
                  label={<FormattedMessage id="USER.PHRASE.EDIT_PERSONAL_INFORMATION"/>}
                  icon={<IconColorMUI icon="edit" color="neutral"/>}
                  onClick={onClick}
                />
                <IsAdmin>
                  <NavItem
                    name="pdfCustomize"
                    title={<FormattedMessage id="PDF.PHRASE.PERSONALIZE_PDF"/>}
                    label={<FormattedMessage id="PDF.PHRASE.PERSONALIZE_PDF_DESC"/>}
                    icon={<IconColorMUI icon="style" color="neutral"/>}
                    onClick={onClick}
                  />
                </IsAdmin>
                <IsPro>
                  <IsAccountType expectedAccountType={ACCOUNT_TYPE.LANDLORD}>
                    {isTeamOwner && (
                      <NavItem
                          name="propertyParticularsCustomize"
                          title={<FormattedMessage id="PROPERTY_PARTICULARS.PERSONALIZE.TITLE"/>}
                          label={<FormattedMessage id="PROPERTY_PARTICULARS.PERSONALIZE.DESC"/>}
                          icon={<IconColorMUI icon="style" color="neutral"/>}
                          onClick={onClick}
                      />
                    )}
                  </IsAccountType>
                </IsPro>
                <IsPro>
                  {isTeamOwner && (
                    <NavItem
                      name="offersCustomize"
                      title={<FormattedMessage id="OFFERS.PERSONALIZE.TITLE"/>}
                      label={<FormattedMessage id="OFFERS.PERSONALIZE.DESC"/>}
                      icon={<IconColorMUI icon="style" color="neutral"/>}
                      onClick={onClick}
                    />
                  )}
                </IsPro>
                <NavItem
                    name="changePassword"
                    title={<FormattedMessage id="USER.PHRASE.CHANGE_PASSWORD"/>}
                    label={<FormattedMessage id="USER.PHRASE.CHANGE_ACCOUNT_PASSWORD"/>}
                    icon={<IconColorMUI icon="lock" color="warning"/>}
                    onClick={onClick}
                />
                <UserDeleteAccount onDelete={onDelete}/>
              </NavContainer>
            </Grid>
          </Grid>
        </PortletBody>
      </Portlet>
    );
  }
}

export default class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    let currentTab = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
    currentTab = currentTab.split("?")[0];
    this.state = {
      editProfile: currentTab === "edit",
      changePassword: currentTab === "change-password",
      pdfCustomize: currentTab === "pdf-customize",
      propertyParticularsCustomize: currentTab === "pp-online-customize",
      offersCustomize: currentTab === "offers-online-customize"
    };
  }
  handleChangeView = e => {
    this.setState({
      editProfile: false,
      changePassword: false,
      pdfCustomize: false,
      propertyParticularsCustomize: false,
      offersCustomize: false,
      [e.currentTarget.getAttribute('data-name')]: true
    })
  };
  render() {
    const {
      user,
      updateUserData,
      updateUserMarket,
      removeUserImage,
      deleteAccount,
      updateLoading,
      ppBranding,
      loadingPPBranding,
      fetchTeamPropertyParticularsStyles,
      updateTeamPropertyParticularsStyles,
      updateTeamPropertyParticularsLogo,
      offersBranding,
      loadingOffersBranding,
      updatingPPLogo,
      fetchTeamOffersStyles,
      updateTeamOffersStyles,
      updateTeamOffersLogo,
      updatingOffersLogo,
    } = this.props;
    const {editProfile, changePassword, pdfCustomize, propertyParticularsCustomize, offersCustomize} = this.state;

    return(
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <ProfilePreview
              user={user}
              onClick={this.handleChangeView}
              onChangeMarket={updateUserMarket}
              onDelete={deleteAccount}
            />
          </Grid>
          <Grid item xs={7}>
            {editProfile && <UserEditProfile user={user} onSave={updateUserData} onRemoveImage={removeUserImage} updateLoading={updateLoading}/>}
            {changePassword && <UserChangePassword onSave={this.props.changePassword} updateLoading={updateLoading}/>}
            {pdfCustomize && <UserPersonalizePDF user={user} onSave={updateUserData} updateLoading={updateLoading}/>}
            {propertyParticularsCustomize &&
                <UserPersonalizePP
                  data={ppBranding}
                  loading={loadingPPBranding}
                  updatingLogo={updatingPPLogo}
                  teamID={user && user.team && user.team.id}
                  onFetch={fetchTeamPropertyParticularsStyles}
                  onUpdate={updateTeamPropertyParticularsStyles}
                  onUpdateLogo={updateTeamPropertyParticularsLogo}
              />
            }
            {offersCustomize &&
                <UserPersonalizeOffers
                  data={offersBranding}
                  loading={loadingOffersBranding}
                  updatingLogo={updatingOffersLogo}
                  teamID={user && user.team && user.team.id}
                  onFetch={fetchTeamOffersStyles}
                  onUpdate={updateTeamOffersStyles}
                  onUpdateLogo={updateTeamOffersLogo}
              />
            }
          </Grid>
        </Grid>
      </Container>
    );
  }
}
import React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import styles from "./index.module.scss";
import clsx from "clsx";
import {toDateFormat} from "../../../utils/helpers";
import ButtonTooltip from "../../components/ButtonTooltip";
import IsPro from "../../../utils/IsPro";

class UserPremiumButton extends React.Component {
  render() {
    const {user} = this.props;
    const proData = user?.proData;
    const proUntilDate = proData?.proUntil;
    const proIndefinite = proData?.proIndefinite;
    const proUntil = proIndefinite ? <FormattedMessage id="VOCABULARY.Indefinitely"/> :
      <FormattedMessage id="GENERAL.PHRASE.PREMIUM_ACCOUNT_UNTIL" values={{until: toDateFormat(proUntilDate)}}/>;

    if(IsPro({overwriteRule: user?.dummyViewActive})) {
      return(
        <ButtonTooltip
            tPlacement="bottom"
            tOverlay={proUntil}
        >
          <span className={styles.badgePro}>PRO</span>
        </ButtonTooltip>
      );
    }
    return(
      <span className={clsx(styles.badgePro, styles.free)}>BASIC</span>
    );
  }
}

export default injectIntl(connect()(UserPremiumButton));
import React from "react";
import TableCell from "@material-ui/core/TableCell/TableCell";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import BuildingUnitsCountPreview from "app/partials/components/_DataParts/BuildingUnitsCountPreview";
import {Grid, Typography} from "@material-ui/core";
import {TABLE_CELLS} from "app/partials/components/TableProperties/index";
import TableDrawer from "app/partials/components/TableDrawer";
import {
  fetchShortlistParks,
  fetchShortlistParksReset,
  fetchShortlistsIndustrial,
} from "app/crud/estates/industrial/offers-industrial.crud";
import {toDateFormat} from "app/utils/helpers";
import ShareOfferIndustrialOnline from "../../ShareOfferIndustrialOnline/ShareOfferIndustrialOnline";
import ShortlistRemoveIndustrial from "../../Shortlists/ShortlistRemoveIndustrial";
import IconLabel from "../../IconLabel";
import {callReddClickEvent} from "app/crud/user.crud";
import TooltipCustom from "../../TooltipCustom";
import IconColorMUI from "../../IconColorMUI";
import OfferEdit from "../../Shortlists/OfferEdit";

class TableOffersIndustrialCells extends React.Component {
  render() {
    const {
      row,
      fetchShortlistParks,
      shortlistParks,
      isShortlistParksLoading,
      fetchShortlistParksReset,
      fetchShortlists,
      isTeamOwner,
      callReddClickEvent
    } = this.props;
    const isOwnerColumnAvailable = isTeamOwner;

    const constructionFinishRange =
      shortlistParks?.parksReadOnly &&
      shortlistParks.parksReadOnly.reduce(
        (prev, current) => {
          if (current?.dateOfConstructionEnd === null) {
            return null;
          }
          const data = parseInt(current?.dateOfConstructionEnd.split("-")[0]);
          const currentLowest = (order) =>
            parseInt(prev?.[order]?.dateOfConstructionEnd);
          const minDate = Math.min(data, currentLowest(0));
          const maxDate = Math.max(data, currentLowest(1));

          return [minDate, maxDate];
        },
        [
          shortlistParks.parksReadOnly?.[0]?.dateOfConstructionEnd,
          shortlistParks.parksReadOnly?.[1]?.dateOfConstructionEnd,
        ]
      );

    const totalBuildingsCount =
      shortlistParks?.parksReadOnly &&
      shortlistParks.parksReadOnly.reduce(
        (prev, current) => Number(prev) + Number(current?.numberOfWarehouses),
        0
      );
    const inactive = totalBuildingsCount <= 0;

    return (
      <>
        <TableCell component="th" scope="row">
          {row?.name}
          {row?.forTesting &&
            <TooltipCustom
              title={
              <span style={{display: "inline-block", textAlign: "left"}}>
                <strong>Test offer</strong>: Admin Only
                <br/>- "Logged in as" mandatory
                <br/>- Offers Online preview disabled
              </span>
              }
            >
            <IconColorMUI icon="info_outline" color="danger" size="16px" style={{fontSize: 0, marginLeft: 5}}/>
          </TooltipCustom>
          }
        </TableCell>
        {isOwnerColumnAvailable && (
          <TableCell component="th" scope="row">
            <Typography variant="body1">{row.owner}</Typography>
          </TableCell>
        )}
        <TableCell align="right">
            <TableDrawer
              title={
                <Grid container spacing={2} alignItems="center">
                  <Grid item style={{ marginRight: "30px" }}>
                    {shortlistParks?.name}
                  </Grid>
                  <Grid item style={{ marginRight: "10px" }}>
                    <IconLabel
                      direction="row"
                      icon="business"
                      label={
                        <FormattedMessage id="SHORTLIST.PHRASE.NO_OF_PARKS" />
                      }
                      value={row.parks && row.parks.length}
                    />
                  </Grid>
                  <Grid item style={{ marginRight: "10px" }}>
                    <IconLabel
                      direction="row"
                      icon="warehouse"
                      label={
                        <FormattedMessage id="SHORTLIST.PHRASE.BUILDINGS" />
                      }
                      value={totalBuildingsCount}
                      style={{ width: "unset" }}
                    />
                  </Grid>
                  <Grid item style={{ marginRight: "10px" }}>
                    <IconLabel
                      icon="apartment"
                      direction="row"
                      label={
                        <FormattedMessage id="SHORTLIST.PHRASE.BUILDING_CLASSES" />
                      }
                      value={
                        shortlistParks?.buildingClasses
                          .filter((n) => n)
                          .join(", ") || "-"
                      }
                      style={{ width: "unset" }}
                    />
                  </Grid>
                  {constructionFinishRange && totalBuildingsCount ? (
                    <Grid item>
                      <IconLabel
                        icon="apartment"
                        direction="row"
                        label={
                          <FormattedMessage id="SHORTLIST.PHRASE.CONSTRUCTION_FINISH_RANGE" />
                        }
                        value={constructionFinishRange?.join(" - ")}
                        style={{ width: "unset" }}
                      />
                    </Grid>
                  ) : null}
                </Grid>
              }
              headRows={[
                {
                  id: "name",
                  numeric: false,
                  disablePadding: false,
                  label: "OFFERS.PHRASE.PARKS",
                  sort: false,
                },
                {
                  id: "area",
                  numeric: false,
                  disablePadding: false,
                  label: "GENERAL.PHRASE.AREA",
                  sort: false,
                },
                {
                  id: "buildings_amount",
                  numeric: false,
                  disablePadding: false,
                  label: "SHORTLIST.PHRASE.BUILDINGS",
                  sort: false,
                },
                {
                  id: "buildings_type",
                  numeric: false,
                  disablePadding: false,
                  label: "INDUSTRIAL.PHRASE.BUILDING_TYPES",
                  sort: false,
                },
                {
                  id: "available_from",
                  numeric: false,
                  disablePadding: false,
                  label: "SHORTLIST.PHRASE.DETAILS_AVAILABLE_FROM",
                  sort: false,
                },
                {
                  id: "updated_at",
                  numeric: false,
                  disablePadding: false,
                  label: "BUILDING.PHRASE.UPDATED_AT",
                  sort: false,
                },
                {
                  id: "actions",
                  numeric: true,
                  disablePadding: false,
                  label: "",
                  sort: false,
                },
              ]}
              bodyRows={shortlistParks?.parksReadOnly}
              bodyRowsReady={isShortlistParksLoading}
              target={TABLE_CELLS.INDUSTRIAL_SHORTLIST_PARKS}
              parentId={row.id}
              parentPreviewData={row}
              onClick={fetchShortlistParks}
              onClose={fetchShortlistParksReset}
              onRefresh={fetchShortlists}
              onOpen={() => callReddClickEvent(
                "offers-industrial-units-preview-click",
                "offer",
                null,
                row?.id
              )}
            >
              <BuildingUnitsCountPreview
                units={row.parks && row.parks.length}
              />
            </TableDrawer>
          </TableCell>
        <TableCell>{toDateFormat(row?.createdAt)}</TableCell>
        <TableCell>{toDateFormat(row?.lastUpdate)}</TableCell>
        <TableCell align="right">
          <Grid container spacing={2} justifyContent="flex-end" wrap="nowrap">
            <Grid item>
              <ShareOfferIndustrialOnline
                offerID={row?.id}
                offerHash={row?.urlHash}
                offerName={row?.name}
                inactive={inactive}
                directBtn
              />
            </Grid>
            <Grid item>
                <Grid container spacing={0} wrap="nowrap">
                  <Grid item>
                    <OfferEdit
                      offerID={row?.id}
                      offerName={row?.name}
                    />
                  </Grid>
                  <Grid item>
                    <ShortlistRemoveIndustrial
                      offerID={row?.id}
                      offerName={row?.name}
                    />
                  </Grid>
                </Grid>
              </Grid>
          </Grid>
        </TableCell>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  shortlistParks: state.offersIndustrial.shortlistParks,
  isShortlistParksLoading: state.offersIndustrial.isShortlistParksLoading,
  isTeamOwner: state.user.data.isTeamOwner,
});
const mapDispatchToProps = {
  fetchShortlists: ({ params }) => fetchShortlistsIndustrial(params),
  fetchShortlistParks: (shortlistID) => fetchShortlistParks(shortlistID),
  fetchShortlistParksReset: fetchShortlistParksReset,
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params)
};
export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(TableOffersIndustrialCells)
);

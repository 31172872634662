import React from "react";
import axios from "axios";
import {FormattedMessage} from "react-intl";

// FETCH Banner data
export async function fetchBannerData(variant, accountType) {
  if(variant && accountType) {
    const lcAccountType = accountType.toLowerCase();
    const finalAccountType = lcAccountType === "admin" ? "landlord" : lcAccountType;
    const bannerData = await axios.get(`/paywallbanner/${variant}-${finalAccountType}/`);
    const bannerStatus = bannerData.status;

    if(bannerStatus === 200) {
      return bannerData?.data;
    }
    return null;
  }
  return null;
}

// RENDER Banner fields
export function getBannerDataValue(data, field) {
  const lang = localStorage.getItem("langManual");
  const langSuffix = lang === "pl" ? "Pl" : "En";

  if(data && field) {
    if(field === "title") {
        return data?.[`bannerTitle${langSuffix}`];
    }
    else if(field === "description") {
        return data?.[`bannerDescription${langSuffix}`];
    }
    else if(field === "list_1") {
        return data?.[`bannerListFirstPoint${langSuffix}`];
    }
    else if(field === "list_2") {
        return data?.[`bannerListSecondPoint${langSuffix}`];
    }
    else if(field === "list_3") {
        return data?.[`bannerListThirdPoint${langSuffix}`];
    }
    else if(field === "list_4") {
        return data?.[`bannerListFourthPoint${langSuffix}`];
    }
    else if(field === "button") {
        return data?.[`bannerButtonText${langSuffix}`] || <FormattedMessage id="VOCABULARY.Request contact"/>;
    }
    else if(field === "price") {
        return data?.[`bannerPrice${langSuffix}`];
    }
    else {
        return null;
    }
  }
  return null;
}
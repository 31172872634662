import React from "react";
import {FormattedMessage} from "react-intl";
import {callReddClickEvent} from "app/crud/user.crud";
import {fetchIndustrialParkRegionXls} from "app/crud/estates/industrial/industrialPark.crud";
import connect from "react-redux/es/connect/connect";
import PaywallModal from "../../../../partials/components/PayWall/components/PaywallModal";
import IsPro from "../../../../utils/IsPro";
import Button from "../../../../partials/components/Button";
import {Icon} from "@material-ui/core";

function PropertyRegionXlsDownload({basic, xlsLoading, fetchIndustrialParkRegionXls, reddClick}) {
  const handleDownloadXls = () => {
    const officeID = basic?.id;
    const officeName = basic?.name;

    if(!xlsLoading && fetchIndustrialParkRegionXls && officeID) {
      callReddClickEvent(
        reddClick,
        'office',
        null,
        officeID
      );
      fetchIndustrialParkRegionXls(officeID, officeName);
    }
  }

  return(
    <IsPro
      paywall
      paywallComponent={
      <PaywallModal
        triggerBtn={
        <Button
          color={"simple"}
          textIcon={<Icon icon={"download"}/>}
        >
          <FormattedMessage id="LEADS.PHRASE.DOWNLOAD_XLS"/>
        </Button>
        }
      />
      }
    >
      <Button
        color={"simple"}
        textIcon={<Icon icon={"download"}/>}
        loading={xlsLoading}
        onClick={() => handleDownloadXls()}
      >
        <FormattedMessage id="LEADS.PHRASE.DOWNLOAD_XLS"/>
      </Button>
    </IsPro>
  );
}

const mapStateToProps = store => ({
  basic: store.industrialPark.basic,
  xlsLoading: store.industrialPark.xlsLoading
});
const mapDispatchToProps = {
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params),
  fetchIndustrialParkRegionXls: (parkID, propertyName) => fetchIndustrialParkRegionXls(parkID, propertyName)
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertyRegionXlsDownload);
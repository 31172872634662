import React from "react";
import {FormattedMessage} from "react-intl";
import TableCustom from "app/partials/components/TableCustom";
import {toDateFormat, toNumberFormat} from "app/utils/helpers";
import styles from "../../../../../common/external/components/index.module.scss";
import UnitTypePreview from "app/partials/components/_DataParts/UnitTypePreview";
import UnitStatusPreview from "app/partials/components/_DataParts/UnitStatusPreview";
import {UNIT_STATUS} from "app/constants";
import UnitAvailabilityPreview from "app/partials/components/_DataParts/UnitAvailabilityPreview";

export default class OffersOnlineSingleUnits extends React.Component {
  render() {
    const {units, branding} = this.props;
    const tHead = [
      {
        "label": <FormattedMessage id="GENERAL.PHRASE.AREA"/>,
        "number": true
      },
      {
        "label": <FormattedMessage id="GENERAL.PHRASE.ENTRANCE"/>,
        "number": true
      },
      {
        "label": <FormattedMessage id="GENERAL.PHRASE.FLOOR"/>,
        "number": true
      },
      {
        "label": <FormattedMessage id="GENERAL.PHRASE.TYPE"/>,
        "number": true
      },
      {
        "label": <FormattedMessage id="BUILDING.PHRASE.STATUS"/>,
        "number": true
      },
      {
        "label": <FormattedMessage id="BUILDING.PHRASE.AVAILABLE_FROM"/>,
        "number": true
      },
      {
        "label": <FormattedMessage id="BUILDING.PHRASE.UPDATED_AT"/>,
        "number": true
      }
    ];
    const tBody = units && units.map(unit => {
      const { area, entrance, floor, unitType, status, availableFrom, updatedAt, isSublease } = unit;
      return {
        "data": [
          toNumberFormat(area, 'area'),
          entrance ? entrance : '-',
          floor,
          <UnitTypePreview unitType={unitType} isSublease={isSublease}/>,
          <UnitStatusPreview unitStatus={status}/>,
          <UnitAvailabilityPreview date={availableFrom} isReserved={status === UNIT_STATUS.RESERVED} isUnavailable={status === UNIT_STATUS.UNAVAILABLE}/>,
          toDateFormat(updatedAt)
        ]
      }
    });
    if(units.length) {
      return(
          <div ref={this.props.reference} className={styles.boxContainer}>
              <h2><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.AVAILABLE_UNITS"/></h2>
              <TableCustom
                  tHead={tHead}
                  tBody={tBody}
                  tHeadBgColor={branding.brandBgColor}
                  tHeadBgFontColor={branding.brandBgFontColor}
                  thStyle={{fontSize: "1.4rem"}}
                  tdStyle={{fontSize: "1.4rem"}}
                  responsive
              />
          </div>
      );
    }
    return false;
  }
}
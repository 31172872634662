import React from "react";
import {FormattedMessage} from "react-intl";
import FinneCrossSelling from "../../../../partials/components/FinneCrossSelling";
import {fetchLead} from "../../../../crud/leadsManagement.crud";
import connect from "react-redux/es/connect/connect";
import Icon from "../../../../partials/components/Icon";
import Button from "../../../../partials/components/Button";

class LeadsManagementProperties extends React.Component {
  render() {
    const {leadID, fetchLead, lead, loadingLead, removingProperty} = this.props;

    return(
      <FinneCrossSelling
        btnTrigger={
        <Button
          type={"icon"}
          tooltip={<FormattedMessage id="LM.PHRASE.MANAGE_PROPERTIES"/>}
          color={"simple"}
        >
          <Icon icon={"list-edit"}/>
        </Button>
        }
        modalTitle={<FormattedMessage id="LM.PHRASE.MANAGE_PROPERTIES" />}
        mode="properties"
        data={lead}
        loading={loadingLead}
        removing={removingProperty}
        onOpen={() => fetchLead(leadID)}
      />
    );
  }
}

const mapStateToProps = ({ leadsManagement }) => ({
  lead: leadsManagement?.lead,
  loadingLead: leadsManagement?.loadingLead,
  removingProperty: leadsManagement?.removing
});
const mapDispatchToProps = {
  fetchLead: (leadID) => fetchLead(leadID)
};
export default connect(mapStateToProps, mapDispatchToProps)(LeadsManagementProperties);
import {connect} from "react-redux";
import CompetitionAnalysis from "./CompetitionAnalysis";
import {
  compareSelectedOffices,
  compareSelectedOfficesReset,
  fetchOfficesSimpleList
} from "../../../crud/estates/offices/offices.crud";
import {
  compareSelectedParks,
  compareSelectedParksReset,
  fetchIndustrialParksSimpleList
} from "app/crud/estates/industrial/industrial.crud";
import {callReddClickEvent} from "app/crud/user.crud";

const mapStateToProps = ({ user, offices, industrial }) => ({
  user: user.data,
  currentApplicationView: user?.data?.currentApplicationView,
  offices: offices?.officesSimpleList,
  officesOwned: offices?.officesOwnedSimpleList,
  loading: offices?.officesSimpleListLoading,
  officesCompareResults: offices?.officesCompareResults,
  officesComparing: offices?.officesComparing,
  parks: industrial?.parksSimpleList,
  parksOwned: industrial?.parksOwnedSimpleList,
  loadingParks: industrial?.parksSimpleListLoading,
  parksCompareResults: industrial?.parksCompareResults,
  parksComparing: industrial?.parksComparing,
});
const mapDispatchToProps = {
  fetchOfficesSimpleList: ({params}, owned) => fetchOfficesSimpleList(params, owned),
  compareSelectedOffices: (data, inBackground) => compareSelectedOffices(data, inBackground),
  compareSelectedOfficesReset: () => compareSelectedOfficesReset(),
  fetchIndustrialParksSimpleList: ({params}, owned) => fetchIndustrialParksSimpleList(params, owned),
  compareSelectedParks: (data, inBackground) => compareSelectedParks(data, inBackground),
  compareSelectedParksReset: () => compareSelectedParksReset(),
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params)
};

const withRedux = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withRedux(CompetitionAnalysis);

// FETCH ACTIONS
export const fetchAcquisitions = (params) => ({
  type: 'FETCH_ACQUISITIONS_REQUEST',
  payload: {
    params
  },
});
export const fetchAcquisitionsReset = (initOrderBy) => ({
  type: 'FETCH_ACQUISITIONS_RESET_REQUEST',
  payload: initOrderBy
});

// RESET ACQUISITIONS LOADING STATES
export const resetAcquisitionsLoadingStates = () => ({
  type: 'RESET_ACQUISITIONS_LOADING_STATES_REQUEST'
});
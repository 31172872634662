import React from 'react';
import {TextField} from '@material-ui/core';

export default class InputMultiline extends React.Component {
  render() {
    const {label, name, value, rowsMax, minRows, onChange, helperText, disabled} = this.props;
    return(
      <TextField
        name={name || ''}
        label={label}
        multiline
        minRows={minRows}
        maxRows={rowsMax || 4}
        value={value}
        onChange={onChange}
        helperText={helperText || false}
        fullWidth
        disabled={disabled}
      />
    );
  }
}
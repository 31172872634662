import React from "react";
import {withRouter} from "react-router-dom";
import ListView from "../components/ListView/ListView";
import {FormattedMessage} from "react-intl";
import {HEAD_ROWS, TABLE_CELLS} from "../../../partials/components/TableProperties";
import store from "../../../store/store";
import IsPro from "../../../utils/IsPro";

let prevLocation; // Hold previous route 'pathname'
class AcquisitionsPage extends React.Component {
  constructor(props) {
    super(props);
    // Set previous route 'pathname'
    props.history.listen(nextLocation => {
      prevLocation = nextLocation.pathname;
    });
    this.state = {
      page: 1,
      initLoaded: false
    };
  }
  componentDidMount() {
    const {setLatestActiveTablePage, filterReset} = this.props;
    const updateData = prevLocation === undefined || (!prevLocation.includes('/owner/') && !prevLocation.includes('/building/'));
    if(updateData) {
      setLatestActiveTablePage(0);
      filterReset(null, null, true);
    }
  }

  // Handle fetch data on enter viewport
  handleOnViewportFetch = (isVisible) => {
    const {fetchVisibleOwners, fetchAcquisitions, loading, callReddClickEvent} = this.props;
    const {initLoaded} = this.state;
    // Check if Acquisitions data should be update based on previous location
    const updateData = prevLocation === undefined || (!prevLocation.includes('/owner/') && !prevLocation.includes('/building/'));

    if(isVisible && !loading && !initLoaded) {
      this.setState({
        initLoaded: true
      }, () => {
        if(updateData) {
          fetchVisibleOwners();
          // Params to update data: new page number, all selected filters, order by price desc
          const params = {
            page: this.state.page,
            ...store.getState().filters,
            order: '-',
            orderBy: 'transaction_date'
          };
          fetchAcquisitions({params});
          // Track entrance
          callReddClickEvent(
            "acquisitions-page-visit",
            "acquisition"
          );
        }
      });
    }
  };
  handleOnViewport = (isInViewport) => {
    this.handleOnViewportFetch(isInViewport);
  }

  render() {
    const {
      items,
      itemsPerPage,
      itemsCount,
      totalItems,
      itemsPage,
      loading
    } = this.props;
    // Validate Acquisitions items, if is Array return items else return empty Array
    const acquisitionsItems =  Array.isArray(items) ? items : [];

    return (
      <IsPro paywall>
        <ListView
          tableLabel={<FormattedMessage id="MENU.ACQUISITIONS"/>}
          tableHeadRows={HEAD_ROWS.ACQUISITIONS}
          tableBodyRows={acquisitionsItems}
          tableDataSource="acquisitions"
          tableDataTarget={TABLE_CELLS.ACQUISITIONS}
          itemsPage={itemsPage}
          itemsPerPage={itemsPerPage}
          itemsCount={itemsCount}
          totalItems={totalItems}
          loading={loading}
          filterBarEnable
          handleFetchData={this.props.fetchAcquisitions}
          onEnterViewport={this.handleOnViewport}
        />
      </IsPro>
    )
  }
}

export default withRouter(AcquisitionsPage);
import React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {
  fetchPropertyParticularsOffices,
  setPropertyParticularsOfficesAsVisible,
} from "../../../crud/propertyParticulars/offices.crud";
import {
  fetchPropertyParticularsPropertiesListManage,
  setPropertyParticularsPropertiesAsVisible,
} from "app/crud/propertyParticulars/industrial.crud";
import SelectSearch from "../SelectSearch";
import ModalCustom from "../ModalCustom";
import {createVisibleArray, isArraysMatch, sortArrayAlph,} from "../../../utils/helpers";
import LoadingScreen from "../LoadingScreen";
import {callReddClickEvent} from "app/crud/user.crud";
import APPLICATION_VIEWS from "../../../constants/APPLICATION_VIEWS";
import Button from "../Button";

class PropertyParticularsManageProperties extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      open: false,
      initLoaded: false,
      visibleProperties: [],
    };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { target, ppOffices, ppParks } = this.props;
    const isIndustrialTarget = target === "industrial";
    const ppData = isIndustrialTarget ? ppParks : ppOffices;
    const selectedProperties = ppData
      ? ppData.filter((property) => {
          return property.ppVisible;
        })
      : [];
    const sortedProperties = selectedProperties
      ? createVisibleArray(selectedProperties, "id")
      : [];
    if (
      !this.state.initLoaded &&
      prevState.visibleProperties &&
      !prevState.visibleProperties.length &&
      !isArraysMatch(prevState.visibleProperties, sortedProperties)
    ) {
      this.setState({
        visibleProperties: sortedProperties,
        initLoaded: true,
      });
    }
  }

  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleAutocompleteChange = (target) => {
    this.setState({
      [target.name]: target.value,
    });
  };
  handleSave = () => {
    const {
      target,
      teamID,
      setPropertyParticularsOfficesAsVisible,
      setPropertyParticularsPropertiesAsVisible,
    } = this.props;
    const isIndustrialTarget = target === "industrial";
    const { visibleProperties } = this.state;

    if (isIndustrialTarget) {
      setPropertyParticularsPropertiesAsVisible(teamID, visibleProperties);
    } else {
      setPropertyParticularsOfficesAsVisible(teamID, visibleProperties);
    }
    this.handleCloseModal();
  };

  render() {
    const {
      target,
      ppOffices,
      ppOfficesLoading,
      ppParks,
      ppParksLoading,
      callReddClickEvent,
      currentApplicationView,
      teamID,
      fetchPropertyParticularsOffices,
      fetchPropertyParticularsPropertiesListManage
    } = this.props;
    const { visibleProperties } = this.state;
    const isIndustrialTarget = target === "industrial";
    const ppData = isIndustrialTarget ? ppParks : ppOffices;
    const ppDataLoading = isIndustrialTarget
      ? ppParksLoading
      : ppOfficesLoading;
    const sortedProperties = ppData
      ? sortArrayAlph(createVisibleArray(ppData, "name"))
      : [];
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    const isIndustrialView = currentApplicationView === APPLICATION_VIEWS.INDUSTRIAL;

    return (
      <ModalCustom
        ref={this.child}
        btn={
        <Button color={"warning"}>
          <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.MANAGE_PP" />
        </Button>
        }
        title={
          <FormattedMessage
            id={
              isIndustrialTarget
                ? "PROPERTY_PARTICULARS.PHRASE.VISIBLE_PARKS"
                : "PROPERTY_PARTICULARS.PHRASE.VISIBLE_OFFICES"
            }
          />
        }
        handleSave={this.handleSave}
        onOpen={() => {
          if (teamID) {
            if (isIndustrialTarget) {
              fetchPropertyParticularsPropertiesListManage(teamID);
            }
            else {
              fetchPropertyParticularsOffices(teamID);
            }
          }
          isOfficeView && callReddClickEvent(
            "property-particulars-manage-pp-button",
            "team"
          );
          isIndustrialView && callReddClickEvent(
            "property-particulars-industrial-manage-pp-button",
            "team"
          );
        }}
      >
        {ppDataLoading ? (
          <LoadingScreen />
        ) : (
          <SelectSearch
            name="visibleProperties"
            value={visibleProperties}
            label={
              <FormattedMessage
                id={
                  isIndustrialTarget
                    ? "PROPERTY_PARTICULARS.PHRASE.VISIBLE_PARKS"
                    : "PROPERTY_PARTICULARS.PHRASE.VISIBLE_OFFICES"
                }
              />
            }
            options={sortedProperties}
            optionsFull={ppData}
            optionFullLabel="name"
            optionFullValue="id"
            onChange={this.handleAutocompleteChange}
            multiple
          />
        )}
      </ModalCustom>
    );
  }
}

const mapStateToProps = (state) => ({
  ppOffices: state.ppOnlineOffices.ppOffices,
  ppOfficesLoading: state.ppOnlineOffices.ppOfficesLoading,
  ppVisibleOffices: state.ppOnlineOffices.ppVisibleOffices,
  ppVisibleOfficesLoading: state.ppOnlineOffices.ppVisibleOfficesLoading,
  ppParks: state.ppOnlineIndustrial.ppPropertiesManageList,
  ppParksLoading: state.ppOnlineIndustrial.ppPropertiesManageListLoading,
  ppVisibleParks: state.ppOnlineIndustrial.ppVisibleProperties,
  ppVisibleParksLoading: state.ppOnlineIndustrial.ppVisiblePropertiesLoading,
  currentApplicationView: state?.user?.data?.currentApplicationView
});
const mapDispatchToProps = {
  fetchPropertyParticularsOffices: (teamID) =>
    fetchPropertyParticularsOffices(teamID),
  setPropertyParticularsOfficesAsVisible: (teamID, data) =>
    setPropertyParticularsOfficesAsVisible(teamID, data),
  fetchPropertyParticularsPropertiesListManage: (teamID) =>
    fetchPropertyParticularsPropertiesListManage(teamID),
  setPropertyParticularsPropertiesAsVisible: (teamID, data) =>
    setPropertyParticularsPropertiesAsVisible(teamID, data),
  callReddClickEvent: (clickType, contentType) => callReddClickEvent(clickType, contentType)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PropertyParticularsManageProperties)
);

import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {put, takeLatest} from "redux-saga/effects";
import axios from "axios";
import {actionNotification, errorMessageData} from "app/utils/notifications";
import {handleErrorResponse} from "app/utils/helpers";

const initialState = {
  user: undefined,
  invitedUser: undefined,
  authToken: undefined,
  loading: false,
  userCreated: false,
  accountRequestCreated: false,
  oneTimeLoginMessage: null
};

const generateRandomBrowserStamp = (length) => {
   let result = '';
   const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
   const charactersLength = characters.length;
   for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }
   return result;
};

export const reducer = persistReducer(
  { storage, key: "authApp" },
  (state = initialState, action) => {
    switch (action.type) {
      // RESET AUTH APP LOADING STATES
      case 'RESET_AUTH_APP_LOADING_STATES_REQUEST': {
        return {
          ...state,
          loading: false
        };
      }
      case 'AUTH_FETCH_USER_BY_TOKEN_SUCCESS': {
        return {...state, user: action.payload};
      }
      case 'AUTH_FETCH_INVITED_USER_REQUEST': {
        return {...state, invitedUser: undefined};
      }
      case 'AUTH_FETCH_INVITED_USER_SUCCESS': {
        return {...state, invitedUser: action.payload};
      }
      case 'AUTH_FETCH_INVITED_USER_FAILED': {
        return {...state, invitedUser: undefined};
      }
      case 'AUTH_ONE_TIME_LOGIN_REQUEST': {
        return {
          ...state,
          loading: true,
          oneTimeLoginMessage: null
        };
      }
      case 'AUTH_LOGIN_REQUEST': {
        return { ...state, loading: true};
      }
      case 'AUTH_LOGIN_SUCCESS': {
        return {
          ...state,
          authToken: action.payload,
          loading: false
        };
      }
      case 'AUTH_LOGIN_FAILED': {
        return {
          ...state,
          authToken: undefined,
          loading: false,
          oneTimeLoginMessage: action.payload
        };
      }
      case 'AUTH_REGISTER_REQUEST': {
        return {...state, accountRequestCreated: false};
      }
      case 'AUTH_REGISTER_SUCCESS': {
        return {...state, accountRequestCreated: true};
      }
      case 'AUTH_REGISTER_FAILED': {
        return {...state, accountRequestCreated: false};
      }
      case 'AUTH_REGISTER_CREATE_USER_REQUEST': {
        return {...state, userCreated: false};
      }
      case 'AUTH_REGISTER_CREATE_USER_SUCCESS': {
        return {...state, userCreated: true};
      }
      case 'AUTH_REGISTER_CREATE_USER_FAILED': {
        return {...state, userCreated: false};
      }

      default:
        return state;
    }
  }
);

// FETCH DATA
function* fetchUserByToken() {
  try {
    // Authorization head should be fulfilled in interceptor.
    const userData = yield axios.get('/auth/user/');
    yield put({ type: "AUTH_FETCH_USER_BY_TOKEN_SUCCESS", payload: userData?.data });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchInvitedUser(action) {
  try {
    // Authorization head should be fulfilled in interceptor.
    const userData = yield axios.get('/team-members/me/', {headers: {Authorization: `Token ${action.payload}`}});
    yield put({ type: "AUTH_FETCH_INVITED_USER_SUCCESS", payload: userData?.data });
  }
  catch(err) {
    yield put({
      type: "AUTH_FETCH_INVITED_USER_FAILED"
    });
  }
}
function* resetAuthLoading() {
  try {
    yield put({ type: "AUTH_LOGIN_FAILED" });
  }
  catch(err) {
    console.log(err);
  }
}

// USER ACTIONS
function* authLogin(action) {
  try {
    const {email, password} = action.payload;
    const userToken = yield axios.post('/auth/login/', { email, password });
    yield put({
      type: "AUTH_LOGIN_SUCCESS",
      payload: userToken?.data?.key
    });
    const initUserData = yield axios.get('/auth/user/');
    if(!initUserData?.data?.loggedInAs) {
      const randBrowserStamp = generateRandomBrowserStamp(24);
      localStorage.setItem("browserStamp", randBrowserStamp);
      yield axios.patch('/auth/user/', {browserStamp: randBrowserStamp, forceLogOut: false});
    }
    const userData = yield axios.get('/auth/user/');
    yield put({
      type: "AUTH_FETCH_USER_BY_TOKEN_SUCCESS",
      payload: userData?.data
    });
  }
  catch(err) {
    yield put({
      type: "AUTH_LOGIN_FAILED",
      payload: err,
      meta: actionNotification(handleErrorResponse(err?.data?.nonFieldErrors[0] || err?.data?.detail || 'Oops, something went wrong! Try again later.'), 'error')
    });
  }
}
function* authOneTimeLogin(action) {
  try {
    const {otp, recipient, url} = action.payload;
    const userToken = yield axios.post(`/auth/user/login-otp/${otp}`, { email: recipient });
    yield put({
      type: "AUTH_LOGIN_SUCCESS",
      payload: userToken.data?.key
    });
    const initUserData = yield axios.get('/auth/user/');
    if(!initUserData?.data?.loggedInAs) {
      const randBrowserStamp = generateRandomBrowserStamp(24);
      localStorage.setItem("browserStamp", randBrowserStamp);
      yield axios.patch('/auth/user/', {browserStamp: randBrowserStamp, forceLogOut: false});
    }
    const userData = yield axios.get('/auth/user/');
    yield put({
      type: "AUTH_FETCH_USER_BY_TOKEN_SUCCESS",
      payload: userData?.data
    });
    // Redirect
    if(url) {
      window.location.href = url;
    }
    else {
      window.location.href = "/";
    }
  }
  catch(err) {
    yield put({
      type: "AUTH_LOGIN_FAILED",
      payload: err?.data?.error
    });
  }
}
function* authRegister(action) {
  try {
    const params = action.payload;
    const userRegister = yield axios.post('/account-requests-v2/', params );
    yield put({
      type: "AUTH_REGISTER_SUCCESS",
      payload: userRegister?.data
    });
  }
  catch(err) {
    yield put({
      type: "AUTH_REGISTER_FAILED",
      payload: err.status,
      meta: actionNotification(errorMessageData(err?.data), 'error')
    });
  }
}
function* authResetPassword(action) {
  try {
    const email = action.payload;
    yield axios.post('/auth/password/reset/', { email } );
    yield put({
      type: "AUTH_RESET_PASSWORD_SUCCESS"
    });
  }
  catch(err) {
    yield put({
      type: "AUTH_RESET_PASSWORD_FAILED",
      payload: err.status,
      meta: actionNotification(errorMessageData(err?.data), 'error')
    });
  }
}
function* authRegisterCreateUser(action) {
  try {
    const data = action.payload;
    const userCreate = yield axios.post('/account-requests-v2/create_user/', data );
    yield put({ type: "AUTH_REGISTER_CREATE_USER_SUCCESS", payload: userCreate?.data });
  }
  catch(err) {
    yield put({
      type: "AUTH_REGISTER_CREATE_USER_FAILED",
      payload: err.status,
      meta: actionNotification(errorMessageData(err?.data), 'error')
    });
  }
}

// BACKGROUND ACTIONS
function* leadTracking(action) {
  try {
    const {checksum, interaction, url} = action.payload;
    yield axios.post(`/interaction/${checksum}`, {interaction, url});
    window.location.href = url;
  }
  catch(err) {
    console.log(err);
  }
}

export function* saga() {
  yield takeLatest('AUTH_FETCH_USER_BY_TOKEN_REQUEST', fetchUserByToken);
  yield takeLatest('AUTH_FETCH_INVITED_USER_REQUEST', fetchInvitedUser);
  yield takeLatest('AUTH_LOGIN_RESET_REQUEST', resetAuthLoading);
  yield takeLatest('AUTH_LOGIN_REQUEST', authLogin);
  yield takeLatest('AUTH_ONE_TIME_LOGIN_REQUEST', authOneTimeLogin);
  yield takeLatest('AUTH_REGISTER_REQUEST', authRegister);
  yield takeLatest('AUTH_RESET_PASSWORD_REQUEST', authResetPassword);
  yield takeLatest('AUTH_REGISTER_CREATE_USER_REQUEST', authRegisterCreateUser);
  yield takeLatest('LEAD_TRACKING_REQUEST', leadTracking);
}
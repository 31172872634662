import React from "react";
import {Link} from "react-router-dom";
import {Formik} from "formik";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {TextField} from "@material-ui/core";
import clsx from "clsx";
import {authLogin} from "app/crud/authApp.crud";
import {isMobile} from "app/utils/helpers";
import styles from "./index.module.scss";
import {toAbsoluteUrl} from "../../../_metronic";

function Login(props) {
  const { intl, authLogin, loading } = props;
  const isMobileTrue = isMobile(window.innerWidth);

  return (
    <>
      <div className="kt-login__body" style={{height: isMobileTrue ? "auto" : "inherit"}}>
        <div className="kt-login__form">
          <div className="kt-login__title" style={{marginBottom: isMobileTrue ? 0 : "inherit"}}>
            <h3>
              <FormattedMessage id="AUTH.LOGIN.TITLE" />
            </h3>
          </div>

          <Formik
            initialValues={{
              email: "",
              password: ""
            }}
            validate={values => {
              const errors = {};

              // Email address is required
              if (!values.email) {
                errors.email = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = intl.formatMessage({
                  id: "AUTH.VALIDATION.INVALID_FIELD"
                });
              }

              // Password is required
              if (!values.password) {
                errors.password = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              }

              return errors;
            }}
            onSubmit={(values) => {
              // Log In common on success
              authLogin(values.email, values.password);
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit
            }) => (
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                {status && (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                )}

                <div className="form-group">
                  <TextField
                    type="email"
                    label={intl.formatMessage({
                      id: "FORM.FIELD.EMAIL_ADDRESS"
                    })}
                    margin="normal"
                    className={styles.customInput}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    helperText={touched.email && errors.email}
                    error={Boolean(touched.email && errors.email)}
                  />
                </div>

                <div className="form-group">
                  <TextField
                    type="password"
                    margin="normal"
                    label={intl.formatMessage({
                      id: "FORM.FIELD.PASSWORD"
                    })}
                    className={styles.customInput}
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    helperText={touched.password && errors.password}
                    error={Boolean(touched.password && errors.password)}
                  />
                </div>

                <div className={styles.contentActions}>
                  <Link
                    to="/auth/forgot-password"
                    className="kt-link kt-login__link-forgot"
                  >
                    <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                  </Link>

                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={loading}
                    className={clsx("btn btn-primary btn-elevate kt-login__btn-primary", styles.btnSubmit, loading && styles.inactive)}
                  >
                    <FormattedMessage id="FORM.BUTTON.SIGN_IN" />
                  </button>
                  <img src={toAbsoluteUrl("media/branding/redd-loader.gif")} alt="REDD Loader" className={clsx(styles.btnLoader, loading && styles.active)}/>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (store) => ({
  loading: store.authApp.loading
});
const mapDispatchToProps = {
  authLogin: (email, password) => authLogin(email, password)
};
export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Login)
);

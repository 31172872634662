import React from 'react';
import {Typography} from "@material-ui/core";
import ModalCustom from "app/partials/components/ModalCustom";
import {deleteSubscriber} from "app/crud/subscribers.crud";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {callReddClickEvent} from "app/crud/user.crud";
import Icon from "../Icon";
import Button from "../Button";

class SubscriberRemove extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
  }
  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleSave = () => {
    const {deleteSubscriber, subscriberId} = this.props;
    deleteSubscriber({subscriberId});
    this.handleCloseModal();
  };

  render() {
    const {callReddClickEvent} = this.props;
    return(
      <ModalCustom
        ref={this.child}
        btn={
        <Button
          type={"icon"}
          tooltip={<FormattedMessage id="SUBSCRIBERS.PHRASE.REMOVE_SUBSCRIBER"/>}
        >
          <Icon icon={"trash"}/>
        </Button>
        }
        title={<FormattedMessage id="SUBSCRIBERS.PHRASE.REMOVE_SUBSCRIBER"/>}
        handleSave={this.handleSave}
        btnReject={<FormattedMessage id="GENERAL.PHRASE.CANCEL"/>}
        btnConfirm={<FormattedMessage id="GENERAL.PHRASE.REMOVE"/>}
        btnInline
        onOpen={() => callReddClickEvent(
          "subscribers-remove-button",
          "subscriber"
        )}
      >
        <Typography><FormattedMessage id="SUBSCRIBERS.PHRASE.REMOVE_SUBSCRIBER_MESSAGE"/> <br/><FormattedMessage id="GENERAL.PHRASE.ACTION_CONFIRMATION_MESSAGE"/></Typography>
      </ModalCustom>
    );
  }
}

const mapDispatchToProps = {
  deleteSubscriber: subscriberId => deleteSubscriber(subscriberId),
  callReddClickEvent: (clickType, contentType) => callReddClickEvent(clickType, contentType)
};

export default injectIntl(
  connect(
    null,
    mapDispatchToProps
  )(SubscriberRemove)
);
import React from "react";
import styles from "./index.module.scss";

import PaywallBannerDefault from "./banners/PaywallBannerDefault";
import PaywallDirectContact from "./components/PaywallDirectContact";

const PayWall = ({style}) => {
  return(
    <div className={styles.paywallContainer} style={style}>
      <div className={styles.paywallWrapper}>
        <PaywallBannerDefault
          bannerVariant="default"
        />
        <PaywallDirectContact/>
      </div>
    </div>
  );
};

export default PayWall;
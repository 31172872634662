import React from "react";
import ModalCustom from "app/partials/components/ModalCustom";
import {Typography} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {PDFViewer} from "@react-pdf/renderer";
import {callReddClickEvent, fetchTeamBuildings} from "app/crud/user.crud";
import LoadingScreen from "app/partials/components/LoadingScreen";
import LandlordBuildingsPdfDocument from "./LandlordBuildingsPdfDocument";
import Button from "../../../../partials/components/Button";

class LandlordBuildingsGeneratePDF extends React.Component {
  constructor(props){
    super(props);
    this.child = React.createRef();
  }
  render() {
    const {fetchTeamBuildings, teamBuildings, teamBuildingsLoading, user, inactive} = this.props;
    const teamId = user.team ? user.team.id : null;
    // Button displayed when modal is inactive
    if(inactive) {
      return(
        <Button inactive>
          <FormattedMessage id="SHORTLIST.PHRASE.GENERATE_PDF"/>
        </Button>
      );
    }
    return (
      <ModalCustom
        ref={this.child}
        btn={
        <Button
          color={"success"}
          onClick={() => fetchTeamBuildings(teamId)}
        >
          <FormattedMessage id="SHORTLIST.PHRASE.GENERATE_PDF"/>
        </Button>
        }
        title={
          <FormattedMessage
            id="SHORTLIST.PHRASE.GENERATE_PDF"
            values={{
              breakingLine: <br/>,
              subtitle: <Typography variant="body2"><FormattedMessage id="GENERAL.PHRASE.MONTHLY"/></Typography>}}
          />
        }
        btnReject={<FormattedMessage id="GENERAL.PHRASE.CLOSE"/>}
        maxWidth="lg"
        onOpen={() => this.props.dispatch(callReddClickEvent(
          "landlord-properties-generate-pdf-click",
          "user"
        ))}
      >
        {!teamBuildingsLoading && teamBuildings ? (
          <div>
            {/* Loading screen will be hidden behind PDF when its ready to display */}
            <LoadingScreen absolute/>
            {/* PDFViewer allways must wrap PDF content */}
            <PDFViewer style={{width: 1200, height: "80vh", border: 0, position: "relative"}}>
              <LandlordBuildingsPdfDocument data={teamBuildings} user={user} intl={this.props.intl}/>
            </PDFViewer>
          </div>
        ) : <LoadingScreen/>}
      </ModalCustom>
    );
  }
}

const mapStateToProps = (store) => ({
  teamBuildings: store.user.teamBuildings,
  teamBuildingsLoading: store.user.teamBuildingsLoading,
  user: store.user.data
});
const mapDispatchToProps = dispatch => ({
  fetchTeamBuildings: (teamId) => dispatch(fetchTeamBuildings(teamId)),
  dispatch
});
export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LandlordBuildingsGeneratePDF)
);
import React from "react";
import {FormattedMessage} from "react-intl";
import axios from "axios";
import NoData from "app/partials/components/NoData/NoData";
import LoadingScreen from "app/partials/components/LoadingScreen";

export default class SubscriberSubscribePage extends React.Component {
  state = {
    loading: true,
    error: null
  };
  componentDidMount() {
    const { match } = this.props;
    const { token } = match.params;

    // Patch Account Request based on accepted/rejected action
    axios
      .get(`/subscribers-v2/accept/${token}`)
      .then(() => {
        this.setState({
          loading: false
        });
      })
      .catch((err) => {
        this.setState({
          error: err
        })
      });
  }
  render() {
    const {loading, error} = this.state;
    // Display error message if provided
    if(error !== null) {
      if(error.status === 404) {
        return <NoData message={`${error.status} Provided Token is incorrect.`}/>;
      }
      return <NoData message={`${error.status} ${error.statusText}`}/>;
    }
    // Loading screen
    else if(loading) {
      return <LoadingScreen/>;
    }
    // Show Success message if Patch without error
    return <NoData message={<FormattedMessage id="AUTH.PHRASE.SUBSCRIBER_ACTIVATED"/>}/>;
  }
}
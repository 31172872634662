import React from "react";
import PropertyParticularsList
  from "app/pages/industrial/external/PropertyParticularsPage/components/PropertyParticularsList/PropertyParticularsList";
import PropertyParticularsAuth
  from "app/pages/industrial/external/PropertyParticularsPage/components/PropertyParticularsAuth";
import styles from "../components/index.module.scss";
import LoadingScreen from "../../../../partials/components/LoadingScreen";
import {getParameterByName, handleEmptyValue} from "../../../../utils/helpers";
import PropertyParticularsSingle from "./components/PropertyParticularsSingle/PropertyParticularsSingle";

export default class PropertyParticulars extends React.Component {
    constructor(props) {
        super(props);
        // Set custom HTML font-size
        const html = document.querySelector('html');
        const windowWidth = window.outerWidth;
        if (windowWidth >= 2300) {
            html.style.fontSize = '12px';
        } else {
            html.style.fontSize = '10px';
        }
        // Fetch branding
        const teamID = props.match.params.team;
        props.fetchPropertyParticularsBranding(teamID);
        // Share PP Log
        const realTimeMailSourceUrl = window.location.href;
        const userPkSharing = handleEmptyValue(getParameterByName('userpksharing', realTimeMailSourceUrl), true);
        const userSharingEmail = handleEmptyValue(getParameterByName('usersharingemail', realTimeMailSourceUrl));
        const userToSendEmail = handleEmptyValue(getParameterByName('usertosendemail', realTimeMailSourceUrl));
        const token = handleEmptyValue(getParameterByName('token', realTimeMailSourceUrl), true);
        const time = handleEmptyValue(getParameterByName('time', realTimeMailSourceUrl), true);
        const updated = handleEmptyValue(getParameterByName('updated', realTimeMailSourceUrl), true);

        if (userPkSharing && userSharingEmail && userToSendEmail && token && time) {
            const data = {
                userPkSharing,
                userSharingEmail,
                userToSendEmail,
                token,
                time
            };

            if(updated) {
                props.shareUpdatedPropertyParticularsRealTimeLog(data);
            }
            else {
                props.sharePropertyParticularsRealTimeLog(data);
            }
        }
    }

    render() {
        const primaryColor = "#FF4626";
        const secondaryColor = "#ffffff";
        const {
            intl,
            match,
            history,
            hasAuthToken,
            destroyNotification,
            notifications: {message, type, isOpen},
            ppProperties,
            loadingProperties,
            ppBranding,
            loadingBranding,
            isNotPro,
            ppPhotos,
            loadingPhotos,
            fetchPropertyParticulars,
            fetchPropertyParticularsListPhotos,
            fetchPropertyParticularsProperty,
            fetchPropertyParticularsPropertyContact,
            ppProperty,
            loadingProperty,
            ppContact,
            loadingContact
        } = this.props;
        const propertyID = match && match.params && match.params.park;

        if (loadingBranding || !ppBranding) {
            return <LoadingScreen brand/>;
        }
        return (
            <div
                className={styles.propertyParticularsContainer}
                style={{
                    "--brand-bg-color": ppBranding.brandBgColor || primaryColor,
                    "--brand-bg-font-color": ppBranding.brandBgFontColor || secondaryColor,
                    "--brand-font-color": ppBranding.brandFontColor || primaryColor,
                    "--brand-btn-bg-color": ppBranding.brandBtnBgColor || primaryColor,
                    "--brand-btn-bg-font-color": ppBranding.brandBtnBgFontColor || secondaryColor
                }}
            >
                {/* Render proper content based on autentification */}
                {(hasAuthToken || localStorage.getItem("ppMail")) ?
                    (propertyID ?
                            <PropertyParticularsSingle
                                match={match}
                                history={history}
                                data={ppProperty}
                                loading={loadingProperty}
                                intl={intl}
                                branding={ppBranding}
                                isNotPro={isNotPro}
                                contact={ppContact}
                                loadingContact={loadingContact}
                                onFetchData={fetchPropertyParticularsProperty}
                                onFetchContact={fetchPropertyParticularsPropertyContact}
                            /> :
                            <PropertyParticularsList
                                match={match}
                                items={ppProperties}
                                loading={loadingProperties}
                                photos={ppPhotos}
                                loadingPhotos={loadingPhotos}
                                branding={ppBranding}
                                isNotPro={isNotPro}
                                onFetchData={fetchPropertyParticulars}
                                onFetchPhotos={fetchPropertyParticularsListPhotos}
                            />
                    ) :
                    <PropertyParticularsAuth
                        match={match}
                        message={message}
                        type={type}
                        isOpen={isOpen}
                        destroyNotification={destroyNotification}
                    />}
            </div>
        );
    }
}
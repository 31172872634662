import {toAbsoluteUrl} from "_metronic";
import {Link as RouterLink} from "react-router-dom";
import {ROUTES} from "app/constants";
import {Grid, Typography} from "@material-ui/core";
import React from "react";
import {Avatar} from "app/partials/components/Avatar";

export default class OwnerCardPreview extends React.Component {
  renderPreview = () => {
    const {name, street, thumb, thumbLogo, margin, ownerID} = this.props;
    const avatar = thumb ? thumb : thumbLogo ? null : toAbsoluteUrl("media/office-placeholder.jpg");
    return (
      <Grid container justifyContent="flex-start" alignItems="center" wrap="nowrap" spacing={1}>
        {thumb !== "disabled" && avatar &&
        <Grid item>
          <Avatar avatar={avatar} margin={margin}/>
        </Grid>
        }
        <Grid item>
          <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
            <Typography variant="h4" color={ownerID ? "primary" : 'initial'}>{name}</Typography>
            {street && (
              <Typography variant="body2">{street}</Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };
  render() {
    const {ownerID} = this.props;
    if(ownerID) {
      return (
        <RouterLink to={ownerID ? `${ROUTES.OWNER_PAGE}/${ownerID}` : false}>
          {this.renderPreview()}
        </RouterLink>
      );
    }
    return this.renderPreview();
  }
}
import React from "react";
import TableCell from "@material-ui/core/TableCell/TableCell";
import {ROUTES} from "app/constants";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {toNumberFormat} from "app/utils/helpers";
import BadgeValue from "../../../../partials/components/BadgeValue";
import PropertyCardPreview from "../../../../partials/components/_DataParts/PropertyCardPreview";
import PropertyAvailabilityPreview from "../../../../partials/components/_DataParts/PropertyAvailabilityPreview";
import PropertyStatusPreview from "../../../../partials/components/_DataParts/PropertyStatusPreview";
import BlurText from "../../../../partials/components/BlurText";
import IsPro from "../../../../utils/IsPro";

class ParksPageParkWarehousesTableRow extends React.Component {
  render() {
    const {row, parentData} = this.props;
    const thumb = row.thumbnail ? row.thumbnail : parentData.thumbnail ? parentData.thumbnail : parentData.thumbnail110x110 ? parentData.thumbnail110x110 : null;

    return (
      <>
        <TableCell component="th" scope="row">
          <PropertyCardPreview
            propertyID={row.id}
            title={row.name}
            thumb={thumb}
            route={ROUTES.INDUSTRIAL_BUILDING}
          />
        </TableCell>
        <TableCell>
          <PropertyStatusPreview status={row?.status}/>
        </TableCell>
        <TableCell>
          <BadgeValue
            value={
            <BlurText
              type="area"
              isBlurred={IsPro({
                reverseCheck: true,
                overwriteRule: row?.isEditable
              })}
            >
              <strong>{toNumberFormat(row.totalAreaWarehouseAvailable, 'area')}</strong>
            </BlurText>
            }
            label={<FormattedMessage id="INDUSTRIAL.PHRASE.WAREHOUSE"/>}
            labelColor="success"
            minWidth="8.5rem"
          />
          <BadgeValue
            value={
            <BlurText
              type="area"
              isBlurred={IsPro({
                reverseCheck: true,
                overwriteRule: row?.isEditable
              })}
            >
              <strong>{toNumberFormat(row.totalAreaOfficeAvailable, 'area')}</strong>
            </BlurText>
            }
            label={<FormattedMessage id="INDUSTRIAL.PHRASE.OFFICE"/>}
            labelColor="neutral"
            minWidth="8.5rem"
          />
        </TableCell>
        <TableCell>
          <BadgeValue
            value={toNumberFormat(row.totalWarehouseAreaInBuilding, 'area')}
            label={<FormattedMessage id="INDUSTRIAL.PHRASE.WAREHOUSE"/>}
            labelColor="success"
            minWidth="8.5rem"
          />
          <BadgeValue
            value={toNumberFormat(row.totalOfficeAreaInBuilding, 'area')}
            label={<FormattedMessage id="INDUSTRIAL.PHRASE.OFFICE"/>}
            labelColor="neutral"
            minWidth="8.5rem"
          />
        </TableCell>
        <TableCell align="right">
          {row.buildingUnitsAvailable}
        </TableCell>
        <TableCell>
          <PropertyAvailabilityPreview
            date={row.buildingAvailableFrom}
            availableInMonths={row.availableFromAdditionalTime}
            isUnavailable={!row?.buildingUnitsAvailable || row?.buildingUnitsAvailable <= 0}
            parentStatus={row?.status}
          />
        </TableCell>
      </>
    );
  }
}

export default injectIntl(
  connect()(ParksPageParkWarehousesTableRow)
);

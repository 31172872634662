import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {put, takeLatest} from "redux-saga/effects";
import axios from "axios";
import {setFilters} from "app/utils/filters";
import {downloadFileFromUrl, toDateFormat} from "../../utils/helpers";
import {actionNotification} from "../../utils/notifications";

const initialState = {
  items: null,
  itemsCount: null,
  itemsPerPage: null,
  total: null,
  hasPaid: false,
  pendingLeadsCount: null,
  loading: false,
  lead: null,
  leadLoading: false,
  leadsCsvLoading: false,
  potentialTenantsXlsLoading: false,
  companiesItems: null,
  companiesItemsCount: null,
  companiesItemsPerPage: null,
  companiesTotal: null,
  companiesLoading: false
};

export const reducer = persistReducer(
  {storage, key: "finneLeads"},
  (state = initialState, action) => {
    const result = action.payload;
    switch (action.type) {
      // RESET FINNE LEADS LOADING STATES
      case "RESET_FINNE_LEADS_LOADING_STATES_REQUEST": {
        return {
          ...state,
          loading: false,
          leadLoading: false,
          leadsCsvLoading: false,
          potentialTenantsXlsLoading: false,
          companiesLoading: false
        };
      }
      // Fetch LEADS
      case "FETCH_MARKETPLACE_LEADS_REQUEST": {
        return {
          ...state,
          loading: true
        };
      }
      case "FETCH_MARKETPLACE_LEADS_SUCCESS": {
        return {
          ...state,
          items: result?.items,
          itemsCount: result?.itemsCount,
          itemsPerPage: result?.itemsPerPage,
          total: result?.total,
          hasPaid: result?.hasPaid,
          pendingLeadsCount: result?.pendingLeadsCount,
          loading: false
        };
      }
      case "FETCH_MARKETPLACE_LEADS_FAILED": {
        return {
          ...state,
          items: null,
          itemsCount: null,
          itemsPerPage: null,
          total: null,
          hasPaid: false,
          pendingLeadsCount: null,
          loading: false
        };
      }
      // Fetch LEAD
      case "FETCH_MARKETPLACE_LEAD_REQUEST": {
        return {
          ...state,
          lead: null,
          leadLoading: true
        };
      }
      case "FETCH_MARKETPLACE_LEAD_SUCCESS": {
        return {
          ...state,
          lead: result,
          leadLoading: false
        };
      }
      case "FETCH_MARKETPLACE_LEAD_FAILED": {
        return {
          ...state,
          lead: null,
          leadLoading: false
        };
      }
      // Fetch LEADS COMPANIES
      case "FETCH_MARKETPLACE_LEADS_COMPANIES_REQUEST": {
        return {
          ...state,
          companiesLoading: true
        };
      }
      case "FETCH_MARKETPLACE_LEADS_COMPANIES_SUCCESS": {
        return {
          ...state,
          companiesItems: result?.items,
          companiesItemsCount: result?.itemsCount,
          companiesItemsPerPage: result?.itemsPerPage,
          companiesTotal: result?.total,
          companiesLoading: false
        };
      }
      case "FETCH_MARKETPLACE_LEADS_COMPANIES_FAILED": {
        return {
          ...state,
          companiesItems: null,
          companiesItemsCount: null,
          companiesItemsPerPage: null,
          companiesTotal: null,
          companiesLoading: false
        };
      }
      // Fetch LEADS XLS
      case "FETCH_MARKETPLACE_LEADS_CSV_REQUEST": {
        return {
          ...state,
          leadsCsvLoading: true
        };
      }
      case "FETCH_MARKETPLACE_LEADS_CSV_SUCCESS": {
        return {
          ...state,
          leadsCsvLoading: false
        };
      }
      case "FETCH_MARKETPLACE_LEADS_CSV_FAILED": {
        return {
          ...state,
          leadsCsvLoading: false
        };
      }
      // Fetch LEADS COMPANIES XLS
      case "FETCH_MARKETPLACE_LEADS_COMPANIES_XLS_REQUEST": {
        return {
          ...state,
          potentialTenantsXlsLoading: true
        };
      }
      case "FETCH_MARKETPLACE_LEADS_COMPANIES_XLS_SUCCESS": {
        return {
          ...state,
          potentialTenantsXlsLoading: false
        };
      }
      case "FETCH_MARKETPLACE_LEADS_COMPANIES_XLS_FAILED": {
        return {
          ...state,
          potentialTenantsXlsLoading: false
        };
      }

      default:
        return state;
    }
  }
);

// FETCH DATA LEADS
function* fetchMarketplaceLeads(action) {
  const {target, params} = action.payload;
  const filters = setFilters(params, 'created_at');
  try {
    const leads = yield axios.get(`/marketplace-leads/${target}/${filters}`);
    yield put({
      type: "FETCH_MARKETPLACE_LEADS_SUCCESS",
      payload: leads.data || [
        {error: leads.statusText},
      ],
    });
  } catch (err) {
    yield put({
      type: "FETCH_MARKETPLACE_LEADS_FAILED",
      payload: err.status,
    });
  }
}
function* fetchMarketplaceLead(action) {
  const {leadID} = action.payload;
  try {
    const leads = yield axios.get(`/marketplace-leads/${leadID}/`);
    yield put({
      type: "FETCH_MARKETPLACE_LEAD_SUCCESS",
      payload: leads.data || [
        {error: leads.statusText},
      ],
    });
  } catch (err) {
    yield put({
      type: "FETCH_MARKETPLACE_LEAD_FAILED",
      payload: err.status,
    });
  }
}
// FETCH DATA COMPANIES
function* fetchMarketplaceLeadsCompanies(action) {
  const {target, params} = action.payload;
  const filters = setFilters(params, 'created_at');
  try {
    const leads = yield axios.get(`/marketplace-leads/${target}/companies/${filters}`);
    yield put({
      type: "FETCH_MARKETPLACE_LEADS_COMPANIES_SUCCESS",
      payload: leads.data || [
        {error: leads.statusText},
      ],
    });
  } catch (err) {
    yield put({
      type: "FETCH_MARKETPLACE_LEADS_COMPANIES_FAILED",
      payload: err.status,
    });
  }
}
// FETCH LEADS CSV
function* fetchMarketplaceLeadsCsv(action) {
  const {target, team} = action.payload;
  try {
    const currentDate = new Date();
    const csvLeads = yield axios.get(`/marketplace-leads/${target}/csv/`, { responseType: 'blob'});
    const csvBlobToUrl = csvLeads?.data && URL.createObjectURL(csvLeads?.data);
    const teamName = team && team?.length > 0 ? team.toLowerCase().replaceAll(" ", "_") : null;
    const fileName = [
      'redd-tenant-introductions',
      ...teamName ? [`${teamName.replaceAll(".", "_")}`] : [],
      ...currentDate ? [`${toDateFormat(currentDate).replaceAll(".", "-")}`] : []
    ].join("-");

    downloadFileFromUrl(csvBlobToUrl, fileName);
    yield put({
      type: "FETCH_MARKETPLACE_LEADS_CSV_SUCCESS",
      payload: csvLeads.data || [
        {error: csvLeads.statusText},
      ],
    });
  } catch (err) {
    yield put({
      type: "FETCH_MARKETPLACE_LEADS_CSV_FAILED",
      payload: err.status,
    });
  }
}
// FETCH LEADS COMPANIES CSV
function* fetchMarketplaceLeadsCompaniesXls(action) {
  const {target, team} = action.payload;
  try {
    const currentDate = new Date();
    const leadsCompanies = yield axios.get(`/marketplace-leads/${target}/companies-xls/`, { responseType: 'blob'});
    const csvBlobToUrl = leadsCompanies?.data && URL.createObjectURL(leadsCompanies?.data);
    const teamName = team && team?.length > 0 ? team.toLowerCase().replaceAll(" ", "_") : null;
    const fileName = [
      'redd-potential-tenants',
      ...teamName ? [`${teamName.replaceAll(".", "_")}`] : [],
      ...currentDate ? [`${toDateFormat(currentDate).replaceAll(".", "-")}`] : []
    ].join("-");

    downloadFileFromUrl(csvBlobToUrl, fileName);
    yield put({
      type: "FETCH_MARKETPLACE_LEADS_COMPANIES_XLS_SUCCESS",
      payload: leadsCompanies.data || [
        {error: leadsCompanies.statusText},
      ],
    });
  } catch (err) {
    yield put({
      type: "FETCH_MARKETPLACE_LEADS_COMPANIES_XLS_FAILED",
      payload: err.status,
    });
  }
}
// "Unlock" LEAD SEND NOTIFICATION
function* sendMarketplaceLeadUnlockNotification(action) {
  const {leadID} = action.payload;
  try {
    if(leadID) {
      yield axios.post(`/marketplace-leads/${leadID}/unlock/`);
    }
    else {
      yield axios.post(`/marketplace-leads/unlock/`);
    }
  } catch (err) {
    console.log(err);
  }
}
// "Unlock" VISITORS SEND NOTIFICATION
function* sendMarketplaceLeadUnlockVisitorsNotification() {
  try {
    yield axios.post(`/marketplace-leads/unlock-company/`);
  } catch (err) {
    console.log(err);
  }
}
// ACTION - Change lead status
function* changeLeadStatus(action) {
  const {leadID, status} = action.payload;
  try {
    const statusUpdated = yield axios.post(`/marketplace-leads/${leadID}/update-status/`, {status});
    yield put({
      type: "CHANGE_LEAD_STATUS_SUCCESS",
      payload: statusUpdated.data,
      meta: actionNotification('Lead status has been updated.', 'success')
    });
  } catch (err) {
    console.log(err);
  }
}

export function* saga() {
  yield takeLatest("FETCH_MARKETPLACE_LEADS_REQUEST", fetchMarketplaceLeads);
  yield takeLatest("FETCH_MARKETPLACE_LEAD_REQUEST", fetchMarketplaceLead);
  yield takeLatest("FETCH_MARKETPLACE_LEADS_COMPANIES_REQUEST", fetchMarketplaceLeadsCompanies);
  yield takeLatest("FETCH_MARKETPLACE_LEADS_CSV_REQUEST", fetchMarketplaceLeadsCsv);
  yield takeLatest("FETCH_MARKETPLACE_LEADS_COMPANIES_XLS_REQUEST", fetchMarketplaceLeadsCompaniesXls);
  yield takeLatest("SEND_MARKETPLACE_LEADS_UNLOCK_NOTIFICATION_REQUEST", sendMarketplaceLeadUnlockNotification);
  yield takeLatest("SEND_MARKETPLACE_LEADS_UNLOCK_VISITORS_NOTIFICATION_REQUEST", sendMarketplaceLeadUnlockVisitorsNotification);
  yield takeLatest("CHANGE_LEAD_STATUS_REQUEST", changeLeadStatus);
}

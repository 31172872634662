import React from 'react';
import cn from "classnames";
import styles from "../index.module.scss";
import TooltipCustom from "../../TooltipCustom";
import Icon from "app/partials/components/Icon";
import Button from "../../Button";

export default function EditButton(props) {
  const {tooltip, iconStyle, tooltipTriggerStyle, withoutBg, onClick} = props;
  return(
    <TooltipCustom
      title={tooltip}
      placement={props?.tooltipPlacement || "right"}
      disabled={!tooltip}
      childStyle={{
        cursor: "pointer",
        ...tooltipTriggerStyle
      }}
    >
      {withoutBg ?
        <span
          className={cn(styles.triggerIcon, styles.editIcon)}
          style={iconStyle}
          onClick={onClick}
        >
          <Icon icon={"edit"}/>
        </span> :
        <Button
          type={"icon"}
          color={"simple"}
          onClick={onClick}
        >
          <Icon icon={"edit"}/>
        </Button>
      }
    </TooltipCustom>
  );
}
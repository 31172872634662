import store from "../store/store";
import ACCOUNT_TYPE from "../constants/ACCOUNT_TYPE";

const IsAccountType = ({expectedAccountType = null, ...props}) => {
  const storeData = store.getState();
  const user = storeData?.user?.data;
  const currentAccountType = user?.proData?.proGroup;
  const isMatched = expectedAccountType === currentAccountType || currentAccountType === ACCOUNT_TYPE.ADMIN;

  if(props?.children) {
    if(isMatched) {
      return props?.children;
    }
    return null;
  }
  return isMatched || false;
}

export default IsAccountType;
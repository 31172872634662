import {connect} from "react-redux";
import UserProfile from "./UserProfile";
import {
  changePassword,
  removeUserImage,
  updateUserAffiliateCodeVisible,
  updateUserData,
  updateUserMarket
} from "app/crud/user.crud";
import {
  fetchTeamOffersStyles,
  fetchTeamPropertyParticularsStyles,
  updateTeamOffersLogo,
  updateTeamOffersStyles,
  updateTeamPropertyParticularsLogo,
  updateTeamPropertyParticularsStyles
} from "app/crud/team.crud";

const mapStateToProps = ({ user, team }) => ({
  user: user.data,
  updateLoading: user.updateLoading,
  ppBranding: team.ppBranding,
  loadingPPBranding: team.loadingPPBranding,
  updatingPPLogo: team.updatingPPLogo,
  offersBranding: team.offersBranding,
  loadingOffersBranding: team.loadingOffersBranding,
  updatingOffersLogo: team.updatingOffersLogo
});
const mapDispatchToProps = dispatch => ({
  updateUserData: (userData, avatar, companyLogo, pdfCover, redirect) => dispatch(updateUserData(userData, avatar, companyLogo, pdfCover, redirect)),
  updateUserAffiliateCodeVisible: () => dispatch(updateUserAffiliateCodeVisible()),
  removeUserImage: (target) => dispatch(removeUserImage(target)),
  changePassword: data => dispatch(changePassword(data)),
  fetchTeamPropertyParticularsStyles: (teamID) => dispatch(fetchTeamPropertyParticularsStyles(teamID)),
  updateTeamPropertyParticularsStyles: (teamID, data) => dispatch(updateTeamPropertyParticularsStyles(teamID, data)),
  updateTeamPropertyParticularsLogo: (logo) => dispatch(updateTeamPropertyParticularsLogo(logo)),
  fetchTeamOffersStyles: (teamID) => dispatch(fetchTeamOffersStyles(teamID)),
  updateTeamOffersStyles: (teamID, data) => dispatch(updateTeamOffersStyles(teamID, data)),
  updateTeamOffersLogo: (logo) => dispatch(updateTeamOffersLogo(logo)),
  updateUserMarket: (userData, redirect) => dispatch(updateUserMarket(userData, redirect))
});

const withRedux = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withRedux(UserProfile);

import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import styles from "./index.module.scss";
import * as colors from "app/_colorPalette.scss";
import {ReactComponent as AddCircle} from "app/media/icons-handmade/add-circle.svg";
import {ReactComponent as AddClean} from "app/media/icons-handmade/add-clean.svg";
import {ReactComponent as AddClusters} from "app/media/icons-handmade/add-clusters.svg";
import {ReactComponent as AddPerson} from "app/media/icons-handmade/add-person.svg";
import {ReactComponent as ArrowDoubleRight} from "app/media/icons-handmade/arrow-double-right.svg";
import {ReactComponent as ArrowSubfolderRight} from "app/media/icons-handmade/arrow-subfolder-right.svg";
import {ReactComponent as ArrowSubfolderUp} from "app/media/icons-handmade/arrow-subfolder-up.svg";
import {ReactComponent as BikePerson} from "app/media/icons-handmade/bike-person.svg";
import {ReactComponent as Bike} from "app/media/icons-handmade/bike.svg";
import {ReactComponent as Building} from "app/media/icons-handmade/building.svg";
import {ReactComponent as Buildings} from "app/media/icons-handmade/buildings.svg";
import {ReactComponent as Cable} from "app/media/icons-handmade/cable.svg";
import {ReactComponent as Calendar} from "app/media/icons-handmade/calendar.svg";
import {ReactComponent as Camera} from "app/media/icons-handmade/camera.svg";
import {ReactComponent as Car} from "app/media/icons-handmade/car.svg";
import {ReactComponent as CardClusters} from "app/media/icons-handmade/card-clusters.svg";
import {ReactComponent as ChartDown} from "app/media/icons-handmade/chart-down.svg";
import {ReactComponent as ChartSearch} from "app/media/icons-handmade/chart-search.svg";
import {ReactComponent as ChartUp} from "app/media/icons-handmade/chart-up.svg";
import {ReactComponent as Chart} from "app/media/icons-handmade/chart.svg";
import {ReactComponent as Check} from "app/media/icons-handmade/check.svg";
import {ReactComponent as CheckCircle} from "app/media/icons-handmade/check-circle.svg";
import {ReactComponent as Checklist} from "app/media/icons-handmade/checklist.svg";
import {ReactComponent as ChevronDown} from "app/media/icons-handmade/chevron-down.svg";
import {ReactComponent as ChevronRight} from "app/media/icons-handmade/chevron-right.svg";
import {ReactComponent as Close} from "app/media/icons-handmade/close.svg";
import {ReactComponent as Collapse} from "app/media/icons-handmade/collapse.svg";
import {ReactComponent as Dashboard} from "app/media/icons-handmade/dashboard.svg";
import {ReactComponent as DocDollar} from "app/media/icons-handmade/doc-dollar.svg";
import {ReactComponent as Download} from "app/media/icons-handmade/download.svg";
import {ReactComponent as Edit} from "app/media/icons-handmade/edit.svg";
import {ReactComponent as Eye} from "app/media/icons-handmade/eye.svg";
import {ReactComponent as Filters} from "app/media/icons-handmade/filters.svg";
import {ReactComponent as Flag} from "app/media/icons-handmade/flag.svg";
import {ReactComponent as GridSquare} from "app/media/icons-handmade/gird-square.svg";
import {ReactComponent as Gym} from "app/media/icons-handmade/gym.svg";
import {ReactComponent as Hamburger} from "app/media/icons-handmade/hamburger.svg";
import {ReactComponent as Hand} from "app/media/icons-handmade/hand.svg";
import {ReactComponent as ImageClusters} from "app/media/icons-handmade/image-clusters.svg";
import {ReactComponent as InfoCircle} from "app/media/icons-handmade/info-circle.svg";
import {ReactComponent as Key} from "app/media/icons-handmade/key.svg";
import {ReactComponent as LiftCar} from "app/media/icons-handmade/lift-car.svg";
import {ReactComponent as Light} from "app/media/icons-handmade/light.svg";
import {ReactComponent as List} from "app/media/icons-handmade/list.svg";
import {ReactComponent as Lock} from "app/media/icons-handmade/lock.svg";
import {ReactComponent as MapPin} from "app/media/icons-handmade/map-pin.svg";
import {ReactComponent as MoreVertical} from "app/media/icons-handmade/more-vertical.svg";
import {ReactComponent as NewLocation} from "app/media/icons-handmade/new-location.svg";
import {ReactComponent as News} from "app/media/icons-handmade/news.svg";
import {ReactComponent as Pdf} from "app/media/icons-handmade/pdf.svg";
import {ReactComponent as Person} from "app/media/icons-handmade/person.svg";
import {ReactComponent as Plan} from "app/media/icons-handmade/plan.svg";
import {ReactComponent as Retail} from "app/media/icons-handmade/retail.svg";
import {ReactComponent as Search} from "app/media/icons-handmade/search.svg";
import {ReactComponent as Send45Deg} from "app/media/icons-handmade/send-45deg.svg";
import {ReactComponent as Send} from "app/media/icons-handmade/send.svg";
import {ReactComponent as Share} from "app/media/icons-handmade/share.svg";
import {ReactComponent as SmokeDetector} from "app/media/icons-handmade/smoke-detector.svg";
import {ReactComponent as SortDoubleDown} from "app/media/icons-handmade/sort-double-down.svg";
import {ReactComponent as SortDoubleUp} from "app/media/icons-handmade/sort-double-up.svg";
import {ReactComponent as SortDouble} from "app/media/icons-handmade/sort-double.svg";
import {ReactComponent as Suitcase} from "app/media/icons-handmade/suitcase.svg";
import {ReactComponent as SwitchSquare} from "app/media/icons-handmade/switch-square.svg";
import {ReactComponent as ThumbUp} from "app/media/icons-handmade/thumb-up.svg";
import {ReactComponent as Time} from "app/media/icons-handmade/time.svg";
import {ReactComponent as Timer} from "app/media/icons-handmade/timer.svg";
import {ReactComponent as Tram} from "app/media/icons-handmade/tram.svg";
import {ReactComponent as Trash} from "app/media/icons-handmade/trash.svg";
import {ReactComponent as UnitsBrick} from "app/media/icons-handmade/units-brick.svg";
import {ReactComponent as Warehouse} from "app/media/icons-handmade/warehouse.svg";
import {ReactComponent as Wifi} from "app/media/icons-handmade/wifi.svg";
import {ReactComponent as Wind} from "app/media/icons-handmade/wind.svg";
import {ReactComponent as EnvelopeAdd} from "app/media/icons-handmade/envelope-add.svg";
import {ReactComponent as ListEdit} from "app/media/icons-handmade/list-edit.svg";
import {ReactComponent as Refresh} from "app/media/icons-handmade/refresh.svg";

const Icon = (
  {
    icon,
    size = "inherit",
    color,
    colorHex,
    style
  }
) => {
  const [activeIcon, setActiveIcon] = useState(null);

  useEffect(() => {
    switch (icon) {
      case "check":
        setActiveIcon(<Check/>);
        break;
      case "add-circle":
        setActiveIcon(<AddCircle/>);
        break;
      case "add-clean":
        setActiveIcon(<AddClean/>);
        break;
      case "add-clusters":
        setActiveIcon(<AddClusters/>);
        break;
      case "add-person":
        setActiveIcon(<AddPerson/>);
        break;
      case "arrow-double-right":
        setActiveIcon(<ArrowDoubleRight/>);
        break;
      case "arrow-subfolder-right":
        setActiveIcon(<ArrowSubfolderRight/>);
        break;
      case "arrow-subfolder-up":
        setActiveIcon(<ArrowSubfolderUp/>);
        break;
      case "bike-person":
        setActiveIcon(<BikePerson/>);
        break;
      case "bike":
        setActiveIcon(<Bike/>);
        break;
      case "building":
        setActiveIcon(<Building/>);
        break;
      case "buildings":
        setActiveIcon(<Buildings/>);
        break;
      case "cable":
        setActiveIcon(<Cable/>);
        break;
      case "calendar":
        setActiveIcon(<Calendar/>);
        break;
      case "camera":
        setActiveIcon(<Camera/>);
        break;
      case "car":
        setActiveIcon(<Car/>);
        break;
      case "card-clusters":
        setActiveIcon(<CardClusters/>);
        break;
      case "chart-down":
        setActiveIcon(<ChartDown/>);
        break;
      case "chart-search":
        setActiveIcon(<ChartSearch/>);
        break;
      case "chart-up":
        setActiveIcon(<ChartUp/>);
        break;
      case "chart":
        setActiveIcon(<Chart/>);
        break;
      case "check-circle":
        setActiveIcon(<CheckCircle/>);
        break;
      case "checklist":
        setActiveIcon(<Checklist/>);
        break;
      case "chevron-down":
        setActiveIcon(<ChevronDown/>);
        break;
      case "chevron-right":
        setActiveIcon(<ChevronRight/>);
        break;
      case "close":
        setActiveIcon(<Close/>);
        break;
      case "collapse":
        setActiveIcon(<Collapse/>);
        break;
      case "dashboard":
        setActiveIcon(<Dashboard/>);
        break;
      case "doc-dollar":
        setActiveIcon(<DocDollar/>);
        break;
      case "download":
        setActiveIcon(<Download/>);
        break;
      case "edit":
        setActiveIcon(<Edit/>);
        break;
      case "eye":
        setActiveIcon(<Eye/>);
        break;
      case "filters":
        setActiveIcon(<Filters/>);
        break;
      case "flag":
        setActiveIcon(<Flag/>);
        break;
      case "grid-square":
        setActiveIcon(<GridSquare/>);
        break;
      case "gym":
        setActiveIcon(<Gym/>);
        break;
      case "hamburger":
        setActiveIcon(<Hamburger/>);
        break;
      case "hand":
        setActiveIcon(<Hand/>);
        break;
      case "image-clusters":
        setActiveIcon(<ImageClusters/>);
        break;
      case "info-circle":
        setActiveIcon(<InfoCircle/>);
        break;
      case "key":
        setActiveIcon(<Key/>);
        break;
      case "lift-car":
        setActiveIcon(<LiftCar/>);
        break;
      case "light":
        setActiveIcon(<Light/>);
        break;
      case "list":
        setActiveIcon(<List/>);
        break;
      case "lock":
        setActiveIcon(<Lock/>);
        break;
      case "map-pin":
        setActiveIcon(<MapPin/>);
        break;
      case "more-vertical":
        setActiveIcon(<MoreVertical/>);
        break;
      case "new-location":
        setActiveIcon(<NewLocation/>);
        break;
      case "news":
        setActiveIcon(<News/>);
        break;
      case "pdf":
        setActiveIcon(<Pdf/>);
        break;
      case "person":
        setActiveIcon(<Person/>);
        break;
      case "plan":
        setActiveIcon(<Plan/>);
        break;
      case "retail":
        setActiveIcon(<Retail/>);
        break;
      case "search":
        setActiveIcon(<Search/>);
        break;
      case "send-45deg":
        setActiveIcon(<Send45Deg/>);
        break;
      case "send":
        setActiveIcon(<Send/>);
        break;
      case "share":
        setActiveIcon(<Share/>);
        break;
      case "smoke-detector":
        setActiveIcon(<SmokeDetector/>);
        break;
      case "sort-double-down":
        setActiveIcon(<SortDoubleDown/>);
        break;
      case "sort-double-up":
        setActiveIcon(<SortDoubleUp/>);
        break;
      case "sort-double":
        setActiveIcon(<SortDouble/>);
        break;
      case "suitcase":
        setActiveIcon(<Suitcase/>);
        break;
      case "switch-square":
        setActiveIcon(<SwitchSquare/>);
        break;
      case "thumb-up":
        setActiveIcon(<ThumbUp/>);
        break;
      case "time":
        setActiveIcon(<Time/>);
        break;
      case "timer":
        setActiveIcon(<Timer/>);
        break;
      case "tram":
        setActiveIcon(<Tram/>);
        break;
      case "trash":
        setActiveIcon(<Trash/>);
        break;
      case "units-brick":
        setActiveIcon(<UnitsBrick/>);
        break;
      case "warehouse":
        setActiveIcon(<Warehouse/>);
        break;
      case "wifi":
        setActiveIcon(<Wifi/>);
        break;
      case "envelope-add":
        setActiveIcon(<EnvelopeAdd/>);
        break;
      case "list-edit":
        setActiveIcon(<ListEdit/>);
        break;
      case "refresh":
        setActiveIcon(<Refresh/>);
        break;
      case "wind":
        setActiveIcon(<Wind/>);
        break;
      default:
        setActiveIcon(null);
    }
  }, [icon]);

  if (activeIcon) {
    return (
      <span
        className={styles.iconWrapper}
        style={{
          ...size ? {fontSize: size} : undefined,
          ...color ? {color: colors?.[color]} : undefined,
          ...colorHex ? {color: colorHex} : undefined,
          ...style
        }}
      >{activeIcon}</span>
    );
  }
  return null;
}

// At the moment, this is not possible to use dynamic variables in PropTypes,
// that's why we will define colors manually
// https://github.com/storybookjs/storybook/issues/14092
Icon.propTypes = {
  size: PropTypes.string,
  colorHex: PropTypes.string,
  color: PropTypes.oneOf([
    "success",
    "successDark",
    "successLight",
    "warning",
    "brand",
    "danger",
    "dangerDark",
    "neutral",
    "neutral2",
    "default",
    "white",
    "gray",
    "premium",
    "body",
    "lightblue",
    "lightGray",
    "thinGray",
    "paleGray",
    "finne",
    "finneDark",
    "admin",
    "adminLight"
  ]),
  icon: PropTypes.oneOf([
    "add-circle",
    "add-clean",
    "add-clusters",
    "add-person",
    "arrow-double-right",
    "arrow-subfolder-right",
    "arrow-subfolder-up",
    "bike-person",
    "bike",
    "building",
    "buildings",
    "cable",
    "calendar",
    "camera",
    "car",
    "card-clusters",
    "chart-down",
    "chart-search",
    "chart-up",
    "chart",
    "check",
    "check-circle",
    "checklist",
    "chevron-down",
    "chevron-right",
    "close",
    "collapse",
    "dashboard",
    "doc-dollar",
    "download",
    "edit",
    "envelope-add",
    "eye",
    "filters",
    "flag",
    "grid-square",
    "gym",
    "hamburger",
    "hand",
    "image-clusters",
    "info-circle",
    "key",
    "lift-car",
    "light",
    "list",
    "list-edit",
    "lock",
    "map-pin",
    "more-vertical",
    "new-location",
    "news",
    "pdf",
    "person",
    "plan",
    "refresh",
    "retail",
    "search",
    "send-45deg",
    "send",
    "share",
    "smoke-detector",
    "sort-double-down",
    "sort-double-up",
    "sort-double",
    "suitcase",
    "switch-square",
    "thumb-up",
    "time",
    "timer",
    "tram",
    "trash",
    "units-brick",
    "warehouse",
    "wifi",
    "wind"
  ]).isRequired,
}
export default Icon;
import React from "react";
import styles from "./index.module.scss";
import clsx from "classnames";

export default class BadgeValue extends React.Component {
  render() {
    const {value, label, bgColor = "transparent", valueColor = "default", labelColor = "default", withBorder, minWidth, solid} = this.props;
    return(
      <span className={clsx(
        styles.badge,
        styles[bgColor],
        withBorder ? styles.withBorder : undefined,
        solid ? styles.solid : undefined
      )} style={minWidth && {minWidth}}>
        <span className={clsx(styles.badgeValue, styles[valueColor])}>{value}</span>
        <span className={clsx(styles.badgeLabel, styles[labelColor])}>{label}</span>
      </span>
    );
  }
}
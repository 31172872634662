import React, {Component} from 'react';
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import styles from './index.module.scss';
import clsx from 'clsx';

export default class ButtonTooltip extends Component {
  render() {
    const {children, tPlacement, tOverlay, tId, onClick, inactive, icon, color, size, defaultCursor} = this.props;
    return (
      <OverlayTrigger
          placement={tPlacement || "bottom"}
          overlay={<Tooltip id={tId}>{tOverlay || "Tooltip text"}</Tooltip>}
        >
        <div
          onClick={onClick}
          className={
            clsx(
              styles.btn,
              inactive ? styles.inactive : '',
              icon ? styles.iconSpace : '',
              color ? styles[color] : styles.neutral,
              size ? styles[size] : styles.defaultSize,
              defaultCursor ? styles.defaultCursor : undefined
            )
          }
        >
          {children}
        </div>
      </OverlayTrigger>
    );
  }
}
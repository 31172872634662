import React from "react";
import {Grid, Typography} from "@material-ui/core";
import {toAbsoluteUrl} from "_metronic";
import {FormattedMessage} from "react-intl";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import styles from "./index.module.scss";
import cn from "classnames";

// Display custom markers and it's descriptions
class MapLegend extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      markerFilters: []
    };
  }
  // Handlers
  handleFilterMarkers = (value) => {
    const {onFilter} = this.props;
    const {markerFilters} = this.state;
    let filters = markerFilters;
    if(markerFilters.includes(value)) {
      filters = filters.filter(item => item !== value);
    }
    else {
      filters.push(value);
    }
    this.setState({
      markerFilters: filters
    }, () => onFilter(this.state.markerFilters));
  }

  render() {
    const {markerFilters} = this.state;
    const pinGreen = toAbsoluteUrl("media/icons/markers/pin-green.png");
    const pinOrange = toAbsoluteUrl("media/icons/markers/pin-orange.png");
    const pinRed = toAbsoluteUrl("media/icons/markers/pin-neon.png");
    const pinBlue = toAbsoluteUrl("media/icons/markers/pin-blue.png");

    return(
      <Grid container spacing={4}>
        <Grid
          item
          className={cn(
            styles.legendItem,
            markerFilters.includes("green") ? styles.filteredOut : undefined
          )}
          onClick={() => this.handleFilterMarkers('green')}
        >
          <Grid container alignItems="center" spacing={1} wrap="nowrap">
            <Grid item>
              <img src={pinGreen} alt="Pin Green" style={{height: "4rem"}}/>
            </Grid>
            <Grid item>
              <Typography variant="body1"><FormattedMessage id="GENERAL.PHRASE.AVAILABLE"/></Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          className={cn(
            styles.legendItem,
            markerFilters.includes("orange") ? styles.filteredOut : undefined
          )}
          onClick={() => this.handleFilterMarkers('orange')}
        >
          <Grid container alignItems="center" spacing={1} wrap="nowrap">
            <Grid item>
              <img src={pinOrange} alt="Pin Green" style={{height: "4rem"}}/>
            </Grid>
            <Grid item>
              <Typography variant="body1"><FormattedMessage id="GENERAL.PHRASE.AVAILABLE_IN_FUTURE"/></Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          className={cn(
            styles.legendItem,
            markerFilters.includes("red") ? styles.filteredOut : undefined
          )}
          onClick={() => this.handleFilterMarkers('red')}
        >
          <Grid container alignItems="center" spacing={1} wrap="nowrap">
            <Grid item>
              <img src={pinRed} alt="Pin Green" style={{height: "4rem"}}/>
            </Grid>
            <Grid item>
              <Typography variant="body1"><FormattedMessage id="GENERAL.PHRASE.UNAVAILABLE"/></Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          className={cn(
            styles.legendItem,
            markerFilters.includes("blue") ? styles.filteredOut : undefined
          )}
          onClick={() => this.handleFilterMarkers('blue')}
        >
          <Grid container alignItems="center" spacing={1} wrap="nowrap">
            <Grid item>
              <img src={pinBlue} alt="Pin Green" style={{height: "4rem"}}/>
            </Grid>
            <Grid item>
              <Typography variant="body1"><FormattedMessage id="GENERAL.PHRASE.PLANNED"/></Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(connect()(MapLegend));
import React from "react";
import {Grid, TableCell} from "@material-ui/core";
import BuildingCardPreview from "app/partials/components/_DataParts/BuildingCardPreview";
import UnitAreaPreview from "app/partials/components/_DataParts/UnitAreaPreview";
import UnitAvailabilityPreview from "app/partials/components/_DataParts/UnitAvailabilityPreview";
import UpdatedAtPreview from "app/partials/components/_DataParts/UpdatedAtPreview";
import UnitStatusPreview from "app/partials/components/_DataParts/UnitStatusPreview";
import UnitFloorPreview from "app/partials/components/_DataParts/UnitFloorPreview";
import UnitTypePreview from "app/partials/components/_DataParts/UnitTypePreview";
import {UNIT_STATUS} from "app/constants";
import {injectIntl} from "react-intl";
import styles from "../index.module.scss";
import connect from "react-redux/es/connect/connect";
import EditModal from "../../EditModal";
import UnitAddToOffer from "../../Units/UnitAddToOffer";
import IsAdmin from "../../../../utils/IsAdmin";

class TableUnitsCells extends React.Component {
  render() {
    const {row} = this.props;
    const parentData = row?.office;
    const isOfficeEditable = parentData?.isEditable;
    const addToOfferEnable = row?.status !== UNIT_STATUS.UNAVAILABLE || IsAdmin();

    return (
      <>
        <TableCell component="th" id={row.id} scope="row" className={styles.wrapCell}>
          <BuildingCardPreview
              name={parentData?.name}
              street={parentData?.addressStreet}
              city={parentData?.addressCity}
              thumb={parentData?.mainPhotoThumbnail}
              officeID={parentData?.id}
              status={parentData?.status}
          />
        </TableCell>
        <TableCell align="right">
          <UnitAreaPreview data={row} style={{fontWeight: "600"}}/>
        </TableCell>
        <TableCell align="right">
          <UnitFloorPreview floor={row?.floor}/>
        </TableCell>
        <TableCell>
          <UnitTypePreview unitType={row?.unitType} isSublease={row?.isSublease}/>
        </TableCell>
        <TableCell>
          <UnitStatusPreview unitStatus={row?.status}/>
        </TableCell>
        <TableCell>
          <UnitAvailabilityPreview
              date={row?.availableFrom}
              isReserved={row?.status === UNIT_STATUS.RESERVED}
              isUnavailable={row?.status === UNIT_STATUS.UNAVAILABLE}
              availableInMonths={row?.availableFromAdditionalTime}
              parentStatus={parentData?.status}
          />
        </TableCell>
        <TableCell>
          <UpdatedAtPreview level={row?.updateLevel} date={row?.updatedAt} alternative/>
        </TableCell>
        <TableCell align="right">
          <Grid container spacing={1} alignItems="center" justifyContent="flex-end" wrap="nowrap">
            {addToOfferEnable &&
            <Grid item>
              <UnitAddToOffer
                unitId={row?.id}
                propertyID={parentData?.id}
                source="unitCell"
              />
            </Grid>
            }
            <Grid item>
              <EditModal
                target="unit"
                parentData={row}
                triggerDots
                isPropertyEditable={isOfficeEditable}
                initialTab={isOfficeEditable ? "basic" : "note"}
                extraData={{
                  officeId: parentData?.id,
                  officeName: parentData?.name
                }}
              />
            </Grid>
          </Grid>
        </TableCell>
      </>
    );
  }
}

export default injectIntl(
  connect()(TableUnitsCells)
);

const symmco = {
  NET_DEAL: [
    {
      id: 0,
      name: "Below 5000"
    },
    {
      id: 1,
      name: "5000 - 10 000"
    },
    {
      id: 2,
      name: "10 000 - 15 000"
    },
    {
      id: 3,
      name: "15 000 - 20 000"
    },
    {
      id: 4,
      name: "20 000 - 25 000"
    },
    {
      id: 5,
      name: "Over 25 000"
    }
  ],
  ZONE: [
    {
      id: 0,
      name: "Near city"
    },
    {
      id: 1,
      name: "Out of city"
    },
    {
      id: 2,
      name: "Inner city"
    }
  ],
  DEAL_TYPE: [
    {
      id: 0,
      name: "New lease"
    },
    {
      id: 1,
      name: "Lease renewal"
    }
  ],
  DEAL_SOURCE: [
    {
      id: 0,
      name: "Direct"
    },
    {
      id: 1,
      name: "Broker"
    }
  ],
  TENANTS_INDUSTRY: [
    {
      id: 0,
      name: "FMCG"
    },
    {
      id: 1,
      name: "Manufacturing"
    },
    {
      id: 2,
      name: "Retail"
    },
    {
      id: 3,
      name: "E-commerce"
    },
    {
      id: 4,
      name: "Automotive"
    },
    {
      id: 5,
      name: "Pharma"
    },
    {
      id: 6,
      name: "Tech"
    },
    {
      id: 7,
      name: "Food & Bev"
    },
    {
      id: 8,
      name: "Logistics"
    },
    {
      id: 9,
      name: "Construction"
    },
    {
      id: 10,
      name: "Apparel"
    },
    {
      id: 11,
      name: "Chemicals"
    },
    {
      id: 12,
      name: "Agribusiness"
    },
    {
      id: 13,
      name: "Aerospace"
    },
    {
      id: 14,
      name: "Wholesale"
    },
    {
      id: 15,
      name: "Renewable"
    },
    {
      id: 16,
      name: "Energy"
    },
    {
      id: 17,
      name: "Paper & Packaging"
    },
    {
      id: 18,
      name: "Furniture"
    },
    {
      id: 19,
      name: "Sports & Outdoors"
    },
    {
      id: 20,
      name: "Medical Devices"
    }
  ]
};

export default symmco;
import React from "react";
import {BUILDING_STATUS} from "../../../constants";
import {FormattedMessage} from "react-intl";
import styles from "./index.module.scss";
import Button from "../Button";

export default class PropertyStatusPreview extends React.Component {
  render() {
    const {status, textOnly, textWithIcon, iconOnly} = this.props;
    let color = "success";
    let tooltip = "GENERAL.PHRASE.EXISTING";

    if (status === BUILDING_STATUS.STATUS_UNDER_CONSTRUCTION) {
      color = "warning";
      tooltip = "GENERAL.PHRASE.UNDER_CONSTRUCTION";
    }
    else if (status === BUILDING_STATUS.STATUS_PLANNED) {
      color = "neutral";
      tooltip = "GENERAL.PHRASE.PLANNED";
    }
    else if (status === BUILDING_STATUS.STATUS_UNDER_REVITALIZATION) {
      color = "gray";
      tooltip = "GENERAL.PHRASE.UNDER_REVITALIZATION";
    }
    else if (status === BUILDING_STATUS.STATUS_UNDER_REDEVELOPMENT) {
      color = "default";
      tooltip = "GENERAL.PHRASE.UNDER_REDEVELOPMENT";
    }

    if(textOnly) {
      return <FormattedMessage id={tooltip}/>;
    }
    else if(iconOnly) {
      return(
        <Button
          type={"plainText"}
          color={color}
          style={{pointerEvents: "none"}}
        >
          <span className={styles.unitStatusDot}/>
        </Button>
      );
    }
    else if(textWithIcon) {
      return(
        <span
          style={{
            display: "flex",
            alignItems: "center"
          }}
        >
          <span className={styles.unitStatusDot} style={{width: "4px", height: "4px", marginRight: "5px", lineHeight: "0"}}/>
          <FormattedMessage id={tooltip}/>
        </span>
      );
    }
    return (
      <Button
        type={"icon"}
        color={color}
        tooltip={<FormattedMessage id={tooltip}/>}
        style={{cursor: "default"}}
      >
        <span className={styles.unitStatusDot}/>
      </Button>
    );
  }
}
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {put, takeLatest} from "redux-saga/effects";
import axios from "axios";
import {actionNotification} from "app/utils/notifications";
import {handleErrorResponse} from "app/utils/helpers";
import {setFilters} from "../../utils/filters";
import _ from "lodash";

const initialState = {
  items: [],
  itemsPerPage: 20,
  itemsCount: null,
  loaded: false,
  loading: false,
  didInvalidate: false,
  inviteErrors: null,
  loadingUpdatePaymentPeriod: false,
  paymentPeriodPlanID: null,
  ppBranding: null,
  loadingPPBranding: false,
  updatingPPLogo: false,
  offersBranding: null,
  loadingOffersBranding: false,
  updatingOffersLogo: false,
  updatingTeamMember: false
};

const unsetContactPerson = (itemsCP, payloadCP) => {
  if(!payloadCP || payloadCP?.length === 0) {
    return itemsCP.map(item => {
      const temp = Object.assign({}, item);
      temp.isContactPerson = false;
      return temp;
    });
  }
  else if(itemsCP?.length > payloadCP?.length) {
    const unsetPerson = itemsCP.filter(item => {
      return !payloadCP.some(person => {
        return item.id === person.id;
      })
    }).map(item => {
      const temp = Object.assign({}, item);
      temp.isContactPerson = false;
      return temp;
    });
    return [...payloadCP, ...unsetPerson];
  }
  return payloadCP;
};
const updateLogoField = (fullObject, logo, fieldName) => {
  return _.update(fullObject, `${fieldName}`, () => logo);
};

export const reducer = persistReducer(
  { storage, key: "team" },
  (state = initialState, action) => {
    switch (action.type) {
      // Reset Team Members Loading States
      case 'RESET_TEAM_MEMBERS_LOADING_STATES_REQUEST': {
        return {
          ...state,
          loading: false,
          loadingUpdatePaymentPeriod: false,
          loadingPPBranding: false,
          loadingOffersBranding: false
        };
      }
      //
      case 'FETCH_TEAM_MEMBERS_REQUEST': {
        return { ...state, loading: true };
      }
      case 'FETCH_TEAM_MEMBERS_SUCCESS': {
        const { items, itemsCount, itemsPerPage } = action.payload;
        return { ...state, items, itemsCount, itemsPerPage, loaded: true, loading: false }
      }
      case 'GET_TEAM_PROPERTY_PARTICULARS_STYLES_REQUEST': {
        return { ...state, loadingPPBranding: true, ppBranding: null };
      }
      case 'GET_TEAM_PROPERTY_PARTICULARS_STYLES_SUCCESS': {
        return { ...state, ppBranding: action.payload, loadingPPBranding: false };
      }
      case 'GET_TEAM_OFFERS_STYLES_REQUEST': {
        return { ...state, loadingOffersBranding: true, offersBranding: null };
      }
      case 'GET_TEAM_OFFERS_STYLES_SUCCESS': {
        return { ...state, offersBranding: action.payload, loadingOffersBranding: false };
      }
      case 'SET_TEAM_MEMBER_AS_OWNER_REQUEST': {
        return { ...state, updatingTeamMember: true };
      }
      case 'SET_TEAM_MEMBER_AS_OWNER_SUCCESS': {
        return {
          ...state,
          items: state.items.map(item => (item.id === action.payload ? { ...item, isOwner: true } : { ...item, isOwner: false })),
          updatingTeamMember: false
        }
      }
      case 'SET_TEAM_MEMBER_AS_OWNER_FAILED': {
        return {
          ...state,
          updatingTeamMember: false
        }
      }
      case 'DELETE_TEAM_MEMBER_REQUEST': {
        return { ...state }
      }
      case 'DELETE_TEAM_MEMBER_SUCCESS': {
        return { ...state, items: state.items.filter(item => item.id !== action.payload) }
      }
      case 'INVITE_TEAM_MEMBER_REQUEST': {
        return { ...state }
      }
      case 'INVITE_TEAM_MEMBER_SUCCESS': {
        return { ...state, items: [...state.items, action.payload] }
      }
      case 'SET_TEAM_MEMBER_AS_CONTACT_REQUEST': {
        return {
          ...state,
          updatingTeamMember: true
        };
      }
      case 'SET_TEAM_MEMBER_AS_CONTACT_SUCCESS': {
        return {
          ...state,
          items: unsetContactPerson(state.items, action.payload.contactPersons),
          updatingTeamMember: false
        }
      }
      case 'SET_TEAM_MEMBER_AS_CONTACT_FAILED': {
        return {
          ...state,
          updatingTeamMember: false
        }
      }
      case 'UPDATE_TEAM_MEMBER_PHONE_REQUEST': {
        return {
          ...state,
          updatingTeamMember: true
        }
      }
      case 'UPDATE_TEAM_MEMBER_PHONE_SUCCESS': {
        return {
          ...state,
          items: state.items.map(item => [action.payload].find(updated => updated.id === item.id) || item ),
          updatingTeamMember: false
        }
      }
      case 'UPDATE_TEAM_MEMBER_PHONE_FAILED': {
        return {
          ...state,
          items: state.items.map(item => [action.payload].find(updated => updated.id === item.id) || item ),
          updatingTeamMember: false
        }
      }
      case 'SET_TEAM_MEMBER_FOR_ACTIVE_SUBSCRIPTION_REQUEST': {
        return { ...state, updatingTeamMember: true }
      }
      case 'SET_TEAM_MEMBER_FOR_ACTIVE_SUBSCRIPTION_SUCCESS': {
        return { ...state, items: state.items.map(item => [action.payload].find(updated => updated.id === item.id) || item ), updatingTeamMember: false }
      }
      case 'SET_TEAM_MEMBER_FOR_ACTIVE_SUBSCRIPTION_FAILED': {
        return { ...state, updatingTeamMember: false }
      }
      case 'UPDATE_TEAM_PROPERTY_PARTICULARS_STYLES_REQUEST': {
        return { ...state,  loadingPPBranding: true };
      }
      case 'UPDATE_TEAM_PROPERTY_PARTICULARS_STYLES_SUCCESS': {
        return { ...state,  loadingPPBranding: false, ppBranding: action.payload };
      }
      case 'UPDATE_TEAM_PROPERTY_PARTICULARS_STYLES_FAILED': {
        return { ...state,  loadingPPBranding: false };
      }
      case 'UPDATE_TEAM_PROPERTY_PARTICULARS_LOGO_REQUEST': {
        return { ...state,  updatingPPLogo: true };
      }
      case 'UPDATE_TEAM_PROPERTY_PARTICULARS_LOGO_SUCCESS': {
        return { ...state,  updatingPPLogo: false, ppBranding: updateLogoField(state.ppBranding, action.payload?.ppCompanyLogo, 'ppCompanyLogo') };
      }
      case 'UPDATE_TEAM_PROPERTY_PARTICULARS_LOGO_FAILED': {
        return { ...state,  updatingPPLogo: false };
      }
      case 'UPDATE_TEAM_OFFERS_STYLES_REQUEST': {
        return { ...state,  loadingOffersBranding: true };
      }
      case 'UPDATE_TEAM_OFFERS_STYLES_SUCCESS': {
        return { ...state,  loadingOffersBranding: false, offersBranding: action.payload };
      }
      case 'UPDATE_TEAM_OFFERS_STYLES_FAILED': {
        return { ...state,  loadingOffersBranding: false };
      }
      case 'UPDATE_TEAM_OFFERS_LOGO_REQUEST': {
        return { ...state,  updatingOffersLogo: true };
      }
      case 'UPDATE_TEAM_OFFERS_LOGO_SUCCESS': {
        return { ...state,  updatingOffersLogo: false, offersBranding: updateLogoField(state.offersBranding, action.payload?.offerCompanyLogo, 'offerCompanyLogo') };
      }
      case 'UPDATE_TEAM_OFFERS_LOGO_FAILED': {
        return { ...state,  updatingOffersLogo: false };
      }

      default:
        return state;
    }
  }
);

// FETCH DATA
function* fetchTeamMembers(action) {
  try {
    const params = action.payload.params;
    const filters = setFilters(params);
    const teamMembers = yield axios.get(`/team-members/${filters}`);
    yield put({ type: "FETCH_TEAM_MEMBERS_SUCCESS", payload: teamMembers.data || [{ error: teamMembers.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}

// FETCH PROPERTY PARTICULARS
function* fetchTeamPropertyParticularsStyles(action) {
  try {
    const {teamID} = action.payload;
    const PPStyles = yield axios.get(`/teams/${teamID}/branding/`);
    yield put({ type: "GET_TEAM_PROPERTY_PARTICULARS_STYLES_SUCCESS", payload: PPStyles.data || [{ error: PPStyles.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}

// FETCH OFFERS ONLINE
function* fetchTeamOffersStyles(action) {
  try {
    const {teamID} = action.payload;
    const OffersStyles = yield axios.get(`/teams/${teamID}/offer-branding/`);
    yield put({ type: "GET_TEAM_OFFERS_STYLES_SUCCESS", payload: OffersStyles.data || [{ error: OffersStyles.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}

// ACTIONS
function* setTeamMemberAsOwner(action) {
  try {
    const {memberId} = action.payload;
    yield axios.post(`/team-members/${memberId}/set_as_owner/`);
    yield put({
      type: "SET_TEAM_MEMBER_AS_OWNER_SUCCESS",
      payload: memberId,
      meta: actionNotification('Selected member has been set as Team Owner.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "SET_TEAM_MEMBER_AS_OWNER_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* deleteTeamMember(action) {
  try {
    const {memberId} = action.payload;
    yield axios.delete(`/team-members/${memberId}/`);
    yield put({
      type: "DELETE_TEAM_MEMBER_SUCCESS",
      payload: memberId,
      meta: actionNotification('Selected team member has been removed.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "DELETE_TEAM_MEMBER_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* inviteTeamMember(action) {
  try {
    const {newMember} = action.payload;
    const inviteMember = yield axios.post(`/team-members/`, newMember);
    yield put({
      type: "INVITE_TEAM_MEMBER_SUCCESS",
      payload: inviteMember.data,
      meta: actionNotification('Invitation has been sent.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "INVITE_TEAM_MEMBER_FAILED",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data.error) || 'Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* setTeamMemberAsContact(action) {
  try {
    const {teamId, data} = action.payload;
    const setContacts = yield axios.patch(`/teams/${teamId}/`, data);
    yield put({
      type: "SET_TEAM_MEMBER_AS_CONTACT_SUCCESS",
      payload: setContacts.data,
      meta: actionNotification('Selected member has been set as Contact Person.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "SET_TEAM_MEMBER_AS_CONTACT_FAILED",
      payload: err.status,
      meta: actionNotification(err.data.detail, 'error')
    });
  }
}
function* unsetTeamMemberAsContact(action) {
  try {
    const {teamId, data} = action.payload;
    const setContacts = yield axios.patch(`/teams/${teamId}/`, data);
    yield put({
      type: "SET_TEAM_MEMBER_AS_CONTACT_SUCCESS",
      payload: setContacts?.data,
      meta: actionNotification('Selected member has been removed from Contact Person list.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "SET_TEAM_MEMBER_AS_CONTACT_FAILED",
      payload: err.status,
      meta: actionNotification(err.data.detail, 'error')
    });
  }
}
function* setTeamAsActive(action) {
  try {
    const teamId = action.payload;
    const data = teamId ? {id: teamId} : {};
    yield axios.post(`/teams/set_active/`, data);
    yield put({ type: "SET_TEAM_AS_ACTIVE_SUCCESS" });
    window.location.reload();
  }
  catch(err) {
    yield put({
      type: "SET_TEAM_AS_ACTIVE_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* updateTeamMemberPhone(action) {
  try {
    const {memberId, data} = action.payload;
    const updatePhone = yield axios.put(`/team-members/${memberId}/staff/change-phone/`, data);
    yield put({
      type: "UPDATE_TEAM_MEMBER_PHONE_SUCCESS",
      payload: updatePhone.data,
      meta: actionNotification('Phone number for selected Team Member has been updated.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "UPDATE_TEAM_MEMBER_PHONE_FAILED",
      payload: err.status,
      meta: actionNotification(err.data.error || 'Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* setTeamMemberForActiveSubscription(action) {
  try {
    const {memberId} = action.payload;
    const activateSubscription = yield axios.post(`/team-members/${memberId}/activate-subscription/`);
    yield put({
      type: "SET_TEAM_MEMBER_FOR_ACTIVE_SUBSCRIPTION_SUCCESS",
      payload: activateSubscription.data,
      meta: actionNotification('Subscription has been activated for selected Team Member.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "SET_TEAM_MEMBER_AS_OWNER_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}

// ACTION PROPERTY PARTICULARS
function* updateTeamPropertyParticularsStyles(action) {
  try {
    const {teamID, data} = action.payload;
    const updatePPStyles = yield axios.post(`/teams/${teamID}/branding/`, data);
    yield put({
      type: "UPDATE_TEAM_PROPERTY_PARTICULARS_STYLES_SUCCESS",
      payload: updatePPStyles.data,
      meta: actionNotification('Your Team styles for Property Particulars Online has been updated.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "UPDATE_TEAM_PROPERTY_PARTICULARS_STYLES_FAILED",
      payload: err.status,
      meta: actionNotification(err.data.error || 'Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* updateTeamPropertyParticularsLogo(action) {
  try {
    const logo = action.payload;
    const formData = new FormData();
    logo && formData.append('pp_company_logo', logo);
    const updatePPLogo = yield axios.post(`/team/pp-logo/`, formData);
    yield put({
      type: "UPDATE_TEAM_PROPERTY_PARTICULARS_LOGO_SUCCESS",
      payload: updatePPLogo.data,
      meta: actionNotification('Your Team Logo for Property Particulars Online has been updated.', 'success')
    });
  }
  catch(err) {
    console.log(err);
    yield put({
      type: "UPDATE_TEAM_PROPERTY_PARTICULARS_LOGO_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}

// ACTION OFFERS ONLINE
function* updateTeamOffersStyles(action) {
  try {
    const {teamID, data} = action.payload;
    const updateOffersStyles = yield axios.post(`/teams/${teamID}/offer-branding/`, data);
    yield put({
      type: "UPDATE_TEAM_OFFERS_STYLES_SUCCESS",
      payload: updateOffersStyles.data,
      meta: actionNotification('Your Team styles for Offers Online has been updated.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "UPDATE_TEAM_OFFERS_STYLES_FAILED",
      payload: err.status,
      meta: actionNotification(err.data.error || 'Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* updateTeamOffersLogo(action) {
  try {
    const logo = action.payload;
    const formData = new FormData();
    logo && formData.append('offer_company_logo', logo);
    const updateOfferLogo = yield axios.post(`/team/offer-logo/`, formData);
    yield put({
      type: "UPDATE_TEAM_OFFERS_LOGO_SUCCESS",
      payload: updateOfferLogo.data,
      meta: actionNotification('Your Team Logo for Offers Online has been updated.', 'success')
    });
  }
  catch(err) {
    console.log(err);
    yield put({
      type: "UPDATE_TEAM_OFFERS_LOGO_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}

export function* saga() {
  yield takeLatest('FETCH_TEAM_MEMBERS_REQUEST', fetchTeamMembers);
  yield takeLatest('GET_TEAM_PROPERTY_PARTICULARS_STYLES_REQUEST', fetchTeamPropertyParticularsStyles);
  yield takeLatest('GET_TEAM_OFFERS_STYLES_REQUEST', fetchTeamOffersStyles);
  yield takeLatest('SET_TEAM_MEMBER_AS_OWNER_REQUEST', setTeamMemberAsOwner);
  yield takeLatest('DELETE_TEAM_MEMBER_REQUEST', deleteTeamMember);
  yield takeLatest('INVITE_TEAM_MEMBER_REQUEST', inviteTeamMember);
  yield takeLatest('SET_TEAM_MEMBER_AS_CONTACT_REQUEST', setTeamMemberAsContact);
  yield takeLatest('UNSET_TEAM_MEMBER_AS_CONTACT_REQUEST', unsetTeamMemberAsContact);
  yield takeLatest('SET_TEAM_AS_ACTIVE_REQUEST', setTeamAsActive);
  yield takeLatest('UPDATE_TEAM_MEMBER_PHONE_REQUEST', updateTeamMemberPhone);
  yield takeLatest('SET_TEAM_MEMBER_FOR_ACTIVE_SUBSCRIPTION_REQUEST', setTeamMemberForActiveSubscription);
  yield takeLatest('UPDATE_TEAM_PROPERTY_PARTICULARS_STYLES_REQUEST', updateTeamPropertyParticularsStyles);
  yield takeLatest('UPDATE_TEAM_PROPERTY_PARTICULARS_LOGO_REQUEST', updateTeamPropertyParticularsLogo);
  yield takeLatest('UPDATE_TEAM_OFFERS_STYLES_REQUEST', updateTeamOffersStyles);
  yield takeLatest('UPDATE_TEAM_OFFERS_LOGO_REQUEST', updateTeamOffersLogo);
}

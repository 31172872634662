import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import RealTimeMailPage from "./RealTimeMailPage";
import {destroyNotification} from "../../../../constants/actionCreators/notifications";
import {createKudosEmailSignInLog, sendKudosEmailConfirmation} from "app/crud/offers.crud";
import {
  createKudosEmailIndustrialSignInLog,
  sendKudosEmailIndustrialConfirmation
} from "app/crud/estates/industrial/offers-industrial.crud";

const mapStateToProps = (state) => ({
  notifications: state.notifications,
});
const mapDispatchToProps = {
  destroyNotification: destroyNotification,
  sendKudosEmailConfirmation: (data) => sendKudosEmailConfirmation(data),
  createKudosEmailSignInLog: (data) => createKudosEmailSignInLog(data),
  sendKudosEmailIndustrialConfirmation: (data) => sendKudosEmailIndustrialConfirmation(data),
  createKudosEmailIndustrialSignInLog: (data) => createKudosEmailIndustrialSignInLog(data)
};
export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RealTimeMailPage)
);
import {connect} from "react-redux";
import AdminManageAccountRequests from "./AdminManageAccountRequests";
import {fetchAccountRequests} from "../../../crud/accountRequest.crud";

const mapStateToProps = ({ accountRequest }) => ({
  data: accountRequest?.data,
  itemsPerPage: accountRequest?.itemsPerPage,
  itemsCount: accountRequest?.itemsCount,
  totalItems: accountRequest?.total,
  loading: accountRequest?.loading
});
const mapDispatchToProps = {
  fetchAccountRequests: ({params}) => fetchAccountRequests(params)
};

const withRedux = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withRedux(AdminManageAccountRequests);

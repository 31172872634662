import React from "react";
import styles from "../index.module.scss";
import {toAbsoluteUrl} from "_metronic";
import {FormattedMessage} from "react-intl";

const PaywallDirectContact = () => {
  return(
    <div className={styles.paywallContactWrapper}>
      <p><FormattedMessage id="Are you pressed for time? Don't wait, call me!"/></p>
      <div>
        <img src={toAbsoluteUrl("media/team/rh.jpg")} alt="REDD App"/>
        <div>
          <p>Rusłan Hajduk</p>
          <p>Junior Business Development Coordinator</p>
          <p>+48 575 132 099</p>
        </div>
      </div>
    </div>
  );
};

export default PaywallDirectContact;
export default {
  OFFICE_BUILDING: {
    id: 1,
    name: 'BUILDING.TYPE.OFFICE'
  },
  APARTMENT_HOUSE: {
    id: 2,
    name: 'BUILDING.TYPE.APARTMENT'
  },
  WAREHOUSE:  {
    id: 3,
    name: 'BUILDING.TYPE.WAREHOUSE'
  },
  SHOPPING_CENTER:  {
    id: 4,
    name: 'BUILDING.TYPE.SHOPPING_CENTER'
  },
  HQ:  {
    id: 5,
    name: 'BUILDING.TYPE.HQ'
  },
  TENEMENT_HOUSE:  {
    id: 6,
    name: 'BUILDING.TYPE.TENEMENT_HOUSE'
  },
  OTHER:  {
    id: 7,
    name: 'BUILDING.TYPE.OTHER'
  }
}
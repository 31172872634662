import React from "react";
import {FormControl, Icon, InputLabel, MenuItem, Select} from "@material-ui/core";
import ModalCustom from "app/partials/components/ModalCustom";
import IconColorMUI from "app/partials/components/IconColorMUI";
import NavItem from "app/partials/components/Navigations/NavItem";
import LoadingScreen from "app/partials/components/LoadingScreen";
import InputIcon from "app/partials/components/InputIcon";
import {FormattedMessage} from "react-intl";
import {ThemeProvider} from "_metronic";

export default class UserChangeCountry extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      open: false,
      selected: props.user[this.props.toSelect] || 'PL',
    };
  }

  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleToggleSelect = () => {
    this.setState({
      open: !this.state.open,
    });
  };
  handleChange = (event) => {
    this.setState({
      selected: event.target.value,
    });
  };
  handleSave = () => {
    const {selected} = this.state;
    const {onSave} = this.props;
    const data = {
      country: selected
    };
    onSave(data);
    this.handleCloseModal();
  };

  render() {
    const {intl} = this.props;
    const {open, selected} = this.state;
    const {
      btnSelect
    } = this.props;
    const countries = [
      {
        "id": "PL",
        "name": intl.formatMessage({id: "Poland"})
      },
      {
        "id": "CS",
        "name": intl.formatMessage({id: "Czech Republic"})
      },
      {
        "id": "SK",
        "name": intl.formatMessage({id: "Slovakia"})
      },
      {
        "id": "HU",
        "name": intl.formatMessage({id: "Hungary"})
      },
      {
        "id": "RO",
        "name": intl.formatMessage({id: "Romania"})
      }
    ];
    const name = countries?.find((item) => item.id === selected)?.name;

    if (!countries) {
      return <LoadingScreen/>;
    }
    return (
      <ModalCustom
        ref={this.child}
        btn={
          btnSelect ? (
            <ThemeProvider>
              <InputIcon
                width={150}
                name="dialogButton"
                icon={<Icon color="primary">public</Icon>}
                value={name}
              />
            </ThemeProvider>
          ) : (
            <NavItem
              name="changeCountry"
              title={<FormattedMessage id="GENERAL.PHRASE.CHANGE_COUNTRY"/>}
              label={<FormattedMessage id="GENERAL.PHRASE.SELECT_COUNTRY"/>}
              icon={<IconColorMUI icon="public" color="warning"/>}
            />
          )
        }
        title={<FormattedMessage id="GENERAL.PHRASE.CHANGE_COUNTRY"/>}
        handleSave={this.handleSave}
        btnFlex={btnSelect}
        btnStyle={{marginRight: 15}}
      >
        <FormControl style={{width: "100%"}}>
          <InputLabel htmlFor="new_user_market">
            <FormattedMessage id="GENERAL.PHRASE.SELECT_COUNTRY"/>
          </InputLabel>
          <Select
            open={open}
            onClose={this.handleToggleSelect}
            onOpen={this.handleToggleSelect}
            value={selected}
            onChange={this.handleChange}
            inputProps={{
              name: "market",
              id: "new_user_market",
            }}
          >
            {countries.map((country, index) => {
              return (
                <MenuItem key={index} value={country.id}>
                  {country.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </ModalCustom>
    );
  }
}

// FETCH
export const fetchTransactions = (params) => ({
  type: "FETCH_SYMMCO_TRANSACTIONS_REQUEST",
  payload: {
    params,
  },
});
export const fetchTransactionsReset = (initOrderBy, isEditable) => ({
  type: 'FETCH_SYMMCO_TRANSACTIONS_RESET_REQUEST',
  payload: {
    initOrderBy,
    isEditable
  }
});
export const fetchTransaction = (id) => ({
  type: "FETCH_SYMMCO_TRANSACTION_REQUEST",
  payload: {id},
});
export const fetchTransactionsXls = (team, params) => ({
  type: "FETCH_SYMMCO_TRANSACTIONS_XLS_REQUEST",
  payload: {
    team,
    params
  },
});
export const fetchTransactionsDynamicData = (params) => ({
  type: "FETCH_SYMMCO_TRANSACTIONS_DYNAMIC_DATA_REQUEST",
  payload: {
    params
  }
});
export const fetchTransactionsQuarters = () => ({
  type: "FETCH_SYMMCO_TRANSACTIONS_QUARTERS_REQUEST"
});
export const fetchTransactionsDatabaseStatus = () => ({
  type: "FETCH_SYMMCO_TRANSACTIONS_DATABASE_STATUS_REQUEST"
});

// Reset Symmco Transactions Loading States
export const resetSymmcoTransactionsLoadingStates = () => ({
  type: "RESET_SYMMCO_TRANSACTIONS_LOADING_STATES_REQUEST"
});
import React, {useState} from "react";
import PaywallLogo from "../components/PaywallLogo";
import styles from "../index.module.scss";
import {ReactComponent as IconLock} from "app/media/icons/icon-lock.svg";
import PaywallModal from "../components/PaywallModal";
import _ from "lodash";
import {fetchBannerData, getBannerDataValue} from "../helpers";
import ReactVisibilitySensor from "react-visibility-sensor";
import LoadingScreen from "../../LoadingScreen";
import connect from "react-redux/es/connect/connect";

const PaywallBannerSimple = (
  {
    userAccountType,
    bannerVariant = "simple",
    modalVariant = "default"
  }
) => {
  const [bannerData, setBannerData] = useState(null);
  const [bannerDataLoading, setBannerDataLoading] = useState(false);

  // Handlers
  const handleOnViewportFetch = async (isVisible) => {
    if(isVisible && !bannerDataLoading && _.isEmpty(bannerData)) {
      setBannerDataLoading(true);
      const bannerData = await fetchBannerData(bannerVariant, userAccountType);
      setBannerData(bannerData);
      setBannerDataLoading(false);
    }
  };

  return(
    <div className={styles.paywallBannerSimpleWrapper}>
      <div className={styles.simpleLockWrapper}>
        <ReactVisibilitySensor
          onChange={(isVisible) => handleOnViewportFetch(isVisible)}
          scrollCheck
          partialVisibility
        >
          <IconLock/>
        </ReactVisibilitySensor>
      </div>
      <div className={styles.simpleContentWrapper}>
        <PaywallLogo brand/>
        {bannerDataLoading ? <LoadingScreen/> :
        <>
        <div className={styles.simpleContent}>
          <p><strong>{getBannerDataValue(bannerData, "title")}</strong></p>
          <p>{getBannerDataValue(bannerData, "description")}</p>
        </div>
        <PaywallModal
          triggerLabel={getBannerDataValue(bannerData, "button")}
          bannerVariant={modalVariant}
          triggerBannerVariant={bannerVariant}
        />
        </>
        }
      </div>
    </div>
  );
}

const mapStateToProps = store => ({
  userAccountType: store?.user?.data?.proData?.proGroup
});
export default connect(
  mapStateToProps
)(PaywallBannerSimple);
import React from "react";
import {Checkbox, FormControlLabel, Grid, Typography} from "@material-ui/core";
import {fetchOfficeAmenities, updateOfficeAmenities} from "app/crud/estates/offices/offices.crud";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import _ from "lodash";

class EditOfficeAmenities extends React.Component {
  constructor(props) {
    super(props);
    this.state = props?.amenities;
    this.initialState = this.state;

    const {fetchOfficeAmenities, officeId} = this.props;
    fetchOfficeAmenities(officeId);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Fetch Amenities
    if(!prevProps.amenities && prevProps.amenities !== this.props.amenities) {
      this.setState({
        ...this.props.amenities
      }, () => this.initialState = this.state);
    }
    // Handle Change Detect
    this.props.onUnsavedData(!_.isEqual(this.initialState, this.state));
    // Handle Save
    if(!prevProps.requestSave && this.props.requestSave) {
      this.handleSave();
    }
    // Detect Office Updating
    if(prevProps.amenitiesUpdating !== this.props.amenitiesUpdating) {
      this.props.onRequestSaving(this.props.amenitiesUpdating);
    }
  }

  // Handlers
  handleCheckboxChange = e => {
    this.setState({
      [e.target.id]: !this.state[e.target.id]
    });
  };
  handleSave = () => {
    const {officeId, updateOfficeAmenities} = this.props;
    this.initialState = this.state;
    updateOfficeAmenities(officeId, this.state, "amenities");
  };

  render() {
    const {amenities, amenitiesUpdating, intl} = this.props;
    let amenitiesArray = [];
    amenities && Object.keys(amenities).map((item) =>
      amenitiesArray.push({
        "id": item,
        "label": intl.formatMessage({id: `${item}`})
      })
    );

    return(
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" style={{color: "#ff4626"}}><FormattedMessage id="VOCABULARY.Amenities"/></Typography>
        </Grid>
        <Grid item xs={12}>
          {this.state && amenitiesArray?.length > 0 && amenitiesArray?.map((item, index) =>
              <FormControlLabel
                key={index}
                control={
                  <Checkbox checked={this.state[item["id"]]} id={item["id"]} onChange={this.handleCheckboxChange} value={item["id"]} color="primary"/>
                }
                label={item["label"]}
                disabled={amenitiesUpdating}
              />
            )
          }
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (store) => ({
  amenities: store.offices.amenities,
  amenitiesLoading: store.offices.amenitiesLoading,
  amenitiesUpdating: store.offices.amenitiesUpdating
});
const mapDispatchToProps = {
    fetchOfficeAmenities: (officeId) => fetchOfficeAmenities(officeId),
    updateOfficeAmenities: (officeId, data, backgroundRefresh) => updateOfficeAmenities(officeId, data, backgroundRefresh)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditOfficeAmenities)
);
import GoogleMap from "app/pages/offices/components/MapView/GoogleMap/index";
import React from "react";
import compose from "recompose/compose";
import defaultProps from "recompose/defaultProps";
import styles from "app/pages/offices/components/MapView/MapView.module.scss";
import {withState} from "recompose";
import TableDrawer from "app/partials/components/TableDrawer";
import {TABLE_CELLS} from "app/partials/components/TableProperties";
import {toAbsoluteUrl} from "_metronic";
import {Marker} from "react-google-maps";
import LoadingScreen from "app/partials/components/LoadingScreen";
import clsx from "clsx";
import GOOGLE_MAPS_OPTIONS from "app/constants/GOOGLE_MAPS_OPTIONS";
import {ROUTES} from "../../../../constants";
import PropertyExpandedCardPreview from "../../../../partials/components/_DataParts/PropertyExpandedCardPreview";
import _ from "lodash";

// Initial map LatLng and zoom settings
const DEFAULT_LOCATION = {lat: 52.38229994194257, lng: 18.903042342058622};
const DEFAULT_ZOOM = 6;

// Component that handle Map View for Properties data (offices, units)

class MapView extends React.Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    // Initial Map Settings
    this.state = {
      zoom: DEFAULT_ZOOM,
      center: DEFAULT_LOCATION,
      drawerOpen: false,
      clusteringGrid: 60,
      sourceMarker: false,
      openInfoWindowMarkerId: '',
      isOfficeEditable: false,
      dbClickDetected: false,
      markerClickedData: null
    };
  }
  componentDidMount() {
    document.addEventListener("dblclick", this.handleDoubleClick);
  }
  componentWillUnmount() {
    document.removeEventListener("dblclick", this.handleDoubleClick);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    // dbClickDetected - state reset
    if(!prevState.dbClickDetected && this.state.dbClickDetected) {
      //
      setTimeout(() => {
        this.setState({
          dbClickDetected: false
        })
      }, 500);
    }
    // Handle marker click - if not double-clicked
    if(!_.isEmpty(this.state.markerClickedData) && !_.isEqual(prevState.markerClickedData, this.state.markerClickedData)) {
      setTimeout(() => {
        if(!this.state.dbClickDetected) {
          const markerId = this.state.markerClickedData?.propertyID;
          const markerIndex = this.state.markerClickedData?.index;

          this.handleMarkerClick(markerId, markerIndex);
        }
      }, 100);
    }
  }

  // Handle DoubleClick
  handleDoubleClick = () => {
    this.setState({
      dbClickDetected: true
    });
  }
  // Handle Map zoom and cluster grid
  handleZoomChange = () => {
    const zoomLevel = this.mapRef.current.getZoom();
    this.setState({
      zoom: zoomLevel,
      clusteringGrid: zoomLevel < 17 ? 50 : 0
    });
  };
  // Handle close TableDrawer
  handleDrawerClose = () => {
    this.setState({
      drawerOpen: false,
      sourceMarker: false
    });
  };
  // Function to handle map fit bounds
  fitToBounds = (markers) => {
    if (markers.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      markers.map((marker) => {
        bounds.extend(new window.google.maps.LatLng(
          marker.lat,
          marker.lng
        ));
        return true;
      });
      this.mapRef.current.fitBounds(bounds);
    }
  };
  // Map interactions
  handleMarkerClickTrigger = (e, propertyID, index) => {
    const mapZoom = this.mapRef.current.getZoom();
    const lat = e?.latLng?.lat();
    const lng = e?.latLng?.lng();

    setTimeout(() => {
      const {dbClickDetected} = this.state;
      const markerClickedData = dbClickDetected ? null : {propertyID, index};

      this.setState({
        markerClickedData
      }, () => {
        if(dbClickDetected) {
          this.setState({
            center: { lat, lng },
            zoom: mapZoom+1
          });
        }
      });
    }, 200);
  }
  handleMarkerClick = (propertyID, index) => {
    if (propertyID) {
      this.props.getProperty(propertyID);
      this.props.getPropertyChilds(propertyID);

      this.setState({
        sourceMarker: true,
        drawerOpen: true,
        markerClickedData: null
      });
    }
    else {
      this.setState({
        openInfoWindowMarkerId: index,
        markerClickedData: null
      });
    }
  };
  handleCloseInfoWindow = () => {
    this.setState({
      openInfoWindowMarkerId: ''
    });
  };
  // Render Marker - icon type based on permissions and buildings status
  renderMarkerIcon = (markerColor, isActive) => {
    const pinGreen = toAbsoluteUrl("media/icons/markers/pin-green.png");
    const pinOrange = toAbsoluteUrl("media/icons/markers/pin-orange.png");
    const pinRed = toAbsoluteUrl("media/icons/markers/pin-neon.png");
    const pinBlue = toAbsoluteUrl("media/icons/markers/pin-blue.png");
    const pinBlack = toAbsoluteUrl("media/icons/markers/pin-black.png");
    let pin = pinRed;
    if (isActive) {
      pin = markerColor !== null ? pinBlack : pinRed;
    }
    else if (markerColor === "green") {
      pin = pinGreen;
    }
    else if (markerColor === "orange") {
      pin = pinOrange;
    }
    else if (markerColor === "red") {
      pin = pinRed;
    }
    else if (markerColor === "blue") {
      pin = pinBlue;
    }
    return pin;
  };

  render() {
    const {
      markers,
      options,
      property,
      clustersLoading,
      propertyLoading,
      limitBarEnable,
      propertyChilds,
      propertyChildsLoading,
      markerFilters
    } = this.props;
    const {drawerOpen, dbClickDetected} = this.state;
    const propertyDataReady = !propertyLoading && property;

    return (
      <div className={clsx(styles.mapWidth, limitBarEnable && styles.limitBarMapHeight)}>
        {clustersLoading ? <LoadingScreen/> : (
          <GoogleMap
            mapRef={this.mapRef}
            options={options}
            center={this.state.center}
            zoom={this.state.zoom}
            onZoomChanged={this.handleZoomChange}
            onFitBounds={() => this.fitToBounds(markers)}
            onClick={() => this.handleCloseInfoWindow()}
          >
            {markers.map((marker, index) => {
              const lat = Number(marker.lat);
              const lng = Number(marker.lng);
              const isMarkerTypeExcluded = markerFilters?.includes(marker?.mapColor);

              return (
                <Marker
                  key={index}
                  position={{lat: lat, lng: lng}}
                  onClick={(e) => this.handleMarkerClickTrigger(e, marker?.park, index)}
                  icon={this.renderMarkerIcon(marker.mapColor, false)}
                  opacity={marker.park ? 1 : 0.5}
                  clickable={!dbClickDetected}
                  visible={!isMarkerTypeExcluded}
                />
              )
            })}
          </GoogleMap>
        )}
        {/* Table Units List open on marker click or units list item */}
        <TableDrawer
          title={
            <PropertyExpandedCardPreview
              propertyID={property?.id}
              title={property?.name}
              semiTitle={property?.address}
              semiTitle2={property?.addressCity}
              thumb={property?.thumbnail}
              route={ROUTES.INDUSTRIAL_PARK}
            />
          }
          titleAdvanced
          headRows={
            [
              {
                id: 'building_name',
                numeric: false,
                disablePadding: false,
                label: 'VOCABULARY.Building',
                sort: false
              },
              {
                id: 'status',
                numeric: false,
                disablePadding: false,
                label: 'INDUSTRIAL.TABLE.STATUS',
                sort: false
              },
              {
                id: 'available_area',
                numeric: false,
                disablePadding: false,
                label: 'INDUSTRIAL.TABLE.AVAILABLE_AREA',
                sort: false
              },
              {
                id: 'total_area',
                numeric: false,
                disablePadding: false,
                label: 'INDUSTRIAL.TABLE.TOTAL_AREA',
                sort: false
              },
              {
                id: 'available_units',
                numeric: true,
                disablePadding: false,
                label: 'INDUSTRIAL.TABLE.NO_OF_AVAILABLE_UNITS',
                sort: true
              },
              {
                id: 'available_at',
                numeric: false,
                disablePadding: false,
                label: 'INDUSTRIAL.TABLE.BUILDING_AVAILABILITY',
                sort: false
              },
            ]
          }
          bodyRows={propertyChilds}
          bodyRowsReady={propertyChildsLoading || propertyLoading}
          target={TABLE_CELLS.INDUSTRIAL_PARK_WAREHOUSES}
          parentId={propertyDataReady && property.id}
          parentData={property}
          parentPreviewData={property}
          externalDrawerState={drawerOpen}
          handleExternalClose={() => this.handleDrawerClose()}
          proNotMandatory
        />
      </div>
    );
  }
}

export const withCluster = compose(
  defaultProps({
    style: {},
    options: {
      minZoom: 3,
      maxZoom: 20,
      styles: GOOGLE_MAPS_OPTIONS.STYLES,
    },
  }),
  withState('mapProps')
);

export default withCluster(MapView);

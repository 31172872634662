import React, {useEffect, useState} from "react";
import {toAbsoluteUrl} from "../../../../../_metronic";
import {lngLatToMeters, metersToPixels} from "global-mercator";
import styles from "./index.module.scss";
import {FormattedMessage} from "react-intl";
import _ from "lodash";
import ReactVisibilitySensor from "react-visibility-sensor";

/*
https://maps.googleapis.com/maps/api/staticmap
?center=51.04004548753835,16.92846239705302
&zoom=10
&size=350x310
&key=AIzaSyDMROiw9UzdnWoXeLjnfHm8Y3v-TBiQJLw
 */
const mapWidth = 350;
const mapHeight = 310;
const mapZoom = 10;
const mapCenter = {
  lat: 51.04004548753835,
  lng: 16.92846239705302
};

export default function PropertyRegionMap({...props}) {
  const basic = props?.basic;
  const mapMarkers = props?.regionMap;
  const [initLoaded, setInitLoaded] = useState(false);
  const [center, setCenter] = useState(mapCenter);
  const [radius, setRadius] = useState(0);
  const [markers, setMarkers] = useState([]);

  // Basic Data
  useEffect(() => {
    const propertyLat = basic?.geoLatitude;
    const propertyLng = basic?.geoLongitude;
    const latLng = {
      lat: propertyLat,
      lng: propertyLng
    }

    if(propertyLat && propertyLng) {
      setCenter(latLng);
    }
  }, [basic]);
  // Map Markers
  useEffect(() => {
    if(mapMarkers?.length > 0) {
      setMarkers(mapMarkers);
    }
  }, [mapMarkers]);

  // Handlers
  const handleOnViewportFetch = (isVisible) => {
    const {fetchIndustrialParkRegionMap, regionMap, regionMapLoading, match} = props;
    const {id} = match?.params;

    setTimeout(() => {
      if (isVisible && !initLoaded && !regionMapLoading && _.isEmpty(regionMap) && id) {
        setInitLoaded(true);
        fetchIndustrialParkRegionMap(id);
      }
    }, 200);
  }
  // Handlers Map
  const handleMarkersPixelPosition = () => {
    /* Calculate center as pixel coordinates in image. */
    const image_center_offset_x = Math.round(mapWidth / 2);
    const image_center_offset_y = Math.round(mapHeight / 2);
    // Calculate Map Center in Pixels
    const mapCenterToMeters = lngLatToMeters([center?.lng, center?.lat]);
    const mapCenterToPixels = metersToPixels(mapCenterToMeters, mapZoom);
    const mapCenterX = mapCenterToPixels?.[0];
    const mapCenterY = mapCenterToPixels?.[1];
    // Calculate Map 15km radius
    const metersDiff = 15000*0.55;
    const radiusInMeters = 15000+metersDiff;
    const mapCenterMetersX = mapCenterToMeters?.[0];
    const mapCenterMetersY = mapCenterToMeters?.[1];
    const centerXMinus15 = mapCenterMetersX - radiusInMeters;
    const centerMinus15ToPixels = metersToPixels([centerXMinus15, mapCenterMetersY], mapZoom);
    const mapCenterMinus15X = centerMinus15ToPixels?.[0];
    const minus15DiffX = mapCenterMinus15X - mapCenterX;

    const mapRadius15 = Math.abs(minus15DiffX*2);

    if(mapRadius15 !== radius) {
      setRadius(mapRadius15);
    }

    const pixelPosition = markers?.map(marker => {
      // Calculate Marker Center in Pixels
      const markerToMeters = lngLatToMeters([marker?.geoLongitude, marker?.geoLatitude]);
      const markerToPixels = metersToPixels(markerToMeters, mapZoom);
      const markerX = markerToPixels?.[0];
      const markerY = markerToPixels?.[1];
      //
      const globalPixelDiffX = markerX - mapCenterX;
      const globalPixelDiffY = markerY - mapCenterY;
      const delta_x  = image_center_offset_x + globalPixelDiffX;
      const delta_y  = image_center_offset_y + (globalPixelDiffY < 0 ? Math.abs(globalPixelDiffY) : -globalPixelDiffY);
      const marker_x = delta_x;
      const marker_y = delta_y;

      return {
        markerToMeters,
        marker_x,
        marker_y
      };
    });

    return(
      <>
        {pixelPosition?.map((item, index) => {
          return(
            <span
              key={index}
              className={styles.mapMarker}
              style={{
                top: `${item?.marker_y}px`,
                left: `${item?.marker_x}px`
              }}
            >
              <img src={toAbsoluteUrl("media/icons/markers/pin.png")} alt="REDD"/>
            </span>
          );
        })}
      </>
    );
  }

  return(
    <div>
      <div
        className={styles.regionMapWrapper}
        style={{
          width: `${mapWidth}px`,
          height: `${mapHeight}px`
        }}
      >
        <ReactVisibilitySensor
          onChange={(isVisible) => handleOnViewportFetch(isVisible)}
          scrollCheck
          partialVisibility
        >
          <img src={basic?.distanceMap} alt="REDD"/>
        </ReactVisibilitySensor>
        <div className={styles.mapMarkersWrapper}>
          {handleMarkersPixelPosition()}
        </div>
        <div
          className={styles.mapRadiusWrapper}
          style={{
            "--total-width": `${radius}px`
          }}
        >
          <span className={styles.radius1} style={{"--border-width": `${Number(radius/6)}px`}}/>
          <span className={styles.radius2} style={{"--border-width": `${Number(radius/6)}px`}}/>
          <span className={styles.radius3}/>
        </div>
      </div>
      <div className={styles.mapLegendWrapper}>
        <div>
          <span/>
          <p><FormattedMessage id="to"/> 5 km</p>
        </div>
        <div>
          <span/>
          <p>5 - 10 km</p>
        </div>
        <div>
          <span/>
          <p>10 - 15 km</p>
        </div>
      </div>
    </div>
  );
}
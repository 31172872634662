import React from "react";
import {Typography} from "@material-ui/core";
import ModalCustom from "app/partials/components/ModalCustom";
import {FormattedMessage, injectIntl} from "react-intl";
import {deleteShortlistIndustrial} from "app/crud/estates/industrial/offers-industrial.crud";
import connect from "react-redux/es/connect/connect";
import Button from "../Button";
import Icon from "../Icon";

class ShortlistRemoveIndustrial extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
  }
  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleSave = () => {
    const { deleteShortlistIndustrial, offerID } = this.props;
    deleteShortlistIndustrial(offerID);
    this.handleCloseModal();
  };

  render() {
    const { offerName } = this.props;
    return (
      <ModalCustom
        ref={this.child}
        btn={
        <Button
          type={"icon"}
          color={"simple"}
          tooltip={<FormattedMessage id="SHORTLIST.PHRASE.REMOVE_SHORTLIST" />}
        >
          <Icon icon={"trash"}/>
        </Button>
        }
        title={<FormattedMessage id="SHORTLIST.PHRASE.REMOVE_SHORTLIST" />}
        handleSave={this.handleSave}
        btnConfirm={<FormattedMessage id="GENERAL.PHRASE.REMOVE" />}
      >
        <Typography>
          <FormattedMessage
            id="SHORTLIST.PHRASE.REMOVE_SHORTLIST_MESSAGE"
            values={{offerName: <strong>{offerName || "-"}</strong>}}
          />
          <br />
          <FormattedMessage id="GENERAL.PHRASE.ACTION_CONFIRMATION_MESSAGE" />
        </Typography>
      </ModalCustom>
    );
  }
}

const mapDispatchToProps = {
  deleteShortlistIndustrial: (offerID) => deleteShortlistIndustrial(offerID),
};

export default injectIntl(connect(null, mapDispatchToProps)(ShortlistRemoveIndustrial));

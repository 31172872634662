/**
 * Entry application component used to compose providers and render Routes.
 *
 * Note: Because of
 */

import React, {useEffect} from "react";
import {bindActionCreators} from "redux";
import {Provider as ReduxProvider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import {PersistGate} from "redux-persist/integration/react";
import Routes from "./app/Routes";
import {I18nProvider, LayoutSplashScreen, metronic, ThemeProvider} from "./_metronic";
import connect from "react-redux/es/connect/connect";
import {updateUserLangCode} from "./app/crud/user.crud";
import {ErrorBoundary, Provider} from "@rollbar/react";
import {stringStartWith, useWindowSize} from "./app/utils/helpers";
import PrintModeDetect from "./app/partials/components/PrintModeDetect";

function App({ store, Layout, persistor, basename, updateUserLangCode, user, lang, setLanguage }) {
  const [width] = useWindowSize();
  useEffect(() => {
    if(width < 1200) {
      document.body.classList.add("kt-aside--minimize");
    }
    else {
      document.body.classList.remove("kt-aside--minimize");
    }
  }, [width])
  useEffect(() => {
    const browserLang = navigator.language;
    const isBrowserLangEN = stringStartWith(browserLang, "en");
    const isBrowserLangPL = stringStartWith(browserLang, "pl");
    const formattedBrowserLang = isBrowserLangPL ? "pl" : "en";
    const userLanguage = user?.langCode;

    // Remove local language settings if local language is not supported by app
    if(lang !== 'pl' && lang !== 'en') {
      localStorage.removeItem("langManual");
      localStorage.removeItem("persist:i18n");
    }
    // Set initial language when user data is not loaded or userLang is not set
    if(!userLanguage) {
      if(isBrowserLangEN || isBrowserLangPL) {
        setLanguage(formattedBrowserLang);

        // Trigger only when user data is readable but user "langCode" is not set
        if(user) {
          updateUserLangCode({langCode: formattedBrowserLang});
        }
      }
    }
    // Trigger if user "langCode" not matching application current language
    else if(userLanguage !== lang) {
      // Update application current language based on user language
      setLanguage(userLanguage);
    }
  }, [updateUserLangCode, user, setLanguage, lang]);
  // Variables
  const envName = window.location.hostname === "localhost" ? "localhost" : window.location.hostname === "app2.reddplatform.com" ? "master" : "staging";
  const rollbarConfig = {
    accessToken: 'e698f5528dd742119dcc5e485a4c01dd',
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: envName
  };

  return (
    /* Provide Redux store */
    <ReduxProvider store={store} loading={<LayoutSplashScreen />}>
      <Provider config={envName === "localhost" ? {} : rollbarConfig}>
        <ErrorBoundary>
          {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
          <PersistGate persistor={persistor}>
            {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
            <React.Suspense fallback={<LayoutSplashScreen />}>
              {/* Override `basename` (e.g: `homepage` in `package.json`) */}
              <BrowserRouter basename={basename}>
                {/* Provide Metronic theme overrides. */}
                <ThemeProvider>
                  {/* Provide `react-intl` context synchronized with Redux state.  */}
                  <I18nProvider>
                    {/* Render routes with provided `Layout`. */}
                    <PrintModeDetect globalPlaceholder>
                      <Routes Layout={Layout} />
                    </PrintModeDetect>
                  </I18nProvider>
                </ThemeProvider>
              </BrowserRouter>
            </React.Suspense>
          </PersistGate>
        </ErrorBoundary>
      </Provider>
    </ReduxProvider>
  );
}

const mapStateToProps = ({ i18n, user }) => ({ lang: i18n.lang, user: user.data });
const mapDispatchToProps = (dispatch) => ({
  updateUserLangCode: langCode => dispatch(updateUserLangCode(langCode)),
  setLanguage: bindActionCreators(metronic.i18n.actions.setLanguage, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
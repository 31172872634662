import React from "react";
import styles from "./index.module.scss";
import cn from "classnames";

export default class BetaLabel extends React.Component {
  render() {
    const { children, top, right, posRelative, style } = this.props;
    return(
      <span className={cn(
        styles.subleaseLabel,
        posRelative ? styles.isRelative : undefined
      )} style={{top, right, ...style}}>{children}</span>
    );
  }
}
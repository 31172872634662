import React, {useEffect, useState} from "react";
import {toAbsoluteUrl} from "../../../../_metronic";

export default function PrintModeDetect({children, hide, placeholder, globalPlaceholder}) {
  const [isInPrintMode, setIsInPrintMode] = useState(false);

  useEffect(() => {
    const target = window.matchMedia('print');
    target.addEventListener("change", event => updatePrintModeState(event.matches));

    return () => {
      target.removeEventListener("change", event => updatePrintModeState(event.matches))
    }
  }, []);

  // Helpers
  const updatePrintModeState = (isPrintMode) => {
    setIsInPrintMode(isPrintMode);
  }

  if(isInPrintMode) {
    if(hide) {
      return null;
    }
    else if(globalPlaceholder) {
      return(
        <div style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          padding: "150px"
        }}>
          <img
            src={`${toAbsoluteUrl("media/branding/redd-logo-neon.png")}`}
            alt="REDD"
            style={{
              width: "100%",
              height: "auto"
            }}
          />
        </div>
      );
    } else if (placeholder) {
      return placeholder;
    } else {
      return children || null;
    }
  }
  return children || null;
}
import React from "react";
import LeadsManagementRemoveProperty
  from "../../../../pages/common/LeadsManagementPage/components/LeadsManagementRemoveProperty";
import {Grid} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import TooltipCustom from "../../TooltipCustom";
import {toDateFormat} from "../../../../utils/helpers";

export const SingleBuilding = ({ children, lead, property, removing, mode }) => {
  const propertyID = property?.id;
  const propertyName = property?.name;
  const areaRange = [
    ...lead?.crmAreaFrom ? [lead?.crmAreaFrom] : [],
    ...lead?.crmAreaTo ? [lead?.crmAreaTo] : []
  ].join("-");
  const leadName = [
    ...(lead?.crmName && lead?.crmName !== "Do ustalenia") ? [lead?.crmName] : [lead?.crmLastName],
    areaRange
  ].join(" | ");

  // Helpers
  const renderPropertySubscription = () => {
    const subName = property?.subscriptionType;
    const subDate = property?.isActiveUntil;
    const subIsActive = property?.isActive;
    const subStatusText = subIsActive ? <FormattedMessage id="Active"/> : <FormattedMessage id="Expired"/>;

    if(subDate) {
      return(
        <TooltipCustom title={toDateFormat(subDate)}>
          <span style={{
            display: "inline-block",
            fontSize: "1.1rem",
            fontWeight: "400",
            lineHeight: "1.6rem",
            padding: "0 5px",
            whiteSpace: "nowrap",
            borderRadius: "4px",
            backgroundColor: subIsActive ? "rgba(255, 70, 38,0.7)" : "rgba(255,255,255,0.2)"
          }}><strong>{subName}</strong> ({subStatusText})</span>
        </TooltipCustom>
      );
    }
    return(
      <span style={{
        display: "inline-block",
        fontSize: "1.1rem",
        fontWeight: "400",
        lineHeight: "1.6rem",
        padding: "0 5px",
        whiteSpace: "nowrap",
        borderRadius: "4px",
        backgroundColor: subIsActive ? "rgba(255, 70, 38,0.7)" : "rgba(255,255,255,0.2)"
      }}><strong>{subName}</strong> ({subStatusText})</span>
    );
  }

  return (
    <Grid container spacing={2} justifyContent="space-between" alignItems="center" style={{borderBottom: "1px solid #E6E6E6"}} wrap="nowrap">
      <Grid item>
        {children}
      </Grid>
      {mode === "properties" &&
      <Grid item>
        <Grid container spacing={1} alignItems="center" wrap="nowrap">
          <Grid item>
            {renderPropertySubscription()}
          </Grid>
          <Grid item>
            <LeadsManagementRemoveProperty
              leadID={lead?.id}
              propertyID={propertyID}
              leadName={leadName}
              propertyName={propertyName}
              removing={removing}
            />
          </Grid>
        </Grid>
      </Grid>
      }
    </Grid>
  );
};

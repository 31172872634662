import React from "react";
import {FormattedMessage} from "react-intl";
import ModalCustom from "../ModalCustom";
import BuildingList from "./components/BuildingList";
import Form from "./components/Form";
import connect from "react-redux/es/connect/connect";
import APPLICATION_VIEWS from "app/constants/APPLICATION_VIEWS";
import LoadingScreen from "../LoadingScreen";
import {Grid} from "@material-ui/core";
import {createFinnePropertyUrl} from "../../../utils/helpers";
import ConfirmationDialog from "../ConfirmationDialog";
import Button from "../Button";
import Icon from "../Icon";

class FinneCrossSelling extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.officeView =
      props.currentApplicationView === APPLICATION_VIEWS.OFFICES;
    this.state = {
      formValues: {},
      isActive: true,
      confirmationOpen: false,
      importTenantData: false
    };
  }

  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleFormikValues = (data) => {
    this.setState({formValues: data});
  };
  handleIsActive = (data) => {
    this.setState({isActive: data});
  };
  handleImportTenantData = () => {
    this.setState({
      importTenantData: true
    }, () => setTimeout(() => {
      this.setState({importTenantData: false})
    }, 500))
  }

  handleProceed = () => {
    const {mode, data, onInterviewUpdate, currentApplicationView, onSend, onCreate, sending} = this.props;
    const {formValues} = this.state;
    const pl = this.props.language === "pl";
    const propertyType = currentApplicationView === APPLICATION_VIEWS.OFFICES ? "offices" : currentApplicationView === APPLICATION_VIEWS.INDUSTRIAL ? "warehouses" : "";
    const objects = data?.objects?.map((elem) => ({
      slug: elem.slug,
      url: createFinnePropertyUrl(elem?.region, propertyType, elem?.marketSlug, elem?.slug),
      name: elem.name,
      region: elem?.region
    }));
    const interviewForm = {
      Last_name: formValues?.fullName,
      Email: formValues?.email,
      Phone_number: formValues?.phoneNumber,
      Name: formValues?.tenantsName,
      Tenants_description: formValues?.tenantsDescription,
      Location: formValues?.location,
      Start_date: formValues?.startDate,
      Type_of_activity:
        !this.officeView && formValues?.typeOfActivity,
      Technical_requirements: formValues?.technicalRequirements,
      Message: formValues?.additionalInformation,
      Area_from: formValues?.areaFrom,
      Area_to: formValues?.areaTo,
      ...formValues?.market ? {Market: formValues?.market} : {}
    };
    const form = {
      Przetwarzanie_danych: true,
      Zgoda_marketingowa_i_handlowa: false,
      Lead_Category: this.officeView ? "Offices" : "Industrial",
      Lead_type: mode === "create" ? "CS manual lead" : "Building lead",
      Lead_Stage: "Pool",
      ...interviewForm
    };

    if (mode === "send") {
      const formData = {
        lang: formValues?.lang ? formValues?.lang?.toLowerCase() : pl ? "pl" : "en",
        region: formValues?.region || "PL",
        timestamp: Math.floor(Date.now() / 1000),
        objects,
        form,
      };
      this.setState({
        confirmationOpen: false
      }, () => {
        onSend && onSend(formData);
        if (!sending) {
          this.handleCloseModal();
        }
      });
    }
    else if (mode === "edit") {
      const isEmpty = formValues?.tenantsName === "" &&
        formValues?.tenantsDescription === "" &&
        formValues?.location === "" &&
        formValues?.startDate === "" &&
        (formValues?.typeOfActivity === "" || !formValues?.typeOfActivity) &&
        formValues?.technicalRequirements === "" &&
        formValues?.additionalInformation === "" &&
        formValues?.areaFrom === "" &&
        formValues?.areaTo === "" &&
        formValues?.fullName === "" &&
        formValues?.email === "" &&
        formValues?.phoneNumber === "" &&
        formValues?.region === "" &&
        formValues?.lang === "";

      const finalInterviewData = isEmpty ? null : interviewForm;
      onInterviewUpdate && onInterviewUpdate({form: finalInterviewData, leadName: formValues?.leadName});
    }
    else if (mode === "create") {
      const data = {
        lang: formValues?.lang ? formValues?.lang?.toLowerCase() : pl ? "pl" : "en",
        region: formValues?.region || "PL",
        timestamp: Math.floor(Date.now() / 1000),
        objects: [],
        form,
        leadName: formValues?.leadName || ""
      }
      onCreate && onCreate(data);
    }
  };
  handleConfirmation = () => {
    this.setState({
      confirmationOpen: true
    });
  }

  render() {
    const {
      btnTrigger,
      onOpen,
      loading,
      data,
      mode,
      updating,
      sending,
      removing,
      modalTitle,
      addingLead,
      currentApplicationView
    } = this.props;
    const {importTenantData, formValues} = this.state;
    const loader = this.officeView
      ? this.props.officeLoading
      : this.props.industrialLoading;
    const mandatoryFieldsEmpty = formValues?.fullName === "" || formValues?.email === "" || formValues?.phoneNumber === "" || (mode === "create" && formValues.market === "");

    return (
      <>
        <ModalCustom
          ref={this.child}
          title={modalTitle || "-"}
          btn={
            btnTrigger ? btnTrigger :
              <Button
                color={"neutral"}
                textIcon={<Icon icon={"edit"}/>}
              >
                <FormattedMessage id="FINNE FORM"/>
              </Button>
          }
          btnConfirm={
            mode === "send" ? <FormattedMessage id="FORM.BUTTON.SUBMIT"/> :
              mode === "edit" ? <FormattedMessage id="GENERAL.PHRASE.SAVE"/> :
                mode === "create" ? <FormattedMessage id="LM.PHRASE.CREATE"/> : false
          }
          btnReject={<FormattedMessage id="GENERAL.PHRASE.CLOSE"/>}
          btnConfirmDisabled={
            updating ||
            sending ||
            addingLead ||
            (mode === "send" && (!data?.objects || data?.objects?.length <= 0 || mandatoryFieldsEmpty)) ||
            (mode === "create" && mandatoryFieldsEmpty)
          }
          btnRejectDisabled={loader}
          maxWidth={(mode === "edit" || mode === "create") ? "sm" : mode === "properties" ? "xs" : "lg"}
          handleSave={(loader || mode === "properties") ? undefined : mode === "send" ? this.handleConfirmation : this.handleProceed}
          fetch={onOpen}
          toolbar={
            mode === "edit" &&
            <Button
              type={"plainText"}
              inactive={importTenantData}
              onClick={this.handleImportTenantData}
            >
              <FormattedMessage id="LM.PHRASE.IMPORT_TENANT_DATA"/>
            </Button>
          }
        >
          {(loader || loading) ? (
            <LoadingScreen/>
          ) : (
            <Grid container spacing={2} justifyContent="space-between">
              {(mode === "edit" || mode === "create" || mode === "send") &&
                <Grid item xs={(mode === "edit" || mode === "create") ? 12 : 6}>
                  <Form
                    data={data}
                    handleFormikValues={this.handleFormikValues}
                    handleIsActive={this.handleIsActive}
                    officeView={this.officeView}
                    previewOnly={mode === "send"}
                    importTenantData={importTenantData}
                    mode={mode}
                  />
                </Grid>
              }
              {(mode === "properties" || mode === "send") &&
                <Grid item xs={mode === "properties" ? 12 : 5}>
                  <BuildingList
                    lead={data}
                    properties={data?.objects}
                    removing={removing}
                    mode={mode}
                    currentApplicationView={currentApplicationView}
                  />
                </Grid>
              }
            </Grid>
          )}
        </ModalCustom>
        <ConfirmationDialog
          open={this.state.confirmationOpen}
          onConfirm={() => this.handleProceed()}
          message={<FormattedMessage id="LM.PHRASE.ACTION_CONFIRMATION_SEND_LEAD_MESSAGE"/>}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentApplicationView: state.user.data.currentApplicationView,
    language: state.user.data.mailLangCode,
    officeLoading: state.shortlists.offersBatchLoading,
    industrialLoading: state.shortlists.offersIndustrialBatchLoading,
  };
};

export default connect(mapStateToProps)(FinneCrossSelling);

import React from "react";
import {FormattedMessage} from "react-intl";
import FinneCrossSelling from "../../../../partials/components/FinneCrossSelling";
import {fetchLead, sendLead} from "../../../../crud/leadsManagement.crud";
import connect from "react-redux/es/connect/connect";
import Icon from "../../../../partials/components/Icon";
import Button from "../../../../partials/components/Button";

class LeadsManagementSend extends React.Component {
  render() {
    const {lead, loadingLead, sending, leadID, fetchLead, sendLead} = this.props;

    return(
      <FinneCrossSelling
        btnTrigger={
        <Button
          type={"icon"}
          tooltip={<FormattedMessage id="LM.PHRASE.PREVIEW_SEND"/>}
          color={"success"}
        >
          <Icon icon={"send-45deg"}/>
        </Button>
        }
        modalTitle={<FormattedMessage id="LM.PHRASE.PREVIEW_SEND"/>}
        mode="send"
        data={lead}
        loading={loadingLead}
        sending={sending}
        onOpen={() => fetchLead(leadID)}
        onSend={(data) => sendLead(leadID, data)}
      />
    );
  }
}

const mapStateToProps = ({ leadsManagement }) => ({
  lead: leadsManagement?.lead,
  loadingLead: leadsManagement?.loadingLead,
  sending: leadsManagement?.sending
});
const mapDispatchToProps = {
  fetchLead: (leadID) => fetchLead(leadID),
  sendLead: (leadID, data) => sendLead(leadID, data)
};
export default connect(mapStateToProps, mapDispatchToProps)(LeadsManagementSend);
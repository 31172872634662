import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest} from "redux-saga/effects";
import axios from "axios";
import {actionNotification} from "app/utils/notifications";
import {resetFilters, setFilters} from "app/utils/filters";
import {VIEW_TYPES} from "app/constants";

const initialState = {
  loaded: false,
  units: null,
  allUnits: null,
  itemsCount: null,
  totalItems: null,
  maxPages: 1,
  clusters: null,
  clustersLoaded: false,
  clustersLoading: false,
  viewType: VIEW_TYPES.LIST_VIEW,
  loading: false,
  contactDetails: null,
  officeUnits: null,
  officeUnitsLoading: false,
  officeUnitsUpdateLoading: false,
  officeUnitsPermOffers: null,
  officeUnitsUnavailable: false,
  refreshing: false,
  addingUnit: false,
  unitAdded: false,
  editFormsData: null,
  editFormsDataLoading: false,
  unitContactPersons: null,
  unitContactPersonsLoading: false
};

const getMaxPages = (totalItems) => {
  return Math.floor(Number(totalItems)/20);
};

const handleUpdatedUnitData = (oldUnits, updatedUnit) => {
  if(oldUnits) {
    const foundChanged = oldUnits.findIndex(unit => unit.id === updatedUnit.id);
    oldUnits[foundChanged] = updatedUnit;
    return [...oldUnits];
  }
  return oldUnits;
};
const handleUpdatedUnitPlan = (oldUnits, updatedUnit) => {
  if(oldUnits) {
    const foundChanged = oldUnits.findIndex(unit => unit.id === updatedUnit.id);
    oldUnits[foundChanged]["planImage"] = updatedUnit.planImage;
    return [...oldUnits];
  }
  return oldUnits;
};

export const reducer = persistReducer(
  { storage, key: "units" },
  (state = initialState, action) => {
    switch (action.type) {
      // RESET OFFICE UNITS LOADING STATES
      case 'RESET_OFFICE_UNITS_LOADING_STATES_REQUEST': {
        return {
          ...state,
          clustersLoading: false,
          loading: false,
          officeUnitsLoading: false,
          officeUnitsUpdateLoading: false,
          editFormsDataLoading: false,
          unitContactPersonsLoading: false
        };
      }
      // FETCH
      case 'FETCH_UNITS_LIST_REQUEST': {
        return { ...state, loading: true }
      }
      case 'FETCH_UNITS_LIST_RESET_REQUEST': {
        return { ...state, loading: true }
      }
      case 'FETCH_UNITS_LIST_SUCCESS': {
        return {
          ...state,
          units: action.payload,
          allUnits: null,
          loaded: true,
          loading: false
        }
      }
      case 'FETCH_UNITS_INFINITE_LIST_REQUEST': {
        return { ...state, loading: true };
      }
      case 'FETCH_UNITS_INFINITE_LIST_SUCCESS': {
        return {
          ...state,
          allUnits: state.allUnits ? [...state.allUnits, ...action.payload.items] : action.payload.items,
          loading: false,
          itemsCount: action.payload.itemsCount,
          totalItems: action.payload.total,
          maxPages: getMaxPages(action.payload.itemsCount)
        }
      }
      case 'CLEAN_UNITS_INFINITE_LIST_SUCCESS': {
        return { ...state, allUnits: action.payload }
      }
      case 'FETCH_VIEW_TYPE_SUCCESS': {
        return { ...state, viewType: action.viewType }
      }
      case 'FETCH_CLUSTERS_RESET_REQUEST': {
        return {
          ...state,
          clustersLoading: true
        }
      }
      case 'FETCH_CLUSTERS_REQUEST': {
        return {
          ...state,
          clustersLoading: true
        }
      }
      case 'FETCH_CLUSTERS_SUCCESS': {
        return {
          ...state,
          clusters: action.payload,
          clustersLoaded: true,
          clustersLoading: false
        }
      }
      case 'FETCH_CLUSTERS_FAILED': {
        return {
          ...state,
          clusters: null,
          clustersLoading: false
        }
      }
      case 'FETCH_OFFICE_UNITS_REQUEST': {
        return {
          ...state,
          officeUnits: null,
          officeUnitsPermOffers: null,
          officeUnitsUnavailable: false,
          officeUnitsLoading: true
        }
      }
      case 'FETCH_OFFICE_UNITS_SUCCESS': {
        return {
          ...state,
          officeUnits: [...action.payload.units],
          officeUnitsPermOffers: action.payload.permOffers,
          officeUnitsUnavailable: action.payload.unavailable,
          officeUnitsLoading: false
        }
      }
      case 'GET_CONTACT_DETAILS_REQUEST': {
        return { ...state, contactDetails: null }
      }
      case 'GET_CONTACT_DETAILS_SUCCESS': {
        return { ...state, contactDetails: action.payload }
      }
      // EDIT FORMS DATA
      case 'FETCH_UNIT_EDIT_FORMS_DATA_REQUEST': {
        return { ...state, editFormsData: null, editFormsDataLoading: true }
      }
      case 'FETCH_UNIT_EDIT_FORMS_DATA_SUCCESS': {
        return { ...state, editFormsData: action.payload, editFormsDataLoading: false }
      }
      case 'FETCH_UNIT_EDIT_FORMS_DATA_FAILED': {
        return { ...state, editFormsData: null, editFormsDataLoading: false }
      }
      // UNIT CONTACT PERSONS
      case 'FETCH_UNIT_CONTACT_PERSONS_REQUEST': {
        return { ...state, unitContactPersons: null, unitContactPersonsLoading: true }
      }
      case 'FETCH_UNIT_CONTACT_PERSONS_SUCCESS': {
        return { ...state, unitContactPersons: action.payload, unitContactPersonsLoading: false }
      }
      case 'FETCH_UNIT_CONTACT_PERSONS_FAILED': {
        return { ...state, unitContactPersons: null, unitContactPersonsLoading: false }
      }
      // ACTIONS
      case 'ADD_OFFICE_UNIT_REQUEST': {
        return {
          ...state,
          addingUnit: true,
          unitAdded: false
        };
      }
      case 'ADD_OFFICE_UNIT_SUCCESS': {
        return {
          ...state,
          addingUnit: false,
          unitAdded: true,
          officeUnits: state.officeUnits ? [...state.officeUnits, action.payload] : [action.payload]
        };
      }
      case 'ADD_OFFICE_UNIT_FAILED': {
        return {
          ...state,
          addingUnit: false,
          unitAdded: false
        };
      }
      case 'UPDATE_UNIT_REQUEST': {
        return { ...state, officeUnitsUpdateLoading: true }
      }
      case 'UPDATE_UNIT_SUCCESS': {
        return { ...state, officeUnits: handleUpdatedUnitData(state.officeUnits, action.payload), officeUnitsUpdateLoading: false };
      }
      case 'UPDATE_UNIT_FAILED': {
        return { ...state, officeUnitsUpdateLoading: false }
      }
      case 'REFRESH_UNIT_REQUEST': {
        return {
          ...state,
          refreshing: true
        };
      }
      case 'REFRESH_UNIT_SUCCESS': {
        return {
          ...state,
          refreshing: false,
          officeUnits: handleUpdatedUnitData(state.officeUnits, action.payload)
        };
      }
      case 'UPDATE_UNIT_PLAN_REQUEST': {
        return { ...state, officeUnitsUpdateLoading: true };
      }
      case 'UPDATE_UNIT_PLAN_SUCCESS': {
        return { ...state, officeUnitsUpdateLoading: false, officeUnits: handleUpdatedUnitPlan(state.officeUnits, action.payload) };
      }
      case 'UPDATE_UNIT_PLAN_FAILED': {
        return { ...state, officeUnitsUpdateLoading: false };
      }
      case 'DELETE_UNIT_SUCCESS': {
        return { ...state, officeUnits: state.officeUnits !== null ? [...state.officeUnits.filter(unit => unit.id !== action.payload)] : null };
      }

      default:
        return state;
    }
  }
);

// FETCH DATA
function* fetchUnitsList(action) {
  const params = action.payload.params;
  const filters = setFilters(params, 'updated_at');
  try {
    const unitsData = yield axios.get(`/office-units-v2/${filters}`);
    yield put({ type: "FETCH_UNITS_LIST_SUCCESS", payload: unitsData.data || [{ error: unitsData.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchUnitsInfiniteList(action) {
  const params = action.payload.params;
  const filters = setFilters(params, 'updated_at');
  try {
    const unitsData = yield axios.get(`/office-units-v2/${filters}`);
    yield put({ type: "FETCH_UNITS_INFINITE_LIST_SUCCESS", payload: unitsData.data || [{ error: unitsData.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchUnitsListReset(action) {
  const {initOrderBy = 'updated_at', isEditable} = action.payload;
  const filtersReset = resetFilters(initOrderBy, isEditable);
  try {
    const unitsData = yield axios.get(`/office-units-v2/${filtersReset}`);
    yield put({ type: "FETCH_UNITS_LIST_SUCCESS", payload: unitsData.data || [{ error: unitsData.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchUnitsInfiniteListReset() {
  const filtersReset = resetFilters('updated_at');
  try {
    const unitsData = yield axios.get(`/office-units-v2/${filtersReset}`);
    yield put({ type: "FETCH_UNITS_INFINITE_LIST_SUCCESS", payload: unitsData.data || [{ error: unitsData.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchOfficeUnits(action) {
  const officeId = action.payload;
  try {
    const unitsData = yield axios.get(`/offices-v2/${officeId}/units/?ordering=floor`);
    yield put({ type: "FETCH_OFFICE_UNITS_SUCCESS", payload: unitsData.data || [{ error: unitsData.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchAgentClustersData(action) {
  const params = action.payload.params;
  const filters = setFilters(params, 'map');
  try {
    const clustersData = yield axios.get(`/office-units-v2/map_clusters/?ne_lat=&ne_lng=&sw_lat=&sw_lng=${filters}`);
    yield put({ type: "FETCH_CLUSTERS_SUCCESS", payload: clustersData.data || [{ error: clustersData.statusText }] });
  }
  catch(err) {
    console.log(err);
    yield put({ type: "FETCH_CLUSTERS_FAILED" });
  }
}
function* fetchAgentClustersDataReset() {
  const filters = resetFilters('map');
  try {
    const clustersData = yield axios.get(`/office-units-v2/map_clusters/?ne_lat=&ne_lng=&sw_lat=&sw_lng=${filters}`);
    yield put({ type: "FETCH_CLUSTERS_SUCCESS", payload: clustersData.data || [{ error: clustersData.statusText }] });
  }
  catch(err) {
    console.log(err);
    yield put({ type: "FETCH_CLUSTERS_FAILED" });
  }
}
function* getAgentViewType() {
  const viewType = yield localStorage.getItem('unitsViewType');
  yield put({ type: "FETCH_VIEW_TYPE_SUCCESS", viewType: viewType || [{ error: viewType.statusText }] });
}

// FETCH BASIC DATA
function* fetchUnitEditFormsData(action) {
  const unitId = action.payload;
  try {
    const unitsData = yield axios.get(`/office-units-v2/${unitId}/basic/`);
    yield put({ type: "FETCH_UNIT_EDIT_FORMS_DATA_SUCCESS", payload: unitsData.data || [{ error: unitsData.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_UNIT_EDIT_FORMS_DATA_FAILED" });
    console.log(err);
  }
}
function* fetchUnitContactPersons(action) {
  const unitId = action.payload;
  try {
    const contactPersons = yield axios.get(`/office-units-v2/${unitId}/contact-persons/`);
    yield put({ type: "FETCH_UNIT_CONTACT_PERSONS_SUCCESS", payload: contactPersons.data || [{ error: contactPersons.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_UNIT_CONTACT_PERSONS_FAILED" });
    console.log(err);
  }
}

// USER ACTIONS
function* addOfficeUnit(action) {
  try {
    const {unit, ordering} = action.payload;
    const addUnit = yield axios.post(`/office-units-v2/`, unit);
    yield put({
      type: "ADD_OFFICE_UNIT_SUCCESS",
      payload: addUnit.data,
      meta: actionNotification('Unit has been added.', 'success')
    });
    // Update Units List on Building Page
    if(ordering !== undefined) {
      yield put({
        type: 'FETCH_OFFICE_BUILDING_UNITS_REQUEST',
        payload: {
          officeID: unit?.office,
          ordering
        },
      });
    }
  }
  catch(err) {
    const errorMessage = err.data.nonFieldErrors !== undefined ? err.data.nonFieldErrors[0] : 'Oops, something went wrong! Try again later.';
    yield put({
      type: "ADD_OFFICE_UNIT_FAILED",
      payload: err.status,
      meta: actionNotification(errorMessage, 'error')
    });
  }
}
function* updateUnit(action) {
  try {
    const {unitId, data, backgroundRefreshObject} = action.payload;
    const updateUnit = yield axios.patch(`/office-units-v2/${unitId}/`, data);
    yield put({
      type: "UPDATE_UNIT_SUCCESS",
      payload: updateUnit.data,
      meta: actionNotification('Unit details has been updated.', 'success')
    });
    if(backgroundRefreshObject) {
      // Refresh Basic Edit Form Data
      yield put({
        type: 'FETCH_UNIT_EDIT_FORMS_DATA_REQUEST',
        payload: unitId,
      });
      // Refresh Basic Data per Section (Property Page)
      if(
        (backgroundRefreshObject?.target === "basic" || backgroundRefreshObject?.target === "flexibleUnits") &&
        backgroundRefreshObject?.ordering &&
        backgroundRefreshObject?.officeID
      ) {
        yield put({
          type: 'FETCH_OFFICE_BUILDING_UNITS_REQUEST',
          payload: {
            officeID: backgroundRefreshObject?.officeID,
            ordering: backgroundRefreshObject?.ordering,
            backgroundLoading: true
          },
        });
      }
    }
  }
  catch(err) {
    const errorMessage = err.data.nonFieldErrors !== undefined ? err.data.nonFieldErrors[0] : 'Oops, something went wrong! Try again later.';
    yield put({
      type: "UPDATE_UNIT_FAILED",
      payload: err.status,
      meta: actionNotification(errorMessage, 'error')
    });
  }
}
function* updateUnitPlan(action) {
  try {
    const {unitId, image, backgroundRefreshObject} = action.payload;
    const formData = new FormData();
    formData.append('planImage', image);
    const updateUnit = yield axios.post(`/office-units-v2/${unitId}/plan_image/`, formData);
    yield put({
      type: "UPDATE_UNIT_PLAN_SUCCESS",
      payload: updateUnit.data,
      meta: actionNotification('Unit plan has been updated.', 'success')
    });
    if(backgroundRefreshObject) {
      // Refresh Basic Edit Form Data
      yield put({
        type: 'FETCH_UNIT_EDIT_FORMS_DATA_REQUEST',
        payload: unitId,
      });
    }
  }
  catch(err) {
    console.log(err);
    yield put({
      type: "UPDATE_UNIT_PLAN_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* deleteUnit(action) {
  try {
    const {unitId} = action.payload;
    yield axios.delete(`/office-units-v2/${unitId}/`);
    yield put({
      type: "DELETE_UNIT_SUCCESS",
      payload: unitId,
      meta: actionNotification('Unit has been removed.', 'success')
    });
  }
  catch(err) {
    console.log(err);
    yield put({
      type: "DELETE_UNIT_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* refreshUnit(action) {
  try {
    const unitId = action.payload;
    const refreshUnit = yield axios.patch(`/office-units-v2/${unitId}/`, {});
    yield put({
      type: "REFRESH_UNIT_SUCCESS",
      payload: refreshUnit.data,
      meta: actionNotification('Unit has been refreshed.', 'success')
    });
  }
  catch(err) {
    const errorMessage = err.data.nonFieldErrors !== undefined ? err.data.nonFieldErrors[0] : 'Oops, something went wrong! Try again later.';
    yield put({
      type: "REFRESH_UNIT_FAILED",
      payload: err.status,
      meta: actionNotification(errorMessage, 'error')
    });
  }
}
function* refreshAllOfficeUnits(action) {
  try {
    const {officeId, backgroundRefreshObject} = action.payload;
    const refreshAllOfficeUnits = yield axios.post(`/offices-v2/${officeId}/refresh_all/`);
    yield put({
      type: "REFRESH_ALL_OFFICE_UNITS_SUCCESS",
      payload: refreshAllOfficeUnits.data,
      meta: actionNotification('All units inside selected building has been refreshed.', 'success')
    });
    if(backgroundRefreshObject) {
      yield put({
        type: 'FETCH_OFFICE_BUILDING_UNITS_REQUEST',
        payload: {
          officeID: officeId,
          ordering: backgroundRefreshObject?.ordering,
          backgroundLoading: true
        },
      });
    }
  }
  catch(err) {
    console.log(err);
    yield put({
      type: "REFRESH_ALL_OFFICE_UNITS_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* refreshSelectedOfficeUnits(action) {
  try {
    const {officeId, units, backgroundRefreshObject} = action.payload;
    const refreshSelectedOfficeUnits = yield axios.post(`/offices-v2/${officeId}/refresh/`, units);
    yield put({
      type: "REFRESH_SELECTED_OFFICE_UNITS_SUCCESS",
      payload: refreshSelectedOfficeUnits.data,
      meta: actionNotification('Selected units inside selected building has been refreshed.', 'success')
    });
    if(backgroundRefreshObject) {
      yield put({
        type: 'FETCH_OFFICE_BUILDING_UNITS_REQUEST',
        payload: {
          officeID: officeId,
          ordering: backgroundRefreshObject?.ordering,
          backgroundLoading: true
        },
      });
    }
  }
  catch(err) {
    yield put({
      type: "REFRESH_SELECTED_OFFICE_UNITS_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}

// BACKGROUND ACTIONS
function* cleanUnitsInfiniteList() {
  yield put({ type: "CLEAN_UNITS_INFINITE_LIST_SUCCESS", payload: null });
}

export function* saga() {
  // FETCH
  yield takeLatest('FETCH_UNITS_LIST_REQUEST', fetchUnitsList);
  yield takeLatest('FETCH_UNITS_INFINITE_LIST_REQUEST', fetchUnitsInfiniteList);
  yield takeLatest('FETCH_UNITS_LIST_RESET_REQUEST', fetchUnitsListReset);
  yield takeLatest('FETCH_UNITS_INFINITE_LIST_RESET_REQUEST', fetchUnitsInfiniteListReset);
  yield takeLatest('FETCH_OFFICE_UNITS_REQUEST', fetchOfficeUnits);
  yield takeLatest('FETCH_VIEW_TYPE_REQUEST', getAgentViewType);
  yield takeLatest('FETCH_CLUSTERS_REQUEST', fetchAgentClustersData);
  yield takeLatest('FETCH_CLUSTERS_RESET_REQUEST', fetchAgentClustersDataReset);
  // FETCH BASIC DATA
  yield takeLatest('FETCH_UNIT_EDIT_FORMS_DATA_REQUEST', fetchUnitEditFormsData);
  yield takeLatest('FETCH_UNIT_CONTACT_PERSONS_REQUEST', fetchUnitContactPersons);
  // ACTIONS
  yield takeLatest('ADD_OFFICE_UNIT_REQUEST', addOfficeUnit);
  yield takeLatest('UPDATE_UNIT_REQUEST', updateUnit);
  yield takeLatest('UPDATE_UNIT_PLAN_REQUEST', updateUnitPlan);
  yield takeLatest('DELETE_UNIT_REQUEST', deleteUnit);
  yield takeLatest('REFRESH_UNIT_REQUEST', refreshUnit);
  yield takeLatest('REFRESH_ALL_OFFICE_UNITS_REQUEST', refreshAllOfficeUnits);
  yield takeLatest('REFRESH_SELECTED_OFFICE_UNITS_REQUEST', refreshSelectedOfficeUnits);
  yield takeLatest('CLEAN_UNITS_INFINITE_LIST_REQUEST', cleanUnitsInfiniteList);
}

export const fetchTeamMembers = (params) => ({
  type: 'FETCH_TEAM_MEMBERS_REQUEST',
  payload: {params}
});
export const fetchTeamPropertyParticularsStyles = (teamID) => ({
  type: 'GET_TEAM_PROPERTY_PARTICULARS_STYLES_REQUEST',
  payload: {
    teamID
  }
});
export const fetchTeamOffersStyles = (teamID) => ({
  type: 'GET_TEAM_OFFERS_STYLES_REQUEST',
  payload: {
    teamID
  }
});
export const inviteTeamMember = (newMember) => ({
  type: 'INVITE_TEAM_MEMBER_REQUEST',
  payload: {newMember}
});
export const setTeamMemberAsOwner = (memberId) => ({
  type: 'SET_TEAM_MEMBER_AS_OWNER_REQUEST',
  payload: memberId
});
export const deleteTeamMember = (memberId) => ({
  type: 'DELETE_TEAM_MEMBER_REQUEST',
  payload: memberId
});
export const setTeamMemberAsContact = (teamId, data) => ({
  type: 'SET_TEAM_MEMBER_AS_CONTACT_REQUEST',
  payload: {teamId, data}
});
export const unsetTeamMemberAsContact = (teamId, data) => ({
  type: 'UNSET_TEAM_MEMBER_AS_CONTACT_REQUEST',
  payload: {teamId, data}
});
export const setTeamAsActive = (teamId) => ({
  type: 'SET_TEAM_AS_ACTIVE_REQUEST',
  payload: teamId
});
export const updateTeamMemberPhone = (memberId, data) => ({
  type: 'UPDATE_TEAM_MEMBER_PHONE_REQUEST',
  payload: {memberId, data}
});
export const setTeamMemberForActiveSubscription = (memberId) => ({
  type: 'SET_TEAM_MEMBER_FOR_ACTIVE_SUBSCRIPTION_REQUEST',
  payload: memberId
});
export const updateTeamPropertyParticularsStyles = (teamID, data) => ({
  type: 'UPDATE_TEAM_PROPERTY_PARTICULARS_STYLES_REQUEST',
  payload: {
    teamID,
    data
  }
});
export const updateTeamPropertyParticularsLogo = (logo) => ({
  type: 'UPDATE_TEAM_PROPERTY_PARTICULARS_LOGO_REQUEST',
  payload: logo
});
export const updateTeamOffersStyles = (teamID, data) => ({
  type: 'UPDATE_TEAM_OFFERS_STYLES_REQUEST',
  payload: {
    teamID,
    data
  }
});
export const updateTeamOffersLogo = (logo) => ({
  type: 'UPDATE_TEAM_OFFERS_LOGO_REQUEST',
  payload: logo
});
// Reset Team Members Loading States
export const resetTeamMembersLoadingStates = () => ({
  type: 'RESET_TEAM_MEMBERS_LOADING_STATES_REQUEST'
});
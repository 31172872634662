import React, {useEffect, useRef, useState} from "react";
import styles from "../index.module.scss";
import LoadingScreen from "../../../../partials/components/LoadingScreen";
import InputWithActions from "../../../../partials/components/InputWithActions";
import EditButton from "../../../../partials/components/EditModal/components/EditButton";
import {FormattedMessage} from "react-intl";
import Button from "../../../../partials/components/Button";
import Icon from "../../../../partials/components/Icon";

export default function OfferDescription(props) {
  const {data, loading, offerUpdating, updateOffer, intl} = props;
  const offerID = data?.id;
  const offerNote = data?.ownerNote || "";
  const [description, setDescription] = useState(offerNote);
  const [isDescriptionEnable, setIsDescriptionEnable] = useState(false);
  const [isDescriptionEditable, setIsDescriptionEditable] = useState(false);
  const prevOfferUpdating = useRef(false);

  //
  useEffect(() => {
    setDescription(offerNote);
    if(offerNote && offerNote !== "") {
      setIsDescriptionEnable(true);
    }
    else if(!offerNote && isDescriptionEnable) {
      setIsDescriptionEditable(true)
    }
  }, [offerNote, isDescriptionEnable]);
  // Close edit mode when change has been saved
  useEffect(() => {
    prevOfferUpdating.current = offerUpdating;
    if(prevOfferUpdating && !offerUpdating) {
      setIsDescriptionEditable(false);
    }
  }, [offerUpdating]);
  // Handlers
  const handleSaveChanges = () => {
    const data = {
      ownerNote: description
    };
    if(offerID && updateOffer) {
      updateOffer(offerID, data);
    }
  }
  const handleRejectChanges = () => {
    setDescription(offerNote);
    setIsDescriptionEditable(false);

    if(description === "") {
      setIsDescriptionEnable(false);
    }
  }

  if(loading) {
    return <LoadingScreen />;
  }
  return(
    <div className={styles.offerDescriptionWrapper}>
      {isDescriptionEnable ? (
        isDescriptionEditable ? (
          <InputWithActions
            name="description"
            label={<FormattedMessage id="VOCABULARY.Description" />}
            value={description}
            btnConfirmLabel={<FormattedMessage id="VOCABULARY.Save" />}
            btnRejectLabel={offerNote !== description ? intl.formatMessage({id: "VOCABULARY.Reject & Close"}) : intl.formatMessage({id: "VOCABULARY.Close"})}
            loading={offerUpdating}
            multiline={5}
            limit={1500}
            style={{
              backgroundColor: "#ffffff"
            }}
            onChange={(e) => setDescription(e.target.value)}
            onConfirm={() => handleSaveChanges()}
            onReject={() => handleRejectChanges()}
          />
        ) : (
          <div>
            <p
              dangerouslySetInnerHTML={{ __html: description }}
              className={styles.descriptionContent}
            />
            <EditButton
              iconStyle={{
                width: "1.4rem",
                height: "1.4rem"
              }}
              tooltipTriggerStyle={{
                marginLeft: "5px",
                position: "relative",
                top: "-1px",
                lineHeight: "0"
              }}
              withoutBg
              onClick={() => setIsDescriptionEditable(!isDescriptionEditable)}
            />
          </div>
        )
      ) : (
        <Button
          type={"plainText"}
          color={"simple"}
          textIcon={<Icon icon={"add-circle"}/>}
          style={{flexDirection: "row-reverse"}}
          textIconStyle={{fontSize: "2rem"}}
          onClick={() => setIsDescriptionEnable(!isDescriptionEnable)}
        >
          <FormattedMessage id="VOCABULARY.Add description" />
        </Button>
      )
      }
    </div>
  )
}
import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as authApp from "./ducks/authApp.duck";
import * as offices from "./ducks/estates/offices/offices.duck";
import * as officesPremium from "./ducks/estates/offices/officesPremium.duck";
import * as units from "./ducks/estates/offices/units.duck";
import * as filters from "./ducks/estates/filters.duck";
import * as shortlists from "./ducks/offers.duck";
import * as user from "./ducks/user.duck";
import * as team from "./ducks/team.duck";
import * as subscribers from "./ducks/subscribers.duck";
import * as reddIndex from "./ducks/reddIndex.duck";
import * as owners from "./ducks/owners.duck";
import * as tablePage from "./ducks/tablePage.duck";
import * as acquisitions from "./ducks/estates/offices/acquisitions.duck";
import * as ppOnlineOffices from "./ducks/propertyParticulars/offices.duck";
import * as ppOnlineIndustrial from "./ducks/propertyParticulars/industrial.duck";
import * as followers from "./ducks/followers.duck";
import * as leadsManagement from "./ducks/leadsManagement.duck";
import * as finneLeads from "./ducks/finneLeads.duck";
import * as officeBuilding from "./ducks/estates/offices/officeBuilding.duck";
import * as symmco from "./ducks/symmco.duck";
import * as accountRequest from "./ducks/accountRequest.duck";
// INDUSTRIAL
import * as industrial from "./ducks/estates/industrial/industrial.duck";
import {notifications} from "./ducks/notifications.duck";
import {metronic} from "../../_metronic";
import * as offersIndustrial from "./ducks/estates/industrial/offers-industrial.duck";
import * as industrialPark from "./ducks/estates/industrial/industrialPark.duck";
import * as industrialWarehouse from "./ducks/estates/industrial/industrialWarehouse.duck";

export const rootReducer = combineReducers({
  authApp: authApp.reducer,
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer,
  offices: offices.reducer,
  officesPremium: officesPremium.reducer,
  units: units.reducer,
  shortlists: shortlists.reducer,
  filters: filters.reducer,
  user: user.reducer,
  team: team.reducer,
  subscribers: subscribers.reducer,
  reddIndex: reddIndex.reducer,
  owners: owners.reducer,
  tablePage: tablePage.reducer,
  acquisitions: acquisitions.reducer,
  ppOnlineOffices: ppOnlineOffices.reducer,
  followers: followers.reducer,
  leadsManagement: leadsManagement.reducer,
  finneLeads: finneLeads.reducer,
  officeBuilding: officeBuilding.reducer,
  // INDUSTRIAL
  industrial: industrial.reducer,
  ppOnlineIndustrial: ppOnlineIndustrial.reducer,
  offersIndustrial: offersIndustrial.reducer,
  industrialPark: industrialPark.reducer,
  industrialWarehouse: industrialWarehouse.reducer,
  // COMMON
  notifications,
  symmco: symmco.reducer,
  accountRequest: accountRequest.reducer
});

export function* rootSaga() {
  yield all([
    authApp.saga(),
    units.saga(),
    offices.saga(),
    officesPremium.saga(),
    shortlists.saga(),
    user.saga(),
    team.saga(),
    subscribers.saga(),
    reddIndex.saga(),
    owners.saga(),
    tablePage.saga(),
    acquisitions.saga(),
    ppOnlineOffices.saga(),
    ppOnlineIndustrial.saga(),
    followers.saga(),
    industrial.saga(),
    offersIndustrial.saga(),
    leadsManagement.saga(),
    finneLeads.saga(),
    officeBuilding.saga(),
    industrialPark.saga(),
    industrialWarehouse.saga(),
    symmco.saga(),
    accountRequest.saga()
  ]);
}

import React from "react";
import clsx from "clsx";
import styles from "app/pages/common/external/components/index.module.scss";
import {FormattedMessage} from "react-intl";
import {Typography} from "@material-ui/core";

export default class PropertyParticularsSingleDescription extends React.Component {
    render() {
        const {reference, description, descriptionLocation} = this.props;
        const isDescriptionHtml = /^/.test(description);
        const isDescriptionLocationHtml = /^/.test(descriptionLocation);

        return(
            <div ref={reference} className={clsx(styles.boxContainer)}>
                <h2><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.DESCRIPTION"/></h2>
                {description && (isDescriptionHtml ? <div dangerouslySetInnerHTML={{__html: description}}/> : <Typography variant="body1" className={styles.simpleText}>{description}</Typography>)}
                {descriptionLocation && (isDescriptionLocationHtml ? <div dangerouslySetInnerHTML={{__html: descriptionLocation}}/> : <Typography variant="body1" className={styles.simpleText}>{descriptionLocation}</Typography>)}
            </div>
        );
    }
}
import React from "react";
import {FormattedMessage} from "react-intl";
import {Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import clsx from "clsx";
import styles from "../index.module.scss";

// Confirmation Message View after sending reset password request
export default class PasswordResetMailSendConfirmation extends React.Component {
  render() {
    return (
      <>
        <div className="kt-login__body">
          <div className="kt-login__form">
            <div className="kt-login__title">
              <Typography variant="h3" style={{marginBottom: 15}}>
                <FormattedMessage id="AUTH.VALIDATION.PASSWORD_RESET_REQUEST_HAS_BEEN_SENT" />
              </Typography>
              <Typography>
                <FormattedMessage id="AUTH.VALIDATION.PASSWORD_RESET_REQUEST_HAS_BEEN_SENT_DESCRIPTION" values={{breakingLine: <br/>}}/>
              </Typography>
            </div>

            <div className={styles.contentActions} style={{marginTop: 50, justifyContent: "center"}}>
              <Link to="/auth">
                <button type="button" className={clsx("btn btn-primary btn-elevate kt-login__btn-primary", styles.btnSubmit)}>
                  <FormattedMessage id="FORM.BUTTON.BACK"/>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}
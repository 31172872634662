import React from "react";
import TableCell from "@material-ui/core/TableCell/TableCell";
import BuildingCardPreview from "app/partials/components/_DataParts/BuildingCardPreview";
import {toNumberFormat} from "app/utils/helpers";
import ProgressBox from "app/partials/components/ProgressBox";
import styles from "app/partials/components/TableProperties/index.module.scss";
import {Typography} from "@material-ui/core";
import IconColorMUI from "app/partials/components/IconColorMUI";
import OwnerPortfolioFinancePreview from "app/partials/components/_DataParts/OwnerPortfolioFinancePreview";
import PropertyStatusPreview from "../../_DataParts/PropertyStatusPreview";

export default class TableOwnerPortfolioCells extends React.Component {

  renderTrueFalse = (state) => {
    const icon = state ? 'check' : 'close';
    const color = state ? 'success' : 'danger';
    return(
      <IconColorMUI icon={icon} color={color}/>
    );
  };

  render() {
    const {row, parentId} = this.props;
    const displayOwnerName = row.ownerShortName !== null ? row.ownerShortName : row.ownerName !== null ? row.ownerName : '-';
    const marketId = row.market;
    const vcPercentage = row.vacancyRateOfficeWithOfficeRetail;
    const percentValue = vcPercentage === null ? 100 : vcPercentage > 100 ? 100 : vcPercentage < 0 ? 0 : vcPercentage;
    const capacity = 100 - Number(percentValue).toFixed();

    return (
      <>
        <TableCell component="th" scope="row" className={styles.wrapCell}>
          <BuildingCardPreview
              name={row.name}
              street={row.addressStreet}
              city={row?.addressCity}
              thumb={row.mainPhotoThumbnail}
              officeID={row.id}
              marketID={marketId}
          />
        </TableCell>
        <TableCell className={styles.wrapCell}><Typography variant="body1">{displayOwnerName}</Typography></TableCell>
        <TableCell align="right">
          <OwnerPortfolioFinancePreview ownerId={parentId} propertyID={row.id} hasFinance={row.hasFinance} directBtn/>
        </TableCell>
        <TableCell align="right">
            <PropertyStatusPreview status={row.status}/>
        </TableCell>
        <TableCell align="right">{this.renderTrueFalse(row.forSale)}</TableCell>
        <TableCell align="right">{toNumberFormat(row.totalAreaAvailable, 'area')}</TableCell>
        <TableCell align="right">{toNumberFormat(row.rentFromEur, 'currency')}</TableCell>
        <TableCell align="right">{toNumberFormat(row.serviceChargesPln, 'currency', 'PLN')}</TableCell>
        <TableCell align="right">
          <ProgressBox percent={capacity}/>
        </TableCell>
      </>
    );
  }
}

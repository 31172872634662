import {ROUTES} from "./index";

export default [
    {
        path: ROUTES.DASHBOARD_PAGE,
        name: 'MENU.DASHBOARD'
    },
    {
        path: ROUTES.BUILDINGS_LIST_PAGE,
        name: 'MENU.BUILDINGS'
    },
    {
        path: ROUTES.MODULES_LIST_PAGE,
        name: 'MENU.UNITS'
    },
    {
        path: ROUTES.BUILDING_PAGE,
        name: 'MENU.BUILDING'
    },
    {
        path: ROUTES.COWORKS_PAGE,
        name: 'MENU.COWORKS'
    },
    {
        path: ROUTES.COWORK_PAGE,
        name: 'MENU.COWORK'
    },
    {
        path: ROUTES.USER_PROFILE,
        name: 'MENU.PROFILE'
    },
    {
        path: ROUTES.USER_WATCHLIST,
        name: 'MENU.WATCHLIST'
    },
    {
        path: ROUTES.TEAM_PAGE,
        name: 'MENU.TEAM'
    },
    {
        path: ROUTES.SUBSCRIBERS_PAGE,
        name: 'MENU.SUBSCRIBERS'
    },
    {
        path: ROUTES.ANALYTICS_PAGE,
        name: 'MENU.ANALYTICS'
    },
    {
        path: ROUTES.OWNERS_LIST_PAGE,
        name: 'MENU.OWNERS'
    },
    {
        path: ROUTES.OWNER_PAGE,
        name: 'MENU.OWNER'
    },
    {
        path: ROUTES.TRANSPARENCY_PAGE,
        name: 'MENU.TRANSPARENCY'
    },
    {
        path: ROUTES.ACQUISITIONS_PAGE,
        name: 'MENU.ACQUISITIONS'
    },
    {
        path: ROUTES.LEADS_TENANT_INTRODUCTIONS,
        name: 'MENU.LEADS_TENANT_INTRODUCTIONS'
    },
    {
        path: ROUTES.LEADS_POTENTIAL_TENANTS,
        name: 'MENU.LEADS_POTENTIAL_TENANTS'
    },
    {
        path: ROUTES.LATEST_TRANSACTIONS,
        name: 'MENU.LATEST_TRANSACTIONS'
    },
    {
        path: ROUTES.SUPPLY_BOOST,
        name: 'MENU.SUPPLY_BOOST'
    },
    {
        path: ROUTES.MEMBERS_PAGE,
        name: ''
    },
    {
        path: ROUTES.TEAMS_PAGE,
        name: ''
    },
    {
        path: ROUTES.EDIT_PROFILE_PAGE,
        name: ''
    },
    {
        path: ROUTES.SHORTLISTS_PAGE,
        name: 'MENU.MY_OFFERS'
    },
    {
        path: ROUTES.OFFER_PAGE,
        name: 'MENU.OFFER'
    },
    {
        path: ROUTES.SHORTLISTS_LANDLORD_PAGE,
        name: 'MENU.SHORTLISTS_ANALYSIS'
    },
    {
        path: ROUTES.OFFERS_ONLINE_ANALYSIS,
        name: 'MENU.OFFERS_ONLINE_ANALYTICS'
    },
    {
        path: ROUTES.PROPERTY_PARTICULARS_ANALYSIS,
        name: 'MENU.PROPERTY_PARTICULARS'
    },
    {
        path: ROUTES.LEADS_MANAGEMENT,
        name: 'MENU.LEADS_MANAGEMENT'
    },
    {
        path: ROUTES.COMPETITION_ANALYSIS_PAGE,
        name: 'MENU.COMPETITION_ANALYSIS'
    },
    {
        path: ROUTES.ADMIN_MANAGE_ACCOUNT_REQUESTS,
        name: 'MENU.ADMIN_MANAGE_ACCOUNT_REQUESTS'
    },
    // Logged out common Routes
    {
        path: ROUTES.LOGIN_PAGE,
        name: ''
    },
    {
        path: ROUTES.RESET_PASSWORD_PAGE,
        name: ''
    },
    {
        path: ROUTES.CHANGE_PASSWORD_PAGE,
        name: ''
    },
    {
        path: ROUTES.CHANGE_PASSWORD_PAGE,
        name: ''
    },
    {
        path: ROUTES.ADD_SUBSCRIBERS_PAGE,
        name: ''
    },
    {
        path: ROUTES.TERMS_OF_SERVICE_PAGE,
        name: ''
    },
    {
        path: ROUTES.PRIVACY_POLICY_PAGE,
        name: ''
    },
    {
        path: ROUTES.ABOUT_PAGE,
        name: ''
    },
    {
        path: ROUTES.AGENT_REGISTER_PAGE,
        name: ''
    },
    {
        path: ROUTES.LANDLORD_REGISTER_PAGE,
        name: ''
    },
    {
        path: ROUTES.ACCOUNT_REQUEST_PAGE,
        name: ''
    },
    // External ROUTES
    {
        path: 'https://www2.reddplatform.com'
    },
    // INDUSTRIAL
    {
        path: ROUTES.INDUSTRIAL_PARKS,
        name: 'MENU.PARKS'
    },
    {
        path: ROUTES.INDUSTRIAL_BUILDINGS,
        name: 'MENU.BUILDINGS'
    },
    {
        path: ROUTES.INDUSTRIAL_PARK,
        name: 'MENU.PARK'
    },
    {
        path: ROUTES.INDUSTRIAL_BUILDING,
        name: 'MENU.INDUSTRIAL_BUILDING'
    },
    {
        path: ROUTES.INDUSTRIAL_PROPERTY_PARTICULARS_ANALYSIS,
        name: 'MENU.PROPERTY_PARTICULARS'
    },
    {
        path: ROUTES.INDUSTRIAL_SHORTLISTS_PAGE,
        name: 'MENU.MY_OFFERS'
    },
    {
        path: ROUTES.INDUSTRIAL_SHORTLISTS_LANDLORD_PAGE,
        name: 'MENU.SHORTLISTS_ANALYSIS_INDUSTRIAL'
    },
    {
        path: ROUTES.INDUSTRIAL_OFFERS_ONLINE_ANALYSIS,
        name: 'MENU.OFFERS_ONLINE_ANALYTICS'
    },
    {
        path: ROUTES.INDUSTRIAL_FINNE_STATISTICS,
        name: 'MENU.INDUSTRIAL_FINNE_STATISTICS'
    },
    {
        path: ROUTES.ADMIN_ADD_USER,
        name: 'MENU.ADD_USER'
    },
];

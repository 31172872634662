import React from "react";
import {connect} from "react-redux";
import objectPath from "object-path";
import * as builder from "../../ducks/builder";
import {FormattedMessage, injectIntl} from "react-intl";
import {ROUTES} from "app/constants";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isZohoChatEnable: false,
    };
  }

  handleChatOpen = () => {
    document.getElementById("zsiq_float").click();
  };

  render() {
    const { intl } = this.props;
    const { isZohoChatEnable } = this.state;
    const checkZohoChatStatus = setInterval(() => {
      if (document.getElementById("zsiq_float")) {
        this.setState(
          {
            isZohoChatEnable: true,
          },
          () => clearInterval(checkZohoChatStatus)
        );
      }
    }, 1000);
    const isLangPL = intl.locale === "pl";

    return (
      <div
        className={`kt-footer ${this.props.footerClasses} kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop`}
        id="kt_footer"
      >
        <div className={`kt-container ${this.props.footerContainerClasses}`}>
          <div className="kt-footer__menu">
            <a
              href={`${ROUTES.REDD_LANDING_PAGE}/${
                isLangPL ? "platform/" : "en/platform/"
              }`}
              target="_blank"
              rel="noopener noreferrer"
              className="kt-footer__menu-link kt-link"
            >
              <FormattedMessage id="GENERAL.PHRASE.ABOUT_REDD" />
            </a>
            <a
              href={`${ROUTES.REDD_LANDING_PAGE}/${
                isLangPL ? "regulamin/" : "en/terms-of-service/"
              }`}
              target="_blank"
              rel="noopener noreferrer"
              className="kt-footer__menu-link kt-link"
            >
              <FormattedMessage id="AUTH.GENERAL.LEGAL" />
            </a>
            <a
              href={`${ROUTES.REDD_LANDING_PAGE}/${
                isLangPL ? "polityka-prywatnosci/" : "en/privacy-policy/"
              }`}
              target="_blank"
              rel="noopener noreferrer"
              className="kt-footer__menu-link kt-link"
            >
              <FormattedMessage id="AUTH.GENERAL.PRIVACY" />
            </a>
            <a
              href={
                "https://www.notion.so/reddgroup/PODSTAWOWA-BAZA-WIEDZY-87da329dafc246d39f7d98f05b3e86ef"
              }
              target="_blank"
              rel="noopener noreferrer"
              className="kt-footer__menu-link kt-link"
            >
              <FormattedMessage id="MENU.HELP_CENTER" />
            </a>
            <a
              href={`${ROUTES.REDD_LANDING_PAGE}/${
                isLangPL ? "kontakt/" : "en/contact/"
              }`}
              target="_blank"
              rel="noopener noreferrer"
              className="kt-footer__menu-link kt-link"
            >
              <FormattedMessage id="AUTH.GENERAL.CONTACT" />
            </a>
            {isZohoChatEnable && (
              <button
                className="kt-footer__menu-link kt-link"
                style={{
                  color: "#FF4626",
                  fontWeight: "bold",
                  border: 0,
                  backgroundColor: "transparent",
                  padding: 0,
                }}
                onClick={this.handleChatOpen}
              >
                <FormattedMessage id="AUTH.GENERAL.LIVE_CHAT" />
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  fluid:
    objectPath.get(store.builder.layoutConfig, "footer.self.width") === "fluid",
  footerClasses: builder?.selectors?.getClasses(store, {
    path: "footer",
    toString: true,
  }),
  footerContainerClasses: builder?.selectors?.getClasses(store, {
    path: "footer_container",
    toString: true,
  }),
});

export default injectIntl(connect(mapStateToProps)(Footer));

import React from "react";
import styles from "../index.module.scss";
import UnitRefreshAllOfficeUnits from "app/partials/components/Units/UnitRefreshAllOfficeUnits";
import UnitRefreshSelectedOfficeUnits from "app/partials/components/Units/UnitRefreshSelectedOfficeUnits";

export default class TableActionsLandlordOfficeUnits extends React.Component {
  render() {
    const {parentId, units, totalItems, totalAvailableItems, isShowUnavailable, ordering, onRefreshComplete } = this.props;
    const totalUnavailable = totalItems - totalAvailableItems;
    const allSelected = isShowUnavailable ? totalUnavailable : totalAvailableItems;

    return(
      <div className={styles.rowActions}>
        {units.length === allSelected && <UnitRefreshAllOfficeUnits officeId={parentId} ordering={ordering} onRefreshComplete={onRefreshComplete}/>}
        {units.length !== allSelected && units.length > 0 &&  <UnitRefreshSelectedOfficeUnits officeId={parentId} units={units} ordering={ordering} onRefreshComplete={onRefreshComplete}/>}
      </div>
    );
  }
}
import React from "react";
import {withRouter} from "react-router";
import {Link} from "react-router-dom";
import styles from "./index.module.scss";
import {Breadcrumbs, Typography} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import {ROUTES} from "app/constants";
import ROUTES_COMPLETE from "../../../constants/ROUTES_COMPLETE";

class BreadcrumbsCustom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prevLocation: props.location.pathname
    }
  }
  componentDidUpdate(prevProps) {
    if(prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({
        prevLocation: prevProps.location.pathname
      });
    }
  }

  render() {
    const {office, owner, park, warehouse, offer} = this.props;
    const {prevLocation} = this.state;
    const currentLocation = this.props.location.pathname;
    const isOffer = currentLocation.includes("/offer/");
    const isBuilding = currentLocation.includes("/building/");
    const isOwner = currentLocation.includes("/owner/");
    const isIndustrialPark = currentLocation.includes("/industrial-park/");
    const isIndustrialBuilding = currentLocation.includes("/industrial-building/");
    const isDashboard = currentLocation.includes("/dashboard");
    const isDummyDataOffice = currentLocation.includes("redd-tower-demo");
    const prevLocOffers = prevLocation === ROUTES.SHORTLISTS_PAGE || isOffer;
    const prevLocBuildings = prevLocation === ROUTES.BUILDINGS_LIST_PAGE || isBuilding;
    const prevLocOwners = prevLocation === ROUTES.OWNERS_LIST_PAGE || isOwner;
    const prevLocIndustrialParks = prevLocation === ROUTES.INDUSTRIAL_PARKS || isIndustrialPark;
    const prevLocIndustrialBuildings = prevLocation === ROUTES.INDUSTRIAL_BUILDINGS || isIndustrialBuilding;
    const prevLocIsFinalPage =
        prevLocation.includes("/offer/") ||
        prevLocation.includes("/building/") ||
        prevLocation.includes("/owner/") ||
        prevLocation.includes("/industrial-park/") ||
        prevLocation.includes("/industrial-building/");
    const setPrevLocation = prevLocIsFinalPage ? (
        isOffer ? ROUTES.SHORTLISTS_PAGE :
        isBuilding ? ROUTES.BUILDINGS_LIST_PAGE :
        isOwner ? ROUTES.OWNERS_LIST_PAGE :
        isIndustrialPark ? ROUTES.INDUSTRIAL_PARKS :
        isIndustrialBuilding && ROUTES.INDUSTRIAL_BUILDINGS
    ) : prevLocation;

    const currentLocationNameArray = ROUTES_COMPLETE.filter(route => currentLocation === route.path);
    const currentChildLocationNameArray = ROUTES_COMPLETE.filter(route => currentLocation.includes(route.path));
    const currentLocationName = currentLocationNameArray.length ? currentLocationNameArray[0].name : "---";
    const currentChildLocationName = currentChildLocationNameArray.length ? currentChildLocationNameArray[0].name : "---";
    const singleChildParentName =
        prevLocOffers ? "MENU.MY_OFFERS" :
        prevLocBuildings ? "MENU.BUILDINGS" :
        prevLocOwners ? "MENU.OWNERS" :
        prevLocIndustrialParks ? "MENU.PARKS" :
        prevLocIndustrialBuildings ? "MENU.BUILDINGS" : "---";

    const analyticsPageName = [
          "MENU.THE_LARGEST_SURFACE_RELEASES",
          "MENU.RENT_REDUCTIONS",
          "MENU.MOST_RENTED_BUILDINGS",
        ][currentLocation.split("/").pop() - 1];

    return(
      <div id="appBreadcrumbs" className={styles.breadcrumbsWrapper}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator="&#8226;"
        >
          {
            (isDashboard || isDummyDataOffice) ? <Typography><FormattedMessage id="MENU.DASHBOARD"/></Typography> :
              <Link to={ROUTES.DASHBOARD_PAGE}><FormattedMessage id="MENU.DASHBOARD"/></Link>
          }
          {!isDashboard && (isOffer || isBuilding || isOwner || isIndustrialPark || isIndustrialBuilding) &&
            <Link to={setPrevLocation}><FormattedMessage id={singleChildParentName}/></Link>
          }
          {!isDashboard &&
              <Typography>
                {
                  isDummyDataOffice ? "REDD Tower" :
                  currentChildLocationName === "MENU.OFFER" && offer ? offer?.name :
                  currentChildLocationName === "MENU.BUILDING" && office ? office?.name :
                  currentChildLocationName === "MENU.OWNER" && owner ? owner?.shortName :
                  currentChildLocationName === "MENU.PARK" && park ? park?.name :
                  currentChildLocationName === "MENU.ANALYTICS" ? <FormattedMessage id={analyticsPageName} /> :
                  currentChildLocationName === "MENU.INDUSTRIAL_BUILDING" && warehouse ? (warehouse.name === warehouse.parkName ? warehouse.name : `${warehouse.parkName} - ${warehouse.name}`) :
                  currentLocationName ? <FormattedMessage id={currentLocationName}/> : ""
                }
              </Typography>
          }
        </Breadcrumbs>
      </div>
    );
  }
}

export default withRouter(BreadcrumbsCustom);
import React from "react";
import {Grid, Typography} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import InputAdornments from "app/partials/components/InputAdornments";
import SingleSelect from "app/partials/components/SingleSelect";
import {FILTERS_SELECTS} from "app/partials/components/FiltersDrawer/constants";
import {isDarkMode, onlyNumbers} from "app/utils/helpers";
import _ from "lodash";
import {UNIT_STATUS} from "../../../../../constants";
import InputSwitcher from "../../../InputSwitcher";
import CURRENCIES from "../../../../../constants/CURRENCIES";
import {updateUnit} from "../../../../../crud/estates/offices/units.crud";
import ConfirmationDialog from "../../../ConfirmationDialog";
import AlertFormDialog from "../../../AlertFormDialog";
import format from "date-fns/format";
import IsAdmin from "../../../../../utils/IsAdmin";

class EditUnitBasics extends React.Component {
  constructor(props) {
    super(props);
    const statusChangeDateDefault = props.data?.statusChangeDate
      ? format(new Date(props.data?.statusChangeDate), "yyyy-MM-dd")
      : format(new Date(), "yyyy-MM-dd");
    this.state = {
      area: props.data?.area,
      entrance: props.data?.entrance,
      floor: props.data?.floor,
      availableFrom:
        props.data && props.data?.availableFrom
          ? format(new Date(props.data?.availableFrom), "yyyy-MM-dd")
          : "",
      unitType: props.data?.unitType,
      status: props.data?.status,
      statusChangeDate: statusChangeDateDefault,
      isSublease: props.data?.isSublease,
      subleaseContactName: props.data?.subleaseContactName,
      subleaseContactPhone: props.data?.subleaseContactPhone,
      subleaseContactEmail: props.data?.subleaseContactEmail,
      subleaseRent: props.data?.subleaseRent,
      subleaseRentToNegotiate: props.data?.subleaseRentToNegotiate,
      subleaseServiceCharge: props?.data?.subleaseServiceCharge,
      subleaseServiceChargesCurrency: props?.data?.subleaseServiceChargesCurrency,
      subleaseAvailableFrom:
        props.data && props.data?.subleaseAvailableFrom
          ? format(new Date(props.data?.subleaseAvailableFrom), "yyyy-MM-dd")
          : "",
      subleaseAvailableTo:
        props.data && props.data?.subleaseAvailableTo
          ? format(new Date(props.data?.subleaseAvailableTo), "yyyy-MM-dd")
          : "",
      subleaseParkingPlaces: props.data?.subleaseParkingPlaces,
      confirmationOpen: false,
      confirmationAction: false,
      alertFormOpen: false,
      alertStatusDate: false,
      availableFromAdditionalTime: props.data?.availableFromAdditionalTime !== "" ? props.data?.availableFromAdditionalTime : null,
    };
    this.initialState = this.state;
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    // Handle Change Detect
    this.props.onUnsavedData(!_.isEqual(this.initialState, this.state));
    // Handle Save
    if (!prevProps.requestSave && this.props.requestSave) {
      this.handleSave();
    }
    // Detect Unit Updating
    if(prevProps.unitUpdating !== this.props.unitUpdating) {
      this.props.onRequestSaving(this.props.unitUpdating);
    }
  }

  // Handlers
  handleChange = (event) => {
    const onlyNums = onlyNumbers(event.target.value);
    const allowOnlyNumbers =
      event.target.name === "area" || event.target.name === "floor";
    this.setState({
      alertFormOpen: false,
      [event.target.name]: allowOnlyNumbers ? onlyNums : event.target.value,
    });
  };
  handleChangeDate = (event) => {
    this.setState({
      alertStatusDate: false,
      [event.target.name]: event.target.value,
    });
  };
  handleSelectedType = (selected) => {
    this.setState({
      unitType: selected,
    });
  };
  handleSelectedStatus = (selected) => {
    this.setState({
      status: selected,
    });
  };
  handleSelect = (selected, target) => {
    this.setState({
      [target]: selected,
    });
  };
  handleChangeSwitcher = (name) => (event) => {
    this.setState({
      [name]: event.target.checked,
    });
  };
  handleConfirmHighFloor = () => {
    this.setState(
      {
        confirmationAction: true,
        confirmationOpen: false,
      },
      () => this.handleSave()
    );
  };
  handleSave = () => {
    const {unitId, updateUnit} = this.props;
    const {
      area,
      entrance,
      floor,
      availableFrom,
      availableFromAdditionalTime,
      unitType,
      status,
      statusChangeDate,
      isSublease,
      subleaseContactName,
      subleaseContactPhone,
      subleaseContactEmail,
      subleaseRent,
      subleaseRentToNegotiate,
      subleaseServiceCharge,
      subleaseServiceChargesCurrency,
      subleaseAvailableFrom,
      subleaseAvailableTo,
      subleaseParkingPlaces,
      confirmationAction,
    } = this.state;
    const subleaseDateFrom =
      subleaseAvailableFrom &&
      subleaseAvailableFrom !== "" &&
      new Date(subleaseAvailableFrom).toISOString();
    const subleaseDateTo =
      subleaseAvailableTo &&
      subleaseAvailableTo !== "" &&
      new Date(subleaseAvailableTo).toISOString();
    const updateData = {
      area: area,
      entrance: entrance,
      floor: floor,
      availableFrom: availableFrom ? format(new Date(availableFrom), "yyyy-MM-dd") : null,
      availableFromAdditionalTime: availableFromAdditionalTime !== "" ? availableFromAdditionalTime : null,
      unitType: unitType,
      status: status,
      statusChangeDate:
        statusChangeDate && statusChangeDate !== ""
          ? new Date(statusChangeDate).toISOString()
          : null,
      isSublease: isSublease,
      subleaseContactName: subleaseContactName,
      subleaseContactPhone: subleaseContactPhone,
      subleaseContactEmail: subleaseContactEmail,
      subleaseRent: subleaseRent || null,
      subleaseRentToNegotiate: subleaseRentToNegotiate,
      subleaseServiceCharge: subleaseServiceCharge || null,
      ..._.isNumber(subleaseServiceChargesCurrency) ? {subleaseServiceChargesCurrency} : {},
      subleaseAvailableFrom: subleaseDateFrom ? format(new Date(subleaseAvailableFrom), "yyyy-MM-dd") : null,
      subleaseAvailableTo: subleaseDateTo ? format(new Date(subleaseDateTo), "yyyy-MM-dd") : null,
      subleaseParkingPlaces: subleaseParkingPlaces || null,
    };

    if (!confirmationAction && Number(floor) > 40) {
      this.setState({
        confirmationOpen: true,
      });
    } else if (Number(area) < 5) {
      this.setState({
        alertFormOpen: true,
      });
    } else if (new Date(statusChangeDate) > new Date()) {
      this.setState({
        alertStatusDate: true,
      });
    } else {
      this.initialState = this.state;
      const backgroundRefreshObject = {
        target: "basic",
        ...this.props.extraData?.officeId ? {officeId: this.props.extraData?.officeId} : {},
        ...this.props.extraData?.ordering ? {ordering: this.props.extraData?.ordering} : {}
      };
      updateUnit(unitId, updateData, backgroundRefreshObject);
    }
  };

  render() {
    const {unitUpdating} = this.props;
    const {
      area,
      entrance,
      floor,
      availableFrom,
      availableFromAdditionalTime,
      unitType,
      status,
      statusChangeDate,
      isSublease,
      subleaseContactName,
      subleaseContactPhone,
      subleaseContactEmail,
      subleaseRent,
      subleaseRentToNegotiate,
      subleaseServiceCharge,
      subleaseServiceChargesCurrency,
      subleaseAvailableFrom,
      subleaseAvailableTo,
      subleaseParkingPlaces
    } = this.state;

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h3" style={{color: "#ff4626"}}><FormattedMessage id="VOCABULARY.Basic data"/></Typography>
          </Grid>
          <IsAdmin>
            <Grid item xs={4}>
              <IsAdmin markup="input">
                <InputAdornments
                  label={<FormattedMessage id="GENERAL.PHRASE.AREA"/>}
                  name="area"
                  value={area}
                  onChange={this.handleChange}
                  disabled={unitUpdating}
                />
              </IsAdmin>
            </Grid>
          </IsAdmin>
          <Grid item xs={4}>
            <InputAdornments
              label={<FormattedMessage id="GENERAL.PHRASE.ENTRANCE"/>}
              name="entrance"
              value={entrance}
              onChange={this.handleChange}
              disabled={unitUpdating}
            />
          </Grid>
          <Grid item xs={4}>
            <InputAdornments
              label={<FormattedMessage id="GENERAL.PHRASE.FLOOR"/>}
              name="floor"
              value={floor}
              onChange={this.handleChange}
              disabled={unitUpdating}
            />
          </Grid>
          <Grid item xs={6}>
            <InputAdornments
              type="date"
              label={<FormattedMessage id="BUILDING.PHRASE.AVAILABLE_FROM"/>}
              name="availableFrom"
              value={availableFrom}
              onChange={this.handleChangeDate}
              defaultDisabled
              disabled={unitUpdating}
            />
          </Grid>
          <Grid item xs={6}>
            <InputAdornments
              label={
                <FormattedMessage id="BUILDING.PHRASE.AVAILABLE_FROM_ADDITIONAL_TIME"/>
              }
              name="availableFromAdditionalTime"
              value={availableFromAdditionalTime}
              onChange={this.handleChange}
              disabled={unitUpdating}
            />
          </Grid>
          <IsAdmin>
            <Grid item xs={6}>
              <IsAdmin markup="input">
                <SingleSelect
                  label={<FormattedMessage id="BUILDING.PHRASE.TYPE"/>}
                  inputName="unitType"
                  options={FILTERS_SELECTS.UNITS_TYPES}
                  optionsValue="id"
                  optionsLabel="label"
                  defaultSelect={unitType}
                  onSelectedValues={this.handleSelectedType}
                  disabled={unitUpdating}
                />
              </IsAdmin>
            </Grid>
          </IsAdmin>
          <Grid item xs={6}>
            <SingleSelect
              label={<FormattedMessage id="BUILDING.PHRASE.STATUS"/>}
              inputName="status"
              options={FILTERS_SELECTS.UNITS_STATUSES}
              optionsValue="id"
              optionsLabel="label"
              defaultSelect={status}
              onSelectedValues={this.handleSelectedStatus}
              disabled={unitUpdating}
            />
          </Grid>
          {status === UNIT_STATUS.UNAVAILABLE && (
            <Grid item xs={4}>
              <InputAdornments
                type="date"
                label={<FormattedMessage id="BUILDING.PHRASE.RENTED_AT"/>}
                name="statusChangeDate"
                value={statusChangeDate}
                onChange={this.handleChangeDate}
                disabled={unitUpdating}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Typography>
                  <FormattedMessage id="UNITS.PHRASE.IS_SUBLEASE"/>
                </Typography>
              </Grid>
              <Grid item>
                <InputSwitcher
                  checked={isSublease}
                  value="isSublease"
                  label={<FormattedMessage id="GENERAL.PHRASE.YES"/>}
                  onChange={this.handleChangeSwitcher("isSublease")}
                />
              </Grid>
            </Grid>
          </Grid>
          {isSublease && (
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                style={{
                  backgroundColor: isDarkMode() ? "rgba(238, 244, 253, 0.1)" : "#f9f9f9",
                  borderRadius: "4px",
                  paddingTop: "0.5rem",
                  paddingBottom: "1.5rem",
                  marginTop: "2.5rem"
                }}
              >
                <Grid item xs={12}>
                  <Typography variant="h4" style={{color: "#ffffff"}}>
                    <FormattedMessage id="GENERAL.PHRASE.CONTACT_PERSON"/>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <InputAdornments
                    label={<FormattedMessage id="FORM.FIELD.FULLNAME"/>}
                    name="subleaseContactName"
                    value={subleaseContactName}
                    onChange={this.handleChange}
                    disabled={unitUpdating}
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputAdornments
                    label={<FormattedMessage id="FORM.FIELD.EMAIL_ADDRESS"/>}
                    name="subleaseContactEmail"
                    value={subleaseContactEmail}
                    onChange={this.handleChange}
                    disabled={unitUpdating}
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputAdornments
                    label={<FormattedMessage id="FORM.FIELD.PHONE_NUMBER"/>}
                    name="subleaseContactPhone"
                    value={subleaseContactPhone}
                    onChange={this.handleChange}
                    disabled={unitUpdating}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h4"
                    style={{color: "#ffffff", marginTop: 25}}
                  >
                    <FormattedMessage id="BUILDING.PHRASE.LEASE_TERMS"/>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <Typography>
                        <FormattedMessage id="UNITS.PHRASE.IS_RENT_TO_NEGOTIATE"/>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <InputSwitcher
                        checked={subleaseRentToNegotiate}
                        value="subleaseRentToNegotiate"
                        label={<FormattedMessage id="GENERAL.PHRASE.YES"/>}
                        onChange={this.handleChangeSwitcher(
                          "subleaseRentToNegotiate"
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <InputAdornments
                    label={
                      <FormattedMessage id="BUILDING.PHRASE.RENT_FROM"/>
                    }
                    name="subleaseRent"
                    value={subleaseRent}
                    onChange={this.handleChange}
                    disabled={subleaseRentToNegotiate || unitUpdating}
                  />
                </Grid>
                <Grid item xs={8}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <InputAdornments
                        label={
                          <FormattedMessage id="BUILDING.PHRASE.SERVICE_CHARGE"/>
                        }
                        name="subleaseServiceCharge"
                        value={subleaseServiceCharge}
                        onChange={this.handleChange}
                        disabled={unitUpdating}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <SingleSelect
                        label={<FormattedMessage id="BUILDING.PHRASE.SERVICE_CHARGES_CURRENCY"
                                                 values={{breakingLine: ''}}/>}
                        inputName="subleaseServiceChargesCurrency"
                        defaultSelect={subleaseServiceChargesCurrency}
                        options={CURRENCIES}
                        optionsValue="value"
                        optionsLabel="label"
                        onSelectedValues={this.handleSelect}
                        required
                        disabled={unitUpdating}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <InputAdornments
                        type="date"
                        label={
                          <FormattedMessage id="BUILDING.PHRASE.AVAILABLE_FROM"/>
                        }
                        name="subleaseAvailableFrom"
                        value={subleaseAvailableFrom}
                        onChange={this.handleChangeDate}
                        disabled={unitUpdating}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputAdornments
                        type="date"
                        label={
                          <FormattedMessage id="BUILDING.PHRASE.AVAILABLE_TO"/>
                        }
                        name="subleaseAvailableTo"
                        value={subleaseAvailableTo}
                        onChange={this.handleChangeDate}
                        disabled={unitUpdating}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <InputAdornments
                    label={
                      <FormattedMessage id="BUILDING.PHRASE.PARKING_PLACES"/>
                    }
                    name="subleaseParkingPlaces"
                    value={subleaseParkingPlaces}
                    onChange={this.handleChange}
                    disabled={unitUpdating}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <ConfirmationDialog
          open={this.state.confirmationOpen}
          onConfirm={this.handleConfirmHighFloor}
          message={
            <FormattedMessage
              id="GENERAL.PHRASE.UNIT_FLOOR_CONFIRMATION"
              values={{floor: floor}}
            />
          }
        />
        <AlertFormDialog
          open={this.state.alertFormOpen}
          message={<FormattedMessage id="GENERAL.PHRASE.FORM_ALERT_MIN_AREA"/>}
        />
        <AlertFormDialog
          open={this.state.alertStatusDate}
          message={
            <FormattedMessage id="GENERAL.PHRASE.FORM_ALERT_UNIT_STATUS_DATE"/>
          }
        />
      </>
    );
  }
}

const mapStateToProps = store => ({
  unitUpdating: store.units.officeUnitsUpdateLoading
});
const mapDispatchToProps = {
  updateUnit: (unitId, data, backgroundRefreshObject) => updateUnit(unitId, data, backgroundRefreshObject),
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditUnitBasics)
);
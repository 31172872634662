import React from "react";
import styles from "../../../../../common/external/components/index.module.scss";
import {toAbsoluteUrl} from "_metronic";
import IconColorMUI from "app/partials/components/IconColorMUI";
import clsx from "clsx";
import ROUTES from "app/constants/ROUTES";
import {Link, Redirect} from "react-router-dom";
import {FormattedMessage, injectIntl} from "react-intl";
import OffersOnlineHeader from "../OffersOnlineHeader";
import LoadingScreen from "app/partials/components/LoadingScreen";
import {handleEmptyValue, snakeToCamelCase, toNumberFormat} from "app/utils/helpers";
import NoData from "../../../../../../partials/components/NoData/NoData";
import ModalCustom from "app/partials/components/ModalCustom";
import SliderCustom from "../../../../../../partials/components/SliderCustom";
import IPI_PLAN_SOURCE from "../../../../../../constants/IPI_PLAN_SOURCE";
import IndustrialPreviewIPI from "../../../../components/IndustrialPreviewIPI";
import connect from "react-redux/es/connect/connect";
import {callReddClickEvent} from "app/crud/user.crud";
import Button from "../../../../../../partials/components/Button";

const getBrandingStyles = (style) => {
  const stylesForModalElement = document.getElementById(
    "propertyParticularsContainer"
  );

  if (!stylesForModalElement) {
    return;
  }

  const stylesParts = stylesForModalElement.attributes.style.nodeValue
    .split(/;|:/)
    .map((item) => item.replaceAll(" ", ""));
  const getStyleIndexByName = stylesParts.indexOf(style);

  return stylesParts[getStyleIndexByName + 1];
};

class ListItemAccordion extends React.Component {
  constructor(props) {
    super(props);
    const isMobile = window.outerWidth < 760;
    this.state = {
      collapsed: isMobile,
    };
  }

  handleToggleCollapse = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    const {title, children} = this.props;
    const {collapsed} = this.state;
    return (
      <div className={styles.accordionWrapper}>
        <div
          className={styles.accordionHeader}
          onClick={() => this.handleToggleCollapse()}
        >
          {title}
          <IconColorMUI
            icon={collapsed ? "add" : "remove"}
            style={{color: "var(--brand-btn-bg-color)"}}
          />
        </div>
        <div
          className={clsx(
            styles.accordionContent,
            collapsed && styles.collapsed
          )}
        >
          {children}
        </div>
      </div>
    );
  }
}

class OffersOnlineList extends React.Component {
  constructor(props) {
    super(props);
    this.propertiesWrapper = React.createRef();
    this.propertyItem = React.createRef();
    this.propertiesContainer = React.createRef();
    this.state = {
      btnNavPrev: false,
      btnNavNext: true,
      btnNavPause: false,
      scrollPosition: 0,
      isFetchDataComplete: false,
      isReady: false
    };
    this.child = React.createRef();
    // Fetch Data on Init
    const offerID =
      props.match && props.match.params && props.match.params.offer;
    const authEmail = handleEmptyValue(localStorage.getItem("offerMail"), true);

    if (offerID && !props.loading) {
      props.onFetchData(offerID, authEmail);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {onFetchPhotos, match} = this.props;
    const offerID = match && match.params && match.params.offer;
    const authEmail = handleEmptyValue(localStorage.getItem("offerMail"), true);
    if (
      (prevProps.items &&
        this.props.items &&
        prevProps.items.length &&
        this.props.items.length &&
        prevProps.items[0].pk !== this.props.items[0].pk) ||
      (prevProps.items &&
        prevProps.items.length &&
        this.props.items &&
        !this.props.items.length) ||
      (prevProps.items &&
        !prevProps.items.length &&
        this.props.items &&
        this.props.items.length) ||
      (prevProps.items && !this.props.items) ||
      (!prevProps.items && this.props.items)
    ) {
      this.setState({
        isFetchDataComplete: true
      }, () => onFetchPhotos(offerID, authEmail));
    }
    // Call Tracking Event - Page Visited
    if(!this.state.isReady && offerID && this.state.isFetchDataComplete) {
      this.setState({
        isReady: true
      }, () => {
        this.props.dispatch(callReddClickEvent(
          "offers-industrial-details",
          "offer",
          null,
          offerID,
          {email: authEmail}
        ));
      });
    }
  }

  handleListHorizontalScroll = (direction) => {
    const scrollElement = this.propertiesWrapper;
    const scrollValue =
      this.propertyItem.current.offsetWidth +
      Number(
        window
          .getComputedStyle(this.propertyItem.current)
          .getPropertyValue("margin-right")
          .replace("px", "")
      );
    const wrapperWidth =
      this.propertiesContainer.current.offsetWidth +
      Number(
        window
          .getComputedStyle(this.propertiesContainer.current)
          .getPropertyValue("margin-right")
          .replace("px", "")
      );
    const totalWidth =
      scrollElement.current.offsetWidth +
      Number(
        window
          .getComputedStyle(scrollElement.current)
          .getPropertyValue("margin-right")
          .replace("px", "")
      );
    const maxScroll =
      Math.floor((totalWidth - (wrapperWidth - scrollValue)) / 100) * 100;
    const currentPos = window
      .getComputedStyle(scrollElement.current)
      .getPropertyValue("-webkit-transform")
      .split(/[()]/)[1];
    const currentX = currentPos.split(",")[4];

    // Block button until animation is finished
    this.setState(
      {
        btnNavPause: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            btnNavPause: false,
          });
        }, 400);
      }
    );

    // Handle PREV scroll
    if (direction === "prev") {
      const newX = Number(currentX) + Number(scrollValue);

      if (-newX <= maxScroll) {
        this.setState({
          btnNavNext: true,
          scrollPosition: newX,
        });
      }
      if (newX >= 0) {
        this.setState({
          btnNavPrev: false,
        });
      }
    }
    // Handle NEXT scroll
    else if (direction === "next") {
      const newX = Number(currentX) - Number(scrollValue);

      if (-newX <= maxScroll) {
        this.setState({
          btnNavPrev: true,
          scrollPosition: newX,
        });
      }
      if (-newX >= maxScroll) {
        this.setState({
          btnNavNext: false,
        });
      }
    }
  };

  handleCloseModal = () => {
    this.child.current.handleClose();
  };

  render() {
    const {
      match,
      items,
      loading,
      branding,
      intl,
      onFetchData,
      photos,
      loadingPhotos,
      isSingleProperty
    } = this.props;
    const {btnNavPrev, btnNavNext, btnNavPause, scrollPosition, isFetchDataComplete} = this.state;
    const offerID = match && match.params && match.params.offer;
    const hasBuildings = items && items.length > 0;
    const isDouble = hasBuildings && items.length === 2;
    const isTriple = hasBuildings && items.length === 3;
    const isQuadruple = hasBuildings && items.length === 4;
    const isSliderActive =
      hasBuildings &&
      !isSingleProperty &&
      !isDouble &&
      !isTriple &&
      !isQuadruple;

    if (!loading && isSingleProperty && isFetchDataComplete) {
      return <Redirect to={`${match.url}${items[0].pk}/`}/>;
    }

    return (
      <div className={styles.mainContainer}>
        <OffersOnlineHeader
          view="list"
          branding={branding}
          offerID={offerID}
          totalItems={items && items.length}
          onFilterMarket={onFetchData}
        />
        {loading ? (
          <LoadingScreen
            brand
            brandColor={branding.offerBgColor || "#4a9dfd"}
          />
        ) : (
          <div
            ref={this.propertiesContainer}
            className={clsx(
              styles.propertiesContainer,
              isSliderActive && styles.sliderActive
            )}
          >
            {hasBuildings ? (
              <div
                ref={this.propertiesWrapper}
                className={clsx(
                  styles.propertiesWrapper,
                  !isSliderActive && styles.sliderNotActive
                )}
                style={{transform: `translateX(${scrollPosition}px)`}}
              >
                {hasBuildings &&
                  items.map((item, index) => {
                    const officeAddress = [
                      ...(item.addressStreet ? [item.addressStreet] : []),
                      ...(item.district ? [item.district] : []),
                      ...(item.addressCity ? [item.addressCity] : []),
                    ];
                    const totalAreaWarehouse = item.warehouses.reduce(
                      (accumulator, item) =>
                        accumulator + item.totalAreaWarehouseAvailable,
                      0
                    );
                    const totalAreaOffice =
                      item.offices &&
                      item.offices.reduce(
                        (accumulator, item) =>
                          accumulator + item.totalAreaOfficeAvailable,
                        0
                      );
                    const totalBuildings = item.warehouses.length;
                    const propertyUrlSeparator = match?.url.substr(-1) !== '/' ? '/' : '';
                    const propertyUrl = `${match?.url}${propertyUrlSeparator}${item?.pk}/`;
                    const plan = item?.ipiPlanImage ? item?.ipiPlanImage : item?.planImage ? item?.planImage : null;

                    return (
                      <div
                        key={index}
                        ref={this.propertyItem}
                        className={clsx(
                          styles.propertyItem,
                          isSingleProperty && styles.singleItem,
                          isDouble && styles.doubleItem,
                          isTriple && styles.tripleItem,
                          isQuadruple && styles.quadrupleItem
                        )}
                      >
                        <div className={styles.propertyHeader}>
                          <h2>{handleEmptyValue(item.name, true)}</h2>
                          <p className={styles.propertyAddress}>
                            {officeAddress.join(", ")}
                          </p>
                        </div>
                        <div className={styles.propertySlider}>
                          {loadingPhotos ? (
                            <LoadingScreen
                              brand
                              brandColor={branding.offerBgFontColor || "#4a9dfd"}
                            />
                          ) : (
                            photos &&
                            photos.length > 0 &&
                            photos.filter((photo) => photo.parkId === item.pk).length > 0 && (
                              <img
                                key={index}
                                src={
                                  photos.filter(
                                    (photo) => photo.parkId === item.pk
                                  )[0].mainPhotoThumbnail
                                }
                                alt={item.name}
                              />
                            )
                          )}
                        </div>
                        <div className={styles.propertyContent}>
                          <ListItemAccordion
                            title={
                              <h3>
                                <FormattedMessage id="SHORTLIST.PHRASE.AVAIABLE_AREA"/>
                              </h3>
                            }
                          >
                            <div className={styles.dataWrapper}>
                              <div className={styles.dataItem}>
                                <span className={styles.dataValue}>
                                  {toNumberFormat(totalAreaWarehouse, "area")}
                                </span>
                                <span className={styles.dataLabel}>
                                  <FormattedMessage id="SHORTLIST.PHRASE.WAREHOUSE_AREA"/>
                                </span>
                              </div>
                              <div className={styles.dataItem}>
                                <span className={styles.dataValue}>
                                  {toNumberFormat(totalAreaOffice, "area")}
                                </span>
                                <span className={styles.dataLabel}>
                                  <FormattedMessage id="SHORTLIST.PHRASE.OFFICE_AREA"/>
                                </span>
                              </div>
                              <div className={styles.dataItem}>
                                <span className={styles.dataValue}>
                                  {totalBuildings}
                                </span>
                                <span className={styles.dataLabel}>
                                  <FormattedMessage id="SHORTLIST.PHRASE.WAREHOUSES_AMOUNT"/>
                                </span>
                              </div>
                            </div>
                          </ListItemAccordion>
                          <ListItemAccordion
                            title={
                              <h3>
                                <FormattedMessage id="SHORTLIST.PHRASE.PARK_DETAILS"/>
                              </h3>
                            }
                          >
                            <div className={styles.dataWrapper}>
                              <div className={styles.dataItem}>
                                <span className={styles.dataValue}>
                                  {toNumberFormat(
                                    item.marketplaceTotalAreaUnderConstruction,
                                    "area"
                                  )}
                                </span>
                                <span className={styles.dataLabel}>
                                  <FormattedMessage id="SHORTLIST.PHRASE.AREA_UNDER_CONSTRUCTION"/>
                                </span>
                              </div>
                              <div className={styles.dataItem}>
                                <span className={styles.dataValue}>
                                  {toNumberFormat(
                                    item.marketplaceTotalAreaPlanned,
                                    "area"
                                  )}
                                </span>
                                <span className={styles.dataLabel}>
                                  <FormattedMessage id="SHORTLIST.PHRASE.PLANED_AREA"/>
                                </span>
                              </div>
                              <div className={styles.dataItem}>
                                <span className={styles.dataValue}>
                                  {toNumberFormat(
                                    item.marketplaceIndustrialUnitMinArea,
                                    "area"
                                  )}
                                </span>
                                <span className={styles.dataLabel}>
                                  <FormattedMessage id="SHORTLIST.PHRASE.MINIMAL_AREA"/>
                                </span>
                              </div>
                              <div className={styles.dataItem}>
                                <span className={styles.dataValue}>
                                  {toNumberFormat(
                                    item?.certificates?.join(", "),
                                    ""
                                  )}
                                </span>
                                <span className={styles.dataLabel}>
                                  <FormattedMessage id="SHORTLIST.PHRASE.CERTYFICATES"/>
                                </span>
                              </div>
                            </div>
                          </ListItemAccordion>
                          <ListItemAccordion
                            title={
                              <h3>
                                <FormattedMessage id="INDUSTRIAL.PHRASE.LEASE_TERMS"/>
                              </h3>
                            }
                          >
                            <div className={styles.dataWrapper}>
                              <div className={styles.dataItem}>
                                <span className={styles.dataValue}>
                                  {toNumberFormat(
                                    item.rentWarehouseFrom,
                                    "currency"
                                  )}
                                </span>
                                <span className={styles.dataLabel}>
                                  <FormattedMessage id="SHORTLIST.PHRASE.WAREHOUSE_MINIMAL_RENT"/>
                                </span>
                              </div>
                              <div className={styles.dataItem}>
                                <span className={styles.dataValue}>
                                  {toNumberFormat(
                                    item.serviceWarehouseFrom,
                                    "currency",
                                    "PLN"
                                  )}
                                </span>
                                <span className={styles.dataLabel}>
                                  <FormattedMessage id="SHORTLIST.PHRASE.WAREHOUSE_RENT_CHARGE"/>
                                </span>
                              </div>
                              <div className={styles.dataItem}>
                                <span className={styles.dataValue}>
                                  {toNumberFormat(
                                    item.rentOfficeFrom,
                                    "currency"
                                  )}
                                </span>
                                <span className={styles.dataLabel}>
                                  <FormattedMessage id="SHORTLIST.PHRASE.OFFICE_MINIMAL_RENT"/>
                                </span>
                              </div>
                              <div className={styles.dataItem}>
                                <span className={styles.dataValue}>
                                  {toNumberFormat(
                                    item.serviceOfficeFrom,
                                    "currency",
                                    "PLN"
                                  )}
                                </span>
                                <span className={styles.dataLabel}>
                                  <FormattedMessage id="SHORTLIST.PHRASE.OFFICE_RENT_CHARGE"/>
                                </span>
                              </div>
                            </div>
                          </ListItemAccordion>
                          {plan && (
                            <ListItemAccordion
                              title={
                                <h3>
                                  <FormattedMessage id="INDUSTRIAL.PHRASE.SITE_PLAN"/>
                                </h3>
                              }
                            >
                              {item?.ipiPlanImage ?
                                <IndustrialPreviewIPI
                                  title={item?.name}
                                  planSource={IPI_PLAN_SOURCE.PARK}
                                  planPark={item?.ipiPlanImage}
                                  parkID={item?.id}
                                  units={item?.ipi}
                                  triggerStyle={{height: "20rem"}}
                                  triggerBtnStyle={{
                                    backgroundColor: "var(--brand-btn-bg-color)",
                                    borderColor: "var(--brand-btn-bg-color)",
                                    color: "var(--brand-btn-bg-font-color)"
                                  }}
                                /> : item?.planImage &&
                                <ModalCustom
                                  btn={
                                    <img
                                      src={item?.planImage}
                                      alt={`${item?.name}`}
                                      style={{
                                        width: "100%",
                                        cursor: "zoom-in"
                                      }}
                                    />
                                  }
                                  title=""
                                  btnReject={<FormattedMessage
                                    id="GENERAL.PHRASE.CLOSE"/>}
                                  fullScreen
                                >
                                  <SliderCustom images={[item?.planImage]} controls={false}/>
                                </ModalCustom>
                              }
                            </ListItemAccordion>
                          )}
                          <ListItemAccordion
                            title={
                              <h3>
                                <FormattedMessage
                                  id="INDUSTRIAL.PHRASE.TECHNICAL_DETAILS"/>
                              </h3>
                            }
                          >
                            <div className={styles.dataWrapper}>
                              <div className={styles.dataItem}>
                                <span className={styles.dataValue}>
                                  {toNumberFormat(item.storageHeightMax)}{" "}
                                  {item.storageHeightMax ? "m" : null}
                                </span>
                                <span className={styles.dataLabel}>
                                  <FormattedMessage id="INDUSTRIAL.PHRASE.STORAGE_HEIGHT_MAX"/>
                                </span>
                              </div>
                              <div className={styles.dataItem}>
                                <span className={styles.dataValue}>
                                  {toNumberFormat(item.flooringCarrierMax)}{" "}
                                  {item.flooringCarrierMax ? "kg" : null}
                                </span>
                                <span className={styles.dataLabel}>
                                  <FormattedMessage id="INDUSTRIAL.PHRASE.FLOOR_LOADING_CAPACITY_MAX"/>
                                </span>
                              </div>
                              <div className={styles.dataItem}>
                                <span className={styles.dataValue}>
                                  {handleEmptyValue(
                                    toNumberFormat(item.parkingSlotsTruck)
                                  )}
                                </span>
                                <span className={styles.dataLabel}>
                                  <FormattedMessage id="INDUSTRIAL.PHRASE.PARKING_TRUCK_SLOTS"/>
                                </span>
                              </div>
                              <div className={styles.dataItem}>
                                <span className={styles.dataValue}>
                                  {handleEmptyValue(
                                    toNumberFormat(item.parkingSlotsCar)
                                  )}
                                </span>
                                <span className={styles.dataLabel}>
                                  <FormattedMessage id="INDUSTRIAL.PHRASE.PARKING_CAR_SLOTS"/>
                                </span>
                              </div>
                              <div className={styles.dataItem}>
                                <span className={styles.dataValue}>
                                  {handleEmptyValue(
                                    toNumberFormat(
                                      item.warehouses.reduce(
                                        (prev, current) => prev + current.docks,
                                        0
                                      )
                                    )
                                  )}
                                </span>
                                <span className={styles.dataLabel}>
                                  <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.LOADING_DOCKS"/>
                                </span>
                              </div>
                              {item.columnGrid.length ? (
                                <div className={styles.dataItem}>
                                  <span className={styles.dataValue}>
                                    <ModalCustom
                                      ref={this.child}
                                      btn={
                                      <Button
                                        style={{
                                          backgroundColor: "var(--brand-btn-bg-color)",
                                          borderColor: "var(--brand-btn-bg-color)",
                                          color: "var(--brand-btn-bg-font-color)",
                                        }}
                                      >
                                        <FormattedMessage id="INDUSTRIAL.PHRASE.COLUMN_GRID"/>
                                      </Button>
                                      }
                                      btnRejectDisabled
                                    >
                                      <h3
                                        style={{
                                          color: getBrandingStyles(
                                            "--brand-font-color"
                                          ),
                                        }}
                                      >
                                        <FormattedMessage id="INDUSTRIAL.PHRASE.COLUMN_GRID"/>
                                      </h3>
                                      <table className={styles.columnsGrid}>
                                        <thead
                                          style={{
                                            backgroundColor: getBrandingStyles(
                                              "--brand-bg-color"
                                            ),
                                            color: getBrandingStyles(
                                              "--brand-bg-font-color"
                                            ),
                                          }}
                                        >
                                          <tr>
                                            <th>
                                              <FormattedMessage id="INDUSTRIAL.PHRASE.WAREHOUSE_NAME"/>
                                            </th>
                                            <th>
                                              <FormattedMessage id="INDUSTRIAL.PHRASE.COLUMN_GRID"/>
                                            </th>
                                            <th>
                                              <FormattedMessage id="INDUSTRIAL.PHRASE.COLUMN_GRID_UNIT"/>
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {item.columnGrid?.map((item) => (
                                            <tr key={item.name}>
                                              <td>{item.name}</td>
                                              <td>{item.columnGrid}</td>
                                              <td>{item.unit}</td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </ModalCustom>
                                  </span>
                                </div>
                              ) : null}
                            </div>
                          </ListItemAccordion>
                          {item.amenities && item.amenities.length ? (
                            <ListItemAccordion
                              title={
                                <h3>
                                  <FormattedMessage
                                    id="PROPERTY_PARTICULARS.PHRASE.AMENITIES"/>
                                </h3>
                              }
                            >
                              <div
                                className={clsx(
                                  styles.dataWrapper,
                                  styles.slim
                                )}
                              >
                                {item.amenities
                                  .sort((a, b) => b.length - a.length)
                                  .map((amenity) => {
                                    return (
                                      <div
                                        key={amenity}
                                        className={styles.dataItem}
                                      >
                                        <span className={styles.itemChecked}>
                                          <IconColorMUI icon="done"/>
                                          {/* CHECK MAPING */}
                                          {intl.formatMessage({id: snakeToCamelCase(amenity)})}
                                        </span>
                                      </div>
                                    );
                                  })}
                              </div>
                            </ListItemAccordion>
                          ) : null}
                        </div>
                        <div className={styles.propertyActions}>
                          <Link to={propertyUrl}>
                            <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.MORE_INFO"/>
                          </Link>
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : (
              <NoData
                message={<FormattedMessage id="No parks in the offer."/>}
              />
            )}
            {hasBuildings && isSliderActive && (
              <div className={styles.carouselNavigation}>
                <button
                  id="navPrev"
                  className={clsx(
                    styles.btnNav,
                    !btnNavPrev && styles.disabled,
                    btnNavPause && styles.inactive
                  )}
                  onClick={() => this.handleListHorizontalScroll("prev")}
                >
                  <IconColorMUI icon="arrow_back_ios" size={30}/>
                </button>
                <button
                  id="navNext"
                  className={clsx(
                    styles.btnNav,
                    !btnNavNext && styles.disabled,
                    btnNavPause && styles.inactive
                  )}
                  onClick={() => this.handleListHorizontalScroll("next")}
                >
                  <IconColorMUI icon="arrow_forward_ios" size={30}/>
                </button>
              </div>
            )}
          </div>
        )}
        <div className={styles.footerSection}>
          <span>
            Powered by{" "}
            <a
              href={ROUTES.REDD_LANDING_PAGE}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={toAbsoluteUrl("media/pp-mockup-media/logo-redd.png")}
                alt="redd property particulars"
              />
            </a>
          </span>
        </div>
      </div>
    );
  }
}

export default injectIntl(connect()(OffersOnlineList));

export default {
  WAREHOUSE_SBU: {
    id: 1,
    name: 'INDUSTRIAL.WAREHOUSE.TYPE.SBU'
  },
  WAREHOUSE_BTS: {
    id: 2,
    name: 'INDUSTRIAL.WAREHOUSE.TYPE.BTS'
  },
  WAREHOUSE_BIG_BOX:  {
    id: 3,
    name: 'INDUSTRIAL.WAREHOUSE.TYPE.BIG_BOX'
  },
  WAREHOUSE_CITY_FLEX:  {
    id: 4,
    name: 'INDUSTRIAL.WAREHOUSE.TYPE.CITY_FLEX'
  },
  WAREHOUSE_OTHER:  {
    id: 5,
    name: 'INDUSTRIAL.WAREHOUSE.TYPE.OTHER'
  }
}
import ROUTES from "./ROUTES";

export default {
    ESTATES: [
        ROUTES.BUILDINGS_LIST_PAGE,
        ROUTES.MODULES_LIST_PAGE,
        ROUTES.COWORKS_PAGE,
        ROUTES.BUILDING_PAGE,
        ROUTES.COWORK_PAGE,
        ROUTES.ANALYTICS_PAGE,
        ROUTES.OWNERS_LIST_PAGE,
        ROUTES.OWNER_PAGE,
        ROUTES.TRANSPARENCY_PAGE,
        ROUTES.ACQUISITIONS_PAGE,
        ROUTES.BIURA_INFO_STATS_PAGE,
        ROUTES.LEADS_TENANT_INTRODUCTIONS,
        ROUTES.LEADS_POTENTIAL_TENANTS,
        ROUTES.PROPERTY_PARTICULARS_ANALYSIS,
        ROUTES.PROPERTY_PARTICULARS_ONLINE,
        ROUTES.SHORTLISTS_PAGE,
        ROUTES.SHORTLISTS_LANDLORD_PAGE,
        ROUTES.OFFERS_ONLINE,
        ROUTES.OFFERS_ONLINE_ANALYSIS
    ],
    INDUSTRIAL: [
        ROUTES.INDUSTRIAL_PARKS,
        ROUTES.INDUSTRIAL_PARK,
        ROUTES.INDUSTRIAL_BUILDINGS,
        ROUTES.INDUSTRIAL_BUILDING,
        ROUTES.INDUSTRIAL_SHORTLISTS_PAGE,
        ROUTES.INDUSTRIAL_OFFERS_ONLINE_ANALYSIS
    ]
};
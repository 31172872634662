import React from "react";
import {Grid} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import InputAdornments from "app/partials/components/InputAdornments";
import {onlyNumbers} from "app/utils/helpers";
import _ from "lodash";
import {updateIndustrialPark} from "../../../../../crud/estates/industrial/industrial.crud";

class EditParkLeaseTerms extends React.Component {
  constructor(props) {
    super(props);
    const {
      rentWarehouseFrom,
      rentWarehouseTo,
      rentOfficeFrom,
      rentOfficeTo,
      serviceWarehouseFrom,
      serviceWarehouseTo,
      serviceOfficeFrom,
      serviceOfficeTo,
      parkMinRentalYears
    } = props.data;
    this.state = {
      rentWarehouseFrom: rentWarehouseFrom || null,
      rentWarehouseTo: rentWarehouseTo || null,
      rentOfficeFrom: rentOfficeFrom || null,
      rentOfficeTo: rentOfficeTo || null,
      serviceWarehouseFrom: serviceWarehouseFrom || null,
      serviceWarehouseTo: serviceWarehouseTo || null,
      serviceOfficeFrom: serviceOfficeFrom || null,
      serviceOfficeTo: serviceOfficeTo || null,
      parkMinRentalYears: parkMinRentalYears || null
    };
    this.initialState = this.state;
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    // Handle Change Detect
    this.props.onUnsavedData(!_.isEqual(this.initialState, this.state));
    // Handle Save
    if(!prevProps.requestSave && this.props.requestSave) {
      this.handleSave();
    }
    // Detect Office Updating
    if(prevProps.parkUpdateLoading !== this.props.parkUpdateLoading) {
      this.props.onRequestSaving(this.props.parkUpdateLoading);
    }
  }

  handleChange = validation => event => {
    const onlyNums = onlyNumbers(event.target.value);
    this.setState({
      [event.target.name]: validation === 'number' ? onlyNums : event.target.value
    });
  };
  handleSave = () => {
    const {data: {id}, updateIndustrialPark} = this.props;
    const {
      rentWarehouseFrom,
      rentWarehouseTo,
      rentOfficeFrom,
      rentOfficeTo,
      serviceWarehouseFrom,
      serviceWarehouseTo,
      serviceOfficeFrom,
      serviceOfficeTo,
      parkMinRentalYears
    } = this.state;
    this.initialState = this.state;
    const data = {
      rentWarehouseFrom: rentWarehouseFrom || null,
      rentWarehouseTo: rentWarehouseTo || null,
      rentOfficeFrom: rentOfficeFrom || null,
      rentOfficeTo: rentOfficeTo || null,
      serviceWarehouseFrom: serviceWarehouseFrom || null,
      serviceWarehouseTo: serviceWarehouseTo || null,
      serviceOfficeFrom: serviceOfficeFrom || null,
      serviceOfficeTo: serviceOfficeTo || null,
      parkMinRentalYears: parkMinRentalYears || null
    };
    updateIndustrialPark(id, data, "leaseTerms");
  };

  render() {
    const {parkUpdateLoading} = this.props;
    const {
      rentWarehouseFrom,
      rentWarehouseTo,
      rentOfficeFrom,
      rentOfficeTo,
      serviceWarehouseFrom,
      serviceWarehouseTo,
      serviceOfficeFrom,
      serviceOfficeTo,
      parkMinRentalYears
    } = this.state;

    return(
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <InputAdornments
              label={<FormattedMessage id="INDUSTRIAL.PHRASE.WAREHOUSE_RENT_FROM"/>}
              name="rentWarehouseFrom"
              value={rentWarehouseFrom}
              onChange={this.handleChange}
              disabled={parkUpdateLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <InputAdornments
              label={<FormattedMessage id="INDUSTRIAL.PHRASE.WAREHOUSE_RENT_TO"/>}
              name="rentWarehouseTo"
              value={rentWarehouseTo}
              onChange={this.handleChange}
              disabled={parkUpdateLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <InputAdornments
              label={<FormattedMessage id="INDUSTRIAL.PHRASE.OFFICE_RENT_FROM"/>}
              name="rentOfficeFrom"
              value={rentOfficeFrom}
              onChange={this.handleChange}
              disabled={parkUpdateLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <InputAdornments
              label={<FormattedMessage id="INDUSTRIAL.PHRASE.OFFICE_RENT_TO"/>}
              name="rentOfficeTo"
              value={rentOfficeTo}
              onChange={this.handleChange}
              disabled={parkUpdateLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <InputAdornments
              label={<FormattedMessage id="INDUSTRIAL.PHRASE.SERVICE_WAREHOUSE_FROM"/>}
              name="serviceWarehouseFrom"
              value={serviceWarehouseFrom}
              onChange={this.handleChange}
              disabled={parkUpdateLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <InputAdornments
              label={<FormattedMessage id="INDUSTRIAL.PHRASE.SERVICE_WAREHOUSE_TO"/>}
              name="serviceWarehouseTo"
              value={serviceWarehouseTo}
              onChange={this.handleChange}
              disabled={parkUpdateLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <InputAdornments
              label={<FormattedMessage id="INDUSTRIAL.PHRASE.SERVICE_OFFICE_FROM"/>}
              name="serviceOfficeFrom"
              value={serviceOfficeFrom}
              onChange={this.handleChange}
              disabled={parkUpdateLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <InputAdornments
              label={<FormattedMessage id="INDUSTRIAL.PHRASE.SERVICE_OFFICE_TO"/>}
              name="serviceOfficeTo"
              value={serviceOfficeTo}
              onChange={this.handleChange}
              disabled={parkUpdateLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <InputAdornments
              label={<FormattedMessage id="INDUSTRIAL.PHRASE.MIN_RENTAL_YEARS"/>}
              name="minRentalYears"
              value={parkMinRentalYears}
              onChange={this.handleChange}
              disabled={parkUpdateLoading}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = store => ({
  parkUpdateLoading: store.industrial.parkUpdateLoading
});
const mapDispatchToProps = {
  updateIndustrialPark: (parkID, data, backgroundRefresh) => updateIndustrialPark(parkID, data, backgroundRefresh)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditParkLeaseTerms)
);
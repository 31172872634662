import React from "react";
import styles from "app/pages/common/external/components/index.module.scss";
import {FormattedMessage} from "react-intl";
import SliderCustom from "app/partials/components/SliderCustom";
import ModalCustom from "app/partials/components/ModalCustom";

export default class PropertyParticularsSingleNavigation extends React.Component {
    render() {
        const {onScrollToAnchor, data, photos, planImage, intl, reference} = this.props;
        let images = [];
        planImage && images.push(planImage);
        photos && photos.length && photos.map((photo) => images.push(photo));
        return(
            <div ref={reference} className={styles.contentNavigation}>
                <button className={styles.contentNavButton} onClick={() => onScrollToAnchor("propertyDetails")}><FormattedMessage id="PROPERTY_PARTICULARS.TAB.DETAILS"/></button>
                {data.units && data.units.length ? <button className={styles.contentNavButton} onClick={() => onScrollToAnchor("propertyUnits")}><FormattedMessage id="PROPERTY_PARTICULARS.TAB.AVAILABLE_UNITS"/></button> : null}
                {data.amenities && data.amenities.length ? <button className={styles.contentNavButton} onClick={() => onScrollToAnchor("propertyAmenities")}><FormattedMessage id="PROPERTY_PARTICULARS.TAB.AMENITIES"/></button> : null}
                <button className={styles.contentNavButton} onClick={() => onScrollToAnchor("propertyLocation")}><FormattedMessage id="PROPERTY_PARTICULARS.TAB.LOCATION"/></button>
                {data.publicTransport && data.publicTransport.length ? <button className={styles.contentNavButton} onClick={() => onScrollToAnchor("propertyTransport")}><FormattedMessage id="PROPERTY_PARTICULARS.TAB.PUBLIC_TRANSPORT"/></button> : null}
                {images.length ? (
                <ModalCustom
                  btn={
                    <button className={styles.contentNavButton}><FormattedMessage id="PROPERTY_PARTICULARS.TAB.GALLERY"/></button>
                  }
                  title=""
                  btnReject={<FormattedMessage id="GENERAL.PHRASE.CLOSE"/>}
                  btnFlex
                  fullScreen
                  inactive={!images || (images && images.length === 0)}
                >
                  <SliderCustom images={images}/>
                </ModalCustom>
                ) : null}
                {(
                    (intl.locale === "pl" && (data?.description || data?.descriptionLocation)) ||
                    (intl.locale === "en" && (data?.descriptionEn || data?.descriptionLocationEn))
                ) && <button className={styles.contentNavButton} onClick={() => onScrollToAnchor("propertyDescription")}><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.DESCRIPTION"/></button>}
                {data?.owners && data?.owners.length > 0 && <button className={styles.contentNavButton} onClick={() => onScrollToAnchor("propertyOwner")}><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.OWNER"/></button>}
            </div>
        );
    }
}
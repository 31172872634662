import React from "react";
import {Grid, Typography} from "@material-ui/core";
import {toAbsoluteUrl} from "_metronic";
import {FormattedMessage} from "react-intl";

// Render Mobile placeholder if window width is less than 1205px
export default class MobilePage extends React.Component {
  render() {
    return(
      <Grid container direction="column" justifyContent="center" alignItems="center" style={{height: "100%", backgroundColor: "#2d2d2d"}}>
        <Grid item>
          <img src={toAbsoluteUrl("media/branding/redd-logo-neon-200px.png")} style={{maxWidth: 200, marginBottom: 50}} alt="REDD"/>
        </Grid>
        <Grid item>
          <Typography variant="h4" style={{textAlign: "center", padding: "0px 25px", color: "#ffffff"}}>
            <FormattedMessage id="AUTH.GENERAL.MOBILE_DEVICE_MESSAGE" values={{breakingLine: <><br/><br/></>}}/>
          </Typography>
        </Grid>
        <Grid item>
          <img src={toAbsoluteUrl("media/branding/redd-loader.gif")} alt="REDD" style={{width: 100, marginTop: 60}} />
        </Grid>
      </Grid>
    );
  }
}
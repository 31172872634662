import React from "react";
import {TABLE_CELLS} from "app/partials/components/TableProperties";
import {FormattedMessage} from "react-intl";
import {withRouter} from "react-router-dom";
import ListView from "app/pages/offices/components/ListView/ListView";
import IsPro from "../../../utils/IsPro";
import IsAdmin from "../../../utils/IsAdmin";

class OffersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      initLoaded: false
    };
  }

  // Handle fetch data on enter viewport
  handleOnViewportFetch = (isVisible) => {
    const {fetchOffersOffices, loading, callReddClickEvent} = this.props;
    const {initLoaded} = this.state;
    const params = {
      page: 1
    };
    if(isVisible && !loading && !initLoaded) {
      this.setState({
        initLoaded: true
      }, () => {
        callReddClickEvent(
          "offers-page-visit",
          "offer"
        );
        fetchOffersOffices({params});
      });
    }
  };
  handleOnViewport = (isInViewport) => {
    this.handleOnViewportFetch(isInViewport);
  }

  render() {
    const {shortlists, items, loading, user} = this.props;
    const isTeamOwner = user?.isTeamOwner;
    const ownerColumnAllowed = isTeamOwner || IsAdmin();

    return (
      <IsPro paywall>
        <ListView
          tableLabel={<FormattedMessage id="MENU.MY_OFFERS"/>}
          tableHeadRows={
            [
              { id: 'offer_name', numeric: false, disablePadding: false, label: 'SHORTLIST.PHRASE.NAME', sort: false },
              ...ownerColumnAllowed ? [{ id: 'offer_owner', numeric: false, disablePadding: false, label: 'VOCABULARY.Owner', sort: false }] : [],
              { id: 'shortlist_create_date', numeric: false, disablePadding: false, label: 'VOCABULARY.Date', sort: false },
              { id: 'offer_buildings', numeric: true, disablePadding: false, label: 'VOCABULARY.Buildings', sort: false },
              { id: 'offer_units', numeric: true, disablePadding: false, label: 'VOCABULARY.Units', sort: false },
              { id: 'actions', numeric: true, disablePadding: false, label: '', sort: false }
            ]
          }
          tableBodyRows={items}
          tableDataSource="office"
          tableDataTarget={TABLE_CELLS.SHORTLISTS}
          itemsPerPage={shortlists.itemsPerPage}
          itemsCount={shortlists.itemsCount}
          totalItems={shortlists.totalItems}
          handleFetchData={this.props.fetchOffersOffices}
          loading={loading}
          onEnterViewport={this.handleOnViewport}
        />
      </IsPro>
    );
  }
}

export default withRouter(OffersPage);
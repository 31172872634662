import React from "react";
import styles from "../../../../../common/external/components/index.module.scss";
import clsx from "clsx";
import Slider from "react-slick/lib";

export default class PropertyParticularsSingleHeader extends React.Component {
    render() {
        const {photos} = this.props;
        const isMobile = window.outerWidth <= 760;
        const slickSettings = {
          dots: false,
          infinite: true,
          speed: 500,
          slideToShow: 1,
          slidesToScroll: 1,
        };

        if(photos.length) {
          if(isMobile) {
            return(
              <div className={styles.propertySlider}>
                  <Slider {...slickSettings}>
                      {photos.map((photo, index) => <img key={index} src={photo.imageHeader} alt="REDD | Property Particular Online"/>)}
                  </Slider>
              </div>
            );
          }
          return(
            <div className={styles.galleryWrapper}>
              {photos.length === 1 && (
              <div className={styles.singleImage}>
                  <div style={{"--bg-image": `url(${photos[0].image})`}}/>
              </div>
              )}
              {photos.length === 2 && photos.map((photo, index) =>
              <div key={index} className={styles.featureImage}>
                  <div style={{"--bg-image": `url(${photo.image})`}}/>
              </div>
              )}
              {photos.length === 3 && photos.map((photo, index) =>
              <div key={index} className={styles.threeImage}>
                  <div style={{"--bg-image": `url(${photo.image})`}}/>
              </div>
              )}
              {photos.length === 4 && (
              <div className={clsx(styles.gridImages, styles.gridFull)}>
                  {photos.map((photo, index) =>
                  <div key={index} className={styles.fourImage}>
                      <div style={{"--bg-image": `url(${photo.imageHeader})`}}/>
                  </div>
                  )}
              </div>
              )}
              {photos.length > 4 && (
              <>
              <div className={styles.featureImage}>
                  <div style={{"--bg-image": `url(${photos[0].imageHeader})`}}/>
              </div>
              <div className={styles.gridImages}>
                  <div style={{"--bg-image": `url(${photos[1].imageHeader})`}}/>
                  <div style={{"--bg-image": `url(${photos[2].imageHeader})`}}/>
                  <div style={{"--bg-image": `url(${photos[3].imageHeader})`}}/>
                  <div style={{"--bg-image": `url(${photos[4].imageHeader})`}}/>
              </div>
              </>
              )}
            </div>
          );
        }
        return false;
    }
}
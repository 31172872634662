import React from "react";
import {TextField} from "@material-ui/core";
import styles from "./index.module.scss";
import IconColorMUI from "../IconColorMUI";
import TooltipCustom from "../TooltipCustom";
import LoadingScreen from "../LoadingScreen";
import cn from "classnames";

export default function InputWithActions(
  {
    name,
    label,
    value,
    btnConfirmLabel,
    btnRejectLabel,
    btnConfirmTrigger,
    btnRejectTrigger,
    multiline,
    limit,
    style,
    loading,
    onChange,
    onConfirm,
    onReject,
    enterSubmitEnabled
  }
) {
  const isTextarea = multiline > 0;
  // Handlers
  const handleChange = (e) => {
    const value = e.target.value;
    const limitReached = value?.length > limit;
    if(!limitReached) {
      onChange && onChange(e);
    }
  }
  const handleConfirm = e => {
    if(e?.keyCode === 13 && onConfirm && enterSubmitEnabled) {
      onConfirm();
    }
  }
  // Helpers
  const getConfirmButton = () => {
    if(btnConfirmTrigger) {
      return btnConfirmTrigger;
    }
    return(
      <TooltipCustom
        title={btnConfirmLabel}
        disabled={!btnConfirmLabel}
        childStyle={{cursor: "pointer"}}
      >
        <IconColorMUI
          icon="check"
          color="success"
          size="14px"
        />
      </TooltipCustom>
    );
  }
  const getRejectButton = () => {
    if(btnRejectTrigger) {
      return btnRejectTrigger;
    }
    return(
      <TooltipCustom
        title={btnRejectLabel}
        disabled={!btnRejectLabel}
        childStyle={{cursor: "pointer"}}
      >
        <IconColorMUI
          icon="close"
          color="geay"
          size="14px"
        />
      </TooltipCustom>
    );
  }

  return(
    <div className={styles.inputWithActionsWrapper}>
      <TextField
        name={name}
        label={label}
        value={value}
        fullWidth
        multiline={isTextarea}
        minRows={multiline}
        style={style}
        variant={"outlined"}
        onChange={handleChange}
        onKeyDown={handleConfirm}
      />
      <div className={cn(
        styles.actionsWrapper,
        isTextarea ? styles.textareaPosition : undefined
      )}>
        {limit && !isNaN(limit) &&
          <span style={{paddingRight: "5px"}}>{value?.length || 0}/<strong>{limit}</strong></span>
        }
        {onConfirm && !loading &&
          <button onClick={onConfirm}>{getConfirmButton()}</button>
        }
        {onReject && !loading &&
          <button onClick={onReject}>{getRejectButton()}</button>
        }
        {loading && <LoadingScreen loaderStyle={{width: "1.5rem", height: "1.5rem"}}/>}
      </div>
    </div>
  );
}
import React, {useEffect} from "react";
import EditUnitBasics from "./EditUnitBasics";
import EditUnitLeadsManagement from "./EditUnitLeadsManagement";
import EditUnitFlexible from "./EditUnitFlexible";
import EditUnitNote from "./EditUnitNote";
import EditUnitPlan from "./EditUnitPlan";
import EditUnitSubleaseTerms from "./EditUnitSubleaseTerms";
import EditUnitContact from "./EditUnitContact";
import EditUnitDeleteUnit from "./EditUnitDeleteUnit";
import {fetchUnitEditFormsData} from "../../../../../crud/estates/offices/units.crud";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import LoadingScreen from "../../../LoadingScreen";
import NoData from "../../../NoData/NoData";
import IsAdmin from "../../../../../utils/IsAdmin";

function EditUnit({data, loading, parentData, activeTab, isEditable, requestSave, onRequestSaving, onUnsavedData, ...props}) {
  useEffect(() => {
    if(!loading && parentData?.id) {
      props.fetchUnitEditFormsData(parentData?.id);
    }
  // eslint-disable-next-line
  }, []);

  if(loading) {
    return <LoadingScreen/>;
  }
  else if(data) {
    return(
      <>
        {activeTab === "basic" &&
        <EditUnitBasics
          unitId={data?.id}
          data={data}
          requestSave={requestSave}
          onRequestSaving={onRequestSaving}
          onUnsavedData={onUnsavedData}
          {...props}
        />
        }
        {activeTab === "flexibleUnits" &&
        <EditUnitFlexible
          unitId={data?.id}
          data={data}
          isOfficeEditable={isEditable}
          requestSave={requestSave}
          onRequestSaving={onRequestSaving}
          onUnsavedData={onUnsavedData}
          {...props}
        />
        }
        {activeTab === "note" &&
        <EditUnitNote
          unitId={data?.id}
          note={data?.note}
          isOfficeEditable={isEditable}
          requestSave={requestSave}
          onRequestSaving={onRequestSaving}
          onUnsavedData={onUnsavedData}
          {...props}
        />
        }
        {activeTab === "plan" &&
        <EditUnitPlan
          unitId={data?.id}
          planImage={data?.planImage}
          isOfficeEditable={isEditable}
          requestSave={requestSave}
          onRequestSaving={onRequestSaving}
          onUnsavedData={onUnsavedData}
          {...props}
        />
        }
        {activeTab === "subleaseTerms" &&
        <EditUnitSubleaseTerms
          data={data}
          requestSave={requestSave}
          onRequestSaving={onRequestSaving}
          onUnsavedData={onUnsavedData}
          {...props}
        />
        }
        {activeTab === "contact" &&
        <EditUnitContact
          unitId={data?.id}
          data={data}
          requestSave={requestSave}
          onRequestSaving={onRequestSaving}
          onUnsavedData={onUnsavedData}
          {...props}
        />
        }
        {activeTab === "leadsManagement" &&
        <IsAdmin markup="editTitle">
          <EditUnitLeadsManagement
            requestSave={requestSave}
            onRequestSaving={onRequestSaving}
            onUnsavedData={onUnsavedData}
            {...props}
          />
        </IsAdmin>
        }
        {activeTab === "deleteUnit" &&
        <IsAdmin markup="editTitle">
          <EditUnitDeleteUnit
            unitId={data?.id}
            requestSave={requestSave}
            onRequestSaving={onRequestSaving}
            onUnsavedData={onUnsavedData}
            {...props}
          />
        </IsAdmin>
        }
      </>
    );
  }
  return <NoData/>;
}

const mapStateToProps = (store) => ({
  data: store.units.editFormsData,
  loading: store.units.editFormsDataLoading
});
const mapDispatchToProps = {
  fetchUnitEditFormsData: (unitId) => fetchUnitEditFormsData(unitId)
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditUnit)
);

import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {
  fetchMarketplaceLeadsCompanies,
  fetchMarketplaceLeadsCsv,
  fetchMarketplaceLeadsCompaniesXls,
  sendMarketplaceLeadUnlockNotification,
  sendMarketplaceLeadUnlockVisitorsNotification
} from "app/crud/finneLeads.crud";
import LeadsPotentialTenantsPage from "./LeadsPotentialTenantsPage";
import {callReddClickEvent} from "app/crud/user.crud";

const mapStateToProps = (store) => ({
  team: store.user.data.team,
  companiesItems: store.finneLeads.companiesItems,
  companiesItemsCount: store.finneLeads.companiesItemsCount,
  companiesItemsPerPage: store.finneLeads.companiesItemsPerPage,
  companiesLoading: store.finneLeads.companiesLoading,
  potentialTenantsXlsLoading: store.finneLeads.potentialTenantsXlsLoading,
  currentApplicationView: store.user.data.currentApplicationView,
  user: store.user.data
});
const mapDispatchToProps = {
  fetchMarketplaceLeadsCompanies: (target, {params}) => fetchMarketplaceLeadsCompanies(target, params),
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params),
  fetchMarketplaceLeadsCsv: (target, team) => fetchMarketplaceLeadsCsv(target, team),
  fetchMarketplaceLeadsCompaniesXls: (target, team) => fetchMarketplaceLeadsCompaniesXls(target, team),
  sendMarketplaceLeadUnlockNotification: (leadID) => sendMarketplaceLeadUnlockNotification(leadID),
  sendMarketplaceLeadUnlockVisitorsNotification: sendMarketplaceLeadUnlockVisitorsNotification
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LeadsPotentialTenantsPage)
);
import React from "react";
import {Typography} from "@material-ui/core";
import ModalCustom from "app/partials/components/ModalCustom";
import {fetchShortlistParks, fetchShortlistsIndustrial} from "app/crud/estates/industrial/offers-industrial.crud";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import axios from "axios";
import Icon from "../Icon";
import Button from "../Button";

class ShortlistRemoveParks extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
  }
  state = {
    open: false,
    selected: ""
  };
  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  removeParksFromShortlist = (parks, shortlistId) => {
    if (!shortlistId) return;

    axios
      .post(`/offers-industrial/${shortlistId}/remove_parks/`, {
        parks,
      })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          this.props.fetchShortlistParks(this.props.shortlistId);
          this.props.fetchShortlistsIndustrial({page: 1});
        }
      });
  };
  handleSave = () => {
    const {parks, shortlistId} = this.props;
    this.removeParksFromShortlist(parks, shortlistId);
    this.handleCloseModal();
  };

  render() {
    return(
      <ModalCustom
        ref={this.child}
        btn={
        <Button
          type={"icon"}
          tooltip={<FormattedMessage id="SHORTLIST.PHRASE.REMOVE_SHORTLIST_PARK"/>}
        >
          <Icon icon={"trash"}/>
        </Button>
        }
        title={<FormattedMessage id="SHORTLIST.PHRASE.REMOVE_SHORTLIST_PARK"/>}
        handleSave={this.handleSave}
        btnReject={<FormattedMessage id="GENERAL.PHRASE.CANCEL"/>}
        btnConfirm={<FormattedMessage id="GENERAL.PHRASE.REMOVE"/>}
      >
        <Typography><FormattedMessage id="SHORTLIST.PHRASE.REMOVE_SHORTLIST_PARK_MESSAGE"/> <br/><FormattedMessage id="GENERAL.PHRASE.ACTION_CONFIRMATION_MESSAGE"/></Typography>
      </ModalCustom>
    );
  }
}

const mapDispatchToProps = {
  fetchShortlistsIndustrial: (params) => fetchShortlistsIndustrial(params),
  fetchShortlistParks: (shortlistID) => fetchShortlistParks(shortlistID),
};

export default injectIntl(
  connect(
    null,
    mapDispatchToProps
  )(ShortlistRemoveParks)
);
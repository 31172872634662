import React from "react";
import ModalCustom from "app/partials/components/ModalCustom";
import {Menu, MenuItem, Typography} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import styles from "./index.module.scss";
import clsx from "clsx";
import {sendOfficeFinanceXLS} from "app/crud/estates/offices/offices.crud";
import {callReddClickEvent} from "../../../../crud/user.crud";
import Button from "../../../../partials/components/Button";

class OwnerFinancialGenerateXLS extends React.Component {
  handleOpen = (event) => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };
  handleClose = () => {
    this.setState({
      anchorEl: null
    })
  };
  handleSendXls = (pk) => {
    const {officeID, sendOfficeFinanceXLS} = this.props;
    sendOfficeFinanceXLS(officeID, {"pk": pk});
  };

  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      anchorEl: null
    }
  }

  render() {
    const {inactive, officeFinances, officeID} = this.props;
    const {anchorEl} = this.state;
    if (inactive) {
      return (
        <Button inactive>
          <FormattedMessage id="OWNERS.PHRASE.GENERATE_XLS"/>
        </Button>
      );
    }
    return (
      <div>
        <Button
          style={{marginLeft: 15}}
          onClick={this.handleOpen}
        >
          <FormattedMessage id="OWNERS.PHRASE.GENERATE_XLS"/>
        </Button>
        <Menu
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          id="generateXls"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          className={clsx(styles.customPopoverContainer, styles.xlsContainer)}
        >
          {officeFinances && officeFinances.map((item, index) => (
            <MenuItem key={index} onClick={this.handleClose}>
              <ModalCustom
                ref={this.child}
                btn={
                <Button
                  color={"warning"}
                  onClick={() => this.handleSendXls(item.pk)}
                >
                  {item?.year}
                </Button>
                }
                title={<FormattedMessage id="OWNERS.PHRASE.GENERATE_XLS"/>}
                btnReject={<FormattedMessage id="GENERAL.PHRASE.CLOSE"/>}
                maxWidth="lg"
                onOpen={() => this.props.dispatch(callReddClickEvent(
                  "owner-financial-statement-send-xls-click",
                  "office",
                  null,
                  officeID
                ))}
              >
                <Typography><FormattedMessage id="OWNERS.PHRASE.FINANCIAL_STATEMENT_XLS_HAS_BEEN_SENT"/></Typography>
              </ModalCustom>
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  user: store.user.data,
  officeFinances: store.offices.officeFinances,
  ownerPortfolio: store.owners.ownerPortfolio,
  ownerPortfolioLoading: store.owners.ownerPortfolioLoading
});
const mapDispatchToProps = dispatch => ({
  sendOfficeFinanceXLS: (officeID, pk) => dispatch(sendOfficeFinanceXLS(officeID, pk)),
  dispatch
});
export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OwnerFinancialGenerateXLS)
);
import {
  fetchMoreShortlistsIndustrialLandlord,
  fetchShortlistIndustrialUnitsLandlord,
  fetchShortlistIndustrialUnitsLandlordReset,
  fetchShortlistsIndustrialLandlord
} from "app/crud/estates/industrial/offers-industrial.crud";
import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import ShortlistsLandlordPageIndustrial from "./ShortlistsLandlordPageIndustrial";
import {filterReset} from "../../../crud/estates/filters.crud";

const mapStateToProps = (state) => ({
  shortlists: state.offersIndustrial.shortlists,
  landlordItems: state.offersIndustrial.landlordItems,
  landlordLoading: state.offersIndustrial.landlordLoading,
  landlordItemsCount: state.offersIndustrial.landlordItemsCount,
  landlordItemsPerPage: state.offersIndustrial.landlordItemsPerPage,
  landlordShortlistUnits: state.offersIndustrial.landlordShortlistUnits,
  landlordShortlistUnitsLoading: state.offersIndustrial.landlordShortlistUnitsLoading,
  loadingMore: state.offersIndustrial.loadingMore
});
const mapDispatchToProps = {
  fetchShortlistsIndustrialLandlord: ({params}) => fetchShortlistsIndustrialLandlord(params),
  fetchMoreShortlistsIndustrialLandlord: ({params}) => fetchMoreShortlistsIndustrialLandlord(params),
  fetchShortlistIndustrialUnitsLandlord: (params) => fetchShortlistIndustrialUnitsLandlord(params),
  fetchShortlistIndustrialUnitsLandlordReset: fetchShortlistIndustrialUnitsLandlordReset,
  filterReset: () => filterReset()
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ShortlistsLandlordPageIndustrial)
);

import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import OwnersPage from "./OwnersPage";
import {filterReset} from "app/crud/estates/filters.crud";
import {fetchOwnerPortfolio, fetchOwners} from "app/crud/owners.crud";
import {callReddClickEvent, fetchVisibleOwners} from "app/crud/user.crud";
import {setLatestActiveTablePage} from "app/crud/tablePage.crud";


const mapStateToProps = (state) => ({
  items: state.owners.items,
  itemsPerPage: state.owners.itemsPerPage,
  itemsCount: state.owners.itemsCount,
  loaded: state.owners.loaded,
  loading: state.owners.loading,
  filters: state.filters,
  allUserStates: state.user
});
const mapDispatchToProps = {
  fetchOwners: ({params}) => fetchOwners(params),
  fetchOwnerPortfolio: (ownerId) => fetchOwnerPortfolio(ownerId),
  fetchVisibleOwners: () => fetchVisibleOwners(),
  filterReset: (filtersToOmit, currentApplicationView, fullReset) => filterReset(filtersToOmit, currentApplicationView, fullReset),
  setLatestActiveTablePage: (page) => setLatestActiveTablePage(page),
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OwnersPage)
);

export default {
  TYPE_PDF: {
    id: 0,
    name: 'GENERAL.PHRASE.FILE_FORMAT.PDF',
    format: '.pdf',
    icon: 'media/branding/icons/files/pdf-brand.png'
  },
  TYPE_JPG: {
    id: 1,
    name: 'GENERAL.PHRASE.FILE_FORMAT.JPG',
    format: '.jpg,.jpeg',
    icon: 'media/branding/icons/files/jpg-brand.png'
  },
  TYPE_PNG:  {
    id: 2,
    name: 'GENERAL.PHRASE.FILE_FORMAT.PNG',
    format: '.png',
    icon: 'media/branding/icons/files/png-brand.png'
  },
  TYPE_DOCX:  {
    id: 3,
    name: 'GENERAL.PHRASE.FILE_FORMAT.DOCX',
    format: '.docx,.doc',
    icon: 'media/branding/icons/files/doc-brand.png'
  },
  TYPE_XLSX:  {
    id: 4,
    name: 'GENERAL.PHRASE.FILE_FORMAT.XLSX',
    format: '.xlsx,.xls',
    icon: 'media/branding/icons/files/xls-brand.png'
  },
  TYPE_CSV:  {
    id: 5,
    name: 'GENERAL.PHRASE.FILE_FORMAT.CSV',
    format: '.csv',
    icon: 'media/branding/icons/files/csv-brand.png'
  }
};
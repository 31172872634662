export default {
    CABIN: { family: 'Cabin', fonts: [
        { src: 'https://fonts.gstatic.com/s/cabin/v10/XeuAFYo2xAPHxZGBbQtHhA.ttf', fontWeight: 400 },
        { src: 'https://fonts.gstatic.com/s/cabin/v10/eUDAvKhBtmTCkeVBsFk34A.ttf', fontWeight: 600 }
    ]},
    CRIMSON_TEXT: { family: 'Crimson Text', fonts: [
        { src: 'https://fonts.gstatic.com/s/crimsontext/v6/3IFMwfRa07i-auYR-B-zNS3USBnSvpkopQaUR-2r7iU.ttf', fontWeight: 400 },
        { src: 'https://fonts.gstatic.com/s/crimsontext/v6/3IFMwfRa07i-auYR-B-zNS3USBnSvpkopQaUR-2r7iU.ttf', fontWeight: 600 }
    ]},
    DOSIS: { family: 'Dosis', fonts: [
        { src: 'https://fonts.gstatic.com/s/dosis/v6/awIB6L0h5mb0plIKorXmuA.ttf', fontWeight: 300 },
        { src: 'https://fonts.gstatic.com/s/dosis/v6/rJRlixu-w0JZ1MyhJpao_Q.ttf', fontWeight: 400 },
        { src: 'https://fonts.gstatic.com/s/dosis/v6/KNAswRNwm3tfONddYyidxg.ttf', fontWeight: 600 }
    ]},
    JURA: { family: 'Jura', fonts: [
        { src: 'https://fonts.gstatic.com/s/jura/v7/Rqx_xy1UnN0C7wD3FUSyPQ.ttf', fontWeight: 300 },
        { src: 'https://fonts.gstatic.com/s/jura/v7/YAWMwF3sN0KCbynMq-Yr_Q.ttf', fontWeight: 400 },
        { src: 'https://fonts.gstatic.com/s/jura/v7/iwseduOwJSdY8wQ1Y6CJdA.ttf', fontWeight: 600 }
    ]},
    LATO: { family: 'Lato', fonts: [
        { src: 'https://fonts.gstatic.com/s/lato/v11/Ja02qOppOVq9jeRjWekbHg.ttf', fontWeight: 300 },
        { src: 'https://fonts.gstatic.com/s/lato/v11/h7rISIcQapZBpei-sXwIwg.ttf', fontWeight: 400 },
        { src: 'https://fonts.gstatic.com/s/lato/v11/iX_QxBBZLhNj5JHlTzHQzg.ttf', fontWeight: 700 }
    ]},
    OPEN_SANS: { family: 'Open Sans', fonts: [
        { src: 'https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTS3USBnSvpkopQaUR-2r7iU.ttf', fontWeight: 300 },
        { src: 'https://fonts.gstatic.com/s/opensans/v13/IgZJs4-7SA1XX_edsoXWog.ttf', fontWeight: 400 },
        { src: 'https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSi3USBnSvpkopQaUR-2r7iU.ttf', fontWeight: 600 }
    ]},
    OSWALD: { family: 'Oswald', fonts: [
        { src: 'https://fonts.gstatic.com/s/oswald/v11/y3tZpCdiRD4oNRRYFcAR5Q.ttf', fontWeight: 300 },
        { src: 'https://fonts.gstatic.com/s/oswald/v11/uLEd2g2vJglLPfsBF91DCg.ttf', fontWeight: 400 },
        { src: 'https://fonts.gstatic.com/s/oswald/v11/7wj8ldV_5Ti37rHa0m1DDw.ttf', fontWeight: 600 }
    ]},
    POPPINS: { family: 'Poppins', fonts: [
        { src: 'https://fonts.gstatic.com/s/poppins/v12/pxiByp8kv8JHgFVrLDz8Z1JlEA.ttf', fontWeight: 300 },
        { src: 'https://fonts.gstatic.com/s/poppins/v12/pxiEyp8kv8JHgFVrJJnedw.ttf', fontWeight: 400 },
        { src: 'https://fonts.gstatic.com/s/poppins/v12/pxiByp8kv8JHgFVrLEj6Z1JlEA.ttf', fontWeight: 600 }
    ]},
    ROBOTO: { family: 'Roboto', fonts: [
        { src: 'https://fonts.gstatic.com/s/roboto/v15/dtpHsbgPEm2lVWciJZ0P-A.ttf', fontWeight: 300 },
        { src: 'https://fonts.gstatic.com/s/roboto/v15/W5F8_SL0XFawnjxHGsZjJA.ttf', fontWeight: 400 },
        { src: 'https://fonts.gstatic.com/s/roboto/v15/Uxzkqj-MIMWle-XP2pDNAA.ttf', fontWeight: 600 }
    ]}
};
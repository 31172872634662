import React from "react";
import {Grid, Typography} from "@material-ui/core";
import {refreshOffice} from "app/crud/estates/offices/offices.crud";
import {refreshPark, refreshWarehouse} from "app/crud/estates/industrial/industrial.crud";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import Button from "../../../Button";

class EditRefreshProperty extends React.Component {
  // Handlers
  handleRefresh = () => {
    const {refreshOffice, refreshPark, refreshWarehouse, propertyID, view} = this.props;
    const isViewOffice = view === 'office';
    const isViewIndustrialPark = view === 'industrialPark';
    const isViewIndustrialWarehouse = view === 'industrialWarehouse';
    
    if(propertyID) {
      if(isViewOffice) {
        refreshOffice(propertyID);
      }
      else if(isViewIndustrialPark) {
        refreshPark(propertyID);
      }
      else if(isViewIndustrialWarehouse) {
        refreshWarehouse(propertyID);
      }
    }
  };

  render() {
    const {refreshingOffice, refreshingPark, refreshingWarehouse} = this.props;
    const refreshing = refreshingOffice || refreshingPark || refreshingWarehouse;
    
    return(
      <Grid container spacing={2} justifyContent="center" alignItems="center" direction="column" style={{height: "100%"}}>
        <Grid item>
          <Typography variant="h3" style={{textAlign: "center"}}>
            <FormattedMessage id="VOCABULARY.Refresh the update date without changing the data."/>
          </Typography>
        </Grid>
        <Grid item>
          <Button
            loading={refreshing}
            onClick={() => this.handleRefresh()}
          >
            <FormattedMessage id="VOCABULARY.Refresh"/>
          </Button>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = store => ({
  refreshingOffice: store.offices.refreshingOffice,
  refreshingPark: store.industrial.refreshingPark,
  refreshingWarehouse: store.industrial.refreshingWarehouse
});
const mapDispatchToProps = {
  refreshOffice: officeID => refreshOffice(officeID),
  refreshPark: parkID => refreshPark(parkID),
  refreshWarehouse: warehouseID => refreshWarehouse(warehouseID)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditRefreshProperty)
);
import React from "react";
import clsx from "clsx";
import styles from "app/partials/components/PartialComponents.module.scss";
import ModalCustom from "app/partials/components/ModalCustom";
import {addUnitToExistingShortlist, addUnitToNewShortlist, fetchOffersAll} from "app/crud/offers.crud";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import InputIcon from "app/partials/components/InputIcon";
import LoadingScreen from "../LoadingScreen";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import {callReddClickEvent} from "app/crud/user.crud";
import IsPro from "../../../utils/IsPro";
import PaywallModal from "../PayWall/components/PaywallModal";
import SelectSearch from "../SelectSearch";
import IsAdmin from "../../../utils/IsAdmin";
import Button from "../Button";
import Icon from "app/partials/components/Icon";

class UnitAddToOffer extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      open: false,
      shortlistName: "",
      selected: "",
      tab: 0,
      forTesting: true
    };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !prevProps.offerUpdated &&
      this.props.offerUpdated
    ) {
      this.props.onComplete && this.props.onComplete([]);
      this.handleCloseModal();
    }
  }

  // Handlers
  handleFetchOffers = () => {
    const {fetchOffersAll, callReddClickEvent, propertyID, source} = this.props;
    fetchOffersAll && fetchOffersAll();

    if(callReddClickEvent) {
      callReddClickEvent(
        "offers-v2-add-to-offer-open",
        "office",
        null,
        propertyID ? propertyID : null,
        {source}
      );
    }
  }
  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleSelect = (target) => {
    this.setState({
      selected: target.value,
    });
  };
  handleCheckboxChange = e => {
    this.setState({
      [e.target.id]: !this.state[e.target.id]
    });
  };
  handleChange = (event) => {
    this.setState({
      shortlistName: event.target.value,
    });
  };
  handleChangeTab = newTab => {
    this.setState({
      tab: newTab
    });
  }
  handleSave = () => {
    const { unitId, addUnitToNewShortlist, addUnitToExistingShortlist, propertyID, callReddClickEvent, source } = this.props;
    const { shortlistName, selected, tab, forTesting } = this.state;
    const units = this.props.units || [unitId];
    const isAdmin = IsAdmin({loggedInAs: true});
    const isForTesting = isAdmin ? forTesting : false;

    // Add to New Offer
    if(tab === 0) {
      if (shortlistName !== "") {
        addUnitToNewShortlist(shortlistName, units, isForTesting);
      }
    }
    // Add to Existing Offer
    else if(tab === 1) {
      if (selected !== "" && selected !== "empty") {
        addUnitToExistingShortlist(selected, units);
      }
    }
    //
    if(callReddClickEvent) {
      callReddClickEvent(
        "offers-v2-add-to-offer-click",
        "office",
        null,
        propertyID ? propertyID : null,
        {
          source,
          units,
          offerType: tab === 0 ? "new" : "existing",
        }
      );
    }
  };

  render() {
    const { inactive, loadingOffersAll, offersAll, user, isDummyViewActive, offerUpdating, triggerColor = "simple" } = this.props;
    const { shortlistName, tab, selected, forTesting } = this.state;
    const isLoggedInAs = user?.loggedInAs;

    return (
      <IsPro
        paywall
        paywallComponent={
        <PaywallModal
          triggerBtn={
            <Button
              color={triggerColor}
              inactive={inactive}
            >
              <FormattedMessage id="VOCABULARY.Add to offer" />
            </Button>
          }
          triggerButtonVariant={this.props.intl.formatMessage({id: "VOCABULARY.Add to offer"})}
          isDummyViewActive={isDummyViewActive}
        />
        }
        reverseCheck={isDummyViewActive}
        overwriteRule={isDummyViewActive}
      >
        <ModalCustom
          ref={this.child}
          btn={
          <Button
            color={triggerColor}
            inactive={inactive}
          >
            <FormattedMessage id="VOCABULARY.Add to offer" />
          </Button>
          }
          btnReject={<FormattedMessage id="GENERAL.PHRASE.CANCEL" />}
          btnConfirm={<FormattedMessage id="GENERAL.PHRASE.ADD" />}
          title={<FormattedMessage id="VOCABULARY.Add to offer" />}
          handleSave={this.handleSave}
          inactive={inactive}
          saving={offerUpdating}
          onOpen={() => this.handleFetchOffers()}
        >
          <div className={styles.tabsWrapper}>
            <button
              className={clsx(
                styles.btnTab,
                tab === 0 ? styles.active : undefined
              )}
              onClick={() => this.handleChangeTab(0)}
            >
              <FormattedMessage id="VOCABULARY.Add new offer"/>
            </button>
            <button
              className={clsx(
                styles.btnTab,
                tab === 1 ? styles.active : undefined
              )}
              onClick={() => this.handleChangeTab(1)}
            >
              <FormattedMessage id="VOCABULARY.Add to existing offer"/>
            </button>
          </div>
          <div className={styles.tabsContentWrapper}>
            {tab === 0 &&
              <div>
                <InputIcon
                  icon={<Icon icon={"edit"} size={"1.8rem"}/>}
                  id="new_shortlist_name"
                  label={<FormattedMessage id="SHORTLIST.PHRASE.NEW_SHORTLIST_NAME" />}
                  name="new_shortlist_name"
                  value={shortlistName}
                  width="100%"
                  onChange={this.handleChange}
                />
                {isLoggedInAs &&
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={forTesting}
                      id="forTesting"
                      value="forTesting"
                      color="primary"
                      onChange={this.handleCheckboxChange}
                    />
                  }
                  label={<FormattedMessage id="SHORTLIST.PHRASE.FOR_TESTING"/>}
                  style={{marginTop: "0.5rem"}}
                />
                }
              </div>
            }
            {tab === 1 && (
              loadingOffersAll ? (
                <LoadingScreen />
              ) : (
                <SelectSearch
                  name="selected"
                  value={selected}
                  label={<FormattedMessage id="SHORTLIST.PHRASE.SELECT_SHORTLIST" />}
                  options={offersAll}
                  onChange={this.handleSelect}
                  optionLabel="name"
                  optionValue="id"
                  marginOff
                />
              )
            )}
          </div>
        </ModalCustom>
      </IsPro>
    );
  }
}

const mapStateToProps = (state) => ({
  offerUpdated: state?.shortlists?.offerUpdated,
  offerUpdating: state?.shortlists?.offerUpdating,
  shortlists: state.shortlists.items,
  offersAll: state.shortlists.offersAll,
  loadingOffersAll: state.shortlists.loadingOffersAll,
  user: state.user.data
});
const mapDispatchToProps = {
  fetchOffersAll: () => fetchOffersAll(),
  addUnitToNewShortlist: (name, units, forTesting) => addUnitToNewShortlist(name, units, forTesting),
  addUnitToExistingShortlist: (id, units) => addUnitToExistingShortlist(id, units),
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params)
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(UnitAddToOffer)
);

/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import clsx from "clsx";
import styles from "../index.module.scss";
import {Portlet, PortletBody, PortletHeader,} from "app/partials/content/Portlet";
import {FormattedMessage, injectIntl} from "react-intl";
import {toDateFormat, toNumberFormat} from "app/utils/helpers";
import {Typography} from "@material-ui/core";
import NoData from "app/partials/components/NoData/NoData";
import Thumbnail from "app/partials/components/Thumbnail";
import {toAbsoluteUrl} from "_metronic";
import {Link as RouterLink, withRouter} from "react-router-dom";
import {ROUTES} from "app/constants";
import LoadingScreen from "../../LoadingScreen";
import {updateUserMarket} from "../../../../crud/user.crud";
import connect from "react-redux/es/connect/connect";
import ConfirmationDialog from "../../ConfirmationDialog";
import APPLICATION_VIEWS from "../../../../constants/APPLICATION_VIEWS";

class TrackingDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmRedirectOpen: false,
      redirectPropertyID: null,
      redirectMarketID: null,
    };
  }

  handleConfirmOpen = (propertyID, marketID) => {
    this.setState({
      confirmRedirectOpen: true,
      redirectPropertyID: propertyID,
      redirectMarketID: marketID,
    });
  };
  handleConfirmClose = () => {
    this.setState({
      confirmRedirectOpen: false,
      redirectPropertyID: null,
      redirectMarketID: null,
    });
  };
  handleProceed = (propertyID, marketID) => {
    const { updateUserMarket, currentApplicationView } = this.props;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    const isIndustrialView = currentApplicationView === APPLICATION_VIEWS.INDUSTRIAL;
    const baseRoute = isOfficeView ? ROUTES.BUILDING_PAGE : isIndustrialView ? ROUTES.INDUSTRIAL_BUILDING : null;

    if(baseRoute) {
      updateUserMarket(marketID, `${baseRoute}/${propertyID}`);
    }
    this.handleConfirmClose();
  };
  handleReturnTitle = (propertyID, marketID, propertyName) => {
    const { userMarket, currentApplicationView } = this.props;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    const isIndustrialView = currentApplicationView === APPLICATION_VIEWS.INDUSTRIAL;
    const baseRoute = isOfficeView ? ROUTES.BUILDING_PAGE : isIndustrialView ? ROUTES.INDUSTRIAL_BUILDING : null;
    const diffMarket = marketID !== userMarket;

    if (diffMarket && propertyID && marketID) {
      return (
        <span
          onClick={() => this.handleConfirmOpen(propertyID, marketID)}
          style={{ cursor: "pointer" }}
        >
          {propertyName}
        </span>
      );
    }
    else if (propertyID && baseRoute) {
      return (
        <RouterLink
          to={`${baseRoute}/${propertyID}`}
        >
          {propertyName}
        </RouterLink>
      );
    }
    return propertyName;
  };

  render() {
    const { label, data, expanded, target, currentApplicationView } = this.props;
    const { redirectPropertyID, redirectMarketID } = this.state;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    const isIndustrialView = currentApplicationView === APPLICATION_VIEWS.INDUSTRIAL;
    const imagePlaceholder = isOfficeView ? toAbsoluteUrl("media/office-placeholder.jpg") :
            isIndustrialView ? toAbsoluteUrl("media/industrial-placeholder-outside-thumbnail.jpg") : null;

    return (
      <>
        <Portlet>
          <PortletHeader title={label} />
          <PortletBody>
            {!data ? (
              <LoadingScreen />
            ) : data && data.length ? (
              <div
                className={clsx(
                  "kt-timeline-v2 ps--active-y",
                  styles.scrollContaienr,
                  expanded && styles.expanded
                )}
              >
                <div className="kt-timeline-v2__items thumbBefore kt-padding-top-25 kt-padding-bottom-30">
                  {data.map((record, index) => {
                    const isUnitCreated =
                      record.action === "unit created" ||
                      record.action === "Unit added";
                    const isUnitRented =
                      record.action === "unit rented" ||
                      record.action === "Unit rented";
                    const thumb = record.officeThumbnail
                      ? record.officeThumbnail
                      : imagePlaceholder;
                    const propertyID = isOfficeView ? record?.officeId : isIndustrialView ? record?.warehouseId : null;
                    const propertyName = isOfficeView ? record?.propertyName : isIndustrialView ? record?.warehouseName : null;

                    return (
                      <div key={index} className="kt-timeline-v2__item">
                        <span
                          className={clsx(
                            "kt-timeline-v2__item-time",
                            styles.timelineValue,
                            isUnitRented
                              ? "kt-font-success"
                              : isUnitCreated && "kt-font-brand"
                          )}
                          style={{ marginTop: 11 }}
                        >
                          {toNumberFormat(record.unitArea, "area")}
                        </span>
                        <div className={styles.thumbPoint}>
                          <Thumbnail src={thumb} size={40} />
                        </div>
                        <div className="kt-timeline-v2__item-text kt-padding-top-5">
                          {target === "dashboard" && (
                            <Typography
                              variant="body1"
                              style={{ fontSize: "1.3rem" }}
                            >
                              {this.handleReturnTitle(
                                propertyID,
                                record?.marketId,
                                propertyName
                              )}
                            </Typography>
                          )}
                          <FormattedMessage
                            id={
                              isUnitCreated
                                ? "BUILDING.PHRASE.UNIT_TRACKING_CREATED"
                                : isUnitRented
                                ? "BUILDING.PHRASE.UNIT_TRACKING_RENTED"
                                : "GENERAL.PHRASE.CLOSE"
                            }
                            values={{
                              date: (
                                <span className="kt-font-bolder">
                                  {toDateFormat(record.transactionDate)}
                                </span>
                              ),
                            }}
                          />
                        </div>
                        <div className="kt-list-pics kt-list-pics--sm kt-padding-l-20" />
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <NoData />
            )}
            <ConfirmationDialog
              open={this.state.confirmRedirectOpen}
              onClose={() => this.handleConfirmClose()}
              onConfirm={() =>
                this.handleProceed(redirectPropertyID, redirectMarketID)
              }
              message={
                <FormattedMessage id="ACQUISITIONS.PHRASE.DIFF_MARKET_MESSAGE" />
              }
            />
          </PortletBody>
        </Portlet>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userMarket: state.user.data.market,
  currentApplicationView: state.user.data.currentApplicationView
});
const mapDispatchToProps = (dispatch) => ({
  updateUserMarket: (market, redirect) =>
    dispatch(updateUserMarket(market, redirect)),
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(TrackingDashboard))
);

import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {put, takeLatest} from "redux-saga/effects";
import axios from "axios";
import {actionNotification} from "app/utils/notifications";
import {setFilters} from "../../utils/filters";

const initialState = {
  items: [],
  itemsPerPage: 20,
  itemsCount: null,
  loaded: false,
  loading: false,
  subscriber: null,
  watchlist: null,
  invitingSubscriber: false,
  invited: false
};

export const reducer = persistReducer(
  { storage, key: "subscribers" },
  (state = initialState, action) => {
    switch (action.type) {
      // RESET SUBSCRIBERS LOADING STATES
      case 'RESET_SUBSCRIBERS_LOADING_STATES_REQUEST': {
        return {
          ...state,
          loading: false,
          invitingSubscriber: false
        };
      }
      //
      case 'FETCH_SUBSCRIBERS_REQUEST': {
        return {
          ...state,
          items: [],
          itemsPerPage: 20,
          itemsCount: null,
          loading: true
        };
      }
      case 'FETCH_SUBSCRIBERS_SUCCESS': {
        const { items, itemsCount, itemsPerPage } = action.payload;
        return {
          ...state,
          items,
          itemsCount,
          itemsPerPage,
          loading: false
        }
      }
      case 'FETCH_SUBSCRIBERS_FAILED': {
        return {
          ...state,
          items: [],
          itemsPerPage: 20,
          itemsCount: null,
          loading: false
        };
      }
      case 'FETCH_SUBSCRIBER_REQUEST': {
        return { ...state };
      }
      case 'FETCH_SUBSCRIBER_SUCCESS': {
        return { ...state, subscriber: action.payload }
      }
      case 'FETCH_BUILDINGS_WATCHLIST_REQUEST': {
        return { ...state };
      }
      case 'FETCH_BUILDINGS_WATCHLIST_SUCCESS': {
        return { ...state, watchlist: action.payload }
      }
      case 'ADD_SUBSCRIBER_REQUEST': {
        return { ...state };
      }
      case 'ADD_SUBSCRIBER_SUCCESS': {
        return { ...state, ...action.payload }
      }
      case 'INVITE_SUBSCRIBER_REQUEST': {
        return { ...state, invitingSubscriber: true, invited: false }
      }
      case 'INVITE_SUBSCRIBER_SUCCESS': {
        return { ...state, invitingSubscriber: false, invited: true, items: [...action.payload.items] }
      }
      case 'INVITE_SUBSCRIBER_FAILED': {
        return { ...state, invitingSubscriber: false, invited: false }
      }
      case 'UPDATE_SUBSCRIBER_REQUEST': {
        return { ...state };
      }
      case 'UPDATE_SUBSCRIBER_SUCCESS': {
        return { ...state, ...action.payload }
      }
      case 'DELETE_SUBSCRIBER_REQUEST': {
        return { ...state };
      }
      case 'DELETE_SUBSCRIBER_SUCCESS': {
        return { ...state, items: state.items.filter(item => item.id !== action.payload) }
      }
      case 'DELETE_ALL_WATCHLIST_ITEMS_REQUEST': {
        return { ...state };
      }
      case 'DELETE_FROM_WATCHLIST_SUCCESS': {
        return { ...state, watchlist: state.watchlist ? [...state.watchlist.filter(item => item.id !== action.payload)] : null }
      }
      case 'DELETE_ALL_WATCHLIST_ITEMS_SUCCESS': {
        return { ...state, watchlist: [] }
      }

      default:
        return state;
    }
  }
);

// FETCH DATA
function* fetchSubscribers(action) {
  const params = action.payload.params;
  const filters = setFilters(params);
  try {
    const subscribers = yield axios.get(`/subscribers-v2/${filters}`);
    yield put({ type: "FETCH_SUBSCRIBERS_SUCCESS", payload: subscribers.data || [{ error: subscribers.statusText }] });
  }
  catch(err) {
    console.log(err);
    yield put({ type: "FETCH_SUBSCRIBERS_FAILED" });
  }
}
function* fetchSubscriber(action) {
  const {id} = action.payload;
  try {
    const subscriber = yield axios.get(`/subscribers-v2/${id}/`);
    yield put({ type: "FETCH_SUBSCRIBER_SUCCESS", payload: subscriber.data || [{ error: subscriber.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}

// USER ACTIONS
function* addSubscriber(action) {
  try {
    const {officeId, data} = action.payload;
    const addSubscriber = yield axios.post(`/offices-v2/${officeId}/subscribe_v2/`, data);
    yield put({
      type: "ADD_SUBSCRIBER_SUCCESS",
      payload: addSubscriber.data,
      meta: actionNotification('Building has been added to your Watchlist.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "ADD_SUBSCRIBER_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* addSubscribersXlsError() {
  yield put({
    type: "ADD_SUBSCRIBERS_XLS_FAILED",
    payload: null,
    meta: actionNotification('Invalid file format. Only XLS files with plain e-mails list are allowed.', 'error')
  });
}
function* inviteSubscriber(action) {
  try {
    const data = action.payload;
    const inviteSubscriber = yield axios.post(`/subscribers-v2/invite_bulk_to_office/`, data);
    yield put({
      type: "INVITE_SUBSCRIBER_SUCCESS",
      payload: inviteSubscriber.data,
      meta: actionNotification('Subscriber has been added.', 'success')
    });
  }
  catch(err) {
    console.log(err);
    yield put({
      type: "ADD_SUBSCRIBER_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* updateSubscriber(action) {
  try {
    const {id, data} = action.payload;
    const updateSubscriber = yield axios.patch(`/subscribers-v2/${id}/`, data);
    yield put({
      type: "UPDATE_SUBSCRIBER_SUCCESS",
      payload: updateSubscriber.data,
      meta: actionNotification('Selected subscriber has been updated.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "UPDATE_SUBSCRIBER_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* deleteSubscriber(action) {
  try {
    const {subscriberId} = action.payload;
    yield axios.delete(`/subscribers-v2/${subscriberId}/`);
    yield put({
      type: "DELETE_SUBSCRIBER_SUCCESS",
      payload: subscriberId,
      meta: actionNotification('Selected subscriber has been removed.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "DELETE_SUBSCRIBER_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}

export function* saga() {
  yield takeLatest('FETCH_SUBSCRIBERS_REQUEST', fetchSubscribers);
  yield takeLatest('FETCH_SUBSCRIBER_REQUEST', fetchSubscriber);
  yield takeLatest('ADD_SUBSCRIBER_REQUEST', addSubscriber);
  yield takeLatest('ADD_SUBSCRIBERS_XLS_REQUEST', addSubscribersXlsError);
  yield takeLatest('INVITE_SUBSCRIBER_REQUEST', inviteSubscriber);
  yield takeLatest('UPDATE_SUBSCRIBER_REQUEST', updateSubscriber);
  yield takeLatest('DELETE_SUBSCRIBER_REQUEST', deleteSubscriber);
}

import {FormattedMessage, injectIntl} from "react-intl";
import {Formik} from "formik";
import {TextField} from "@material-ui/core";
import {Link} from "react-router-dom";
import React from "react";
import connect from "react-redux/es/connect/connect";
import {resetPassword} from 'app/crud/user.crud';
import SetUserPaddwordConfirmation from "app/pages/auth/PasswordReset/SetUserPaddwordConfirmation";
import clsx from "clsx";
import styles from "../index.module.scss";

// This Component allow users to change their password based on Token
class SetUserPassword extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isDone: false
    }
  }
  componentWillReceiveProps(nextProps){
    if(nextProps.resetPasswordSuccess === true) {
      this.setState({
        isDone: true
      })
    }
  }

  render() {
    const { intl, match, resetPassword } = this.props;
    const { token } = match.params;
    const {isDone} = this.state;
    const queryParams = new URLSearchParams(window.location.search);
    const isFirstLogin = queryParams.get("first_login") === "true";

    // Display success message after successful password change
    if(isDone) {
      return <SetUserPaddwordConfirmation isFirstLogin={isFirstLogin}/>;
    }
    return (
      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__title">
            <h3>
              <FormattedMessage
                id={isFirstLogin ? "AUTH.FORGOT.SET_PASSWORD" : "AUTH.FORGOT.RESET_PASSWORD"}
              />
            </h3>
          </div>
          <Formik
            initialValues={{
              newPassword1: '',
              newPassword2: ''
            }}
            validate={values => {
              const errors = {};

              if (!values.newPassword1) {
                errors.newPassword1 = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              }

              if (!values.newPassword2) {
                errors.newPassword2 = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              } else if(values.newPassword1 !== values.newPassword2) {
                errors.newPassword2 = intl.formatMessage({
                  id: "AUTH.VALIDATION.PASSWORD_NOT_MATCH"
                });
              }

              return errors;
            }}
            onSubmit={(values) => {
              const {newPassword1, newPassword2} = values;
              const params = {
                newPassword1,
                newPassword2,
                token
              };
              resetPassword(params);
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form onSubmit={handleSubmit} autoComplete="off">
                {status && (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                )}

                <div className="form-group">
                  <TextField
                    type="password"
                    margin="normal"
                    label={intl.formatMessage({
                      id: "FORM.FIELD.NEW_PASSWORD"
                    })}
                    className={styles.customInput}
                    name="newPassword1"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.newPassword1}
                    helperText={touched.newPassword1 && errors.newPassword1}
                    error={Boolean(touched.newPassword1 && errors.newPassword1)}
                  />
                </div>
                <div className="form-group">
                  <TextField
                    type="password"
                    margin="normal"
                    label={intl.formatMessage({
                      id: "FORM.FIELD.CONFIRM_NEW_PASSWORD"
                    })}
                    className={styles.customInput}
                    name="newPassword2"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.newPassword2}
                    helperText={touched.newPassword2 && errors.newPassword2}
                    error={Boolean(touched.newPassword2 && errors.newPassword2)}
                  />
                </div>

                <div className={styles.contentActions}>
                  <Link to="/auth">
                    <FormattedMessage id="AUTH.GENERAL.BACK_BUTTON"/>
                  </Link>
                  <button
                    type="submit"
                    className={clsx("btn btn-primary btn-elevate kt-login__btn-primary", styles.btnSubmit)}
                  >
                    <FormattedMessage id="FORM.BUTTON.SUBMIT"/>
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  resetPasswordSuccess: state.user.resetPasswordSuccess
});
const mapDispatchToProps = {
  resetPassword: (params) => resetPassword(params)
};
export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SetUserPassword)
);

import React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {Grid, Typography} from "@material-ui/core";
import NoData from "../../../NoData/NoData";
import LoadingScreen from "../../../LoadingScreen";
import {fetchUnitContactPersons} from "../../../../../crud/estates/offices/units.crud";

class EditUnitContact extends React.Component {
  componentDidMount() {
    const { unitId, fetchUnitContactPersons } = this.props;
    fetchUnitContactPersons(unitId);
  }

  render() {
    const { contactDetails, data, isSublease, loading } = this.props;
    const handleIsSublease = data?.isSublease || isSublease;

    if(loading) {
      return <LoadingScreen/>;
    }
    else if(contactDetails) {
      const subleaseDetails = contactDetails?.subleaseContact;
      const isSubleaseEnable =
          subleaseDetails?.subleaseContactEmail.length &&
          subleaseDetails?.subleaseContactPhone.length;

      return(
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h3" style={{color: "#ff4626"}}><FormattedMessage id="VOCABULARY.Contact"/></Typography>
          </Grid>
          {
            handleIsSublease && isSubleaseEnable ?
            <Grid item>
                <Grid container spacing={1} direction="column">
                  <Grid item>
                    <Typography variant="body1">
                      {subleaseDetails?.subleaseContactEmail}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1">
                      {subleaseDetails?.subleaseContactPhone}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid> :
            contactDetails.length > 0 ? contactDetails?.map((contact, index) => {
              const {email = null, phone = null} = contact;
              return (
                <Grid item key={index}>
                  <Grid container spacing={1} direction="column">
                    {email && (
                      <Grid item>
                        <Typography variant="body1">{`${email}`}</Typography>
                      </Grid>
                    )}
                    {phone && (
                      <Grid item>
                        <Typography variant="body1">{`${phone}`}</Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              );
            }) :
              <NoData/>
          }
        </Grid>
      );
    }
    return <NoData/>;
  }
}

const mapStateToProps = (store) => ({
  contactDetails: store.units.unitContactPersons,
  loading: store.units.unitContactPersonsLoading,
  user: store.user.data,
});
const mapDispatchToProps = {
  fetchUnitContactPersons: (unitId) => fetchUnitContactPersons(unitId),
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditUnitContact)
);
import React, {useState} from "react";
import {callReddClickEvent} from "../../../../crud/user.crud";
import {fetchOfficeCertificates} from "../../../../crud/estates/offices/officeBuilding.crud";
import {fetchIndustrialParkCertificates} from "../../../../crud/estates/industrial/industrialPark.crud";
import {FormattedMessage} from "react-intl";
import connect from "react-redux/es/connect/connect";
import ReactVisibilitySensor from "react-visibility-sensor";
import PropertySectionTitle from "./PropertySectionTitle";
import _ from "lodash";
import styles from "../../../offices/PropertyPage/components/content/index.module.scss";
import LoadingScreen from "../../../../partials/components/LoadingScreen";
import {ReactComponent as CertBreeam} from "app/media/certificates/breeam.svg";
import {ReactComponent as CertWell} from "app/media/certificates/well.svg";
import {ReactComponent as CertLeed} from "app/media/certificates/leed.svg";
import {Grid} from "@material-ui/core";
import {toAbsoluteUrl} from "../../../../../_metronic";
import TooltipCustom from "../../../../partials/components/TooltipCustom";
import IsApplicationView from "../../../../utils/IsApplicationView";
import APPLICATION_VIEWS from "../../../../constants/APPLICATION_VIEWS";
import REDD_TOWER_DUMMY_DATA from "../../../offices/external/PropertyPageDemo/REDD_TOWER_DUMMY_DATA";

function PropertyCertificates(
  {
    officeBasic,
    officeCertificates,
    officeCertificatesLoading,
    fetchOfficeCertificates,
    parkCertificates,
    parkCertificatesLoading,
    fetchIndustrialParkCertificates,
    breakPoint,
    ...props
  }
) {
  const isOfficeView = IsApplicationView({expectedAppView: APPLICATION_VIEWS.OFFICES});
  const isIndustrialView = IsApplicationView({expectedAppView: APPLICATION_VIEWS.INDUSTRIAL});
  const [initLoaded, setInitLoaded] = useState(false);
  const certificates = isOfficeView ? officeCertificates : isIndustrialView ? parkCertificates : null;
  const certificatesLoading = isOfficeView ? officeCertificatesLoading : isIndustrialView ? parkCertificatesLoading : null;
  // Handlers
  const handleOnViewportFetch = (isVisible) => {
    const id = props.match?.params?.id;

    if(isOfficeView && isVisible && !initLoaded && !officeCertificatesLoading && _.isEmpty(officeCertificates) && id) {
      setInitLoaded(true);
      fetchOfficeCertificates(id)
    }
    else if(isIndustrialView && isVisible && !initLoaded && !parkCertificatesLoading && _.isEmpty(parkCertificates) && id) {
      setInitLoaded(true);
      fetchIndustrialParkCertificates(id)
    }
  }
  // Helpers
  const isCertificatesEnable = () => {
    if(certificates) {
      const {breeamCertification, wellCertification, leedCertification, hqeCertification, dgnbCertification, withoutBarrierCertification} = certificates;
      const certs = [
        ...breeamCertification ? [true] : [],
        ...wellCertification ? [true] : [],
        ...leedCertification ? [true] : [],
        ...hqeCertification ? [true] : [],
        ...dgnbCertification ? [true] : [],
        ...withoutBarrierCertification ? [true] : []
      ];
      return certs.filter(cert => cert)?.length > 0;
    }
    return false;
  }
  const renderCertificates = () => {
    if(certificates) {
      const {breeamCertification, wellCertification, leedCertification, hqeCertification, dgnbCertification, withoutBarrierCertification} = certificates;
      const certs = [
        ...breeamCertification ? [{
          icon: <CertBreeam/>,
          title: "BREEAM"
        }] : [],
        ...wellCertification ? [{
          icon: <CertWell/>,
          title: "WELL"
        }] : [],
        ...leedCertification ? [{
          icon: <CertLeed/>,
          title: "LEED"
        }] : [],
        ...hqeCertification ? [{
          icon: <img src={toAbsoluteUrl("media/certificates/hqe.png")} alt="HQE"/>,
          title: "HQE"
        }] : [],
        ...dgnbCertification ? [{
          icon: <img src={toAbsoluteUrl("media/certificates/dgnb.png")} alt="DGNB"/>,
          title: "DGNB"
        }] : [],
        ...withoutBarrierCertification ? [{
          icon: <img src={toAbsoluteUrl("media/certificates/obb-square.png")} alt="Obiekt Bez Barier"/>,
          title: <FormattedMessage id="Building Without Barriers"/>
        }] : []
      ];
      return certs?.map((cert, index) => {
        return(
          <TooltipCustom
            key={index}
            title={cert?.title}
            placement="bottom"
          >
            <span className={styles.certificateItem}>
              {cert?.icon}
            </span>
          </TooltipCustom>
        );
      });
    }
    return null;
  }

  if(initLoaded && !certificatesLoading && !isCertificatesEnable()) {
    return null;
  }
  return(
    <Grid item xs={breakPoint ? 6 : 12}>
      <div className={styles.propertyCertificatesContainer}>
        <ReactVisibilitySensor
          onChange={(isVisible) => handleOnViewportFetch(isVisible)}
          scrollCheck
          partialVisibility
        >
          <PropertySectionTitle
            title={<FormattedMessage id="VOCABULARY.Certificates"/>}
          />
        </ReactVisibilitySensor>
        {certificatesLoading ? <LoadingScreen/> :
        <div className={styles.certificatesWrapper}>
          {renderCertificates()}
        </div>
        }
      </div>
    </Grid>
  );
}

const mapStateToProps = (store, ownProps) => ({
  officeBasic: ownProps?.dummyData ? REDD_TOWER_DUMMY_DATA.basic : store.officeBuilding.basic,
  officeCertificates: ownProps?.dummyData ? REDD_TOWER_DUMMY_DATA.certificates : store.officeBuilding.certificates,
  officeCertificatesLoading: store.officeBuilding.certificatesLoading,
  parkBasic: store.industrialPark.basic,
  parkCertificates: store.industrialPark.certificates,
  parkCertificatesLoading: store.industrialPark.certificatesLoading
});
const mapDispatchToProps = {
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params),
  fetchOfficeCertificates: (officeID) => fetchOfficeCertificates(officeID),
  fetchIndustrialParkCertificates: (parkID) => fetchIndustrialParkCertificates(parkID)
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertyCertificates);
import React from "react";
import connect from "react-redux/es/connect/connect";
import {Typography} from "@material-ui/core";
import PortletAccordion from "app/partials/components/PortletAccordion";
import {FormattedMessage} from "react-intl";
import TableCustom from "app/partials/components/TableCustom";
import {fetchOfficesDashboardTransactions} from "app/crud/estates/offices/offices.crud";
import { fetchIndustrialDashboardTransactions } from "../../../../crud/estates/industrial/industrial.crud";
import _ from "lodash";
import LoadingScreen from "app/partials/components/LoadingScreen";
import NoData from "app/partials/components/NoData/NoData";
import {ROUTES} from "app/constants";
import APPLICATION_VIEWS from "app/constants/APPLICATION_VIEWS";
import PropertyCardPreview from "app/partials/components/_DataParts/PropertyCardPreview";
import ReactVisibilitySensor from "react-visibility-sensor";
import styles from "../../components/LeadsPages/index.module.scss";
import HighlightLabel from "../../../../partials/components/HighlightLabel";
import UpdatedAtPreview from "../../../../partials/components/_DataParts/UpdatedAtPreview";
import {toNumberFormat} from "../../../../utils/helpers";
import IsApplicationView from "../../../../utils/IsApplicationView";
import Button from "../../../../partials/components/Button";

class DashboardLatestTransactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tBody: null,
      initLoaded: false
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {officesTransactions, industrialTransactions} = this.props;
    const isOfficeView = IsApplicationView({expectedAppView: APPLICATION_VIEWS.OFFICES});
    const isIndustrialView = IsApplicationView({expectedAppView: APPLICATION_VIEWS.INDUSTRIAL});
    const data = isOfficeView ? officesTransactions : isIndustrialView ? industrialTransactions : null;
    const dataPrev = isOfficeView ? prevProps?.officesTransactions : isIndustrialView ? prevProps?.industrialTransactions : null;
    // Re-render TableBody
    if(
      // if offices was empty
      (_.isEmpty(dataPrev) && data?.length > 0) ||
      // if offices was updated
      (!_.isEqual(dataPrev, data))
    ) {
      this.setState({
        tBody: this.handlePrepTableBody()
      });
    }
  }
  // Handlers
  handlePrepTableBody = () => {
    const {officesTransactions, industrialTransactions} = this.props;
    const isOfficeView = IsApplicationView({expectedAppView: APPLICATION_VIEWS.OFFICES});
    const isIndustrialView = IsApplicationView({expectedAppView: APPLICATION_VIEWS.INDUSTRIAL});
    const data = isOfficeView ? officesTransactions : isIndustrialView ? industrialTransactions : null;

    if(data?.length > 0) {
      return data?.map(property => {
        return {
          data: [
            <span className={styles.propertyNameWrapper}>
              <PropertyCardPreview
                propertyID={property?.assetId}
                title={property?.assetName}
                semiTitle={property?.assetAddress}
                thumb={property?.assetThumbnail}
                route={isOfficeView ? ROUTES.BUILDING_PAGE : ROUTES.INDUSTRIAL_PARK}
                routeNewTab
                noOverflow
              />
            </span>,
            <HighlightLabel color="success">{toNumberFormat(property?.area, 'area')}</HighlightLabel>,
            <UpdatedAtPreview
              date={property?.date}
              alternative
            />
          ]
        }
      });
    }
  }
  handleOnViewportFetch = (isVisible) => {
    const {
      fetchOfficesDashboardTransactions,
      fetchIndustrialDashboardTransactions,
      officesTransactionsLoading,
      industrialTransactionsLoading
    } = this.props;
    const {initLoaded} = this.state;
    const isOfficeView = IsApplicationView({expectedAppView: APPLICATION_VIEWS.OFFICES});
    const isIndustrialView = IsApplicationView({expectedAppView: APPLICATION_VIEWS.INDUSTRIAL});

    if(isVisible && isOfficeView && !officesTransactionsLoading && !initLoaded) {
      this.setState({
        initLoaded: true
      }, () => fetchOfficesDashboardTransactions());
    }
    if(isVisible && isIndustrialView && !industrialTransactionsLoading && !initLoaded) {
      this.setState({
        initLoaded: true
      }, () => fetchIndustrialDashboardTransactions());
    }
  };

  render() {
    const {officesTransactionsLoading, industrialTransactionsLoading} = this.props;
    const {tBody} = this.state;
    const isOfficeView = IsApplicationView({expectedAppView: APPLICATION_VIEWS.OFFICES});
    const transactionsLoading = isOfficeView ? officesTransactionsLoading : industrialTransactionsLoading;
    const tHead = [
      {
        "label": isOfficeView ? <FormattedMessage id="GENERAL.PHRASE.BUILDING"/> : <FormattedMessage id="GENERAL.PHRASE.INDUSTRIAL_PARK"/>,
        "number": false
      },
      {
        "label": <FormattedMessage id="VOCABULARY.Area"/>,
        "number": true
      },
      {
        "label": <FormattedMessage id="VOCABULARY.Date"/>,
        "number": true
      }
    ];

    return(
      <PortletAccordion
        title={
        <ReactVisibilitySensor
            onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
            scrollCheck
            partialVisibility
        >
          <Typography variant="h3">
            <FormattedMessage id="MENU.LATEST_TRANSACTIONS"/>
          </Typography>
        </ReactVisibilitySensor>
        }
        fluidHeight
        disableAccordion
        footer={
        !_.isEmpty(tBody) &&
          <Button
            type={"plainText"}
            variant={"outlined"}
            redirect={ROUTES.LATEST_TRANSACTIONS}
            trackingData={{
              clickType: isOfficeView ?
                "dashboard-latest-transactions-see-all-click" :
                "dashboard-industrial-latest-transactions-see-all-click",
              contentType: "user"
            }}
            style={{fontSize: "1.6rem", fontWeight: "400"}}
          >
            <FormattedMessage id="VOCABULARY.Check all"/>
          </Button>
        }
      >
        {transactionsLoading ? <LoadingScreen/> :
          _.isEmpty(tBody) ? <NoData withIcon/> :
            <TableCustom
              tHead={tHead}
              tBody={tBody}
              propertyCellIndex={0}
              responsive
            />
        }
      </PortletAccordion>
    );
  }
}

const mapStateToProps = (state) => ({
  officesTransactions: state.offices.officesDashboardTransactions,
  officesTransactionsLoading: state.offices.officesDashboardTransactionsLoading,
  industrialTransactions: state.industrial.industrialDashboardTransactions,
  industrialTransactionsLoading: state.industrial.industrialDashboardTransactionsLoading
});
const mapDispatchToProps = {
  fetchOfficesDashboardTransactions: () => fetchOfficesDashboardTransactions(),
  fetchIndustrialDashboardTransactions: () => fetchIndustrialDashboardTransactions()
};
export default connect(mapStateToProps, mapDispatchToProps)(DashboardLatestTransactions)
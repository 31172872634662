import React from "react";
import {toDateFormat, toTimeFromDate} from "app/utils/helpers";
import clsx from "clsx";
import styles from "./index.module.scss";
import {FormattedMessage} from "react-intl";
import dayjs from "dayjs";

export default class UpdatedAtPreview extends React.Component {
  // Helpers
  getUpdateLevel = () => {
    const {date} = this.props;
    const currentDate = dayjs(new Date());
    const daysFromUpdate = currentDate.diff(date, 'day');

    return daysFromUpdate <= 30 ? "0" :
      daysFromUpdate > 30 && daysFromUpdate <= 90 ? "1" :
        daysFromUpdate > 90 && daysFromUpdate <= 360 ? "2" : "3";
  }
  render() {
    const {date, dateString, alternative, disableHighlight} = this.props;
    if(alternative && date) {
      return(
        <span className={clsx(
          styles.unitAlternativeDate,
          styles[`lvl${this.getUpdateLevel()}`]
        )}>
          {toTimeFromDate(date)}
        </span>
      );
    }
    return <span
        className={clsx(
          styles.level,
          disableHighlight ? undefined : styles[`lvl${this.getUpdateLevel()}`]
      )}
    >
      {
        date && !dateString ? toDateFormat(date) :
          (dateString ? date : <FormattedMessage id="GENERAL.PHRASE.UNDEFINED" />)
      }
    </span>;
  }
}
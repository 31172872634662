import React from "react";
import styles from "./index.module.scss";
import {FormattedMessage} from "react-intl";
import {handleEmptyValue, toNumberFormat} from "app/utils/helpers";
import clsx from "clsx";

export default class MarketplaceLeadsDataPreviewItem extends React.Component {
  render() {
    const {value, diffValue, label, diffLabel, icon} = this.props;
    return(
      <div className={styles.biDataWrapper}>
        <span className={styles.biLabel}><FormattedMessage id={label}/></span>
        <div className={styles.valueWrapper}>
            <img src={icon} alt="REDD Data" />
            <span className={styles.biValue}>{handleEmptyValue(value)}</span>
        </div>
        {diffValue && diffLabel &&
        <div className={styles.biDifferenceWrapper}>
            <span className={clsx(styles.diffValue, diffValue > 0 ? styles.success : diffValue < 0 && styles.danger)}>{toNumberFormat(diffValue, '%')}</span>
            <span className={styles.diffLabel}><FormattedMessage id={diffLabel}/></span>
        </div>
        }
      </div>
    );
  }
}
import React from "react";
import {Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {theExcerpt, toDateFormat, toNumberFormat} from "../../../../utils/helpers";
import {Link as RouterLink} from "react-router-dom";
import {ROUTES} from "../../../../constants";
import {Avatar} from "../../Avatar";
import HighlightLabel from "../../HighlightLabel";
import ModalCustom from "../../ModalCustom";
import OwnerCardPreview from "../../_DataParts/OwnerCardPreview";
import TooltipCustom from "../../TooltipCustom";

class TableAcquisitionOfficeCells extends React.Component {
    constructor(props) {
        super(props);
        this.child = React.createRef();
    }
  renderSellerBuyerData = (data, isSingle, type, buildingName) => {
    if(data && data.length) {
      const isBuyer = type === 'buyer';
      if(isSingle) {
          const {logo, id, shortName, name} = data[0];
          const companyLogo = logo ? logo : null;
          const nameExcerpt = name && theExcerpt(name, 30);
          const anyName = shortName ? shortName : nameExcerpt;
          return (
              <RouterLink to={id ? `${ROUTES.OWNER_PAGE}/${id}` : false}>
                  {logo ?
                      (anyName ? (
                          <TooltipCustom placement="top" title={<Typography variant="body2">{anyName}</Typography>}>
                              <span><Avatar avatar={companyLogo}/></span>
                          </TooltipCustom>
                      ) : <Avatar avatar={companyLogo}/>)
                   : <Typography>{anyName ? anyName : "N/A"}</Typography>}
              </RouterLink>
          );
      }
      const modalTitle = buildingName ? (`${buildingName} | ${this.props.intl.formatMessage({id: isBuyer ? "ACQUISITIONS.PHRASE.BUYER" : "ACQUISITIONS.PHRASE.SELLER"})}`) : <FormattedMessage id={isBuyer ? "ACQUISITIONS.PHRASE.BUYER" : "ACQUISITIONS.PHRASE.SELLER"}/>;
      return(
          <ModalCustom
          ref={this.child}
          btn={<HighlightLabel color={isBuyer ? "danger" : "success"}><FormattedMessage id={isBuyer ? "ACQUISITIONS.PHRASE.CHECK_BUYERS" : "ACQUISITIONS.PHRASE.CHECK_SELLERS"}/></HighlightLabel>}
          title={modalTitle}
          btnStyle={{cursor: "pointer"}}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell><FormattedMessage id="FORM.FIELD.COMPANY_NAME"/></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, index) => {
                const {logo, shortName, name} = item;
                const companyLogo = logo ? logo : null;
                const nameExcerpt = name && theExcerpt(name, 30);
                const anyName = shortName ? shortName : nameExcerpt;
                return (
                  <TableRow key={index}>
                    <TableCell>{`${index+1}`}</TableCell>
                    <TableCell>
                        <OwnerCardPreview name={anyName ? anyName : "N/A"} thumb={companyLogo} ownerID={item.id} thumbLogo/>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </ModalCustom>
      );
    }
    return false;
  };

  render() {
    const {row} = this.props;
    const acquisitionOffice = row.transactions ? row.transactions : null;
    const isSingleBuilding = acquisitionOffice && acquisitionOffice.length <= 1;
    const isSingleSeller = row.sellerCompanies && row.sellerCompanies.length <= 1;
    const isSingleBuyer = row.acquiringCompanies && row.acquiringCompanies.length <= 1;

    return (
      <>
        <TableCell>{this.renderSellerBuyerData(row.acquiringCompanies, isSingleBuyer, 'buyer', acquisitionOffice && isSingleBuilding && acquisitionOffice[0].office.name)}</TableCell>
        <TableCell>{this.renderSellerBuyerData(row.sellerCompanies, isSingleSeller, 'seller', acquisitionOffice && isSingleBuilding && acquisitionOffice[0].office.name)}</TableCell>
        <TableCell align="right">{toDateFormat(row.transactionDate)}</TableCell>
        <TableCell align="right">{toNumberFormat(row.share, '%')}</TableCell>
        <TableCell align="right">{toNumberFormat(row.price, 'currency')}</TableCell>
      </>
    );
  }
}

export default injectIntl(
  connect(
    null,
    null
  )(TableAcquisitionOfficeCells)
);

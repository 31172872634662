import React from "react";
import TableCell from "@material-ui/core/TableCell/TableCell";
import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {Grid, Typography} from "@material-ui/core";
import OfferRemove from "app/partials/components/Shortlists/OfferRemove";
import {fetchOffersOffices, fetchShortlistUnits, fetchShortlistUnitsReset,} from "app/crud/offers.crud";
import {toDateFormat, toNumberFormat} from "app/utils/helpers";
import {callReddClickEvent} from "app/crud/user.crud";
import TooltipCustom from "../../TooltipCustom";
import IconColorMUI from "../../IconColorMUI";
import {Link} from "react-router-dom";
import {ROUTES} from "../../../../constants";
import GenerateOfferPdf from "../../../../pages/offices/OffersPage/pdf/GenerateOfferPdf";
import OfferShare from "../../../../pages/offices/OffersPage/components/OfferShare";
import IsAdmin from "../../../../utils/IsAdmin";

class TableOffersCells extends React.Component {
  render() {
    const {
      row,
      isTeamOwner
    } = this.props;
    const inactive = row?.unitsCount <= 0;
    const ownerColumnAllowed = isTeamOwner || IsAdmin();

    return (
      <>
        <TableCell component="th" scope="row">
          <Grid container spacing={0}>
            <Grid item>
              {row?.id ?
              <Link to={`${ROUTES.OFFER_PAGE}/${row?.id}`} className="linkBrand">
                {row?.name}
              </Link> : row?.name
              }
            </Grid>
            <Grid item>
              {row?.forTesting &&
                <TooltipCustom
                  title={
                  <span style={{display: "inline-block", textAlign: "left"}}>
                    <strong>Test offer</strong>: Admin Only
                  </span>
                  }
                >
                <IconColorMUI icon="info_outline" color="danger" size="16px" style={{fontSize: 0, marginLeft: 5, lineHeight: 0}}/>
              </TooltipCustom>
              }
            </Grid>
          </Grid>
        </TableCell>
        {ownerColumnAllowed &&
        <TableCell component="th" scope="row">
          <Typography variant="body1">{row?.owner}</Typography>
        </TableCell>
        }
        <TableCell>{row?.offerDate ? toDateFormat(row?.offerDate) : toDateFormat(row?.createdAt)}</TableCell>
        <TableCell align="right">{toNumberFormat(row?.buildingsCount)}</TableCell>
        <TableCell align="right">{toNumberFormat(row?.unitsCount)}</TableCell>
        <TableCell align="right">
          {!row?.isDeleted &&
          <Grid container spacing={1} justifyContent="flex-end" wrap="nowrap">
            <Grid item>
              <GenerateOfferPdf
                offerID={row?.id}
                inactive={inactive}
              />
            </Grid>
            <Grid item>
              <OfferShare
                offerID={row?.id}
                offerHash={row?.urlHash}
                offerName={row?.name}
                inactive={inactive}
                directBtn
              />
            </Grid>
            <Grid item>
              <OfferRemove
                offerID={row?.id}
                offerName={row?.name}
              />
            </Grid>
          </Grid>
          }
        </TableCell>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  shortlistUnits: state.shortlists.shortlistUnits,
  shortlistUnitsLoading: state.shortlists.shortlistUnitsLoading,
  isTeamOwner: state.user.data.isTeamOwner
});
const mapDispatchToProps = {
  fetchOffersOffices: ({ params }) => fetchOffersOffices(params),
  fetchShortlistUnits: (shortlistID) => fetchShortlistUnits(shortlistID),
  fetchShortlistUnitsReset: fetchShortlistUnitsReset,
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params)
};
export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(TableOffersCells)
);

import React from "react";
import styles from "./index.module.scss";
import cn from "classnames";
import TooltipCustom from "../../../../../partials/components/TooltipCustom";

export default function PropertySubscription({subscription}) {
  if(subscription) {
    const subName = subscription && subscription.charAt(0) + subscription.slice(1).toLowerCase();

    return(
      <TooltipCustom title={subName}>
        <span className={cn(
          styles.propertySubscriptionWrapper,
          styles[`sub_${subscription}`]
        )}>{Array.from(subName)[0]}</span>
      </TooltipCustom>
    );
  }
  return null;
}
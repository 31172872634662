import React from 'react';
import {Typography} from "@material-ui/core";
import ModalCustom from "app/partials/components/ModalCustom";
import {refreshAllOfficeUnits} from "app/crud/estates/offices/units.crud";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import Icon from "../Icon";
import Button from "../Button";

class UnitRefreshAllOfficeUnits extends React.Component {
  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleSave = () => {
    const {officeId, refreshAllOfficeUnits, ordering, onRefreshComplete} = this.props;
    const backgroundRefreshObject = {
      ordering
    };
    refreshAllOfficeUnits(officeId, backgroundRefreshObject);
    onRefreshComplete && onRefreshComplete();
    this.handleCloseModal();
  };

  constructor(props) {
    super(props);
    this.child = React.createRef();
  }

  render() {
    return(
      <ModalCustom
        ref={this.child}
        btn={
        <Button
          type={"plainText"}
          textIcon={<Icon icon={"refresh"}/>}
        >
          <FormattedMessage id="UNITS.PHRASE.REFRESH_ALL_OFFICE_UNITS"/>
        </Button>
        }
        btnReject={<FormattedMessage id="GENERAL.PHRASE.CANCEL"/>}
        btnConfirm={<FormattedMessage id="GENERAL.PHRASE.REFRESH"/>}
        title={<FormattedMessage id="UNITS.PHRASE.REFRESH_ALL_OFFICE_UNITS"/>}
        handleSave={this.handleSave}
      >
        <Typography><FormattedMessage id="UNITS.PHRASE.REFRESH_ALL_OFFICE_UNITS_MESSAGE"/> <br/><FormattedMessage id="GENERAL.PHRASE.ACTION_CONFIRMATION_MESSAGE"/></Typography>
      </ModalCustom>
    );
  }
}

const mapDispatchToProps = {
  refreshAllOfficeUnits: (officeId, backgroundRefreshObject) => refreshAllOfficeUnits(officeId, backgroundRefreshObject)
};

export default injectIntl(
  connect(
    null,
    mapDispatchToProps
  )(UnitRefreshAllOfficeUnits)
);
import React from "react";
import clsx from "clsx";
import styles from "app/pages/common/external/components/index.module.scss";
import industrialStyles from "app/pages/industrial/external/components/index.module.scss";
import {FormattedMessage} from "react-intl";
import {Typography} from "@material-ui/core";
import {handleEmptyValue, toNumberFormat} from "../../../../../../utils/helpers";

export default class PropertyParticularsSingleOwner extends React.Component {
    render() {
        const {reference, owner} = this.props;
        const ownerCity = [
            ...owner?.addressZipcode ? [owner?.addressZipcode] : [],
            ...owner?.addressCity ? [owner?.addressCity] : []
        ].join(" ");
        const ownerAddress = [
            ...owner?.addressStreet ? [owner?.addressStreet] : [],
            ownerCity,
            ...owner?.addressCountry ? [owner?.addressCountry] : []
        ];

        return(
            <div ref={reference} className={clsx(styles.boxContainer)}>
                <h2><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.OWNER"/></h2>
                <div className={industrialStyles.ownerDetailsWrapper}>
                    <div className={industrialStyles.ownerDetailsLeft}>
                        {owner?.logo && <img src={owner?.logo} alt={`${owner?.name}`} className={industrialStyles.ownerLogo}/>}
                        <Typography variant="h4">{owner?.name}</Typography>
                        <Typography variant="body1" className={industrialStyles.ownerAddress}>{ownerAddress.join(", ")}</Typography>
                        {owner?.description && <Typography variant="body1" className={industrialStyles.ownerDesc}>{owner?.description}</Typography>}
                    </div>
                    <div className={industrialStyles.ownerDetailsRight}>
                        <div className={industrialStyles.ownerDataWrapper}>
                            <div className={industrialStyles.ownerDataItem}>
                                <Typography variant="body1"><FormattedMessage id="INDUSTRIAL.PHRASE.PORTFOLIO_AREA"/></Typography>
                                <Typography variant="h4">{toNumberFormat(owner?.portfolioArea, 'area')}</Typography>
                            </div>
                            <div className={industrialStyles.ownerDataItem}>
                                <Typography variant="body1"><FormattedMessage id="INDUSTRIAL.PHRASE.PORTFOLIO_BUILDINGS"/></Typography>
                                <Typography variant="h4">{handleEmptyValue(owner?.buildingsCount)}</Typography>
                            </div>
                            <div className={industrialStyles.ownerDataItem}>
                                <Typography variant="body1"><FormattedMessage id="INDUSTRIAL.PHRASE.MAIN_MARKET"/></Typography>
                                <Typography variant="h4">{handleEmptyValue(owner?.mainMarket)}</Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import objectPath from "object-path";
import PropertyPageDemo from "./PropertyPageDemo";
import REDD_TOWER_DUMMY_DATA from "./REDD_TOWER_DUMMY_DATA";

const mapStateToProps = ({builder: { layoutConfig }}) => ({
  // Config DATA
  layoutConfig,
  selfLayout: objectPath.get(layoutConfig, "self.layout"),
  asideDisplay: objectPath.get(layoutConfig, "aside.self.display"),
  subheaderDisplay: objectPath.get(layoutConfig, "subheader.display"),
  desktopHeaderDisplay: objectPath.get(
    layoutConfig,
    "header.self.fixed.desktop"
  ),
  fitTop: objectPath.get(layoutConfig, "content.fit-top"),
  fluid: objectPath.get(layoutConfig, "content.width") === "fluid",
  // Common DATA
  user: REDD_TOWER_DUMMY_DATA.user,
  // Property DATA
  basic: REDD_TOWER_DUMMY_DATA.basic,
  basicLoading: false,
  units: REDD_TOWER_DUMMY_DATA.units,
  unitsLoading: false,
  leaseTerms: REDD_TOWER_DUMMY_DATA.leaseTerms,
  leaseTermsLoading: false,
  amenities: REDD_TOWER_DUMMY_DATA.amenities,
  amenitiesLoading: false,
  changes: REDD_TOWER_DUMMY_DATA.changes,
  changesLoading: false,
  similarProperties: REDD_TOWER_DUMMY_DATA.similarProperties,
  similarPropertiesLoading: false,
  owner: REDD_TOWER_DUMMY_DATA.owner,
  ownerLoading: false,
  leasingContacts: REDD_TOWER_DUMMY_DATA.leasingContacts,
  leasingContactsLoading: false,
  photos: REDD_TOWER_DUMMY_DATA.photos,
  photosLoading: false
});
export default injectIntl(
  connect(mapStateToProps)(PropertyPageDemo)
);

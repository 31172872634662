import React from "react";
import {FILTERS_SELECTS} from "app/partials/components/FiltersDrawer/constants";
import {UNIT_STATUS} from "app/constants";
import {FormattedMessage} from "react-intl";
import Button from "../Button";
import styles from "./index.module.scss";

export default class UnitStatusPreview extends React.Component {
  render() {
    const {unitStatus} = this.props;
    const statusesOptions = FILTERS_SELECTS.UNITS_STATUSES;
    const status = statusesOptions.filter(option => option["id"] === unitStatus);
    const statusLabel = status && status.length ? status[0].label.props.id : 'N/A';
    const iconStatus = unitStatus === UNIT_STATUS.AVAILABLE ? 'true' : unitStatus === UNIT_STATUS.RESERVED ? 'maybe' : unitStatus === UNIT_STATUS.UNAVAILABLE && 'false';
    const color = iconStatus === 'true' ? 'success' : iconStatus === 'maybe' ? 'warning' : iconStatus === 'false' && 'danger';
    // return <TooltipCustom title={<FormattedMessage id={statusLabel}/>}>{theStatusIcon(iconStatus)}</TooltipCustom>;
    return(
      <Button
        type={"icon"}
        color={color}
        tooltip={<FormattedMessage id={statusLabel}/>}
        style={{cursor: "default"}}
      >
        <span className={styles.unitStatusDot}/>
      </Button>
    );
  }
}
import React from 'react';
import {InputAdornment, TextField} from '@material-ui/core';

export default function InputAdornments(props) {
  const {position, adornmentLabel, type, label, name, value, onChange, disabled, minWidth, style} = props;
  const isStartAdornment = position === "start";
  const isEndAdornment = position === "end";
  const isTypeDate = type === "date";
  if(isTypeDate) {
    return (
      <TextField
        type="date"
        label={label}
        name={name}
        defaultValue={value}
        onChange={onChange}
        disabled={disabled}
        fullWidth
        InputProps={{
          startAdornment: isStartAdornment && <InputAdornment position={position}>{adornmentLabel}</InputAdornment>,
          endAdornment: isEndAdornment && <InputAdornment position={position}>{adornmentLabel}</InputAdornment>
        }}
        InputLabelProps={{
          shrink: true,
        }}
        style={{minWidth, ...style}}
      />
    );
  }
  return (
    <TextField
      label={label}
      name={name}
      defaultValue={value || ""}
      onChange={onChange}
      disabled={disabled}
      fullWidth
      style={{minWidth}}
      InputProps={{
        startAdornment: isStartAdornment && <InputAdornment position={position}>{adornmentLabel}</InputAdornment>,
        endAdornment: isEndAdornment && <InputAdornment position={position}>{adornmentLabel}</InputAdornment>
      }}
      {...props}
    />
  );
}

import {fetchShortlistsIndustrial} from "app/crud/estates/industrial/offers-industrial.crud"
import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import ShortlistsPage from "./OffersPage";
import {callReddClickEvent} from "app/crud/user.crud";

const mapStateToProps = (state) => ({
  shortlists: state.offersIndustrial,
  items: state.offersIndustrial.items,
  loaded: state.offersIndustrial.isLoadedShortlist,
  loading: state.offersIndustrial.isLoadingShortlists,
  isTeamOwner: state.user.data.isTeamOwner,
  user: state.user.data
});

const mapDispatchToProps = {
  fetchShortlists: ({params}) => fetchShortlistsIndustrial(params),
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ShortlistsPage)
);

import React from "react";
import {Grid, Typography} from "@material-ui/core";
import LoadingScreen from "../../../../partials/components/LoadingScreen";
import LineChart from "../../../../partials/components/Charts/LineChart";
import {FormattedMessage, injectIntl} from "react-intl";
import styles from "./index.module.scss";
import NoData from "../../../../partials/components/NoData/NoData";
import {
  fetchOfficeRCAll,
  fetchOfficeRCBuilding,
  fetchOfficeRCContact,
  fetchOfficeRCUnits,
} from "../../../../crud/estates/offices/offices.crud";
import connect from "react-redux/es/connect/connect";
import {getMonthName, getWeeksInYear} from "../../../../utils/helpers";
import cx from "classnames";

// Array of allowed years to be used in stats filters
const allowedYears = ["2020", "2021", "2022", "2023", "2024"];
// Array of allowed periods to be used in stats filters
const allowedPeriods = ["weekly", "monthly"];

class LandlordStats extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    const { activeYear } = props;
    this.state = {
      isActive: false,
      defaultYear: activeYear || allowedYears[4],
      defaultDataPeriod: allowedPeriods[1],
      buildingDataPeriod: allowedPeriods[1],
      unitsDataPeriod: allowedPeriods[1],
      contactDataPeriod: allowedPeriods[1],
      allDataPeriod: allowedPeriods[1],
      targetId: this.props.targetId
    };
  }
  static getDerivedStateFromProps(props, state) {
    const {
      targetId,
      activeYear,
      fetchOfficeRCBuilding,
      fetchOfficeRCUnits,
      fetchOfficeRCContact,
      fetchOfficeRCAll
    } = props;
    const {
      defaultYear,
      defaultDataPeriod,
      buildingDataPeriod,
      unitsDataPeriod,
      contactDataPeriod,
      allDataPeriod,
    } = state;

    if (props.active && props.active !== state.isActive) {
      // Define fetch params
      const params = {
        targetId,
        year: defaultYear,
        dataPeriod: defaultDataPeriod,
      };
      // Two possible fetching categories: office stats
      fetchOfficeRCBuilding({ params });
      fetchOfficeRCUnits({ params });
      fetchOfficeRCContact({ params });
      fetchOfficeRCAll({ params });

      return {
        isActive: props.active,
      };
    }
    else if (props.active && props.activeYear !== state.defaultYear) {
      // Two possible fetching categories: office stats
      fetchOfficeRCBuilding({
        params: {
          targetId,
          year: activeYear,
          dataPeriod: buildingDataPeriod,
        },
      });
      fetchOfficeRCUnits({
        params: { targetId, year: activeYear, dataPeriod: unitsDataPeriod },
      });
      fetchOfficeRCContact({
        params: {
          targetId,
          year: activeYear,
          dataPeriod: contactDataPeriod,
        },
      });
      fetchOfficeRCAll({
        params: { targetId, year: activeYear, dataPeriod: allDataPeriod },
      });
      return {
        defaultYear: activeYear,
      };
    }
    return null;
  }

  // Fetch Stats - Redd Clicks Buildings
  handleFetchRCBuilding = (year, dataPeriod) => {
    const {
      targetId,
      fetchOfficeRCBuilding
    } = this.props;

    const params = {
      targetId,
      year,
      dataPeriod,
    };
    this.setState(
      {
        buildingDataPeriod: dataPeriod,
      }, () => fetchOfficeRCBuilding({ params })
    );
  };
  // Fetch Stats - Redd Clicks Units
  handleFetchRCUnits = (year, dataPeriod) => {
    const {
      targetId,
      fetchOfficeRCUnits
    } = this.props;
    const params = {
      targetId,
      year,
      dataPeriod,
    };

    this.setState(
      {
        unitsDataPeriod: dataPeriod,
      }, () => fetchOfficeRCUnits({ params })
    );
  };
  // Fetch Stats - Redd Clicks Contact
  handleFetchRCContact = (year, dataPeriod) => {
    const {
      targetId,
      fetchOfficeRCContact,
    } = this.props;
    const params = {
      targetId,
      year,
      dataPeriod,
    };

    this.setState(
      {
        contactDataPeriod: dataPeriod,
      }, () => fetchOfficeRCContact({ params })
    );
  };
  // Fetch Stats - Redd Clicks All
  handleFetchRCAll = (year, dataPeriod) => {
    const {
      targetId,
      fetchOfficeRCAll,
    } = this.props;
    const params = {
      targetId,
      year,
      dataPeriod,
    };

    this.setState(
      {
        allDataPeriod: dataPeriod,
      }, () => fetchOfficeRCAll({ params })
    );
  };

  // Return proper data format for Stats Line Charts
  setChartData = (loading, data, dataPeriod) => {
    const months = Array.from({ length: 12 }, (_, i) => i + 1);
    const weeks = Array.from(
      { length: getWeeksInYear(this.state.defaultYear) },
      (_, i) => i + 1
    );
    const period =
      dataPeriod === allowedPeriods[1]
        ? months
        : dataPeriod === allowedPeriods[0] && weeks;
    const periodKey = dataPeriod === allowedPeriods[1] ? "month" : "week";
    if (!loading && data) {
      return period.map((time) => {
        const apiValue = data.filter((item) => item[periodKey] === time);
        const value = apiValue && apiValue.length ? apiValue[0].number : 0;
        const label =
          dataPeriod === allowedPeriods[1]
            ? getMonthName(time, this.props.intl.locale)
            : time;
        return {
          label: label,
          value: value,
        };
      });
    }
    return null;
  };
  // Get total number of views/redd clicks
  getTotalViews = (data) => {
    let totalViews = 0;
    data.map((item) => (totalViews = totalViews + item.number));
    return totalViews;
  };

  render() {
    const {
      theme,
      rcBuildingWeekly,
      rcBuildingMonthly,
      rcBuildingLoading,
      rcUnitsWeekly,
      rcUnitsMonthly,
      rcUnitsLoading,
      rcContactWeekly,
      rcContactMonthly,
      rcContactLoading,
      rcAllWeekly,
      rcAllMonthly,
      rcAllLoading
    } = this.props;
    const {
      defaultYear,
      buildingDataPeriod,
      unitsDataPeriod,
      contactDataPeriod,
      allDataPeriod,
    } = this.state;

    const isBuildingDataPeriodMonthly =
      buildingDataPeriod === allowedPeriods[1];
    const isUnitsDataPeriodMonthly = unitsDataPeriod === allowedPeriods[1];
    const isContactDataPeriodMonthly = contactDataPeriod === allowedPeriods[1];
    const isAllDataPeriodMonthly = allDataPeriod === allowedPeriods[1];
    const activeBuildingDataPeriod = isBuildingDataPeriodMonthly
      ? allowedPeriods[1]
      : allowedPeriods[0];
    const activeBuildingData = isBuildingDataPeriodMonthly ? rcBuildingMonthly : rcBuildingWeekly;
    const activeUnitsDataPeriod = isUnitsDataPeriodMonthly
      ? allowedPeriods[1]
      : allowedPeriods[0];
    const activeUnitsData = isUnitsDataPeriodMonthly ? rcUnitsMonthly : rcUnitsWeekly;
    const activeContactDataPeriod = isContactDataPeriodMonthly
      ? allowedPeriods[1]
      : allowedPeriods[0];
    const activeContactData = isContactDataPeriodMonthly ? rcContactMonthly : rcContactWeekly;
    const activeBAllDataPeriod = isAllDataPeriodMonthly
      ? allowedPeriods[1]
      : allowedPeriods[0];
    const activeAllData = isAllDataPeriodMonthly ? rcAllMonthly : rcAllWeekly;
    const buildingLoading = rcBuildingLoading;
    const unitsLoading = rcUnitsLoading;
    const contactLoading = rcContactLoading;

    const allLoading = rcAllLoading;

    const isLightTheme = theme === "light";
    const gridLineColor = isLightTheme
      ? "rgba(0,0,0,0.1)"
      : "rgba(255,255,255,0.1)";

    return (
      <Grid container spacing={2}>
        {/* Render Redd Clicks Buildings stats chart */}
        <Grid item xs={6}>
          {buildingLoading ? (
            <LoadingScreen />
          ) : activeBuildingData ? (
            <LineChart
              dataChart={this.setChartData(
                buildingLoading,
                activeBuildingData,
                activeBuildingDataPeriod
              )}
              title={
                <FormattedMessage id="BUILDING.PHRASE.REDD_CLICKS_DETAILS" />
              }
              desc={
                <Typography variant="h2">
                  <Typography>
                    <FormattedMessage id="GENERAL.PHRASE.TOTAL_VIEWS" />:
                  </Typography>{" "}
                  {this.getTotalViews(activeBuildingData)}
                </Typography>
              }
              labelX={
                isBuildingDataPeriodMonthly
                  ? "GENERAL.PHRASE.MONTHS"
                  : "GENERAL.PHRASE.WEEKS"
              }
              labelY="GENERAL.PHRASE.VIEWS"
              tooltipPrefix={
                activeBuildingDataPeriod === allowedPeriods[0] &&
                this.props.intl.formatMessage({ id: "GENERAL.PHRASE.WEEK" })
              }
              height={250}
              disableSorting
              roundValues
              titleMarginDisable
              gridLine={{ color: gridLineColor }}
              toolbar={
                <Grid container spacing={1} alignItems="center">
                  {allowedPeriods &&
                    allowedPeriods.map((period, index) => {
                      return (
                        <Grid key={index} item>
                          <button
                            className={cx(
                              styles.customBtn,
                              period === buildingDataPeriod && styles.active,
                              isLightTheme && styles.light
                            )}
                            onClick={() =>
                              this.handleFetchRCBuilding(defaultYear, period)
                            }
                          >
                            <Typography>
                              <span>
                                <FormattedMessage
                                  id={
                                    period === allowedPeriods[0]
                                      ? "BUILDING.PHRASE.REDD_CLICKS_LAST_WEEK"
                                      : "BUILDING.PHRASE.REDD_CLICKS_LAST_MONTH"
                                  }
                                />
                              </span>
                            </Typography>
                          </button>
                        </Grid>
                      );
                    })}
                </Grid>
              }
            />
          ) : (
            <NoData />
          )}
        </Grid>
        {/* Render Redd Clicks Units stats chart */}
        <Grid item xs={6}>
          {unitsLoading ? (
            <LoadingScreen />
          ) : activeUnitsData ? (
            <LineChart
              dataChart={this.setChartData(
                unitsLoading,
                activeUnitsData,
                activeUnitsDataPeriod
              )}
              title={
                <FormattedMessage id="BUILDING.PHRASE.REDD_CLICKS_UNITS" />
              }
              desc={
                <Typography variant="h2">
                  <Typography>
                    <FormattedMessage id="GENERAL.PHRASE.TOTAL_VIEWS" />:
                  </Typography>{" "}
                  {this.getTotalViews(activeUnitsData)}
                </Typography>
              }
              labelX={
                isUnitsDataPeriodMonthly
                  ? "GENERAL.PHRASE.MONTHS"
                  : "GENERAL.PHRASE.WEEKS"
              }
              labelY="GENERAL.PHRASE.VIEWS"
              tooltipPrefix={
                activeUnitsDataPeriod === allowedPeriods[0] &&
                this.props.intl.formatMessage({ id: "GENERAL.PHRASE.WEEK" })
              }
              height={250}
              disableSorting
              roundValues
              titleMarginDisable
              chartColors={{
                background: "rgba(10,187,135, 0.2)",
                border: "rgb(10,187,135)",
              }}
              gridLine={{ color: gridLineColor }}
              toolbar={
                <Grid container spacing={1} alignItems="center">
                  {allowedPeriods &&
                    allowedPeriods.map((period, index) => {
                      return (
                        <Grid key={index} item>
                          <button
                            className={cx(
                              styles.customBtn,
                              period === activeUnitsDataPeriod && styles.active,
                              isLightTheme && styles.light
                            )}
                            onClick={() =>
                              this.handleFetchRCUnits(defaultYear, period)
                            }
                          >
                            <Typography>
                              <span>
                                <FormattedMessage
                                  id={
                                    period === allowedPeriods[0]
                                      ? "BUILDING.PHRASE.REDD_CLICKS_LAST_WEEK"
                                      : "BUILDING.PHRASE.REDD_CLICKS_LAST_MONTH"
                                  }
                                />
                              </span>
                            </Typography>
                          </button>
                        </Grid>
                      );
                    })}
                </Grid>
              }
            />
          ) : (
            <NoData />
          )}
        </Grid>
        {/* Render Redd Clicks Contact stats chart */}
        <Grid item xs={6}>
          {contactLoading ? (
            <LoadingScreen />
          ) : activeContactData ? (
            <LineChart
              dataChart={this.setChartData(
                contactLoading,
                activeContactData,
                activeContactDataPeriod
              )}
              title={
                <FormattedMessage id="BUILDING.PHRASE.REDD_CLICKS_CONTACTS" />
              }
              desc={
                <Typography variant="h2">
                  <Typography>
                    <FormattedMessage id="GENERAL.PHRASE.TOTAL_VIEWS" />:
                  </Typography>{" "}
                  {this.getTotalViews(activeContactData)}
                </Typography>
              }
              labelX={
                isContactDataPeriodMonthly
                  ? "GENERAL.PHRASE.MONTHS"
                  : "GENERAL.PHRASE.WEEKS"
              }
              labelY="GENERAL.PHRASE.VIEWS"
              tooltipPrefix={
                activeContactDataPeriod === allowedPeriods[0] &&
                this.props.intl.formatMessage({ id: "GENERAL.PHRASE.WEEK" })
              }
              height={250}
              disableSorting
              roundValues
              titleMarginDisable
              chartColors={{
                background: "rgba(74,157,253, 0.2)",
                border: "rgb(74,157,253)",
              }}
              gridLine={{ color: gridLineColor }}
              toolbar={
                <Grid container spacing={1} alignItems="center">
                  {allowedPeriods &&
                    allowedPeriods.map((period, index) => {
                      return (
                        <Grid key={index} item>
                          <button
                            className={cx(
                              styles.customBtn,
                              period === activeContactDataPeriod &&
                                styles.active,
                              isLightTheme && styles.light
                            )}
                            onClick={() =>
                              this.handleFetchRCContact(defaultYear, period)
                            }
                          >
                            <Typography>
                              <span>
                                <FormattedMessage
                                  id={
                                    period === allowedPeriods[0]
                                      ? "BUILDING.PHRASE.REDD_CLICKS_LAST_WEEK"
                                      : "BUILDING.PHRASE.REDD_CLICKS_LAST_MONTH"
                                  }
                                />
                              </span>
                            </Typography>
                          </button>
                        </Grid>
                      );
                    })}
                </Grid>
              }
            />
          ) : (
            <NoData />
          )}
        </Grid>
        {/* Render Redd Clicks All stats chart */}
        <Grid item xs={6}>
          {allLoading ? (
            <LoadingScreen />
          ) : activeAllData ? (
            <LineChart
              dataChart={this.setChartData(
                allLoading,
                activeAllData,
                activeBAllDataPeriod
              )}
              title={<FormattedMessage id="BUILDING.PHRASE.REDD_CLICKS_ALL" />}
              desc={
                <Typography variant="h2">
                  <Typography>
                    <FormattedMessage id="GENERAL.PHRASE.TOTAL_VIEWS" />:
                  </Typography>{" "}
                  {this.getTotalViews(activeAllData)}
                </Typography>
              }
              labelX={
                isAllDataPeriodMonthly
                  ? "GENERAL.PHRASE.MONTHS"
                  : "GENERAL.PHRASE.WEEKS"
              }
              labelY="GENERAL.PHRASE.VIEWS"
              tooltipPrefix={
                activeBAllDataPeriod === allowedPeriods[0] &&
                this.props.intl.formatMessage({ id: "GENERAL.PHRASE.WEEK" })
              }
              height={250}
              disableSorting
              roundValues
              titleMarginDisable
              chartColors={{
                background: "rgba(255,184,34, 0.2)",
                border: "rgb(255,184,34)",
              }}
              gridLine={{ color: gridLineColor }}
              toolbar={
                <Grid container spacing={1} alignItems="center">
                  {allowedPeriods &&
                    allowedPeriods.map((period, index) => {
                      return (
                        <Grid key={index} item>
                          <button
                            className={cx(
                              styles.customBtn,
                              period === activeBAllDataPeriod && styles.active,
                              isLightTheme && styles.light
                            )}
                            onClick={() =>
                              this.handleFetchRCAll(defaultYear, period)
                            }
                          >
                            <Typography>
                              <span>
                                <FormattedMessage
                                  id={
                                    period === allowedPeriods[0]
                                      ? "BUILDING.PHRASE.REDD_CLICKS_LAST_WEEK"
                                      : "BUILDING.PHRASE.REDD_CLICKS_LAST_MONTH"
                                  }
                                />
                              </span>
                            </Typography>
                          </button>
                        </Grid>
                      );
                    })}
                </Grid>
              }
            />
          ) : (
            <NoData />
          )}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (store) => ({
  rcBuildingWeekly: store.offices.rcBuildingWeekly,
  rcBuildingMonthly: store.offices.rcBuildingMonthly,
  rcBuildingLoading: store.offices.rcBuildingLoading,
  rcUnitsWeekly: store.offices.rcUnitsWeekly,
  rcUnitsMonthly: store.offices.rcUnitsMonthly,
  rcUnitsLoading: store.offices.rcUnitsLoading,
  rcContactWeekly: store.offices.rcContactWeekly,
  rcContactMonthly: store.offices.rcContactMonthly,
  rcContactLoading: store.offices.rcContactLoading,
  rcAllWeekly: store.offices.rcAllWeekly,
  rcAllMonthly: store.offices.rcAllMonthly,
  rcAllLoading: store.offices.rcAllLoading
});
const mapDispatchToProps = {
  fetchOfficeRCBuilding: ({ params }) => fetchOfficeRCBuilding(params),
  fetchOfficeRCUnits: ({ params }) => fetchOfficeRCUnits(params),
  fetchOfficeRCContact: ({ params }) => fetchOfficeRCContact(params),
  fetchOfficeRCAll: ({ params }) => fetchOfficeRCAll(params)
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(LandlordStats)
);
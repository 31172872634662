import React from "react";
import {ROUTES} from "../../../../constants";
import PropertyCardPreview from "../../../../partials/components/_DataParts/PropertyCardPreview";
import styles from "./index.module.scss"
import LoadingScreen from "../../../../partials/components/LoadingScreen";

export default function PropertyHeaderBackToPark({data}) {
  return(
    <div className={styles.headerBackToParkWrapper}>
      {data ?
      <PropertyCardPreview
        propertyID={data?.parkId}
        title={data?.parkName}
        semiTitle={data?.addressStreet}
        semiTitle2={data?.addressCity}
        thumb={data?.mainPhotoThumbnail600x400Park}
        route={ROUTES.INDUSTRIAL_PARK}
        backArrow
      /> : <LoadingScreen/>
      }
    </div>
  );
}
import React, {useEffect, useRef, useState} from 'react';
import PropertySectionTitle from "../../../common/components/PropertyPage/PropertySectionTitle";
import styles from "./index.module.scss";
import IconColorMUI from "../../../../partials/components/IconColorMUI";
import {FormattedMessage} from "react-intl";
import InputSwitcher from "../../../../partials/components/InputSwitcher";
import {toDateFormat} from "../../../../utils/helpers";
import LoadingScreen from "../../../../partials/components/LoadingScreen";
import IsAdmin from "../../../../utils/IsAdmin";

export default function OfferSettings(props) {
  const datePickerRef = useRef();
  const {offerUpdating, updateOffer, match, data} = props;
  const offerID = match?.params?.id;
  const offerLeasingManagerEnable = data?.isLeasingManagerEnable || false;
  const offerManualDate = data?.offerDate || data?.createdAt;
  const offerForTesting = data?.forTesting || false;
  // States
  const [isLeasingManagerEnable, setIsLeasingManagerEnable] = useState(offerLeasingManagerEnable);
  const [isTestOffer, setIsTestOffer] = useState(offerForTesting);
  const [offerDate, setOfferDate] = useState(offerManualDate);
  const [updatingLeasing, setUpdatingLeasing] = useState(false);
  const [updatingDate, setUpdatingDate] = useState(false);
  const [updatingTesting, setUpdatingTesting] = useState(false);
  // Handlers
  const handleChangeLeasing = (event) => {
    setIsLeasingManagerEnable(event.target.checked);
    setUpdatingLeasing(true);

    const data = {
      isLeasingManagerEnable: event.target.checked,
    }
    if(!offerUpdating && updateOffer && offerID) {
      updateOffer(offerID, data);
    }
  };
  const handleChangeTest = (event) => {
    setIsTestOffer(event.target.checked);
    setUpdatingTesting(true);

    const data = {
      forTesting: event.target.checked,
    }
    if(!offerUpdating && updateOffer && offerID) {
      updateOffer(offerID, data);
    }
  };
  const handleChangeDate = (event) => {
    setOfferDate(event.target.value);
    setUpdatingDate(true);

    const data = {
      offerDate: event.target.value,
    }
    if(!offerUpdating && updateOffer && offerID) {
      updateOffer(offerID, data);
    }
  }
  const handleDateFormat = (date) => {
    return toDateFormat(date, false, false, false, "reverse").replaceAll(".", "-");
  }
  const handleOpenDatePicker = () => {
    datePickerRef.current.showPicker();
  }
  // On Values changed
  useEffect(() => {
    if(offerLeasingManagerEnable !== isLeasingManagerEnable) {
      setIsLeasingManagerEnable(offerLeasingManagerEnable);
    }
    if(offerManualDate !== offerDate) {
      setOfferDate(offerManualDate);
    }
    if(offerForTesting !== isTestOffer) {
      setIsTestOffer(offerForTesting);
    }
  }, [offerLeasingManagerEnable, offerManualDate, isLeasingManagerEnable, offerDate, offerForTesting, isTestOffer]);
  // On Updating
  useEffect(() => {
    if(updatingLeasing && !offerUpdating) {
      setUpdatingLeasing(false);
    }
    else if(updatingDate && !offerUpdating) {
      setUpdatingDate(false);
    }
    else if(updatingTesting && !offerUpdating) {
      setUpdatingTesting(false);
    }
  }, [offerUpdating, updatingLeasing, updatingDate, updatingTesting]);

  return(
    <div className={styles.offerSettingsContainer}>
      <PropertySectionTitle
        title={<FormattedMessage id="VOCABULARY.Settings"/>}
        style={{
          marginBottom: "2.4rem"
        }}
      />
      <div className={styles.helpersInfoWrapper}>
        <div className={styles.helperItem}>
          <span>
            <IconColorMUI
              icon="info_outline"
              color="default"
              size="16px"
            />
          </span>
          <p><FormattedMessage id="VOCABULARY.Name will not be visible in the Offer."/></p>
        </div>
        <div className={styles.helperItem}>
          <span>
            <IconColorMUI
              icon="info_outline"
              color="default"
              size="16px"
            />
          </span>
          <p><FormattedMessage id="VOCABULARY.Optional description will be visible in the Offer."/></p>
        </div>
        <IsAdmin markup="font">
          <div className={styles.helperItem}>
            <span>
              <IconColorMUI
                icon="info_outline"
                color="default"
                size="16px"
              />
            </span>
            <p><FormattedMessage id="VOCABULARY.Test offer can only be created by administrator"/></p>
          </div>
        </IsAdmin>
      </div>
      <div className={styles.settingsWrapper}>
        <IsAdmin markup="input">
          <div className={styles.settingsItem}>
            <label><FormattedMessage id="VOCABULARY.Test offer"/></label>
            {updatingTesting ? <LoadingScreen loaderStyle={{width: "1.5rem", height: "1.5rem"}}/> :
              <InputSwitcher
                checked={isTestOffer}
                value="isTestOffer"
                onChange={handleChangeTest}
                style={{margin: 0}}
              />
            }
          </div>
        </IsAdmin>
        <div className={styles.settingsItem}>
          <label><FormattedMessage id="VOCABULARY.Leasing Manager contact details"/></label>
          {updatingLeasing ? <LoadingScreen loaderStyle={{width: "1.5rem", height: "1.5rem"}}/> :
            <InputSwitcher
              checked={isLeasingManagerEnable}
              value="isContactDataEnable"
              onChange={handleChangeLeasing}
              style={{margin: 0}}
            />
          }
        </div>
        <div className={styles.settingsItem}>
          <label><FormattedMessage id="VOCABULARY.Offer date"/></label>
          {updatingDate ? <LoadingScreen loaderStyle={{width: "1.5rem", height: "1.5rem"}}/> :
            <span className={styles.dateField}>
              <input
                ref={datePickerRef}
                type="date"
                name="offerDate"
                value={handleDateFormat(offerDate)}
                onChange={handleChangeDate}
                placeholder="dd-mm-yyyy"
              />
              <span className={styles.datePickerTrigger} onClick={() => handleOpenDatePicker()}/>
            </span>
          }
        </div>
      </div>
    </div>
  );
}
import React from "react";
import ModalCustom from "../../../../partials/components/ModalCustom";
import {FormattedMessage} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {addLeadProperties, fetchLeadsPreview} from "../../../../crud/leadsManagement.crud";
import LoadingScreen from "../../../../partials/components/LoadingScreen";
import APPLICATION_VIEWS from "../../../../constants/APPLICATION_VIEWS";
import _ from "lodash";
import AutocompleteCustom from "../../../../partials/components/AutocompleteCustom";
import Button from "../../../../partials/components/Button";
import Icon from "../../../../partials/components/Icon";

class LeadsManagementAddProperty extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      open: false,
      leadID: undefined,
      selectedLead: undefined,
    };
  }

  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleChange = (value) => {
    this.setState({
      selectedLead: value,
    });
  };
  handleSave = () => {
    const {addLeadProperties, propertyID, propertyData, isUnitView, isOfficeUnitView, isIndustrialBuildingsView, currentApplicationView} = this.props;
    const {selectedLead} = this.state;
    const leadID = selectedLead?.id;

    if (leadID && propertyID && addLeadProperties) {
      const properties = Array.isArray(propertyID) ? propertyID : [propertyID];
      const isPropertiesObjects = properties.every(i => typeof i === "object");
      const propertiesResponse = isPropertiesObjects ? properties?.map(item => item?.id) : properties;
      let unitParentsIDS = [];
      let industrialBuildingsParentsIDS = [];
      let finalProperties = propertiesResponse;
      // Handle unit select
      if(isUnitView && propertyData) {
        const selectedUnitsData = propertyData?.filter(item => propertiesResponse?.includes(item?.id));
        selectedUnitsData.map(unitData => unitParentsIDS.push(unitData?.office?.id));

        if(!_.isEmpty(unitParentsIDS)) {
          finalProperties = _.uniq(unitParentsIDS);
        }
      }
      if(isOfficeUnitView && propertyData) {
        const selectedUnitsData = propertyData?.filter(item => propertiesResponse?.includes(item?.id));
        selectedUnitsData.map(unitData => unitParentsIDS.push(unitData?.office));

        if(!_.isEmpty(unitParentsIDS)) {
          finalProperties = _.uniq(unitParentsIDS);
        }
      }
      else if(isIndustrialBuildingsView && propertyData) {
        const selectedUnitsData = propertyData?.filter(item => propertiesResponse?.includes(item?.id));
        selectedUnitsData.map(unitData => industrialBuildingsParentsIDS.push(unitData?.park));

        if(!_.isEmpty(industrialBuildingsParentsIDS)) {
          finalProperties = _.uniq(industrialBuildingsParentsIDS);
        }
      }
      // Create data to send
      const data = {
        ...currentApplicationView === APPLICATION_VIEWS.OFFICES ? {"offices": finalProperties} : {},
        ...currentApplicationView === APPLICATION_VIEWS.INDUSTRIAL ? {"parks": finalProperties} : {}
      };
      addLeadProperties(leadID, data);
    }
    this.handleCloseModal();
  };

  render() {
    const {loadingLeads, leads, fetchLeadsPreview, btnListMode, btnMode, tooltip, tooltipPosition = "right"} = this.props;
    const {selectedLead} = this.state;
    const leadsOptions = leads?.map((lead) => {
      const areaRange = [
        ...lead?.crmAreaFrom ? [lead?.crmAreaFrom] : [],
        ...lead?.crmAreaTo ? [lead?.crmAreaTo] : []
      ].join("-");
      const location = [
        ...lead?.market ? [lead?.market] : [],
        ...lead?.region ? [lead?.region] : []
      ].join(", ");
      const combinedLabel = [
        ...lead?.crmEmail ? [lead?.crmEmail] : [],
        areaRange,
        location
      ].join(" | ");
      const label = lead?.leadName ? lead?.leadName : combinedLabel;

      return {
        id: lead?.id,
        name: label
      };
    });
    const leadID = selectedLead?.id;

    return (
      <ModalCustom
        ref={this.child}
        btn={
        btnMode ?
          <Button
            color={"admin"}
            textIcon={<Icon icon={"add-clean"}/>}
            tooltip={tooltip}
            tooltipPlacement={tooltipPosition}
          >
            <FormattedMessage id="LM.PHRASE.ADD_TO_LEAD"/>
          </Button> :
          <Button
            type={btnListMode ? "plainText" : "icon"}
            color={"admin"}
            tooltip={tooltip || <FormattedMessage id="LM.PHRASE.ADD_TO_LEAD"/>}
            tooltipPlacement={tooltipPosition}
          >
            {btnListMode ? <FormattedMessage id="LM.PHRASE.ADD_TO_LEAD"/> :
            <Icon icon={"envelope-add"}/>
            }
          </Button>
        }
        btnConfirmDisabled={!leadID}
        title={<FormattedMessage id="LM.PHRASE.ADD_PROPERTY_TO_LEAD"/>}
        fetch={fetchLeadsPreview}
        handleSave={this.handleSave}
      >
        {loadingLeads ? <LoadingScreen/> :
          <AutocompleteCustom
            name="lead"
            value={selectedLead || undefined}
            options={leadsOptions}
            optionLabelKey="name"
            inputLabel={<FormattedMessage id="LM.PHRASE.SELECT_LEAD" />}
            inputValue={selectedLead?.name}
            onChange={(event, value) => this.handleChange(value)}
          />
        }
      </ModalCustom>
    );
  }
}

const mapStateToProps = ({leadsManagement, user}) => ({
  leads: leadsManagement?.leadsPreview,
  loadingLeads: leadsManagement?.loadingLeadsPreview,
  adding: leadsManagement?.adding,
  currentApplicationView: user?.data?.currentApplicationView
});
const mapDispatchToProps = {
  fetchLeadsPreview: () => fetchLeadsPreview(),
  addLeadProperties: (leadID, data) => addLeadProperties(leadID, data)
};
export default connect(mapStateToProps, mapDispatchToProps)(LeadsManagementAddProperty);
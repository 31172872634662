import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import WarehousesPage from "./WarehousesPage";
import {
  fetchIndustrialPark,
  fetchIndustrialParkUnits,
  fetchIndustrialWarehouses,
  fetchIndustrialWarehousesClusters
} from "app/crud/estates/industrial/industrial.crud";
import {filterReset} from "app/crud/estates/filters.crud";
import {setLatestActiveTablePage} from "app/crud/tablePage.crud";
import {callReddClickEvent} from "app/crud/user.crud";

const mapStateToProps = store => ({
    items: store.industrial.warehousesItems,
    itemsCount: store.industrial.warehousesItemsCount,
    itemsPerPage: store.industrial.warehousesItemsPerPage,
    total: store.industrial.warehousesTotal,
    loading: store.industrial.warehousesLoading,
    loaded: store.industrial.warehousesLoaded,
    viewType: store.industrial.viewTypeWarehouses,
    clusters: store.industrial.warehousesClusters,
    clustersLoaded: store.industrial.warehousesClustersLoaded,
    clustersLoading: store.industrial.warehousesClustersLoading,
    park: store.industrial.park,
    parkLoading: store.industrial.parkLoading,
    allUserStates: store.user,
    parkUnits: store.industrial.parkUnits,
    parkUnitsLoading: store.industrial.parkUnitsLoading,
    parkUnitsLoaded: store.industrial.parkUnitsLoaded,
});
const mapDispatchToProps = {
    fetchIndustrialWarehouses: ({params}) => fetchIndustrialWarehouses(params),
    fetchIndustrialWarehousesClusters: ({params}) => fetchIndustrialWarehousesClusters(params),
    fetchIndustrialPark: (parkID) => fetchIndustrialPark(parkID),
    fetchIndustrialParkUnits: (parkID) => fetchIndustrialParkUnits(parkID),
    filterReset: (filtersToOmit) => filterReset(filtersToOmit),
    setLatestActiveTablePage: (page) => setLatestActiveTablePage(page),
    callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params)
};
export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(WarehousesPage)
);
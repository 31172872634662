import React from 'react';
import { Document } from '@react-pdf/renderer';
import OfferPdfBuildingPage from "./pages/OfferPdfBuildingPage";
import OfferPdfSummaryPage from "./pages/OfferPdfSummaryPage";

// Create Document Component
function OfferPdfDocument(props){
  const buildings = props?.data?.assets;
  const isSingleProperty = buildings?.length === 1;
  let order = ["summary", "buildings"];

  if(isSingleProperty) {
    order = ["buildings", "summary"];
  }

  return (
    <Document>
      {order?.map((item, index) => {
        if(item === "summary") {
          return(
            <React.Fragment key={`summary-${index}`}>
              <OfferPdfSummaryPage
                data={props?.data}
                isSingleProperty={isSingleProperty}
                {...props}
              />
            </React.Fragment>
          );
        }
        else if(item === "buildings") {
          return(
            <React.Fragment key={`buildings-${index}`}>
              {buildings?.map((building, index) => (
                <React.Fragment key={`building-${index}`}>
                  <OfferPdfBuildingPage
                    data={props?.data}
                    building={building}
                    isSingleProperty={isSingleProperty}
                    {...props}
                  />
                </React.Fragment>
              ))}
            </React.Fragment>
          );
        }
        return null;
      })}
    </Document>
  );
};

export default OfferPdfDocument;
import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import ParksPage from "./ParksPage";
import {
  fetchIndustrialPark,
  fetchIndustrialParks,
  fetchIndustrialParksClusters,
  fetchIndustrialParkWarehouses
} from "app/crud/estates/industrial/industrial.crud";
import {setLatestActiveTablePage} from "app/crud/tablePage.crud";
import {filterByModules, filterReset} from "../../../crud/estates/filters.crud";
import {callReddClickEvent} from "app/crud/user.crud";

const mapStateToProps = store => ({
    items: store.industrial.parksItems,
    itemsCount: store.industrial.parksItemsCount,
    itemsPerPage: store.industrial.parksItemsPerPage,
    total: store.industrial.parksTotal,
    loading: store.industrial.parksLoading,
    loaded: store.industrial.parksLoaded,
    viewType: store.industrial.viewType,
    clusters: store.industrial.parkClusters,
    clustersLoaded: store.industrial.parkClustersLoaded,
    clustersLoading: store.industrial.parkClustersLoading,
    park: store.industrial.park,
    parkLoading: store.industrial.parkLoading,
    allUserStates: store.user,
    parkWarehouses: store.industrial.parkWarehouses,
    parkWarehousesLoading: store.industrial.parkWarehousesLoading,
    parkWarehousesLoaded: store.industrial.parkWarehousesLoaded,
});
const mapDispatchToProps = {
    fetchIndustrialParks: ({params}) => fetchIndustrialParks(params),
    fetchIndustrialParksClusters: ({params}) => fetchIndustrialParksClusters(params),
    fetchIndustrialPark: (parkID) => fetchIndustrialPark(parkID),
    fetchIndustrialParkWarehouses: (parkID) => fetchIndustrialParkWarehouses(parkID),
    filterReset: (filtersToOmit) => filterReset(filtersToOmit),
    filterByModules: (params) => filterByModules(params),
    setLatestActiveTablePage: (page) => setLatestActiveTablePage(page),
    callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params)
};
export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ParksPage)
);
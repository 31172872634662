import store from "../../../store/store";

export function isFiltersActive() {
    const excludedFilters = [
        "forceUpdateChilds",
        "isEditable",
        "isFilterSet",
        "order",
        "orderBy",
        "pageReset",
        "size",
        "state",
        "markets",
        "industrialMarkets",
        "officeMarkets",
        "industrialAmenities",
        "year",
        "assetId"
    ];
    const filtersAsArray = Object.entries(store.getState().filters);
    const filtersDefaultArray = filtersAsArray.filter(item => !excludedFilters.includes(item[0]));
    const fieldsCondition = filtersDefaultArray.filter(item => item[0] === "market" && item[1]?.length > 0).length > 0;

    return fieldsCondition;
}
import React, {Component} from 'react';
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import styles from "./index.module.scss";
import cn from "classnames";

export default class TooltipCustom extends Component {
  render() {
    const {children, title, placement, disabled, childStyle, defaultCursor, underlineDisabled} = this.props;

    if(disabled) {
      return (
        <span
          className={cn(
            styles.labelWrapper,
            defaultCursor ? styles.defaultCursor : undefined,
            underlineDisabled ? styles.underlineDisabled : undefined
          )}
          style={childStyle}
        >{children}</span>
      );
    }
    return (
      <OverlayTrigger
        placement={placement || "top"}
        overlay={<Tooltip id={`id-${Math.random(100)}`}>{title}</Tooltip>}
      >
        <span
          className={cn(
            styles.labelWrapper,
            defaultCursor ? styles.defaultCursor : undefined,
            underlineDisabled ? styles.underlineDisabled : undefined
          )} 
          style={childStyle}
        >{children}</span>
      </OverlayTrigger>
    );
  }
}
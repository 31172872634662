import React from "react";
import TableCell from "@material-ui/core/TableCell/TableCell";
import ShortlistRemoveUnits from "app/partials/components/Shortlists/ShortlistRemoveUnits";
import UnitFloorPreview from "app/partials/components/_DataParts/UnitFloorPreview";
import UnitAreaPreview from "app/partials/components/_DataParts/UnitAreaPreview";
import UnitTypePreview from "app/partials/components/_DataParts/UnitTypePreview";
import UnitAvailabilityPreview from "app/partials/components/_DataParts/UnitAvailabilityPreview";
import UnitStatusPreview from "app/partials/components/_DataParts/UnitStatusPreview";
import BuildingCardPreview from "app/partials/components/_DataParts/BuildingCardPreview";
import UpdatedAtPreview from "app/partials/components/_DataParts/UpdatedAtPreview";
import UnitForecastPremium from "app/partials/components/Units/UnitForecastPremium";
import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";

class TableOffersOfficeUnitsCells extends React.Component {
  render() {
    const { row, shortlistId } = this.props;
    return (
      <>
        <TableCell component="th" id={row.id} scope="row">
          <BuildingCardPreview
            name={row.office.name}
            street={row.office.addressStreet}
            thumb={row.office.mainPhotoThumbnail}
            officeID={row.office.id}
            marketID={row?.office?.marketId}
          />
        </TableCell>
        <TableCell align="right">
          <UnitAreaPreview data={row} />
        </TableCell>
        <TableCell align="right">
          <UnitFloorPreview floor={row.floor} />
        </TableCell>
        <TableCell align="right">
          <UnitTypePreview
            unitType={row.unitType}
            isSublease={row.isSublease}
          />
        </TableCell>
        <TableCell align="right">
          <UnitStatusPreview unitStatus={row.status} />
        </TableCell>
        <TableCell align="right">
          <UnitAvailabilityPreview date={row.availableFrom} />
        </TableCell>
        <TableCell>
          <UpdatedAtPreview level={row.updateLevel} date={row.updatedAt} alternative />
        </TableCell>
        <TableCell align="right">
          <UnitForecastPremium
            unit={row}
            area={row?.area}
            officeData={row?.office}
          />
        </TableCell>
        <TableCell align="right">
          <ShortlistRemoveUnits
            shortlistId={shortlistId}
            units={[row.id]}
          />
        </TableCell>
      </>
    );
  }
}

export default injectIntl(connect(null, null)(TableOffersOfficeUnitsCells));

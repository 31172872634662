// FETCH DATA
export const fetchPropertyParticulars = (teamID, email, market) => ({
  type: 'FETCH_INDUSTRIAL_PROPERTY_PARTICULARS_REQUEST',
  payload: {
      teamID,
      email,
      market
  },
});
export const fetchPropertyParticularsProperty = (teamID, propertyID, email) => ({
  type: 'FETCH_INDUSTRIAL_PROPERTY_PARTICULARS_PROPERTY_REQUEST',
  payload: {
      teamID,
      propertyID,
      email
  },
});
export const fetchPropertyParticularsPropertyContact = (teamID, propertyID, email) => ({
  type: 'FETCH_INDUSTRIAL_PROPERTY_PARTICULARS_PROPERTY_CONTACT_REQUEST',
  payload: {
      teamID,
      propertyID,
      email
  },
});
export const fetchPropertyParticularsBranding = (teamID) => ({
  type: 'FETCH_INDUSTRIAL_PROPERTY_PARTICULARS_BRANDING_REQUEST',
  payload: {
      teamID
  },
});
export const fetchPropertyParticularsListPhotos = (teamID, email) => ({
  type: 'FETCH_INDUSTRIAL_PROPERTY_PARTICULARS_LIST_PHOTOS_REQUEST',
  payload: {
      teamID,
      email
  },
});
export const fetchPropertyParticularsPropertiesListManage = (teamID) => ({
  type: 'FETCH_INDUSTRIAL_PROPERTY_PARTICULARS_PROPERTIES_LIST_MANAGE_REQUEST',
  payload: {
      teamID
  }
});
export const fetchPropertyParticularsAnalytics = (teamID) => ({
  type: 'FETCH_INDUSTRIAL_PROPERTY_PARTICULARS_ANALYTICS_REQUEST',
  payload: teamID
});
export const fetchPropertyParticularsAnalyticsCharts = (teamID, year) => ({
  type: 'FETCH_INDUSTRIAL_PROPERTY_PARTICULARS_ANALYTICS_CHARTS_REQUEST',
  payload: {
    teamID,
    year
  }
});
export const fetchIndustrialPropertyParticularsAnalyticsDetails = (teamID, propertyID) => ({
  type: 'FETCH_INDUSTRIAL_PROPERTY_PARTICULARS_ANALYTICS_DETAILS_REQUEST',
  payload: {
    teamID,
    propertyID
  }
});
// USER ACTIONS
export const verifyPropertyParticularsAccess = (teamID, email) => ({
  type: 'VERIFY_INDUSTRIAL_PROPERTY_PARTICULARS_ACCESS_REQUEST',
  payload: {
      teamID,
      email
  },
});
export const verifyPropertyParticularsAccessReset = () => ({
  type: 'VERIFY_INDUSTRIAL_PROPERTY_PARTICULARS_ACCESS_RESET_REQUEST'
});
export const requestPropertyParticularsAccess = (teamID, email) => ({
  type: 'REQUEST_INDUSTRIAL_PROPERTY_PARTICULARS_ACCESS_REQUEST',
  payload: {
      teamID,
      email
  },
});
export const shareIndustrialPropertyParticulars = (data, teamID) => ({
  type: 'SHARE_INDUSTRIAL_PROPERTY_PARTICULARS_REQUEST',
  payload: {
      data,
      teamID
  },
});
export const sharePropertyParticularsRealTimeLog = (data) => ({
  type: 'SHARE_INDUSTRIAL_PROPERTY_PARTICULARS_REAL_TIME_LOG_REQUEST',
  payload: data,
});
export const shareUpdatedPropertyParticulars = (teamID) => ({
  type: 'SHARE_UPDATED_INDUSTRIAL_PROPERTY_PARTICULARS_REQUEST',
  payload: {
      teamID
  },
});
export const shareUpdatedPropertyParticularsRealTimeLog = (data) => ({
  type: 'SHARE_UPDATED_INDUSTRIAL_PROPERTY_PARTICULARS_REAL_TIME_LOG_REQUEST',
  payload: data,
});
export const setPropertyParticularsPropertiesAsVisible = (teamID, data) => ({
  type: 'SET_INDUSTRIAL_PROPERTY_PARTICULARS_PROPERTIES_AS_VISIBLE_REQUEST',
  payload: {
      teamID,
      data
  }
});

// RESET PROPERTY PARTICULARS LOADING STATUS
export const resetIndustrialPropertyParticularsLoadingStates = () => ({
  type: 'RESET_INDUSTRIAL_PROPERTY_PARTICULARS_LOADING_STATES_REQUEST'
});
export default {
    TYPE_AIRPORT: {
        id: 0,
        name: 'INDUSTRIAL.PHRASE.POI_TYPE.AIRPORT'
    },
    TYPE_CITY_CENTER: {
        id: 1,
        name: 'INDUSTRIAL.PHRASE.POI_TYPE.CITY_CENTER'
    },
    TYPE_RAILWAY_STATION: {
        id: 2,
        name: 'INDUSTRIAL.PHRASE.POI_TYPE.RAILWAY_STATION'
    },
    TYPE_MOTORWAY_JUNCTION: {
        id: 3,
        name: 'INDUSTRIAL.PHRASE.POI_TYPE.MOTORWAY_JUNCTION'
    },
    TYPE_MOTORWAY: {
        id: 4,
        name: 'INDUSTRIAL.PHRASE.POI_TYPE.MOTORWAY'
    }
};
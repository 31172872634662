import React from "react";
import {TABLE_CELLS} from "app/partials/components/TableProperties/index";
import {Grid, Typography} from "@material-ui/core";
import InputSwitcher from "app/partials/components/InputSwitcher";
import {FormattedMessage} from "react-intl";
import IndustrialParkAdd from "./components/IndustrialParkAdd";
import IndustrialParkWarehouseAdd from "./components/IndustrialParkWarehouseAdd";
import IndustrialWarehouseUnitAdd from "./components/IndustrialWarehouseUnitAdd";
import LackOfAvailableArea from "../../../LackOfAvailableArea/LackOfAvailableArea";
import IndustrialAddFile from "../../../../../pages/industrial/components/IndustrialFiles/IndustrialAddFile";
import axios from "axios";
import store from "../../../../../store/store";

export default class TableActionsIndustrial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showUnavailable: false,
      parkFiles: [],
      showOwned: store.getState().filters && store.getState().filters.isEditable === "true"
    };
  }

  componentDidMount() {
    const isEditableFilterOn = store.getState().filters && store.getState().filters.isEditable === "true";
    if(this.state.showOwned !== isEditableFilterOn) {
      this.setState({
        showOwned: isEditableFilterOn
      });
    }
    // Fetch files
    if (this.props?.parentData?.park) {
      axios
        .get(`/industrial-parks/${this.props?.parentData?.park}/files/`)
        .then((res) => {
          this.setState((s) => ({ ...s, parkFiles: Object.values(res.data) }));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  handleChangeSwitcher = (name) => (event) => {
    const { checked } = event.target;

    this.setState(
      (s) => ({ ...s, [name]: checked }),
      () => {
        if (name === "showUnavailable") {
          this.props.onShowUnavailableUnits(this.state.showUnavailable);
        }
        else if (name === "showOwned") {
          this.props.onShowOwnedBuildings(this.state.showOwned);
        }
      }
    );
  };

  render() {
    const {
      dataTarget,
      parentId,
      parentData,
      dataSource
    } = this.props;
    const {showOwned} = this.state;

    return (
      <>
        {(dataTarget === TABLE_CELLS.INDUSTRIAL_PARKS || dataTarget === TABLE_CELLS.INDUSTRIAL_BUILDINGS) &&
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <InputSwitcher
              checked={showOwned}
              value="showOwned"
              label={<FormattedMessage id="GENERAL.PHRASE.SHOW_OWNED_PROPERTIES"/>}
              onChange={this.handleChangeSwitcher("showOwned")}
            />
          </Grid>
          {dataTarget === TABLE_CELLS.INDUSTRIAL_PARKS && (
            <Grid item>
              <IndustrialParkAdd />
            </Grid>
          )}
          {dataTarget === TABLE_CELLS.INDUSTRIAL_BUILDINGS && parentData?.isEditable && (
            <Grid item>
              <IndustrialParkWarehouseAdd/>
            </Grid>
          )}
        </Grid>
        }
        {dataTarget === TABLE_CELLS.INDUSTRIAL_PARK_WAREHOUSES && parentData?.isEditable && (
          <Grid container spacing={2} alignItems="center" wrap="nowrap">
            <Grid item>
              <IndustrialParkWarehouseAdd
                parkID={parentId}
                parentData={parentData}
              />
            </Grid>
          </Grid>
        )}
        {dataTarget === TABLE_CELLS.INDUSTRIAL_BUILDING_UNITS && (
          <Grid container spacing={2} alignItems="center" wrap="nowrap">
            {parentData?.isEditable &&
            <Grid item>
                <Grid container spacing={2} alignItems="center" wrap="nowrap">
                  <Grid item>
                    <Typography>
                      <FormattedMessage id="GENERAL.PHRASE.SHOW_UNAVAILABLE" />
                    </Typography>
                  </Grid>
                  <Grid item>
                    <InputSwitcher
                      checked={this.state.showUnavailable}
                      value="showUnavailable"
                      label={<FormattedMessage id="GENERAL.PHRASE.YES" />}
                      onChange={this.handleChangeSwitcher("showUnavailable")}
                    />
                  </Grid>
                </Grid>
              </Grid>
            }
            {(
              parentData?.numberOfUnitsAvailable <= 0 ||
              parentData?.lackOfAvailableArea
            ) && parentData?.isEditable && (
              <Grid item>
                <LackOfAvailableArea
                  propertyID={parentData?.id}
                  lackOfAvailableArea={parentData?.lackOfAvailableArea}
                  buildingAvailableFrom={parentData?.buildingAvailableFrom}
                  target="industrial"
                  tooltipPosition="bottom"
                />
              </Grid>
            )}
            {parentData?.isEditable &&
            <Grid item>
              <IndustrialWarehouseUnitAdd parentID={parentId} lackOfAvailableArea={parentData?.lackOfAvailableArea} />
            </Grid>
            }
          </Grid>
        )}
        {dataTarget === TABLE_CELLS.INDUSTRIAL_FILES && parentData?.isEditable && (
          <Grid item>
            <IndustrialAddFile
              propertyID={parentId}
              dataSource={dataSource}
            />
          </Grid>
        )}
      </>
    );
  }
}

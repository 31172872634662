import React from "react";
import styles from "../../../../../common/external/components/index.module.scss";
import {toAbsoluteUrl} from "_metronic";
import Slider from "react-slick/lib";
import IconColorMUI from "app/partials/components/IconColorMUI";
import clsx from "clsx";
import ROUTES from "app/constants/ROUTES";
import {Link, Redirect} from "react-router-dom";
import {FormattedMessage, injectIntl} from "react-intl";
import OffersOnlineHeader from "../OffersOnlineHeader";
import LoadingScreen from "app/partials/components/LoadingScreen";
import {handleEmptyValue, toNumberFormat,} from "app/utils/helpers";
import UnitAvailabilityPreview from "../../../../../../partials/components/_DataParts/UnitAvailabilityPreview";
import NoData from "../../../../../../partials/components/NoData/NoData";
import connect from "react-redux/es/connect/connect";
import {callReddClickEvent} from "app/crud/user.crud";

class ListItemAccordion extends React.Component {
  constructor(props) {
    super(props);
    const isMobile = window.outerWidth < 760;
    this.state = {
      collapsed: isMobile,
    };
  }

  handleToggleCollapse = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    const {title, children} = this.props;
    const {collapsed} = this.state;
    return (
      <div className={styles.accordionWrapper}>
        <div
          className={styles.accordionHeader}
          onClick={() => this.handleToggleCollapse()}
        >
          {title}
          <IconColorMUI
            icon={collapsed ? "add" : "remove"}
            style={{color: "var(--brand-btn-bg-color)"}}
          />
        </div>
        <div
          className={clsx(
            styles.accordionContent,
            collapsed && styles.collapsed
          )}
        >
          {children}
        </div>
      </div>
    );
  }
}

class OffersOnlineList extends React.Component {
  constructor(props) {
    super(props);
    this.propertiesWrapper = React.createRef();
    this.propertyItem = React.createRef();
    this.propertiesContainer = React.createRef();
    this.state = {
      btnNavPrev: false,
      btnNavNext: true,
      btnNavPause: false,
      scrollPosition: 0,
      isFetchDataComplete: false,
      isReady: false
    };
    // Fetch Data on Init
    const offerID =
      props.match && props.match.params && props.match.params.offer;
    const authEmail = handleEmptyValue(localStorage.getItem("offerMail"), true);

    if (offerID && !props.loading) {
      props.onFetchData(offerID, authEmail);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {onFetchPhotos, branding, match} = this.props;
    const offerHash = match?.params?.offer;
    const offerID = branding?.offerId;
    const authEmail = handleEmptyValue(localStorage.getItem("offerMail"), true);

    if (
      (prevProps.items &&
        this.props.items &&
        prevProps.items.length &&
        this.props.items.length &&
        prevProps.items[0].pk !== this.props.items[0].pk) ||
      (prevProps.items &&
        prevProps.items.length &&
        this.props.items &&
        !this.props.items.length) ||
      (prevProps.items &&
        !prevProps.items.length &&
        this.props.items &&
        this.props.items.length) ||
      (prevProps.items && !this.props.items) ||
      (!prevProps.items && this.props.items)
    ) {
      this.setState(
        {
          isFetchDataComplete: true,
        },
        () => onFetchPhotos(offerHash, authEmail)
      );
    }
    // Call Tracking Event - Page Visited
    if (!this.state.isReady && offerID && this.state.isFetchDataComplete) {
      this.setState({
        isReady: true
      }, () => {
        if(offerID) {
          this.props.dispatch(callReddClickEvent(
            "offers-details",
            "offer",
            null,
            offerID,
            authEmail ? {email: authEmail} : null
          ));
        }
      });
    }
  }

  handleListHorizontalScroll = (direction) => {
    const scrollElement = this.propertiesWrapper;
    const scrollValue =
      this.propertyItem.current.offsetWidth +
      Number(
        window
          .getComputedStyle(this.propertyItem.current)
          .getPropertyValue("margin-right")
          .replace("px", "")
      );
    const wrapperWidth =
      this.propertiesContainer.current.offsetWidth +
      Number(
        window
          .getComputedStyle(this.propertiesContainer.current)
          .getPropertyValue("margin-right")
          .replace("px", "")
      );
    const totalWidth =
      scrollElement.current.offsetWidth +
      Number(
        window
          .getComputedStyle(scrollElement.current)
          .getPropertyValue("margin-right")
          .replace("px", "")
      );
    const maxScroll =
      Math.floor((totalWidth - (wrapperWidth - scrollValue)) / 100) * 100;
    const currentPos = window
      .getComputedStyle(scrollElement.current)
      .getPropertyValue("-webkit-transform")
      .split(/[()]/)[1];
    const currentX = currentPos.split(",")[4];

    // Block button until animation is finished
    this.setState(
      {
        btnNavPause: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            btnNavPause: false,
          });
        }, 400);
      }
    );

    // Handle PREV scroll
    if (direction === "prev") {
      const newX = Number(currentX) + Number(scrollValue);

      if (-newX <= maxScroll) {
        this.setState({
          btnNavNext: true,
          scrollPosition: newX,
        });
      }
      if (newX >= 0) {
        this.setState({
          btnNavPrev: false,
        });
      }
    }
    // Handle NEXT scroll
    else if (direction === "next") {
      const newX = Number(currentX) - Number(scrollValue);

      if (-newX <= maxScroll) {
        this.setState({
          btnNavPrev: true,
          scrollPosition: newX,
        });
      }
      if (-newX >= maxScroll) {
        this.setState({
          btnNavNext: false,
        });
      }
    }
  };

  render() {
    const {
      match,
      items,
      loading,
      branding,
      intl,
      onFetchData,
      photos,
      loadingPhotos,
      isSingleProperty,
    } = this.props;
    const {
      btnNavPrev,
      btnNavNext,
      btnNavPause,
      scrollPosition,
      isFetchDataComplete,
    } = this.state;
    const offerID = match && match.params && match.params.offer;
    const hasBuildings = items && items.length > 0;
    const isDouble = hasBuildings && items.length === 2;
    const isTriple = hasBuildings && items.length === 3;
    const isQuadruple = hasBuildings && items.length === 4;
    const isSliderActive =
      hasBuildings &&
      !isSingleProperty &&
      !isDouble &&
      !isTriple &&
      !isQuadruple;

    const slickUnitsSettings = {
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      slideToShow: 1,
      slidesToScroll: 1,
    };

    if (!loading && isSingleProperty && isFetchDataComplete) {
      return <Redirect to={`${match.url}${items[0].pk}/`}/>;
    }

    return (
      <div className={styles.mainContainer}>
        <OffersOnlineHeader
          view="list"
          branding={branding}
          offerID={offerID}
          totalItems={items && items.length}
          onFilterMarket={onFetchData}
        />
        {loading ? (
          <LoadingScreen
            brand
            brandColor={branding.offerBgColor || "#4a9dfd"}
          />
        ) : (
          <div
            ref={this.propertiesContainer}
            className={clsx(
              styles.propertiesContainer,
              isSliderActive && styles.sliderActive
            )}
          >
            {hasBuildings ? (
              <div
                ref={this.propertiesWrapper}
                className={clsx(
                  styles.propertiesWrapper,
                  !isSliderActive && styles.sliderNotActive
                )}
                style={{transform: `translateX(${scrollPosition}px)`}}
              >
                {hasBuildings &&
                  items.map((item, index) => {
                    const officeAddress = [
                      ...(item.addressStreet ? [item.addressStreet] : []),
                      ...(item.district ? [item.district] : []),
                      ...(item.addressCity ? [item.addressCity] : []),
                    ];
                    const officeCompletionYear = [
                      ...(item.commissioningQuarter
                        ? [`Q${item.commissioningQuarter}`]
                        : []),
                      ...(item.commissioningYear
                        ? [item.commissioningYear]
                        : []),
                    ];
                    const officeRentRange = [
                      ...(item.rentFromEur ? [toNumberFormat(item.rentFromEur)] : []),
                      ...(item.rentToEur ? [toNumberFormat(item.rentToEur)] : []),
                    ];
                    const officeRetailRentRange = [
                      ...(item.retailRentPriceEur
                        ? [toNumberFormat(item.retailRentPriceEur)]
                        : []),
                      ...(item.retailRentPriceToEur
                        ? [toNumberFormat(item.retailRentPriceToEur)]
                        : []),
                    ];
                    const propertyUrlSeparator =
                      match?.url.substr(-1) !== "/" ? "/" : "";
                    const propertyUrl = `${match?.url}${propertyUrlSeparator}${item?.pk}/`;

                    return (
                      <div
                        key={index}
                        ref={this.propertyItem}
                        className={clsx(
                          styles.propertyItem,
                          isSingleProperty && styles.singleItem,
                          isDouble && styles.doubleItem,
                          isTriple && styles.tripleItem,
                          isQuadruple && styles.quadrupleItem
                        )}
                      >
                        <div className={styles.propertyHeader}>
                          <h2>{handleEmptyValue(item.name, true)}</h2>
                          <p className={styles.propertyAddress}>
                            {officeAddress.join(", ")}
                          </p>
                        </div>
                        <div className={styles.propertySlider}>
                          {loadingPhotos ? (
                            <LoadingScreen
                              brand
                              brandColor={branding.offerBgFontColor || "#4a9dfd"}
                            />
                          ) : (
                            photos &&
                            photos.length > 0 &&
                            photos.filter((photo) => photo.officeId === item.pk)
                              .length && (
                              <img
                                key={index}
                                src={
                                  photos.filter(
                                    (photo) => photo.officeId === item.pk
                                  )[0].mainPhotoThumbnail
                                }
                                alt={item.name}
                              />
                            )
                          )}
                          {/*<div className={styles.mediaActions}>*/}
                          {/*    <span><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.3D_TOUR"/></span>*/}
                          {/*    <span><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.VIDEO"/></span>*/}
                          {/*</div>*/}
                        </div>
                        <div className={styles.propertyContent}>
                          <h3>
                            <FormattedMessage id="OFFERS.PHRASE.OFFICE_DETAILS"/>
                          </h3>
                          {item && item.units && item.units.length && (
                            <div className={styles.propertyUnitsSliderWrapper}>
                              <Slider {...slickUnitsSettings}>
                                {item.units.map((unit, index) => (
                                  <div
                                    key={index}
                                    className={styles.previewContainer}
                                  >
                                    <div className={styles.previewWrapper}>
                                      <div className={styles.previewItem}>
                                        <span className={styles.previewValue}>
                                          {toNumberFormat(unit.area, "area")}
                                        </span>
                                        <span className={styles.previewLabel}>
                                          <FormattedMessage id="OFFERS.PHRASE.UNIT_AREA"/>
                                        </span>
                                      </div>
                                      <div className={styles.previewItem}>
                                        <span className={styles.previewValue}>
                                          {toNumberFormat(unit.floor)}
                                        </span>
                                        <span className={styles.previewLabel}>
                                          <FormattedMessage id="OFFERS.PHRASE.UNIT_FLOOR"/>
                                        </span>
                                      </div>
                                      <div className={styles.previewItem}>
                                        <span className={styles.previewValue}>
                                          {handleEmptyValue(
                                            intl.formatMessage({
                                              id: unit.unitType,
                                            })
                                          )}
                                        </span>
                                        <span className={styles.previewLabel}>
                                          <FormattedMessage id="OFFERS.PHRASE.UNIT_TYPE"/>
                                        </span>
                                      </div>
                                      <div className={styles.previewItem}>
                                        <span className={styles.previewValue}>
                                          <UnitAvailabilityPreview
                                            date={unit.availableFrom}
                                          />
                                        </span>
                                        <span className={styles.previewLabel}>
                                          <FormattedMessage id="OFFERS.PHRASE.UNIT_AVAILABLE_FROM"/>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </Slider>
                            </div>
                          )}
                          <ListItemAccordion
                            title={
                              <h3>
                                <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.OFFICE_DETAILS"/>
                              </h3>
                            }
                          >
                            <div className={styles.dataWrapper}>
                              <div className={styles.dataItem}>
                                <span className={styles.dataValue}>
                                  {toNumberFormat(item.totalAreaNetto, "area")}
                                </span>
                                <span className={styles.dataLabel}>
                                  <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.NET_AREA"/>
                                </span>
                              </div>
                              <div className={styles.dataItem}>
                                <span className={styles.dataValue}>
                                  {toNumberFormat(item.totalAreaBrutto, "area")}
                                </span>
                                <span className={styles.dataLabel}>
                                  <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.GROSS_AREA"/>
                                </span>
                              </div>
                              <div className={styles.dataItem}>
                                <span className={styles.dataValue}>
                                  {handleEmptyValue(item.buildingClass)}
                                </span>
                                <span className={styles.dataLabel}>
                                  <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.BUILDING_CLASS"/>
                                </span>
                              </div>
                              <div className={styles.dataItem}>
                                <span className={styles.dataValue}>
                                  {officeCompletionYear.join(" ")}
                                </span>
                                <span className={styles.dataLabel}>
                                  <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.COMPLETION_YEAR"/>
                                </span>
                              </div>
                              <div className={styles.dataItem}>
                                <span className={styles.dataValue}>
                                  {handleEmptyValue(item.certificate)}
                                </span>
                                <span className={styles.dataLabel}>
                                  <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.CERTIFICATE"/>
                                </span>
                              </div>
                              <div className={styles.dataItem}>
                                <span className={styles.dataValue}>
                                  <UnitAvailabilityPreview
                                    date={item.buildingAvailableFrom}
                                  />
                                </span>
                                <span className={styles.dataLabel}>
                                  <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.AVAILABLE_FROM"/>
                                </span>
                              </div>
                            </div>
                          </ListItemAccordion>
                          <ListItemAccordion
                            title={
                              <h3>
                                <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.LEASE_TERMS"/>
                              </h3>
                            }
                          >
                            <div className={styles.dataWrapper}>
                              <div className={styles.dataItem}>
                                <span className={styles.dataValue}>
                                  {toNumberFormat(
                                    officeRentRange.join(" - "),
                                    "range",
                                    "€"
                                  )}
                                </span>
                                <span className={styles.dataLabel}>
                                  <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.OFFICE_RENT"/>
                                </span>
                              </div>
                              <div className={styles.dataItem}>
                                <span className={styles.dataValue}>
                                  {toNumberFormat(
                                    officeRetailRentRange.join(" - "),
                                    "range",
                                    "€"
                                  )}
                                </span>
                                <span className={styles.dataLabel}>
                                  <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.RETAIL_RENT"/>
                                </span>
                              </div>
                              <div className={styles.dataItem}>
                                <span className={styles.dataValue}>
                                  {toNumberFormat(
                                    item.serviceChargesPln,
                                    "currency",
                                    "PLN"
                                  )}
                                </span>
                                <span className={styles.dataLabel}>
                                  <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.SERVICE_CHARGE"/>
                                </span>
                              </div>
                              <div className={styles.dataItem}>
                                <span className={styles.dataValue}>
                                  {toNumberFormat(
                                    item.parkingGroundRentEur,
                                    "currency"
                                  )}
                                </span>
                                <span className={styles.dataLabel}>
                                  <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.PARKING_GROUND"/>
                                </span>
                              </div>
                              <div className={styles.dataItem}>
                                <span className={styles.dataValue}>
                                  {toNumberFormat(
                                    item.parkingUndergroundRentEur,
                                    "currency"
                                  )}
                                </span>
                                <span className={styles.dataLabel}>
                                  <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.PARKING_UNDERGROUND"/>
                                </span>
                              </div>
                              <div className={styles.dataItem}>
                                <span className={styles.dataValue}>
                                  {toNumberFormat(item.commonAreaFactor)}
                                </span>
                                <span className={styles.dataLabel}>
                                  <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.COMMON_AREA_FACTOR"/>
                                </span>
                              </div>
                              <div className={styles.dataItem}>
                                <span className={styles.dataValue}>
                                  {handleEmptyValue(item.parkingFactor)}
                                </span>
                                <span className={styles.dataLabel}>
                                  <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.PARKING_FACTOR"/>
                                </span>
                              </div>
                              <div className={styles.dataItem}>
                                <span className={styles.dataValue}>
                                  {handleEmptyValue(item.minRentalYears)}
                                </span>
                                <span className={styles.dataLabel}>
                                  <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.MIN_RENTAL_PERIOD"/>
                                </span>
                              </div>
                            </div>
                          </ListItemAccordion>
                          {item.amenities && item.amenities.length ? (
                            <ListItemAccordion
                              title={
                                <h3>
                                  <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.AMENITIES"/>
                                </h3>
                              }
                            >
                              <div
                                className={clsx(
                                  styles.dataWrapper,
                                  styles.slim
                                )}
                              >
                                {item.amenities
                                  .sort((a, b) => b.length - a.length)
                                  .map((amenity, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className={styles.dataItem}
                                      >
                                        <span className={styles.itemChecked}>
                                          <IconColorMUI icon="done"/>
                                          {intl.formatMessage({id: amenity})}
                                        </span>
                                      </div>
                                    );
                                  })}
                              </div>
                            </ListItemAccordion>
                          ) : null}
                        </div>
                        <div className={styles.propertyActions}>
                          <Link to={propertyUrl}>
                            <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.MORE_INFO"/>
                          </Link>
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : (
              <NoData
                message={<FormattedMessage id="No buildings in the offer."/>}
              />
            )}
            {hasBuildings && isSliderActive && (
              <div className={styles.carouselNavigation}>
                <button
                  id="navPrev"
                  className={clsx(
                    styles.btnNav,
                    !btnNavPrev && styles.disabled,
                    btnNavPause && styles.inactive
                  )}
                  onClick={() => this.handleListHorizontalScroll("prev")}
                >
                  <IconColorMUI icon="arrow_back_ios" size={30}/>
                </button>
                <button
                  id="navNext"
                  className={clsx(
                    styles.btnNav,
                    !btnNavNext && styles.disabled,
                    btnNavPause && styles.inactive
                  )}
                  onClick={() => this.handleListHorizontalScroll("next")}
                >
                  <IconColorMUI icon="arrow_forward_ios" size={30}/>
                </button>
              </div>
            )}
          </div>
        )}
        <div className={styles.footerSection}>
          <span>
            Powered by{" "}
            <a
              href={ROUTES.REDD_LANDING_PAGE}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={toAbsoluteUrl("media/pp-mockup-media/logo-redd.png")}
                alt="redd property particulars"
              />
            </a>
          </span>
        </div>
      </div>
    );
  }
}

export default injectIntl(connect()(OffersOnlineList));

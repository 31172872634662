import React from "react";
import clsx from "clsx";
import styles from "./index.module.scss";

export default class SimpleDataPreviewContainer extends React.Component {
  render() {
    const {children, subscriptionsTheme} = this.props;
    return (
      <div className={clsx("kt-widget4", subscriptionsTheme && styles.subscriptionsTheme)}>
        {children}
      </div>
    );
  }
}

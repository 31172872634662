import React, {useEffect, useMemo, useRef} from "react";
import {Chart} from "chart.js";
import {Typography} from "@material-ui/core";
import NoData from "app/partials/components/NoData/NoData";

export default function PieChart({ title, desc, dataChart }) {
  const ref = useRef();
  const isEmpty = dataChart.length === 0;
  let chartColors = ['#c0392b', '#e74c3c', '#9b59b6', '#8e44ad', '#2980b9', '#3498db', '#1abc9c', '#16a085', '#2ecc71', '#f39c12', '#e67e22', '#bdc3c7', '#707b7c', '#34495e'];
  const sortData = dataChart.sort(function(a, b){return b.value - a.value});
  const chartData = sortData.map(function(item) { return item["value"]; });
  const chartLabels = sortData.map(function(item) { return item["label"]; });
  const colorsDiff = chartColors.length - chartData.length;
  chartColors.splice(-colorsDiff, colorsDiff);

  const data = useMemo(
    () => ({
      datasets: [{
					data: chartData,
					backgroundColor: chartColors,
					label: 'Dataset 1'
				}],
				labels: chartLabels
    }),
    [chartLabels, chartData, chartColors]
  );

  useEffect(() => {
    const chart = new Chart(ref.current, {
      type: 'pie',
			data,
			options: {
				responsive: true,
        legend: {
				  position: 'right'
        },
        tooltips: {
          callbacks: {
            title: function(tooltipItems, data) {
              return '';
            },
            label: function(tooltipItem, data) {
              return data.labels[tooltipItem.index];
            }
          }
        }
			}
    });

    return () => {
      chart.destroy();
    };
  }, [data]);
  return (
    <div className="kt-widget14 customPadding">
      <div className="kt-widget14__header kt-margin-b-30">
        {title && <Typography variant="h3">{title}</Typography>}
        <span className="kt-widget14__desc">{desc}</span>
      </div>
      {isEmpty && <NoData/>}
      <div className="kt-widget14__chart">
        <canvas ref={ref}/>
      </div>
    </div>
  );
}

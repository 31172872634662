import React, {useEffect, useMemo, useRef} from "react";
import {Chart} from "chart.js";
import {Grid, Typography} from "@material-ui/core";
import {injectIntl} from "react-intl";

function LineChart({
  title,
  desc,
  dataChart,
  labelsChart,
  height,
  scale,
  disableSorting,
  disabledXBorder,
  disabledYBorder,
  labelX,
  labelY,
  disableYLabels,
  disableXLabels,
  tooltipPrefix,
  gridLine,
  chartColors,
  roundValues,
  toolbar,
  titleMarginDisable,
  intl,
  paddingDisabled,
  preventRerender,
  customLabel,
}) {
  const ref = useRef();

  let chartLabels;
  let chartData;
  if (labelsChart) {
    chartData = dataChart;
    chartLabels = labelsChart;
  } else {
    const sortData = disableSorting
      ? dataChart
      : dataChart.sort(function(a, b) {
          return a.value - b.value;
        });
    chartData = sortData.map(function(item) {
      return item["value"];
    });
    chartLabels = sortData.map(function(item) {
      if (item["label"] !== undefined) {
        return item["label"];
      }
      return "";
    });
  }

  const data = useMemo(
    () => ({
      datasets: [
        {
          label: customLabel
            ? customLabel
            : labelY
            ? intl.formatMessage({ id: labelY })
            : false,
          backgroundColor:
            chartColors && chartColors.background
              ? chartColors.background
              : "rgba(255, 70, 38, 0.2)",
          borderColor:
            chartColors && chartColors.border
              ? chartColors.border
              : "rgb(255, 70, 38)",
          radius: 0,
          pointHoverRadius: 7,
          pointBackgroundColor: "rgb(255, 70, 38)",
          data: chartData,
          fill: true,
          lineTension: 0.5,
        },
      ],
      labels: chartLabels,
    }),
    // Conditional parameter do prevent unnecessary rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // eslint-disable-next-line react-hooks/exhaustive-deps
      ...(preventRerender
        ? []
        : [chartLabels, chartData, labelY, chartColors, intl]),
    ]
  );

  useEffect(
    () => {
      const chart = new Chart(ref.current, {
        type: "line",
        data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: false,
            text: "",
          },
          tooltips: {
            mode: "index",
            intersect: false,
            callbacks: {
              title: function(tooltipItem, data) {
                return tooltipPrefix
                  ? `${tooltipPrefix} ${tooltipItem[0].label}`
                  : tooltipItem[0].label;
              },
            },
          },
          hover: {
            mode: "nearest",
            intersect: true,
          },
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                position: "right",
                ticks: {
                  fontSize: scale === "large" ? 18 : 14,
                  beginAtZero: roundValues,
                  userCallback: roundValues
                    ? function(label) {
                        if (Math.floor(label) === label) {
                          return label;
                        }
                      }
                    : false,
                  display: !disableYLabels,
                },
                scaleLabel: {
                  display: labelY && !disableYLabels,
                  labelString: labelY ? intl.formatMessage({ id: labelY }) : "",
                  fontStyle: "normal",
                  fontColor: "#FF4626",
                  fontSize: 16,
                },
                gridLines: {
                  display: true,
                  color: gridLine ? gridLine.color : "rgba(0,0,0,0.05)",
                  drawBorder: !disabledYBorder,
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  fontSize: scale === "large" ? 14 : 12,
                  display: !disableXLabels,
                },
                scaleLabel: {
                  display: chartLabels[0] !== "" && labelX,
                  labelString: labelX ? intl.formatMessage({ id: labelX }) : "",
                  fontStyle: "normal",
                  fontColor: "#FF4626",
                  fontSize: 16,
                },
                gridLines: {
                  display: true,
                  color: gridLine ? gridLine.color : "rgba(0,0,0,0.05)",
                  drawBorder: !disabledXBorder,
                },
              },
            ],
          },
        },
      });

      return () => {
        chart.destroy();
      };
    },
    // Conditional parameter do prevent unnecessary rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // eslint-disable-next-line react-hooks/exhaustive-deps
      ...(preventRerender
        ? []
        : [
            data,
            scale,
            chartLabels,
            labelX,
            labelY,
            tooltipPrefix,
            gridLine,
            roundValues,
            intl,
            disableYLabels,
            disableXLabels,
            disabledXBorder,
            disabledYBorder,
          ]),
    ]
  );

  return (
    <div
      className={`kt-widget14 customPadding ${paddingDisabled &&
        "disabledPadding"}`}
    >
      {(title || desc) && (
        <div
          className={`kt-widget14__header ${!titleMarginDisable &&
            "kt-margin-b-30"}`}
        >
          {toolbar ? (
            <Grid container justifyContent="space-between">
              <Grid item>
                {title && <Typography variant="h3">{title}</Typography>}
              </Grid>
              <Grid item>{toolbar}</Grid>
            </Grid>
          ) : (
            title && <Typography variant="h3">{title}</Typography>
          )}
          <span className="kt-widget14__desc">{desc}</span>
        </div>
      )}
      <div className="kt-widget14__chart" style={{ height: height }}>
        <canvas ref={ref} />
      </div>
    </div>
  );
}

export default injectIntl(LineChart);

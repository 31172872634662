import {connect} from "react-redux";
import AdminAddUser from "./AdminAddUser";
import {fetchVisibleTeams, adminCreateUser} from "app/crud/user.crud";

const mapStateToProps = ({ user }) => ({
  user: user.data,
  teams: user?.visibleTeams,
  teamsLoading: user?.visibleTeamsLoading,
  creatingUser: user?.creatingUser
});
const mapDispatchToProps = {
  fetchVisibleTeams: () => fetchVisibleTeams(),
  adminCreateUser: data => adminCreateUser(data)
};

const withRedux = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withRedux(AdminAddUser);

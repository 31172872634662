import React, {useEffect, useState} from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import {removeUnitFromOffer} from "app/crud/offers.crud";
import {callReddClickEvent} from "app/crud/user.crud";
import connect from "react-redux/es/connect/connect";
import Button from "../../../../../partials/components/Button";
import Icon from "../../../../../partials/components/Icon";

function OfferRemoveUnit({offerID, unitID, removing, removeUnitFromOffer, callReddClickEvent}) {
  const [loading, setLoading] = useState(false);
  // Handlers
  const handleRemoveUnit = () => {
    if(offerID && unitID && !removing) {
      setLoading(true);
      const data = {
        id: unitID
      };
      callReddClickEvent(
        "offers-v2-offer-unit-remove-click",
        "offer",
        null,
        offerID
      );
      removeUnitFromOffer(offerID, data);
    }
  };

  //
  useEffect(() => {
    if(!removing && loading) {
      setLoading(false);
    }
  }, [removing, loading]);

  return(
    <Button
      type={"icon"}
      color={"simple"}
      tooltip={<FormattedMessage id="VOCABULARY.Remove from offer"/>}
      loading={loading}
      onClick={() => handleRemoveUnit()}
    >
      <Icon icon={"trash"}/>
    </Button>
  );
}

const mapStateToProps = (store) => ({
  removing: store.shortlists.removingUnit
});
const mapDispatchToProps = {
  removeUnitFromOffer: (offerID, data) => removeUnitFromOffer(offerID, data),
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params, notification) => callReddClickEvent(clickType, contentType, appLabel, objectId, params, notification),
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OfferRemoveUnit)
);

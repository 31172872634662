// OFFERS
export const fetchOffersIndustrialAll = () => ({
    type: 'FETCH_OFFERS_ALL_INDUSTRIAL_REQUEST'
});
export const fetchShortlistsIndustrial = (params) => ({
    type: 'FETCH_SHORTLISTS_INDUSTRIAL_REQUEST',
    payload: {
        params
    },
});

export const fetchShortlistsIndustrialLandlord = (params) => ({
    type: 'FETCH_SHORTLISTS_INDUSTRIAL_LANDLORD_REQUEST',
    payload: {
        params
    },
});
export const fetchMoreShortlistsIndustrialLandlord = (params) => ({
    type: 'FETCH_MORE_SHORTLISTS_INDUSTRIAL_LANDLORD_REQUEST',
    payload: {
        params
    },
});
export const fetchShortlistIndustrialPdf = (shortlistID) => ({
    type: 'FETCH_SHORTLIST_PDF_INDUSTRIAL_REQUEST',
    payload: shortlistID,
});

export const fetchShortlistParks = (params) => ({
    type: 'FETCH_SHORTLISTS_PARKS_REQUEST',
    payload: {
        params
    },
});
export const fetchShortlistIndustrialUnitsLandlord = (params) => ({
    type: 'FETCH_SHORTLISTS_INDUSTRIAL_LANDLORD_UNITS_REQUEST',
    payload: {params},
});
export const fetchShortlistIndustrialLandlordReset = () => ({
    type: 'FETCH_SHORTLISTS_INDUSTRIAL_LANDLORD_RESET_REQUEST'
});
export const fetchShortlistParksReset = () => ({
    type: 'FETCH_SHORTLISTS_PARKS_RESET_REQUEST'
});
export const fetchShortlistIndustrialUnitsLandlordReset = () => ({
    type: 'FETCH_SHORTLISTS_INDUSTRIAL_LANDLORD_UNITS_RESET_REQUEST'
});
// OFFERS ONLINE
export const fetchOfferIndustrialOnline = (offerID, email, market) => ({
    type: 'FETCH_OFFER_INDUSTRIAL_ONLINE_REQUEST',
    payload: {
        offerID,
        email,
        market
    },
});
export const fetchOfferIndustrialOnlinePark = (offerID, officeID, email) => ({
    type: 'FETCH_OFFER_INDUSTRIAL_ONLINE_OFFICE_REQUEST',
    payload: {
        offerID,
        officeID,
        email
    },
});
export const fetchOfferIndustrialOnlineOfficeContact = (offerID, officeID, email) => ({
    type: 'FETCH_OFFER_INDUSTRIAL_ONLINE_OFFICE_CONTACT_REQUEST',
    payload: {
        offerID,
        officeID,
        email
    },
});
export const fetchOfferIndustrialOnlineBranding = (offerID) => ({
    type: 'FETCH_OFFER_INDUSTRIAL_ONLINE_BRANDING_REQUEST',
    payload: {
        offerID
    },
});
export const fetchOfferIndustrialOnlineListPhotos = (offerID, email) => ({
    type: 'FETCH_OFFER_INDUSTRIAL_ONLINE_LIST_PHOTOS_REQUEST',
    payload: {
        offerID,
        email
    },
});
// OFFERS ACTIONS
export const addShortlistIndustrial = (data) => ({
    type: 'ADD_SHORTLIST_INDUSTRIAL_REQUEST',
    payload: {data},
});
export const addParkToExistingShortlist = (id, parks) => ({
    type: 'ADD_PARK_TO_EXISTING_SHORTLIST_REQUEST',
    payload: {
        id, parks
    },
});
export const addParkToNewShortlistIndustrial = (name, parks, forTesting) => ({
    type: 'ADD_PARK_TO_NEW_SHORTLIST_REQUEST',
    payload: {
        name, parks, forTesting
    },
});
export const deleteShortlistIndustrial = (shortlistId) => ({
    type: 'DELETE_SHORTLIST_INDUSTRIAL_REQUEST',
    payload: shortlistId,
});
export const updateOfferIndustrial = (offerID, data) => ({
    type: 'UPDATE_OFFER_INDUSTRIAL_REQUEST',
    payload: {
        offerID, data
    },
});
// OFFERS ONLINE ACTIONS
export const verifyOfferIndustrialOnlineAccess = (offerID, email) => ({
    type: 'VERIFY_OFFER_INDUSTRIAL_ONLINE_ACCESS_REQUEST',
    payload: {
        offerID,
        email
    },
});
export const requestOfferIndustrialOnlineAccess = (offerID, email) => ({
    type: 'REQUEST_OFFER_INDUSTRIAL_ONLINE_ACCESS_REQUEST',
    payload: {
        offerID,
        email
    },
});
// OFFERS KUDOS MAIL SEND
export const sendKudosEmailIndustrialConfirmation = (data) => ({
    type: 'SEND_KUDOS_EMAIL_INDUSTRIAL_REQUEST',
    payload: {
        data
    },
});
export const createKudosEmailIndustrialSignInLog = (data) => ({
    type: 'CREATE_KUDOS_EMAIL_INDUSTRIAL_SIGN_IN_LOG_REQUEST',
    payload: {
        data
    },
});
export const createRealTimeEmailIndustrialSignInLog = (data) => ({
    type: 'CREATE_REAL_TIME_EMAIL_SIGN_IN_LOG_REQUEST',
    payload: {
        data
    },
});
// OFFER WEEKLY SUMMARY - BUTTON REDIRECT
export const createRealTimeEmailIndustrialWeeklySummaryLog = (data) => ({
    type: 'CREATE_REAL_TIME_EMAIL_WEEKLY_SUMMARY_LOG_REQUEST',
    payload: {
        data
    },
});
// OFFER FIRST OPEN - BUTTON REDIRECT
export const createRealTimeEmailIndustrialFirstOpenLog = (data) => ({
    type: 'CREATE_REAL_TIME_EMAIL_FIRST_OPEN_LOG_REQUEST',
    payload: {
        data
    },
});
// OFFER FIRST OPEN - BUTTON REDIRECT
export const createRealTimeEmailOfferIndustrialOpenLog = (data) => ({
    type: 'CREATE_REAL_TIME_EMAIL_OFFER_INDUSTRIAL_OPEN_LOG_REQUEST',
    payload: {
        data
    },
});
// RESET INDUSTRIAL OFFERS LOADING STATES
export const resetIndustrialOffersLoadingStates = () => ({
    type: 'RESET_INDUSTRIAL_OFFERS_LOADING_STATES_REQUEST'
});
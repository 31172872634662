import React from "react";
import {FormattedMessage} from "react-intl";
import {toDateFormat} from "app/utils/helpers";
import {Icon, Tooltip, Typography} from "@material-ui/core";

export default class WarehouseAvailabilityPreview extends React.Component {
    render() {
        const {availabilityDate, isAvailable, isAvailableFrom, isUnavailable} = this.props;
        const availableDate = new Date(availabilityDate).getTime();

        const returnAvailableFrom = (
                <Tooltip
                    placement="top"
                    title={<Typography variant="body1">{toDateFormat(availableDate)}</Typography>}
                >
                  <span style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                    <FormattedMessage id="INDUSTRIAL.PHRASE.AVAILABLE_FROM_DATE" />
                    <Icon fontSize="small" color="error">help_outline</Icon>
                  </span>
                </Tooltip>
            )
            const availabilityStatus = isUnavailable ? <FormattedMessage id="INDUSTRIAL.PHRASE.UNAVAILABLE"/> :
                isAvailableFrom ? returnAvailableFrom :
                    isAvailable ? <FormattedMessage id="INDUSTRIAL.PHRASE.AVAILABLE"/> :
                        toDateFormat(availableDate);

        return <span>{availabilityStatus}</span>;
    }
}
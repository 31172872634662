import React from "react";
import {FormattedMessage} from "react-intl";
import {TABLE_CELLS} from "../../../partials/components/TableProperties";
import ListView from "../../offices/components/ListView/ListView";
import IsAdmin from "../../../utils/IsAdmin";

export default class AdminManageAccountRequests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      initLoaded: false
    };
  }

  // Handle fetch data on enter viewport
  handleOnViewportFetch = (isVisible) => {
    const {fetchAccountRequests, loading} = this.props;
    const {initLoaded} = this.state;
    const params = {
      page: 1
    };
    if(isVisible && !loading && !initLoaded) {
      this.setState({
        initLoaded: true
      }, () => fetchAccountRequests({params}));
    }
  };
  handleOnViewport = (isInViewport) => {
    this.handleOnViewportFetch(isInViewport);
  }

  render() {
    const {data, loading, itemsPerPage, itemsCount, totalItems, fetchAccountRequests} = this.props;
    const tHead = [
      {
        id: "ar_name",
        label: "AR.PHRASE.NAME",
        numeric: false
      },
      {
        id: "ar_mail",
        label: "AR.PHRASE.MAIL",
        numeric: false
      },
      {
        id: "ar_phone_number",
        label: "AR.PHRASE.PHONE_NUMBER",
        numeric: false
      },
      {
        id: "ar_created_at",
        label: "AR.PHRASE.CREATED_AT",
        numeric: true
      },
      {
        id: "ar_additional_information",
        label: "AR.PHRASE.ADDITIONAL_INFORMATION",
        numeric: true
      },
      {
        id: "ar_options",
        label: "",
        numeric: true
      }
    ];

    return(
      <IsAdmin markup="section">
        <ListView
          tableLabel={<FormattedMessage id="MENU.ADMIN_MANAGE_ACCOUNT_REQUESTS"/>}
          tableHeadRows={tHead}
          tableBodyRows={data}
          tableDataSource="adminManageAccountRequest"
          tableDataTarget={TABLE_CELLS.ADMIN_MANAGE_ACCOUNT_REQUESTS}
          itemsPerPage={itemsPerPage}
          itemsCount={itemsCount}
          totalItems={totalItems}
          loading={loading}
          handleFetchData={fetchAccountRequests}
          onEnterViewport={this.handleOnViewport}
        />
      </IsAdmin>
    );
  }
}
import {filterByModules, filterReset} from "app/crud/estates/filters.crud";
import {
  cleanUnitsInfiniteList,
  fetchOfficeUnits,
  fetchUnitsInfiniteList,
  fetchUnitsInfiniteListReset,
  fetchUnitsList,
  getClusters
} from "app/crud/estates/offices/units.crud";
import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import UnitsPage from "./UnitsPage";
import {setLatestActiveTablePage} from "app/crud/tablePage.crud";
import {callReddClickEvent} from "app/crud/user.crud";
import {fetchOffice} from "../../../crud/estates/offices/offices.crud";

const mapStateToProps = (state) => ({
  units: state.units.units,
  area: state.units.area,
  allUnits: state.units.allUnits,
  itemsCount: state.units.itemsCount,
  totalItems: state.units.totalItems,
  maxPages: state.units.maxPages,
  shortlists: state.shortlists.items,
  loaded: state.units.loaded,
  loading: state.units.loading,
  viewType: state.units.viewType,
  filters: state.filters,
  clusters: state.units.clusters,
  clustersLoaded: state.units.clustersLoaded,
  clustersLoading: state.units.clustersLoading,
  office: state.offices.office,
  officeLoading: state.offices.officeLoading,
  user: state.user.data,
  allUserStates: state.user,
  officeUnits: state.units.officeUnits,
  officeUnitsLoading: state.units.officeUnitsLoading,
  marketName: state.user.data.marketName
});
const mapDispatchToProps = {
  fetchUnitsList: ({params}) => fetchUnitsList(params),
  fetchUnitsInfiniteList: ({params}) => fetchUnitsInfiniteList(params),
  fetchOffice: (officeId) => fetchOffice(officeId),
  cleanUnitsInfiniteList: cleanUnitsInfiniteList,
  getClusters: ({params}) => getClusters(params),
  filterByModules:(params)=>filterByModules(params),
  filterReset: (filtersToOmit) => filterReset(filtersToOmit),
  fetchUnitsInfiniteListReset: () => fetchUnitsInfiniteListReset(),
  fetchOfficeUnits: (officeId) => fetchOfficeUnits(officeId),
  setLatestActiveTablePage: (page) => setLatestActiveTablePage(page),
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UnitsPage)
);
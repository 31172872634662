import PDF_FONTS_REGISTER from "./PDF_FONTS_REGISTER";

export default [
    {
        "name": "Cabin",
        "code": PDF_FONTS_REGISTER.CABIN
    },
    {
        "name": "Crimson Text",
        "code": PDF_FONTS_REGISTER.CRIMSON_TEXT
    },
    {
        "name": "Dosis",
        "code": PDF_FONTS_REGISTER.DOSIS
    },
    {
        "name": "Jura",
        "code": PDF_FONTS_REGISTER.JURA
    },
    {
        "name": "Lato",
        "code": PDF_FONTS_REGISTER.LATO
    },
    {
        "name": "Open Sans",
        "code": PDF_FONTS_REGISTER.OPEN_SANS
    },
    {
        "name": "Oswald",
        "code": PDF_FONTS_REGISTER.OSWALD
    },
    {
        "name": "Poppins",
        "code": PDF_FONTS_REGISTER.POPPINS
    },
    {
        "name": "Roboto",
        "code": PDF_FONTS_REGISTER.ROBOTO
    }
];
import React from "react";
import {injectIntl} from "react-intl";
import styles from "app/pages/common/components/PropertyPage/index.module.scss";
import cn from "classnames";
import _ from "lodash";
import ReactVisibilitySensor from "react-visibility-sensor";
import {callReddClickEvent} from "app/crud/user.crud";
import connect from "react-redux/es/connect/connect";
import PropertyHeaderPreview from "./content/PropertyHeaderPreview";

class PropertyHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initLoaded: false
    }
  }
  
  // Handlers
  handleOnViewportFetch = (isVisible) => {
    const {
      fetchIndustrialParkBasic,
      basic,
      basicLoading,
      resetting,
      match,
      callReddClickEvent
    } = this.props;
    const {initLoaded} = this.state;
    const {id} = match?.params;

    if(isVisible && !initLoaded && !basicLoading && _.isEmpty(basic) && id && !resetting) {
      if(id && callReddClickEvent) {
        callReddClickEvent(
          "estates-industrial-park-details",
          "park",
          null,
          id
        );
      }
      this.setState({
        initLoaded: true,
      }, () => fetchIndustrialParkBasic(id));
    }
  }

  render() {
    const {breakPoint} = this.props;

    return(
      <ReactVisibilitySensor
        onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
        scrollCheck
        partialVisibility
      >
        <div className={cn(
          styles.propertyHeaderContainer
        )}>
          <PropertyHeaderPreview breakPoint={breakPoint}/>
        </div>
      </ReactVisibilitySensor>
    );
  }
}

const mapDispatchToProps = {
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params, notification) => callReddClickEvent(clickType, contentType, appLabel, objectId, params, notification)
};

export default injectIntl(
  connect(
    null,
    mapDispatchToProps
  )(PropertyHeader)
);
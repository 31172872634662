import React from "react";
import {Image} from "react-bootstrap";

export class Avatar extends React.Component {
  render() {
    const { avatar, size = "4.5rem", margin, imgStyle } = this.props;
    return (
      <span
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginRight: "0",
          overflow: "hidden",
          width: size,
          height: size,
          borderRadius: "3px"
        }}
      >
        <Image
          src={avatar}
          style={{
            marginTop: margin ? "1rem" : 0,
            marginBottom: margin ? "1rem" : 0,
            maxHeight: "100%",
            objectFit: "cover",
            ...imgStyle
          }}
        />
      </span>
    );
  }
}

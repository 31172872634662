export default [
  {
    label: 'PLN',
    value: 0,
    symbol: 'PLN'
  },
  {
    label: 'EUR',
    value: 1,
    symbol: '€'
  }
]
import React from "react";
import styles from "../../../../../common/external/components/index.module.scss";
import clsx from "clsx";
import OffersOnlineSingleDetails from "./OffersOnlineSingleDetails";
import OffersOnlineSingleAmenities from "./OffersOnlineSingleAmenities";
import OffersOnlineSingleLocation from "./OffersOnlineSingleLocation";
import OffersOnlineSingleTransport from "./OffersOnlineSingleTransport";
import {FormattedMessage, injectIntl} from "react-intl";
import OffersOnlineSingleHeader from "./OffersOnlineSingleHeader";
import LoadingScreen from "app/partials/components/LoadingScreen";
import OffersOnlineHeader from "../OffersOnlineHeader";
import OffersOnlineSingleNavigation from "./OffersOnlineSingleNavigation";
import {handleEmptyValue} from "app/utils/helpers";
import OffersOnlineSingleUnits from "./OffersOnlineSingleUnits";
import ROUTES from "app/constants/ROUTES";
import {toAbsoluteUrl} from "_metronic";
import Page404 from "../../../../../../partials/components/404";
import connect from "react-redux/es/connect/connect";
import {callReddClickEvent} from "app/crud/user.crud";

class OffersOnlineSingle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isReady: false
        }
        // Refs
        this.refNavigation = React.createRef();
        this.officeDetails = React.createRef();
        this.officeUnits = React.createRef();
        this.officeAmenities = React.createRef();
        this.officeLocation = React.createRef();
        this.officeTransport = React.createRef();
        if (props.match && props.match.params && props.match.params.offer && props.match.params.office && !props.loading) {
            const offerID = props.match.params.offer;
            const officeID = props.match.params.office;
            const authEmail = handleEmptyValue(localStorage.getItem("offerMail"), true);
            props.onFetchData(offerID, officeID, authEmail);
        }
    }
    componentDidMount() {
        const offerID = this.props.match.params.offer;
        const officeID = this.props.match?.params?.office;
        const authEmail = handleEmptyValue(localStorage.getItem("offerMail"), true);

        if(!this.state.isReady && officeID) {
            this.setState({
                isReady: true
            }, () => {
                this.props.dispatch(callReddClickEvent(
                  "offers-details-office",
                  "office",
                  null,
                  officeID,
                  {offerID, email: authEmail}
                ));
            });
        }
    }

    handleGoBack = () => {
        const fullPath = this.props.history.location.pathname;
        const fullPathArray = fullPath.split("/");
        const goBackPath = fullPath.replace(fullPathArray[fullPathArray.length - 2] + '/', '');
        this.props.history.replace(goBackPath);
    };
    handleScrollToAnchor = (targetRef) => {
        if (this[targetRef] && this[targetRef].current) {
            const overflowParent = document.getElementById("root");
            const headerOffset = this.refNavigation.current?.offsetHeight + 50;
            const targetPosition = this[targetRef].current?.getBoundingClientRect().top;
            const offsetPosition = targetPosition + overflowParent.scrollTop - headerOffset;

            overflowParent.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }
    };
    handleLoadContacts = () => {
        const {onFetchContact, match} = this.props;
        if (match && match.params && match.params.offer && match.params.office) {
            const offerID = match.params.offer;
            const officeID = match.params.office;
            const authEmail = handleEmptyValue(localStorage.getItem("offerMail"), true);
            onFetchContact(offerID, officeID, authEmail);
            //
            this.props.dispatch(callReddClickEvent(
              "offers-details-office-contact",
              "office",
              null,
              officeID,
              {offerID, email: authEmail}
            ));
        }
        return false;
    };

    render() {
        const {loading, data, intl, branding, contact, loadingContact, isSingleProperty, is404} = this.props;

        if (loading) {
            return <LoadingScreen absolute/>;
        }
        else if(is404) {
            return <Page404 onBack={!isSingleProperty && this.handleGoBack} message="The offer you are looking for does not exist."/>
        }

        return (
            <>
                {/* Render PP Single Header Nav */}
                <OffersOnlineHeader view="single" branding={branding} onGoBack={!isSingleProperty && this.handleGoBack}/>
                {data && <OffersOnlineSingleHeader photos={data.photos}/>}
                {data && <OffersOnlineSingleNavigation reference={this.refNavigation} data={data} photos={data.photos}
                                                       onScrollToAnchor={this.handleScrollToAnchor}/>}
                {/* Main Content Wrapper*/}
                {data && (
                    <div className={styles.mainOfficeContainer}>
                        <div className={styles.contentWrapper}>
                            <OffersOnlineSingleDetails reference={this.officeDetails} data={data}/>
                            {data.units && <OffersOnlineSingleUnits reference={this.officeUnits} units={data.units}
                                                                    branding={branding}/>}
                            {data.amenities &&
                                <OffersOnlineSingleAmenities reference={this.officeAmenities} data={data.amenities}
                                                             intl={intl}/>}
                            <OffersOnlineSingleLocation
                                reference={this.officeLocation}
                                data={data}
                                intl={intl}
                            />
                            {data.publicTransport && data.publicTransport.length ?
                                <OffersOnlineSingleTransport
                                    reference={this.officeTransport}
                                    data={data.publicTransport}
                                    intl={intl}
                                /> :
                                null
                            }
                        </div>
                        <div className={styles.contentSidebar}>
                            <div className={clsx(styles.boxContainer, styles.contactBox)}>
                                <div className={styles.contactHeader}>
                                    <h3><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.LEASE"/></h3>
                                    {branding && branding.ppCompanyLogo && (
                                        <div className={styles.contactLogoWrapper}>
                                            <img src={branding.ppCompanyLogo} alt={handleEmptyValue(branding.name)}/>
                                        </div>
                                    )}
                                </div>
                                <div className={styles.contactBody}>
                                    {loadingContact ? <LoadingScreen/> :
                                        !contact ?
                                            <button type="button" className={styles.btnLeaseShow}
                                                    onClick={() => this.handleLoadContacts()}><FormattedMessage
                                                id="PROPERTY_PARTICULARS.PHRASE.SHOW_CONTACT_DETAILS"/></button>
                                            :
                                            <div className={styles.contactPerson}>
                                                {contact.mainPhotoThumbnail &&
                                                    <img src={contact.mainPhotoThumbnail} alt={contact.companyName}/>}
                                                <div className={styles.contactDetails}>
                                                    <h3>{[...contact.firstName ? [contact.firstName] : [], ...contact.lastName ? [contact.lastName] : []].join(" ")}</h3>
                                                    <p>{handleEmptyValue(contact.companyName)}</p>
                                                    {handleEmptyValue(contact.phoneNumber, true) &&
                                                        <a href={`tel:${contact.phoneNumber}`}>{contact.phoneNumber}</a>}
                                                    {handleEmptyValue(contact.email, true) &&
                                                        <a href={`mailto:${contact.email}`}>{contact.email}</a>}
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className={styles.footerSection}>
                    <span>Powered by <a href={ROUTES.REDD_LANDING_PAGE} target="_blank" rel="noopener noreferrer"><img
                        src={toAbsoluteUrl("media/pp-mockup-media/logo-redd.png")} alt="redd property particulars"/></a></span>
                </div>
            </>
        );
    }
}

export default injectIntl(connect()(OffersOnlineSingle));
// FETCH ACTIONS
export const fetchUnitsList = (params) => ({
  type: 'FETCH_UNITS_LIST_REQUEST',
  payload: {
    params
  },
});
export const fetchUnitsListReset = (initOrderBy, isEditable) => ({
  type: 'FETCH_UNITS_LIST_RESET_REQUEST',
  payload: {
    initOrderBy,
    isEditable
  }
});
export const fetchUnitsInfiniteList = (params) => ({
  type: 'FETCH_UNITS_INFINITE_LIST_REQUEST',
  payload: {
    params
  },
});
export const fetchUnitsInfiniteListReset = () => ({
  type: 'FETCH_UNITS_INFINITE_LIST_RESET_REQUEST',
});
export const fetchOfficeUnits = (officeId) => ({
  type: 'FETCH_OFFICE_UNITS_REQUEST',
  payload: officeId,
});
export const getAgentViewType = () => ({
  type: 'FETCH_VIEW_TYPE_REQUEST',
});
export const getClusters = (params) => ({
  type: 'FETCH_CLUSTERS_REQUEST',
  payload: {
    params
  },
});
export const getClustersReset = () => ({
  type: 'FETCH_CLUSTERS_RESET_REQUEST',
});
// FETCH BASIC DATA
export const fetchUnitEditFormsData = (unitId) => ({
  type: 'FETCH_UNIT_EDIT_FORMS_DATA_REQUEST',
  payload: unitId,
});
export const fetchUnitContactPersons = (unitId) => ({
  type: 'FETCH_UNIT_CONTACT_PERSONS_REQUEST',
  payload: unitId,
});
// USER ACTIONS
export const addOfficeUnit = (unit, ordering) => ({
  type: 'ADD_OFFICE_UNIT_REQUEST',
  payload: {unit, ordering}
});
export const updateUnit = (unitId, data, backgroundRefreshObject) => ({
  type: 'UPDATE_UNIT_REQUEST',
  payload: {unitId, data, backgroundRefreshObject}
});
export const updateUnitPlan = (unitId, image, backgroundRefreshObject) => ({
  type: 'UPDATE_UNIT_PLAN_REQUEST',
  payload: {unitId, image, backgroundRefreshObject}
});
export const deleteUnit = unitId => ({
  type: 'DELETE_UNIT_REQUEST',
  payload: {unitId}
});
export const refreshUnit = unitId => ({
  type: 'REFRESH_UNIT_REQUEST',
  payload: unitId
});
export const refreshAllOfficeUnits = (officeId, backgroundRefreshObject) => ({
  type: 'REFRESH_ALL_OFFICE_UNITS_REQUEST',
  payload: {
    officeId,
    backgroundRefreshObject
  }
});
export const refreshSelectedOfficeUnits = (officeId, units, backgroundRefreshObject) => ({
  type: 'REFRESH_SELECTED_OFFICE_UNITS_REQUEST',
  payload: {
    officeId,
    units,
    backgroundRefreshObject
  }
});

// BACKGROUND ACTIONS
export const cleanUnitsInfiniteList = () => ({
  type: 'CLEAN_UNITS_INFINITE_LIST_REQUEST'
});

// RESET OFFICE UNITS LOADING STATES
export const resetOfficeUnitsLoadingStates = () => ({
  type: 'RESET_OFFICE_UNITS_LOADING_STATES_REQUEST'
});
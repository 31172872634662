import React from "react";
import connect from "react-redux/es/connect/connect";
import {Typography} from "@material-ui/core";
import PortletAccordion from "app/partials/components/PortletAccordion";
import {FormattedMessage, injectIntl} from "react-intl";
import {callReddClickEvent} from "app/crud/user.crud";
import TableCustom from "app/partials/components/TableCustom";
import PropertyStatusPreview from "app/partials/components/_DataParts/PropertyStatusPreview";
import BadgeValue from "app/partials/components/BadgeValue";
import {toNumberFormat} from "app/utils/helpers";
import {ROUTES} from "app/constants";
import _ from "lodash";
import LoadingScreen from "app/partials/components/LoadingScreen";
import NoData from "app/partials/components/NoData/NoData";
import TableDrawer from "app/partials/components/TableDrawer";
import TABLE_CELLS from "app/partials/components/TableProperties/TableCells/TABLE_CELLS";
import {
  fetchIndustrialWarehouses,
  fetchIndustrialWarehouseUnits
} from "app/crud/estates/industrial/industrial.crud";
import PropertyCardPreview from "app/partials/components/_DataParts/PropertyCardPreview";
import PropertyExpandedCardPreview from "app/partials/components/_DataParts/PropertyExpandedCardPreview";
import ReactVisibilitySensor from "react-visibility-sensor";
import IsAccountType from "../../../../utils/IsAccountType";
import ACCOUNT_TYPE from "../../../../constants/ACCOUNT_TYPE";
import IsPro from "../../../../utils/IsPro";
import PaywallModal from "../../../../partials/components/PayWall/components/PaywallModal";
import IsApplicationView from "../../../../utils/IsApplicationView";
import APPLICATION_VIEWS from "../../../../constants/APPLICATION_VIEWS";
import BlurText from "../../../../partials/components/BlurText";
import UpdatedAtPreview from "../../../../partials/components/_DataParts/UpdatedAtPreview";
import Button from "../../../../partials/components/Button";

class DashboardPropertiesIndustrial extends React.Component {
  constructor(props) {
    super(props);
    const initParams = {
      orderBy: IsAccountType({expectedAccountType: ACCOUNT_TYPE.LANDLORD}) ? 'overdue_unit' : (
        IsApplicationView({expectedAppView: APPLICATION_VIEWS.INDUSTRIAL}) ? 'updated_at' : 'updated_at_by_user'
      ),
      order: IsAccountType({expectedAccountType: ACCOUNT_TYPE.LANDLORD}) ? '' : '-',
      isEditable: IsAccountType({expectedAccountType: ACCOUNT_TYPE.LANDLORD}) ? 'true' : 'false',
      size: 5,
      totalAreaWarehouseAvailableFrom: "1"
    };
    this.state = {
      tBody: null,
      initLoaded: false,
      params: initParams
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    // Re-render TableBody
    if (
      // if InitLoaded
      (!prevState.initLoaded && this.state.initLoaded) ||
      // if offices was empty
      (_.isEmpty(prevProps?.warehouses) && this.props.warehouses?.length > 0) ||
      // if offices was updated
      (!_.isEqual(prevProps?.warehouses, this.props.warehouses)) ||
      // if units loading
      (!prevProps.warehouseUnitsLoading && this.props.warehouseUnitsLoading) ||
      // if units was empty
      (_.isEmpty(prevProps?.warehouseUnits) && this.props.warehouseUnits?.length > 0) ||
      // if units was updated
      (!_.isEqual(prevProps?.warehouseUnits, this.props.warehouseUnits)) ||
      // if unit has been refreshed
      (prevProps.refreshWarehouseUnitsLoading && !this.props.refreshWarehouseUnitsLoading) ||
      // if unit has been updated
      (prevProps.updateWarehouseUnitLoading && !this.props.updateWarehouseUnitLoading)
    ) {
      this.setState({
        tBody: this.handlePrepTableBody()
      });
    }
  }

  // Handlers
  handlePrepTableBody = () => {
    if (this.props.warehouses?.length > 0) {
      return this.props.warehouses?.map(warehouse => {
        return {
          data: [
            <PropertyCardPreview
              title={warehouse?.parkName}
              semiTitle={warehouse?.name}
              semiTitle2={warehouse?.marketName}
              thumb={warehouse?.parkThumbnail}
              propertyID={warehouse?.id}
              route={ROUTES.INDUSTRIAL_BUILDING}
            />,
            <PropertyStatusPreview status={warehouse?.status}/>,
            <>
              <BadgeValue
                value={
                <BlurText
                  type="area"
                  isBlurred={
                  IsAccountType({expectedAccountType: ACCOUNT_TYPE.AGENT}) &&
                    IsPro({reverseCheck: true})
                  }
                >
                  <strong>{toNumberFormat(warehouse.totalAreaWarehouseAvailable, 'area')}</strong>
                </BlurText>
                }
                label={<FormattedMessage id="INDUSTRIAL.PHRASE.WAREHOUSE"/>}
                labelColor="success"
                minWidth="8.5rem"
              />
              <BadgeValue
                value={
                <BlurText
                  type="area"
                  isBlurred={
                  IsAccountType({expectedAccountType: ACCOUNT_TYPE.AGENT}) &&
                    IsPro({reverseCheck: true})
                  }
                >
                  <strong>{toNumberFormat(warehouse.totalAreaOfficeAvailable, 'area')}</strong>
                </BlurText>
                }
                label={<FormattedMessage id="INDUSTRIAL.PHRASE.OFFICE"/>}
                labelColor="neutral"
                minWidth="8.5rem"
              />
            </>,
            <>
              <BadgeValue
                value={toNumberFormat(warehouse.totalWarehouseAreaInBuilding, 'area')}
                label={<FormattedMessage id="INDUSTRIAL.PHRASE.WAREHOUSE"/>}
                labelColor="success"
                minWidth="8.5rem"
              />
              <BadgeValue
                value={toNumberFormat(warehouse.totalOfficeAreaInBuilding, 'area')}
                label={<FormattedMessage id="INDUSTRIAL.PHRASE.OFFICE"/>}
                labelColor="neutral"
                minWidth="8.5rem"
              />
            </>,
            <UpdatedAtPreview
              level={1}
              date={
              IsAccountType({expectedAccountType: ACCOUNT_TYPE.AGENT}) ?
                (warehouse?.updatedAtByUser || warehouse?.overdueUnitDate) : warehouse?.overdueUnitDate
              }
              alternative
            />,
            <>
              {
                (
                  IsPro({}) ||
                  IsAccountType({expectedAccountType: ACCOUNT_TYPE.LANDLORD})
                ) ? this.handleManageUnitsDrawer(warehouse) :
                  <PaywallModal
                    triggerLabel={<FormattedMessage id="VOCABULARY.View units"/>}
                    triggerButtonVariant={this.props.intl.formatMessage({id: "VOCABULARY.View units"})}
                    withIcon
                  />
              }
            </>
          ]
        }
      });
    }
  }
  handleManageUnitsDrawer = (warehouse) => {
    const {warehouseUnits, warehouseUnitsLoading, fetchIndustrialWarehouseUnits, fetchIndustrialWarehouses} = this.props;
    const {params} = this.state;
    const propertyThumbnail = warehouse.thumbnail ? warehouse.thumbnail : warehouse.parkThumbnail;
    const isUnitsActionsPermitted = warehouse?.isEditable;

    return (
      <TableDrawer
        titleAdvance
        title={
          <PropertyExpandedCardPreview
            title={warehouse?.parkName}
            semiTitle={warehouse?.name}
            semiTitle2={warehouse?.marketName}
            thumb={propertyThumbnail}
            route={ROUTES.INDUSTRIAL_BUILDING}
            propertyID={warehouse?.id}
          />
        }
        headRows={[
          ...isUnitsActionsPermitted ? [{
            id: 'checkable',
            numeric: false,
            disablePadding: false,
            label: '',
            sort: false
          }] : [],
          {
            id: 'area',
            numeric: true,
            disablePadding: false,
            label: 'GENERAL.PHRASE.AREA',
            sort: false
          },
          {
            id: 'is_office',
            numeric: false,
            disablePadding: false,
            label: 'GENERAL.PHRASE.TYPE',
            sort: false
          },
          {
            id: 'status',
            numeric: false,
            disablePadding: false,
            label: 'INDUSTRIAL.TABLE.STATUS',
            sort: false
          },
          {
            id: 'available_from',
            numeric: false,
            disablePadding: false,
            label: 'BUILDING.PHRASE.AVAILABLE_FROM',
            sort: false
          },
          {
            id: 'updated_at',
            numeric: false,
            disablePadding: false,
            label: 'BUILDING.PHRASE.UPDATED_AT',
            sort: false
          },
          {
            id: 'docks',
            numeric: true,
            disablePadding: false,
            label: 'INDUSTRIAL.PHRASE.LOADING_DOCKS',
            sort: false
          },
          {
            id: 'actions',
            numeric: true,
            disablePadding: false,
            label: '',
            sort: false
          }
        ]}
        bodyRows={warehouseUnits}
        bodyRowsReady={warehouseUnitsLoading}
        target={TABLE_CELLS.INDUSTRIAL_BUILDING_UNITS}
        parentData={warehouse}
        parentPreviewData={warehouse}
        parentId={warehouse.id}
        onClick={fetchIndustrialWarehouseUnits}
        onClose={() => fetchIndustrialWarehouses({params})}
        isEditable={isUnitsActionsPermitted}
      >
        <Button
          trackingData={{
            clickType: "dashboard-industrial-overdue-properties-manage-units-click",
            contentType: "user"
          }}
        >
          {
            IsAccountType({expectedAccountType: ACCOUNT_TYPE.LANDLORD}) ?
            <>
              <FormattedMessage id="GENERAL.PHRASE.MANAGE_UNITS"/>
              <strong style={{paddingLeft: "0.5rem"}}>({warehouse?.numberOfUnitsAvailable})</strong>
            </> :
            <FormattedMessage id="VOCABULARY.View units"/>
          }
        </Button>
      </TableDrawer>
    );
  }
  handleOnViewportFetch = (isVisible) => {
    const {
      fetchIndustrialWarehouses,
      loading
    } = this.props;
    const {initLoaded, params} = this.state;

    if(isVisible && !loading && !initLoaded) {
      this.setState({
        initLoaded: true
      }, () => fetchIndustrialWarehouses({params}));
    }
  };

  render() {
    const {loading} = this.props;
    const {tBody} = this.state;
    const tHead = [
      {
        "label": <FormattedMessage id="GENERAL.PHRASE.INDUSTRIAL_PARK"/>,
        "number": false
      },
      {
        "label": <FormattedMessage id="BUILDING.PHRASE.STATUS"/>,
        "number": false
      },
      {
        "label": <FormattedMessage id="BUILDING.PHRASE.AVAILABLE_AREA"/>,
        "number": false
      },
      {
        "label": <FormattedMessage id="INDUSTRIAL.TABLE.BUILDING_AREA"/>,
        "number": false
      },
      {
        "label": <FormattedMessage id="VOCABULARY.Update"/>,
        "number": false
      },
      {
        "label": "",
        "number": true
      }
    ];

    return (
      <PortletAccordion
        title={
        <ReactVisibilitySensor
            onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
            scrollCheck
            partialVisibility
        >
          <Typography variant="h3">
            {
              IsAccountType({expectedAccountType: ACCOUNT_TYPE.LANDLORD}) ?
                <FormattedMessage id="DASHBOARD.PHRASE.YOUR_WAREHOUSES"/> :
                <FormattedMessage id="VOCABULARY.Recently updated"/>
            }
          </Typography>
        </ReactVisibilitySensor>
        }
        footer={
        <Button
          type={"plainText"}
          variant={"outlined"}
          redirect={`${ROUTES.INDUSTRIAL_PARKS}${IsAccountType({expectedAccountType: ACCOUNT_TYPE.LANDLORD}) ? "?is_editable=true" : ""}`}
          style={{fontSize: "1.6rem", fontWeight: "400"}}
        >
          <FormattedMessage id="VOCABULARY.Check all"/>
        </Button>
        }
        disableAccordion
      >
        {loading ? <LoadingScreen/> :
          _.isEmpty(tBody) ? <NoData withIcon/> :
            <TableCustom
              tHead={tHead}
              tBody={tBody}
              responsive
              maxHeight="50rem"
              stickyHeader
            />
        }
      </PortletAccordion>
    );
  }
}

const mapStateToProps = (store) => ({
  warehouses: store.industrial.warehousesItems,
  loading: store.industrial.warehousesLoading,
  warehouseUnits: store.industrial.warehouseUnits,
  warehouseUnitsLoading: store.industrial.warehouseUnitsLoading,
  updateWarehouseUnitLoading: store.industrial.updateWarehouseUnitLoading,
  refreshWarehouseUnitsLoading: store.industrial.refreshWarehouseUnitsLoading
});
const mapDispatchToProps = {
  fetchIndustrialWarehouses: ({params}) => fetchIndustrialWarehouses(params),
  fetchIndustrialWarehouseUnits: (warehouseID) => fetchIndustrialWarehouseUnits(warehouseID),
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params)
};
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DashboardPropertiesIndustrial))
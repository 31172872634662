import React from "react";
import ModalCustom from "app/partials/components/ModalCustom";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {AppBar, Tab, Tabs} from "@material-ui/core";
import styles from "./index.module.scss";
import cx from "classnames";
import Tab1 from "./Tab1";
import {callReddClickEvent} from "app/crud/user.crud";
import Button from "../../Button";
import Icon from "app/partials/components/Icon";

class FollowerAddNew extends React.Component {
  constructor(props){
    super(props);
    this.child = React.createRef();
    this.state = {
      activeTab: 'tab_1'
    };
  }
  // Function to change/set new Active Tab
  handleChangeActiveTab = (event, newValue) => {
    this.setState({
      activeTab: newValue
    });
  };
  handleCloseModal = () => {
    this.child.current.handleClose();
  };

  render() {
    const {activeTab} = this.state;
    // Function to determine if given Tab is a Active Tab
    const isActiveTabBarItem = (tab) => {
      return tab === activeTab;
    };
    // Overwrite some base Tabs Bar Styles
    const tabsBarStyles = {
      marginBottom: 18,
      backgroundColor: "transparent",
      color: "#3a3a3a",
      boxShadow: "none"
    };

    return (
      <ModalCustom
        ref={this.child}
        btn={
        <Button
          textIcon={<Icon icon={"add-clean"}/>}
          color="success"
        >
          <FormattedMessage id="SUBSCRIBERS.PHRASE.INVITE_NEW_SUBSCRIBER"/>
        </Button>
        }
        btnRejectDisabled
        onOpen={() => this.props.dispatch(callReddClickEvent(
          "subscribers-invite-button",
          "subscriber"
        ))}
      >
        <div className={styles.tabsWrapper}>
          <div className={styles.tabsBarWrapper}>
            <AppBar position="static" style={tabsBarStyles}>
              <Tabs
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#FF4626"
                    }
                  }}
                  value={activeTab}
                  onChange={this.handleChangeActiveTab}
                  variant="scrollable"
                  scrollButtons="auto"
              >
                <Tab
                    value="tab_1"
                    className={cx(styles.tabBarItem, isActiveTabBarItem("tab_1") && styles.active)}
                    label={<FormattedMessage id="SUBSCRIBERS.PHRASE.INVITE_NEW_SUBSCRIBER"/>}
                    wrapped
                />
              </Tabs>
            </AppBar>
          </div>
          {activeTab === 'tab_1' && <Tab1 onCloseModal={this.handleCloseModal} {...this.props}/>}
        </div>
      </ModalCustom>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.data
});

export default injectIntl(
  connect(
    mapStateToProps
  )(FollowerAddNew)
);
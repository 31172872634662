// FETCH
export const fetchOfficeBasic = (officeID, backgroundLoading) => ({
  type: 'FETCH_OFFICE_BASIC_REQUEST',
  payload: {
    officeID,
    backgroundLoading
  },
});
export const fetchOfficeUnits = (officeID, ordering, backgroundLoading) => ({
  type: 'FETCH_OFFICE_BUILDING_UNITS_REQUEST',
  payload: {
    officeID,
    ordering,
    backgroundLoading
  },
});
export const fetchOfficeLeaseTerms = (officeID, backgroundLoading) => ({
  type: 'FETCH_OFFICE_LEASE_TERMS_REQUEST',
  payload: {
    officeID,
    backgroundLoading
  },
});
export const fetchOfficeMarketplaceStats = (officeID) => ({
  type: 'FETCH_OFFICE_MARKETPLACE_STATS_REQUEST',
  payload: {
    officeID
  },
});
export const fetchOfficeAmenities = (officeID, backgroundLoading) => ({
  type: 'FETCH_OFFICE_BUILDING_AMENITIES_REQUEST',
  payload: {
    officeID,
    backgroundLoading
  },
});
export const fetchOfficeMetrics = (officeID, backgroundLoading) => ({
  type: 'FETCH_OFFICE_METRICS_REQUEST',
  payload: {
    officeID,
    backgroundLoading
  },
});
export const fetchOfficeChanges = (officeID) => ({
  type: 'FETCH_OFFICE_CHANGES_REQUEST',
  payload: {
    officeID
  },
});
export const fetchOfficeSimilarProperties = (officeID) => ({
  type: 'FETCH_OFFICE_SIMILAR_PROPERTIES_REQUEST',
  payload: {
    officeID
  },
});
export const fetchOfficeOwner = (officeID) => ({
  type: 'FETCH_OFFICE_OWNER_REQUEST',
  payload: {
    officeID
  },
});
export const fetchOfficeLeasingContacts = (officeID) => ({
  type: 'FETCH_OFFICE_LEASING_CONTACTS_REQUEST',
  payload: {
    officeID
  },
});
export const fetchOfficeEditFormsData = (officeID) => ({
  type: 'FETCH_OFFICE_EDIT_FORMS_DATA_REQUEST',
  payload: {
    officeID
  },
});
export const fetchOfficeCertificates = (officeID) => ({
  type: 'FETCH_OFFICE_CERTIFICATES_REQUEST',
  payload: {
    officeID
  },
});
export const fetchOfficeCompetitors = (officeID, backgroundLoading) => ({
  type: 'FETCH_OFFICE_COMPETITORS_REQUEST',
  payload: {
    officeID,
    backgroundLoading
  },
});
export const fetchOfficeMarketComparison = (officeID) => ({
  type: 'FETCH_OFFICE_MARKET_COMPARISON_REQUEST',
  payload: {
    officeID
  },
});

// ACTIONS
export const fetchOfficeComparisonXls = (officeID, officeName) => ({
  type: "FETCH_OFFICE_COMPARISON_XLS_REQUEST",
  payload: {
    officeID,
    officeName
  },
});
export const updateOfficePhotosOrder = (officeID, data) => ({
  type: "UPDATE_OFFICE_PHOTOS_ORDER_REQUEST",
  payload: {
    officeID,
    data
  },
});
export const updateOfficeCompetitors = (officeID, data) => ({
  type: "UPDATE_OFFICE_COMPETITORS_REQUEST",
  payload: {
    officeID,
    data
  },
});

// AUTO ACTIONS
export const resetOfficeBuildingState = () => ({
  type: 'RESET_OFFICE_BUILDING_STATE_REQUEST'
});
export const resetOfficeBuildingLoadingStates = () => ({
  type: 'RESET_OFFICE_BUILDING_LOADING_STATES_REQUEST'
});
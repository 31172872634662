import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import OffersOnline from "./OffersOnlinePage";
import {
  createRealTimeEmailOfferOpenLog,
  fetchOfferOnline,
  fetchOfferOnlineBranding,
  fetchOfferOnlineListPhotos,
  fetchOfferOnlineOffice,
  fetchOfferOnlineOfficeContact
} from "app/crud/offers.crud";

const mapStateToProps = (state) => ({
  offerItems: state.shortlists.offerItems,
  loadingOfferItems: state.shortlists.loadingOfferItems,
  offerBranding: state.shortlists.offerBranding,
  offerBranding404: state.shortlists.offerBranding404,
  loadingOfferBranding: state.shortlists.loadingOfferBranding,
  offerOffice: state.shortlists.offerOffice,
  offerOffice404: state.shortlists.offerOffice404,
  loadingOfferOffice: state.shortlists.loadingOfferOffice,
  offerContact: state.shortlists.offerContact,
  loadingOfferContact: state.shortlists.loadingOfferContact,
  offerPhotos: state.shortlists.offerPhotos,
  loadingPhotos: state.shortlists.loadingPhotos
});
const mapDispatchToProps = {
  fetchOfferOnline: (offerID, email, market) => fetchOfferOnline(offerID, email, market),
  fetchOfferOnlineOffice: (offerID, officeID, email) => fetchOfferOnlineOffice(offerID, officeID, email),
  fetchOfferOnlineBranding: (offerID) => fetchOfferOnlineBranding(offerID),
  fetchOfferOnlineOfficeContact: (offerID, officeID, email) => fetchOfferOnlineOfficeContact(offerID, officeID, email),
  fetchOfferOnlineListPhotos: (offerID, email) => fetchOfferOnlineListPhotos(offerID, email),
  createRealTimeEmailOfferOpenLog: (data) => createRealTimeEmailOfferOpenLog(data)
};
export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OffersOnline)
);
import React from "react";
import styles from "./index.module.scss";
import cn from "classnames";
import TooltipCustom from "../TooltipCustom";

function MarkupWrapper(tooltip, children) {
  if(tooltip) {
    return(
      <TooltipCustom title={tooltip}>{children}</TooltipCustom>
    );
  }
  return children;
}
export default function AdminOnlyMarkup({children, type, tooltip}) {
  return(
    <span className={cn(
      styles.adminOnlyWrapper,
      type === "font" ? styles.font : undefined,
      type === "fontLight" ? styles.fontLight : undefined,
      type === "svg" ? styles.svg : undefined,
      type === "section" ? styles.section : undefined,
      type === "input" ? styles.input : undefined,
      type === "editTitle" ? styles.editTitle : undefined
    )}>
      {MarkupWrapper(tooltip, children)}
    </span>
  );
}
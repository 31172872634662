import React from "react";
import HighlightLabel from "../../../../partials/components/HighlightLabel";
import styles from "../index.module.scss";
import {toNumberFormat} from "../../../../utils/helpers";
import SYMMCO from "../../../../constants/SYMMCO";
import cn from "classnames";
import {FormattedMessage} from "react-intl";
import TransactionPolicyMessage from "./TransactionPolicyMessage";
import {injectIntl} from "react-intl";
import TransactionOwned from "./TransactionOwned";

class TransactionDetailsView extends React.Component {
  // Handlers
  handleBlank = (hiddenFields, fieldValue, fieldType) => {
    const isLeasePeriod = fieldType === 'lease_period';
    const isNumeric =
      fieldType === 'headline_rent' ||
      fieldType === 'effective_rent' ||
      fieldType === 'rent_free' ||
      fieldType === 'capex_contribution' ||
      fieldType === 'service_charge' ||
      fieldType === 'broker_fee';
    const isHidden = hiddenFields?.includes(fieldType);

    if (isHidden) {
      const blurValue = isLeasePeriod ? '0 years' :
        isNumeric  ? '1.23' : 'Lorem Ipsum';
      return <span
        className={cn(
          styles.blurValue
        )}
      >{blurValue}</span>;
    }
    return fieldValue;
  }

  render() {
    const {transaction, marketsIndustrial, intl} = this.props;
    const transactionDate = [
      ...transaction?.transactionDateQuater >= 0 ? [`Q${transaction?.transactionDateQuater+1}`] : [],
      ...transaction?.transactionDateYear ? [transaction?.transactionDateYear] : []
    ].join(" ");
    const marketName = marketsIndustrial?.filter(market => market?.id === transaction?.market)?.[0]?.name;
    const zoneName = SYMMCO.ZONE.filter(zone => zone?.id === transaction?.zone)?.[0]?.name || "";
    const dealTypeName = SYMMCO.DEAL_TYPE.filter(zone => zone?.id === transaction?.dealType)?.[0]?.name || "";
    const netDealSize = SYMMCO.NET_DEAL.filter(deal => deal?.id === transaction?.netDealSize)?.[0]?.name || transaction?.netDealSize;
    const dealSourceName = SYMMCO.DEAL_SOURCE.filter(source => source?.id === transaction?.dealSource)?.[0]?.name || "";
    const tenantsIndustryName = SYMMCO.TENANTS_INDUSTRY.filter(tenant => tenant?.id === transaction?.tenantIndustry)?.[0]?.name || "";
    const isHidden = transaction?.hiddenFields?.length > 0;

    return(
      <div className={styles.transactionDetailsWrapper}>
        {isHidden &&
        <TransactionPolicyMessage market={marketName} quarter={transactionDate}/>
        }
        <div className={styles.areaWrapper}>
          <HighlightLabel color="neutral2">{toNumberFormat(netDealSize,"area")}</HighlightLabel>
          {transaction?.isEditable && <TransactionOwned style={{marginLeft: "1rem"}}/>}
        </div>
        <table>
          <tbody>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Transaction date"/></td>
              <td>{transactionDate}</td>
            </tr>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Market"/></td>
              <td>{marketName}</td>
            </tr>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Zone"/></td>
              <td>{zoneName ? <FormattedMessage id={zoneName}/> : "-"}</td>
            </tr>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Deal type"/></td>
              <td>{dealTypeName ? <FormattedMessage id={dealTypeName}/> : "-"}</td>
            </tr>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Tenant industry"/></td>
              <td>{this.handleBlank(transaction?.hiddenFields, tenantsIndustryName, 'tenant_industry')}</td>
            </tr>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Lease period"/></td>
              <td>{this.handleBlank(transaction?.hiddenFields, <FormattedMessage id="BUILDING.PHRASE.YEARS_VALUE" values={{count: transaction?.leasePeriod, years: transaction?.leasePeriod}}/>, 'lease_period')}</td>
            </tr>
            <tr>
              <td colSpan={2}><span className={styles.separator}/></td>
            </tr>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Headline rent"/></td>
              <td>{this.handleBlank(transaction?.hiddenFields, toNumberFormat(transaction?.headlineRent, 'currency'), 'headline_rent')}</td>
            </tr>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Effective rent"/></td>
              <td>{this.handleBlank(transaction?.hiddenFields, toNumberFormat(transaction?.effectiveRent, 'currency'), 'effective_rent')}</td>
            </tr>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Rent free"/></td>
              <td>{this.handleBlank(transaction?.hiddenFields, `${transaction?.rentFree} ${intl.formatMessage({id: transaction?.rentFree > 1 ? "mths" : "mth"})}`, 'rent_free')}</td>
            </tr>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Capex contribution"/></td>
              {transaction?.capexContribution &&
              <td>{this.handleBlank(transaction?.hiddenFields, toNumberFormat(transaction?.capexContribution, 'range', null, 'EUR/m2'), 'capex_contribution')}</td>
              }
              {transaction?.capexContributionTotal &&
              <td>{this.handleBlank(transaction?.hiddenFields, toNumberFormat(Number(transaction?.capexContributionTotal).toFixed(0), 'currency'), 'capex_contribution_total')}</td>
              }
              {!transaction?.capexContribution && !transaction?.capexContributionTotal &&
              <td>{this.handleBlank(transaction?.hiddenFields, toNumberFormat(transaction?.capexContribution, 'range', null, 'EUR/m2'), 'capex_contribution')}</td>
              }
            </tr>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Service charge"/></td>
              <td>{this.handleBlank(transaction?.hiddenFields, toNumberFormat(transaction?.serviceCharge, 'range', null, 'EUR/m2'), 'service_charge')}</td>
            </tr>
            <tr>
              <td colSpan={2}><span className={styles.separator}/></td>
            </tr>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Deal source"/></td>
              <td>{this.handleBlank(transaction?.hiddenFields, `${dealSourceName ? intl.formatMessage({id: dealSourceName}) : ''}`, 'deal_source')}</td>
            </tr>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Broker fee"/></td>
              <td>{this.handleBlank(transaction?.hiddenFields, `${transaction?.brokerFee} ${intl.formatMessage({id: transaction?.brokerFee > 1 ? "mths" : "mth"})}`, 'broker_fee')}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default injectIntl(TransactionDetailsView);
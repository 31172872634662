import React from "react";
import {Grid, Typography} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {deleteIndustrialPark} from "../../../../../crud/estates/industrial/industrial.crud";
import Button from "../../../Button";

class EditParkDeleteProperty extends React.Component {
  // Handlers
  handleSave = () => {
    const {parkID, deleteIndustrialPark} = this.props;
    deleteIndustrialPark(parkID);
  };

  render() {
    return(
      <Grid container spacing={2} justifyContent="center" alignItems="center" direction="column" style={{height: "100%"}}>
        <Grid item>
          <Typography variant="h3" style={{textAlign: "center"}}>
            <FormattedMessage id="INDUSTRIAL.PHRASE.DELETE_PARK_MESSAGE"/>
            <br/>
            <FormattedMessage id="GENERAL.PHRASE.ACTION_CONFIRMATION_MESSAGE"/>
          </Typography>
        </Grid>
        <Grid item>
          <Button onClick={() => this.handleSave()}>
            <FormattedMessage id="VOCABULARY.Delete park"/>
          </Button>
        </Grid>
      </Grid>
    );
  }
}

const mapDispatchToProps = {
  deleteIndustrialPark: parkID => deleteIndustrialPark(parkID)
};

export default injectIntl(
  connect(
    null,
    mapDispatchToProps
  )(EditParkDeleteProperty)
);
import React from 'react';
import {Text, View, StyleSheet} from '@react-pdf/renderer';
import OfferPdfPageHeader from "../components/OfferPdfPageHeader";
import OfferPdfPageFooter from "../components/OfferPdfPageFooter";
import {toNumberFormat} from "../../../../../utils/helpers";
import OfferPdfPage from "../components/OfferPdfPage";

// Create styles
const styles = StyleSheet.create({
  contactSection: {
    flex: 1,
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  contactBox: {
    width: 'auto', // Make it full width of its parent
    backgroundColor: '#FBFBFB',
    borderRadius: 3,
    padding: 10,
  },
  contactBoxFullWidth: {
    width: '100%', // Make it full width of its parent
  },
  contactInfo: {
    flex: 1,
    justifyContent: 'center', // This will vertically center the text
  },
  contactLabel: {
    marginBottom: 2, // Reduced margin to bring text closer together
    fontWeight: 'medium',
  },
  contactName: {
    fontSize: 8,
    padding: 0,
  },
  sectionTitle: {
    fontSize: 12,
    fontWeight: 'medium',
    marginBottom: 5,
  },
  additionalInformationSection: {
    marginBottom: 10
  },
  additionalInformationContent: {
    width: '100%'
  },
  additionalInformationDescription: {
    marginBottom: 5,
    textAlign: 'left'
  },
  propertiesOfferedSection: {
    width: '65%'
  },
  propertyRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#E6E6E6',
    borderBottomStyle: 'solid',
    alignItems: 'flex-start',
    minHeight: 24,
  },
  propertyHeaderRow: {
    backgroundColor: '#f9f9f9',
    minHeight: 20, // 20% smaller than the regular row (30 * 0.8 = 24)
  },
  unitCell: {
    textAlign: 'left',
    paddingVertical: 5, // Changed from 5 to 3
    paddingHorizontal: 10, // Changed from 5 to 10
    width: '60%'
  },
  unitCellRight: {
    textAlign: 'right',
    paddingVertical: 5, // Changed from 5 to 3
    paddingHorizontal: 10, // Changed from 5 to 10
    width: '20%'
  },
  propertyHeaderCell: {
    textAlign: 'left',
    paddingVertical: 5, // Changed from 5 to 3
    paddingHorizontal: 10, // Changed from 5 to 10
    width: '60%'
  },
  propertyHeaderCellRight: {
    textAlign: 'right',
    paddingVertical: 5, // Changed from 5 to 3
    paddingHorizontal: 10, // Changed from 5 to 10
    width: '20%'
  },
  propertyTitle: {
    fontWeight: 'medium',
    whiteSpace: 'nowrap',
  },
  propertyAddress: {
    fontSize: 8,
    color: '#575757',
    marginTop: 1
  },
  unitValue: {
    fontWeight: 'medium',
    paddingVertical: 2
  },
  sectionsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5, // Changed from 10 to 5
  },
  leftColumn: {
    width: '65%'
  },
  rightColumn: {
    width: '30%'
  },
  mainColumnTopContactSection: {
    marginBottom: 10,
    width: '65%'
  }
});

// Create Document Component
function OfferPdfSummaryPage(props) {
  const {
    intl,
    data,
    isSingleProperty
  } = props;
  const owner = data?.owner;
  const additionalInformation = data?.ownerNote;
  const propertiesOffered = data?.records;
  // Function to translate strings
  const handleStringTranslation = (id, values) => {
    return intl.formatMessage({id: id}, values);
  };
  // Common
  // Offer contacts data
  const ownerName = [
    ...owner?.firstName ? [owner?.firstName] : [],
    ...owner?.lastName ? [owner?.lastName] : []
  ].join(" ");
  const ownerEmail = owner?.email;
  const ownerPhone = owner?.phone;
  // Note
  const isNoteAvailable = additionalInformation && additionalInformation !== "" && additionalInformation?.length > 0;
  console.log(isNoteAvailable);

  return (
    <OfferPdfPage {...props}>
      {/* Page Header */}
      <OfferPdfPageHeader
        target="summary"
        isSingleProperty={isSingleProperty}
        {...props}
      />

      {/* Additional Information Section */}
      {isNoteAvailable ?
      <View style={styles.sectionsContainer}>
        <View style={styles.leftColumn}>
          <View style={styles.additionalInformationSection}>
            {isSingleProperty && <Text style={styles.sectionTitle}>{handleStringTranslation("LEADS.PHRASE.ADDITIONAL_INFORMATION")}</Text>}
            <View style={styles.additionalInformationContent}>
              <Text style={styles.additionalInformationDescription}>{additionalInformation}</Text>
            </View>
          </View>
        </View>
        {!isSingleProperty ?
        <View style={styles.rightColumn}>
          <View style={styles.contactBox}>
            <View style={styles.contactInfo}>
              <Text style={styles.contactLabel}>{ownerName}</Text>
              {ownerPhone && <Text style={styles.contactName}>{ownerPhone}</Text>}
              {ownerEmail && <Text style={styles.contactName}>{ownerEmail}</Text>}
            </View>
          </View>
        </View> : null
        }
      </View> : null
      }

      {/* Offer Contact if no Additional Information */}
      {!isNoteAvailable && !isSingleProperty ?
        <View style={styles.mainColumnTopContactSection}>
          <View style={[styles.contactBox, styles.contactBoxFullWidth]}>
            <View style={styles.contactInfo}>
              <Text style={styles.contactLabel}>{ownerName}</Text>
              {ownerPhone && <Text style={styles.contactName}>{ownerPhone}</Text>}
              {ownerEmail && <Text style={styles.contactName}>{ownerEmail}</Text>}
            </View>
          </View>
        </View> : null
      }

      {/* Featured properties Section */}
      {!isSingleProperty ?
      <View style={styles.propertiesOfferedSection}>
        <Text style={styles.sectionTitle}>{handleStringTranslation("VOCABULARY.Featured properties")}</Text>
        {/* Table Header */}
          <View style={[styles.propertyRow, styles.propertyHeaderRow]}>
            <Text style={styles.propertyHeaderCell}>{handleStringTranslation("VOCABULARY.Building")}</Text>
            <Text style={styles.propertyHeaderCellRight}>{handleStringTranslation("GENERAL.PHRASE.UNITS")}</Text>
            <Text style={styles.propertyHeaderCellRight}></Text>
          </View>

          {/* Table Rows */}
          {propertiesOffered?.map((building, index) => {
            const buildingName = building?.assetName;
            const buildingStreet = building?.assetStreet;
            const buildingCity = building?.assetCity;
            const buildingUnits = building?.assetUnits;
            const buildingAddress = [
              ...buildingStreet ? [buildingStreet] : [],
              ...buildingCity ? [buildingCity] : []
            ].join(", ");

            return (
              <View key={index} style={styles.propertyRow} wrap={false}>
                <View style={styles.unitCell}>
                  <Text style={styles.propertyTitle}>{buildingName}</Text>
                  <Text style={styles.propertyAddress}>{buildingAddress}</Text>
                </View>
                <View style={styles.unitCellRight}>
                  {buildingUnits?.length > 0 && buildingUnits?.map((unit, index) => {
                    const unitArea = unit?.area ? toNumberFormat(unit?.area) + " m²" : "0 m²";
                    return(
                      <Text key={index} style={styles.unitValue}>{unitArea}</Text>
                    );
                  })}
                </View>
                <View style={styles.unitCellRight}>
                  {buildingUnits?.length > 0 && buildingUnits.map((unit, index) => {
                    return(
                      <Text key={index} style={styles.unitValue}>{unit?.floor} {handleStringTranslation("GENERAL.PHRASE.FLOOR_TOLOWER")}</Text>
                    );
                  })}
                </View>
              </View>
            )
          })}
      </View> : null
      }

      {/* Amenities and Certificates Sections */}
      {ownerName && isSingleProperty ? (
        <View style={styles.contactSection}>
          <Text style={styles.sectionTitle}>{handleStringTranslation("VOCABULARY.Contact")}</Text>
          <View style={[styles.contactBox, isSingleProperty ? styles.contactBoxFullWidth : undefined]}>
            <View style={styles.contactInfo}>
              <Text style={styles.contactLabel}>{ownerName}</Text>
              {ownerPhone && <Text style={styles.contactName}>{ownerPhone}</Text>}
              {ownerEmail && <Text style={styles.contactName}>{ownerEmail}</Text>}
            </View>
          </View>
        </View>
      ) : null}

      {/* Footer */}
      <OfferPdfPageFooter {...props}/>
    </OfferPdfPage>
  );
}

export default OfferPdfSummaryPage;


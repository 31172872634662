import React from "react";
import IconColorMUI from "../../../../partials/components/IconColorMUI";
import styles from "../index.module.scss";
import TooltipCustom from "../../../../partials/components/TooltipCustom";
import {FormattedMessage} from "react-intl";

export default function TransactionOwned({style, size = "18px", tooltipDisabled}) {
  if(tooltipDisabled) {
    return(
      <span className={styles.transactionOwnedWrapper} style={style}>
        <IconColorMUI
          icon="key"
          size={size}
          color="white"
          style={{lineHeight: "0"}}
        />
      </span>
    );
  }
  return(
    <TooltipCustom title={<FormattedMessage id="VOCABULARY.Transaction owned"/>}>
      <span className={styles.transactionOwnedWrapper} style={style}>
        <IconColorMUI
          icon="key"
          size={size}
          color="white"
          style={{lineHeight: "0"}}
        />
      </span>
    </TooltipCustom>
  );
}
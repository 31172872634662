import React from "react";
import LineChart from "app/partials/components/Charts/LineChart";
import {FormattedMessage} from "react-intl";


export default class OwnerReddIndex extends React.Component {
  setChartData = () => {
    const {ownerReddIndex} = this.props;
    if(ownerReddIndex) {
      let ownerReddIndexArray = [];
      Object.entries(ownerReddIndex).map((item) => {
        const set = {
          "label": item[0],
          "value": item[1].toFixed(2)
        };
        return ownerReddIndexArray.push(set);
      });
      return ownerReddIndexArray.reverse();
    }
    return null;
  };
  render() {
    return(
      <LineChart
          dataChart={this.setChartData()}
          desc={<FormattedMessage id="OWNERS.PHASE.AVERAGE_FOR_ALL_BUILDINGS_IN_PORTFOLIO"/>}
          labelX="GENERAL.PHRASE.DATE"
          labelY="GENERAL.PHRASE.NUMBER_OF_DAYS"
          height={this.props.height}
      />
    );
  }
}
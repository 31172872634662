import React from "react";
import {ProgressBar} from "react-bootstrap";
import styles from "./index.module.scss";
import cn from "classnames";
import {toNumberFormat} from "app/utils/helpers";
import BlurText from "../BlurText";

export default class ProgressBox extends React.Component {
  render() {
    const {
      style,
      styleValue,
      styleBar,
      baseValue,
      totalValue,
      percent,
      isBlurred
    } = this.props;
    let value;
    if(isBlurred) {
      value = "123";
    }
    else {
      if(percent || percent === 0 || percent === null) {
        const percentValue = percent === null ? 100 : percent > 100 ? 100 : percent < 0 ? 0 : percent;
        value = Number(percentValue).toFixed();
      } else {
        if(baseValue !== 0 && totalValue !== 0) {
          value = toNumberFormat(Math.round((baseValue*100) / totalValue));
        } else {
          value = 0;
        }
      }
    }
    const capacityColor = value > 75 ? 'success' : value > 35 ? 'warning' : 'danger';

    return(
      <div
        className={styles.progressBarWrapper}
        style={style}
      >
        <span
          className={cn(
            styles.barWrapper,
            isBlurred ? styles.blurBar : undefined
          )}
          style={styleBar}
        >
          <ProgressBar variant={capacityColor || "success"} now={value} key={1} />
        </span>

          <BlurText
            type="custom"
            customBlurValue={
            <span className={styles.valueLabel} style={styleValue}>
              {`${value}%`}
            </span>
            }
            isBlurred={isBlurred}
          >
            <span className={styles.valueLabel} style={styleValue}>
              {isNaN(value) ? '-' : `${value}%`}
            </span>
          </BlurText>
      </div>
    );
  }
}

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {makeStyles, Snackbar, SnackbarContent} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import Button from "../Button";
import Icon from "app/partials/components/Icon";

const useStyles1 = makeStyles(theme => ({
  common: {
    fontSize: '1.4rem',
    color: '#2D2D2D',
    borderBottom: '3px solid',
    borderRadius: 0
  },
  success: {
    backgroundColor: '#EEF8F3',
    borderColor: '#55B88B'
  },
  error: {
    backgroundColor: '#FFE1E1',
    borderColor: '#EC5638'
  },
  info: {
    backgroundColor: '#A2A5B8',
    borderColor: '#55B88B'
  },
  warning: {
    backgroundColor: '#FDEFD3',
    borderColor: '#F7C86D'
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 1,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    gap: "1rem"
  },
}));

function MySnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;

  return (
    <SnackbarContent
      className={clsx(classes[variant], classes?.common, className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon icon={"info-circle"} color={variant} size={"2rem"}/>
          {message}
        </span>
      }
      action={[
        <Button
          type={"icon"}
          color={"simple"}
          onClick={onClose}
        >
          <Icon icon={"close"}/>
        </Button>
      ]}
      {...other}
    />
  );
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

export default function PageMessages(props) {
  const {
    message,
    variant,
    isOpen,
    timeout,
    destroyNotification,
    style,
    vertical = 'top',
    horizontal = 'right'
  } = props;

  function handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    destroyNotification();
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: vertical,
        horizontal: horizontal,
      }}
      open={isOpen}
      autoHideDuration={timeout}
      onClose={handleClose}
      style={style}
    >
      <MySnackbarContentWrapper
        onClose={handleClose}
        variant={variant || 'success'}
        message={message && typeof message === "string" ? <FormattedMessage id={message}/> : message}
      />
    </Snackbar>
  );
}

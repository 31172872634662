import React from "react";
import styles from "./index.module.scss";
import SliderHtml from "../../../../partials/components/SliderHtml";
import {FormattedMessage, injectIntl} from "react-intl";
import PhoneNumberShow from "../../../../partials/components/PhoneNumberShow";
import cn from "classnames";
import _ from "lodash";
import BlurText from "../../../../partials/components/BlurText";
import IsPro from "../../../../utils/IsPro";
import PaywallModal from "../../../../partials/components/PayWall/components/PaywallModal";
import {callReddClickEvent} from "../../../../crud/user.crud";
import connect from "react-redux/es/connect/connect";
import PropertySectionTitle from "./PropertySectionTitle";

function PropertyHeaderContacts(
  {
    owner,
    contacts,
    propertyID,
    isIndustrialParkView,
    isIndustrialWarehouseView,
    isEditable,
    intl,
    callReddClickEvent,
    logoBreak,
    ...props
  }
) {
  // Owner
  const ownerLogo = owner?.ownerLogo;
  const ownerName = owner?.ownerShortName || owner?.ownerName;
  // Leasing
  const leasingBasicDummyData = [
    {
      email: "example@example.com",
      phone: "+48 000-000-000"
    }
  ];
  const leasingLogo = contacts?.landlordLogo;
  const leasingName = contacts?.landlordShortName || contacts?.landlordName;
  const leasingContactPersons = contacts?.landlordContactPersons ||
    (
      IsPro({
        reverseCheck: true,
        overwriteRule: isEditable
      }) ? leasingBasicDummyData : []
    );
  // Common Data
  const isOwnerLeasingSame = ownerName === leasingName;
  const commonLogo = logoBreak && !props?.oneLine ? null : (ownerLogo || leasingLogo);
  const commonName = ownerName || leasingName;
  const isDataComplete = !_.isEmpty(owner) && leasingContactPersons?.length >= 1;
  
  // Handlers
  const handleMailClick = () => {
    if(callReddClickEvent && propertyID) {
      if(isIndustrialParkView) {
        callReddClickEvent(
          "industrial-park-page-email-click",
          "park",
          null,
          propertyID
        );
      }
      else if(isIndustrialWarehouseView) {
        callReddClickEvent(
          "industrial-warehouse-page-email-click",
          "warehouse",
          null,
          propertyID
        );
      }
      else {
        callReddClickEvent(
          "office-building-page-email-click",
          "office",
          null,
          propertyID
        );
      }
    }
  }

  // Helpers
  const contactItem = (contact, target) => {
    const contactPhone = contact?.phone;
    const contactEmail = contact?.email;
    const isLeasing = target === "leasing";
    const isOwner = target === "owner";
    const logo = logoBreak && !props?.oneLine ? null : (isLeasing ? leasingLogo : isOwner ? ownerLogo : null);
    const name = isLeasing ? leasingName : isOwner ? ownerName : null;
    const blurEnable = !IsPro({overwriteRule: props.overwritePro}) && !isEditable;

    return(
      <div className={cn(
        styles.contactWrapper,
        isOwner ? styles.ownerContactWrapper : undefined
      )}>
        {(!isOwnerLeasingSame || (props?.oneLine && isOwner)) && logo && !blurEnable &&
          <div className={styles.contactLogo}>
            <img src={logo} alt={`${name}`}/>
          </div>
        }
        <div className={styles.contactData}>
          <p>{isLeasing ? <FormattedMessage id="VOCABULARY.Leasing Manager"/> : isOwner ? <FormattedMessage id="VOCABULARY.Property Owner"/> : ""}</p>
          {isOwner &&
            <BlurText
              type="string"
              isBlurred={blurEnable}
            >
              {ownerName && <p className={styles.ownerName}>{ownerName}</p>}
            </BlurText>
          }
          {isLeasing &&
            <BlurText
              type="phone"
              isBlurred={blurEnable}
            >
              {contactPhone ?
              <PhoneNumberShow
                phone={contactPhone}
                propertyID={propertyID}
                isIndustrialParkView={isIndustrialParkView}
                isIndustrialWarehouseView={isIndustrialWarehouseView}
              /> : ""
              }
            </BlurText>
          }
          {isLeasing &&
            <BlurText
              type="email"
              isBlurred={blurEnable}
            >
              {contactEmail ?
                <a
                  href={`mailto:${contactEmail}`}
                  onClick={() => handleMailClick()}
                >{contactEmail}</a> : ""}
            </BlurText>
          }
        </div>
        {isLeasing &&
        <IsPro reverseCheck overwriteRule={props?.overwritePro}>
          <div className={styles.unlockWrapper}>
            <PaywallModal
              triggerLabel={<FormattedMessage id="VOCABULARY.Show"/>}
              triggerSlim
              triggerButtonVariant={intl.formatMessage({id: "VOCABULARY.Show"})}
              withIcon
            />
          </div>
        </IsPro>
        }
      </div>
    );
  }
  const getContacts = (target) => {
    let result = [];
    if(target === "leasing" && leasingContactPersons?.length > 0) {
      leasingContactPersons.map(contact => result.push(contactItem(contact, target)));
    }
    return result;
  };

  if(isOwnerLeasingSame) {
    return(
      <>
        {!props?.oneLine && <PropertySectionTitle title={<FormattedMessage id="VOCABULARY.Contact"/>} style={{marginBottom: "2.4rem"}}/>}
        <div className={cn(
          styles.propertyContactsWrapper,
          props?.oneLine ? styles.variant1 : styles.variant2,
          props?.oneLine ? styles.extraBackground : undefined
        )}
        >
          {commonLogo && !props?.oneLine && <div className={styles.logoWrapper}><img src={commonLogo} alt={`${commonName}`}/></div>}
          <div className={cn(
            styles.dataWrapper,
            !commonLogo ? styles.fullWidth : undefined,
            props?.oneLine ? styles.oneLine : undefined
          )}>
            {!_.isEmpty(owner) && contactItem(null, "owner")}
            {isDataComplete && !props?.oneLine && <span className={styles.lineSeparator}/>}
            {
            leasingContactPersons?.length > 1 ?
              <div className={styles.contactSliderWrapper}>
                <SliderHtml data={getContacts("leasing")} alternative/>
              </div> :
                leasingContactPersons?.length === 1 && contactItem(leasingContactPersons?.[0], "leasing")
            }
          </div>
        </div>
      </>
    );
  }
  return(
    <>
      {!props?.oneLine && <PropertySectionTitle title={<FormattedMessage id="VOCABULARY.Contact"/>} style={{marginBottom: "2.4rem"}}/>}
      <div
        className={cn(
          styles.propertyContactsWrapper,
          styles.variant1,
          props?.oneLine ? styles.extraBackground : undefined
        )}
      >
        {!_.isEmpty(owner) && contactItem(null, "owner")}
        {isDataComplete && !props?.oneLine && <span className={styles.lineSeparator}/>}
        {
          leasingContactPersons?.length > 1 ?
            <div className={styles.contactSliderWrapper}><SliderHtml data={getContacts("leasing")} alternative/></div> :
            leasingContactPersons?.length === 1 && contactItem(leasingContactPersons?.[0], "leasing")
        }
      </div>
    </>
  );
}

const mapDispatchToProps = {
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params)
};

export default injectIntl(
  connect(
    null,
    mapDispatchToProps
  )(PropertyHeaderContacts)
);
import React from "react";
import {TextField, Typography} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import axios from "axios";
import {addSubscribersXlsError, inviteSubscriber} from "app/crud/subscribers.crud";
import styles from "./index.module.scss";
import IconColorMUI from "app/partials/components/IconColorMUI";
import cx from "classnames";
import {downloadFileFromUrl} from "app/utils/helpers";
import {toAbsoluteUrl} from "_metronic";
import {filterByModules} from "../../../../crud/estates/filters.crud";
import Button from "../../Button";
import Icon from "../../Icon";

class FileUpload extends React.Component {
  static defaultProps = {
    multiple: false,
  };

  constructor(props) {
    super(props);
    this.fileUploadRef = React.createRef();
  }

  handleAddFile = () => {
    this.fileUploadRef.current.click();
  };

  handleChangeFiles = e => {
    const {target} = e;

    if (target.files) {
      Object.values(target.files).forEach(file => {
        const reader = new FileReader();
        reader.onload = readerEvent => {
          this.props.onAddFile({
            data: readerEvent.target.result,
            type: file.type,
            name: file.name,
          });
        };
        reader.readAsArrayBuffer(file);
      });
    }
  };

  render() {
    return (
      <>
        <input
          ref={this.fileUploadRef}
          type="file"
          id="file"
          accept=".xlsx,.xls"
          style={{display: 'none'}}
          multiple={this.props.multiple}
          onChange={this.handleChangeFiles}
        />
        {this.props.caller(this.handleAddFile)}
      </>
    );
  }
}

class Tab1 extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      open: false,
      subscriberEmail: "",
      subscribersList: [],
      selectedSubscribers: [],
      sharedSuccess: false
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.invited && !prevProps.invited && prevState.sharedSuccess !== this.props.invited) {
      this.setState({
        sharedSuccess: this.props.invited
      }, () => setTimeout(() => {
        this.setState({
          subscriberEmail: "",
          subscribersList: [],
          selectedSubscribers: [],
          sharedSuccess: false
        })
      }, 5000));
    }
  }

  handleChange = (event) => {
    if (event.target.value.length <= 512) {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };
  handleAddSubscriberEmail = (event) => {
    event.preventDefault();
    const mailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,8})+$/;
    const {subscribersList, subscriberEmail} = this.state;
    if (subscriberEmail.length && subscriberEmail.match(mailFormat)) {
      subscribersList.push(subscriberEmail);
      this.setState({
        subscriberEmail: ""
      });
    }
  };
  handleAddFile = ({data, type}) => {
    const {addSubscribersXlsError} = this.props;
    const headers = {
      'Content-Type': type,
      'Content-Disposition': `attachment; filename=upload-subscribers`,
    };
    return axios
      .post(`/subscribers-v2/emails_from_xls/`, data, {
        headers,
      })
      .then(({data}) => {
        if (data && data.emails) {
          this.setState({
            subscribersList: [...this.state.subscribersList, ...data.emails]
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error);
        }
        addSubscribersXlsError();
      });
  };
  handleRemoveFollower = (follower) => {
    this.setState({
      subscribersList: this.state.subscribersList.filter(subscriber => subscriber !== follower)
    })
  };

  handleInviteFollower = () => {
    const {inviteSubscriber} = this.props;
    const {subscribersList} = this.state;
    const data = {
      emails: subscribersList
    };
    if (subscribersList && subscribersList.length) {
      inviteSubscriber(data);
      const finalParams = {
        page: 1
      };
      this.props.filterByModules(finalParams);
    }
  };

  render() {
    const {team, invitingSubscriber, invited} = this.props;
    if (team !== null) {
      const {subscriberEmail, subscribersList, sharedSuccess} = this.state;
      if (invited && sharedSuccess) {
        return (
          <div className={styles.addFollowersSharedSuccessWrapper}>
            <span className={styles.sharedSuccessIcon}><IconColorMUI icon="check" color="danger"/></span>
            <span className={styles.sharedSuccessMessage}><FormattedMessage
              id="You successfully invite new Follower."/></span>
          </div>
        );
      }
      return (
        <>
          <div className={styles.addFollowerWrapper}>
            <form onSubmit={this.handleAddSubscriberEmail}>
              <TextField
                id="invite_new_subscriber"
                name="subscriberEmail"
                label={<FormattedMessage id="SUBSCRIBERS.PHRASE.ADD_FOLLOWER_ADDRESS_EMAIL"/>}
                value={subscriberEmail}
                onChange={this.handleChange}
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </form>
            <Button onClick={this.handleAddSubscriberEmail}>
              <FormattedMessage id="GENERAL.PHRASE.ADD"/>
            </Button>
            <FileUpload
              onAddFile={this.handleAddFile}
              caller={onClick => (
                <Button
                  textIcon={<Icon icon={"add-clean"}/>}
                  onClick={onClick}
                >
                  <FormattedMessage id="GENERAL.PHRASE.UPLOAD_A_XLS_FILE"/>
                </Button>
              )}
            />
          </div>
          <div className={styles.downloadSampleWrapper}>
                        <span className={styles.btnDownload}
                              onClick={() => downloadFileFromUrl(toAbsoluteUrl("media/REDD_Import_Followers_Sample_File.xls"))}><IconColorMUI
                          icon="file_download"/><FormattedMessage
                          id="PROPERTY_PARTICULARS.PHRASE.DOWNLOAD_SAMPLE_FILE"/></span>
          </div>
          <div className={cx(styles.listFollowers, !subscribersList.length && styles.flex)}>
            {subscribersList && subscribersList.length > 0 ? subscribersList.map((subscriber, index) => {
                return (
                  <div key={index} className={styles.follower}><span className={styles.followerRemove}
                                                                     onClick={() => this.handleRemoveFollower(subscriber)}><IconColorMUI
                    icon="close" color="danger"/></span>{subscriber}</div>
                );
              }) :
              <Typography variant="body2"><FormattedMessage id="SUBSCRIBERS.PHRASE.ADD_PEOPLE_MESSAGE"/></Typography>}
          </div>
          <div className={styles.shareActions}>
            <div/>
            <Button
              textIcon={<Icon icon={"add-clean"}/>}
              loading={invitingSubscriber}
              inactive={subscribersList && subscribersList.length <= 0}
              onClick={() => this.handleInviteFollower()}
            >
              <FormattedMessage id="GENERAL.PHRASE.INVITE"/>
            </Button>
          </div>
        </>
      );
    }
    return true;
  }
}

const mapStateToProps = ({user, subscribers}) => ({
  team: user.data.team,
  invitingSubscriber: subscribers.invitingSubscriber,
  invited: subscribers.invited

});
const mapDispatchToProps = {
  addSubscribersXlsError: () => addSubscribersXlsError(),
  inviteSubscriber: (data) => inviteSubscriber(data),
  filterByModules: (params) => filterByModules(params)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Tab1)
);
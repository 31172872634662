import React from "react";
import {Grid, Typography} from "@material-ui/core";
import {Portlet, PortletBody, PortletHeader} from "../../../partials/content/Portlet";
import BadgeTitle from "../../../partials/components/BadgeTitle";
import {FormattedMessage} from "react-intl";
import CAStepsIntro from "./components/CAStepsIntro";
import CACompareTable from "./components/CACompareTable";
import styles from "./index.module.scss";
import Icon from "@material-ui/core/Icon/Icon";
import SelectSearch from "../../../partials/components/SelectSearch";
import LoadingScreen from "../../../partials/components/LoadingScreen";
import {createVisibleArray, sortArrayAlph} from "../../../utils/helpers";
import _ from "lodash";
import APPLICATION_VIEWS from "../../../constants/APPLICATION_VIEWS";
import CAFeedback from "./components/CAFeedback";
import ReactVisibilitySensor from "react-visibility-sensor";
import IsPro from "../../../utils/IsPro";
import Button from "../../../partials/components/Button";

export default class CompetitionAnalysis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ownedProperty: '',
      ownedPropertyMarketId: null,
      propertyOne: '',
      propertyTwo: '',
      propertyThree: '',
      isReady: false,
      initLoaded: false
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {currentApplicationView} = this.props;
    if (this.state.isReady) {
      // On Own Property select
      if (Array.isArray(this.state.ownedProperty) && !_.isEqual(prevState.ownedProperty, this.state.ownedProperty)) {
        const ids = [
          ...this.state.ownedProperty ? this.state.ownedProperty : [],
        ];
        if (currentApplicationView === APPLICATION_VIEWS.OFFICES) {
          this.props.compareSelectedOffices({ids}, true);
        } else if (currentApplicationView === APPLICATION_VIEWS.INDUSTRIAL) {
          this.props.compareSelectedParks({ids}, true);
        }
        this.setState({
          propertyOne: '',
          propertyTwo: '',
          propertyThree: ''
        });
      }
      // On Own Property Initial Comparing
      if (currentApplicationView === APPLICATION_VIEWS.OFFICES) {
        if (this.props.officesCompareResults?.length > 0) {
          const ownPropertyMarketId = this.props.officesCompareResults?.[0]?.marketId;

          if (prevState.ownedPropertyMarketId !== ownPropertyMarketId && ownPropertyMarketId) {
            this.setState({
              ownedPropertyMarketId: ownPropertyMarketId
            });
          }
        }
      } else if (currentApplicationView === APPLICATION_VIEWS.INDUSTRIAL) {
        if (this.props.parksCompareResults?.length > 0) {
          const ownPropertyMarketId = this.props.parksCompareResults?.[0]?.marketId;

          if (prevState.ownedPropertyMarketId !== ownPropertyMarketId && ownPropertyMarketId) {
            this.setState({
              ownedPropertyMarketId: ownPropertyMarketId
            });
          }
        }
      }
      // On Own Property Market Changed
      if (prevState?.ownedPropertyMarketId !== this.state.ownedPropertyMarketId && this.state.ownedPropertyMarketId) {
        // Buildings to compare
        if (currentApplicationView === APPLICATION_VIEWS.OFFICES) {
          const params = {
            market: `${this.state.ownedPropertyMarketId}`
          }
          this.props.fetchOfficesSimpleList({params}, false);
        }
        else if (currentApplicationView === APPLICATION_VIEWS.INDUSTRIAL) {
          const params = {
            marketId: `${this.state.ownedPropertyMarketId}`
          }
          this.props.fetchIndustrialParksSimpleList({params}, false);
        }
      }
    }
  }

  // Handlers
  handleAutocompleteChange = (target) => {
    this.setState({
      [target.name]: target.value,
    });
  };
  handleCompare = () => {
    const {compareSelectedOffices, compareSelectedParks, currentApplicationView, callReddClickEvent} = this.props;
    const {ownedProperty, propertyOne, propertyTwo, propertyThree, ownedPropertyMarketId} = this.state;
    const competitionSelected = propertyOne !== '' || propertyTwo !== '' || propertyThree !== '';
    const ids = [
      ...ownedProperty ? ownedProperty : [],
      ...propertyOne ? propertyOne : [],
      ...propertyTwo ? propertyTwo : [],
      ...propertyThree ? propertyThree : []
    ];

    if (ownedProperty !== '' && competitionSelected) {
      if (currentApplicationView === APPLICATION_VIEWS.OFFICES) {
        compareSelectedOffices({ids}, false);
        callReddClickEvent(
          "competition-analysis-offices-compare-button-click",
          "user",
          null,
          null,
          {
            ownedPropertyMarketId,
            ownedProperty,
            compareProperty1: propertyOne,
            compareProperty2: propertyTwo,
            compareProperty3: propertyThree
          }
        );
      }
      else if (currentApplicationView === APPLICATION_VIEWS.INDUSTRIAL) {
        compareSelectedParks({ids}, false);
        callReddClickEvent(
          "competition-analysis-industrial-compare-button-click",
          "user",
          null,
          null,
          {
            ownedPropertyMarketId,
            ownedProperty,
            compareProperty1: propertyOne,
            compareProperty2: propertyTwo,
            compareProperty3: propertyThree
          }
        );
      }
    }
  }
  // Handle fetch data on enter viewport
  handleOnViewportFetch = (isVisible) => {
    const {
      currentApplicationView,
      callReddClickEvent,
      fetchOfficesSimpleList,
      compareSelectedOfficesReset,
      fetchIndustrialParksSimpleList,
      compareSelectedParksReset,
      loading
    } = this.props;
    const {initLoaded, isReady} = this.state;

    // Fetch data
    if(isVisible && !loading && !initLoaded) {
      this.setState({
        initLoaded: true
      }, () => {
        if (currentApplicationView === APPLICATION_VIEWS.OFFICES) {
          compareSelectedOfficesReset();
          if (fetchOfficesSimpleList) {
            const params = {
              isEditable: 'true'
            }
            // Owned Buildings
            fetchOfficesSimpleList({params}, true);
          }
        } else if (currentApplicationView === APPLICATION_VIEWS.INDUSTRIAL) {
          compareSelectedParksReset();
          if (fetchIndustrialParksSimpleList) {
            const params = {
              isEditable: 'true'
            }
            // Owned Parks
            fetchIndustrialParksSimpleList({params}, true);
          }
        }
      });
    }
    // Tracking event
    if(isVisible && !isReady) {
      this.setState({
      isReady: true
    }, () => {
      if (currentApplicationView === APPLICATION_VIEWS.OFFICES) {
        callReddClickEvent(
          "competition-analysis-offices-page-enter",
          "user"
        );
      }
      else if(currentApplicationView === APPLICATION_VIEWS.INDUSTRIAL) {
        callReddClickEvent(
          "competition-analysis-industrial-page-enter",
          "user"
        );
      }
    });
    }
  };

  render() {
    const {
      user,
      offices,
      officesOwned,
      officesComparing,
      officesCompareResults,
      loading,
      parks,
      parksOwned,
      parksComparing,
      parksCompareResults,
      loadingParks,
      currentApplicationView,
      callReddClickEvent
    } = this.props;
    const isUserPremium = user?.isPremium;
    const {ownedProperty, propertyOne, propertyTwo, propertyThree} = this.state;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    const sortProperties = isOfficeView ? (
      offices?.length > 0 ? sortArrayAlph(createVisibleArray(offices, "name")) : []
    ) : (parks?.length > 0 ? sortArrayAlph(createVisibleArray(parks, "name")) : [])
    const sortPropertiesOwned = isOfficeView ? (
      officesOwned?.length > 0 ? sortArrayAlph(createVisibleArray(officesOwned, "name")) : []
    ) : (parksOwned?.length > 0 ? sortArrayAlph(createVisibleArray(parksOwned, "name")) : []);
    const competitorsDisabled = ownedProperty === '';
    const competitionSelected = propertyOne !== '' || propertyTwo !== '' || propertyThree !== '';
    const loadingData = isOfficeView ? loading : loadingParks;

    return(
      <IsPro paywall>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Portlet fluidHeight>
              <ReactVisibilitySensor
                onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
                scrollCheck
                partialVisibility
              >
                <PortletHeader
                  title={
                    <Typography variant="h3">
                      <BadgeTitle
                        badge={<FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.TITLE_PREFIX"/>}><FormattedMessage
                        id="COMPETITION_ANALYSIS.PHRASE.TITLE_SUFFIX"/></BadgeTitle>
                    </Typography>
                  }
                  toolbar={
                  <div style={{display: "flex"}}>
                  <Grid container alignItems="center" justifyContent="flex-end">
                    <CAFeedback
                      currentApplicationView={currentApplicationView}
                      callReddClickEvent={callReddClickEvent}
                    />
                  </Grid>
                  </div>
                  }
                />
              </ReactVisibilitySensor>
              <PortletBody>
                <Grid container spacing={2}>
                  <Grid item>
                    <SelectSearch
                      width="23rem"
                      name="ownedProperty"
                      value={ownedProperty}
                      icon={<Icon color="primary">{isOfficeView ? "business" : "warehouse"}</Icon>}
                      label={
                        isOfficeView ? <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.YOUR_BUILDING"/> :
                          <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.YOUR_PARK"/>
                      }
                      options={sortPropertiesOwned}
                      optionsFull={isOfficeView ? officesOwned : parksOwned}
                      optionFullLabel="name"
                      optionFullValue="id"
                      onChange={this.handleAutocompleteChange}
                    />
                  </Grid>
                  <Grid item>
                    <SelectSearch
                      width="23rem"
                      name="propertyOne"
                      value={propertyOne}
                      icon={<Icon color="primary">{isOfficeView ? "business" : "warehouse"}</Icon>}
                      label={
                        isOfficeView ? <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.SELECT_BUILDING"/> :
                          <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.SELECT_PARK"/>
                      }
                      options={sortProperties}
                      optionsFull={isOfficeView ? offices : parks}
                      optionFullLabel="name"
                      optionFullValue="id"
                      isDisabled={competitorsDisabled || loadingData}
                      disabledLabel={
                      isOfficeView ? <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.SELECT_BUILDING"/> :
                        <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.SELECT_PARK"/>
                      }
                      loading={!competitorsDisabled && loadingData}
                      onChange={this.handleAutocompleteChange}
                    />
                  </Grid>
                  <Grid item>
                    <SelectSearch
                      width="23rem"
                      name="propertyTwo"
                      value={propertyTwo}
                      icon={<Icon color="primary">{isOfficeView ? "business" : "warehouse"}</Icon>}
                      label={
                        isOfficeView ? <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.SELECT_BUILDING"/> :
                          <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.SELECT_PARK"/>
                      }
                      options={sortProperties}
                      optionsFull={isOfficeView ? offices : parks}
                      optionFullLabel="name"
                      optionFullValue="id"
                      isDisabled={competitorsDisabled || loadingData}
                      disabledLabel={
                      isOfficeView ? <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.SELECT_BUILDING"/> :
                        <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.SELECT_PARK"/>
                      }
                      loading={!competitorsDisabled && loadingData}
                      onChange={this.handleAutocompleteChange}
                    />
                  </Grid>
                  <Grid item>
                    <SelectSearch
                      width="23rem"
                      name="propertyThree"
                      value={propertyThree}
                      icon={<Icon color="primary">{isOfficeView ? "business" : "warehouse"}</Icon>}
                      label={
                        isOfficeView ? <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.SELECT_BUILDING"/> :
                          <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.SELECT_PARK"/>
                      }
                      options={sortProperties}
                      optionsFull={isOfficeView ? offices : parks}
                      optionFullLabel="name"
                      optionFullValue="id"
                      isDisabled={competitorsDisabled || loadingData}
                      disabledLabel={
                      isOfficeView ? <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.SELECT_BUILDING"/> :
                        <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.SELECT_PARK"/>
                      }
                      loading={!competitorsDisabled && loadingData}
                      onChange={this.handleAutocompleteChange}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      inactive={competitorsDisabled || !competitionSelected}
                      tooltip={(competitorsDisabled || !competitionSelected) && (
                        isOfficeView ? <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.COMPARE_TOOLTIP"/> :
                          <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.COMPARE_TOOLTIP_INDUSTRIAL"/>
                      )}
                      onClick={() => this.handleCompare()}
                    >
                      <FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.COMPARE"/>
                    </Button>
                  </Grid>
                </Grid>
              </PortletBody>
            </Portlet>
          </Grid>
          <Grid item xs={12}>
            <Portlet>
              {(isOfficeView ? officesComparing : parksComparing) ? <LoadingScreen/> : (
                isOfficeView ? officesCompareResults?.length : parksCompareResults?.length
              ) > 1 ?
                <PortletBody fit>
                  <CACompareTable
                    data={isOfficeView ? officesCompareResults : parksCompareResults}
                    currentApplicationView={currentApplicationView}
                    callReddClickEvent={callReddClickEvent}
                    isUserPremium={isUserPremium}
                  />
                </PortletBody> :
                <PortletBody className={styles.introContainer}>
                  <h2 className={styles.introTitle}>
                    REDD <span className={styles.introTitleBrand}>Dynamic Analytics</span>
                  </h2>
                  <p className={styles.introSubtitle}><FormattedMessage id="COMPETITION_ANALYSIS.PHRASE.INTRO_SUBTITLE"/></p>
                  <CAStepsIntro
                    currentApplicationView={currentApplicationView}
                  />
                  <div className={styles.introCirclesWrapper}>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                  </div>
                </PortletBody>
              }
            </Portlet>
          </Grid>
        </Grid>
      </IsPro>
    );
  }
}
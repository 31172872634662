import React from "react";
import TableMUI from "app/partials/components/TableMUI";
import {TableCell, TableRow} from "@material-ui/core";
import {toDateFormat, toNumberFormat} from "app/utils/helpers";
import styles from "./index.module.scss";
import PropertySectionTitle from "../../../../common/components/PropertyPage/PropertySectionTitle";
import {ROUTES} from "../../../../../constants";
import PropertyCardPreview from "../../../../../partials/components/_DataParts/PropertyCardPreview";
import _ from "lodash";
import ReactVisibilitySensor from "react-visibility-sensor";
import {FormattedMessage} from "react-intl";

export default class PropertyRegionSupplyBoost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initLoaded: false
    }
  }
  // Handlers
  handleOnViewportFetch = (isVisible) => {
    const {fetchIndustrialParkRegionSupplyBoost, regionSupplyBoost, regionSupplyBoostLoading, match} = this.props;
    const {initLoaded} = this.state;
    const {id} = match?.params;

    setTimeout(() => {
      if(isVisible && !initLoaded && !regionSupplyBoostLoading && _.isEmpty(regionSupplyBoost) && id) {
        this.setState({
          initLoaded: true,
        }, () => fetchIndustrialParkRegionSupplyBoost(id));
      }
    }, 200);
  }

  render() {
    const {regionSupplyBoost, regionSupplyBoostLoading} = this.props;

    return(
      <>
        <ReactVisibilitySensor
          onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
          scrollCheck
          partialVisibility
        >
          <PropertySectionTitle
            title={<FormattedMessage id="VOCABULARY.Supply boost in the region"/>}
            style={{
              marginBottom: "1.6rem"
            }}
          />
        </ReactVisibilitySensor>
        <TableMUI
          loading={regionSupplyBoostLoading}
          containerStyle={{minHeight: "20rem"}}
          totalItems={regionSupplyBoost?.length}
          tableContainerClasses={styles.regionAreaTable}
        >
          {regionSupplyBoost?.length > 0 && regionSupplyBoost?.map((item, index) => {
            return(
              <TableRow key={index}>
                <TableCell
                  align="left"
                  className={styles.excerptTd}
                >
                  {index === 0 ? <FormattedMessage id="VOCABULARY.Active park"/> :
                  <PropertyCardPreview
                    propertyID={item?.parkId}
                    title={item?.parkName}
                    thumb="disabled"
                    route={ROUTES.INDUSTRIAL_PARK}
                    gridSpacing={0}
                    noOverflow
                  />
                  }
                </TableCell>
                <TableCell align="right" style={{fontWeight: "400"}}>
                  {item?.transactionDate && toDateFormat(item?.transactionDate, false, false, false, "M/Y")}
                </TableCell>
                <TableCell align="right" style={{fontWeight: "400"}}>
                  {toNumberFormat(item?.area, 'area')}
                </TableCell>
              </TableRow>
            );
          })}
        </TableMUI>
      </>
    );
  }
}
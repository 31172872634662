import React, {useState} from "react";
import styles from "../index.module.scss";
import PaywallModal from "../components/PaywallModal";
import PaywallLogo from "../components/PaywallLogo";
import {ReactComponent as IconCheck} from "app/media/icons/round-check.svg";
import _ from "lodash";
import {fetchBannerData, getBannerDataValue} from "../helpers";
import ReactVisibilitySensor from "react-visibility-sensor";
import LoadingScreen from "../../LoadingScreen";
import connect from "react-redux/es/connect/connect";

const PaywallBannerRow = (
  {
    userAccountType,
    bannerVariant,
    modalVariant = "default"
  }
) => {
  const [bannerData, setBannerData] = useState(null);
  const [bannerDataLoading, setBannerDataLoading] = useState(false);
  const CheckIcon = () => {
    return(
      <span className={styles.iconCheck}><IconCheck/></span>
    );
  }

  // Handlers
  const handleOnViewportFetch = async (isVisible) => {
    if(isVisible && !bannerDataLoading && _.isEmpty(bannerData)) {
      setBannerDataLoading(true);
      const bannerData = await fetchBannerData(bannerVariant, userAccountType);
      setBannerData(bannerData);
      setBannerDataLoading(false);
    }
  };

  return(
    <div className={styles.paywallBannerRowWrapper}>
      <div className={styles.paywallHeader}>
        <ReactVisibilitySensor
          onChange={(isVisible) => handleOnViewportFetch(isVisible)}
          scrollCheck
          partialVisibility
        >
          <PaywallLogo/>
        </ReactVisibilitySensor>
      </div>
      {bannerDataLoading ? <LoadingScreen/> :
      <>
      <div className={styles.paywallBody}>
        <div className={styles.rowLeftWrapper}>
          {getBannerDataValue(bannerData, "title") && <h2>{getBannerDataValue(bannerData, "title")}</h2>}
          {getBannerDataValue(bannerData, "description") && <p>{getBannerDataValue(bannerData, "description")}</p>}
        </div>
        <div className={styles.rowRightWrapper}>
          <ul>
            {getBannerDataValue(bannerData, "list_1") && <li><CheckIcon/>{getBannerDataValue(bannerData, "list_1")}</li>}
            {getBannerDataValue(bannerData, "list_2") && <li><CheckIcon/>{getBannerDataValue(bannerData, "list_2")}</li>}
            {getBannerDataValue(bannerData, "list_3") && <li><CheckIcon/>{getBannerDataValue(bannerData, "list_3")}</li>}
          </ul>
        </div>
      </div>
      <div className={styles.paywallFooter}>
        <PaywallModal
          bannerVariant={modalVariant}
          triggerBannerVariant={bannerVariant}
        />
      </div>
      </>
      }
    </div>
  );
};

const mapStateToProps = store => ({
  userAccountType: store?.user?.data?.proData?.proGroup
});
export default connect(
  mapStateToProps
)(PaywallBannerRow);
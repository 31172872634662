import React from "react";
import ModalCustom from "app/partials/components/ModalCustom";
import {FormattedMessage, injectIntl} from "react-intl";
import {getBaseUrl, handleGoogleAnalyticsTracking} from "../../../utils/helpers";
import ROUTES from "../../../constants/ROUTES";
import {callReddClickEvent} from "app/crud/user.crud";
import connect from "react-redux/es/connect/connect";
import styles from "../../../pages/offices/OffersPage/components/index.module.scss";
import {TextField} from "@material-ui/core";
import {triggerNotification} from "app/utils/notifications";
import Icon from "../Icon";
import Button from "../Button";

class ShareOfferIndustrialOnline extends React.Component {
  constructor(props){
    super(props);
    this.child = React.createRef();
    this.state = {
      copied: false
    };
  }
  // Helpers
  getOfferUrl = () => {
    const { offerHash } = this.props;
    const baseUrl = getBaseUrl(window.location);
    return `${baseUrl}${ROUTES.INDUSTRIAL_OFFERS_ONLINE}/${offerHash}/`;
  }
  // Handlers
  handleCopyOfferLink = () => {
    const offerLink = this.getOfferUrl();

    this.setState(
      {
        copied: true,
      },
      () => {
        handleGoogleAnalyticsTracking('Button', 'Click', 'Offers Industrial Online - Copy Link');
        setTimeout(() => {
          this.setState({
            copied: false,
          });
        }, 5000);
        triggerNotification('URL copied to clipboard', 'success');
        return navigator.clipboard.writeText(offerLink);
      }
    );
  };

  render() {
    const {offerName, intl, inactive, offerID} = this.props;

    return (
      <ModalCustom
        ref={this.child}
        btn={
          <Button
            color={"simple"}
            variant={"filled"}
            textIcon={<Icon icon="share"/>}
            inactive={inactive}
            tooltip={<FormattedMessage id="VOCABULARY.Add park to generate an offer"/>}
            tooltipInactive={!inactive}
          >
            <FormattedMessage id="VOCABULARY.Copy link"/>
          </Button>
        }
        title={`${intl.formatMessage({id: "VOCABULARY.Offer url"})} - ${offerName}`}
        btnReject={<FormattedMessage id="GENERAL.PHRASE.CLOSE"/>}
        maxWidth="md"
        inactive={inactive}
        onOpen={() => this.props.dispatch(callReddClickEvent(
          "offers-share-button",
          "offer",
          null,
          offerID
        ))}
      >
        <span
          className={styles.shareClickWrapper}
          onClick={() => this.handleCopyOfferLink()}
        >
          <TextField
            name="offerUrl"
            label={<FormattedMessage id="VOCABULARY.Offer url" />}
            value={this.getOfferUrl()}
            fullWidth
            minRows={1}
            variant={"outlined"}
            disabled
            inputProps={{
              style: {textAlign: "center"},
            }}
          />
        </span>
      </ModalCustom>
    );
  }
}

export default injectIntl(
  connect()(ShareOfferIndustrialOnline)
);
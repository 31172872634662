import {
  fetchShortlistsLandlord
} from "app/crud/offers.crud";
import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import ShortlistsLandlordPage from "./ShortlistsLandlordPage";
import {filterByModules, filterReset} from "../../../crud/estates/filters.crud";
import {setLatestActiveTablePage} from "../../../crud/tablePage.crud";
import {callReddClickEvent} from "../../../crud/user.crud";

const mapStateToProps = (state) => ({
  shortlists: state.shortlists,
  items: state.shortlists.landlordItems,
  loading: state.shortlists.landlordLoading,
  itemsCount: state.shortlists.landlordItemsCount,
  itemsPerPage: state.shortlists.landlordItemsPerPage
});
const mapDispatchToProps = {
  fetchShortlistsLandlord: ({params}) => fetchShortlistsLandlord(params),
  filterReset: (filtersToOmit) => filterReset(filtersToOmit),
  filterByModules: (params) => filterByModules(params),
  setLatestActiveTablePage: (page) => setLatestActiveTablePage(page),
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ShortlistsLandlordPage)
);

import React from "react";
import styles from "../../../common/external/components/index.module.scss";
import LoadingScreen from "../../../../partials/components/LoadingScreen";
import OffersOnlineList from "./components/OffersOnlineList/OffersOnlineList";
import OffersOnlineSingle from "./components/OffersOnlineSingle/OffersOnlineSingle";
import {getParameterByName, handleEmptyValue} from "../../../../utils/helpers";
import Page404 from "../../../../partials/components/404";

export default class OffersOnline extends React.Component {
  constructor(props) {
    super(props);
    // Set custom HTML font-size
    const html = document.querySelector('html');
    const windowWidth = window.outerWidth;
    if(windowWidth >= 2300) {
        html.style.fontSize = '12px';
    }
    else {
        html.style.fontSize = '10px';
    }
    // Fetch branding
    const offerID = props.match.params.offer;
    props.fetchOfferOnlineBranding(offerID);
    // Share Offer Log
    const realTimeMailSourceUrl = window.location.href;
    const userPkSharing = handleEmptyValue(getParameterByName('userpksharing', realTimeMailSourceUrl), true);
    const userSharingEmail = handleEmptyValue(getParameterByName('usersharingemail', realTimeMailSourceUrl));
    const userToSendEmail = handleEmptyValue(getParameterByName('usertosendemail', realTimeMailSourceUrl));
    const token = handleEmptyValue(getParameterByName('token', realTimeMailSourceUrl), true);
    const time = handleEmptyValue(getParameterByName('time', realTimeMailSourceUrl), true);

    if(userPkSharing && userSharingEmail && userToSendEmail && token && time) {
      const data = {
        userPkSharing,
        userSharingEmail,
        userToSendEmail,
        token,
        time,
        offerPk: offerID
      };
      props.createRealTimeEmailOfferOpenLog(data);
    }
  }

  render() {
    const primaryColor = "#FF4626";
    const secondaryColor = "#ffffff";
    const {
      offerItems,
      loadingOfferItems,
      offerBranding,
      offerBranding404,
      loadingOfferBranding,
      fetchOfferOnline,
      offerOffice,
      offerOffice404,
      loadingOfferOffice,
      offerContact,
      loadingOfferContact,
      fetchOfferOnlineOffice,
      fetchOfferOnlineOfficeContact,
      match,
      intl,
      history,
      fetchOfferOnlineListPhotos,
      offerPhotos,
      loadingPhotos
    } = this.props;

    if(!loadingOfferBranding && offerBranding404) {
      return <Page404/>;
    }
    else if(loadingOfferBranding || !offerBranding) {
      return <LoadingScreen/>;
    }

    const officeID = match && match.params && match.params.office;
    const isSingleProperty = offerItems && offerItems.length === 1;

    return (
      <div
          className={styles.propertyParticularsContainer}
          style={{
            "--brand-bg-color": offerBranding.offerBgColor || primaryColor,
            "--brand-bg-font-color": offerBranding.offerBgFontColor || secondaryColor,
            "--brand-font-color": offerBranding.offerFontColor || primaryColor,
            "--brand-btn-bg-color": offerBranding.offerBtnBgColor || primaryColor,
            "--brand-btn-bg-font-color": offerBranding.offerBtnBgFontColor || secondaryColor
          }}
      >
        {/* Render proper content based on autentification */}
        {officeID ?
          <OffersOnlineSingle
              intl={intl}
              match={match}
              history={history}
              data={offerOffice}
              loading={loadingOfferOffice}
              contact={offerContact}
              loadingContact={loadingOfferContact}
              branding={offerBranding}
              isSingleProperty={isSingleProperty}
              is404={offerOffice404}
              onFetchData={fetchOfferOnlineOffice}
              onFetchContact={fetchOfferOnlineOfficeContact}
          /> :
          <OffersOnlineList
              intl={intl}
              match={match}
              items={offerItems}
              loading={loadingOfferItems}
              photos={offerPhotos}
              loadingPhotos={loadingPhotos}
              branding={offerBranding}
              isSingleProperty={isSingleProperty}
              onFetchData={fetchOfferOnline}
              onFetchPhotos={fetchOfferOnlineListPhotos}
          />
        }
      </div>
    );
  }
}
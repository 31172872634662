import React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import ModalCustom from "../../ModalCustom";
import {FormControl, InputLabel, ListSubheader, MenuItem, Select, Typography} from "@material-ui/core";
import styles from "./index.module.scss";
import Button from "../../Button";
import Icon from "../../Icon";

class SelectActiveIpiUnit extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      selected: props?.selected || ""
    }
  }

  componentDidMount() {
    const {onChange} = this.props;
    const {selected} = this.state;

    onChange && selected && onChange(selected);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.selected && prevProps.selected !== this.props.selected && prevState.selected !== this.props.selected) {
      this.setState({
        selected: this.props.selected
      })
    }
  }

  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleSelectChange = (e) => {
    const {onChange} = this.props;

    this.setState({
      selected: e.target?.value
    }, () => {
      onChange && onChange(this.state.selected);
      this.handleCloseModal()
    });
  }

  render() {
    const {intl, data} = this.props;
    const {selected} = this.state;

    return (
      <ModalCustom
        ref={this.child}
        btn={
          <Button
            color={"neutral"}
            textIcon={<Icon icon={"list"}/>}
          >
            <FormattedMessage id="INDUSTRIAL.PHRASE.IPI_SELECT_UNIT"/>
          </Button>
        }
        title={<FormattedMessage id="INDUSTRIAL.PHRASE.IPI_SELECT_UNIT_TO_BE_ADDED_EDITED"/>}
      >
        <FormControl style={{width: "100%"}}>
          <InputLabel htmlFor="grouped-select"><FormattedMessage id="INDUSTRIAL.PHRASE.IPI_SELECT_UNIT"/></InputLabel>
          <Select
            defaultValue={selected}
            id="grouped-select"
            style={{width: "100%"}}
            onChange={this.handleSelectChange}
          >
            {data?.map(group => group?.units.length && (
              [
                <ListSubheader className={styles.groupTitle}><Typography variant="h4"
                                                                         style={{margin: "1rem 0"}}>{group?.name}</Typography></ListSubheader>,
                group?.units?.filter(unit => unit?.area).map((option, index) => {
                  const label = [
                    ...option?.area ? [option?.area] : [],
                    ...option?.isPolygonExist ? [`${intl.formatMessage({id: 'INDUSTRIAL.PHRASE.IPI_ON_PLAN'})}`] : []
                  ].join(" ");

                  return (
                    <MenuItem key={index} value={option?.id} className={styles.optionLabel}>
                      <Typography variant="body1">{label}</Typography>
                    </MenuItem>
                  );
                })
              ]
            ))}
          </Select>
        </FormControl>
      </ModalCustom>
    );
  }
}

export default injectIntl(SelectActiveIpiUnit);
import React, {useEffect, useMemo, useRef} from "react";
import {useSelector} from "react-redux";
import {Chart} from "chart.js";
import {injectIntl} from "react-intl";

function BarChart({ title, desc, dataChart, intl, xAxesLabel, height, paddingDisabled, disableYLabels, disableXLabels, tooltipSufix, tooltipDisabled, animationsDisabled }) {
  const ref = useRef();
  const isEN = intl.locale === 'en';

  const { successColor } = useSelector(state => ({
    successColor: "#FF4626"
    // successColor: metronic.builder.selectors.getConfig(
    //   state,
    //   "colors.state.success"
    // )
  }));

  const chartData = dataChart.map(function(item) { return item["value"]; });
  const chartLabels = dataChart.map(function(item) { return item["label"]; });

  const data = useMemo(
    () => ({
      labels: chartLabels,
      datasets: [
        {
          // label: 'dataset 1',
          backgroundColor: successColor,
          data: chartData
        }
      ]
    }),
    [successColor, chartData, chartLabels]
  );

  useEffect(() => {
    const chart = new Chart(ref.current, {
      data,
      type: "bar",
      options: {
        title: { display: false },
        animation: {
          duration: animationsDisabled && 0
        },
        tooltips: {
          enabled: !tooltipDisabled,
          intersect: false,
          mode: "index",
          xPadding: 10,
          yPadding: 10,
          caretPadding: 10,
          callbacks: {
            label: function(tooltipItem, data) {
              let value = data && data.datasets[0] && data.datasets[0].data[tooltipItem.index] && data.datasets[0].data[tooltipItem.index].toString();
              if(value && value.indexOf(".") === -1) {
                value = value.split(/(?=(?:...)*$)/);
                value = value.join(',');
              }
              if(tooltipSufix) {
                return value + tooltipSufix;
              }
              return value;
            }
          }
        },
        legend: { display: false },
        responsive: true,
        maintainAspectRatio: false,
        barRadius: 4,
        scales: {
          xAxes: [{
            scaleLabel: {
              display: !disableXLabels,
              labelString: xAxesLabel ? xAxesLabel : isEN ? 'Date' : 'Data',
              fontStyle: 'normal',
              fontColor: "#FF4626",
              fontSize: 14
            },
            gridLines: {
              display: false,
              color: 'rgba(0,0,0,0.05)'
            },
            ticks: {
              fontSize: 14
            }
          }],
          yAxes: [{
            scaleLabel: {
              display: !disableYLabels,
              labelString: isEN ? 'Rate' : 'Wskaźnik',
              fontStyle: 'normal',
              fontColor: "#FF4626",
              fontSize: 14
            },
            gridLines: {
              display: false,
              color: 'rgba(0,0,0,0.05)'
            },
            ticks: {
              beginAtZero: true,
              display: !disableYLabels
           }
          }]
        },
        layout: { padding: { left: 0, right: 0, top: 0, bottom: 0 } },
      }
    });

    return () => {
      chart.destroy();
    };
  }, [data, xAxesLabel, isEN, disableXLabels, disableYLabels, tooltipSufix, tooltipDisabled, animationsDisabled]);

  return (
    <div className={`kt-widget14 ${paddingDisabled && 'disabledPadding'}`}>
      {desc && (
      <div className="kt-widget14__header kt-margin-b-30">
        <h3 className="kt-widget14__title">{title}</h3>
        <span className="kt-widget14__desc">{desc}</span>
      </div>
      )}
      <div className="kt-widget14__chart" style={{height: height}}>
        <canvas ref={ref} />
      </div>
    </div>
  );
}

export default injectIntl(BarChart);
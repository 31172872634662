import React from "react";
import PropTypes from "prop-types";
import {Checkbox, ListSubheader, TextField, useMediaQuery, useTheme} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {VariableSizeList} from "react-window";
import stylesScss from "app/partials/components/SelectSearch/index.module.scss";
import {createVisibleArray} from "app/utils/helpers";

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

export default class SelectSearchVirtualization extends React.Component {
  handleChange = (event, value) => {
    const {optionValue, multiple, optionsFull, optionFullLabel} = this.props;
    const selectedValues = value !== null ? (optionValue ? value[optionValue] : value) : (multiple ? [] : ''); // (optionValue ? this.state.searchVal[optionValue] : this.state.searchVal)
    let sendVal = selectedValues;
    if(optionsFull) {
      const findMatch = optionsFull.filter(item => {
        return item[optionFullLabel] === selectedValues.filter(select => select === item[optionFullLabel]).join()
      });
      sendVal = createVisibleArray(findMatch, "id");
    }

    this.setState({
      searchVal: value
    }, () => this.props.onChange({name: this.props.name, value: sendVal}));
  };

  constructor(props) {
    super(props);
    const {value, multiple, optionsFull, optionFullValue, optionFullLabel} = props;
    let setValue = value;
    if(optionsFull && Array.isArray(value)) {
      const matchSelects = optionsFull.filter(item => {
        return item[optionFullValue] === Number(value.filter(select => select === item[optionFullValue]).join())
      });
      setValue = createVisibleArray(matchSelects, optionFullLabel);
    }
    this.state = {
      searchVal: multiple ? (Array.isArray(setValue) ? setValue : [setValue]) : setValue
    }
  }

  render() {
    const {multiple, limitTags, name, options, optionLabel, label, size} = this.props;

    return (
      <Autocomplete
        multiple={multiple}
        limitTags={limitTags || 1}
        disableCloseOnSelect={multiple}
        id={name}
        options={options || []}
        getOptionLabel={option => typeof option === 'object' ? (optionLabel ? option[optionLabel] : option) : option}
        ListboxComponent={ListboxComponent}
        renderOption={multiple && ((option, { selected }) => (
          <React.Fragment>
            <Checkbox
              checked={selected}
              className={stylesScss.selectCheckbox}
              color="primary"
            />
            {optionLabel ? option[optionLabel] : option}
          </React.Fragment>
        ))}
        renderInput={params =>
          <TextField
            {...params}
            label={label}
            inputProps={{size: size || 15, ...params.inputProps}}
            fullWidth
            className={stylesScss.selectInput}
          />
        }
        ChipProps={{className: stylesScss.selectChip}}
        value={this.state.searchVal}
        onChange={this.handleChange}
      />
    );
  }
}
export default {
  OFFICES: 'offices',
  OFFICE_UNITS: 'officeUnits',
  OFFICE_UNITS_LANDLORD: 'officeUnitsLandlord',
  OFFICES_PREMIUM_LATEST_RELEASES: 'officesPremiumLatestReleases',
  OFFICES_PREMIUM_LATEST_RENT_CHANGED: 'officesPremiumLatestRentChanged',
  OFFICES_PREMIUM_LATEST_RENTED: 'officesPremiumLatestRented',
  OFFICES_PREMIUM_LATEST_SOLD_OFFICES: 'officesPremiumLatestSoldOffices',
  UNITS: 'units',
  COWORKS: 'coworks',
  COWORK_DESKS: 'coworkDesks',
  SHORTLISTS: 'shortlists',
  SHORTLIST_UNITS: 'shortlistUnits',
  SHORTLIST_LANDLORD: 'shortlistLandlord',
  SHORTLIST_LANDLORD_UNITS: 'shortlistLandlordUnits',
  TEAM: 'team',
  SUBSCRIBERS: 'subscribers',
  OWNERS: 'owners',
  OWNER_PORTFOLIO: 'ownerPortfolio',
  ACQUISITIONS: 'acquisitions',
  ACQUISITIONS_OFFICE: 'acquisitionsOffice',
  ACQUISITIONS_OWNER: 'acquisitionsOwner',
  USER_INVOICES: 'userInvoices',
  LEADS_MANAGEMENT: 'leadsManagement',
  ADMIN_MANAGE_ACCOUNT_REQUESTS: 'adminManageAccountRequests',
  // INDUSTRIAL TABLE_CELLS
  INDUSTRIAL_PARKS: 'industrialParks',
  INDUSTRIAL_BUILDINGS: 'industrialBuildings',
  INDUSTRIAL_PARK_WAREHOUSES: 'industrialParkWarehouses',
  INDUSTRIAL_BUILDING_UNITS: 'industrialBuildingUnits',
  INDUSTRIAL_BUILDING_ACQUISITIONS: 'industrialBuildingAcquisitions',
  INDUSTRIAL_FILES: 'industrialFiles',
  INDUSTRIAL_SHORTLIST: "industrialShortlist",
  INDUSTRIAL_SHORTLIST_PARKS: "industrialShortlistParks",
  INDUSTRIAL_SHORTLIST_LANDLORD: "industrialShortlistLandlord"
};

import React from "react";
import {FormattedMessage} from "react-intl";
import Icon from "app/partials/components/Icon";
import Button from "app/partials/components/Button";
import PropertyCardPreview from "../../../../../partials/components/_DataParts/PropertyCardPreview";
import styles from "./index.module.scss";
import SelectSearch from "../../../../../partials/components/SelectSearch";
import {createVisibleArray, sortArrayAlph} from "../../../../../utils/helpers";

export default class CompetitionCompareManageItem extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      edit: false,
      editPropertyID: null,
      propertyID: null,
      typingValue: ''
    };
  }
  // Handlers
  handleToggleEdit = (editPropertyID) => {
    this.setState({
      edit: !this.state.edit
    }, () => {
      this.setState({
        editPropertyID: this.state.edit ? editPropertyID : null,
      })
    });
  }
  // Handlers
  handleAutocompleteChange = (target) => {
    const {onPropertiesUpdate} = this.props;
    const {editPropertyID, propertyID} = this.state;
    const {label, value} = target;
    const prevPropertyID = propertyID;

    // Change input value on property select
    if(label) {
      this.setState({
        typingValue: label
      });
    }
    // Save selected propertyID
    if(Array.isArray(value)) {
      this.setState({
        propertyID: value?.[0]
      }, () => onPropertiesUpdate(editPropertyID, prevPropertyID, this.state.propertyID));
    }
  };
  handleInputChange = (value) => {
    this.setState({
      typingValue: value || '',
    });
  };

  render() {
    const {property, offices, loading, officeID} = this.props;
    const {edit, typingValue, propertyID} = this.state;
    // Variables
    const address = [
      ...property?.addressStreet ? [property?.addressStreet] : [],
      ...property?.addressCity ? [property?.addressCity] : []
    ].join(", ");
    // Searching
    const completeList = offices.filter(office => Number(office?.id) !== Number(officeID));

    const sortedProperties = offices?.length > 0 ?
      sortArrayAlph(createVisibleArray(completeList, "name")) : [];

    return (
      <div className={styles.competitionCompareEditItem}>
        <div className={styles.propertyWrapper}>
          <PropertyCardPreview
            title={property?.name}
            semiTitle={address}
            thumb={"disabled"}
            titleStyles={{
              color: "#2d2d2d"
            }}
          />
          {edit &&
          <div className={styles.propertySelectWrapper}>
            <SelectSearch
              name="property"
              inputValue={typingValue}
              inputVariant="outlined"
              label={<FormattedMessage id="VOCABULARY.Search building"/>}
              options={sortedProperties}
              optionsFull={completeList}
              optionFullLabel="name"
              optionFullValue="id"
              loading={loading}
              disabled={loading}
              minLength={3}
              marginOff
              searchMode
              onChange={this.handleAutocompleteChange}
              onInputChange={this.handleInputChange}
            />
          </div>
          }
        </div>
        <Button
          type={"plainText"}
          color={"simple"}
          textIcon={<Icon icon={edit ? "close" : "edit"}/>}
          style={{
            flexDirection: "row-reverse",
            opacity: propertyID ? 0 : 1,
          }}
          disabled={propertyID}
          onClick={() => this.handleToggleEdit(property?.id)}
        >
          {edit ? <FormattedMessage id={"VOCABULARY.Close"}/> : <FormattedMessage id={"VOCABULARY.Change"}/>}
        </Button>
      </div>
    );
  }
}
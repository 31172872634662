import React from "react";
import styles from "../../../../../common/external/components/index.module.scss";
import GoogleMap from "./GoogleMap";
import {Marker} from "react-google-maps";
import GOOGLE_MAPS_OPTIONS from "app/constants/GOOGLE_MAPS_OPTIONS";
import {FormattedMessage} from "react-intl";
import {toAbsoluteUrl} from "_metronic";

export default class OffersOnlineSingleLocation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lat: props.data && Number(props.data.geoLatitude),
            lng: props.data && Number(props.data.geoLongitude)
        };
    }
    render() {
        const {data} = this.props;
        const {lat, lng} = this.state;
        // Map settings
        const options = {
          minZoom: 3,
          maxZoom: 20,
          styles: GOOGLE_MAPS_OPTIONS.EXTERNAL_STYLES,
          scrollwheel: false
        };
        const pos = { lat, lng };
        const zoom = 11;

        // Data values
        const officeAddress = [
            ...data.addressStreet ? [data.addressStreet] : [],
            ...data.district ? [data.district] : [],
            ...data.addressCity ? [data.addressCity] : []
        ];

        return(
            <div ref={this.props.reference} className={styles.boxContainer}>
                <div className={styles.titleWrapper}>
                    <h2><FormattedMessage id="PROPERTY_PARTICULARS.TAB.LOCATION"/></h2>
                    <p>{officeAddress.join(", ")}</p>
                </div>
                {lat && lng && (
                <div className={styles.locationWrapper}>
                    <GoogleMap
                      options={options}
                      center={pos}
                      zoom={zoom}
                    >
                      <Marker position={pos}
                       icon={toAbsoluteUrl("media/icons/pois/success/marker-poi-warehouse.png")}
                      />
                    </GoogleMap>
                </div>
                )}
            </div>
        );
    }
}
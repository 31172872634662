import React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import _ from "lodash";
import {updateIndustrialWarehouseUnit} from "app/crud/estates/industrial/industrial.crud";
import InputMultiline from "../../../InputMultiline";
import {DialogContentText, Grid, Typography} from "@material-ui/core";

class EditWarehouseUnitNote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      note: props.note,
    };
    this.initialState = this.state;
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    // Handle Change Detect
    this.props.onUnsavedData(!_.isEqual(this.initialState, this.state));
    // Handle Save
    if (!prevProps.requestSave && this.props.requestSave) {
      this.handleSave();
    }
    // Update Note
    if (prevProps.note !== this.props.note) {
      this.setState({
        note: this.props.note,
      });
    }
    // Detect Office Updating
    if(prevProps.unitUpdating !== this.props.unitUpdating) {
      this.props.onRequestSaving(this.props.unitUpdating);
    }
  }

  // Handlers
  handleChange = (event) => {
    this.setState({
      note: event.target.value,
    });
  };
  handleSave = () => {
    const { unitId, updateIndustrialWarehouseUnit } = this.props;
    const { note } = this.state;
    const data = {
      note: note,
    };
    this.initialState = this.state;
    const backgroundRefreshObject = {
      target: "note"
    };
    updateIndustrialWarehouseUnit(unitId, data, backgroundRefreshObject);
  };

  render() {
    const {unitUpdating} = this.props;
    const { note } = this.state;
    const {
      isPropertyEditable
    } = this.props;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" style={{color: "#ff4626"}}><FormattedMessage id="VOCABULARY.Note"/></Typography>
        </Grid>
        <Grid item xs={12}>
          {isPropertyEditable ? (
            <InputMultiline
              label={<FormattedMessage id="UNITS.PHRASE.SHORT_UNIT_INFO" />}
              value={note || ""}
              onChange={this.handleChange}
              disabled={unitUpdating}
            />
          ) : (
            <DialogContentText>{note}</DialogContentText>
          )}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  unitUpdating: state.industrial.updateWarehouseUnitLoading,
});
const mapDispatchToProps = {
  updateIndustrialWarehouseUnit: (unitId, data, backgroundRefreshObject) => updateIndustrialWarehouseUnit(unitId, data, backgroundRefreshObject),
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditWarehouseUnitNote)
);
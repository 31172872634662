import React from "react";
import {Portlet, PortletBody, PortletHeader} from "app/partials/content/Portlet";
import {TextField, Typography} from "@material-ui/core";
import clsx from "clsx";
import {FormattedMessage, injectIntl} from "react-intl";
import styles from "../index.module.scss";
import LoadingScreen from "../../../../partials/components/LoadingScreen";
import {InputUpload} from "../../../../partials/components/InputUpload";
import _ from "lodash";
import {callReddClickEvent} from "app/crud/user.crud";
import connect from "react-redux/es/connect/connect";

const primaryColor = "#ff4626";
const secondaryColor = "#ffffff";
const initBrandBgColor = primaryColor;
const initBrandBgFontColor = secondaryColor;
const initBrandFontColor = primaryColor;
const initBrandBtnBgColor = primaryColor;
const initBrandBtnBgFontColor = secondaryColor;

class UserPersonalizePP extends React.Component {
  constructor(props) {
    super(props);
    const initialStyles = {
      brandBgColor: (props.data && props.data.brandBgColor) || initBrandBgColor,
      brandBgFontColor: (props.data && props.data.brandBgFontColor) || initBrandBgFontColor,
      brandFontColor: (props.data && props.data.brandFontColor) || initBrandFontColor,
      brandBtnBgColor: (props.data && props.data.brandBtnBgColor) || initBrandBtnBgColor,
      brandBtnBgFontColor: (props.data && props.data.brandBtnBgFontColor) || initBrandBtnBgFontColor
    };
    this.state = {
      ...initialStyles,
      logo: props.data && props.data.ppCompanyLogo,
      logoFile: null,
      initStyles: initialStyles,
      isReady: false
    };
    const {onFetch, teamID} = props;
    onFetch && onFetch(teamID);
  }
  componentDidMount() {
    if(!this.state.isReady) {
      this.setState({
        isReady: true
      }, () => this.props.dispatch(callReddClickEvent(
        "user-customize-pp-online-view",
        "user"
      )));
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {data} = this.props;
    if(data && !_.isEqual(_.omit(prevProps.data, ['ppCompanyLogo']), _.omit(data, ['ppCompanyLogo']))) {
      this.setState({
        brandBgColor: data?.brandBgColor || initBrandBgColor,
        brandBgFontColor: data?.brandBgFontColor || initBrandBgFontColor,
        brandFontColor: data?.brandFontColor || initBrandFontColor,
        brandBtnBgColor: data?.brandBtnBgColor || initBrandBtnBgColor,
        brandBtnBgFontColor: data?.brandBtnBgFontColor || initBrandBtnBgFontColor
      });
    }
    if(data && !_.isEqual(this.state.logo, data?.ppCompanyLogo)) {
      this.setState({
        logo: this.props.data?.ppCompanyLogo
      });
    }
  }

  handleSave = () => {
    const {onUpdate, teamID} = this.props;
    const {initStyles} = this.state;
    const {
      brandBgColor,
      brandBgFontColor,
      brandFontColor,
      brandBtnBgColor,
      brandBtnBgFontColor,
    } = this.state;
    const data = {
      brandBgColor,
      brandBgFontColor,
      brandFontColor,
      brandBtnBgColor,
      brandBtnBgFontColor
    };
    if(!_.isEqual(_.omit(this.state, ['logo', 'logoFile', 'initStyles']), initStyles)) {
      onUpdate(teamID, data);
    }
  };
  handleUpdateLogo = () => {
    const {onUpdateLogo} = this.props;
    const {logoFile} = this.state;

    logoFile && onUpdateLogo(logoFile);
  };
  handleRestoreDefault = () => {
    const {onUpdate, teamID} = this.props;
    this.setState({
      brandBgColor: initBrandBgColor,
      brandBgFontColor: initBrandBgFontColor,
      brandFontColor: initBrandFontColor,
      brandBtnBgColor: initBrandBtnBgColor,
      brandBtnBgFontColor: initBrandBtnBgFontColor
    }, () => {
      const {
        brandBgColor,
        brandBgFontColor,
        brandFontColor,
        brandBtnBgColor,
        brandBtnBgFontColor
      } = this.state;
      const data = {
        brandBgColor,
        brandBgFontColor,
        brandFontColor,
        brandBtnBgColor,
        brandBtnBgFontColor
      };
      onUpdate(teamID, data);
    });
  };
  handleRemoveLogo = () => {
    const {onUpdateLogo} = this.props;
    this.setState({
      logo: null,
      logoFile: null
    }, () => {
      onUpdateLogo(null);
    });
  }
  handleColorPicker = e => {
    const selectType = e.target.type;
    const selectedColor = e.target.value;
    const selectedInputColor = e.target.id;
    const selectedInputText = e.target.name;
    if(selectType === "color") {
      this.setState({
        [selectedInputColor]: selectedColor
      });
    } else if(selectType === "text") {
      this.setState({
        [selectedInputText]: selectedColor
      });
    }
  };
  handleLogoUpload = logoFile => {
    this.setState({
      logoFile
    });
  };

  render() {
    const {loading, updatingLogo} = this.props;
    const {
      brandBgColor,
      brandBgFontColor,
      brandFontColor,
      brandBtnBgColor,
      brandBtnBgFontColor,
      logo
    } = this.state;

    return(
      <Portlet fluidHeight>
        <PortletHeader title={<Typography variant="h4"><FormattedMessage id="PROPERTY_PARTICULARS.PERSONALIZE.TITLE"/></Typography>}/>
        <PortletBody>
          {loading ? <LoadingScreen/> :
          <>
          <Typography variant="h4" style={{marginBottom: 35}}><FormattedMessage id="PROPERTY_PARTICULARS.PERSONALIZE.INFO"/></Typography>
          <Typography variant="h4"><FormattedMessage id="PROPERTY_PARTICULARS.PERSONALIZE.BRANDING_COLORS"/></Typography>
          <div className={clsx("form-group", styles.inputGroupPdfStyles)}>
            <div className={styles.inputPdfPickerContainer}>
              <TextField
                type="text"
                margin="normal"
                label={<FormattedMessage id="PROPERTY_PARTICULARS.PERSONALIZE.BRAND_BG_COLOR"/>}
                name="brandBgColor"
                value={brandBgColor}
                onChange={this.handleColorPicker}
              />
              <input type="color" value={brandBgColor} id="brandBgColor" className={styles.inputPdfColorPicker} onChange={this.handleColorPicker}/>
            </div>
            <div className={styles.inputPdfPickerContainer}>
              <TextField
                type="text"
                margin="normal"
                label={<FormattedMessage id="PROPERTY_PARTICULARS.PERSONALIZE.BRAND_BG_FONT_COLOR"/>}
                name="brandBgFontColor"
                value={brandBgFontColor}
                onChange={this.handleColorPicker}
              />
              <input type="color" value={brandBgFontColor} id="brandBgFontColor" className={styles.inputPdfColorPicker} onChange={this.handleColorPicker}/>
            </div>
            <div className={styles.inputPdfPickerContainer}>
              <TextField
                type="text"
                margin="normal"
                label={<FormattedMessage id="PROPERTY_PARTICULARS.PERSONALIZE.BRAND_FONT_COLOR"/>}
                name="brandFontColor"
                value={brandFontColor}
                onChange={this.handleColorPicker}
              />
              <input type="color" value={brandFontColor} id="brandFontColor" className={styles.inputPdfColorPicker} onChange={this.handleColorPicker}/>
            </div>
          </div>
          <Typography variant="h4"><FormattedMessage id="PROPERTY_PARTICULARS.PERSONALIZE.BRANDING_BUTTONS_COLORS"/></Typography>
          <div className={clsx("form-group", styles.inputGroupPdfStyles)}>
            <div className={styles.inputPdfPickerContainer}>
              <TextField
                type="text"
                margin="normal"
                label={<FormattedMessage id="PROPERTY_PARTICULARS.PERSONALIZE.BRAND_BUTTON_BG_COLOR"/>}
                name="brandBtnBgColor"
                value={brandBtnBgColor}
                onChange={this.handleColorPicker}
              />
              <input type="color" value={brandBtnBgColor} id="brandBtnBgColor" className={styles.inputPdfColorPicker} onChange={this.handleColorPicker}/>
            </div>
            <div className={styles.inputPdfPickerContainer}>
              <TextField
                type="text"
                margin="normal"
                label={<FormattedMessage id="PROPERTY_PARTICULARS.PERSONALIZE.BRAND_BUTTON_BG_FONT_COLOR"/>}
                name="brandBtnBgFontColor"
                value={brandBtnBgFontColor}
                onChange={this.handleColorPicker}
              />
              <input type="color" value={brandBtnBgFontColor} id="brandBtnBgFontColor" className={styles.inputPdfColorPicker} onChange={this.handleColorPicker}/>
            </div>
          </div>
          </>
          }
          {updatingLogo ? <LoadingScreen/> :
          <>
          <Typography variant="h4" style={{marginBottom: 15}}><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.UPLOAD_COMPANY_LOGO"/></Typography>
          <div className={clsx("form-group")}>
            <div>
              <InputUpload
                currentFile={logo}
                onFileUpload={this.handleLogoUpload}
                width="100%"
                height="100%"
                noFrame
                responsiveBox
                fileTarget="logo"
              />
            </div>
          </div>
          </>
          }
          <div className="kt-login__actions">
            <button
              type="button"
              className="btn btn-primary btn-elevate kt-login__btn-primary"
              onClick={this.handleSave}
              style={{marginRight: 10}}
            >
              {<FormattedMessage id="GENERAL.PHRASE.SAVE_STYLES"/>}
            </button>
            <button
              type="button"
              className="btn btn-primary btn-elevate kt-login__btn-primary"
              onClick={this.handleUpdateLogo}
              style={{marginRight: 10}}
            >
              {<FormattedMessage id="GENERAL.PHRASE.SAVE_LOGOTYPE"/>}
            </button>
            <button
              type="button"
              className="btn btn-secondary btn-elevate kt-login__btn-primary"
              onClick={this.handleRestoreDefault}
              style={{marginRight: 10}}
            >
              {<FormattedMessage id="GENERAL.PHRASE.RESET_STYLES"/>}
            </button>
            <button
              type="button"
              className="btn btn-secondary btn-elevate kt-login__btn-primary"
              onClick={this.handleRemoveLogo}
            >
              {<FormattedMessage id="GENERAL.PHRASE.RESET_LOGOTYPE"/>}
            </button>
          </div>
        </PortletBody>
      </Portlet>
    );
  }
}

export default injectIntl(connect()(UserPersonalizePP));
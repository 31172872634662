import React from "react";
import {toAbsoluteUrl} from "_metronic";
import {FormattedMessage} from "react-intl";
import "../../../_metronic/_assets/sass/pages/error/error-1.scss";
import axios from "axios";
import {clearCacheAndStorage} from "../../utils/helpers";
import styles from "./index.module.scss";

const useConstructor = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = React.useState(false);
  if (hasBeenCalled) {
    return;
  }
  callBack();
  setHasBeenCalled(true);
};

export function MaintenancePage() {
  const [messagePl, setMessagePl] = React.useState(null);
  const [messageEn, setMessageEn] = React.useState(null);
  const currentLang = localStorage.getItem("langManual");
  const forceMaintenance = false;

    useConstructor(() => {
        if(forceMaintenance) {
            setMessagePl(<span>W dniach 1-2 lipca mogą występować przerwy w dostępie do REDD ze względu na prace techniczne.<br/>Działamy w celu zapewnienia lepszego doświadczenia korzystania z naszej platformy.<br/>Dziękujemy za wyrozumiałość.</span>);
            setMessageEn(<span>There may be interruptions in access to REDD on 1-2 July due to technical work.<br/>We are working to ensure a better experience using our platform.<br/>Thank you for your understanding.</span>);
        }
        else {
            // Logout and enter Maintenance mode if true
            axios.get('/redd-status/').then((response) => {
              const data = response.data;
              setMessagePl(data.maintenanceMessagePl);
              setMessageEn(data.maintenanceMessageEn);
            });
            setInterval(() => {
              axios.get('/redd-status/').then((response) => {
                const data = response.data;
                const status = data.maintenance;
                setMessagePl(data.maintenanceMessagePl);
                setMessageEn(data.maintenanceMessageEn);
                return !status && clearCacheAndStorage();
              });
            }, 60000);
        }
    });
  return (
    <>
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div
          className="kt-grid__item kt-grid__item--fluid kt-grid kt-error-v1"
          style={{
            backgroundColor: "#2d2d2d",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <div className={styles.maintenanceWrapper}>
            <img src={toAbsoluteUrl("media/branding/redd-logo-neon.png")} style={{height: 60, marginBottom: 100}} alt="REDD Logo" />
            <h1 style={{fontSize: 30, color: "#ffffff"}}><FormattedMessage id="Maintenance mode"/></h1>
            <p style={{fontSize: 20, color: "#ffffff", marginTop: 25}}><FormattedMessage id="Maintain work is underway, we'll be back in a moment. We apologize for the inconvenience." values={{br: <br/>}}/></p>
            <div style={{fontSize: "20px", color: "#ffffff", marginTop: 25}} dangerouslySetInnerHTML={{__html: currentLang === "pl" ? messagePl : messageEn}}/>
            <img src={toAbsoluteUrl("media/branding/redd-loader.gif")} style={{height: 50, marginTop: 100}} alt="REDD Loader" />
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";
import parentStyles from "app/pages/common/external/components/index.module.scss";
import {FormattedMessage} from "react-intl";
import LanguageSelector from "../../../../../partials/layout/LanguageSelector";
import PropertyParticularsSelectCity from "./PropertyParticularsSelectCity";

export default class PropertyParticularsHeader extends React.Component {
    render() {
        const {view, onGoBack, branding, teamID, onFilterMarket} = this.props;
        return(
            <div ref={this.props.reference} className={parentStyles.headerSection}>
                <div className={parentStyles.headerTitle}>
                    {branding && branding.ppCompanyLogo ? <img src={branding.ppCompanyLogo} alt={branding.name}/> : branding && <span className={parentStyles.headerName}>{branding.name}</span>}
                    <h2>Property<br/>Particulars</h2>
                </div>
                <div className={parentStyles.headerOptions}>
                    {view === "list" && branding && branding.markets && branding.markets.length > 1 && onFilterMarket &&
                        <PropertyParticularsSelectCity
                            branding={branding}
                            teamID={teamID}
                            onFilter={onFilterMarket}
                        />
                    }
                    {view === "single" && <button className={parentStyles.menuItem} onClick={onGoBack}><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.BACK_TO_LIST"/></button>}
                    <div className={parentStyles.langMenu}>
                        <LanguageSelector preventReload/>
                    </div>
                </div>
            </div>
        );
    }
}
import React from "react";
import {Grid, Table, TableBody, TableCell, TableRow, Typography} from "@material-ui/core";
import ModalCustom from "app/partials/components/ModalCustom";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {handleEmptyValue, toNumberFormat} from "app/utils/helpers";
import {fetchOfficeFinances} from "app/crud/estates/offices/offices.crud";
import LoadingScreen from "app/partials/components/LoadingScreen";
import {DataPreviewContainer, DataPreviewItem} from "../SimpleDataPreview";
import styles from "./index.module.scss";
import DashboardExtendedDataItem
  from "../DashboardExtendedDataBox/DashboardExtendedDataItem";
import DashboardExtendedDataWrapper
  from "../DashboardExtendedDataBox/DashboardExtendedDataWrapper";
import NoData from "../NoData/NoData";
import OwnerFinancialGeneratePDF from "app/pages/offices/OwnerPage/components/OwnerFinancialGeneratePDF";
import OwnerFinancialGenerateXLS from "app/pages/offices/OwnerPage/components/OwnerFinancialGenerateXLS";
import FinancesTooltip from "../FinancesAdvTooltip/FinancesTooltip";
import {fetchIndustrialWarehouseFinances} from "../../../crud/estates/industrial/industrial.crud";
import {callReddClickEvent} from "../../../crud/user.crud";
import Button from "../Button";

class OwnerPortfolioFinancePreview extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      isOpen: false,
      listFinancesActiveYear: 0,
      isIndustrialWarehouseView: props.view === 'industrialWarehouse'
    };
    const {listView, fetchOfficeFinances, fetchIndustrialWarehouseFinances, propertyID} = props;
    if (listView) {
      if (this.state.isIndustrialWarehouseView) {
        fetchIndustrialWarehouseFinances(propertyID);
      } else {
        fetchOfficeFinances(propertyID);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {fetchOfficeFinances, fetchIndustrialWarehouseFinances, propertyID} = this.props;
    if (this.state.isOpen && prevState.isOpen !== this.state.isOpen) {
      if (this.state.isIndustrialWarehouseView) {
        fetchIndustrialWarehouseFinances(propertyID);
      } else {
        fetchOfficeFinances(propertyID);
      }
    }
  }

  handleCloseModal = () => {
    this.setState({
      isOpen: false
    }, () => this.child.current.handleClose());
  };
  getModalStateOpen = (state) => {
    this.setState({
      isOpen: state
    })
  };
  handleChangeStatementYear = (yearIndex) => {
    this.setState({
      listFinancesActiveYear: yearIndex
    });
  };
  renderChangeStatementYear = (year) => {
    const {officeFinances, warehouseFinances} = this.props;
    const {listFinancesActiveYear, isIndustrialWarehouseView} = this.state;
    const financesData = isIndustrialWarehouseView ? warehouseFinances : officeFinances;

    return (
      <ModalCustom
        ref={this.child}
        btn={
        <Button color={"success"}>
          {year}
        </Button>
        }
        title={<FormattedMessage id="OWNERS.PHRASE.YEAR_OF_THE_FINANCIAL_STATEMENT"/>}
      >
        <Grid container spacing={2}>
          {financesData.map((data, index) => (
            <Grid item key={index}>
              <Button
                color={index === listFinancesActiveYear ? "danger" : "success"}
                disabled={index === listFinancesActiveYear}
                onClick={() => this.handleChangeStatementYear(index)}
              >
                {data?.year}
              </Button>
            </Grid>
          ))}
        </Grid>
      </ModalCustom>
    );
  };

  render() {
    const {officeFinances, warehouseFinances, hasFinance, listView, view, propertyID} = this.props;
    const {listFinancesActiveYear, isIndustrialWarehouseView} = this.state;
    const financesData = isIndustrialWarehouseView ? warehouseFinances : officeFinances;

    if (listView) {
      if (financesData && financesData.length) {
        const selectedYearData = financesData[listFinancesActiveYear];
        return (
          <DataPreviewContainer>
            <DataPreviewItem title={<FormattedMessage id="GENERAL.PHRASE.YEAR"/>}
                             value={this.renderChangeStatementYear(selectedYearData["year"])}/>
            <DataPreviewItem title={<FormattedMessage id="OWNERS.PHRASE.TOTAL_OPERATING_REVENUE"/>}
                             value={toNumberFormat(selectedYearData["totalOperatingRevenue"], "currency", "PLN")}/>
            <DataPreviewItem title={<FormattedMessage id="OWNERS.PHRASE.PROFIT_LOSS_FROM_SALES"/>}
                             value={toNumberFormat(selectedYearData["salesProfit"], "currency", "PLN")}/>
            <DataPreviewItem title={<FormattedMessage id="OWNERS.PHRASE.GROSS_PROFIT_MARGIN"/>}
                             value={toNumberFormat(selectedYearData["grossProfitMargin"], "%")}/>
            <DataPreviewItem title={<FormattedMessage id="OWNERS.PHRASE.OPERATING_PROFIT_EBIT"/>}
                             value={toNumberFormat(selectedYearData["ebit"], "currency", "PLN")}/>
            <DataPreviewItem title={<FormattedMessage id="OWNERS.PHRASE.OPERATING_PROFIT_MARGIN_ROS"/>}
                             value={toNumberFormat(selectedYearData["operatingProfitMarginRos"], "%")}/>
            <DataPreviewItem title="EBITDA"
                             value={toNumberFormat(selectedYearData["ebitda"], "currency", "PLN")}/>
            <DataPreviewItem title={<FormattedMessage id="OWNERS.PHRASE.TOTAL_ASSETS"/>}
                             value={toNumberFormat(selectedYearData["totalAssets"], "currency", "PLN")}/>
            <DataPreviewItem title={<FormattedMessage id="OWNERS.PHRASE.CURRENT_ASSETS"/>}
                             value={toNumberFormat(selectedYearData["assets"], "currency", "PLN")}/>
            <DataPreviewItem title={<FormattedMessage id="OWNERS.PHRASE.TOTAL_LIABILITIES"/>}
                             value={toNumberFormat(selectedYearData["liabilities"], "currency", "PLN")}/>
            <DataPreviewItem title="ROA" value={toNumberFormat(selectedYearData["roaMargin"], "%")}/>
            <DataPreviewItem title={<FormattedMessage id="OWNERS.PHRASE.DEBT_TO_EQUITY"/>}
                             value={toNumberFormat(selectedYearData["debtToEquity"])}/>
          </DataPreviewContainer>
        );
      }
      return <NoData/>;
    }
    return (
      <ModalCustom
        ref={this.child}
        btn={
        <Button color={hasFinance ? "success" : "simple"}>
          <FormattedMessage id="BUILDING.PHRASE.TABS.FINANCES"/>
        </Button>
        }
        title={<FormattedMessage id="OWNERS.PHRASE.FINANCIAL_STATEMENT"/>}
        btnStyle={{cursor: "pointer", opacity: hasFinance ? 1 : 0.3}}
        isOpen={this.getModalStateOpen}
        inactive={!hasFinance}
        maxWidth="lg"
        toolbar={
          <div style={{display: "flex"}}>
            <OwnerFinancialGeneratePDF officeID={propertyID} view={view}/>
            <OwnerFinancialGenerateXLS officeID={propertyID}/>
          </div>
        }
        onOpen={() => this.props.dispatch(callReddClickEvent(
          "owner-financial-statement-open",
          "office",
          null,
          propertyID
        ))}
      >
        {financesData && financesData.length ? (
          <Grid container spacing={2}>
            <Grid item xs={4} style={{borderRight: "2px solid rgba(255,255,255,0.1)"}}>
              <Grid container direction="column" justifyContent="space-between" style={{height: "100%"}}>
                <Grid item>
                  <DashboardExtendedDataWrapper direction="column" cols={1}
                                                className={styles.spvPreviewWrapper}>
                    <DashboardExtendedDataItem variant="h3"
                                               value={handleEmptyValue(financesData[0].companyName)}
                                               label={<FormattedMessage id="OWNERS.PHRASE.SPV"/>}/>
                    <DashboardExtendedDataItem variant="h3"
                                               value={handleEmptyValue(financesData[0].companyNip)}
                                               label="NIP"/>
                    <DashboardExtendedDataItem variant="h3"
                                               value={handleEmptyValue(financesData[0].companyRegon)}
                                               label="REGON"/>
                    <DashboardExtendedDataItem variant="h3"
                                               value={handleEmptyValue(financesData[0].companyKrs)}
                                               label="KRS"/>
                  </DashboardExtendedDataWrapper>
                </Grid>
                <Grid item>
                  <span className={styles.separator}/>
                  <Typography variant="h3"><FormattedMessage id="OWNERS.PHRASE.BUILDINGS_WITHIN_SPV"/></Typography>
                  <Table style={{width: "auto"}}>
                    <TableBody>
                      {financesData[0].companyBuildings.map((building, index) => (
                        <TableRow key={index}>
                          <TableCell>{index + 1}.</TableCell>
                          <TableCell>{building}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <span className={styles.separator}/>
                </Grid>
                <Grid item>
                  <Typography variant="h4" style={{color: "#ffffff"}}><FormattedMessage
                    id="GENERAL.PHRASE.SOURCE"/>: Krajowy Rejestr Sądowy</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell/>
                    {financesData.map((item, index) => (
                      <TableCell key={index} className={styles.valueCell}>
                        <Typography variant="h4" style={{
                          color: "#ffffff",
                          justifyContent: "flex-end"
                        }}>{item["year"]}</Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell><FormattedMessage
                      id="OWNERS.PHRASE.TOTAL_OPERATING_REVENUE"/></TableCell>
                    {financesData.map((item, index) => (
                      <TableCell key={index} className={styles.valueCell}>
                        <Typography
                          variant="body1">{toNumberFormat(item["totalOperatingRevenue"], 'currency', 'PLN')}</Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell><FormattedMessage
                      id="OWNERS.PHRASE.PROFIT_LOSS_FROM_SALES"/></TableCell>
                    {financesData.map((item, index) => (
                      <TableCell key={index} className={styles.valueCell}>
                        <Typography
                          variant="body1">{toNumberFormat(item["salesProfit"], 'currency', 'PLN')}</Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <FinancesTooltip
                        var_1={<FormattedMessage
                          id="OWNERS.PHRASE.FINANCES_TOOLTIP.Sales Margin"/>}
                        var_2={<FormattedMessage
                          id="OWNERS.PHRASE.FINANCES_TOOLTIP.Profit (loss) from sales"/>}
                        var_3={<FormattedMessage
                          id="OWNERS.PHRASE.FINANCES_TOOLTIP.Total Sales"/>}
                        operation="divide"
                      >
                        <FormattedMessage id="OWNERS.PHRASE.GROSS_PROFIT_MARGIN"/>
                      </FinancesTooltip>
                    </TableCell>
                    {financesData.map((item, index) => (
                      <TableCell key={index} className={styles.valueCell}>
                        <Typography
                          variant="body1">{toNumberFormat(item["grossProfitMargin"], '%')}</Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell><FormattedMessage
                      id="OWNERS.PHRASE.OPERATING_PROFIT_EBIT"/></TableCell>
                    {financesData.map((item, index) => (
                      <TableCell key={index} className={styles.valueCell}>
                        <Typography
                          variant="body1">{toNumberFormat(item["ebit"], 'currency', 'PLN')}</Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <FinancesTooltip
                        var_1={<FormattedMessage
                          id="OWNERS.PHRASE.FINANCES_TOOLTIP.Operating Profit Margin"/>}
                        var_2={<FormattedMessage
                          id="OWNERS.PHRASE.FINANCES_TOOLTIP.Operating Profit (EBIT)"/>}
                        var_3={<FormattedMessage
                          id="OWNERS.PHRASE.FINANCES_TOOLTIP.(Total Sales + Other Operating Profit)"/>}
                        operation="divide"
                      >
                        <FormattedMessage id="OWNERS.PHRASE.OPERATING_PROFIT_MARGIN_ROS"/>
                      </FinancesTooltip>
                    </TableCell>
                    {financesData.map((item, index) => (
                      <TableCell key={index} className={styles.valueCell}>
                        <Typography
                          variant="body1">{toNumberFormat(item["operatingProfitMarginRos"], '%')}</Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <FinancesTooltip
                        var_1="EBITDA"
                        var_2={<FormattedMessage
                          id="OWNERS.PHRASE.FINANCES_TOOLTIP.Operating Profit (EBIT)"/>}
                        var_3={<FormattedMessage
                          id="OWNERS.PHRASE.FINANCES_TOOLTIP.Amortization"/>}
                        operation="plus"
                      >
                        EBITDA
                      </FinancesTooltip>
                    </TableCell>
                    {financesData.map((item, index) => (
                      <TableCell key={index} className={styles.valueCell}>
                        <Typography
                          variant="body1">{toNumberFormat(item["ebitda"], 'currency', 'PLN')}</Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell><FormattedMessage id="OWNERS.PHRASE.TOTAL_ASSETS"/></TableCell>
                    {financesData.map((item, index) => {
                      return (
                        <TableCell key={index} className={styles.valueCell}>
                          <Typography
                            variant="body1">{toNumberFormat(item["totalAssets"], 'currency', 'PLN')}</Typography>
                        </TableCell>
                      )
                    })}
                  </TableRow>
                  <TableRow>
                    <TableCell><FormattedMessage id="OWNERS.PHRASE.CURRENT_ASSETS"/></TableCell>
                    {financesData.map((item, index) => (
                      <TableCell key={index} className={styles.valueCell}>
                        <Typography
                          variant="body1">{toNumberFormat(item["assets"], 'currency', 'PLN')}</Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell><FormattedMessage id="OWNERS.PHRASE.TOTAL_LIABILITIES"/></TableCell>
                    {financesData.map((item, index) => (
                      <TableCell key={index} className={styles.valueCell}>
                        <Typography
                          variant="body1">{toNumberFormat(item["liabilities"], 'currency', 'PLN')}</Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <FinancesTooltip
                        var_1="ROA"
                        var_2={<FormattedMessage
                          id="OWNERS.PHRASE.FINANCES_TOOLTIP.Net profit (loss)"/>}
                        var_3={<FormattedMessage
                          id="OWNERS.PHRASE.FINANCES_TOOLTIP.Total Assets"/>}
                        operation="divide"
                      >
                        ROA
                      </FinancesTooltip>
                    </TableCell>
                    {financesData.map((item, index) => (
                      <TableCell key={index} className={styles.valueCell}>
                        <Typography
                          variant="body1">{toNumberFormat(item["roaMargin"], '%')}</Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <FinancesTooltip
                        var_1={<FormattedMessage id="OWNERS.PHRASE.DEBT_TO_EQUITY"/>}
                        var_2={<FormattedMessage
                          id="OWNERS.PHRASE.FINANCES_TOOLTIP.Total Debt"/>}
                        var_3={<FormattedMessage
                          id="OWNERS.PHRASE.FINANCES_TOOLTIP.Equity Capital"/>}
                        operation="divide"
                      >
                        <FormattedMessage id="OWNERS.PHRASE.DEBT_TO_EQUITY"/>
                      </FinancesTooltip>
                    </TableCell>
                    {financesData.map((item, index) => (
                      <TableCell key={index} className={styles.valueCell}>
                        <Typography
                          variant="body1">{toNumberFormat(item["debtToEquity"])}</Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        ) : <LoadingScreen/>}
      </ModalCustom>
    );
  }
}

const mapStateToProps = (state) => ({
  officeFinances: state.offices.officeFinances,
  warehouseFinances: state.industrial.warehouseFinances
});
const mapDispatchToProps = dispatch => ({
  fetchOfficeFinances: (officeId) => dispatch(fetchOfficeFinances(officeId)),
  fetchIndustrialWarehouseFinances: (warehouseID) => dispatch(fetchIndustrialWarehouseFinances(warehouseID)),
  dispatch
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OwnerPortfolioFinancePreview)
);
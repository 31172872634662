import React from "react";
import TooltipCustom from "../TooltipCustom";
import styles from "./index.module.scss"

export default function Excerpt(
  {
    text,
    length = 150,
    tooltip = false,
    overflow = false,
    emptyValue = null,
    ...props
  }
) {
  const emptyText = text === null || text === undefined;
  const cropDots = text?.length > length ? '...' : '';
  const shortText = text?.length > 0 && text?.substring(0, length) + cropDots;

  if (emptyText) {
    return emptyValue ? emptyValue : "N/A";
  }
  else if(tooltip) {
    return(
      <TooltipCustom
        title={<span style={{fontSize: "1.2rem", ...props.style}}>{text}</span>}
        placement="top"
      >
        <span>{shortText}</span>
      </TooltipCustom>
    );
  }
  else if(overflow) {
    return(
      <span className={styles.textNoOverflowWrapper}>{text}</span>
    );
  }
  return shortText;
}
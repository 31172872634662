import React from "react";
import {Grid, Typography} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import _ from "lodash";
import {updateUnit} from "../../../../../crud/estates/offices/units.crud";
import InputSwitcher from "../../../InputSwitcher";
import InputIcon from "../../../InputIcon";
import {isNumber} from "../../../../../utils/helpers";
import Button from "../../../Button";
import Icon from "app/partials/components/Icon";

class EditUnitFlexible extends React.Component {
  constructor(props) {
    super(props);
    let dividedUnitsAreas = [];
    if (props.data?.dividedUnits) {
      props.data.dividedUnits.map((unit) => dividedUnitsAreas.push(unit.area));
    }

    this.state = {
      canBeDivided: props.data?.canBeDivided,
      dividedMinValue: props.data?.dividedMinValue,
      dividedUnits: dividedUnitsAreas.length ? dividedUnitsAreas : ["", ""],
    };
    this.initialState = this.state;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Handle Change Detect
    this.props.onUnsavedData(!_.isEqual(this.initialState, this.state));
    // Handle Save
    if (!prevProps.requestSave && this.props.requestSave) {
      this.handleSave();
    }
    // Detect Office Updating
    if (prevProps.unitUpdating !== this.props.unitUpdating) {
      this.props.onRequestSaving(this.props.unitUpdating);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data?.canBeDivided !== this.state.canBeDivided) {
      let dividedUnitsAreas = [];
      if (nextProps.data?.dividedUnits) {
        nextProps.data.dividedUnits.map((unit) => dividedUnitsAreas.push(unit.area));
      }

      this.setState({
        canBeDivided: nextProps.data?.canBeDivided,
        dividedMinValue: nextProps.data?.dividedMinValue,
        dividedUnits: dividedUnitsAreas.length ? dividedUnitsAreas : ["", ""],
      }, () => this.initialState = this.state);
    }
  }

  // Handlers
  removeElementFromArray = (array, elem) => {
    const index = array.indexOf(elem);
    if (index > -1) {
      array.splice(index, 1);
    }
  };
  handleSetPartialUnitAsLeased = (
    unitId,
    unitArea,
    dividedMinValue,
    dividedAreas,
    dividedIndex,
    updateUnit
  ) => {
    const dividedArea = dividedAreas[dividedIndex];
    const newTotalUnitArea = parseInt(unitArea) - parseInt(dividedArea);
    this.removeElementFromArray(dividedAreas, dividedArea);
    let newDividedUnitsSet = [];
    dividedAreas &&
    dividedAreas.map((unit) => newDividedUnitsSet.push({area: unit}));
    const lastUnit =
      dividedMinValue === newTotalUnitArea ||
      (dividedAreas.length === 1 && dividedAreas[0] === newTotalUnitArea);
    const backgroundRefreshObject = {
      target: "flexibleUnits",
      officeID: this.props.extraData?.officeId,
      ordering: this.props.extraData?.ordering
    };
    // 1. Set new TOTAL UNIT AREA to "newTotalUnitArea" - function updateOfficeUnit
    // 2. Update UNIT "dividedUnits" with "dividedAreas" - function updateOfficeUnit
    // 3. Add new UNIT to OFFICE BUILDING with area equal to "dividedArea" with status unavailable - function addOfficeUnit
    updateUnit(
      unitId,
      {
        area: newTotalUnitArea,
        dividedUnits: newDividedUnitsSet,
        canBeDivided: !lastUnit,
      },
      backgroundRefreshObject
    );
  };
  handleChangeMinDivided = (event) => {
    this.setState({
      dividedMinValue: isNumber(event.target.value) ? event.target.value : "",
    });
  };

  handleChangeSwitcher = (name) => (event) => {
    this.setState(
      {
        [name]: event.target.checked,
      },
      () => {
        this.initialState = this.state;
        const backgroundRefreshObject = {
          target: "flexibleUnits",
          officeID: this.props.extraData?.officeId,
          ordering: this.props.extraData?.ordering
        };
        this.props.updateUnit(
          this.props.unitId,
          {canBeDivided: this.state.canBeDivided},
          backgroundRefreshObject
        );
      }
    );
  };
  handleChangeDividedUnit = (event) => {
    const unitIndex = event.target.name.replace("dividedUnit_", "");
    const newDividedUnits = this.state.dividedUnits.slice();
    newDividedUnits[unitIndex] = Number(event.target.value);
    this.setState({
      dividedUnits: isNumber(event.target.value)
        ? newDividedUnits
        : this.state.dividedUnits,
    });
  };
  handleRemoveField = (targetIndex) => {
    const newDividedUnits = this.state.dividedUnits.filter(
      (unit, index) => index !== targetIndex
    );
    this.setState({
      dividedUnits: [...newDividedUnits],
    });
  };
  handleAddField = () => {
    this.setState({
      dividedUnits: [...this.state.dividedUnits, Number("")],
    });
  };
  handleSave = () => {
    const {unitId, updateUnit} = this.props;
    const {dividedMinValue, dividedUnits, canBeDivided} = this.state;
    const dividedUnitsToPass = dividedUnits.filter((unit) => unit !== "");
    let newDividedUnits = [];
    dividedUnitsToPass &&
    dividedUnitsToPass.map((unit) => newDividedUnits.push({area: unit}));
    const data = {
      dividedMinValue,
      dividedUnits: newDividedUnits,
      canBeDivided,
    };
    this.initialState = this.state;
    const backgroundRefreshObject = {
      target: "flexibleUnits",
      officeID: this.props.extraData?.officeId,
      ordering: this.props.extraData?.ordering
    };
    updateUnit(unitId, data, backgroundRefreshObject);
  };

  render() {
    const {
      data,
      updateUnit,
      unitUpdating,
      isOfficeEditable
    } = this.props;
    const {dividedUnits, dividedMinValue, canBeDivided} = this.state;

    return (
      <Grid container direction="column" spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" style={{color: "#ff4626"}}><FormattedMessage
            id="VOCABULARY.Flexible units"/></Typography>
        </Grid>
        {isOfficeEditable &&
          <Grid item>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Typography>
                  <FormattedMessage id="UNITS.PHRASE.IS_FLEXIBLE"/>
                </Typography>
              </Grid>
              <Grid item>
                <InputSwitcher
                  checked={canBeDivided}
                  value={canBeDivided}
                  label={<FormattedMessage id="GENERAL.PHRASE.YES"/>}
                  onChange={this.handleChangeSwitcher("canBeDivided")}
                />
              </Grid>
            </Grid>
          </Grid>
        }
        {canBeDivided && (
          <>
            <Grid item>
              <Grid
                container
                spacing={2}
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Grid item xs={7}>
                  <InputIcon
                    name="dividedMinValue"
                    label={
                      <FormattedMessage id="UNITS.PHRASE.MINIMAL_AREA"/>
                    }
                    icon={<Icon icon={"edit"} size={"1.8rem"}/>}
                    value={dividedMinValue || ""}
                    disabled={unitUpdating || !isOfficeEditable}
                    onChange={this.handleChangeMinDivided}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Typography>
                    <FormattedMessage id="UNITS.PHRASE.TOTAL_UNIT_AREA"/>:
                  </Typography>
                  <Typography variant="h3">{data?.area}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                spacing={2}
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Grid item>
                  <Typography>
                    <FormattedMessage id="UNITS.PHRASE.FLEXIBLE_UNITS"/>:
                  </Typography>
                </Grid>
                {isOfficeEditable &&
                  <Grid item>
                    <Button
                      color={"success"}
                      textIcon={<Icon icon="add-clean"/>}
                      onClick={this.handleAddField}
                    >
                      <FormattedMessage id="UNITS.PHRASE.ADD_UNIT"/>
                    </Button>
                  </Grid>
                }
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                spacing={2}
                wrap="wrap"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                {dividedUnits.map((unit, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      xs={6}
                      style={{position: "relative"}}
                    >
                      <InputIcon
                        name={`dividedUnit_${index}`}
                        label={
                          <FormattedMessage
                            id="UNITS.PHRASE.DIVIDED_UNIT_INDEX_LABEL"
                            values={{index: index}}
                          />
                        }
                        icon={<Icon icon={"units-brick"}/>}
                        value={unit}
                        disabled={unitUpdating || !isOfficeEditable}
                        onChange={this.handleChangeDividedUnit}
                      />
                      {isOfficeEditable &&
                        <span
                          style={{
                            position: "absolute",
                            right: 7,
                            bottom: 13,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "1rem"
                          }}
                        >
                          <Button
                            type="icon"
                            inactive={unitUpdating}
                            tooltip={<FormattedMessage id="UNITS.PHRASE.DELETE_UNIT"/>}
                            style={{width: "2.5rem", height: "2.5rem"}}
                            onClick={() =>
                              this.handleRemoveField(index)
                            }
                          >
                            <Icon icon="close"/>
                          </Button>
                          <Button
                            type="icon"
                            color={"success"}
                            inactive={unitUpdating}
                            style={{width: "2.5rem", height: "2.5rem"}}
                            tooltip={<FormattedMessage id="UNITS.PHRASE.RENT_UNIT"/>}
                            onClick={() =>
                              this.handleSetPartialUnitAsLeased(
                                data?.id,
                                data?.area,
                                dividedMinValue,
                                dividedUnits,
                                index,
                                updateUnit
                              )
                            }
                          >
                            <Icon icon="check"/>
                          </Button>
                        </span>
                      }
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    );
  }
}

const mapStateToProps = store => ({
  unitUpdating: store.units.officeUnitsUpdateLoading
});
const mapDispatchToProps = {
  updateUnit: (unitId, data, backgroundRefreshObject) => updateUnit(unitId, data, backgroundRefreshObject),
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditUnitFlexible)
);
// FETCH DATA
export const fetchLeads = (params) => ({
  type: "FETCH_LEADS_REQUEST",
  payload: {
    params
  }
});
export const fetchLead = (leadID) => ({
  type: "FETCH_LEAD_REQUEST",
  payload: {
    leadID
  }
});
export const fetchLeadsPreview = () => ({
  type: "FETCH_LEADS_PREVIEW_REQUEST"
});
// ACTIONS
export const updateLeadInterviewForm = (leadID, data) => ({
  type: "UPDATE_LEAD_INTERVIEW_FORM_REQUEST",
  payload: {
    leadID,
    data
  }
});
export const updateLeadStatus = (leadID, data) => ({
  type: "UPDATE_LEAD_STATUS_REQUEST",
  payload: {
    leadID,
    data
  }
});
export const addLeadProperties = (leadID, data) => ({
  type: "ADD_LEAD_PROPERTIES_REQUEST",
  payload: {
    leadID,
    data
  }
});
export const removeLeadProperties = (leadID, data) => ({
  type: "REMOVE_LEAD_PROPERTIES_REQUEST",
  payload: {
    leadID,
    data
  }
});
export const sendLead = (leadID, data) => ({
  type: "SEND_LEAD_REQUEST",
  payload: {
    leadID,
    data
  }
});
export const addLead = (data) => ({
  type: "ADD_LEAD_REQUEST",
  payload: {
    data
  }
});

// RESET LEADS MANAGEMENT LOADING STATES
export const resetLeadsManagementLoadingStates = () => ({
  type: "RESET_LEADS_MANAGEMENT_LOADING_STATES_REQUEST",
});
import React from "react";
import TableCell from "@material-ui/core/TableCell/TableCell";
import UnitAreaPreview from "app/partials/components/_DataParts/UnitAreaPreview";
import UnitFloorPreview from "app/partials/components/_DataParts/UnitFloorPreview";
import UnitTypePreview from "app/partials/components/_DataParts/UnitTypePreview";
import UnitStatusPreview from "app/partials/components/_DataParts/UnitStatusPreview";
import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import OwnerCardPreview from "../../_DataParts/OwnerCardPreview";
import UpdatedAtPreview from "../../_DataParts/UpdatedAtPreview";

class TableOffersLandlordOfficeUnitsCells extends React.Component {

  render() {
    const {row} = this.props;

    return (
      <>
        <TableCell>
            <OwnerCardPreview name={row.companyName} thumb={row.companyLogo}/>
        </TableCell>
        <TableCell component="th" scope="row" align="right">
          <UnitAreaPreview data={row}/>
        </TableCell>
        <TableCell align="right">
          <UnitFloorPreview floor={row.floor}/>
        </TableCell>
        <TableCell align="right">
          <UnitStatusPreview unitStatus={row.status}/>
        </TableCell>
        <TableCell align="right">
          <UnitTypePreview unitType={row.unitType} isSublease={row.isSublease}/>
        </TableCell>
        <TableCell>
            <UpdatedAtPreview level={4} date={row.shortlistCreatedAt}/>
        </TableCell>
        <TableCell>
          <UpdatedAtPreview level={1} date={row.shortlistLastUpdate} alternative/>
        </TableCell>
      </>
    );
  }
}

export default injectIntl(
  connect(
    null,
    null
  )(TableOffersLandlordOfficeUnitsCells)
);

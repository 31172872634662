import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {put, takeLatest} from "redux-saga/effects";
import axios from "axios";
import {actionNotification} from "app/utils/notifications";
import {resetFilters, setFilters} from "app/utils/filters";
import {VIEW_TYPES} from "app/constants";
import {handleErrorResponse} from "app/utils/helpers";

const initialState = {
  items: {},
  office: null,
  officeUpdating: false,
  officeLoading: false,
  officeLoaded: false,
  itemsPerPage: 20,
  itemsCount: null,
  totalItems: null,
  loaded: false,
  loading: false,
  didInvalidate: false,
  inviteErrors: null,
  clusters: null,
  clustersLoaded: false,
  clustersLoading: false,
  viewType: VIEW_TYPES.LIST_VIEW,
  officePhotos: null,
  officePhotosLoading: false,
  officePhotoUploading: false,
  rcBuildingMonthly: null,
  rcBuildingWeekly: null,
  rcBuildingLoading: false,
  rcUnitsMonthly: null,
  rcUnitsWeekly: null,
  rcUnitsLoading: false,
  rcContactMonthly: null,
  rcContactWeekly: null,
  rcContactLoading: false,
  rcAllMonthly: null,
  rcAllWeekly: null,
  rcAllLoading: false,
  officeLeasingTeams: null,
  officeLeasingTeamsLoading: false,
  officeOwnersCurrent: null,
  officeOwnersPrevious: null,
  officeOwnersCompanies: null,
  officeOwnersTotalShare: null,
  officeOwnersLoading: false,
  amenities: null,
  amenitiesLoading: false,
  amenitiesUpdating: false,
  latestLeads: null,
  latestLeadsLoading: false,
  visitingCompanies: null,
  visitingCompaniesLoading: false,
  officesOwnedSimpleList: null,
  officesSimpleList: null,
  officesSimpleListLoading: false,
  officesCompareResults: null,
  officesComparing: false,
  officesMinified: null,
  officesMinifiedLoading: false,
  officesDashboardTransactions: null,
  officesDashboardTransactionsLoading: false,
  officesDashboardSupplyBoost: null,
  officesDashboardSupplyBoostLoading: false,
  officesTransactions: null,
  officesTransactionsLoading: false,
  officesSupplyBoost: null,
  officesSupplyBoostLoading: false,
  refreshingOffice: false
};

export const reducer = persistReducer(
  { storage, key: "offices" },
  (state = initialState, action) => {
    switch (action.type) {
      // RESET OFFICES LOADING STATES
      case 'RESET_OFFICE_LOADING_STATES_REQUEST': {
        return {
          ...state,
          officeLoading: false,
          officeOwnerLoading: false,
          officeFinancesLoading: false,
          officesMinifiedLoading: false,
          officePhotosLoading: false,
          officeOwnersLoading: false,
          officeLeasingTeamsLoading: false,
          officePhotoUploading: false,
          officesSimpleListLoading: false,
          officesSupplyBoostLoading: false,
          officesTransactionsLoading: false,
          officesDashboardSupplyBoostLoading: false,
          officesDashboardTransactionsLoading: false,
          visitingCompaniesLoading: false,
          latestLeadsLoading: false,
          amenitiesLoading: false,
          rcAllLoading: false,
          rcContactLoading: false,
          rcUnitsLoading: false,
          rcBuildingLoading: false,
          clustersLoading: false,
          loading: false,
          refreshingOffice: false
        }
      }
      //
      case 'FETCH_OFFICE_LIST_REQUEST': {
        return { ...state, loading: true }
      }
      case 'FETCH_OFFICE_LIST_SUCCESS': {
        const { items, itemsCount, itemsPerPage, total } = action.payload;
        return { ...state, items, itemsCount, itemsPerPage, totalItems: total, loaded: true, loading: false }
      }
      case 'FETCH_OFFICE_LIST_FAILED': {
        return { ...state, loading: false }
      }
      case 'FETCH_OFFICE_RESET_REQUEST': {
        return { ...state, loading: true };
      }
      case 'FETCH_OFFICE_REQUEST': {
        return {
          ...state,
          office: null,
          officeLoading: true,
          officeLoaded: false,
          officePhotos: null,
          officeOwner: null,
          officeFinances: null
        };
      }
      case 'FETCH_OFFICE_SUCCESS': {
        return {...state, office: action.payload, officeLoading: false, officeLoaded: true}
      }
      case 'FETCH_OFFICE_CLUSTERS_RESET_REQUEST': {
        return { ...state, clustersLoading: true }
      }
      case 'FETCH_OFFICE_CLUSTERS_REQUEST': {
        return { ...state, clustersLoading: true }
      }
      case 'FETCH_OFFICE_CLUSTERS_SUCCESS': {
        return {
          ...state,
          clusters: action.payload,
          clustersLoaded: true,
          clustersLoading: false
        }
      }
      case 'FETCH_OFFICE_CLUSTERS_FAILED': {
        return {
          ...state,
          clustersLoading: false,
          clusters: null
        }
      }
      case 'FETCH_OFFICE_FINANCES_FAILED': {
        return { ...state, officeFinances: null, officeFinancesLoading: false }
      }
      case 'FETCH_OFFICE_FINANCES_REQUEST': {
        return { ...state, officeFinances: null, officeFinancesLoading: true }
      }
      case 'FETCH_OFFICE_FINANCES_SUCCESS': {
        return { ...state, officeFinances: action.payload.length ? action.payload : null, officeFinancesLoading: false }
      }
      case 'FETCH_OFFICE_PHOTOS_REQUEST': {
        return { ...state, officePhotos: null, officePhotosLoading: true }
      }
      case 'FETCH_OFFICE_PHOTOS_SUCCESS': {
        return { ...state, officePhotos: action.payload.length ? action.payload : null, officePhotosLoading: false }
      }
      case 'FETCH_OFFICE_PHOTOS_FAILED': {
        return { ...state, officePhotos: null, officePhotosLoading: false }
      }
      case 'FETCH_OFFICE_LEASING_TEAMS_FAILED': {
        return { ...state, officeLeasingTeams: null, officeLeasingTeamsLoading: false }
      }
      case 'FETCH_OFFICE_LEASING_TEAMS_REQUEST': {
        return { ...state, officeLeasingTeams: null,  officeLeasingTeamsLoading: true }
      }
      case 'FETCH_OFFICE_LEASING_TEAMS_SUCCESS': {
        return { ...state, officeLeasingTeams: action.payload.length ? action.payload : null, officeLeasingTeamsLoading: false }
      }
      case 'FETCH_OFFICES_LATEST_LEADS_REQUEST': {
        return {
          ...state,
          latestLeads: null,
          latestLeadsLoading: true
        }
      }
      case 'FETCH_OFFICES_LATEST_LEADS_SUCCESS': {
        const { items } = action.payload;
        return {
          ...state,
          latestLeads: items,
          latestLeadsLoading: false
        }
      }
      case 'FETCH_OFFICES_LATEST_LEADS_FAILED': {
        return {
          ...state,
          latestLeads: null,
          latestLeadsLoading: false
        };
      }
      case 'FETCH_OFFICES_VISITING_COMPANIES_REQUEST': {
        return {
          ...state,
          visitingCompanies: null,
          visitingCompaniesLoading: true
        }
      }
      case 'FETCH_OFFICES_VISITING_COMPANIES_SUCCESS': {
        const { items } = action.payload;
        return {
          ...state,
          visitingCompanies: items,
          visitingCompaniesLoading: false
        }
      }
      case 'FETCH_OFFICES_VISITING_COMPANIES_FAILED': {
        return {
          ...state,
          visitingCompanies: null,
          visitingCompaniesLoading: false
        };
      }
      // DASHBOARD TRANSACTION/SUPPLY
      case 'FETCH_OFFICES_DASHBOARD_TRANSACTIONS_REQUEST': {
        return {
          ...state,
          officesDashboardTransactions: null,
          officesDashboardTransactionsLoading: true
        }
      }
      case 'FETCH_OFFICES_DASHBOARD_TRANSACTIONS_SUCCESS': {
        return {
          ...state,
          officesDashboardTransactions: action?.payload,
          officesDashboardTransactionsLoading: false
        }
      }
      case 'FETCH_OFFICES_DASHBOARD_TRANSACTIONS_FAILED': {
        return {
          ...state,
          officesDashboardTransactions: null,
          officesDashboardTransactionsLoading: false
        }
      }
      case 'FETCH_OFFICES_DASHBOARD_SUPPLY_BOOST_REQUEST': {
        return {
          ...state,
          officesDashboardSupplyBoost: null,
          officesDashboardSupplyBoostLoading: true
        }
      }
      case 'FETCH_OFFICES_DASHBOARD_SUPPLY_BOOST_SUCCESS': {
        return {
          ...state,
          officesDashboardSupplyBoost: action?.payload,
          officesDashboardSupplyBoostLoading: false
        }
      }
      case 'FETCH_OFFICES_DASHBOARD_SUPPLY_BOOST_FAILED': {
        return {
          ...state,
          officesDashboardSupplyBoost: null,
          officesDashboardSupplyBoostLoading: false
        }
      }
      // TRANSACTIONS/SUPPLY
      case 'FETCH_OFFICES_TRANSACTIONS_REQUEST': {
        return {
          ...state,
          officesTransactionsLoading: true
        }
      }
      case 'FETCH_OFFICES_TRANSACTIONS_SUCCESS': {
        return {
          ...state,
          officesTransactions: action?.payload,
          officesTransactionsLoading: false,
        }
      }
      case 'FETCH_OFFICES_TRANSACTIONS_FAILED': {
        return {
          ...state,
          officesTransactions: null,
          officesTransactionsLoading: false
        }
      }
      case 'FETCH_OFFICES_SUPPLY_BOOST_REQUEST': {
        return {
          ...state,
          officesSupplyBoostLoading: true
        }
      }
      case 'FETCH_OFFICES_SUPPLY_BOOST_SUCCESS': {
        return {
          ...state,
          officesSupplyBoost: action?.payload,
          officesSupplyBoostLoading: false
        }
      }
      case 'FETCH_OFFICES_SUPPLY_BOOST_FAILED': {
        return {
          ...state,
          officesSupplyBoost: null,
          officesSupplyBoostLoading: false
        }
      }

      case 'FETCH_OFFICES_SIMPLE_LIST_FAILED': {
        return { ...state, officesSimpleListLoading: false }
      }
      case 'FETCH_OFFICES_SIMPLE_LIST_REQUEST': {
        return { ...state, officesSimpleListLoading: true }
      }
      case 'FETCH_OFFICES_SIMPLE_LIST_SUCCESS': {
        return { ...state, officesSimpleList: action.payload ? action.payload : null, officesSimpleListLoading: false }
      }
      case 'FETCH_OFFICES_OWNED_SIMPLE_LIST_SUCCESS': {
        return { ...state, officesOwnedSimpleList: action.payload ? action.payload : null, officesSimpleListLoading: false }
      }
      case 'FETCH_OFFICE_RC_BUILDING_FAILED': {
        return { ...state, rcBuildingMonthly: null, rcBuildingWeekly: null, rcBuildingLoading: false }
      }
      case 'FETCH_OFFICE_RC_BUILDING_REQUEST': {
        return { ...state, rcBuildingMonthly: null, rcBuildingWeekly: null,  rcBuildingLoading: true }
      }
      case 'FETCH_OFFICE_RC_BUILDING_MONTHLY_SUCCESS': {
        return { ...state, rcBuildingMonthly: action.payload ? action.payload : null, rcBuildingLoading: false }
      }
      case 'FETCH_OFFICE_RC_BUILDING_WEEKLY_SUCCESS': {
        return { ...state, rcBuildingWeekly: action.payload ? action.payload : null, rcBuildingLoading: false }
      }
      case 'FETCH_OFFICE_RC_UNITS_FAILED': {
        return { ...state, rcUnitsMonthly: null, rcUnitsWeekly: null, rcUnitsLoading: false }
      }
      case 'FETCH_OFFICE_RC_UNITS_REQUEST': {
        return { ...state, rcUnitsMonthly: null, rcUnitsWeekly: null,  rcUnitsLoading: true }
      }
      case 'FETCH_OFFICE_RC_UNITS_MONTHLY_SUCCESS': {
        return { ...state, rcUnitsMonthly: action.payload ? action.payload : null, rcUnitsLoading: false }
      }
      case 'FETCH_OFFICE_RC_UNITS_WEEKLY_SUCCESS': {
        return { ...state, rcUnitsWeekly: action.payload ? action.payload : null, rcUnitsLoading: false }
      }
      case 'FETCH_OFFICE_RC_CONTACT_FAILED': {
        return { ...state, rcContactMonthly: null, rcContactWeekly: null, rcContactLoading: false }
      }
      case 'FETCH_OFFICE_RC_CONTACT_REQUEST': {
        return { ...state, rcContactMonthly: null, rcContactWeekly: null,  rcContactLoading: true }
      }
      case 'FETCH_OFFICE_RC_CONTACT_MONTHLY_SUCCESS': {
        return { ...state, rcContactMonthly: action.payload ? action.payload : null, rcContactLoading: false }
      }
      case 'FETCH_OFFICE_RC_CONTACT_WEEKLY_SUCCESS': {
        return { ...state, rcContactWeekly: action.payload ? action.payload : null, rcContactLoading: false }
      }
      case 'FETCH_OFFICE_RC_ALL_FAILED': {
        return { ...state, rcAllMonthly: null, rcAllWeekly: null, rcAllLoading: false }
      }
      case 'FETCH_OFFICE_RC_ALL_REQUEST': {
        return { ...state, rcAllMonthly: null, rcAllWeekly: null,  rcAllLoading: true }
      }
      case 'FETCH_OFFICE_RC_ALL_MONTHLY_SUCCESS': {
        return { ...state, rcAllMonthly: action.payload ? action.payload : null, rcAllLoading: false }
      }
      case 'FETCH_OFFICE_RC_ALL_WEEKLY_SUCCESS': {
        return { ...state, rcAllWeekly: action.payload ? action.payload : null, rcAllLoading: false }
      }
      case 'FETCH_OFFICE_OWNERS_FAILED': {
        return {
          ...state,
          officeOwnersCurrent: null,
          officeOwnersPrevious: null,
          officeOwnersCompanies: null,
          officeOwnersTotalShare: null,
          officeOwnersLoading: false
        }
      }
      case 'FETCH_OFFICE_OWNERS_REQUEST': {
        return {
          ...state,
          officeOwnersCurrent: null,
          officeOwnersPrevious: null,
          officeOwnersCompanies: null,
          officeOwnersTotalShare: null,
          officeOwnersLoading: true
        }
      }
      case 'FETCH_OFFICE_OWNERS_SUCCESS': {
        const data = action.payload ? action.payload : null;
        return {
          ...state,
          officeOwnersCurrent: data && data.owners && data.owners.current,
          officeOwnersPrevious: data && data.owners && data.owners.previous,
          officeOwnersCompanies: data && data.companies,
          officeOwnersTotalShare: data && data.totalShare,
          officeOwnersLoading: false
        }
      }
      case 'FETCH_OFFICE_AMENITIES_REQUEST': {
        return {
          ...state,
          amenities: null,
          amenitiesLoading: true
        }
      }
      case 'FETCH_OFFICE_AMENITIES_SUCCESS': {
        const data = action.payload ? action.payload : null;
        return {
          ...state,
          amenities: data,
          amenitiesLoading: false
        }
      }
      case 'FETCH_OFFICES_MINIFIED_REQUEST': {
        return {
          ...state,
          officesMinifiedLoading: true,
          officesMinified: null
        }
      }
      case 'FETCH_OFFICES_MINIFIED_SUCCESS': {
        return {
          ...state,
          officesMinifiedLoading: false,
          officesMinified: action?.payload
        }
      }
      case 'FETCH_OFFICES_MINIFIED_FAILED': {
        return {
          ...state,
          officesMinifiedLoading: false,
          officesMinified: null
        }
      }
      case 'RESET_OFFICES_MINIFIED_REQUEST': {
        return {
          ...state,
          officesMinifiedLoading: false,
          officesMinified: null
        }
      }

      case 'GET_VIEW_TYPE_SUCCESS': {
        return { ...state, viewType: action.payload }
      }
      case 'ADD_OFFICE_SUCCESS': {
        return { ...state, items: [...state.items, action.payload] };
      }
      case 'DELETE_OFFICE_SUCCESS': {
        return { ...state, items: state.items.filter(item => item.id !== action.payload) }
      }
      case 'UPDATE_OFFICE_REQUEST': {
        return { ...state, officeUpdating: true }
      }
      case 'UPDATE_OFFICE_SUCCESS': {
        return { ...state, officeUpdating: false, office: {...state.office, ...action.payload} }
      }
      case 'UPDATE_OFFICE_FAILED': {
        return { ...state, officeUpdating: false }
      }
      case 'UPDATE_OFFICE_AMENITIES_REQUEST': {
        return { ...state, amenitiesUpdating: true }
      }
      case 'UPDATE_OFFICE_AMENITIES_SUCCESS': {
        return { ...state, amenitiesUpdating: false }
      }
      case 'UPDATE_OFFICE_AMENITIES_FAILED': {
        return { ...state, amenitiesUpdating: false }
      }
      case 'ADD_OFFICE_PHOTO_REQUEST': {
        return { ...state, officePhotoUploading: true }
      }
      case 'ADD_OFFICE_PHOTO_SUCCESS': {
        return { ...state, officePhotoUploading: false }
      }
      case 'ADD_OFFICE_PHOTO_FAILED': {
        return { ...state, officePhotoUploading: false }
      }
      case 'DELETE_OFFICE_PHOTO_SUCCESS': {
        return { ...state, officePhotos: [...state.officePhotos.filter(photo => photo.id !== action.payload)]}
      }
      case 'ADD_OFFICE_OWNERS_REQUEST': {
        return {...state, officeOwnersLoading: true}
      }
      case 'ADD_OFFICE_OWNERS_SUCCESS': {
        const data = action.payload;
        return {
          ...state,
          officeOwnersCurrent: data && data.owners && data.owners.current,
          officeOwnersTotalShare: data.totalShare,
          officeOwnersLoading: false
        }
      }
      case 'ADD_OFFICE_OWNERS_FAILED': {
        return {...state, officeOwnersLoading: false}
      }
      case 'DELETE_OFFICE_OWNERS_SUCCESS': {
        const data = action.payload;
        return {
          ...state,
          officeOwnersCurrent: data && data.owners && data.owners.current,
          officeOwnersPrevious: data && data.owners && data.owners.previous,
          officeOwnersTotalShare: data.totalShare
        }
      }

      case 'COMPARE_SELECTED_OFFICES_REQUEST': {
        return {
          ...state,
          officesComparing: !action.payload.inBackground,
          officesCompareResults: null
        }
      }
      case 'COMPARE_SELECTED_OFFICES_SUCCESS': {
        return {
          ...state,
          officesComparing: false,
          officesCompareResults: action.payload
        }
      }
      case 'COMPARE_SELECTED_OFFICES_FAILED': {
        return {
          ...state,
          officesComparing: false,
          officesCompareResults: null
        }
      }
      case 'COMPARE_SELECTED_OFFICES_RESET': {
        return {
          ...state,
          officesComparing: false,
          officesCompareResults: null,
          officesSimpleList: null
        }
      }

      case 'REFRESH_OFFICE_REQUEST': {
        return { ...state, refreshingOffice: true }
      }
      case 'REFRESH_OFFICE_SUCCESS': {
        return { ...state, refreshingOffice: false }
      }
      case 'REFRESH_OFFICE_FAILED': {
        return { ...state, refreshingOffice: false }
      }
      // RESET STATES OPTIONS
      case 'RESET_OFFICE_STATE_REQUEST': {
        return {
          ...state,
          office: null,
          officeLoading: false,
          officeLoaded: false,
          officeOwner: null,
          officeOwnerLoading: false,
          officePhotos: null,
          officeFinances: null,
          officeFinancesLoading: false
        }
      }

      default:
        return state;
    }
  }
);

// FETCH DATA
function* fetchOfficeList(action) {
  const params = action.payload.params;
  const filters = setFilters(params, 'updated_at_by_user'); //number_of_units_available
  try {
    const offices = yield axios.get(`/offices-v2/${filters}`);
    yield put({ type: "FETCH_OFFICE_LIST_SUCCESS", payload: offices.data || [{ error: offices.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchOfficeResetList(action) {
  const {initOrderBy, isEditable} = action.payload;
  const filtersReset = resetFilters(initOrderBy, isEditable);
  try {
    const offices = yield axios.get(`/offices-v2/${filtersReset}`);
    yield put({ type: "FETCH_OFFICE_LIST_SUCCESS", payload: offices.data || [{ error: offices.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchOffice(action) {
  try {
    const officeID = action.payload;
    const office = yield axios.get(`/offices-v2/${officeID}/`);
    yield put({ type: "FETCH_OFFICE_SUCCESS", payload: office.data || [{ error: office.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchOfficeClusters(action) {
  const params = action.payload.params;
  const filters = setFilters(params, 'map');
  try {
    const clustersData = yield axios.get(`/offices-v2/map_clusters/?ne_lat=&ne_lng=&sw_lat=&sw_lng=${filters}`);
    yield put({ type: "FETCH_OFFICE_CLUSTERS_SUCCESS", payload: clustersData.data || [{ error: clustersData.statusText }] });
  }
  catch(err) {
    console.log(err);
    yield put({ type: "FETCH_OFFICE_CLUSTERS_FAILED" });
  }
}
function* fetchOfficeClustersReset() {
  const filters = resetFilters('map');
  try {
    const clustersData = yield axios.get(`/offices-v2/map_clusters/?ne_lat=&ne_lng=&sw_lat=&sw_lng=${filters}`);
    yield put({ type: "FETCH_OFFICE_CLUSTERS_SUCCESS", payload: clustersData.data || [{ error: clustersData.statusText }] });
  }
  catch(err) {
    console.log(err);
    yield put({ type: "FETCH_OFFICE_CLUSTERS_FAILED" });
  }
}
function* fetchOfficeFinances(action) {
  const officeID = action.payload;
  try {
    const officeFinances = yield axios.get(`/offices-v2/${officeID}/get-finances/`);
    yield put({ type: "FETCH_OFFICE_FINANCES_SUCCESS", payload: officeFinances.data || [{ error: officeFinances.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_OFFICE_FINANCES_FAILED"});
  }
}
function* fetchOfficePhotos(action) {
  const officeID = action.payload;
  try {
    const officePhotos = yield axios.get(`/offices-v2/${officeID}/get_photos/`);
    yield put({ type: "FETCH_OFFICE_PHOTOS_SUCCESS", payload: officePhotos.data || [{ error: officePhotos.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_OFFICE_PHOTOS_FAILED"});
    console.log(err);
  }
}
function* fetchOfficeLeasingTeams(action) {
  try {
    const officeID = action.payload;
    const leasingTeams = yield axios.get(`/offices-v2/${officeID}/staff/leasing-teams/`);
    yield put({ type: "FETCH_OFFICE_LEASING_TEAMS_SUCCESS", payload: leasingTeams.data || [{ error: leasingTeams.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchOfficeOwners(action) {
  try {
    const officeID = action.payload;
    const owners = yield axios.get(`/offices-v2/${officeID}/staff/owners/`);
    yield put({ type: "FETCH_OFFICE_OWNERS_SUCCESS", payload: owners.data || [{ error: owners.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchOfficeAmenities(action) {
  try {
    const officeID = action.payload;
    const amenities = yield axios.get(`/offices-v2/${officeID}/staff/amenities/`);
    yield put({ type: "FETCH_OFFICE_AMENITIES_SUCCESS", payload: amenities.data || [{ error: amenities.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchOfficesLatestLeads(action) {
  const params = action.payload.params;
  const filters = setFilters(params, 'updated_at'); // updated_at
  try {
    const latestLeads = yield axios.get(`/offices-v2/latest-leads/${filters}`);
    yield put({ type: "FETCH_OFFICES_LATEST_LEADS_SUCCESS", payload: latestLeads.data || [{ error: latestLeads.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchOfficesVisitingCompanies(action) {
  const params = action.payload.params;
  const filters = setFilters(params, 'updated_at'); // updated_at
  try {
    const visitingCompanies = yield axios.get(`/offices-v2/latest-companies/${filters}`);
    yield put({ type: "FETCH_OFFICES_VISITING_COMPANIES_SUCCESS", payload: visitingCompanies.data || [{ error: visitingCompanies.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchOfficesMinified(action) {
  const params = action.payload.params;
  const filters = params ? setFilters(params) : ''; //number_of_units_available
  try {
    const offices = yield axios.get(`/offices-v2/minified/${filters}`);
    yield put({ type: "FETCH_OFFICES_MINIFIED_SUCCESS", payload: offices.data || [{ error: offices.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* resetOfficesMinified() {
  try {
    yield put({ type: "RESET_OFFICES_MINIFIED_SUCCESS" });
  }
  catch(err) {
    console.log(err);
  }
}
//
function* fetchOfficeRCBuilding(action) {
  const {targetId, year, dataPeriod} = action.payload.params;
  const isMonthly = dataPeriod === "monthly";
  const isWeekly = dataPeriod === "weekly";
  const actionType = (type) => {
    return type === "SUCCESS" ? (
        isMonthly ? "FETCH_OFFICE_RC_BUILDING_MONTHLY_"+type :
        isWeekly && "FETCH_OFFICE_RC_BUILDING_WEEKLY_"+type
    ) : type === "FAILED" && "FETCH_OFFICE_RC_BUILDING_"+type
  };
  try {
    const officeRCAdvance = yield axios.get(`/offices-v2/${targetId}/redd-clicks/building/${year}/${dataPeriod}/`);
    yield put({ type: actionType("SUCCESS"), payload: officeRCAdvance.data || [{ error: officeRCAdvance.statusText }] });
  }
  catch(err) {
    yield put({ type: actionType("FAILED")});
    console.log(err);
  }
}
function* fetchOfficeRCUnits(action) {
  const {targetId, year, dataPeriod} = action.payload.params;
  const isMonthly = dataPeriod === "monthly";
  const isWeekly = dataPeriod === "weekly";
  const actionType = (type) => {
    return type === "SUCCESS" ? (
        isMonthly ? "FETCH_OFFICE_RC_UNITS_MONTHLY_"+type :
        isWeekly && "FETCH_OFFICE_RC_UNITS_WEEKLY_"+type
    ) : type === "FAILED" && "FETCH_OFFICE_RC_UNITS_"+type
  };
  try {
    const officeRCAdvance = yield axios.get(`/offices-v2/${targetId}/redd-clicks/units/${year}/${dataPeriod}/`);
    yield put({ type: actionType("SUCCESS"), payload: officeRCAdvance.data || [{ error: officeRCAdvance.statusText }] });
  }
  catch(err) {
    yield put({ type: actionType("FAILED")});
    console.log(err);
  }
}
function* fetchOfficeRCContact(action) {
  const {targetId, year, dataPeriod} = action.payload.params;
  const isMonthly = dataPeriod === "monthly";
  const isWeekly = dataPeriod === "weekly";
  const actionType = (type) => {
    return type === "SUCCESS" ? (
        isMonthly ? "FETCH_OFFICE_RC_CONTACT_MONTHLY_"+type :
        isWeekly && "FETCH_OFFICE_RC_CONTACT_WEEKLY_"+type
    ) : type === "FAILED" && "FETCH_OFFICE_RC_CONTACT_"+type
  };
  try {
    const officeRCAdvance = yield axios.get(`/offices-v2/${targetId}/redd-clicks/contact/${year}/${dataPeriod}/`);
    yield put({ type: actionType("SUCCESS"), payload: officeRCAdvance.data || [{ error: officeRCAdvance.statusText }] });
  }
  catch(err) {
    yield put({ type: actionType("FAILED")});
    console.log(err);
  }
}
function* fetchOfficeRCAll(action) {
  const {targetId, year, dataPeriod} = action.payload.params;
  const isMonthly = dataPeriod === "monthly";
  const isWeekly = dataPeriod === "weekly";
  const actionType = (type) => {
    return type === "SUCCESS" ? (
        isMonthly ? "FETCH_OFFICE_RC_ALL_MONTHLY_"+type :
        isWeekly && "FETCH_OFFICE_RC_ALL_WEEKLY_"+type
    ) : type === "FAILED" && "FETCH_OFFICE_RC_ALL_"+type
  };
  try {
    const officeRCAdvance = yield axios.get(`/offices-v2/${targetId}/redd-clicks/all/${year}/${dataPeriod}/`);
    yield put({ type: actionType("SUCCESS"), payload: officeRCAdvance.data || [{ error: officeRCAdvance.statusText }] });
  }
  catch(err) {
    yield put({ type: actionType("FAILED")});
    console.log(err);
  }
}
// FETCH DASHBOARD TRANSACTIONS/SUPPLY
function* fetchOfficesDashboardTransactions() {
  try {
    const transactions = yield axios.get(`/offices-v2/transactions-dashboard/`);
    yield put({ type: "FETCH_OFFICES_DASHBOARD_TRANSACTIONS_SUCCESS", payload: transactions.data || [{ error: transactions.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchOfficesDashboardSupplyBoost() {
  try {
    const supplyBoost = yield axios.get(`/offices-v2/supply-dashboard/`);
    yield put({ type: "FETCH_OFFICES_DASHBOARD_SUPPLY_BOOST_SUCCESS", payload: supplyBoost.data || [{ error: supplyBoost.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
// FETCH TRANSACTIONS/SUPPLY
function* fetchOfficesTransactions(action) {
  const params = action.payload.params;
  const filters = setFilters(params); //number_of_units_available
  try {
    const transactions = yield axios.get(`/offices-v2/transactions-list/${filters}`);
    yield put({ type: "FETCH_OFFICES_TRANSACTIONS_SUCCESS", payload: transactions.data || [{ error: transactions.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchOfficesSupplyBoost(action) {
  const params = action.payload.params;
  const filters = setFilters(params); //number_of_units_available
  try {
    const supplyBoost = yield axios.get(`/offices-v2/supply-list/${filters}`);
    yield put({ type: "FETCH_OFFICES_SUPPLY_BOOST_SUCCESS", payload: supplyBoost.data || [{ error: supplyBoost.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}

function* getOfficesViewType() {
  const viewType = yield localStorage.getItem('officesViewType');
  yield put({ type: "GET_VIEW_TYPE_SUCCESS", payload: viewType || [{ error: viewType.statusText }] });
}

// FETCH OFFICES - COMPETITORS ANALYSIS
function* fetchOfficesSimpleList(action) {
  const params = action.payload?.params;
  const ownedBuildings = action.payload?.owned;
  const filters = setFilters(params, 'updated_at', true); //number_of_units_available
  try {
    const offices = yield axios.get(`/offices-v2/simple-list/${filters}`);
    if(ownedBuildings) {
      yield put({ type: "FETCH_OFFICES_OWNED_SIMPLE_LIST_SUCCESS", payload: offices.data || [{ error: offices.statusText }] });
    }
    else {
      yield put({ type: "FETCH_OFFICES_SIMPLE_LIST_SUCCESS", payload: offices.data || [{ error: offices.statusText }] });
    }
  }
  catch(err) {
    console.log(err);
  }
}

// USER ACTIONS
function* addOffice(action) {
  try {
    const office = action.payload;
    const addOffice = yield axios.post(`/offices-v2/`, office);
    yield put({
      type: "ADD_OFFICE_SUCCESS",
      payload: addOffice.data,
      meta: actionNotification('Building has been added.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "ADD_OFFICE_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* deleteOffice(action) {
  try {
    const officeID = action.payload;
    yield axios.delete(`/offices-v2/${officeID}/`);
    yield put({
      type: "DELETE_OFFICE_SUCCESS",
      payload: officeID,
      meta: actionNotification('Building has been removed.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "DELETE_OFFICE_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }

}
function* updateOffice(action) {
  try {
    const {officeID, data, backgroundRefresh} = action.payload;
    const updateOffice = yield axios.patch(`/offices-v2/${officeID}/`, data);
    yield put({
      type: "UPDATE_OFFICE_SUCCESS",
      payload: updateOffice.data,
      meta: actionNotification('Building has been updated.', 'success')
    });
    if(backgroundRefresh) {
      // Refresh Basic Edit Form Data
      yield put({
        type: "FETCH_OFFICE_EDIT_FORMS_DATA_REQUEST",
        payload: {officeID},
      });
      // Refresh Basic Data per Section
      if(backgroundRefresh === "basic") {
        // Refresh Basic Data
        yield put({
          type: 'FETCH_OFFICE_BASIC_REQUEST',
          payload: {
            officeID,
            backgroundLoading: true
          },
        });
        // Refresh Metrics Data
        yield put({
          type: 'FETCH_OFFICE_METRICS_REQUEST',
          payload: {
            officeID,
            backgroundLoading: true
          },
        });
      }
      else if(backgroundRefresh === "location") {
        // Refresh Basic Data
        yield put({
          type: 'FETCH_OFFICE_BASIC_REQUEST',
          payload: {
            officeID,
            backgroundLoading: true
          },
        });
      }
      else if(backgroundRefresh === "leaseTerms") {
        // Refresh Lease Terms Data
        yield put({
          type: 'FETCH_OFFICE_LEASE_TERMS_REQUEST',
          payload: {
            officeID,
            backgroundLoading: true
          },
        });
      }
    }
  }
  catch(err) {
    yield put({
      type: "UPDATE_OFFICE_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* addOfficePhoto(action) {
  try {
    const {officeID, file} = action.payload;
    const { type, name, data } = file;
    const headers = {
      'Content-Type': type,
      'Content-Disposition': `attachment; filename=${name}`,
    };
    // upload photos
    const addPhoto = yield axios.post(`/offices-v2/${officeID}/upload_photo/`, data, {headers});
    yield put({
      type: "ADD_OFFICE_PHOTO_SUCCESS",
      payload: addPhoto.data,
      meta: actionNotification('Images has been added.', 'success')
    });
    // refresh building props to display uploaded photos
    const updateOffice = yield axios.patch(`/offices-v2/${officeID}/`, {});
    yield put({
      type: "UPDATE_OFFICE_SUCCESS",
      payload: updateOffice.data
    });
    // refresh photos props to display uploaded photos
    const officePhotos = yield axios.get(`/offices-v2/${officeID}/get_photos/`);
    yield put({ type: "FETCH_OFFICE_PHOTOS_SUCCESS", payload: officePhotos.data || [{ error: officePhotos.statusText }] });
  }
  catch(err) {
    yield put({
      type: "ADD_OFFICE_PHOTO_FAILED",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data.error) || 'Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* deleteOfficePhoto(action) {
  try {
    const photoId = action.payload;
    yield axios.delete(`/office-photos/${photoId}/`);
    yield put({
      type: "DELETE_OFFICE_PHOTO_SUCCESS",
      payload: photoId,
      meta: actionNotification('Image has been removed.', 'success')
    });
  }
  catch(err) {
    console.log(err);
    yield put({
      type: "DELETE_OFFICE_PHOTO_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* sendOfficeFinanceXLS(action) {
  const {officeID, pk} = action.payload;
  try {
    yield axios.post(`/offices-v2/${officeID}/get_finance_xls/`, pk);
    yield put({ type: "SEND_OFFICE_FINANCE_XLS_SUCCESS" });
  }
  catch(err) {
    yield put({ type: "SEND_OFFICE_FINANCE_XLS_FAILED"});
    console.log(err);
  }
}
function* updateOfficeLeasingTeam(action) {
  try {
    const {officeID, data} = action.payload;
    const updateLeasingTeam = yield axios.post(`/offices-v2/${officeID}/staff/leasing-teams/`, data);
    yield put({
      type: "UPDATE_OFFICE_LEASING_TEAM_SUCCESS",
      payload: updateLeasingTeam.data,
      meta: actionNotification('Leasing Team for selected building has been updated.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "UPDATE_OFFICE_LEASING_TEAM_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* addOfficeOwners(action) {
  try {
    const {officeID, data} = action.payload;
    const owner = yield axios.post(`/offices-v2/${officeID}/staff/owners/`, data);
    yield put({
      type: "ADD_OFFICE_OWNERS_SUCCESS",
      payload: owner.data,
      meta: actionNotification('New Ownership for selected building has been added.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "ADD_OFFICE_OWNERS_FAILED",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data.error) || 'Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* deleteOfficeOwners(action) {
  try {
    const {officeID, data} = action.payload;
    const deleteOwner = yield axios.post(`/offices-v2/${officeID}/staff/delete-owners/`, data);
    yield put({
      type: "DELETE_OFFICE_OWNERS_SUCCESS",
      payload: deleteOwner.data,
      meta: actionNotification('Selected Ownership has been ended.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "DELETE_OFFICE_OWNERS_FAILED",
      payload: err.status,
      meta: actionNotification(err.data.error || 'Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* updateOfficeAmenities(action) {
  try {
    const {officeID, data, backgroundRefresh} = action.payload;
    const amenities = yield axios.post(`/offices-v2/${officeID}/staff/amenities/`, data);
    yield put({
      type: "UPDATE_OFFICE_AMENITIES_SUCCESS",
      payload: amenities.data,
      meta: actionNotification('Amenities for selected building has been updated.', 'success')
    });
    if(backgroundRefresh) {
      // Refresh Basic Edit Form Data
      yield put({
        type: 'FETCH_OFFICE_AMENITIES_REQUEST',
        payload: officeID
      });
      // Refresh Basic Data per Section
      if(backgroundRefresh === "amenities") {
        // Refresh Amenities Data
        yield put({
          type: 'FETCH_OFFICE_BUILDING_AMENITIES_REQUEST',
          payload: {
            officeID,
            backgroundLoading: true
          },
        });
      }
    }
  }
  catch(err) {
    yield put({
      type: "UPDATE_OFFICE_AMENITIES_FAILED",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data.error) || 'Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* compareSelectedOffices(action) {
  try {
    const {data} = action.payload;
    const compare = yield axios.post(`/offices-v2/compare-offices/`, data);
    yield put({
      type: "COMPARE_SELECTED_OFFICES_SUCCESS",
      payload: compare.data
    });
  }
  catch(err) {
    yield put({
      type: "COMPARE_SELECTED_OFFICES_FAILED",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data.error) || 'Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* compareSelectedOfficesReset() {
  try {
    yield put({
      type: "COMPARE_SELECTED_OFFICES_RESET"
    });
  }
  catch(err) {
    console.log(err);
  }
}
function* refreshOffice(action) {
  try {
    const officeID = action.payload;
    const office = yield axios.get(`/offices-v2/${officeID}/updated-at-refresh/`);
    yield put({
      type: "REFRESH_OFFICE_SUCCESS",
      payload: office.data,
      meta: actionNotification('Selected office has been refreshed.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "REFRESH_OFFICE_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}

export function* saga() {
  yield takeLatest('FETCH_OFFICE_LIST_REQUEST', fetchOfficeList);
  yield takeLatest('FETCH_OFFICE_RESET_REQUEST', fetchOfficeResetList);
  yield takeLatest('FETCH_OFFICE_REQUEST', fetchOffice);
  yield takeLatest('FETCH_OFFICE_CLUSTERS_REQUEST', fetchOfficeClusters);
  yield takeLatest('FETCH_OFFICE_CLUSTERS_RESET_REQUEST', fetchOfficeClustersReset);
  yield takeLatest('FETCH_OFFICE_FINANCES_REQUEST', fetchOfficeFinances);
  yield takeLatest('FETCH_OFFICE_PHOTOS_REQUEST', fetchOfficePhotos);
  yield takeLatest('FETCH_OFFICE_LEASING_TEAMS_REQUEST', fetchOfficeLeasingTeams);
  yield takeLatest('FETCH_OFFICE_OWNERS_REQUEST', fetchOfficeOwners);
  yield takeLatest('FETCH_OFFICE_AMENITIES_REQUEST', fetchOfficeAmenities);
  yield takeLatest('FETCH_OFFICES_LATEST_LEADS_REQUEST', fetchOfficesLatestLeads);
  yield takeLatest('FETCH_OFFICES_VISITING_COMPANIES_REQUEST', fetchOfficesVisitingCompanies);
  yield takeLatest('FETCH_OFFICES_MINIFIED_REQUEST', fetchOfficesMinified);
  yield takeLatest('RESET_OFFICES_MINIFIED_REQUEST', resetOfficesMinified);

  yield takeLatest('FETCH_OFFICE_RC_BUILDING_REQUEST', fetchOfficeRCBuilding);
  yield takeLatest('FETCH_OFFICE_RC_UNITS_REQUEST', fetchOfficeRCUnits);
  yield takeLatest('FETCH_OFFICE_RC_CONTACT_REQUEST', fetchOfficeRCContact);
  yield takeLatest('FETCH_OFFICE_RC_ALL_REQUEST', fetchOfficeRCAll);

  yield takeLatest('FETCH_OFFICES_DASHBOARD_TRANSACTIONS_REQUEST', fetchOfficesDashboardTransactions);
  yield takeLatest('FETCH_OFFICES_DASHBOARD_SUPPLY_BOOST_REQUEST', fetchOfficesDashboardSupplyBoost);
  yield takeLatest('FETCH_OFFICES_TRANSACTIONS_REQUEST', fetchOfficesTransactions);
  yield takeLatest('FETCH_OFFICES_SUPPLY_BOOST_REQUEST', fetchOfficesSupplyBoost);

  yield takeLatest('FETCH_OFFICES_SIMPLE_LIST_REQUEST', fetchOfficesSimpleList);

  yield takeLatest('GET_VIEW_TYPE_REQUEST', getOfficesViewType);
  yield takeLatest('ADD_OFFICE_REQUEST', addOffice);
  yield takeLatest('DELETE_OFFICE_REQUEST', deleteOffice);
  yield takeLatest('UPDATE_OFFICE_REQUEST', updateOffice);
  yield takeLatest('ADD_OFFICE_PHOTO_REQUEST', addOfficePhoto);
  yield takeLatest('DELETE_OFFICE_PHOTO_REQUEST', deleteOfficePhoto);
  yield takeLatest('SEND_OFFICE_FINANCE_XLS_REQUEST', sendOfficeFinanceXLS);
  yield takeLatest('UPDATE_OFFICE_LEASING_TEAM_REQUEST', updateOfficeLeasingTeam);
  yield takeLatest('ADD_OFFICE_OWNERS_REQUEST', addOfficeOwners);
  yield takeLatest('DELETE_OFFICE_OWNERS_REQUEST', deleteOfficeOwners);
  yield takeLatest('UPDATE_OFFICE_AMENITIES_REQUEST', updateOfficeAmenities);
  yield takeLatest('COMPARE_SELECTED_OFFICES_REQUEST', compareSelectedOffices);
  yield takeLatest('COMPARE_SELECTED_OFFICES_RESET_REQUEST', compareSelectedOfficesReset);
  yield takeLatest('REFRESH_OFFICE_REQUEST', refreshOffice);
}

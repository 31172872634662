// OFFERS
export const fetchOffersAll = () => ({
  type: "FETCH_OFFERS_ALL_REQUEST",
});
export const fetchShortlistsLandlord = (params) => ({
  type: "FETCH_SHORTLISTS_LANDLORD_REQUEST",
  payload: {
    params,
  },
});
export const fetchShortlistsLandlordBuildings = () => ({
  type: "FETCH_SHORTLISTS_LANDLORD_BUILDINGS_REQUEST"
});
export const fetchShortlistPdf = (shortlistID) => ({
  type: "FETCH_SHORTLIST_PDF_REQUEST",
  payload: shortlistID,
});
export const fetchShortlistUnits = (shortlistID) => ({
  type: "FETCH_SHORTLIST_UNITS_REQUEST",
  payload: shortlistID,
});
export const fetchShortlistUnitsLandlord = (params) => ({
  type: "FETCH_SHORTLISTS_LANDLORD_UNITS_REQUEST",
  payload: { params },
});
export const fetchShortlistLandlordReset = () => ({
  type: "FETCH_SHORTLISTS_LANDLORD_RESET_REQUEST",
});
export const fetchShortlistUnitsReset = () => ({
  type: "FETCH_SHORTLIST_UNITS_RESET_REQUEST",
});
export const fetchShortlistUnitsLandlordReset = () => ({
  type: "FETCH_SHORTLISTS_LANDLORD_UNITS_RESET_REQUEST",
});
// OFFERS ONLINE
export const fetchOfferOnline = (offerID, email, market) => ({
  type: "FETCH_OFFER_ONLINE_REQUEST",
  payload: {
    offerID,
    email,
    market,
  },
});
export const fetchOfferOnlineOffice = (offerID, officeID, email) => ({
  type: "FETCH_OFFER_ONLINE_OFFICE_REQUEST",
  payload: {
    offerID,
    officeID,
    email,
  },
});
export const fetchOfferOnlineOfficeContact = (offerID, officeID, email) => ({
  type: "FETCH_OFFER_ONLINE_OFFICE_CONTACT_REQUEST",
  payload: {
    offerID,
    officeID,
    email,
  },
});
export const fetchOfferOnlineBranding = (offerID) => ({
  type: "FETCH_OFFER_ONLINE_BRANDING_REQUEST",
  payload: {
    offerID,
  },
});
export const fetchOfferOnlineListPhotos = (offerID, email) => ({
  type: "FETCH_OFFER_ONLINE_LIST_PHOTOS_REQUEST",
  payload: {
    offerID,
    email,
  },
});
// OFFERS ACTIONS
export const addShortlist = (data) => ({
  type: "ADD_SHORTLIST_REQUEST",
  payload: { data },
});
export const addUnitToExistingShortlist = (id, units) => ({
  type: "ADD_UNIT_TO_EXISTING_SHORTLIST_REQUEST",
  payload: {
    id,
    units,
  },
});
export const addUnitToNewShortlist = (name, units, forTesting) => ({
  type: "ADD_UNIT_TO_NEW_SHORTLIST_REQUEST",
  payload: {
    name,
    units,
    forTesting
  },
});
export const deleteOffer = (offerID) => ({
  type: "DELETE_OFFER_REQUEST",
  payload: offerID,
});
export const updateOffer = (offerID, data) => ({
  type: "UPDATE_OFFER_REQUEST",
  payload: {
    offerID,
    data,
  },
});
// OFFERS ONLINE ACTIONS
export const verifyOfferOnlineAccess = (offerID, email) => ({
  type: "VERIFY_OFFER_ONLINE_ACCESS_REQUEST",
  payload: {
    offerID,
    email,
  },
});
export const requestOfferOnlineAccess = (offerID, email) => ({
  type: "REQUEST_OFFER_ONLINE_ACCESS_REQUEST",
  payload: {
    offerID,
    email,
  },
});
// OFFERS KUDOS MAIL SEND
export const sendKudosEmailConfirmation = (data) => ({
  type: "SEND_KUDOS_EMAIL_REQUEST",
  payload: {
    data,
  },
});
export const createKudosEmailSignInLog = (data) => ({
  type: "CREATE_KUDOS_EMAIL_SIGN_IN_LOG_REQUEST",
  payload: {
    data,
  },
});
export const createRealTimeEmailSignInLog = (data) => ({
  type: "CREATE_REAL_TIME_EMAIL_SIGN_IN_LOG_REQUEST",
  payload: {
    data,
  },
});
// OFFER WEEKLY SUMMARY - BUTTON REDIRECT
export const createRealTimeEmailWeeklySummaryLog = (data) => ({
  type: "CREATE_REAL_TIME_EMAIL_WEEKLY_SUMMARY_LOG_REQUEST",
  payload: {
    data,
  },
});
// OFFER FIRST OPEN - BUTTON REDIRECT
export const createRealTimeEmailOfferOpenLog = (data) => ({
  type: "CREATE_REAL_TIME_EMAIL_OFFER_OPEN_LOG_REQUEST",
  payload: {
    data,
  },
});

// RESET OFFICE OFFERS LOADING STATES
export const resetOfficeOffersLoadingStates = () => ({
  type: "RESET_OFFICE_OFFERS_LOADING_STATES_REQUEST"
});

// NEW OFFERS - REFACTOR
export const fetchOffersOffices = (params) => ({
  type: "FETCH_OFFERS_OFFICES_REQUEST",
  payload: {
    params,
  },
});
export const fetchOfferOffices = (offerID) => ({
  type: "FETCH_OFFER_OFFICES_REQUEST",
  payload: offerID,
});
export const fetchOfferOfficesPdf = (offerID) => ({
  type: "FETCH_OFFER_OFFICES_PDF_REQUEST",
  payload: offerID,
});
export const removeUnitFromOffer = (offerID, data) => ({
  type: "REMOVE_UNIT_FROM_OFFER_REQUEST",
  payload: {
    offerID,
    data
  },
});

import React from "react";
import styles from "../../../../../common/external/components/index.module.scss";
import {FormattedMessage} from "react-intl";
import SliderCustom from "app/partials/components/SliderCustom";
import ModalCustom from "app/partials/components/ModalCustom";

export default class PropertyParticularsSingleNavigation extends React.Component {
    render() {
        const {onScrollToAnchor, data, photos, reference} = this.props;
        let images = [];
        photos && photos.length && photos.map(photo => images.push(photo.image));
        return(
            <div ref={reference} className={styles.contentNavigation}>
                <button className={styles.contentNavButton} onClick={() => onScrollToAnchor("officeDetails")}><FormattedMessage id="PROPERTY_PARTICULARS.TAB.DETAILS"/></button>
                {data.units && data.units.length ? <button className={styles.contentNavButton} onClick={() => onScrollToAnchor("officeUnits")}><FormattedMessage id="PROPERTY_PARTICULARS.TAB.AVAILABLE_UNITS"/></button> : null}
                {data.amenities && data.amenities.length ? <button className={styles.contentNavButton} onClick={() => onScrollToAnchor("officeAmenities")}><FormattedMessage id="PROPERTY_PARTICULARS.TAB.AMENITIES"/></button> : null}
                <button className={styles.contentNavButton} onClick={() => onScrollToAnchor("officeLocation")}><FormattedMessage id="PROPERTY_PARTICULARS.TAB.LOCATION"/></button>
                {data.publicTransport && data.publicTransport.length ? <button className={styles.contentNavButton} onClick={() => onScrollToAnchor("officeTransport")}><FormattedMessage id="PROPERTY_PARTICULARS.TAB.PUBLIC_TRANSPORT"/></button> : null}
                {images.length ? (
                <ModalCustom
                  btn={
                    <button className={styles.contentNavButton}><FormattedMessage id="PROPERTY_PARTICULARS.TAB.GALLERY"/></button>
                  }
                  title=""
                  btnReject={<FormattedMessage id="GENERAL.PHRASE.CLOSE"/>}
                  btnFlex
                  fullScreen
                  inactive={!images || (images && images.length === 0)}
                >
                  <SliderCustom images={images}/>
                </ModalCustom>
                ) : null}
            </div>
        );
    }
}
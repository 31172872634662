import React from "react";
import clsx from "clsx";
import {Icon, Typography} from "@material-ui/core";
import styles from "./index.module.scss";
import {FormattedMessage} from "react-intl";

export default class DashboardExtendedDataItem extends React.Component {
  render() {
    const {
      value,
      label,
      col,
      variant,
      semiValue,
      semiLabel,
      semiColor = "default",
      border,
      borderColor = "default",
      style,
      icon,
      titleSize,
    } = this.props;
    const itemCol =
      col === 2 ? "col2" : col === 3 ? "col3" : col === 4 ? "col4" : "";
    return (
      <div
        className={clsx(
          "kt-widget12__info",
          itemCol,
          styles.mainContainer,
          border && styles.leftBorder,
          border && styles[`border_${borderColor}`]
        )}
        style={style}
      >
        <div className={styles.mainWrapper}>
          {icon && <div className={styles.icon}>{icon}</div>}
          <div>
            <Typography
              variant={variant ? variant : "h2"}
              style={titleSize && { fontSize: titleSize }}
            >
              {value || <FormattedMessage id="GENERAL.PHRASE.NO_DATA" />}
            </Typography>
            <span className="kt-widget12__desc">{label}</span>
            {semiValue && (
              <Typography variant="body2">
                <span
                  className={clsx(styles.bold, semiColor && styles[semiColor])}
                >
                  <span style={{ fontSize: "1.3rem" }}>
                    {semiColor === "success" && (
                      <Icon fontSize="inherit">trending_up</Icon>
                    )}
                    {semiColor === "danger" && (
                      <Icon fontSize="inherit">trending_down</Icon>
                    )}
                    {semiColor === "noData" && (
                      <Icon fontSize="inherit">highlight_off</Icon>
                    )}
                    {semiColor === "noChange" && (
                      <Icon fontSize="inherit">do_disturb_alt</Icon>
                    )}
                  </span>{" "}
                  {semiValue}
                </span>{" "}
                {semiColor === "noData" ? (
                  <FormattedMessage id="GENERAL.PHRASE.NO_DATA" />
                ) : (
                  semiLabel
                )}
              </Typography>
            )}
          </div>
        </div>
      </div>
    );
  }
}

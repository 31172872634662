import React from "react";
import {theExcerpt} from "app/utils/helpers";
import PieChart from "app/partials/components/Charts/PieChart";
import {FormattedMessage} from "react-intl";

export default class OwnerSectors extends React.Component {
  getSectors = () => {
    const {ownerSectors, intl} = this.props;
    const lang = intl.locale;
    let sectors = [];
    ownerSectors.companies !== undefined && ownerSectors.companies.map((sector) => sectors.push({
      value: sector.count,
      label: lang === "pl" ? theExcerpt(sector.tenant_Sector_Parent_Name, 40) : theExcerpt(sector.tenant_Sector_Parent_NameEn, 40)
    }));
    return sectors;
  };
  render() {

    return(
      <PieChart
        desc={<FormattedMessage id="BUILDING.PHRASE.TENANTS_CHART_MESSAGE"/>}
        dataChart={this.getSectors()}
      />
    );
  }
}
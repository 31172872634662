import React from 'react';
import {Text, View, StyleSheet, Image} from '@react-pdf/renderer';
import {BUILDING_TYPES} from "app/constants";
import {toDateFormat, toNumberFormat, snakeToCamelCase} from "app/utils/helpers";
import {UNIT_TYPES} from "app/constants";
import OfferPdfPageHeader from "../components/OfferPdfPageHeader";
import OfferPdfPageFooter from "../components/OfferPdfPageFooter";
import OfferPdfPage from "../components/OfferPdfPage";

/*
Łódź - 51.77059846317887, 19.5070449497188
Wrocław - 51.117953020432644, 17.00002769445789
Gdańsk - 54.3628898929504, 18.647635525140597
Olsztyn - 53.770000898903845, 20.509967440481947
Rzeszów - 50.03425179443476, 21.995912915341357
Poznań - 52.4298511463623, 16.848850559518045
Szczecin - 53.435307285200444, 14.555114626987967
*/
/*
https://maps.googleapis.com/maps/api/staticmap
?center=50.03425179443476, 21.995912915341357
&zoom=14
&size=560x280
&markers=50.03425179443476, 21.995912915341357
&key=AIzaSyDMROiw9UzdnWoXeLjnfHm8Y3v-TBiQJLw
&style=feature%3Aall%7Celement%3Alabels.text.fill%7Csaturation%3A36%7Ccolor%3A0xf7f7f7%7Clightness%3A40%7C&style=feature%3Aall%7Celement%3Alabels.text.stroke%7Cvisibility%3Aon%7Ccolor%3A0x2d2d2d%7Clightness%3A16%7C&style=feature%3Aall%7Celement%3Alabels.icon%7Cvisibility%3Aoff%7C&style=feature%3Aadministrative%7Celement%3Ageometry.fill%7Ccolor%3A0xe9e9e9%7Clightness%3A20%7C&style=feature%3Aadministrative%7Celement%3Ageometry.stroke%7Ccolor%3A0xf7f7f7%7Clightness%3A17%7Cweight%3A1.2%7C&style=feature%3Aadministrative.neighborhood%7Celement%3Alabels%7Cvisibility%3Aoff%7C&style=feature%3Alandscape%7Celement%3Ageometry%7Ccolor%3A0xf7f7f7%7Clightness%3A20%7C&style=feature%3Apoi%7Celement%3Ageometry%7Ccolor%3A0xe9e9e9%7Clightness%3A21%7C&style=feature%3Aroad.highway%7Celement%3Ageometry.fill%7Ccolor%3A0xbfbfbf%7Clightness%3A17%7C&style=feature%3Aroad.highway%7Celement%3Ageometry.stroke%7Ccolor%3A0xbfbfbf%7Clightness%3A29%7Cweight%3A0.2%7C&style=feature%3Aroad.arterial%7Celement%3Ageometry%7Ccolor%3A0xbfbfbf%7Clightness%3A18%7C&style=feature%3Aroad.local%7Celement%3Ageometry%7Ccolor%3A0xcfcfcf%7Clightness%3A16%7C&style=feature%3Atransit%7Celement%3Ageometry%7Ccolor%3A0xbfbfbf%7Clightness%3A19%7C&style=feature%3Awater%7Celement%3Ageometry%7Ccolor%3A0xe1f6ff%7Clightness%3A17%7C
 */

// Create styles
const styles = StyleSheet.create({
  headerSection: {
    flexDirection: 'row',
    marginBottom: 5, // Changed from 10 to 5
    justifyContent: 'space-between', // This will create space between columns
  },
  buildingInfoColumn: {
    width: '60%', // 65% width for the left column
  },
  buildingInfoRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  buildingImage: {
    width: 105,
    height: 105,
    aspectRatio: 1, // This ensures a 1:1 aspect ratio
    objectFit: 'cover',
    borderRadius: 2, // Added border radius of 2
  },
  buildingInfo: {
    width: '64.54%', // Remaining width in the buildingInfoColumn (65% - 25% = 40% of page width, which is 58.54% of the buildingInfoColumn)
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  buildingName: {
    fontSize: 16, // Adjust if needed for the longer name
    fontWeight: 'medium',
    marginBottom: 2,
  },
  buildingMapColumn: {
    width: '40%'
  },
  buildingMap: {
    width: 210,
    height: 105
  },
  address: {
    color: '#2d2d2d',
  },
  headerSectionTable: {
    display: 'flex',
    width: 'auto',
  },
  headerSectionTableRow: {
    flexDirection: 'row',
    height: 15,
  },
  headerSectionTableCol: {
    width: '50%',
  },
  headerSectionTableCell: {
    textAlign: 'left',
    paddingHorizontal: 0, // Removed horizontal padding
    flexWrap: 'nowrap', // Prevent text wrap
  },
  headerSectionTableCellRight: {
    textAlign: 'left', // Changed from 'right' to 'left'
    paddingHorizontal: 0, // Removed horizontal padding
    flexWrap: 'nowrap', // Prevent text wrap
  },
  ownerColumn: {
    width: '35%', // 30% width for the right column
  },
  contactSection: {
    flex: 1,
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    borderTopWidth: 1,
    borderTopColor: '#E6E6E6',
    borderTopStyle: 'solid',
    paddingTop: 10
  },
  contactBox: {
    width: 'auto', // Make it full width of its parent
    minWidth: '20%',
    backgroundColor: '#FBFBFB',
    borderRadius: 3,
    padding: 10,
  },
  contactInfo: {
    flex: 1,
    justifyContent: 'center', // This will vertically center the text
  },
  contactLabel: {
    marginBottom: 2, // Reduced margin to bring text closer together
    fontWeight: 'medium',
  },
  contactName: {
    padding: 0,
  },
  ownerLogoContainer: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: 2, // Changed from 1 to 2
    borderRadius: 1,
    width: 'auto',
    height: 20
  },
  ownerLogo: {
    width: 'auto',  // Adjust as needed
    height: 18,  // This will maintain the aspect ratio
  },
  reportDate: {
    color: '#A2A5B8',
    marginTop: 6
  },
  sectionTitle: {
    fontSize: 12,
    fontWeight: 'medium',
    marginBottom: 10,
    marginTop: 15, // Changed from 20 to 15
  },
  leasedTermsSection: {
    marginBottom: 5, // Changed from 10 to 5
  },
  tablesContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  leasedTermsTable: {
    width: '48%',
  },
  leasedTermsTableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#E6E6E6',
    borderBottomStyle: 'solid',
    alignItems: 'center',
    height: 24,
  },
  availableUnitsSection: {
    marginBottom: 5, // Changed from 10 to 5
    width: '100%',
  },
  unitRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#E6E6E6',
    borderBottomStyle: 'solid',
    alignItems: 'center',
    minHeight: 24,
  },
  unitHeaderRow: {
    backgroundColor: '#f9f9f9',
    minHeight: 20, // 20% smaller than the regular row (30 * 0.8 = 24)
  },
  unitCell: {
    textAlign: 'left',
    flex: 1,
    paddingVertical: 3, // Changed from 5 to 3
    paddingHorizontal: 10, // Changed from 5 to 10
    flexWrap: 'nowrap', // Prevent text wrap
  },
  unitCellRight: {
    textAlign: 'right',
    flex: 1,
    paddingVertical: 3, // Changed from 5 to 3
    paddingHorizontal: 10, // Changed from 5 to 10
    flexWrap: 'nowrap', // Prevent text wrap
  },
  unitHeaderCell: {
    textAlign: 'left',
    flex: 1,
    paddingVertical: 3, // Changed from 5 to 3
    paddingHorizontal: 10, // Changed from 5 to 10
    flexWrap: 'nowrap', // Prevent text wrap
  },
  unitHeaderCellRight: {
    textAlign: 'right',
    flex: 1,
    paddingVertical: 3, // Changed from 5 to 3
    paddingHorizontal: 10, // Changed from 5 to 10
    flexWrap: 'nowrap', // Prevent text wrap
  },
  leasedTermsTableCol: {
    width: '35%',
  },
  leasedTermsTableLabelCol: {
    width: '65%'
  },
  leasedTermsTableCell: {
    textAlign: 'left',
    paddingVertical: 5, // Changed from 5 to 3
    paddingHorizontal: 10, // Changed from 5 to 10
    flexWrap: 'nowrap', // Prevent text wrap
  },
  leasedTermsTableCellRight: {
    textAlign: 'right', // Align to right
    fontWeight: 'medium',
    paddingVertical: 5, // Changed from 5 to 3
    paddingHorizontal: 10, // Changed from 5 to 10
    flexWrap: 'nowrap', // Prevent text wrap
  },
  sectionsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5, // Changed from 10 to 5
  },
  sectionAmenities: {
    width: '60%',  // Set width to 48% for both sections
  },
  sectionAmenitiesFullWidth: {
    width: '100%'
  },
  sectionCertificates: {
    width: '36%',  // Set width to 48% for both sections
  },
  fullWidthSection: {
    marginBottom: 5, // Changed from 10 to 5
    width: '100%',  // Set width to 100% for Market and Competition Comparison sections
  },
  amenitiesContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 5,
    marginBottom: 5, // Changed from 10 to 5
  },
  amenityItem: {
    borderColor: '#E6E6E6',
    borderWidth: 1,
    borderRadius: 10, // This is equivalent to 25% for most cases
    paddingVertical: 2,
    paddingHorizontal: 6,
    marginRight: 5,
    marginBottom: 5,
  },
  certificatesContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap', // Allow wrapping of certificates
    marginTop: 5,
    marginBottom: 5, // Changed from 10 to 5
  },
  certificateItem: {
    marginRight: 10,
    marginBottom: 10,
    borderWidth: 1,
    borderColor: '#E6E6E6',
    borderRadius: 3,
    padding: 5,
    alignItems: 'center', // Center horizontally
    justifyContent: 'center', // Center vertically
  },
  certificateLogo: {
    width: 40, // Set max width to 80px
    height: 'auto',
  },
  cellPlaceholder: {
    textAlign: 'left',
    flex: 1,
    paddingVertical: 5,
    paddingHorizontal: 0,
    width: 2,
  },
  buildingNameContainer: {
    width: '100%', // Take 100% width of the page
    textAlign: 'left', // Align text to the left
    marginBottom: 10, // Add some margin below the building name
  },
});

// Helper function to replace EUR with €
const formatCurrency = (value) => {
  return value ? value.replace('EUR', ' €') : value;
};

// Create Document Component
function OfferPdfBuildingPage(props) {
  const {
    intl,
    data,
    building,
    isSingleProperty
  } = props;
  const {leasingManager, amenities, certificates, units} = building;
  // Function to translate strings
  const handleStringTranslation = (id, values) => {
    return intl.formatMessage({id: id}, values);
  };
  // Building data
  const buildingName = building?.name || "-";
  const buildingType = building?.buildingType;
  const streetName = building?.addressStreet;
  const cityName = building?.addressCity;
  const buildingAddress = [
    ...streetName ? [streetName] : [],
    ...cityName ? [cityName] : []
  ].join(", ");
  const buildingClass = building?.buildingClass || "-";
  const completionYear = building?.commissioningYear || "-";
  const grossArea = toNumberFormat(building?.totalAreaBrutto) + " m²" || "0 m²";
  const netArea = toNumberFormat(building?.totalAreaNetto) + " m²" || "0 m²";
  const buildingImage = building?.mainPhotoThumbnail400x400 || "/media/office-card-placeholder.jpg";
  const buildingMap = building?.offerMap;
  const buildingTypeRender =
    buildingType === BUILDING_TYPES.OFFICE_BUILDING.id ? handleStringTranslation(BUILDING_TYPES.OFFICE_BUILDING.name) :
      buildingType === BUILDING_TYPES.APARTMENT_HOUSE.id ? handleStringTranslation(BUILDING_TYPES.APARTMENT_HOUSE.name) :
        buildingType === BUILDING_TYPES.WAREHOUSE.id ? handleStringTranslation(BUILDING_TYPES.WAREHOUSE.name) :
          buildingType === BUILDING_TYPES.SHOPPING_CENTER.id ? handleStringTranslation(BUILDING_TYPES.SHOPPING_CENTER.name) :
            buildingType === BUILDING_TYPES.HQ.id ? handleStringTranslation(BUILDING_TYPES.HQ.name) :
              buildingType === BUILDING_TYPES.TENEMENT_HOUSE.id ? handleStringTranslation(BUILDING_TYPES.TENEMENT_HOUSE.name) : handleStringTranslation(BUILDING_TYPES.OTHER.name);
  // Leasing contacts data
  const isLeasingManagerEnable = data?.isLeasingManagerEnable;
  const leasingCompanyName = leasingManager?.name;
  const leasingPersonEmail = leasingManager?.email;
  const leasingPersonPhone = leasingManager?.phone;
  // Lease terms data
  const rentOfficeRange = [
    ...building?.rentFromEur ? [toNumberFormat(building?.rentFromEur)] : [],
    ...building?.rentToEur ? [toNumberFormat(building?.rentToEur)] : []
  ];
  const rentRetailRange = [
    ...building?.retailRentPriceEur ? [toNumberFormat(building?.retailRentPriceEur)] : [],
    ...building?.retailRentPriceToEur ? [toNumberFormat(building?.retailRentPriceToEur)] : []
  ];
  const rentOffice = (building?.rentToNegotiate || rentOfficeRange?.length <= 0) ? handleStringTranslation("BUILDING.PHRASE.TO_NEGOTIATE") : formatCurrency(rentOfficeRange.join(" - ") + "EUR");
  const rentRetail = (building?.rentToNegotiate || rentRetailRange?.length <= 0) ? handleStringTranslation("BUILDING.PHRASE.TO_NEGOTIATE") : formatCurrency(rentRetailRange.join(" - ") + "EUR");
  const parkingFactorArray = [
    ...building?.parkingFactorNumber ? [building?.parkingFactorNumber] : building?.parkingFactorArea ? ["1"] : [],
    ...building?.parkingFactorArea ? [building?.parkingFactorArea] : []
  ];
  const parkingFactor = parkingFactorArray?.length > 0 ? parkingFactorArray.join("/") : "-";
  const parkingGround = building?.parkingGroundNotExist ? handleStringTranslation("VOCABULARY.Not exist") :
    building?.parkingGroundToNegotiate ? handleStringTranslation("BUILDING.PHRASE.TO_NEGOTIATE") :
      building?.parkingGroundRentEur ? formatCurrency(Number(building?.parkingGroundRentEur) + "EUR") : "-";
  const parkingUnderground = building?.parkingUndergroundNotExist ? handleStringTranslation("VOCABULARY.Not exist") :
    building?.parkingUndergroundToNegotiate ? handleStringTranslation("BUILDING.PHRASE.TO_NEGOTIATE") :
      building?.parkingUndergroundRentEur ? formatCurrency(Number(building?.parkingUndergroundRentEur) + "EUR") : "-";
  const serviceCharge = building?.serviceChargesPln ? formatCurrency(Number(building?.serviceChargesPln) + " PLN") : "-";
  const commonAreaFactor = building?.commonAreaFactor ? Number(building?.commonAreaFactor) + "%" : "-";
  const leasePeriod = building?.minRentalYearsToNegotiate ? handleStringTranslation("BUILDING.PHRASE.TO_NEGOTIATE") :
    building?.minRentalYearsIndefinite ? handleStringTranslation("BUILDING.PHRASE.INDEFINITE") :
      building?.minRentalYears ?
        handleStringTranslation("BUILDING.PHRASE.YEARS_VALUE", {
          years: building?.minRentalYears,
          count: building?.minRentalYears
        }) : "-";
  // Amenities
  const isAmenitiesActive = Object.keys(amenities)?.filter(amenity => amenities?.[amenity])?.length > 0;
  // Certificates
  const isCertificatesActive = () => {
    if (certificates) {
      const {
        breeamCertification,
        wellCertification,
        leedCertification,
        hqeCertification,
        dgnbCertification,
        withoutBarrierCertification
      } = certificates;
      const certs = [
        ...breeamCertification ? [true] : [],
        ...wellCertification ? [true] : [],
        ...leedCertification ? [true] : [],
        ...hqeCertification ? [true] : [],
        ...dgnbCertification ? [true] : [],
        ...withoutBarrierCertification ? [true] : []
      ];
      return certs?.length > 0;
    }
    return false;
  }
  const renderCertificates = () => {
    if (certificates) {
      const {
        breeamCertification,
        wellCertification,
        leedCertification,
        hqeCertification,
        dgnbCertification,
        withoutBarrierCertification
      } = certificates;
      const certs = [
        ...breeamCertification ? [{
          icon: <Image src="/media/certificates/breeam.png" alt="BREEAM" style={styles.certificateLogo}/>,
          title: "BREEAM"
        }] : [],
        ...wellCertification ? [{
          icon: <Image src="/media/certificates/well.png" alt="WELL" style={styles.certificateLogo}/>,
          title: "WELL"
        }] : [],
        ...leedCertification ? [{
          icon: <Image src="/media/certificates/leed.png" alt="LEED" style={styles.certificateLogo}/>,
          title: "LEED"
        }] : [],
        ...hqeCertification ? [{
          icon: <Image src="/media/certificates/hqe.png" alt="HQE" style={styles.certificateLogo}/>,
          title: "HQE"
        }] : [],
        ...dgnbCertification ? [{
          icon: <Image src="/media/certificates/dgnb.png" alt="DGNB" style={styles.certificateLogo}/>,
          title: "DGNB"
        }] : [],
        ...withoutBarrierCertification ? [{
          icon: <Image src="/media/certificates/obb.png" alt="Obiekt Bez Barier" style={styles.certificateLogo}/>,
          title: "OBB"
        }] : []
      ];

      return certs?.map((cert, index) => (
        <View key={index} style={styles.certificateItem}>
          {cert?.icon}
        </View>
      ));
    }
    return null;
  }

  return (
    <OfferPdfPage buildingPage={!isSingleProperty} {...props}>
      {/* Page Header */}
      {isSingleProperty ?
        <OfferPdfPageHeader
          {...props}
        /> : null
      }

      {/* Building Name */}
      <View style={styles.buildingNameContainer}>
        <Text style={styles.buildingName}>{buildingName}</Text>
        <Text style={styles.address}>{buildingAddress}</Text>
      </View>

      {/* First page content */}
      <View style={styles.headerSection}>
        <View style={styles.buildingInfoColumn}>
          <View style={styles.buildingInfoRow}>
            <Image
              style={styles.buildingImage}
              src={`${buildingImage}`}
            />
            <View style={styles.buildingInfo}>
              <View style={styles.headerSectionTable}>
                {[
                  [handleStringTranslation("VOCABULARY.Type"), buildingTypeRender],
                  [handleStringTranslation("VOCABULARY.Class"), buildingClass],
                  [handleStringTranslation("VOCABULARY.Completion year"), completionYear],
                  [handleStringTranslation("VOCABULARY.Gross area"), grossArea],
                  [handleStringTranslation("VOCABULARY.Net area"), netArea],
                ].map(([label, value]) => (
                  <View style={styles.headerSectionTableRow} key={label}>
                    <View style={styles.headerSectionTableCol}>
                      <Text style={styles.headerSectionTableCell}>{label}:</Text>
                    </View>
                    <View style={styles.headerSectionTableCol}>
                      <Text style={styles.headerSectionTableCellRight}>{value}</Text>
                    </View>
                  </View>
                ))}
              </View>
            </View>
          </View>
        </View>
        {buildingMap ?
        <View style={styles.buildingMapColumn}>
          <Image
            style={styles.buildingMap}
            src={`${buildingMap}`}
          />
        </View> : null
        }
      </View>

      {/* Leased Terms Section */}
      <View style={styles.leasedTermsSection}>
        <Text style={styles.sectionTitle}>{handleStringTranslation("VOCABULARY.Basic conditions")}</Text>
        <View style={styles.tablesContainer}>
          {/* First Table */}
          <View style={styles.leasedTermsTable}>
            {[
              [handleStringTranslation("VOCABULARY.Rent - office"), rentOffice],
              [handleStringTranslation("VOCABULARY.Rent - retail"), rentRetail],
              [handleStringTranslation("VOCABULARY.Service charge"), serviceCharge],
              [handleStringTranslation("VOCABULARY.Lease period"), leasePeriod],
            ].map(([label, value]) => (
              <View style={styles.leasedTermsTableRow} key={label}>
                <View style={[styles.leasedTermsTableCol, styles.leasedTermsTableLabelCol]}>
                  <Text style={styles.leasedTermsTableCell}>{label}</Text>
                </View>
                <View style={styles.leasedTermsTableCol}>
                  <Text style={styles.leasedTermsTableCellRight}>{value}</Text>
                </View>
              </View>
            ))}
          </View>

          {/* Second Table */}
          <View style={styles.leasedTermsTable}>
            {[
              [handleStringTranslation("VOCABULARY.Common area factor"), commonAreaFactor],
              [handleStringTranslation("VOCABULARY.Parking factor"), parkingFactor],
              [handleStringTranslation("VOCABULARY.Parking ground"), parkingGround],
              [handleStringTranslation("VOCABULARY.Parking underground"), parkingUnderground],
            ].map(([label, value]) => (
              <View style={styles.leasedTermsTableRow} key={label}>
                <View style={[styles.leasedTermsTableCol, styles.leasedTermsTableLabelCol]}>
                  <Text style={styles.leasedTermsTableCell}>{label}</Text>
                </View>
                <View style={styles.leasedTermsTableCol}>
                  <Text style={styles.leasedTermsTableCellRight}>{formatCurrency(value)}</Text>
                </View>
              </View>
            ))}
          </View>
        </View>
      </View>

      {/* Available Units Section */}
      {units?.length > 0 ? (
        <View style={styles.availableUnitsSection}>
          <Text style={styles.sectionTitle}>{handleStringTranslation("VOCABULARY.Units offered")}</Text>

          {/* Table Header */}
          <View style={[styles.unitRow, styles.unitHeaderRow]}>
            <Text style={styles.unitHeaderCell}>{handleStringTranslation("VOCABULARY.Type")}</Text>
            <Text style={styles.unitHeaderCellRight}>{handleStringTranslation("VOCABULARY.Area")}</Text>
            <Text style={styles.unitHeaderCellRight}>{handleStringTranslation("VOCABULARY.Floor")}</Text>
            <Text style={styles.unitHeaderCell}>{handleStringTranslation("VOCABULARY.Availability")}</Text>
            <Text style={styles.unitHeaderCell}>{handleStringTranslation("VOCABULARY.Updated at")}</Text>
          </View>

          {/* Table Rows */}
          {units?.map((unit, index) => {
            const unitArea = unit?.area ? toNumberFormat(unit?.area) + " m²" : "0 m²";
            // type
            const unitType = unit?.unitType;
            const type = unitType === UNIT_TYPES.RETAIL ? handleStringTranslation("GENERAL.PHRASE.RETAIL") :
              unitType === UNIT_TYPES.OFFICE_RETAIL ? handleStringTranslation("GENERAL.PHRASE.OFFICE_RETAIL") :
                handleStringTranslation("GENERAL.PHRASE.OFFICE");

            return (
              <View key={index} style={styles.unitRow} wrap={false}>
                <Text style={styles.unitCell}>{type}</Text>
                <Text style={styles.unitCellRight}>{unitArea}</Text>
                <Text style={styles.unitCellRight}>{unit?.floor || "-"}</Text>
                <Text style={styles.unitCell}>{unit?.availableFrom ? toDateFormat(unit?.availableFrom) : "-"}</Text>
                <Text style={styles.unitCell}>{toDateFormat(unit?.updatedAt)}</Text>
              </View>
            )
          })}
        </View>
      ) : null}

      {/* Amenities and Certificates Sections */}
      <View style={styles.sectionsContainer} wrap={false}>
        {/* Amenities Section */}
        {isAmenitiesActive && (
          <View style={[
            styles.sectionAmenities,
            isCertificatesActive() ? undefined : styles.sectionAmenitiesFullWidth
          ]}>
            <Text style={styles.sectionTitle}>{handleStringTranslation("VOCABULARY.Amenities")}</Text>
            <View style={styles.amenitiesContainer}>
              {Object.keys(amenities)?.map((amenity, index) => {
                const isActive = amenities?.[amenity];

                if (isActive) {
                  return (
                    <View key={index} style={styles.amenityItem}>
                      <Text>{handleStringTranslation(snakeToCamelCase(amenity))}</Text>
                    </View>
                  );
                }
                return null;
              })}
            </View>
          </View>
        )}

        {/* Certificates Section */}
        {isCertificatesActive() && (
          <View style={styles.sectionCertificates}>
            <Text style={styles.sectionTitle}>{handleStringTranslation("VOCABULARY.Certificates")}</Text>
            <View style={styles.certificatesContainer}>
              {renderCertificates()}
            </View>
          </View>
        )}
      </View>

      {/* Amenities and Certificates Sections */}
      {isLeasingManagerEnable && leasingCompanyName ? (
        <View style={styles.contactSection}>
          <View style={styles.contactBox}>
            <View style={styles.contactInfo}>
              <Text style={styles.contactLabel}>{handleStringTranslation("VOCABULARY.Leasing Manager")}</Text>
              {leasingPersonPhone && <Text style={styles.contactName}>{leasingPersonPhone}</Text>}
              {leasingPersonEmail && <Text style={styles.contactName}>{leasingPersonEmail}</Text>}
            </View>
          </View>
        </View>
      ) : null}

      {/* Footer */}
      <OfferPdfPageFooter {...props}/>
    </OfferPdfPage>
  );
};

export default OfferPdfBuildingPage;


import React from 'react';
import ModalCustom from "app/partials/components/ModalCustom";
import InputIcon from "app/partials/components/InputIcon";
import {addOffice} from "app/crud/estates/offices/offices.crud";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {fetchMarkets, fetchMarketsIndustrial} from "app/crud/user.crud";
import LoadingScreen from "app/partials/components/LoadingScreen";
import {Grid} from '@material-ui/core';
import _ from "lodash";
import AutocompleteCustom from "../AutocompleteCustom";
import Button from "../Button";
import Icon from "../Icon";

class OfficeAddBuilding extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      name: '',
      market: null,
      nameError: false,
      marketError: false
    };
  }
  componentDidMount = async () => {
    this._isMounted = true;
    const {fetchMarkets, markets, marketsLoading} = this.props;

    if(!marketsLoading && _.isEmpty(markets)) {
      fetchMarkets();
    }
  };
  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = (event) => {
    this.setState({
      name: event.target.value
    });
  };
  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleSelectedMarket = selected => {
    this.setState({
      market: selected
    });
  };
  handleSave = () => {
    const {name, market} = this.state;
    const {addOffice} = this.props;
    const marketId = market?.id;

    this.setState({
      nameError: name?.length <= 0,
      marketError: !marketId
    }, () => {
      if(name?.length > 0 && marketId) {
        addOffice({name, market: marketId, units: []});
        this.handleCloseModal();
        return true;
      }
    });
  };

  render() {
    const {markets, marketsLoading} = this.props;
    const {name, market, nameError} = this.state;

    if(markets === undefined) {
      return <LoadingScreen/>;
    }
    return(
      <ModalCustom
        ref={this.child}
        btn={
          <Button
            color="success"
            textIcon={<Icon icon={"add-clean"}/>}
          >
            <FormattedMessage id="BUILDING.PHRASE.ADD_BUILDING"/>
          </Button>
        }
        btnConfirm={<FormattedMessage id="GENERAL.PHRASE.ADD"/>}
        title={<FormattedMessage id="BUILDING.PHRASE.ADD_BUILDING"/>}
        handleSave={this.handleSave}
      >
        {marketsLoading ? <LoadingScreen/> :
        <Grid container spacing={2} direction="column">
          <Grid item>
            <InputIcon label={<FormattedMessage id="BUILDING.PHRASE.BUILDING_NAME"/>} name="new_building_name" value={name} error={nameError} onChange={this.handleChange}/>
          </Grid>
          <Grid item>
            <AutocompleteCustom
              name="market"
              value={market || undefined}
              options={markets}
              optionLabelKey="name"
              inputLabel={<FormattedMessage id="FILTER.FORM.FIELD.MARKET" />}
              inputValue={market?.name}
              onChange={(event, value) => this.handleSelectedMarket(value)}
            />
          </Grid>
        </Grid>
        }
      </ModalCustom>
    );
  }
}

const mapStateToProps = (store) => ({
  user: store?.user.data,
  markets: store?.user?.markets,
  marketsLoading: store?.user?.marketsLoading,
});
const mapDispatchToProps = {
  addOffice: office => addOffice(office),
  fetchMarkets: fetchMarkets,
  fetchMarketsIndustrial: fetchMarketsIndustrial
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OfficeAddBuilding)
);
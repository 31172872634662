import React from "react";
import MenuSection from "./MenuSection";
import MenuItemSeparator from "./MenuItemSeparator";
import MenuItem from "./MenuItem";
import APPLICATION_VIEWS from "../../../app/constants/APPLICATION_VIEWS";
import IsAccountType from "../../../app/utils/IsAccountType";
import connect from "react-redux/es/connect/connect";

class MenuList extends React.Component {
  render() {
    const {
      currentUrl,
      menuConfig,
      layoutConfig,
      onMenuItemClick,
      userFollowersCount,
      userDataMode,
      symmcoAccess,
      navMetrics,
      isDummyViewActive
    } = this.props;
    const menuConfigDataMode =
      userDataMode === APPLICATION_VIEWS.INDUSTRIAL
        ? "asideIndustrial"
        : "aside";
    const menuItems = menuConfig[menuConfigDataMode].items.filter((item) => {
      if (item.submenu) {
        return item.submenu.filter((subItem) => {
          const accountTypePermission = subItem?.permissions?.accountType;
          return accountTypePermission ? IsAccountType({expectedAccountType: accountTypePermission}) : true;
        }).length > 0;
      }
      else if (item?.permissions) {
        const accountTypePermission = item?.permissions?.accountType;
        const isSymmcoActive = item?.title === "Symmco" ? symmcoAccess : true;
        const isAccountTypePermitted = accountTypePermission ? IsAccountType({expectedAccountType: accountTypePermission}) : true;
        return isAccountTypePermitted && isSymmcoActive;
      }
      return item.section;
    });
    return menuItems.map((child, index) => {
      return (
        <React.Fragment key={`menuList${index}`}>
          {child.section &&
            menuItems &&
            menuItems[index + 1] &&
            !menuItems[index + 1].section && <MenuSection item={child} />}
          {child.separator && <MenuItemSeparator item={child} />}
          {child.title && (
            <MenuItem
              item={child}
              currentUrl={currentUrl}
              layoutConfig={layoutConfig}
              userFollowersCount={userFollowersCount}
              onMenuItemClick={onMenuItemClick}
              navMetrics={navMetrics}
              isDummyViewActive={isDummyViewActive}
            />
          )}
        </React.Fragment>
      );
    });
  }
}

const mapStateToProps = store => ({
  symmcoAccess: store?.user?.data?.proData?.symmcoAccess
});
export default connect(mapStateToProps)(MenuList);
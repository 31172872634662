import React from "react";
import TableMUI from "app/partials/components/TableMUI";
import {TableCell, TableRow} from "@material-ui/core";
import {handleTableCellAlign, toNumberFormat} from "app/utils/helpers";
import styles from "./index.module.scss";
import {FormattedMessage} from "react-intl";
import BlurText from "../../../../../partials/components/BlurText";
import IsPro from "../../../../../utils/IsPro";

export default class PropertyRegionAreaTable extends React.Component {
  // Helpers
  getDistanceLabel = (distance) => {
    if(distance === "park") {
      return <FormattedMessage id="VOCABULARY.Active park"/>;
    }
    else if(distance === "country") {
      return <FormattedMessage id="GENERAL.PHRASE.COUNTRY"/>;
    }
    else if(distance === "region") {
      return <FormattedMessage id="GENERAL.PHRASE.REGION"/>;
    }
    else if(distance === "5") {
      return <><FormattedMessage id="to"/> {distance} km</>;
    }
    else {
      return <>{distance} km</>
    }
  }
  render() {
    const {regionStats, regionStatsLoading, breakPointAreaTable} = this.props;
    const tHead = [
        {id: 'distance', numeric: false, disablePadding: false, label: 'VOCABULARY.Distance', sort: false},
        {id: 'existing', numeric: true, disablePadding: false, label: 'VOCABULARY.Existing', sort: false},
      ...breakPointAreaTable ? [] : [{id: 'under_construction', numeric: true, disablePadding: false, label: 'VOCABULARY.Under constr.', sort: false}],
      ...breakPointAreaTable ? [] : [{id: 'planned', numeric: true, disablePadding: false, label: 'VOCABULARY.Planned', sort: false}],
      ...breakPointAreaTable ? [{id: 'in_future', numeric: true, disablePadding: false, label: 'VOCABULARY.In future', sort: false}] : []
    ];

    return(
      <TableMUI
        tHead={tHead}
        loading={regionStatsLoading}
        containerStyle={{minHeight: "20rem", overflow: "hidden"}}
        totalItems={regionStats?.length}
        tableContainerClasses={styles.regionAreaTable}
      >
        {regionStats?.length > 0 && regionStats?.map((item, index) => {
          const areaInFuture = Number(item?.underConstruction) + Number(item?.planned);
          return(
            <TableRow key={index}>
              <TableCell align={handleTableCellAlign(tHead, 0)}>
                {this.getDistanceLabel(item?.distance)}
              </TableCell>
              <TableCell align={handleTableCellAlign(tHead, 1)}>
                <BlurText
                  type="area"
                  isBlurred={
                    IsPro({reverseCheck: true, overwriteRule: this.props.basic?.isEditable}) &&
                    item?.distance === "park"
                  }
                >
                  {toNumberFormat(item?.existing, 'area')}
                </BlurText>
              </TableCell>
              {!breakPointAreaTable &&
              <TableCell align={handleTableCellAlign(tHead, 2)}>
                <BlurText
                  type="area"
                  isBlurred={
                    IsPro({reverseCheck: true, overwriteRule: this.props.basic?.isEditable}) &&
                    item?.distance === "park"
                  }
                >
                  {toNumberFormat(item?.underConstruction, 'area')}
                </BlurText>
              </TableCell>
              }
              {!breakPointAreaTable &&
              <TableCell align={handleTableCellAlign(tHead, 3)}>
                <BlurText
                  type="area"
                  isBlurred={
                    IsPro({reverseCheck: true, overwriteRule: this.props.basic?.isEditable}) &&
                    item?.distance === "park"
                  }
                >
                  {toNumberFormat(item?.planned, 'area')}
                </BlurText>
              </TableCell>
              }
              {breakPointAreaTable &&
              <TableCell align={handleTableCellAlign(tHead, 2)}>
                <BlurText
                  type="area"
                  isBlurred={
                    IsPro({reverseCheck: true, overwriteRule: this.props.basic?.isEditable}) &&
                    item?.distance === "park"
                  }
                >
                  {toNumberFormat(areaInFuture, 'area')}
                </BlurText>
              </TableCell>
              }
            </TableRow>
          );
        })}
      </TableMUI>
    );
  }
}
import React from 'react';
import {Text, View, StyleSheet, Image} from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  footer: {
    position: 'absolute',
    bottom: 18,
    left: 36,
    right: 36,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 5, // Changed from 10 to 5
  },
  footerText: {
    fontSize: 6.4, // Reduced by 20% from 8 to 6.4
    color: '#666', // Ensure this color contrasts with the background
    marginRight: 5, // Add some space between text and logo
  },
  footerLogo: {
    width: 40, // Adjust as needed
    height: 'auto',
  },
});

// Create Document Component
function OfferPdfPageFooter(props) {
  const {
    intl
  } = props;
  // Function to translate strings
  const handleStringTranslation = (id, values) => {
    return intl.formatMessage({id: id}, values);
  };

  return (
    <View style={styles.footer} fixed>
      <Text style={styles.footerText}>{handleStringTranslation("VOCABULARY.Offer generated using")}</Text>
      <Image
        style={styles.footerLogo}
        src="/media/branding/redd-logo-neon-200px.png"
      />
    </View>
  );
};

export default OfferPdfPageFooter;


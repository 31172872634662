import React from 'react';
import {FormControlLabel, Switch} from "@material-ui/core";
import styles from './index.module.scss';
import cx from "classnames";

export default class InputSwitcher extends React.Component {
  render() {
    const {checked, value, color, label, onChange, style, extraClass} = this.props;
    return(
      <FormControlLabel
        control={
          <Switch checked={checked} value={value} color={color || "primary"} onChange={onChange} />
        }
        label={label}
        className={cx(
          styles.label,
          extraClass,
          label ? styles.withMargin : undefined
        )}
        style={{whiteSpace: "nowrap", ...style}}
      />
    );
  }
}
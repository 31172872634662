import store from "../store/store";
import APPLICATION_VIEWS from "../constants/APPLICATION_VIEWS";

const IsApplicationView = ({expectedAppView = null, ...props}) => {
  const storeData = store.getState();
  const user = storeData?.user?.data;
  const currentAppView = user?.currentApplicationView || APPLICATION_VIEWS.OFFICES;
  const isMatched = expectedAppView === currentAppView;

  if(props?.children) {
    if(isMatched) {
      return props?.children;
    }
    return null;
  }
  return isMatched || false;
}

export default IsApplicationView;
import React, {useEffect} from "react";
import EditWarehouseUnitBasics from "./EditWarehouseUnitBasics";
import EditWarehouseUnitFlexible from "./EditWarehouseUnitFlexible";
import EditWarehouseUnitNote from "./EditWarehouseUnitNote";
import EditWarehouseUnitPlan from "./EditWarehouseUnitPlan";
import EditWarehouseUnitDeleteUnit from "./EditWarehouseUnitDeleteUnit";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import LoadingScreen from "../../../LoadingScreen";
import NoData from "../../../NoData/NoData";
import {fetchIndustrialWarehouseUnitEditFormsData} from "../../../../../crud/estates/industrial/industrial.crud";

function EditWarehouseUnit({data, loading, parentData, activeTab, isEditable, requestSave, onRequestSaving, onUnsavedData, ...props}) {
  useEffect(() => {
    if(!loading && parentData?.id) {
      props.fetchIndustrialWarehouseUnitEditFormsData(parentData?.id);
    }
  // eslint-disable-next-line
  }, []);

  if(loading) {
    return <LoadingScreen/>;
  }
  else if(data) {
    return(
      <>
        {activeTab === "basic" && isEditable &&
        <EditWarehouseUnitBasics
          unitId={data?.id}
          data={data}
          requestSave={requestSave}
          onRequestSaving={onRequestSaving}
          onUnsavedData={onUnsavedData}
          {...props}
        />
        }
        {activeTab === "flexibleUnits" &&
        <EditWarehouseUnitFlexible
          unitId={data?.id}
          data={data}
          isPropertyEditable={isEditable}
          parentData={parentData}
          requestSave={requestSave}
          onRequestSaving={onRequestSaving}
          onUnsavedData={onUnsavedData}
          {...props}
        />
        }
        {activeTab === "note" &&
        <EditWarehouseUnitNote
          unitId={data?.id}
          note={data?.note}
          isPropertyEditable={isEditable}
          requestSave={requestSave}
          onRequestSaving={onRequestSaving}
          onUnsavedData={onUnsavedData}
          {...props}
        />
        }
        {activeTab === "plan" &&
        <EditWarehouseUnitPlan
          unitId={data?.id}
          planImage={data?.planImage}
          isPropertyEditable={isEditable}
          requestSave={requestSave}
          onRequestSaving={onRequestSaving}
          onUnsavedData={onUnsavedData}
          {...props}
        />
        }
        {activeTab === "deleteUnit" && isEditable &&
        <EditWarehouseUnitDeleteUnit
          unitId={data?.id}
          requestSave={requestSave}
          onRequestSaving={onRequestSaving}
          onUnsavedData={onUnsavedData}
          {...props}
        />
        }
      </>
    );
  }
  return <NoData/>;
}

const mapStateToProps = (store) => ({
  data: store.industrial.warehouseUnitEditFormsData,
  loading: store.industrial.warehouseUnitEditFormsDataLoading
});
const mapDispatchToProps = {
  fetchIndustrialWarehouseUnitEditFormsData: (unitID) => fetchIndustrialWarehouseUnitEditFormsData(unitID)
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditWarehouseUnit)
);

import React from "react";
import {Grid, Typography} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import InputAdornments from "app/partials/components/InputAdornments";
import {onlyNumbers} from "app/utils/helpers";
import _ from "lodash";
import {updateIndustrialWarehouse} from "../../../../../crud/estates/industrial/industrial.crud";

class EditWarehouseLeaseTerms extends React.Component {
  constructor(props) {
    super(props);
    const {
      rentWarehouseFrom,
      rentWarehouseTo,
      rentOfficeFrom,
      rentOfficeTo,
      serviceWarehouseFrom,
      serviceWarehouseTo,
      serviceOfficeFrom,
      serviceOfficeTo,
      minRentalYears
    } = props.data;
    this.state = {
      rentWarehouseFrom,
      rentWarehouseTo,
      rentOfficeFrom,
      rentOfficeTo,
      serviceWarehouseFrom,
      serviceWarehouseTo,
      serviceOfficeFrom,
      serviceOfficeTo,
      minRentalYears
    };
    this.initialState = this.state;
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    // Handle Change Detect
    this.props.onUnsavedData(!_.isEqual(this.initialState, this.state));
    // Handle Save
    if(!prevProps.requestSave && this.props.requestSave) {
      this.handleSave();
    }
    // Detect Office Updating
    if(prevProps.warehouseUpdateLoading !== this.props.warehouseUpdateLoading) {
      this.props.onRequestSaving(this.props.warehouseUpdateLoading);
    }
  }

  // Handlers
  handleChange = (event) => {
    const onlyNums = onlyNumbers(event.target.value);
    const allowOnlyNumbers = event.target.name === "area" || event.target.name === "floor";
    this.setState({
      [event.target.name]: allowOnlyNumbers ? onlyNums : event.target.value
    });
  };
  handleSave = () => {
    const {data: {id}, updateIndustrialWarehouse} = this.props;
    const {
      rentWarehouseFrom,
      rentWarehouseTo,
      rentOfficeFrom,
      rentOfficeTo,
      serviceWarehouseFrom,
      serviceWarehouseTo,
      serviceOfficeFrom,
      serviceOfficeTo,
      minRentalYears
    } = this.state;
    this.initialState = this.state;
    const data = {
      rentWarehouseFrom: rentWarehouseFrom || null,
      rentWarehouseTo: rentWarehouseTo || null,
      rentOfficeFrom: rentOfficeFrom || null,
      rentOfficeTo: rentOfficeTo || null,
      serviceWarehouseFrom: serviceWarehouseFrom || null,
      serviceWarehouseTo: serviceWarehouseTo || null,
      serviceOfficeFrom: serviceOfficeFrom || null,
      serviceOfficeTo: serviceOfficeTo || null,
      minRentalYears: minRentalYears || null
    };
    updateIndustrialWarehouse(id, data, "leaseTerms");
  };

  render() {
    const {warehouseUpdateLoading} = this.props;
    const {
      rentWarehouseFrom,
      rentWarehouseTo,
      rentOfficeFrom,
      rentOfficeTo,
      serviceWarehouseFrom,
      serviceWarehouseTo,
      serviceOfficeFrom,
      serviceOfficeTo,
      minRentalYears
    } = this.state;

    return(
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" style={{color: "#ff4626"}}><FormattedMessage id="VOCABULARY.Lease terms"/></Typography>
        </Grid>
        <Grid item xs={6}>
          <InputAdornments
              label={<FormattedMessage id="INDUSTRIAL.PHRASE.WAREHOUSE_RENT_FROM"/>}
              name="rentWarehouseFrom"
              value={rentWarehouseFrom}
              onChange={this.handleChange}
              disabled={warehouseUpdateLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <InputAdornments
              label={<FormattedMessage id="INDUSTRIAL.PHRASE.WAREHOUSE_RENT_TO"/>}
              name="rentWarehouseTo"
              value={rentWarehouseTo}
              onChange={this.handleChange}
              disabled={warehouseUpdateLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <InputAdornments
              label={<FormattedMessage id="INDUSTRIAL.PHRASE.OFFICE_RENT_FROM"/>}
              name="rentOfficeFrom"
              value={rentOfficeFrom}
              onChange={this.handleChange}
              disabled={warehouseUpdateLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <InputAdornments
              label={<FormattedMessage id="INDUSTRIAL.PHRASE.OFFICE_RENT_TO"/>}
              name="rentOfficeTo"
              value={rentOfficeTo}
              onChange={this.handleChange}
              disabled={warehouseUpdateLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <InputAdornments
              label={<FormattedMessage id="INDUSTRIAL.PHRASE.SERVICE_WAREHOUSE_FROM"/>}
              name="serviceWarehouseFrom"
              value={serviceWarehouseFrom}
              onChange={this.handleChange}
              disabled={warehouseUpdateLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <InputAdornments
              label={<FormattedMessage id="INDUSTRIAL.PHRASE.SERVICE_WAREHOUSE_TO"/>}
              name="serviceWarehouseTo"
              value={serviceWarehouseTo}
              onChange={this.handleChange}
              disabled={warehouseUpdateLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <InputAdornments
              label={<FormattedMessage id="INDUSTRIAL.PHRASE.SERVICE_OFFICE_FROM"/>}
              name="serviceOfficeFrom"
              value={serviceOfficeFrom}
              onChange={this.handleChange}
              disabled={warehouseUpdateLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <InputAdornments
              label={<FormattedMessage id="INDUSTRIAL.PHRASE.SERVICE_OFFICE_TO"/>}
              name="serviceOfficeTo"
              value={serviceOfficeTo}
              onChange={this.handleChange}
              disabled={warehouseUpdateLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <InputAdornments
              label={<FormattedMessage id="INDUSTRIAL.PHRASE.MIN_RENTAL_YEARS"/>}
              name="minRentalYears"
              value={minRentalYears}
              onChange={this.handleChange}
              disabled={warehouseUpdateLoading}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = store => ({
  warehouseUpdateLoading: store.industrial.warehouseUpdateLoading
});
const mapDispatchToProps = {
  updateIndustrialWarehouse: (warehouseID, data, backgroundRefresh) => updateIndustrialWarehouse(warehouseID, data, backgroundRefresh)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditWarehouseLeaseTerms)
);
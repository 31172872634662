import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import PropertyParticulars from "./PropertyParticularsPage";
import {destroyNotification} from "../../../../constants/actionCreators/notifications";
import {
  fetchPropertyParticulars,
  fetchPropertyParticularsBranding,
  fetchPropertyParticularsListPhotos,
  fetchPropertyParticularsProperty,
  fetchPropertyParticularsPropertyContact,
  sharePropertyParticularsRealTimeLog,
  shareUpdatedPropertyParticularsRealTimeLog
} from "app/crud/propertyParticulars/industrial.crud";

const mapStateToProps = (state) => ({
  hasAuthToken: Boolean(state.authApp.authToken),
  notifications: state.notifications,
  ppProperties: state.ppOnlineIndustrial.ppProperties,
  loadingProperties: state.ppOnlineIndustrial.loadingProperties,
  ppBranding: state.ppOnlineIndustrial.ppBranding,
  loadingBranding: state.ppOnlineIndustrial.loadingBranding,
  isNotPro: state.ppOnlineIndustrial.isNotPro,
  ppPhotos: state.ppOnlineIndustrial.ppPhotos,
  loadingPhotos: state.ppOnlineIndustrial.loadingPhotos,
  ppProperty: state.ppOnlineIndustrial.ppProperty,
  loadingProperty: state.ppOnlineIndustrial.loadingProperty,
  ppContact: state.ppOnlineIndustrial.ppContact,
  loadingContact: state.ppOnlineIndustrial.loadingContact
});
const mapDispatchToProps = {
  destroyNotification: destroyNotification,
  fetchPropertyParticulars: (teamID, email, market) => fetchPropertyParticulars(teamID, email, market),
  fetchPropertyParticularsListPhotos: (teamID, email) => fetchPropertyParticularsListPhotos(teamID, email),
  fetchPropertyParticularsBranding: (teamID) => fetchPropertyParticularsBranding(teamID),
  sharePropertyParticularsRealTimeLog: (data) => sharePropertyParticularsRealTimeLog(data),
  shareUpdatedPropertyParticularsRealTimeLog: (data) => shareUpdatedPropertyParticularsRealTimeLog(data),
  fetchPropertyParticularsProperty: (teamID, propertyID, email) => fetchPropertyParticularsProperty(teamID, propertyID, email),
  fetchPropertyParticularsPropertyContact: (teamID, propertyID, email) => fetchPropertyParticularsPropertyContact(teamID, propertyID, email)
};
export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PropertyParticulars)
);
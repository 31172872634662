import React from "react";
import connect from "react-redux/es/connect/connect";
import {Typography} from "@material-ui/core";
import PortletAccordion from "app/partials/components/PortletAccordion";
import {FormattedMessage} from "react-intl";
import TableCustom from "app/partials/components/TableCustom";
import {fetchOffersOffices} from "app/crud/offers.crud";
import {fetchShortlistsIndustrial} from "app/crud/estates/industrial/offers-industrial.crud"
import {ROUTES} from "app/constants";
import _ from "lodash";
import LoadingScreen from "app/partials/components/LoadingScreen";
import APPLICATION_VIEWS from "app/constants/APPLICATION_VIEWS";
import ShareOfferIndustrialOnline from "app/partials/components/ShareOfferIndustrialOnline/ShareOfferIndustrialOnline";
import ReactVisibilitySensor from "react-visibility-sensor";
import UpdatedAtPreview from "app/partials/components/_DataParts/UpdatedAtPreview";
import NoData from "../../../../partials/components/NoData/NoData";
import {toDateFormat} from "../../../../utils/helpers";
import OfferShare from "../../../offices/OffersPage/components/OfferShare";
import {Link} from "react-router-dom";
import Button from "../../../../partials/components/Button";
import Icon from "../../../../partials/components/Icon";

class DashboardLatestOffers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tBody: null,
      initLoaded: false
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {currentApplicationView, offers, offersIndustrial} = this.props;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    const data = isOfficeView ? offers : offersIndustrial;
    const prevData = isOfficeView ? prevProps?.offers : prevProps?.offersIndustrial;

    // Re-render TableBody
    if(
      // if offers was empty
      (_.isEmpty(prevData) && data?.length > 0) ||
      // if offers was updated
      (!_.isEqual(prevData, data))
    ) {
      this.setState({
        tBody: this.handlePrepTableBody()
      });
    }
  }

  // Handlers
  handlePrepTableBody = () => {
    const {currentApplicationView, offers, offersIndustrial} = this.props;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    const data = isOfficeView ? offers : offersIndustrial;

    if(data?.length > 0) {
      return data?.map(offer => {
        const inactive = offer?.unitsCount <= 0;

        return {
          data: [
            <span style={{color: "#ff4626"}}>
              {isOfficeView && offer?.id ?
              <Link to={`${ROUTES.OFFER_PAGE}/${offer?.id}`} className="linkBrand">
                {offer?.name}
              </Link> : offer?.name
              }
            </span>,
            isOfficeView ? toDateFormat(offer?.createdAt) : <UpdatedAtPreview
              date={offer?.lastUpdate}
              alternative
            />,
            isOfficeView ? offer?.unitsCount : offer?.totalBuildings,
            (isOfficeView ? (
              <OfferShare
                offerID={offer?.id}
                offerHash={offer?.urlHash}
                offerName={offer?.name}
                inactive={inactive}
                directBtn
              />
            ) :
              <ShareOfferIndustrialOnline
                offerID={offer?.id}
                offerHash={offer?.urlHash}
                offerName={offer?.name}
                inactive={inactive}
                directBtn
              />
            )
          ]
        }
      });
    }
  }
  handleOnViewportFetch = (isVisible) => {
    const {
      fetchOffersOffices,
      fetchShortlistsIndustrial,
      loading,
      loadingIndustrial,
      currentApplicationView
    } = this.props;
    const {initLoaded} = this.state;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    const isIndustrialView = currentApplicationView === APPLICATION_VIEWS.INDUSTRIAL;
    const params = {
      page: 1,
      size: 3
    };

    if(isOfficeView && isVisible && !loading && !initLoaded) {
      this.setState({
        initLoaded: true
      }, () => fetchOffersOffices({params}));
    }
    else if(isIndustrialView && isVisible && !loadingIndustrial && !initLoaded) {
      this.setState({
        initLoaded: true
      }, () => fetchShortlistsIndustrial({params}));
    }
  };

  render() {
    const {loading, loadingIndustrial, currentApplicationView} = this.props;
    const {tBody} = this.state;
    const tHead = [
      {
        "label": <FormattedMessage id="GENERAL.PHRASE.OFFER_NAME"/>,
        "number": false
      },
      {
        "label": <FormattedMessage id="VOCABULARY.Date"/>,
        "number": false
      },
      {
        "label": <FormattedMessage id="GENERAL.PHRASE.NUMBER_OF_UNITS"/>,
        "number": true
      },
      {
        "label": "",
        "number": true
      }
    ];
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;

    return (
      <PortletAccordion
        title={
        <ReactVisibilitySensor
            onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
            scrollCheck
            partialVisibility
        >
          <Typography variant="h3"><FormattedMessage id="DASHBOARD.PHRASE.LATEST_OFFERS"/></Typography>
        </ReactVisibilitySensor>
        }
        fluidHeight
        disableAccordion
        footer={
        !_.isEmpty(tBody) &&
          <Button
            type={"plainText"}
            variant={"outlined"}
            redirect={isOfficeView ? ROUTES.SHORTLISTS_PAGE : ROUTES.INDUSTRIAL_SHORTLISTS_PAGE}
            trackingData={{
              clickType: isOfficeView ?
                "dashboard-latest-offers-see-all-click" :
                "dashboard-industrial-latest-offers-see-all-click",
              contentType: "user"
            }}
            style={{fontSize: "1.6rem", fontWeight: "400"}}
          >
            <FormattedMessage id="VOCABULARY.Check all"/>
          </Button>
        }
      >
        {(isOfficeView ? loading : loadingIndustrial) ? <LoadingScreen/> :
          _.isEmpty(tBody) ?
            <NoData
              withIcon
              message={<FormattedMessage id="You currently have no active offers."/>}
              action={
              <Button
                textIcon={<Icon icon={"add-clean"}/>}
                redirect={isOfficeView ? `${ROUTES.SHORTLISTS_PAGE}?add_offer=true` : `${ROUTES.INDUSTRIAL_SHORTLISTS_PAGE}?add_offer=true`}
                style={{marginLeft: "2rem"}}
              >
                <FormattedMessage id="SHORTLIST.PHRASE.ADD_OFFER"/>
              </Button>
              }
            />
            :
          <TableCustom
            tHead={tHead}
            tBody={tBody}
            responsive
            tdStyle={{height: "9rem"}}
          />
        }
      </PortletAccordion>
    );
  }
}

const mapStateToProps = (state) => ({
  offers: state.shortlists.items,
  loading: state.shortlists.loading,
  offersIndustrial: state.offersIndustrial?.items,
  loadingIndustrial: state.offersIndustrial?.loading,
  currentApplicationView: state.user?.data?.currentApplicationView
});
const mapDispatchToProps = {
  fetchOffersOffices: ({params}) => fetchOffersOffices(params),
  fetchShortlistsIndustrial: ({params}) => fetchShortlistsIndustrial(params)
};
export default connect(mapStateToProps, mapDispatchToProps)(DashboardLatestOffers)
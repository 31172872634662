import React from "react";
import store from "../store/store";
import AdminOnlyMarkup from "../partials/components/AdminOnlyMarkup";

const IsAdmin = (props) => {
  const loggedInAsCount = props?.loggedInAs;
  const storeData = store.getState();
  const user = storeData?.user?.data;
  const isAdminGroup = user?.proData?.proGroup === "Admin";
  const isLoggedInAs = loggedInAsCount && user?.loggedInAs;
  const isAdmin = isAdminGroup || isLoggedInAs;
  const isAdminMarkupAllowed = user?.adminMarkup;

  if(props?.children) {
    if(isAdmin) {
      if(isAdminMarkupAllowed && props?.markup) {
        return(
          <AdminOnlyMarkup
            type={props?.markup}
            tooltip={props?.markupTooltip}
          >{props?.children}</AdminOnlyMarkup>
        );
      }
      return props?.children;
    }
    return null;
  }
  return isAdmin || false;
}

export default IsAdmin;
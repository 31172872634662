import React from "react";
import ModalCustom from "app/partials/components/ModalCustom";
import {addOffice} from "app/crud/estates/offices/offices.crud";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {Grid, Typography} from "@material-ui/core";
import InputAdornments from "../../InputAdornments";
import Icon from "../../Icon";
import Button from "../../Button";

class OfficeDeleteOwnership extends React.Component {
    constructor(props) {
        super(props);
        this.child = React.createRef();
        this.state = {
          endDate: new Date()
        }
    }
    _isMounted = false;
    componentDidMount = async () => {
        this._isMounted = true;
    };
    componentWillUnmount() {
        this._isMounted = false;
    }
    handleChange = (event) => {
        this.setState({
          endDate: event.target.value
        });
    };
    handleCloseModal = () => {
        this.child.current.handleClose();
    };

    handleSave = () => {
        const {endDate} = this.state;
        const {deleteOwnership, ownerID, officeID} = this.props;
        const data = [
            {
                id: ownerID,
                endDate
            }
        ];
        deleteOwnership(officeID, data);
        this.handleCloseModal();
    };

  render() {
    const {endDate} = this.state;
    return(
      <ModalCustom
        ref={this.child}
        btn={
        <Button
          type={"icon"}
          tooltip={<FormattedMessage id="BUILDING.PHRASE.END_OWNERSHIP"/>}
        >
          <Icon icon={"close"}/>
        </Button>
        }
        btnConfirm={<FormattedMessage id="GENERAL.PHRASE.DELETE"/>}
        title={<FormattedMessage id="BUILDING.PHRASE.DELETE_OWNERSHIP"/>}
        handleSave={this.handleSave}
      >
        <Grid container spacing={2} direction="column">
          <Grid item>
              <Typography variant="body1" style={{marginBottom: 15}}><FormattedMessage id="BUILDING.PHRASE.END_DATE_MESSAGE"/></Typography>
            <InputAdornments label={<FormattedMessage id="BUILDING.PHRASE.END_DATE"/>} name="endDate" value={endDate} type="date" onChange={this.handleChange}/>
          </Grid>
        </Grid>
      </ModalCustom>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  user: user.data,
});
const mapDispatchToProps = {
  addOffice: office => addOffice(office)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OfficeDeleteOwnership)
);
import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid/Grid";
import {TextField} from "@material-ui/core";

export default class InputIcon extends Component {
  render() {
    const {icon, label, name, value, onChange, size, disabled, error, width, minWidth, type = "text", labelShrink, ...props} = this.props;
    return (
      <Grid container spacing={icon ? 1 : 0} alignItems="flex-end" wrap="nowrap" style={{width: width}}>
        {icon ?
          <>
            <Grid item>
              {icon}
            </Grid>
            <Grid item style={{flexGrow: '1', minWidth: minWidth ? minWidth : "auto"}}>
              <TextField
                {...props}
                id={name}
                label={label}
                name={name}
                value={value}
                onChange={onChange}
                disabled={disabled}
                inputProps={{size: size || 15}}
                InputLabelProps={{
                  shrink: labelShrink,
                }}
                error={error}
                type={type}
                fullWidth
              />
            </Grid>
          </> :
          <Grid item xs={12}>
            <TextField
              {...props}
              id={name}
              label={label}
              name={name}
              value={value}
              onChange={onChange}
              disabled={disabled}
              inputProps={{
                size: size || 15
              }}
              InputLabelProps={{
                shrink: labelShrink,
              }}
              error={error}
              type={type}
              fullWidth
            />
          </Grid>
        }
      </Grid>
    );
  }
}
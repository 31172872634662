import React from "react";
import {Grid, TableCell} from "@material-ui/core";
import PropertyCardPreview from "../../../../partials/components/_DataParts/PropertyCardPreview";
import {ROUTES} from "../../../../constants";
import {toNumberFormat} from "../../../../utils/helpers";
import {FormattedMessage, injectIntl} from "react-intl";
import PropertyAvailabilityPreview from "../../../../partials/components/_DataParts/PropertyAvailabilityPreview";
import TABLE_CELLS from "../../../../partials/components/TableProperties/TableCells/TABLE_CELLS";
import TableDrawer from "../../../../partials/components/TableDrawer";
import {fetchIndustrialWarehouseUnits} from "../../../../crud/estates/industrial/industrial.crud";
import connect from "react-redux/es/connect/connect";
import PropertyExpandedCardPreview from "../../../../partials/components/_DataParts/PropertyExpandedCardPreview";
import LackOfAvailableArea from "../../../../partials/components/LackOfAvailableArea/LackOfAvailableArea";
import IconColorMUI from "../../../../partials/components/IconColorMUI";
import HighlightLabel from "../../../../partials/components/HighlightLabel";
import PropertyStatusPreview from "../../../../partials/components/_DataParts/PropertyStatusPreview";
import ModalIPI from "../../../../partials/components/IPI/ModalIPI";
import IPI_PLAN_SOURCE from "../../../../constants/IPI_PLAN_SOURCE";
import LeadsManagementAddProperty from "../../../common/LeadsManagementPage/components/LeadsManagementAddProperty";
import IsAdmin from "../../../../utils/IsAdmin";
import IsPro from "../../../../utils/IsPro";
import BlurText from "../../../../partials/components/BlurText";
import UpdatedAtPreview from "../../../../partials/components/_DataParts/UpdatedAtPreview";
import styles from "../../../../partials/components/TableProperties/index.module.scss";

class WarehousesPageTableRow extends React.Component {
  render() {
    const {row, warehouseUnits, warehouseUnitsLoading, fetchIndustrialWarehouseUnits} = this.props;
    const propertyThumbnail = row.thumbnail ? row.thumbnail : row.parkThumbnail;
    const isUnitsActionsPermitted = row?.isEditable;
    const ipiPlanWarehouse = row?.warehouseIpiPlanImage;
    const ipiPlanPark = row?.parkIpiPlanImage;

    return (
      <>
        <TableCell>
          <PropertyCardPreview
            propertyID={row.id}
            title={row.parkName}
            semiTitle={row.parkName !== row.name && row.name}
            semiTitle2={row.marketName}
            thumb={propertyThumbnail}
            route={ROUTES.INDUSTRIAL_BUILDING}
            target="industrialWarehouse"
            semiTitleSolid
          />
        </TableCell>
        <TableCell><PropertyStatusPreview status={row?.status}/></TableCell>
        <TableCell alight="right">
          <div className={styles.spaceDataWrapper}>
            <HighlightLabel color="neutral2">
              <BlurText
                type="area"
                isBlurred={IsPro({
                  reverseCheck: true,
                  overwriteRule: row?.isEditable
                })}
              >
                {toNumberFormat(row?.totalAreaWarehouseAvailable, "area")}
              </BlurText>
            </HighlightLabel>
            <TableDrawer
              titleAdvance
              title={
                <PropertyExpandedCardPreview
                  propertyID={row.id}
                  title={row.name}
                  semiTitle={row.marketName}
                  thumb={propertyThumbnail}
                  route={ROUTES.INDUSTRIAL_BUILDING}
                />
              }
              headRows={[
                ...isUnitsActionsPermitted ? [{
                  id: 'checkable',
                  numeric: false,
                  disablePadding: false,
                  label: '',
                  sort: false
                }] : [],
                {
                  id: 'area',
                  numeric: true,
                  disablePadding: false,
                  label: 'GENERAL.PHRASE.AREA',
                  sort: false
                },
                {
                  id: 'is_office',
                  numeric: false,
                  disablePadding: false,
                  label: 'GENERAL.PHRASE.TYPE',
                  sort: false
                },
                {
                  id: 'status',
                  numeric: false,
                  disablePadding: false,
                  label: 'INDUSTRIAL.TABLE.STATUS',
                  sort: false
                },
                {
                  id: 'available_from',
                  numeric: false,
                  disablePadding: false,
                  label: 'BUILDING.PHRASE.AVAILABLE_FROM',
                  sort: false
                },
                {
                  id: 'updated_at',
                  numeric: false,
                  disablePadding: false,
                  label: 'BUILDING.PHRASE.UPDATED_AT',
                  sort: false
                },
                {
                  id: 'docks',
                  numeric: true,
                  disablePadding: false,
                  label: 'INDUSTRIAL.PHRASE.LOADING_DOCKS',
                  sort: false
                },
                {
                  id: 'actions',
                  numeric: true,
                  disablePadding: false,
                  label: '',
                  sort: false
                }
              ]}
              bodyRows={warehouseUnits}
              bodyRowsReady={warehouseUnitsLoading}
              target={TABLE_CELLS.INDUSTRIAL_BUILDING_UNITS}
              parentData={row}
              parentPreviewData={row}
              parentId={row.id}
              onClick={fetchIndustrialWarehouseUnits}
              isEditable={row?.isEditable}
            >
              <HighlightLabel
                color="success"
                tooltip={<FormattedMessage id="INDUSTRIAL.PHRASE.VIEW_AVAILABLE_UNITS"/>}
                disableTooptipInfo
                style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}
                hover
              >
                {row?.numberOfUnitsAvailable}
                <IconColorMUI
                  icon="rounded_corner"
                  color="success"
                  style={{marginLeft: "5px"}}
                />
              </HighlightLabel>
            </TableDrawer>
          </div>
        </TableCell>
        <TableCell>
          <PropertyAvailabilityPreview
            date={row?.buildingAvailableFrom}
            availableInMonths={row?.availableFromAdditionalTime}
            isUnavailable={!row?.numberOfUnitsAvailable || row?.numberOfUnitsAvailable <= 0}
            parentStatus={row?.status}
          />
        </TableCell>
        <TableCell>
          <UpdatedAtPreview level={1} date={row?.updatedAtByUser} alternative/>
        </TableCell>
        <TableCell alight="right">
          <Grid container justifyContent="flex-end" alignItems="center" wrap="nowrap">
            {row?.isEditable &&
              <Grid item>
                {
                  (row?.numberOfUnitsAvailable <= 0 || row?.lackOfAvailableArea) &&
                  <LackOfAvailableArea
                    propertyID={row?.id}
                    lackOfAvailableArea={row?.lackOfAvailableArea}
                    buildingAvailableFrom={row?.buildingAvailableFrom}
                    target="industrial"
                  />
                }
              </Grid>
            }
            {(ipiPlanPark || ipiPlanWarehouse) &&
              <Grid item>
                <ModalIPI
                  title={row?.name}
                  planSource={IPI_PLAN_SOURCE.WAREHOUSE}
                  planPark={ipiPlanPark}
                  planBuilding={ipiPlanWarehouse}
                  parkID={row?.park}
                  buildingID={row.id}
                  isEditable={row?.ipiEditable}
                />
              </Grid>
            }
            <IsAdmin>
              <Grid item>
                <LeadsManagementAddProperty propertyID={row?.park} tooltipPosition="top"/>
              </Grid>
            </IsAdmin>
          </Grid>
        </TableCell>
      </>
    );
  }
}

const mapStateToProps = store => ({
  warehouseUnits: store.industrial.warehouseUnits,
  warehouseUnitsLoading: store.industrial.warehouseUnitsLoading,
  warehouseUnitsLoaded: store.industrial.warehouseUnitsLoaded
});
const mapDispatchToProps = {
  fetchIndustrialWarehouseUnits: (warehouseID) => fetchIndustrialWarehouseUnits(warehouseID)
};
export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(WarehousesPageTableRow)
);
export const fetchAccountRequests = (params) => ({
  type: "FETCH_ACCOUNT_REQUESTS_REQUEST",
  payload: {
    params
  }
});
export const updateAccountRequest = (requestID, data) => ({
  type: "UPDATE_ACCOUNT_REQUEST_REQUEST",
  payload: {
    requestID,
    data
  },
});
export const rejectAccountRequest = (requestID) => ({
  type: "REJECT_ACCOUNT_REQUEST_REQUEST",
  payload: {
    requestID
  },
});
export const resetAccountRequestsStates = () => ({
  type: 'RESET_ACCOUNT_REQUESTS_STATES_REQUEST'
});
import React from "react";
import TableCell from "@material-ui/core/TableCell/TableCell";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {HEAD_ROWS, TABLE_CELLS} from "app/partials/components/TableProperties/index";
import TableDrawer from "app/partials/components/TableDrawer";
import {fetchShortlistUnitsLandlord, fetchShortlistUnitsLandlordReset} from "app/crud/offers.crud";
import styles from "../index.module.scss";
import BuildingCardPreview from "../../_DataParts/BuildingCardPreview";
import HighlightLabel from "../../HighlightLabel";
import {toDateFormat} from "app/utils/helpers";

class TableOffersLandlordCells extends React.Component {
  render() {
    const {row, fetchShortlistUnitsLandlord, fetchShortlistUnitsLandlordReset, landlordShortlistUnits, landlordShortlistUnitsLoading} = this.props;
    const params = {
        officeId: row.id
    };
    return (
      <>
        <TableCell component="th" scope="row" className={styles.wrapCell}>
          <BuildingCardPreview
            name={row.buildingName}
            street={row.addressStreet}
            thumb={row.mainPhotoThumbnail}
            officeID={row.id}
          />
        </TableCell>
        <TableCell align="right">{toDateFormat(row.shortlistCreatedAt)}</TableCell>
        <TableCell align="right">{toDateFormat(row.shortlistLastUpdate)}</TableCell>
        <TableCell align="right">
            <TableDrawer
              title={
              <BuildingCardPreview
                  name={row.buildingName}
                  street={row.addressStreet}
                  thumb={row.mainPhotoThumbnail}
                  officeID={row.id}
              />
              }
              headRows={HEAD_ROWS.SHORTLIST_LANDLORD_UNITS}
              bodyRows={landlordShortlistUnits}
              bodyRowsReady={landlordShortlistUnitsLoading}
              target={TABLE_CELLS.SHORTLIST_LANDLORD_UNITS}
              parentId={params}
              parentPreviewData={row}
              onClick={fetchShortlistUnitsLandlord}
              onClose={fetchShortlistUnitsLandlordReset}
            >
              <HighlightLabel color="success" hover><FormattedMessage id="GENERAL.PHRASE.CHECK_DETAILS"/></HighlightLabel>
            </TableDrawer>
          </TableCell>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  landlordShortlistUnits: state.shortlists.landlordShortlistUnits,
  landlordShortlistUnitsLoading: state.shortlists.landlordShortlistUnitsLoading
});
const mapDispatchToProps = {
  fetchShortlistUnitsLandlord: (params) => fetchShortlistUnitsLandlord(params),
  fetchShortlistUnitsLandlordReset: fetchShortlistUnitsLandlordReset
};
export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TableOffersLandlordCells)
);

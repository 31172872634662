import React, {useLayoutEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import FINNE_REGIONS from "../constants/FINNE_REGIONS";
import CURRENCIES from "../constants/CURRENCIES";
import _ from "lodash";
import {persistor} from "../store/store";
import dayjs from "dayjs";
import "dayjs/locale/pl";
import relativeTime from "dayjs/plugin/relativeTime";
import {BUILDING_STATUS} from "../constants";
import TooltipCustom from "../partials/components/TooltipCustom";

export function areaFormat() {
  const currentLang = localStorage.getItem("langManual");
  return currentLang === "pl" ? <>m<sup>2</sup></> : <>sqm</>;
}

export function toNumberFormat(number, type, prefix, sufix, emptyNumberPlaceholder) {
  const emptyNumber = number === null || number === undefined || number === '' || number === 'null';
  const currentLang = localStorage.getItem("langManual");
  const numberFormat = currentLang === "pl" ? "pl-PL" : "en-GB";
  let emptyValue = emptyNumberPlaceholder || "-";

  let format = number && Number(number).toLocaleString(numberFormat);
  if (type === 'area') {
    const langSuffix = areaFormat();
    const value = emptyNumber ? 0 : format;
    return (
      <span>
            {prefix} {value} <span style={{fontSize: "80%"}}>{langSuffix}</span>
          </span>
    );
  } else if (emptyNumber) {
    return emptyValue;
  } else if (type === 'currency') {
    const matchCurrency = !_.isEmpty(prefix) && CURRENCIES.filter(currency => `${currency?.value}` === prefix);
    const finalPrefix = matchCurrency?.length > 0 ? matchCurrency?.[0]?.symbol : prefix;
    return (
      <span>
            {format} <span style={{fontSize: "90%"}}>{finalPrefix || '€'}</span>
          </span>
    );
  } else if (type === 'range') {
    const langAreaSuffix = areaFormat();
    const finalSufix = (sufix === 'EUR/' || sufix === 'PLN/' || sufix === 'area') ?
      <>{sufix === 'area' ? ' ' : sufix}{langAreaSuffix}</> :
      sufix === 'EUR/m2' ? <>€/{langAreaSuffix}</> : sufix;
    if (number) {
      return (<span>
                  {prefix && <span style={{fontSize: "90%"}}>{prefix}</span>} {number} {sufix &&
        <span style={{fontSize: "90%"}}>{finalSufix}</span>}
              </span>);
    }
  } else if (type === 'minutes') {
    if (number) {
      const minutes = Math.ceil(number / 60);
      return (<span>
                  {minutes} min
              </span>);
    }
  } else if (type) {
    return (<span>{format}<span style={{fontSize: "80%"}}>{type}</span></span>);
  } else {
    return format;
  }
}

export function toDateFormat(
  date,
  dateTime = false,
  immediately = false,
  apiFormat = false,
  customFormat = null
) {
  const emptyDate = date === null || date === undefined;
  if (emptyDate) {
    return "N/A";
  }
  const currentDate = new Date().getTime();
  const targetDate = new Date(date).getTime();
  const decodeDate = new Date(date);
  let year = decodeDate.getFullYear();
  let month = decodeDate.getMonth() + 1;
  let day = decodeDate.getDate();
  let hours = decodeDate.getHours();
  let minutes = decodeDate.getMinutes();
  let seconds = decodeDate.getSeconds();
  month = month < 10 ? `0${month}` : month;
  day = day < 10 ? `0${day}` : day;
  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  seconds = seconds < 10 ? `0${seconds}` : seconds;
  if (apiFormat) {
    return year + "-" + month + "-" + day;
  } else if (dateTime && hours && minutes && seconds) {
    return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
  } else if (immediately && (currentDate >= targetDate || !targetDate)) {
    return <FormattedMessage id="GENERAL.PHRASE.IMMEDIATELY"/>;
  } else if (customFormat) {
    if (customFormat === "reverse") {
      return year + "." + month + "." + day;
    } else {
      const includedSegments = customFormat.split("/");
      const isDayEnable = includedSegments?.includes("D");
      const isMonthEnable = includedSegments?.includes("M");
      const isYearEnable = includedSegments?.includes("Y");
      const dateToReturn = [
        ...isDayEnable ? [day] : [],
        ...isMonthEnable ? [month] : [],
        ...isYearEnable ? [year] : []
      ].join(".");

      return dateToReturn;
    }
  }
  return day + "." + month + "." + year;
}

export function toTimeFromDate(date) {
  dayjs.extend(relativeTime);
  const currentLang = localStorage.getItem("langManual");
  const lang = currentLang === 'pl' ? 'pl' : 'en';
  return date ? dayjs(date).locale(lang).fromNow() : null;
}

export function theExcerpt(text, length = 150, emptyValue, tooltip) {
  const emptyText = text === null || text === undefined;
  if (emptyText) {
    return emptyValue ? emptyValue : "N/A";
  }
  const cropDots = text?.length > length ? '...' : '';
  const shortText = text?.length > 0 && text?.substring(0, length) + cropDots;

  if (tooltip && text?.length > shortText?.length) {
    return (
      <TooltipCustom
        title={<span style={{fontSize: "1.2rem"}}>{text}</span>}
        placement="top"
      >
        <span>{shortText}</span>
      </TooltipCustom>
    );
  }
  return shortText;
}

export function isNumber(value) {
  if (!isNaN(value) || value === '' || value === null) return true;
}

export function getEveryNthElement(array, nth) {
  return array && array.filter((item, index) => index % nth === 0);
}

export function handleEmptyValue(value, returnNull) {
  if (value === undefined || value === null || value === '' || value === 'None') {
    if (returnNull) {
      return null;
    }
    return '-';
  }
  return value;
}

export function onlyNumbers(value) {
  const regexText = /[^\d.]|\.(?=.*\.)/g;
  const regexNumber = /^-?(\d+\.?\d*|\d*\.?\d+)$/g;
  if (regexNumber.test(value) || value.indexOf("-") !== -1) {
    return value;
  }
  return value.replace(regexText, '');
}

export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export function isMobile(width) {
  return Number(width) < 1024;
}

export function arr_diff(a1, a2) {

  let a = [], diff = [];

  for (let i = 0; i < a1.length; i++) {
    a[a1[i]] = true;
  }

  for (let i = 0; i < a2.length; i++) {
    if (a[a2[i]]) {
      delete a[a2[i]];
    } else {
      a[a2[i]] = true;
    }
  }

  for (let k in a) {
    diff.push(k);
  }

  return diff;
}

export function sortArrayAlph(array, sortBy) {
  if (sortBy) {
    return array.sort((a, b) => {
      if (a[sortBy].localeCompare(b[sortBy]) < b[sortBy].localeCompare(a[sortBy])) {
        return -1;
      }
      if (a[sortBy].localeCompare(b[sortBy]) > b[sortBy].localeCompare(a[sortBy])) {
        return 1;
      }
      return 0;
    });
  }
  return array.sort((a, b) => {
    return a.localeCompare(b);
  });
}

export function sortArrayNum(array, sortBy) {
  if (sortBy) {
    return array.sort((a, b) => {
      return a[sortBy] - b[sortBy];
    });
  }
  return array.sort((a, b) => {
    return a - b;
  });
}

export function isArraysMatch(arr1, arr2) {

  // Check if the arrays are the same length
  if (arr1.length !== arr2.length) return false;
  // Check if all items exist and are in the same order
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }
  // Otherwise, return true
  return true;
}

export function createVisibleArray(visibleValues, field) {
  const visibleArray = [];
  if (visibleValues && visibleValues[0] !== null) {
    if (Array.isArray(field)) {
      visibleValues.map(value => {
        const arrObj = [];
        field.map(f => arrObj.push({[f]: value[f]}));
        const objMerge = Object.assign({}, ...arrObj);
        return visibleArray.push(objMerge);
      })
    } else {
      visibleValues.map(value => visibleArray.push(value[field]));
    }
  }
  return visibleArray;
}

export function arrayOnlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export function deleteAllCookies() {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/auth";
  }
}

export function clearCacheAndStorage(reloadDisabled, redirect) {
  persistor.pause();
  persistor.flush().then(() => {
    return persistor.purge();
  }).then(() => {
    deleteAllCookies();
    localStorage.clear();
    if (redirect) {
      window.location.href = redirect;
    } else if (reloadDisabled) {
      return true;
    } else {
      window.location.href = "/auth/login";
    }
  });
}

export function getMonthName(index, locale) {
  const objDate = new Date();
  objDate.setDate(1);
  objDate.setMonth(index - 1);
  const month = objDate.toLocaleString(locale, {month: "long"});
  return month.charAt(0).toUpperCase() + month.slice(1)
}

function getWeekNumber(d) {
  // Copy date so don't modify original
  d = new Date(+d);
  d.setHours(0, 0, 0, 0);
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setDate(d.getDate() + 4 - (d.getDay() || 7));
  // Get first day of year
  var yearStart = new Date(d.getFullYear(), 0, 1);
  // Calculate full weeks to nearest Thursday
  var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
  // Return array of year and week number
  return [d.getFullYear(), weekNo];
}

export function getWeeksInYear(year) {
  let month = 11, day = 31, week;

  // Find week that 31 Dec is in. If is first week, reduce date until
  // get previous week.
  do {
    const d = new Date(year, month, day--);
    week = getWeekNumber(d)[1];
  } while (week === 1);

  return week;
}

export function handleErrorResponse(err) {
  if (err === undefined || err === null || err === '') {
    return "";
  } else {
    if (typeof err === "object") {
      // return Object.keys(err)[0] + ": " + Object.values(err)[0];
      return Object.values(err)[0];
    }
    return Array.isArray(err) ? err.join(' | ') : err ? err : null;
  }
}

export function getBaseUrl(location) {
  if (typeof window !== 'undefined' && location) {
    return location.protocol + '//' + location.host;
  }
  return false;
}

export function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'), results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function decodeHtml(html) {
  const txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

export function downloadFileFromUrl(link, filename) {
  const a = document.createElement('a');
  a.setAttribute('href', link);
  a.setAttribute('target', '_blank');
  if (filename) {
    a.setAttribute('download', filename);
  }
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export function downloadFileFromBlob(blob, filename) {
  const link = document.createElement('a');
  link.style.display = 'none';
  // create a blobURI pointing to our Blob
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  // some browser needs the anchor to be in the doc
  document.body.appendChild(link);
  link.click();
  link.remove();
  // in case the Blob uses a lot of memory
  setTimeout(() => URL.revokeObjectURL(link.href), 7000);
}
export function downloadFileFromURLBlob(url, filename) {
  return fetch(url, {headers: {"Cache-Control": "no-cache"}})
  .then(response => response.blob())
  .then(blob => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
  })
  .catch(error => console.error(error));
}

export function downloadFileBase64(filename, data) {
  let element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;base64,' + data);
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export function getFieldByIdFromObject(object, compareValue, returnValue) {
  const selectedField = Object.entries(object).filter(item => item[1].id === compareValue);
  return selectedField && selectedField[0] && selectedField[0][1] && selectedField[0][1][returnValue];
}

export function handleGoogleAnalyticsTracking(category, action, label) {
  if (typeof window.gtag === 'function') {
    window.gtag('event', action, {
      event_category: category, event_label: label,
    });
  }
}

export function stringStartWith(str, sub) {
  sub = sub.toLowerCase();

  return str
    .toLowerCase()
    .startsWith(sub.slice(0, Math.max(str.length - 1, 1)));
}

// Convert SnakeCase to CamelCase
export function snakeToCamelCase(str) {
  return str.replace(/[-_][a-z]/g, (group) => group.slice(-1).toUpperCase());
}

// Create finne property URL
export function createFinnePropertyUrl(regionCode, propertyType, marketSlug, propertySlug) {
  const regionDomain = FINNE_REGIONS.filter(region => region?.regionCode === regionCode)[0]?.domain;

  if (regionDomain && marketSlug && propertySlug && propertyType) {
    return `https://${regionDomain}/${propertyType}/${marketSlug}/${propertySlug}`;
  }
  return "";
}

// Handle validate market name to show
export function handleValidMarketName(validMarketName) {
  let validMarketNameDisplay = "";
  if (
    validMarketName === "Gdańsk" ||
    validMarketName === "Gdynia" ||
    validMarketName === "Sopot"
  ) {
    validMarketNameDisplay = "Trójmiasto";
  } else if (
    validMarketName === "Katowice" ||
    validMarketName === "Zabrze" ||
    validMarketName === "Tychy" ||
    validMarketName === "Jaworzno" ||
    validMarketName === "Gliwice" ||
    validMarketName === "Sosnowiec" ||
    validMarketName === "Bytom" ||
    validMarketName === "Chorzów"
  ) {
    validMarketNameDisplay = "Katowice i Metropolia";
  } else {
    validMarketNameDisplay = validMarketName;
  }
  return validMarketNameDisplay;
}

// Detect Class change
export function onClassChange(node, callback) {
  let lastClassString = node.classList.toString();

  const mutationObserver = new MutationObserver((mutationList) => {
    for (const item of mutationList) {
      if (item.attributeName === "class") {
        const classString = node.classList.toString();
        if (classString !== lastClassString) {
          callback(mutationObserver);
          lastClassString = classString;
          break;
        }
      }
    }
  });

  mutationObserver.observe(node, {attributes: true});

  return mutationObserver;
}

// Handle Phone Number Render
export function formatPhoneNumber(phone) {
  const phoneTrim = phone?.length > 0 ? phone?.replaceAll(" ", "") : null;
  const isPhonePL = phoneTrim?.includes("+48");
  const phonePLArray = isPhonePL ? phoneTrim?.split("+48") : phoneTrim;
  const phonePL = isPhonePL && Array.isArray(phonePLArray) && phonePLArray[1]?.length > 0 ? phonePLArray[1]?.match(/.{1,3}/g).join(" ") : phoneTrim;
  return isPhonePL ? `+48 ${phonePL}` : phoneTrim;
}

// Handle Table Cell align based on Table Head
export function handleTableCellAlign(source, columnIndex) {
  return source?.[columnIndex]?.numeric ? 'right' : 'left';
}

// Handle render Office Building Status
export function returnOfficeBuildingStatus(status) {
  const translation = status === BUILDING_STATUS.STATUS_EXISTS ? "GENERAL.PHRASE.EXISTING" :
    status === BUILDING_STATUS.STATUS_UNDER_CONSTRUCTION ? "GENERAL.PHRASE.UNDER_CONSTRUCTION" :
      status === BUILDING_STATUS.STATUS_PLANNED ? "GENERAL.PHRASE.PLANNED" :
        status === BUILDING_STATUS.STATUS_UNDER_REDEVELOPMENT ? "GENERAL.PHRASE.UNDER_REDEVELOPMENT" :
          status === BUILDING_STATUS.STATUS_UNDER_REVITALIZATION ? "GENERAL.PHRASE.UNDER_REVITALIZATION" : null;
  return translation ? <FormattedMessage id={translation}/> : null;
}

// Scroll to Element with offset
export function scrollToElement(element, offset) {
  if (window) {
    const scrollPos = document.getElementById("root").scrollTop;
    const y = element.getBoundingClientRect().top + scrollPos + -offset;
    document.getElementById("root").scrollTo({top: y, behavior: "smooth"});
  }
}

// Random number from range
export function getRandomNumber(min = 0, max = 3000) {
  return Number(Math.random() * (max - min) + min).toFixed(0);
}

// Check if browser/system mode is Dark
export function isDarkMode() {
  return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
}
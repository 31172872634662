import React from "react";
import clsx from "clsx";
import styles from "./index.module.scss";

export default function BadgeLabel({label, type}) {
  return(
    <p
      className={clsx(
        styles.badgeLabelWrapper,
        type === "admin" ? styles.admin : undefined
      )}
    >
      {label}
    </p>
  );
}
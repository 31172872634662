import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {put, takeLatest} from "redux-saga/effects";
import axios from "axios";
import {resetFilters, setFilters} from "app/utils/filters";
import {downloadFileFromUrl, toDateFormat} from "../../utils/helpers";

const initialState = {
  items: null,
  itemsCount: null,
  itemsPerPage: null,
  total: null,
  loading: false,
  transaction: null,
  transactionLoading: false,
  transactionsXlsLoading: false,
  dynamicData: null,
  dynamicDataLoading: false,
  quarters: null,
  quartersLoading: false,
  databaseStatus: null,
  databaseStatusLoading: false
};

export const reducer = persistReducer(
  {storage, key: "symmco"},
  (state = initialState, action) => {
    const result = action.payload;
    switch (action.type) {
      // RESET SYMMCO TRANSACTIONS LOADING STATES
      case "RESET_SYMMCO_TRANSACTIONS_LOADING_STATES_REQUEST": {
        return {
          ...state,
          loading: false,
          transactionLoading: false,
          transactionsXlsLoading: false,
          dynamicDataLoading: false,
          quartersLoading: false,
          databaseStatusLoading: false
        };
      }
      // Fetch TRANSACTIONS
      case "FETCH_SYMMCO_TRANSACTIONS_REQUEST": {
        return {
          ...state,
          loading: true
        };
      }
      case "FETCH_SYMMCO_TRANSACTIONS_SUCCESS": {
        return {
          ...state,
          items: result?.items,
          itemsCount: result?.itemsCount,
          itemsPerPage: result?.itemsPerPage,
          itemsEffectiveRentMin: result?.itemsEffectiveRentMin,
          itemsNetDealMin: result?.itemsNetDealMin,
          itemsLeasePeriodMin: result?.itemsLeasePeriodMin,
          itemsHeadlineRentMin: result?.itemsHeadlineRentMin,
          itemsEffectiveRentMax: result?.itemsEffectiveRentMax,
          itemsNetDealMax: result?.itemsNetDealMax,
          itemsLeasePeriodMax: result?.itemsLeasePeriodMax,
          itemsHeadlineRentMax: result?.itemsHeadlineRentMax,
          itemsEffectiveRentAvg: result?.itemsEffectiveRentAvg,
          itemsNetDealAvg: result?.itemsNetDealAvg,
          itemsLeasePeriodAvg: result?.itemsLeasePeriodAvg,
          itemsHeadlineRentAvg: result?.itemsHeadlineRentAvg,
          loading: false
        };
      }
      case "FETCH_SYMMCO_TRANSACTIONS_FAILED": {
        return {
          ...state,
          items: null,
          itemsCount: null,
          itemsPerPage: null,
          total: null,
          loading: false
        };
      }
      // Fetch TRANSACTIONS Reset
      case 'FETCH_OFFICE_RESET_REQUEST': {
        return { ...state, loading: true };
      }
      // Fetch TRANSACTION
      case "FETCH_SYMMCO_TRANSACTION_REQUEST": {
        return {
          ...state,
          transaction: null,
          transactionLoading: true
        };
      }
      case "FETCH_SYMMCO_TRANSACTION_SUCCESS": {
        return {
          ...state,
          transaction: result,
          transactionLoading: false
        };
      }
      case "FETCH_SYMMCO_TRANSACTION_FAILED": {
        return {
          ...state,
          transaction: null,
          transactionLoading: false
        };
      }
      // Fetch TRANSACTIONS XLS
      case "FETCH_SYMMCO_TRANSACTIONS_XLS_REQUEST": {
        return {
          ...state,
          transactionsXlsLoading: true
        };
      }
      case "FETCH_SYMMCO_TRANSACTIONS_XLS_SUCCESS": {
        return {
          ...state,
          transactionsXlsLoading: false
        };
      }
      case "FETCH_SYMMCO_TRANSACTIONS_XLS_FAILED": {
        return {
          ...state,
          transactionsXlsLoading: false
        };
      }
      // Fetch DYNAMIC DATA
      case "FETCH_SYMMCO_TRANSACTIONS_DYNAMIC_DATA_REQUEST": {
        return {
          ...state,
          dynamicData: null,
          dynamicDataLoading: true
        };
      }
      case "FETCH_SYMMCO_TRANSACTIONS_DYNAMIC_DATA_SUCCESS": {
        return {
          ...state,
          dynamicData: result,
          dynamicDataLoading: false
        };
      }
      case "FETCH_SYMMCO_TRANSACTIONS_DYNAMIC_DATA_FAILED": {
        return {
          ...state,
          dynamicData: null,
          dynamicDataLoading: false
        };
      }
      // Fetch QUARTERS
      case "FETCH_SYMMCO_TRANSACTIONS_QUARTERS_REQUEST": {
        return {
          ...state,
          quarters: null,
          quartersLoading: true
        };
      }
      case "FETCH_SYMMCO_TRANSACTIONS_QUARTERS_SUCCESS": {
        return {
          ...state,
          quarters: result,
          quartersLoading: false
        };
      }
      case "FETCH_SYMMCO_TRANSACTIONS_QUARTERS_FAILED": {
        return {
          ...state,
          quarters: null,
          quartersLoading: false
        };
      }
      // Fetch DATABASE STATUS
      case "FETCH_SYMMCO_TRANSACTIONS_DATABASE_STATUS_REQUEST": {
        return {
          ...state,
          databaseStatus: null,
          databaseStatusLoading: true
        };
      }
      case "FETCH_SYMMCO_TRANSACTIONS_DATABASE_STATUS_SUCCESS": {
        return {
          ...state,
          databaseStatus: result,
          databaseStatusLoading: false
        };
      }
      case "FETCH_SYMMCO_TRANSACTIONS_DATABASE_STATUS_FAILED": {
        return {
          ...state,
          databaseStatus: null,
          databaseStatusLoading: false
        };
      }

      default:
        return state;
    }
  }
);

// FETCH DATA
function* fetchTransactions(action) {
  const {params} = action.payload;
  const filters = setFilters(params, 'transaction_date');

  try {
    const transactions = yield axios.get(`/symmco/${filters}`);
    yield put({
      type: "FETCH_SYMMCO_TRANSACTIONS_SUCCESS",
      payload: transactions.data || [
        {error: transactions.statusText},
      ],
    });
  }
  catch (err) {
    yield put({
      type: "FETCH_SYMMCO_TRANSACTIONS_FAILED",
      payload: err.status,
    });
  }
}
function* fetchTransactionsReset(action) {
  const {initOrderBy, isEditable} = action.payload;
  const filtersReset = resetFilters(initOrderBy, isEditable);
  try {
    const transactions = yield axios.get(`/symmco/${filtersReset}`);
    yield put({ type: "FETCH_SYMMCO_TRANSACTIONS_SUCCESS", payload: transactions.data || [{ error: transactions.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchTransaction(action) {
  const {id} = action.payload;
  
  try {
    const transaction = yield axios.get(`/symmco/${id}/`);
    yield put({
      type: "FETCH_SYMMCO_TRANSACTION_SUCCESS",
      payload: transaction.data || [
        {error: transaction.statusText},
      ],
    });
  }
  catch (err) {
    yield put({
      type: "FETCH_SYMMCO_TRANSACTION_FAILED",
      payload: err.status,
    });
  }
}
function* fetchTransactionsDynamicData(action) {
  const {params} = action.payload;
  const filters = setFilters(params, 'transaction_date');
  try {
    const transactions = yield axios.get(`/symmco/dynamic-data/${filters}`);
    yield put({
      type: "FETCH_SYMMCO_TRANSACTIONS_DYNAMIC_DATA_SUCCESS",
      payload: transactions.data || [
        {error: transactions.statusText},
      ],
    });
  }
  catch (err) {
    yield put({
      type: "FETCH_SYMMCO_TRANSACTIONS_FAILED",
      payload: err.status,
    });
  }
}
function* fetchTransactionsQuarters() {
  try {
    const quarters = yield axios.get(`/symmco/quaters/`);
    yield put({
      type: "FETCH_SYMMCO_TRANSACTIONS_QUARTERS_SUCCESS",
      payload: quarters.data || [
        {error: quarters.statusText},
      ],
    });
  }
  catch (err) {
    yield put({
      type: "FETCH_SYMMCO_TRANSACTIONS_FAILED",
      payload: err.status,
    });
  }
}
function* fetchTransactionsDatabaseStatus() {
  try {
    const stats = yield axios.get(`/symmco/stats/`);
    yield put({
      type: "FETCH_SYMMCO_TRANSACTIONS_DATABASE_STATUS_SUCCESS",
      payload: stats.data || [
        {error: stats.statusText},
      ],
    });
  }
  catch (err) {
    yield put({
      type: "FETCH_SYMMCO_TRANSACTIONS_FAILED",
      payload: err.status,
    });
  }
}

// ACTIONS
function* fetchTransactionsXls(action) {
  const {params, team} = action.payload;
  const filters = setFilters(params, 'transaction_date');

  try {
    const currentDate = new Date();
    const xlsTransactions = yield axios.get(`/symmco/xls/${filters}`, { responseType: 'blob'});
    const csvBlobToUrl = xlsTransactions?.data && URL.createObjectURL(xlsTransactions?.data);
    const teamName = team && team?.length > 0 ? team.toLowerCase().replaceAll(" ", "_") : null;
    const fileName = [
      'symmco',
      ...teamName ? [`${teamName.replaceAll(".", "_")}`] : [],
      ...currentDate ? [`${toDateFormat(currentDate).replaceAll(".", "-")}`] : []
    ].join("-");

    downloadFileFromUrl(csvBlobToUrl, fileName);
    yield put({
      type: "FETCH_SYMMCO_TRANSACTIONS_XLS_SUCCESS",
      payload: xlsTransactions.data || [
        {error: xlsTransactions.statusText},
      ],
    });
  } catch (err) {
    yield put({
      type: "FETCH_SYMMCO_TRANSACTIONS_XLS_FAILED",
      payload: err.status,
    });
  }
}

export function* saga() {
  yield takeLatest("FETCH_SYMMCO_TRANSACTIONS_REQUEST", fetchTransactions);
  yield takeLatest('FETCH_SYMMCO_TRANSACTIONS_RESET_REQUEST', fetchTransactionsReset);
  yield takeLatest("FETCH_SYMMCO_TRANSACTION_REQUEST", fetchTransaction);
  yield takeLatest("FETCH_SYMMCO_TRANSACTIONS_XLS_REQUEST", fetchTransactionsXls);
  yield takeLatest("FETCH_SYMMCO_TRANSACTIONS_DYNAMIC_DATA_REQUEST", fetchTransactionsDynamicData);
  yield takeLatest("FETCH_SYMMCO_TRANSACTIONS_QUARTERS_REQUEST", fetchTransactionsQuarters);
  yield takeLatest("FETCH_SYMMCO_TRANSACTIONS_DATABASE_STATUS_REQUEST", fetchTransactionsDatabaseStatus);
}
import React from "react";
import {FormattedMessage} from "react-intl";
import styles from "app/pages/offices/OwnerPage/index.module.scss";
import {Grid} from "@material-ui/core";
import {toNumberFormat} from "app/utils/helpers";
import TableCustom from "app/partials/components/TableCustom";
import NoData from "app/partials/components/NoData/NoData";

export default class OwnerLoans extends React.Component {
  render() {
    const {ownerLoans} = this.props;
    const tHead = [
      {
        "label": "#",
        "number": false
      },
      {
        "label": "Bank",
        "number": false
      },
      {
        "label": <FormattedMessage id="GENERAL.PHRASE.CREDIT_AMOUNT"/>,
        "number": true
      },
      {
        "label": <FormattedMessage id="DASHBOARD.PHRASE.BUILDINGS" values={{br: ""}}/>,
        "number": false
      }
    ];
    const tBody = ownerLoans.map((loan, index) => {
      const { bank, bankLogo, office, offices, creditAmount, currency } = loan;
      const bankName = bank !== null ? bank : 'N/A';
      const logo = bankLogo !== null ? <img src={bankLogo} className={styles.logo} alt=""/> : '';
      const bankFullName = <Grid container spacing={1} wrap="nowrap" alignItems="center"><Grid item>{logo}</Grid><Grid item><span style={{whiteSpace: 'normal'}}>{bankName}</span></Grid></Grid>;
      const officesNames = offices && offices.length ? offices.map(item => item.name).join(", ") : office;
      return {
        "data": [index+1, bankFullName, toNumberFormat(creditAmount, 'currency', currency) , officesNames]
      }
    });
    if(ownerLoans.length) {
      return(
        <TableCustom tHead={tHead} tBody={tBody} stickyHeader maxHeight={570} theadStyleOrigin/>
      );
    }
    return <NoData/>;
  }
}
/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/initial/HomePage`).
 */
import React, {useEffect, useRef, useState} from "react";
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import {shallowEqual, useSelector} from "react-redux";
import HomePage from "./pages/initial/InitialPage";
import AuthPage from "./pages/auth/AuthPage";
import SubscriberSubscribePage from "./pages/auth/Subscribers/SubscriberSubscribePage";
import SubscriberUnsubscribePage from "./pages/auth/Subscribers/SubscriberUnsubscribePage";
import ErrorsPage from "./pages/handlers/ErrorsPage";
import {LayoutContextProvider} from "_metronic";
import axios from "axios";
import {clearCacheAndStorage, getParameterByName} from "app/utils/helpers";
import {MaintenancePage} from "app/pages/handlers/MaintenancePage";
import * as PropertyParticulars from "app/pages/offices/external/PropertyParticularsPage";
import * as OffersOnline from "app/pages/offices/external/OffersOnlinePage";
import * as RealTimeMail from "app/pages/common/external/RealTimeMailPage";
import * as IndustrialPropertyParticulars from "app/pages/industrial/external/PropertyParticularsPage";
import * as PropertyPageDemo from "app/pages/offices/external/PropertyPageDemo";
import {ROUTES} from "./constants";
import {ForceLogoutPage} from "./pages/handlers/ForceLogoutPage";
import APPLICATION_VIEWS from "./constants/APPLICATION_VIEWS";
import ROUTES_GROUPS from "./constants/ROUTES_GROUPS";
import OffersOnlineIndustrial from "./pages/industrial/external/OffersOnlinePage/OffersOnlinePage.connect";
import RedirectPage from "./pages/handlers/RedirectPage";
import connect from "react-redux/es/connect/connect";
import {updateUserData} from "./crud/user.crud";
import ResetLoadingStates from "./utils/ResetLoadingStates";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

function Routes({Layout, history, applicationView, updateUserData}) {
  const redirectPath = getParameterByName('redirect');
  const redirectApplicationView = getParameterByName('applicationView') || localStorage.getItem("redirectApplicationView");
  const isNotAuth = !localStorage.getItem("storageToken");
  if(redirectPath) {
    localStorage.setItem("redirectSourceUrl", window.location.href);
    if(isNotAuth) {
      localStorage.setItem("redirectPath", redirectPath);
    }
  }
  const { isAuthorized, userData, menuConfig } = useSelector(
    ({ authApp, user, builder: { menuConfig } }) => ({
      menuConfig,
      isAuthorized: localStorage.getItem("storageToken") !== null && (user.data !== null || authApp.user !== undefined),
      userData: user.data !== null ? user.data : authApp.user !== undefined ? authApp.user : null
    }),
    shallowEqual
  );
  const [currentLoc, setCurrentLoc] = useState(window.location.pathname);
  const [maintenance, setMaintenance] = useState(false);
  const [applicationViewMismatch, setApplicationViewMismatch] = useState(false);
  const prevLoc = usePrevious(currentLoc);
  const forceMaintenance = false;

  // Set Current Location / REDD Status Check
  useEffect(() => {
    if(!forceMaintenance) {
      setCurrentLoc(window.location.pathname);
      history.listen(location => {
        if(currentLoc !== prevLoc) {
          if(typeof window.gtag === 'function') {
            window.gtag('config', 'UA-125799533-3', {'page_path': location.pathname});
          }
        }
      });
    }
    // REDD Status Check
    if(!forceMaintenance) {
      axios.get('/redd-status/').then((response) => {
        const status = response.data.maintenance;
        setMaintenance(status);
      })
      .catch(error => {
        if(error?.data?.detail === 'Invalid token.') {
          clearCacheAndStorage();
        }
      });
    }
  }, [currentLoc, prevLoc, history, maintenance, forceMaintenance]);

  // Force ApplicationView change
  useEffect(() => {
    const officeViewMismatch = applicationView === APPLICATION_VIEWS.OFFICES && ROUTES_GROUPS.INDUSTRIAL.filter(path => currentLoc.includes(path)).length > 0;
    const industrialViewMismatch = applicationView === APPLICATION_VIEWS.INDUSTRIAL && ROUTES_GROUPS.ESTATES.filter(path => currentLoc.includes(path)).length > 0;
    const isMismatched = officeViewMismatch || industrialViewMismatch;

    if(isMismatched && redirectApplicationView) {
      const data = {
        currentApplicationView: redirectApplicationView === "offices" ? APPLICATION_VIEWS.OFFICES : redirectApplicationView === "industrial" && APPLICATION_VIEWS.INDUSTRIAL
      };
      updateUserData && updateUserData(data);
    }
    else {
      setApplicationViewMismatch(isMismatched);
    }

  }, [applicationView, currentLoc, updateUserData, redirectApplicationView]);

  if(forceMaintenance) {
    return(
        <LayoutContextProvider history={history} menuConfig={menuConfig}>
          <Switch>
            <Route path="/maintenance" component={MaintenancePage} />
            <Redirect to="/maintenance" />
          </Switch>
        </LayoutContextProvider>
    );
  }
  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <LayoutContextProvider history={history} menuConfig={menuConfig}>
      <ResetLoadingStates/>
      <Switch>
        {/* Handle Maintenance Mode Redirection */}
        {maintenance && window.location.search !== "?reddaccess" ?
            <Redirect from="/auth" to="/maintenance" /> :
            <Redirect from="/maintenance" to="/auth" />
        }
        {!isAuthorized ? (
          /* Render auth page when common at `/auth` and not authorized. */
          <Route path="/auth" component={AuthPage} />
        ) : (
          /* Otherwise redirect to root page (`/`) */
          <Redirect from="/auth" to="/" />
        )}

        {applicationViewMismatch &&
        <Redirect to="/" />
        }
        {/* App urls changed - redirects */}
        <Redirect from="/marketplace-leads" to={ROUTES.LEADS_TENANT_INTRODUCTIONS}/>
        <Redirect from="/finne-leads" to={ROUTES.LEADS_TENANT_INTRODUCTIONS}/>

        <Route path="/error" component={ErrorsPage} />
        <Route path="/maintenance" component={MaintenancePage} />
        <Route path="/application-new-release" component={ForceLogoutPage}/>
        <Route path="/redirect" component={RedirectPage}/>
        <Route path="/kudos-thank-you" component={RealTimeMail.RealTimeMailPageContainer} />
        <Route path={`${ROUTES.SUBSCRIBERS_PAGE}/accept/:token/`} component={SubscriberSubscribePage} />
        <Route path={`${ROUTES.SUBSCRIBERS_PAGE}/unsubscribe/:token/`} component={SubscriberUnsubscribePage} />
        <Route path={`${ROUTES.PROPERTY_PARTICULARS_ONLINE}/:team/:office/`} component={PropertyParticulars.PropertyParticularsContainer} />
        <Route path={`${ROUTES.PROPERTY_PARTICULARS_ONLINE}/:team/`} component={PropertyParticulars.PropertyParticularsContainer} />
        <Route path={`${ROUTES.OFFERS_ONLINE}/:offer/:office/`} component={OffersOnline.OffersOnlineContainer} />
        <Route path={`${ROUTES.OFFERS_ONLINE}/:offer/`} component={OffersOnline.OffersOnlineContainer} />
        <Route path={`${ROUTES.INDUSTRIAL_OFFERS_ONLINE}/:offer/:office`} component={OffersOnlineIndustrial} />
        <Route path={`${ROUTES.INDUSTRIAL_OFFERS_ONLINE}/:offer/`} component={OffersOnlineIndustrial} />
        <Route path={`${ROUTES.INDUSTRIAL_PROPERTY_PARTICULARS_ONLINE}/:team/:park/`} component={IndustrialPropertyParticulars.PropertyParticularsContainer} />
        <Route path={`${ROUTES.INDUSTRIAL_PROPERTY_PARTICULARS_ONLINE}/:team/`} component={IndustrialPropertyParticulars.PropertyParticularsContainer} />
        <Route path={`${ROUTES.REDD_TOWER_DEMO}/`} component={PropertyPageDemo.PropertyPageDemoContainer} />

        {!isAuthorized ? (
          /* Redirect to `/auth` when common is not authorized */
          <Redirect to="/auth" />
        ) : (
          /* Render main Layout if common is logged in */
          <Layout>
            <HomePage user={userData}/>
          </Layout>
        )}
      </Switch>
    </LayoutContextProvider>
  );
}

const mapStateToProps = ({user, authApp}) => ({
  applicationView: user?.data?.currentApplicationView || authApp?.data?.currentApplicationView
});
const mapDispatchToProps = {
  updateUserData: (data) => updateUserData(data)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Routes));
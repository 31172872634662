import React from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle, Slide, Typography} from "@material-ui/core";
import {ThemeProvider} from "_metronic";
import styles from "./index.module.scss";
import {FormattedMessage} from "react-intl";
import clsx from "clsx";
import ConfirmationDialog from "../ConfirmationDialog";
import {ReactComponent as IconClose} from "app/media/icons/icon-close.svg";
import Button from "../Button";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default class ModalCustom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.externalModalState || false,
      confirmationOpen: false
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { onToggle } = this.props;
    if (onToggle && !prevState.open && prevState.open !== this.state.open) {
      onToggle(this.state.open);
    }
    // Disable BI Insights auto modal open after initial action
    if (this.state.open && localStorage.getItem("redirectOfficeID")) {
      localStorage.removeItem("redirectOfficeID");
      localStorage.removeItem("redirectViewMode");
    }
  }

  handleClickOpen = (e, inactive) => {
    const { fetch, isOpen, gtagAllowed, onOpen } = this.props;
    fetch && fetch();
    onOpen && onOpen(e);
    if (!inactive) {
      this.setState(
        {
          open: true,
        },
        () => {
          if (isOpen) {
            isOpen(this.state.open);
          }
          if (gtagAllowed && typeof window.gtag === "function") {
            window.gtag("event", "Click", {
              event_category: "Button",
              event_label: "Become Premium",
            });
          }
        }
      );
    }
  };
  handleClose = () => {
    const {
      closeConfirmationRequired,
      handleClose,
      isOpen,
      externalPremiumPopupClose,
    } = this.props;

    if (closeConfirmationRequired) {
      this.setState({
        confirmationOpen: true,
      });
    } else {
      this.setState(
        {
          open: false,
        },
        () => {
          isOpen && isOpen(this.state.open);
          externalPremiumPopupClose && externalPremiumPopupClose();
          handleClose && handleClose();
        }
      );
    }
  };
  handleConfirmationRejected = () => {
    this.setState({
      confirmationOpen: false,
    });
  };
  handleConfirmationGranted = () => {
    const { handleClose, isOpen, externalPremiumPopupClose } = this.props;
    this.setState(
      {
        confirmationOpen: false,
        open: false,
      },
      () => {
        isOpen && isOpen(this.state.open);
        externalPremiumPopupClose && externalPremiumPopupClose();
        handleClose && handleClose();
      }
    );
  };

  render() {
    const {
      toolbar,
      btn,
      title,
      children,
      handleSave,
      btnFlex,
      btnReject,
      btnConfirm,
      maxWidth,
      fullScreen,
      inactive,
      btnStyle,
      responseDisabled,
      externalModalState,
      modalContentFlexCenter,
      btnInline,
      btnRejectDisabled,
      btnConfirmDisabled,
      justify,
      btnClassNames,
      disabled,
      saving,
      btnCloseAlt,
      dialogStyle,
      themeDark,
      paperStyle
    } = this.props;
    const { open } = this.state;
    const isOpen = externalModalState || open;

    return (
      <>
        <div
          className={clsx(
            btnFlex ? styles.btnFlex : undefined,
            btnInline ? styles.btnInline : undefined,
            inactive && !responseDisabled ? styles.inactive : undefined,
            disabled ? styles.disabled : undefined,
            btnClassNames
          )}
          onClick={(e) => this.handleClickOpen(e, inactive || disabled)}
          style={btnStyle}
        >
          {btn}
        </div>
        <ThemeProvider dark={themeDark}>
          {isOpen && (
            <Dialog
              fullScreen={fullScreen}
              maxWidth={maxWidth || "xs"}
              fullWidth
              open={isOpen}
              TransitionComponent={Transition}
              keepMounted
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
              container={document.getElementById("root")}
              PaperProps={{
                square: true,
                style: {
                  ...paperStyle ? paperStyle : {}
                }
              }}
              style={dialogStyle}
            >
              {btnCloseAlt &&
                <button className={styles.btnCloseAlt} onClick={this.handleClose}>
                  <IconClose width="2.4rem" height="2.4rem"/>
                </button>
              }
              <div className={styles.toolbarContainer}>
                {title &&
                <DialogTitle id="alert-dialog-slide-title" disableTypography>
                  <h3 className={styles.modalTitle}>{title}</h3>
                </DialogTitle>
                }
                {toolbar && toolbar}
              </div>
              <DialogContent
                className={modalContentFlexCenter && styles.dialogContentFlexCenter}
                style={{overflowX: "hidden"}}
              >
                {children}
              </DialogContent>

              {((!btnRejectDisabled && !btnCloseAlt) || handleSave) && (
                <DialogActions
                  style={{
                    justifyContent: justify || null,
                    gap: "2.4rem"
                  }}
                >
                  {(!btnRejectDisabled || !btnCloseAlt) && (
                    <Button
                      type={"plainText"}
                      color={"simple"}
                      style={{textDecoration: 'underline', fontSize: "1.2rem"}}
                      onClick={this.handleClose}
                    >
                      {btnReject || <FormattedMessage id="GENERAL.PHRASE.CLOSE"/>}
                    </Button>
                  )}
                  {handleSave &&
                  <Button
                    inactive={btnConfirmDisabled}
                    loading={saving}
                    onClick={handleSave}
                  >
                    {btnConfirm || "Ok"}
                  </Button>
                  }
                </DialogActions>
              )}
            </Dialog>
          )}
        </ThemeProvider>
        <ConfirmationDialog
          open={this.state.confirmationOpen}
          onClose={this.handleConfirmationRejected}
          onConfirm={this.handleConfirmationGranted}
          message={
            <>
              <Typography style={{ marginBottom: "1rem" }}>
                <FormattedMessage id="INDUSTRIAL.PHRASE.IPI_CLOSE_CONFIRMATION_MESSAGE" />
              </Typography>
              <Typography>
                <FormattedMessage id="GENERAL.PHRASE.ACTION_CONFIRMATION_MESSAGE" />
              </Typography>
            </>
          }
        />
      </>
    );
  }
}

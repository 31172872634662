import React from "react";
import {Grid} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import InputAdornments from "app/partials/components/InputAdornments";
import SingleSelect from "app/partials/components/SingleSelect";
import {FILTERS_SELECTS} from "app/partials/components/FiltersDrawer/constants";
import {onlyNumbers} from "app/utils/helpers";
import _ from "lodash";
import {updateIndustrialPark} from "../../../../../crud/estates/industrial/industrial.crud";
import format from "date-fns/format";

class EditParkBasics extends React.Component {
  constructor(props) {
    super(props);
    const {
      name,
      dateOfConstructionStart,
      dateOfConstructionEnd,
      parkAvailableFrom,
      status,
      entranceGates,
      distanceFromFreeway,
      distanceFromCityCentre
    } = props.data;
    this.state = {
      name: name || '',
      dateOfConstructionStart: dateOfConstructionStart ? format(new Date(dateOfConstructionStart), "yyyy-MM-dd") : "",
      dateOfConstructionEnd: dateOfConstructionEnd ? format(new Date(dateOfConstructionEnd), "yyyy-MM-dd") : "",
      parkAvailableFrom: parkAvailableFrom ? format(new Date(parkAvailableFrom), "yyyy-MM-dd") : "",
      status,
      entranceGates: entranceGates || '',
      distanceFromFreeway,
      distanceFromCityCentre
    };
    this.initialState = this.state;
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    // Handle Change Detect
    this.props.onUnsavedData(!_.isEqual(this.initialState, this.state));
    // Handle Save
    if(!prevProps.requestSave && this.props.requestSave) {
      this.handleSave();
    }
    // Detect Office Updating
    if(prevProps.parkUpdateLoading !== this.props.parkUpdateLoading) {
      this.props.onRequestSaving(this.props.parkUpdateLoading);
    }
  }

  handleChange = (event) => {
    const onlyNums = onlyNumbers(event.target.value);
    const allowOnlyNumbers = event.target.name === "area" || event.target.name === "floor";
    this.setState({
      [event.target.name]: allowOnlyNumbers ? onlyNums : event.target.value
    });
  };
  handleChangeSelect = (selected, target) => {
    this.setState({
      [target]: selected
    });
  };
  handleChangeDate = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  handleSave = () => {
    const {data: {id}, updateIndustrialPark} = this.props;
    const {
      name,
      dateOfConstructionStart,
      dateOfConstructionEnd,
      parkAvailableFrom,
      status,
      entranceGates,
      distanceFromFreeway,
      distanceFromCityCentre
    } = this.state;
    this.initialState = this.state;
    const data = {
      name,
      dateOfConstructionStart: dateOfConstructionStart ? format(new Date(dateOfConstructionStart), "yyyy-MM-dd") : null,
      dateOfConstructionEnd: dateOfConstructionEnd ? format(new Date(dateOfConstructionEnd), "yyyy-MM-dd") : null,
      parkAvailableFrom: parkAvailableFrom ? format(new Date(parkAvailableFrom), "yyyy-MM-dd") : null,
      status: status === "" ? null : status,
      entranceGates: entranceGates || null,
      distanceFromFreeway,
      distanceFromCityCentre
    };
    updateIndustrialPark(id, data, "basic");
  };

  render() {
    const {parkUpdateLoading} = this.props;
    const {
      name,
      dateOfConstructionStart,
      dateOfConstructionEnd,
      parkAvailableFrom,
      status,
      entranceGates,
      distanceFromFreeway,
      distanceFromCityCentre
    } = this.state;

    return(
      <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputAdornments
                label={<FormattedMessage id="INDUSTRIAL.PHRASE.PARK_NAME"/>}
                name="name"
                value={name}
                onChange={this.handleChange}
                disabled={parkUpdateLoading}
            />
          </Grid>
          <Grid item xs={6}>
            <InputAdornments
                type="date"
                label={<FormattedMessage id="INDUSTRIAL.PHRASE.DATE_OD_CONSTRUCTION_START"/>}
                name="dateOfConstructionStart"
                value={dateOfConstructionStart}
                onChange={this.handleChangeDate}
                disabled={parkUpdateLoading}
            />
          </Grid>
          <Grid item xs={6}>
            <InputAdornments
                type="date"
                label={<FormattedMessage id="INDUSTRIAL.PHRASE.DATE_OD_CONSTRUCTION_END"/>}
                name="dateOfConstructionEnd"
                value={dateOfConstructionEnd}
                onChange={this.handleChangeDate}
                disabled={parkUpdateLoading}
            />
          </Grid>
          <Grid item xs={6}>
            <InputAdornments
                type="date"
                label={<FormattedMessage id="INDUSTRIAL.PHRASE.PARK_AVAILABLE_FROM"/>}
                name="parkAvailableFrom"
                value={parkAvailableFrom}
                onChange={this.handleChangeDate}
                disabled={parkUpdateLoading}
            />
          </Grid>
          <Grid item xs={6}>
            <SingleSelect
                label={<FormattedMessage id="INDUSTRIAL.PHRASE.PARK_STATUS"/>}
                inputName="status"
                options={FILTERS_SELECTS.BUILDINGS_STATUSES}
                optionsValue="id"
                optionsLabel="label"
                defaultSelect={status}
                onSelectedValues={this.handleChangeSelect}
                disabled={parkUpdateLoading}
            />
          </Grid>
          <Grid item xs={6}>
            <InputAdornments
                label={<FormattedMessage id="INDUSTRIAL.PHRASE.PARK_ENTRANCE_GATES"/>}
                name="entranceGates"
                value={entranceGates}
                onChange={this.handleChange}
                disabled={parkUpdateLoading}
            />
          </Grid>
          <Grid item xs={6}>
            <InputAdornments
                label={<FormattedMessage id="INDUSTRIAL.PHRASE.DISTANCE_FROM_FREEWAY"/>}
                name="distanceFromFreeway"
                value={distanceFromFreeway}
                onChange={this.handleChange}
                disabled={parkUpdateLoading}
            />
          </Grid>
          <Grid item xs={6}>
            <InputAdornments
                label={<FormattedMessage id="INDUSTRIAL.PHRASE.DISTANCE_FROM_CITY_CENTER"/>}
                name="distanceFromCityCentre"
                value={distanceFromCityCentre}
                onChange={this.handleChange}
                disabled={parkUpdateLoading}
            />
          </Grid>
        </Grid>
    );
  }
}

const mapStateToProps = store => ({
  parkUpdateLoading: store.industrial.parkUpdateLoading
});
const mapDispatchToProps = {
  updateIndustrialPark: (parkID, data, backgroundRefresh) => updateIndustrialPark(parkID, data, backgroundRefresh)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditParkBasics)
);
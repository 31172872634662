import React from "react";
import TableCell from "@material-ui/core/TableCell/TableCell";
import UnitTypePreview from "app/partials/components/_DataParts/UnitTypePreview";
import UnitStatusPreview from "app/partials/components/_DataParts/UnitStatusPreview";
import UnitAvailabilityPreview from "app/partials/components/_DataParts/UnitAvailabilityPreview";
import UpdatedAtPreview from "app/partials/components/_DataParts/UpdatedAtPreview";
import {UNIT_STATUS} from "app/constants";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {handleEmptyValue,} from "app/utils/helpers";
import EditModal from "../../../../partials/components/EditModal";
import UnitAreaPreview from "../../../../partials/components/_DataParts/UnitAreaPreview";
import Button from "../../../../partials/components/Button";
import Icon from "../../../../partials/components/Icon";

class WarehousesPageWarehouseUnitsTableRow extends React.Component {
  render() {
    const { row, parentData } = this.props;
    const isPropertyEditable = parentData?.isEditable;

    return (
      <>
        <TableCell component="th" scope="row" align="right">
          <UnitAreaPreview
            data={row}
            parentData={parentData}
            isOfficeEditable={isPropertyEditable}
            flexibleUnitForm={
              <EditModal
                target="warehouseUnit"
                parentData={row}
                triggerDots
                isPropertyEditable={isPropertyEditable}
                initialTab="flexibleUnits"
                extraData={{
                  warehouseID: parentData?.id
                }}
                triggerCustom={
                <Button
                  type={"icon"}
                  color={row?.canBeDivided ? "danger" : "simple"}
                  tooltip={<FormattedMessage id="UNITS.PHRASE.FLEXIBLE_UNITS" />}
                >
                  <Icon icon={"units-brick"}/>
                </Button>
                }
              />
            }
          />
        </TableCell>
        <TableCell>
          <UnitTypePreview
            industrialType={row.isOffice ? "office" : "warehouse"}
          />
        </TableCell>
        <TableCell>
          <UnitStatusPreview unitStatus={row.status} />
        </TableCell>
        <TableCell>
          <UnitAvailabilityPreview
            date={row.availableFrom}
            isReserved={row.status === UNIT_STATUS.RESERVED}
            isUnavailable={row.status === UNIT_STATUS.UNAVAILABLE}
            availableInMonths={row.availableFromAdditionalTime}
            parentStatus={parentData?.status}
          />
        </TableCell>
        <TableCell>
          <UpdatedAtPreview level={row.updateLevel} date={row.updatedAt} alternative />
        </TableCell>
        <TableCell align="right">{handleEmptyValue(row.docks)}</TableCell>
        <TableCell align="right">
          <EditModal
            target="warehouseUnit"
            parentData={row}
            triggerDots
            isPropertyEditable={isPropertyEditable}
            initialTab={isPropertyEditable ? "basic" : "note"}
            extraData={{
              warehouseID: parentData?.id,
              warehouseName: parentData?.name,
              parkName: parentData?.parkName
            }}
          />
        </TableCell>
      </>
    );
  }
}

export default injectIntl(
  connect()(WarehousesPageWarehouseUnitsTableRow)
);

import React from "react";
import {TABLE_CELLS} from "app/partials/components/TableProperties";
import LoadingScreen from "app/partials/components/LoadingScreen";
import {FormattedMessage} from "react-intl";
import {withRouter} from "react-router-dom";
import {VIEW_TYPES} from "app/constants";
import ListView from "app/pages/offices/components/ListView/ListView";
import store from "app/store/store";
import {Portlet, PortletBody, PortletHeader, PortletHeaderToolbar} from "app/partials/content/Portlet";
import {Grid, Typography} from "@material-ui/core";
import MapView from "app/pages/offices/components/MapView/MapView";
import MapLegend from "app/pages/offices/components/MapView/MapLegend";
import BadgeTitle from "app/partials/components/BadgeTitle";
import tableStyles from "app/partials/components/TableProperties/index.module.scss";
import styles from "./components/index.module.scss";
import {isFiltersActive} from "app/partials/components/FiltersManageBar/constants";
import IsAdmin from "../../../utils/IsAdmin";
import ReactVisibilitySensor from "react-visibility-sensor";

let prevLocation; // Hold previous route 'pathname'
class BuildingsPage extends React.Component {
  constructor(props) {
    super(props);
    // Set previous route 'pathname'
    props.history.listen(nextLocation => {
      prevLocation = nextLocation.pathname;
    });
    this.state = {
      page: 1,
      viewType: props.viewType,
      selectedOffice: null,
      selectedItem: null,
      isReady: false,
      initLoaded: false,
      markerFilters: []
    };
  }
  componentDidMount() {
    const {setLatestActiveTablePage, filterReset} = this.props;
    const updateData = prevLocation === undefined || !prevLocation.includes('/building/');
    if(updateData) {
      setLatestActiveTablePage(0);
      filterReset(["market", "isEditable"]);
    }
  }
  componentDidUpdate() {
    const {viewType, fetchOfficeList, fetchOfficeClusters} = this.props;
    // Check if Offices data should be update based on previous location
    const updateData = prevLocation === undefined || !prevLocation.includes('/building/');
    // Flag to check if current page view is a Map View
    const isMapView = viewType === VIEW_TYPES.MAP_VIEW;
    // Execute this if current View Type is different from View Type before component Update
    if (viewType !== this.state.viewType) {
      let params = {
        page: 1,
        ...store.getState().filters
      };
      // Change params set if current page view is not a Map View
      if (!isMapView) {
        params = {
          page: this.state.page,
          ...store.getState().filters,
          order: '-',
          orderBy: 'updated_at_by_user'
        };
      }
      // Update View Type
      this.setState({
        viewType
      }, () => {
        if (updateData) {
          if (viewType === VIEW_TYPES.MAP_VIEW) {
            // Update Office Clusters if View Type is Map View
            fetchOfficeClusters({params});
          }
          else {
            // Update Offices List if View Type is not Map View
            fetchOfficeList({params});
          }
        }
      });
    }
  }

  // Function used to determine current active unit
  handleSelectOffice = (unitId) => {
    this.setState({
      selectedItem: unitId
    });
  };
  // Function used when Map Table Drawer is closed
  handleListItemClick = (officeData) => {
    this.setState({
      selectedOffice: officeData
    })
  };
  // Handle fetch data on enter viewport
  handleOnViewportFetch = (isVisible) => {
    const {
      viewType,
      fetchOfficeList,
      fetchOfficeClusters,
      filterByModules,
      location,
      history,
      loading,
      callReddClickEvent
    } = this.props;
    const {initLoaded} = this.state;
    const isMapView = viewType === VIEW_TYPES.MAP_VIEW;
    const queryParams = new URLSearchParams(location.search)
    const isQueryParamIsEditable = queryParams.has("is_editable");
    // Check if Offices data should be update based on previous location
    const updateData = prevLocation === undefined || !prevLocation.includes('/building/');

    if(isVisible && !loading && !initLoaded) {
      this.setState({
        initLoaded: true
      }, () => {
        // Params to update data: new page number, all selected filters
        if (updateData) {
          const params = {
            isFilterSet: 0,
            forceUpdateChilds: [],
            ...store.getState().filters,
            orderBy: 'updated_at_by_user',
            ...isQueryParamIsEditable ? {isEditable: "true"} : {}
          };
          if(isQueryParamIsEditable) {
            // update filters
            filterByModules(params);
            // remove is_editable from queryParams
            queryParams.delete('is_editable')
            history.replace({
              search: queryParams.toString(),
            })
          }
          if (isMapView) {
            fetchOfficeClusters({params});
          }
          else {
            fetchOfficeList({params});
          }
        }
      });
    }
    if (isVisible && !this.state.isReady) {
      this.setState({
        isReady: true
      }, () => {
        callReddClickEvent(
          "estates-office-list",
          "office"
        );
      })
    }
  };
  handleOnViewport = (isInViewport) => {
    this.handleOnViewportFetch(isInViewport);
  }
  // Handle filter markers
  handleFilterMarkers = (filters) => {
    this.setState({
      markerFilters: filters
    });
  }

  // Render Map View
  renderUnitsMapView = () => {
    const {
      clusters,
      clustersLoaded,
      fetchOffice,
      office,
      officeLoading,
      clustersLoading,
      allUserStates,
      fetchOfficeUnits,
      officeUnits,
      officeUnitsLoading,
      marketName
    } = this.props;
    const {markerFilters} = this.state;
    const {markers} = clusters;
    const totalBuildings = markers.length;

    return (
      <Portlet style={{marginBottom: 0}} className={isFiltersActive() && styles.filterBarEnable}>
        <PortletHeader
          title={
            <Typography variant="h3">
              {marketName ? <BadgeTitle badge={marketName}/> : <FormattedMessage id="VOCABULARY.Properties"/>}
              {totalBuildings && <FormattedMessage id="GENERAL.PHRASE.TOTAL_RESULTS" values={{
                results: <span className={tableStyles.resultsValue}>{totalBuildings}</span>, prefix: " - "
              }}/>}
            </Typography>
          }
          toolbar={
            <PortletHeaderToolbar>
              <MapLegend markerFilters={markerFilters} onFilter={this.handleFilterMarkers}/>
            </PortletHeaderToolbar>
          }
        />
        <PortletBody fit>
          <ReactVisibilitySensor
            onChange={(isVisible) => this.handleOnViewport(isVisible)}
            scrollCheck
            partialVisibility
          >
            <Grid container spacing={0} direction="column">
              <Grid item xs={12}>
                {clustersLoaded ?
                  <MapView
                    getOffice={fetchOffice}
                    getOfficeUnits={fetchOfficeUnits}
                    markers={markers}
                    office={office}
                    officeLoading={officeLoading}
                    officeUnits={officeUnits}
                    officeUnitsLoading={officeUnitsLoading}
                    clustersLoading={clustersLoading}
                    selectedOffice={this.state.selectedOffice}
                    allUserStates={allUserStates}
                    markerFilters={markerFilters}
                    onSelectOffice={this.handleSelectOffice}
                    onCloseSelectedOffice={this.handleListItemClick}
                  /> : <LoadingScreen/>}

              </Grid>
            </Grid>
          </ReactVisibilitySensor>
        </PortletBody>
      </Portlet>
    );
  };

  render() {
    const {viewType, clustersLoaded, marketName} = this.props;
    const isMapView = viewType === VIEW_TYPES.MAP_VIEW;
    const {offices, items, loading} = this.props;
    const buildingItems = Array.isArray(items) ? items : [];

    return (
      <>
        {isMapView ? (clustersLoaded ? this.renderUnitsMapView() : <LoadingScreen/>) :
          <ListView
            tableLabel={marketName ? <BadgeTitle badge={marketName}/> : <FormattedMessage id="VOCABULARY.Properties"/>}
            tableHeadRows={[
              ...IsAdmin() ? [{
                id: 'checkable',
                numeric: false,
                disablePadding: false,
                label: 'BUILDING.TABLE.NAME',
                sort: false
              }] : [],
              {
                id: 'office_name',
                numeric: false,
                disablePadding: false,
                label: 'VOCABULARY.Building',
                sort: true
              },
              {
                id: 'rent_details',
                numeric: false,
                disablePadding: false,
                label: 'VOCABULARY.Lease terms',
                sort: false
              },
              {
                id: 'total_area_available',
                numeric: true,
                disablePadding: false,
                label: 'VOCABULARY.Available space',
                sort: true
              },
              {
                id: 'occupancy_rate',
                numeric: true,
                disablePadding: false,
                label: 'VOCABULARY.Occupancy rate',
                sort: false
              },
              {
                id: 'updated_at_by_user',
                numeric: false,
                disablePadding: false,
                label: 'BUILDING.PHRASE.UPDATED_AT',
                sort: true
              },
              {id: 'actions', numeric: true, disablePadding: false, label: '', sort: false},
            ]}
            tableBodyRows={buildingItems}
            tableDataSource="office"
            tableDataTarget={TABLE_CELLS.OFFICES}
            itemsPage={offices?.itemsPage}
            itemsPerPage={offices?.itemsPerPage}
            itemsCount={offices?.itemsCount}
            totalItems={offices?.totalItems}
            handleFetchData={this.props.fetchOfficeList}
            loading={loading}
            filterBarEnable
            onEnterViewport={this.handleOnViewport}
          />
        }
      </>
    )
  }
}

export default withRouter(BuildingsPage);
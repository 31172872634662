import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {put, takeLatest} from "redux-saga/effects";
import axios from "axios";
import {actionNotification} from "../../../../utils/notifications";

const initialState = {
  basic: null,
  basicLoading: false,
  units: null,
  unitsLoading: false,
  leaseTerms: null,
  leaseTermsLoading: false,
  technicalDetails: null,
  technicalDetailsLoading: false,
  amenities: null,
  amenitiesLoading: false,
  metrics: null,
  metricsLoading: false,
  changes: null,
  changesLoading: false,
  files: null,
  filesLoading: false,
  similarProperties: null,
  similarPropertiesLoading: false,
  owner: null,
  ownerLoading: false,
  leasingContacts: null,
  leasingContactsLoading: false,
  editFormsData: null,
  editFormsDataLoading: false,
  leasingTeams: null,
  leasingTeamsLoading: false,
  photosOrderUpdating: false,
  resetting: false
};

export const reducer = persistReducer(
  { storage, key: "industrialWarehouse" },
  (state = initialState, action) => {
    switch (action.type) {
      // RESET INDUSTRIAL WAREHOUSE LOADING STATES
      case 'RESET_INDUSTRIAL_WAREHOUSE_LOADING_STATES_REQUEST': {
        return {
          ...state,
          basicLoading: false,
          unitsLoading: false,
          leaseTermsLoading: false,
          technicalDetailsLoading: false,
          amenitiesLoading: false,
          metricsLoading: false,
          changesLoading: false,
          filesLoading: false,
          similarPropertiesLoading: false,
          ownerLoading: false,
          leasingContactsLoading: false,
          editFormsDataLoading: false,
          photosOrderUpdating: false
        }
      }
      // BASIC
      case 'FETCH_INDUSTRIAL_WAREHOUSE_BASIC_REQUEST': {
        const backgroundLoading = action.payload?.backgroundLoading;
        return {
          ...state,
          basic: backgroundLoading ? state.basic : null,
          basicLoading: !backgroundLoading
        }
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_BASIC_SUCCESS': {
        return { ...state, basic: action.payload, basicLoading: false }
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_BASIC_FAILED': {
        return { ...state, basic: null, basicLoading: false }
      }
      // UNITS
      case 'FETCH_INDUSTRIAL_WAREHOUSE_UNITS_REQUEST': {
        const backgroundLoading = action.payload?.backgroundLoading;
        return {
          ...state,
          unitsLoading: !backgroundLoading,
          units: backgroundLoading ? state.units : null
        }
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_UNITS_SUCCESS': {
        return { ...state, unitsLoading: false, units: [...action.payload.units] }
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_UNITS_FAILED': {
        return { ...state, unitsLoading: false, units: null }
      }
      // LEASE TERMS
      case 'FETCH_INDUSTRIAL_WAREHOUSE_LEASE_TERMS_REQUEST': {
        const backgroundLoading = action.payload?.backgroundLoading;
        return {
          ...state,
          leaseTerms: backgroundLoading ? state.leaseTerms : null,
          leaseTermsLoading: !backgroundLoading
        }
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_LEASE_TERMS_SUCCESS': {
        return { ...state, leaseTerms: action.payload, leaseTermsLoading: false }
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_LEASE_TERMS_FAILED': {
        return { ...state, leaseTerms: null, leaseTermsLoading: false }
      }
      // TECHNICAL DETAILS
      case 'FETCH_INDUSTRIAL_WAREHOUSE_TECHNICAL_DETAILS_REQUEST': {
        const backgroundLoading = action.payload?.backgroundLoading;
        return {
          ...state,
          technicalDetails: backgroundLoading ? state.technicalDetails : null,
          technicalDetailsLoading: !backgroundLoading
        }
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_TECHNICAL_DETAILS_SUCCESS': {
        return { ...state, technicalDetails: action.payload, technicalDetailsLoading: false }
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_TECHNICAL_DETAILS_FAILED': {
        return { ...state, technicalDetails: null, technicalDetailsLoading: false }
      }
      // AMENITIES
      case 'FETCH_INDUSTRIAL_WAREHOUSE_AMENITIES_REQUEST': {
        const backgroundLoading = action.payload.backgroundLoading;
        return {
          ...state,
          amenities: backgroundLoading ? state.amenities : null,
          amenitiesLoading: !backgroundLoading
        }
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_AMENITIES_SUCCESS': {
        return { ...state, amenities: action.payload?.amenities, amenitiesLoading: false }
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_AMENITIES_FAILED': {
        return { ...state, amenities: null, amenitiesLoading: false }
      }
      // METRICS
      case 'FETCH_INDUSTRIAL_WAREHOUSE_METRICS_REQUEST': {
        const backgroundLoading = action.payload?.backgroundLoading;
        return {
          ...state,
          metrics: backgroundLoading ? state.metrics : null,
          metricsLoading: !backgroundLoading
        }
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_METRICS_SUCCESS': {
        return { ...state, metrics: action.payload, metricsLoading: false }
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_METRICS_FAILED': {
        return { ...state, metrics: null, metricsLoading: false }
      }
      // CHANGES
      case 'FETCH_INDUSTRIAL_WAREHOUSE_CHANGES_REQUEST': {
        return { ...state, changes: null, changesLoading: true }
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_CHANGES_SUCCESS': {
        return { ...state, changes: action.payload?.changes, changesLoading: false }
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_CHANGES_FAILED': {
        return { ...state, changes: null, changesLoading: false }
      }
      // FILES
      case 'FETCH_INDUSTRIAL_WAREHOUSE_FILES_REQUEST': {
        return { ...state, files: null, filesLoading: true }
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_FILES_SUCCESS': {
        return { ...state, files: action.payload, filesLoading: false }
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_FILES_FAILED': {
        return { ...state, files: null, filesLoading: false }
      }
      // SIMILAR PROPERTIES
      case 'FETCH_INDUSTRIAL_WAREHOUSE_SIMILAR_PROPERTIES_REQUEST': {
        return { ...state, similarProperties: null, similarPropertiesLoading: true }
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_SIMILAR_PROPERTIES_SUCCESS': {
        return { ...state, similarProperties: action.payload, similarPropertiesLoading: false }
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_SIMILAR_PROPERTIES_FAILED': {
        return { ...state, similarProperties: null, similarPropertiesLoading: false }
      }
      // OWNER
      case 'FETCH_INDUSTRIAL_WAREHOUSE_OWNER_REQUEST': {
        return { ...state, owner: null, ownerLoading: true }
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_OWNER_SUCCESS': {
        return { ...state, owner: action.payload, ownerLoading: false }
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_OWNER_FAILED': {
        return { ...state, owner: null, ownerLoading: false }
      }
      // OWNER
      case 'FETCH_INDUSTRIAL_WAREHOUSE_LEASING_CONTACTS_REQUEST': {
        return { ...state, leasingContacts: null, leasingContactsLoading: true }
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_LEASING_CONTACTS_SUCCESS': {
        return { ...state, leasingContacts: action.payload, leasingContactsLoading: false }
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_LEASING_CONTACTS_FAILED': {
        return { ...state, leasingContacts: null, leasingContactsLoading: false }
      }
      // EDIT FORMS DATA
      case 'FETCH_INDUSTRIAL_WAREHOUSE_EDIT_FORMS_DATA_REQUEST': {
        return { ...state, editFormsData: null, editFormsDataLoading: true }
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_EDIT_FORMS_DATA_SUCCESS': {
        return { ...state, editFormsData: action.payload, editFormsDataLoading: false }
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_EDIT_FORMS_DATA_FAILED': {
        return { ...state, editFormsData: null, editFormsDataLoading: false }
      }
      // LEASING TEAMS
      case 'FETCH_INDUSTRIAL_WAREHOUSE_LEASING_TEAMS_REQUEST': {
        return { ...state, leasingTeams: null,  leasingTeamsLoading: true }
      }
      case 'FETCH_INDUSTRIAL_WAREHOUSE_LEASING_TEAMS_SUCCESS': {
        return { ...state, leasingTeams: action.payload.length > 0 ? action.payload : null, leasingTeamsLoading: false }
      }

      // ACTIONS
      case "UPDATE_INDUSTRIAL_WAREHOUSE_PHOTOS_ORDER_REQUEST": {
        return {
          ...state,
          photosOrderUpdating: true
        };
      }
      case "UPDATE_INDUSTRIAL_WAREHOUSE_PHOTOS_ORDER_SUCCESS": {
        return {
          ...state,
          photosOrderUpdating: false
        };
      }
      case "UPDATE_INDUSTRIAL_WAREHOUSE_PHOTOS_ORDER_FAILED": {
        return {
          ...state,
          photosOrderUpdating: false
        };
      }
      // AUTO ACTION
      case 'RESET_INDUSTRIAL_WAREHOUSE_STATE_REQUEST': {
        return {
          ...state,
          resetting: true
        }
      }
      case 'RESET_INDUSTRIAL_WAREHOUSE_STATE_SUCCESS': {
        return {
          ...initialState,
          resetting: false
        }
      }
      case 'RESET_INDUSTRIAL_WAREHOUSE_STATE_FAILED': {
        return {
          ...initialState,
          resetting: false
        }
      }

      default:
        return state;
    }
  }
);

// FETCH DATA
function* fetchIndustrialWarehouseBasic(action) {
  const {warehouseID} = action.payload;
  try {
    const officeBasic = yield axios.get(`/industrial-buildings/${warehouseID}/basic/`);
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSE_BASIC_SUCCESS", payload: officeBasic.data || [{ error: officeBasic.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSE_BASIC_FAILED" });
    console.log(err);
  }
}
function* fetchIndustrialWarehouseUnits(action) {
  const {warehouseID, ordering} = action.payload;
  try {
    const setOrdering = [
      ...ordering ? [`?ordering=${ordering}`] : []
    ].join("");
    const buildings = yield axios.get(`/industrial-buildings/${warehouseID}/basic-units/${setOrdering}`);
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSE_UNITS_SUCCESS", payload: buildings.data || [{ error: buildings.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSE_UNITS_FAILED" });
    console.log(err);
  }
}
function* fetchIndustrialWarehouseLeaseTerms(action) {
  const {warehouseID} = action.payload;
  try {
    const leaseTerms = yield axios.get(`/industrial-buildings/${warehouseID}/basic-rent/`);
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSE_LEASE_TERMS_SUCCESS", payload: leaseTerms.data || [{ error: leaseTerms.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSE_LEASE_TERMS_FAILED" });
    console.log(err);
  }
}
function* fetchIndustrialWarehouseTechnicalDetails(action) {
  const {warehouseID} = action.payload;
  try {
    const stats = yield axios.get(`/industrial-buildings/${warehouseID}/basic-technical/`);
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSE_TECHNICAL_DETAILS_SUCCESS", payload: stats.data || [{ error: stats.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSE_TECHNICAL_DETAILS_FAILED" });
    console.log(err);
  }
}
function* fetchIndustrialWarehouseAmenities(action) {
  const {warehouseID} = action.payload;
  try {
    const stats = yield axios.get(`/industrial-buildings/${warehouseID}/basic-amenities/`);
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSE_AMENITIES_SUCCESS", payload: stats.data || [{ error: stats.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSE_AMENITIES_FAILED" });
    console.log(err);
  }
}
function* fetchIndustrialWarehouseMetrics(action) {
  const {warehouseID} = action.payload;
  try {
    const stats = yield axios.get(`/industrial-buildings/${warehouseID}/basic-metrics/`);
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSE_METRICS_SUCCESS", payload: stats.data || [{ error: stats.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSE_METRICS_FAILED" });
    console.log(err);
  }
}
function* fetchIndustrialWarehouseChanges(action) {
  const {warehouseID} = action.payload;
  try {
    const changes = yield axios.get(`/industrial-buildings/${warehouseID}/basic-changes/`);
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSE_CHANGES_SUCCESS", payload: changes.data || [{ error: changes.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSE_CHANGES_FAILED" });
    console.log(err);
  }
}
function* fetchIndustrialWarehouseFiles(action) {
  const {warehouseID} = action.payload;
  try {
    const files = yield axios.get(`/industrial-buildings/${warehouseID}/basic-files/`);
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSE_FILES_SUCCESS", payload: files.data || [{ error: files.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSE_CHANGES_FAILED" });
    console.log(err);
  }
}
function* fetchIndustrialWarehouseSimilarProperties(action) {
  const {warehouseID} = action.payload;
  try {
    const changes = yield axios.get(`/industrial-buildings/${warehouseID}/basic-similar/?size=6`);
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSE_SIMILAR_PROPERTIES_SUCCESS", payload: changes.data || [{ error: changes.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSE_SIMILAR_PROPERTIES_FAILED" });
    console.log(err);
  }
}
function* fetchIndustrialWarehouseOwner(action) {
  const {warehouseID} = action.payload;
  try {
    const owner = yield axios.get(`/industrial-buildings/${warehouseID}/basic-owner/`);
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSE_OWNER_SUCCESS", payload: owner?.data });
  }
  catch(err) {
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSE_OWNER_FAILED" });
    console.log(err);
  }
}
function* fetchIndustrialWarehouseLeasingContacts(action) {
  const {warehouseID} = action.payload;
  try {
    const leasingContact = yield axios.get(`/industrial-buildings/${warehouseID}/basic-landlord/`);
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSE_LEASING_CONTACTS_SUCCESS", payload: leasingContact?.data });
  }
  catch(err) {
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSE_LEASING_CONTACTS_FAILED" });
    console.log(err);
  }
}
function* fetchIndustrialWarehouseEditFormsData(action) {
  const {warehouseID} = action.payload;
  try {
    const editFormsData = yield axios.get(`/industrial-buildings/${warehouseID}/basic-form/`);
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSE_EDIT_FORMS_DATA_SUCCESS", payload: editFormsData.data || [{ error: editFormsData.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSE_EDIT_FORMS_DATA_FAILED" });
    console.log(err);
  }
}
function* fetchIndustrialWarehouseLeasingTeams(action) {
  try {
    const warehouseID = action.payload;
    const leasingTeams = yield axios.get(`/industrial-buildings/${warehouseID}/staff/leasing-teams/`);
    yield put({ type: "FETCH_INDUSTRIAL_WAREHOUSE_LEASING_TEAMS_SUCCESS", payload: leasingTeams.data || [{ error: leasingTeams.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* resetIndustrialParkState() {
  try {
    yield put({ type: "RESET_INDUSTRIAL_WAREHOUSE_STATE_SUCCESS" });
  }
  catch(err) {
    yield put({ type: "RESET_INDUSTRIAL_WAREHOUSE_STATE_FAILED" });
    console.log(err);
  }
}

// ACTION
function* updateIndustrialWarehouseLeasingTeam(action) {
  try {
    const {warehouseID, data} = action.payload;
    const updateLeasingTeam = yield axios.post(`/industrial-buildings/${warehouseID}/staff/leasing-teams/`, data);
    yield put({
      type: "UPDATE_INDUSTRIAL_WAREHOUSE_LEASING_TEAM_SUCCESS",
      payload: updateLeasingTeam.data,
      meta: actionNotification('Leasing Team for selected building has been updated.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "UPDATE_INDUSTRIAL_WAREHOUSE_LEASING_TEAM_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* updateIndustrialWarehousePhotosOrder(action) {
  try {
    const {warehouseID, data} = action.payload;
    const update = yield axios.post(`/industrial-buildings/${warehouseID}/set_photos/`, data);
    yield put({
      type: "UPDATE_INDUSTRIAL_WAREHOUSE_PHOTOS_ORDER_SUCCESS",
      payload: update.data,
      meta: actionNotification('Photos order has been updated', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "UPDATE_INDUSTRIAL_WAREHOUSE_PHOTOS_ORDER_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}

export function* saga() {
  yield takeLatest('FETCH_INDUSTRIAL_WAREHOUSE_BASIC_REQUEST', fetchIndustrialWarehouseBasic);
  yield takeLatest('FETCH_INDUSTRIAL_WAREHOUSE_UNITS_REQUEST', fetchIndustrialWarehouseUnits);
  yield takeLatest('FETCH_INDUSTRIAL_WAREHOUSE_LEASE_TERMS_REQUEST', fetchIndustrialWarehouseLeaseTerms);
  yield takeLatest('FETCH_INDUSTRIAL_WAREHOUSE_TECHNICAL_DETAILS_REQUEST', fetchIndustrialWarehouseTechnicalDetails);
  yield takeLatest('FETCH_INDUSTRIAL_WAREHOUSE_AMENITIES_REQUEST', fetchIndustrialWarehouseAmenities);
  yield takeLatest('FETCH_INDUSTRIAL_WAREHOUSE_METRICS_REQUEST', fetchIndustrialWarehouseMetrics);
  yield takeLatest('FETCH_INDUSTRIAL_WAREHOUSE_CHANGES_REQUEST', fetchIndustrialWarehouseChanges);
  yield takeLatest('FETCH_INDUSTRIAL_WAREHOUSE_FILES_REQUEST', fetchIndustrialWarehouseFiles);
  yield takeLatest('FETCH_INDUSTRIAL_WAREHOUSE_SIMILAR_PROPERTIES_REQUEST', fetchIndustrialWarehouseSimilarProperties);
  yield takeLatest('FETCH_INDUSTRIAL_WAREHOUSE_OWNER_REQUEST', fetchIndustrialWarehouseOwner);
  yield takeLatest('FETCH_INDUSTRIAL_WAREHOUSE_LEASING_CONTACTS_REQUEST', fetchIndustrialWarehouseLeasingContacts);
  yield takeLatest('FETCH_INDUSTRIAL_WAREHOUSE_EDIT_FORMS_DATA_REQUEST', fetchIndustrialWarehouseEditFormsData);
  yield takeLatest('FETCH_INDUSTRIAL_WAREHOUSE_LEASING_TEAMS_REQUEST', fetchIndustrialWarehouseLeasingTeams);
  yield takeLatest('RESET_INDUSTRIAL_WAREHOUSE_STATE_REQUEST', resetIndustrialParkState);
  // ACTIONS
  yield takeLatest('UPDATE_INDUSTRIAL_WAREHOUSE_LEASING_TEAM_REQUEST', updateIndustrialWarehouseLeasingTeam);
  yield takeLatest('UPDATE_INDUSTRIAL_WAREHOUSE_PHOTOS_ORDER_REQUEST', updateIndustrialWarehousePhotosOrder);
}
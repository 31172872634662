import React from "react";
import {Chip} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import clsx from "clsx";
import LoadingScreen from "../LoadingScreen";
import styles from "./index.module.scss";

export default class BadgeList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    }
  }
  // Handlers
  handleExpandToggle = () => {
    this.setState({
      expanded: !this.state.expanded
    });
  }

  render() {
    const {badges, simple, collapse, collapsedLimit} = this.props;
    const {expanded} = this.state;
    let sortedBadges = [];
    let activeBadges = [];
    let inactiveBadges = [];
    if (badges) {
      activeBadges = Object.entries(badges).filter(badge => badge[1]);
      inactiveBadges = Object.entries(badges).filter(badge => !badge[1]);
      sortedBadges = [
        ...activeBadges,
        ...simple ? [] : inactiveBadges
      ];
    }

    return (
      <div className={clsx(
        styles.badgeListContainer,
        simple ? styles.badgeSimpleList : undefined
      )}>
        {badges ? (sortedBadges.map((badge, index) => {
          if(simple) {
            const isNotInCollapseLimit = index+1 > collapsedLimit;

            return(
              <li key={index} className={clsx(
                styles.badgeSimple,
                isNotInCollapseLimit && !expanded ? styles.collapsedLimit : undefined
              )}><FormattedMessage id={badge[0]}/></li>
            );
          }
          return(
            <Chip
              key={index}
              label={<FormattedMessage id={badge[0]}/>}
              className={clsx(styles.badge, badge[1] && styles.badgeActive)}
            />
          );
        })) : <LoadingScreen/>}
        {badges && simple && collapse && sortedBadges?.length > collapsedLimit &&
        <button
          className={styles.btnExpandToggle}
          onClick={() => this.handleExpandToggle()}
        >{expanded ? <FormattedMessage id="GENERAL.PHRASE.COLLAPSE"/> : <FormattedMessage id="GENERAL.PHRASE.EXPAND"/>}</button>
        }
      </div>
    );
  }
}
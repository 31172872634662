import React from "react";
import connect from "react-redux/es/connect/connect";
import {Typography} from "@material-ui/core";
import PortletAccordion from "app/partials/components/PortletAccordion";
import {FormattedMessage, injectIntl} from "react-intl";
import {fetchOfficeList} from "app/crud/estates/offices/offices.crud";
import {fetchOfficeUnits} from "app/crud/estates/offices/units.crud";
import {callReddClickEvent} from "app/crud/user.crud";
import TableCustom from "app/partials/components/TableCustom";
import BuildingCardPreview from "app/partials/components/_DataParts/BuildingCardPreview";
import PropertyStatusPreview from "app/partials/components/_DataParts/PropertyStatusPreview";
import BadgeValue from "app/partials/components/BadgeValue";
import {toNumberFormat} from "app/utils/helpers";
import {ROUTES} from "app/constants";
import _ from "lodash";
import LoadingScreen from "app/partials/components/LoadingScreen";
import NoData from "app/partials/components/NoData/NoData";
import TableDrawer from "app/partials/components/TableDrawer";
import TABLE_CELLS from "app/partials/components/TableProperties/TableCells/TABLE_CELLS";
import ReactVisibilitySensor from "react-visibility-sensor";
import IsAccountType from "../../../../utils/IsAccountType";
import ACCOUNT_TYPE from "../../../../constants/ACCOUNT_TYPE";
import HighlightLabel from "../../../../partials/components/HighlightLabel";
import BlurText from "../../../../partials/components/BlurText";
import IsPro from "../../../../utils/IsPro";
import PaywallModal from "../../../../partials/components/PayWall/components/PaywallModal";
import UpdatedAtPreview from "../../../../partials/components/_DataParts/UpdatedAtPreview";
import IsAdmin from "../../../../utils/IsAdmin";
import Button from "../../../../partials/components/Button";

class DashboardPropertiesOffices extends React.Component {
  constructor(props) {
    super(props);
    const initParams = {
      orderBy: IsAccountType({expectedAccountType: ACCOUNT_TYPE.LANDLORD}) ? 'overdue_unit' : 'updated_at_by_user',
      order: IsAccountType({expectedAccountType: ACCOUNT_TYPE.LANDLORD}) ? '' : '-',
      isEditable: IsAccountType({expectedAccountType: ACCOUNT_TYPE.LANDLORD}) ? 'true' : 'false',
      size: 5,
      totalAreaAvailableFrom: "1"
    };
    this.state = {
      tBody: null,
      initLoaded: false,
      params: initParams
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Re-render TableBody
    if(
      // if InitLoaded
      (!prevState.initLoaded && this.state.initLoaded) ||
      // if offices was empty
      (_.isEmpty(prevProps?.offices) && this.props.offices?.length > 0) ||
      // if offices was updated
      (!_.isEqual(prevProps?.offices, this.props.offices)) ||
      // if units loading
      (!prevProps.officeUnitsLoading && this.props.officeUnitsLoading) ||
      // if units was empty
      (_.isEmpty(prevProps?.officeUnits) && this.props.officeUnits?.length > 0) ||
      // if units was updated
      (prevProps.officeUnits && this.props.officeUnits && !_.isEqual(prevProps?.officeUnits, this.props.officeUnits)) ||
      // if unit has been refreshed
      (prevProps.refreshingUnit && !this.props.refreshingUnit) ||
      // if unit has been updated
      (prevProps.officeUnitsUpdateLoading && !this.props.officeUnitsUpdateLoading)
    ) {
      this.setState({
        tBody: this.handlePrepTableBody()
      });
    }
  }

  // Handlers
  handlePrepTableBody = () => {
    if(this.props.offices?.length > 0) {
      return this.props.offices?.map(office => {
        return {
          data: [
            <BuildingCardPreview
              name={office?.name}
              street={office?.addressStreet}
              city={office?.addressCity}
              thumb={office?.mainPhotoThumbnail}
              officeID={office?.id}
            />,
            <PropertyStatusPreview status={office?.status} />,
            <HighlightLabel color="neutral2">
              <BlurText
                type="area"
                isBlurred={IsAccountType({expectedAccountType: ACCOUNT_TYPE.AGENT}) && !IsPro({})}
              >
                {toNumberFormat(office?.totalAreaAvailable, "area")}
              </BlurText>
            </HighlightLabel>,
            <>
            <BadgeValue
              value={toNumberFormat(office?.rentFromEur, "currency")}
              label={<FormattedMessage id="BUILDING.PHRASE.RENT_FROM" />}
            />
            <BadgeValue
              value={toNumberFormat(office?.serviceChargesPln, "currency", "PLN")}
              label={<FormattedMessage id="BUILDING.PHRASE.SERVICE_CHARGE" />}
            />
            </>,
            <UpdatedAtPreview
              level={1}
              date={
              IsAccountType({expectedAccountType: ACCOUNT_TYPE.AGENT}) ?
                office?.updatedAtByUser : office?.overdueUnitDate
              }
              alternative
            />,
            <>
              {
                (
                  IsPro({}) ||
                  IsAccountType({expectedAccountType: ACCOUNT_TYPE.LANDLORD})
                ) ?
                  this.handleManageUnitsDrawer(office) :
                  <PaywallModal
                    triggerLabel={<FormattedMessage id="VOCABULARY.View units"/>}
                    triggerButtonVariant={this.props.intl.formatMessage({id: "VOCABULARY.View units"})}
                    withIcon
                  />
              }
            </>
          ]
        }
      });
    }
  }
  handleManageUnitsDrawer = (office) => {
    const {officeUnits, officeUnitsLoading, fetchOfficeUnits, fetchOfficeList} = this.props;
    const {params} = this.state;
    const isOfficeEditable = office?.isEditable && IsAdmin();

    return(
      <TableDrawer
        titleAdvance
        headRows={[
          ...isOfficeEditable
            ? [
                {
                  id: "checkable",
                  numeric: false,
                  disablePadding: false,
                  label: "",
                  sort: false,
                },
              ]
            : [],
          {
            id: "area",
            numeric: true,
            disablePadding: false,
            label: "GENERAL.PHRASE.AREA",
            sort: false,
          },
          {
            id: "entrance",
            numeric: false,
            disablePadding: false,
            label: "GENERAL.PHRASE.ENTRANCE",
            sort: true,
          },
          {
            id: "floor",
            numeric: true,
            disablePadding: false,
            label: "GENERAL.PHRASE.FLOOR",
            sort: false,
          },
          {
            id: "type",
            numeric: false,
            disablePadding: false,
            label: "GENERAL.PHRASE.TYPE",
            sort: false,
          },
          {
            id: "status",
            numeric: false,
            disablePadding: false,
            label: "BUILDING.PHRASE.STATUS",
            sort: false,
          },
          {
            id: "available_from",
            numeric: false,
            disablePadding: false,
            label: "BUILDING.PHRASE.AVAILABLE_FROM",
            sort: false,
          },
          {
            id: "updated_at",
            numeric: false,
            disablePadding: false,
            label: "BUILDING.PHRASE.UPDATED_AT",
            sort: false,
          },
          {
            id: "rent_esitmation",
            numeric: true,
            disablePadding: false,
            label: "UNITS.PHRASE.FORECAST_FEES_PREMIUM",
            sort: false,
          },
          {
            id: "actions",
            numeric: true,
            disablePadding: false,
            label: "",
            sort: false,
          },
        ]}
        bodyRows={officeUnits}
        bodyRowsReady={officeUnitsLoading}
        target={TABLE_CELLS.OFFICE_UNITS}
        isEditable={isOfficeEditable}
        parentId={office.id}
        parentData={{
          commonAreaFactor: office.commonAreaFactor,
          rentFromEur: office.rentFromEur,
          serviceChargesPln: office.serviceChargesPln,
          lackOfAvailableArea: office.lackOfAvailableArea,
          minRentalYears: office.minRentalYears,
          minRentalYearsToNegotiate: office.minRentalYearsToNegotiate,
          retailRentPriceEur: office.retailRentPriceEur,
          rentToNegotiate: office.rentToNegotiate,
          officeName: office.name,
          isEditable: isOfficeEditable,
          status: office.status,
          commercializationSuspended: office.commercializationSuspended,
          commercializationSuspendedDate: office.commercializationSuspendedDate,
          numberOfUnitsAvailable: office?.numberOfUnitsAvailable
        }}
        parentPreviewData={office}
        onClick={fetchOfficeUnits}
        onClose={() => fetchOfficeList({params})}
      >
        <Button
          trackingData={{
            clickType: "dashboard-overdue-properties-manage-units-click",
            contentType: "user"
          }}
        >
          {
            IsAccountType({expectedAccountType: ACCOUNT_TYPE.LANDLORD}) ?
            <>
              <FormattedMessage id="GENERAL.PHRASE.MANAGE_UNITS"/>
              <strong style={{paddingLeft: "0.5rem"}}>({office?.numberOfUnitsAvailable})</strong>
            </> :
            <FormattedMessage id="VOCABULARY.View units"/>
          }
        </Button>
      </TableDrawer>
    );
  }
  handleOnViewportFetch = (isVisible) => {
    const {
      fetchOfficeList,
      loading
    } = this.props;
    const {initLoaded, params} = this.state;

    if(isVisible && !loading && !initLoaded) {
      this.setState({
        initLoaded: true
      }, () => fetchOfficeList({params}));
    }
  };

  render() {
    const {loading} = this.props;
    const {tBody} = this.state;
    const tHead = [
      {
        "label": <FormattedMessage id="GENERAL.PHRASE.BUILDING"/>,
        "number": false
      },
      {
        "label": <FormattedMessage id="BUILDING.PHRASE.STATUS"/>,
        "number": false
      },
      {
        "label": <FormattedMessage id="VOCABULARY.Available space"/>,
        "number": true
      },
      {
        "label": <FormattedMessage id="BUILDING.PHRASE.RENT_DETAILS"/>,
        "number": false
      },
      {
        "label": <FormattedMessage id="VOCABULARY.Update"/>,
        "number": false
      },
      {
        "label": "",
        "number": true
      }
    ];
    return(
      <PortletAccordion
        title={
        <ReactVisibilitySensor
            onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
            scrollCheck
            partialVisibility
        >
          <Typography variant="h3">
            {
              IsAccountType({expectedAccountType: ACCOUNT_TYPE.LANDLORD}) ?
                <FormattedMessage id="DASHBOARD.PHRASE.YOUR_BUILDINGS"/> :
                <FormattedMessage id="VOCABULARY.Recently updated"/>
            }
          </Typography>
        </ReactVisibilitySensor>
        }
        footer={
        <Button
          type={"plainText"}
          variant={"outlined"}
          redirect={`${ROUTES.BUILDINGS_LIST_PAGE}${IsAccountType({expectedAccountType: ACCOUNT_TYPE.LANDLORD}) ? "?is_editable=true" : ""}`}
          style={{fontSize: "1.6rem", fontWeight: "400"}}
        >
          <FormattedMessage id="VOCABULARY.Check all"/>
        </Button>
        }
        disableAccordion
      >
        {loading ? <LoadingScreen/> :
          _.isEmpty(tBody) ? <NoData withIcon/> :
          <TableCustom
              tHead={tHead}
              tBody={tBody}
              responsive
              maxHeight="75rem"
          />
        }
      </PortletAccordion>
    );
  }
}

const mapStateToProps = (state) => ({
  offices: state.offices.items,
  loaded: state.offices.loaded,
  loading: state.offices.loading,
  marketName: state.user.data.marketName,
  filters: state.filters,
  officeUnits: state.units.officeUnits,
  officeUnitsLoading: state.units.officeUnitsLoading,
  refreshingUnit: state.units.refreshing,
  officeUnitsUpdateLoading: state.units.officeUnitsUpdateLoading
});
const mapDispatchToProps = {
  fetchOfficeList: ({params}) => fetchOfficeList(params),
  fetchOfficeUnits: (officeId) => fetchOfficeUnits(officeId),
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params)
};
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DashboardPropertiesOffices))
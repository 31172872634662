import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import SymmcoPage from "./SymmcoPage";
import {fetchTransactions, fetchTransaction, fetchTransactionsXls, fetchTransactionsDynamicData, fetchTransactionsDatabaseStatus} from "../../../crud/symmco.crud";
import {filterByModules} from "../../../crud/estates/filters.crud";
import {callReddClickEvent} from "../../../crud/user.crud";

const mapStateToProps = (store) => ({
  user: store.user.data,
  items: store.symmco.items,
  itemsCount: store.symmco.itemsCount,
  itemsPerPage: store.symmco.itemsPerPage,
  itemsEffectiveRentMin: store.symmco.itemsEffectiveRentMin,
  itemsNetDealMin: store.symmco.itemsNetDealMin,
  itemsLeasePeriodMin: store.symmco.itemsLeasePeriodMin,
  itemsHeadlineRentMin: store.symmco.itemsHeadlineRentMin,
  itemsEffectiveRentMax: store.symmco.itemsEffectiveRentMax,
  itemsNetDealMax: store.symmco.itemsNetDealMax,
  itemsLeasePeriodMax: store.symmco.itemsLeasePeriodMax,
  itemsHeadlineRentMax: store.symmco.itemsHeadlineRentMax,
  itemsEffectiveRentAvg: store.symmco.itemsEffectiveRentAvg,
  itemsNetDealAvg: store.symmco.itemsNetDealAvg,
  itemsLeasePeriodAvg: store.symmco.itemsLeasePeriodAvg,
  itemsHeadlineRentAvg: store.symmco.itemsHeadlineRentAvg,
  loading: store.symmco.loading,
  transaction: store.symmco.transaction,
  transactionLoading: store.symmco.transactionLoading,
  transactionsXlsLoading: store.symmco.transactionsXlsLoading,
  marketsIndustrial: store?.user?.marketsIndustrial,
  dynamicData: store.symmco.dynamicData,
  dynamicDataLoading: store.symmco.dynamicDataLoading,
  quartersLoading: store.symmco.quartersLoading,
  databaseStatus: store.symmco.databaseStatus,
  databaseStatusLoading: store.symmco.databaseStatusLoadings
});
const mapDispatchToProps = {
  fetchTransactions: (params) => fetchTransactions(params),
  fetchTransaction: (id) => fetchTransaction(id),
  fetchTransactionsXls: (team, params) => fetchTransactionsXls(team, params),
  fetchTransactionsDynamicData: (params) => fetchTransactionsDynamicData(params),
  fetchTransactionsDatabaseStatus: () => fetchTransactionsDatabaseStatus(),
  filterByModules: (params) => filterByModules(params),
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SymmcoPage)
);
import React, {useRef, useState} from "react";
import {FormattedMessage} from "react-intl";
import ModalCustom from "../ModalCustom";
import EditBody from "./components/EditBody";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {handleEmptyValue, isDarkMode, toNumberFormat} from "../../../utils/helpers";
import UnitTypePreview from "../_DataParts/UnitTypePreview";
import Button from "../Button";
import Icon from "../Icon";

function EditModal(
  {
    target,
    triggerDots,
    triggerCustom,
    parentData,
    initialTab,
    isPropertyEditable,
    ...props
  }
) {
  const [requestSave, setRequestSave] = useState(false);
  const [requestSaving, setRequestSaving] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const childRef = useRef();
  // Handlers
  const handleRequestSave = () => {
    setRequestSave(true);
    setTimeout(() => {
      setRequestSave(false);
    }, 1000);
  }
  // Helpers
  const renderModalTitle = () => {
    const unitOfficeName = props?.extraData?.officeName;
    const parkName = props?.extraData?.parkName;
    const warehouseName = props?.extraData?.warehouseName;
    const unitWarehouseName = [
      ...parkName ? [parkName] : [],
      ...warehouseName ? [warehouseName] : []
    ];

    if (target === "office") {
      return (
        <>
          <FormattedMessage id="VOCABULARY.Edit building"/>
          {" "}
          <span style={{color: "#ff4626"}}>{parentData?.name}</span>
        </>
      );
    } else if (target === "unit") {
      return (
        <>
          <FormattedMessage id="UNITS.PHRASE.UNIT_OPTIONS"/>{": "}
          <span style={{color: "#ff4626"}}>{toNumberFormat(parentData?.area, 'area')}</span>
          {" | "}
          <span style={{color: "#ff4626"}}>{handleEmptyValue(parentData?.floor)} <FormattedMessage
            id="GENERAL.PHRASE.FLOOR_TOLOWER"/></span>
          {unitOfficeName &&
            <span style={{
              display: "block",
              fontSize: "1.3rem",
              fontStyle: "italic",
              marginTop: "1rem"
            }}>{unitOfficeName}</span>
          }
        </>
      );
    } else if (target === "park") {
      return (
        <>
          <FormattedMessage id="VOCABULARY.Edit park"/>
          {" "}
          <span style={{color: "#ff4626"}}>{parentData?.name}</span>
        </>
      );
    } else if (target === "warehouse") {
      return (
        <>
          <FormattedMessage id="VOCABULARY.Edit warehouse"/>
          {" "}
          <span style={{color: "#ff4626"}}>{parentData?.name}</span>
          {parentData?.parkName && ` | ${parentData?.parkName}`}
        </>
      );
    } else if (target === "warehouseUnit") {
      return (
        <>
          <FormattedMessage id="UNITS.PHRASE.UNIT_OPTIONS"/>{": "}
          <span style={{color: "#ff4626"}}>{toNumberFormat(parentData?.area, 'area')}</span>
          {" | "}
          <span style={{color: "#ff4626"}}>
            <UnitTypePreview
              industrialType={parentData?.isOffice ? "office" : "warehouse"}
              simple
            />
          </span>
          {unitWarehouseName?.length > 0 &&
            <span style={{
              display: "block",
              fontSize: "1.3rem",
              fontStyle: "italic",
              marginTop: "1rem"
            }}>{unitWarehouseName.join(" - ")}</span>
          }
        </>
      );
    } else if (target === "teamMember") {
      return (
        <>
          <FormattedMessage id="TEAM.PHRASE.Manage team member"/>
          {": "}
          <span style={{color: "#ff4626"}}>{props?.data?.user?.email}</span>
        </>
      );
    }
  }

  return (
    <ModalCustom
      ref={childRef}
      btn={
        triggerCustom ? triggerCustom :
          triggerDots ?
            <Button
              type={"icon"}
              color={"simple"}
              tooltip={<FormattedMessage id="UNITS.PHRASE.UNIT_OPTIONS"/>}
              tooltipPlacement={"left"}
            >
              <Icon icon={"more-vertical"}/>
            </Button> :
            <Button
              type={"icon"}
              color={"simple"}
              tooltip={<FormattedMessage id="GENERAL.PHRASE.EDIT"/>}
              tooltipPlacement={props?.tooltipPlacement || "right"}
            >
              <Icon icon={"edit"}/>
            </Button>
      }
      title={renderModalTitle()}
      btnConfirm={<FormattedMessage id="GENERAL.PHRASE.SAVE"/>}
      handleSave={!saveDisabled && isPropertyEditable ? handleRequestSave : undefined}
      saving={requestSaving}
      maxWidth="lg"
      themeDark={isDarkMode()}
    >
      <EditBody
        target={target}
        parentData={parentData}
        initialTab={initialTab}
        isEditable={isPropertyEditable}
        requestSave={requestSave}
        onRequestSaving={setRequestSaving}
        onSaveDisabled={setSaveDisabled}
        {...props}
      />
    </ModalCustom>
  );
}

export default withRouter(
  connect(null, null)(EditModal)
);

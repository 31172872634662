import React from "react";
import {TableCell, Typography} from "@material-ui/core";
import {toDateFormat} from "../../../../utils/helpers";
import AccountRequestNote from "app/pages/common/AdminManageUserRequestsPage/components/AccountRequestNote";
import AccountRequestDecide from "../../../../pages/common/AdminManageUserRequestsPage/components/AccountRequestDecide";

export default class TableAdminManageAccountRequests extends React.Component {
  render() {
    const {row} = this.props;

    return(
      <>
        <TableCell component="th" scope="row">
          <Typography variant="body1">{row?.name}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body1">{row?.email}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body1">{row?.phone}</Typography>
        </TableCell>
        <TableCell align="right">
          <Typography variant="body1">{toDateFormat(row?.createdAt, true)}</Typography>
        </TableCell>
        <TableCell align="right">
          <AccountRequestNote
            id={row?.id}
            note={row?.note}
          />
        </TableCell>
        <TableCell align="right">
          <AccountRequestDecide
            row={row}
            isAccepted={row?.accepted}
            isRejected={row?.rejected}
          />
        </TableCell>
      </>
    );
  }
}
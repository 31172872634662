import React from 'react';
import ModalCustom from "app/partials/components/ModalCustom";
import InputIcon from "app/partials/components/InputIcon";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {
  fetchIndustrialParkWarehouses,
  uploadIndustrialParkFile,
  uploadIndustrialWarehouseFile
} from "app/crud/estates/industrial/industrial.crud";
import LoadingScreen from "app/partials/components/LoadingScreen";
import SingleSelect from "app/partials/components/SingleSelect";
import {Grid, Typography} from '@material-ui/core';
import {FILTERS_SELECTS} from "../../../../partials/components/FiltersDrawer/constants";
import {IndustrialUploadFile} from "./IndustrialUploadFile";
import InputSwitcher from "../../../../partials/components/InputSwitcher";
import Button from "../../../../partials/components/Button";

class IndustrialAddFile extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      name: '',
      nameEn: '',
      fileType: '',
      fileCategory: '',
      isGalleryIncluded: false,
      selectedWarehouse: ''
    };
    const {dataSource, fetchIndustrialParkWarehouses, parentId} = this.props;

    if (dataSource === 'industrialPark' && parentId) {
      fetchIndustrialParkWarehouses(parentId);
    }
  }

  handleCloseModal = () => {
    this.child.current && this.child.current.handleClose();
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  handleChangeSelect = (selected, target) => {
    this.setState({
      [target]: selected
    });
  };
  handleChangeSwitcher = name => event => {
    this.setState({
      [name]: event.target.checked
    });
  };
  handleResetForm = () => {
    this.setState({
      name: '',
      nameEn: '',
      fileType: '',
      fileCategory: '',
      isGalleryIncluded: false,
      selectedWarehouse: ''
    });
  };

  render() {
    const {
      loading,
      propertyID,
      uploadIndustrialParkFile,
      uploadIndustrialWarehouseFile,
      dataSource,
      parkWarehouses,
      parkWarehousesLoading
    } = this.props;
    const {name, nameEn, fileType, fileCategory, isGalleryIncluded, selectedWarehouse} = this.state;
    const isWarehouseSelected = dataSource === 'industrialWarehouse' || (dataSource === 'industrialPark' && selectedWarehouse !== '');
    const fileDetails = {
      name,
      nameEn,
      fileType,
      fileCategory,
      isGalleryIncluded
    };
    let warehousesOptions = [];
    if (!parkWarehousesLoading && parkWarehouses && parkWarehouses.length) {
      parkWarehouses.map(warehouse => {
        const id = warehouse.id;
        const label = warehouse.name;
        warehousesOptions.push({id, label});
        return true;
      });
    }
    if (loading) {
      return <LoadingScreen/>;
    }

    return (
      <ModalCustom
        ref={this.child}
        btn={
        <Button
          size={"slim"}
          onClick={() => this.handleResetForm()}
        >
          <FormattedMessage id="GENERAL.PHRASE.ADD_FILE"/>
        </Button>
        }
        title={<FormattedMessage id="GENERAL.PHRASE.ADD_FILE"/>}
      >
        <Grid container spacing={2} direction="column">
          <Grid item>
            <InputIcon label={<FormattedMessage id="GENERAL.PHRASE.FILE_NAME_PL"/>} name="name"
                       value={name} onChange={this.handleChange}/>
          </Grid>
          <Grid item>
            <InputIcon label={<FormattedMessage id="GENERAL.PHRASE.FILE_NAME_EN"/>} name="nameEn"
                       value={nameEn} onChange={this.handleChange}/>
          </Grid>
          <Grid item>
            <SingleSelect
              label={<FormattedMessage id="GENERAL.PHRASE.FILE_FORMAT"/>}
              inputName="fileType"
              options={FILTERS_SELECTS.INDUSTRIAL_FILES_FORMATS}
              optionsValue="id"
              optionsLabel="label"
              onSelectedValues={this.handleChangeSelect}
            />
          </Grid>
          <Grid item>
            <SingleSelect
              label={<FormattedMessage id="GENERAL.PHRASE.FILE_CATEGORY"/>}
              inputName="fileCategory"
              options={FILTERS_SELECTS.INDUSTRIAL_FILES_CATEGORIES}
              optionsValue="id"
              optionsLabel="label"
              onSelectedValues={this.handleChangeSelect}
            />
          </Grid>
          {dataSource === 'industrialPark' && warehousesOptions && warehousesOptions.length > 0 && (
            <Grid item>
              <SingleSelect
                label={<FormattedMessage id="INDUSTRIAL.PHRASE.SELECT_BUILDING_OPTIONAL"/>}
                inputName="selectedWarehouse"
                options={warehousesOptions}
                optionsValue="id"
                optionsLabel="label"
                onSelectedValues={this.handleChangeSelect}
              />
            </Grid>
          )}
          <Grid item>
            <Grid container spacing={2} alignItems="center">
              <Grid item><Typography><FormattedMessage
                id="INDUSTRIAL.PHRASE.DISPLAY_IN_GALLERY"/></Typography></Grid>
              <Grid item>
                <InputSwitcher checked={isGalleryIncluded} value="isGalleryIncluded"
                               label={<FormattedMessage id="GENERAL.PHRASE.YES"/>}
                               onChange={this.handleChangeSwitcher("isGalleryIncluded")}/>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <IndustrialUploadFile
              propertyID={(dataSource === 'industrialWarehouse' || (dataSource === 'industrialPark' && selectedWarehouse === '')) ? propertyID : selectedWarehouse}
              fileDetails={fileDetails}
              onFileUpload={isWarehouseSelected ? uploadIndustrialWarehouseFile : uploadIndustrialParkFile}
              onFormReset={() => this.handleCloseModal()}
            />
          </Grid>
        </Grid>
      </ModalCustom>
    );
  }
}

const mapStateToProps = store => ({
  loading: store.industrial.uploadParkFileLoading,
  parkWarehouses: store.industrial.parkWarehouses,
  parkWarehousesLoading: store.industrial.parkWarehousesLoading
});
const mapDispatchToProps = {
  uploadIndustrialParkFile: (parkID, file, fileDetails) => uploadIndustrialParkFile(parkID, file, fileDetails),
  uploadIndustrialWarehouseFile: (warehouseID, file, fileDetails) => uploadIndustrialWarehouseFile(warehouseID, file, fileDetails),
  fetchIndustrialParkWarehouses: (parkID) => fetchIndustrialParkWarehouses(parkID)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(IndustrialAddFile)
);
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {put, takeLatest} from "redux-saga/effects";
import axios from "axios";
import {resetFilters, setFilters} from "app/utils/filters";

const initialState = {
  items: null,
  itemsPerPage: 20,
  itemsCount: null,
  totalItems: null,
  itemsPage: 1,
  loaded: false,
  loading: false
};

export const reducer = persistReducer(
  { storage, key: "acquisitions" },
  (state = initialState, action) => {
    switch (action.type) {
      // RESET ACQUISITIONS STATES
      case 'RESET_ACQUISITIONS_LOADING_STATES_REQUEST': {
        return {
          ...state,
          loading: false
        }
      }
      //
      case 'FETCH_ACQUISITIONS_REQUEST': {
        return { ...state, loading: true }
      }
      case 'FETCH_ACQUISITIONS_RESET_REQUEST': {
        return { ...state, loading: true }
      }
      case 'FETCH_ACQUISITIONS_SUCCESS': {
        const { items, itemsCount, itemsPerPage, itemsPage, total } = action.payload;
        return { ...state, items, itemsCount, itemsPerPage, itemsPage, totalItems: total, loaded: true, loading: false }
      }

      default:
        return state;
    }
  }
);

// FETCH DATA
function* fetchAcquisitions(action) {
  const params = action.payload.params;
  const filters = setFilters(params, 'transaction_date'); //transaction_date
  try {
    const acquisitions = yield axios.get(`/acquisitions/${filters}`);
    yield put({ type: "FETCH_ACQUISITIONS_SUCCESS", payload: acquisitions.data || [{ error: acquisitions.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchAcquisitionsReset(action) {
  const initOrderBy = action.payload;
  const filtersReset = resetFilters(initOrderBy);
  try {
    const acquisitions = yield axios.get(`/acquisitions/${filtersReset}`);
    yield put({ type: "FETCH_ACQUISITIONS_SUCCESS", payload: acquisitions.data || [{ error: acquisitions.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}

export function* saga() {
  yield takeLatest('FETCH_ACQUISITIONS_REQUEST', fetchAcquisitions);
  yield takeLatest('FETCH_ACQUISITIONS_RESET_REQUEST', fetchAcquisitionsReset);
}

import React from "react";
import {FormattedMessage} from "react-intl";
import {Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import styles from "../index.module.scss";
import clsx from "clsx";

// Confirmation Message View after successful password change
export default class SetUserPaddwordConfirmation extends React.Component {
  render() {
    const {isFirstLogin} = this.props;

    return (
      <>
        <div className="kt-login__body">
          <div className="kt-login__form">
            <div className="kt-login__title">
              <Typography variant="h3" style={{marginBottom: 15}}>
                <FormattedMessage
                  id={isFirstLogin ? "AUTH.FORGOT.PASSWORD_HAS_BEEN_SET" : "AUTH.FORGOT.PASSWORD_HAS_BEEN_CHANGED"}
                />
              </Typography>
              <Typography>
                <FormattedMessage
                  id={isFirstLogin ? "AUTH.FORGOT.PASSWORD_HAS_BEEN_SET_MESSAGE" : "AUTH.FORGOT.PASSWORD_HAS_BEEN_CHANGED_MESSAGE"}
                  values={{breakingLine: <br/>}}
                />
              </Typography>
            </div>

            <div className={styles.contentActions} style={{marginTop: 50, justifyContent: "center"}}>
              <Link to="/auth">
                <button type="button" className={clsx("btn btn-primary btn-elevate kt-login__btn-primary", styles.btnSubmit)}>
                  <FormattedMessage id="FORM.BUTTON.BACK"/>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}
import styles from "./index.module.scss";
import React, {Fragment, useCallback, useRef, useState} from "react";
import cn from "classnames";
import {useResizeDetector} from "react-resize-detector";
import {toDateFormat, toNumberFormat} from "app/utils/helpers";
import {FormattedMessage} from "react-intl";
import {UNIT_STATUS} from "../../../constants";
import _ from "lodash";
import Button from "../Button";
import Icon from "../Icon";

const initialSvgPreviewColor = "#ff4626";
const initialSvgPreviewActiveColor = "#ff4626";
const initialSvgEditModeColor = "#68ff00";
const initialSvgEditModeActiveColor = "#ff4626";

const getPolygonPointsString = (points) => {
  if (!points) {
    return "0,0";
  }
  let pointsString = "";
  points.forEach((point) => {
    pointsString += `${point.x},${point.y} `;
  });
  return pointsString;
};
const interpolate = (a, b, f) => {
  const nx = a.x + (b.x - a.x) * f;
  const ny = a.y + (b.y - a.y) * f;
  return {x: nx, y: ny};
};

export default function IpiHandler(props) {
  const svgRef = useRef();
  const ipiWrapper = useRef();
  const [dimensions, setDimensions] = useState({
    width: props.imageRef.current?.offsetWidth,
    height: props.imageRef.current?.offsetHeight,
  });
  const [popupsVisible, setPopupVisible] = useState(null);
  const [zoom, setZoom] = useState(1);

  const planOriginalWidth = props.imageRef?.current?.naturalWidth;
  const planOriginalHeight = props.imageRef?.current?.naturalHeight;
  const handleSVGViewBoxResize = useCallback(() => {
    if (props.imageRef.current?.offsetWidth) {
      const imgWidth = props.imageRef.current?.offsetWidth;
      const imgHeight = props.imageRef.current?.offsetHeight;

      setDimensions({
        width: imgWidth,
        height: imgHeight,
      });
    }
  }, [props]);

  useResizeDetector({targetRef: props.imageRef, onResize: handleSVGViewBoxResize});

  const getCursorPointValues = (event) => {
    let cursorPoint = svgRef.current.createSVGPoint();
    cursorPoint.x = event.clientX;
    cursorPoint.y = event.clientY;
    cursorPoint = cursorPoint.matrixTransform(
      svgRef.current.getScreenCTM().inverse()
    );
    return cursorPoint;
  };
  const getCursorPolygonPoint = (x, y) => {
    let cursorPoint = svgRef.current.createSVGPoint();
    cursorPoint.x = x;
    cursorPoint.y = y;
    cursorPoint = cursorPoint.matrixTransform(
      svgRef.current.getScreenCTM().inverse()
    );
    return cursorPoint;
  };

  const trackMouseMovement = (start, stop) => {
    document.addEventListener("mousemove", start);
    document.addEventListener("touchmove", start);
    document.addEventListener("mouseup", stop);
    document.addEventListener("touchend", stop);
  };
  const stopTrackMouseMovement = (start, stop) => {
    document.removeEventListener("mousemove", start);
    document.removeEventListener("touchmove", start);
    document.removeEventListener("mouseup", stop);
    document.removeEventListener("touchend", stop);
  };

  const dragPolygon = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const initMouseX = event?.clientX;
    const initMouseY = event?.clientY;
    const initSvgPoint = getCursorPolygonPoint(initMouseX, initMouseY);

    const startDragPolygon = (event) => {
      event.preventDefault();
      event.stopPropagation();
      const currentMouseX = event?.clientX;
      const currentMouseY = event?.clientY;
      const currentSvgPoint = getCursorPolygonPoint(
        currentMouseX,
        currentMouseY
      );
      const diffSvgPointX = initSvgPoint?.x - currentSvgPoint?.x;
      const diffSvgPointY = initSvgPoint?.y - currentSvgPoint?.y;

      const newAreaPoints = props.polygons.map((polygon) => {
        const isActivePolygon = polygon?.unitId === props?.unitId;

        if (isActivePolygon) {
          return {
            unitId: polygon?.unitId,
            points: polygon?.points.map((p) => {
              const pointX = p?.x - diffSvgPointX;
              const pointY = p?.y - diffSvgPointY;

              return {
                x: pointX,
                y: pointY,
              };
            })
          };
        }
        return polygon;
      });
      props.updatePoints(newAreaPoints);
    };

    const stopDragPolygon = () => {
      stopTrackMouseMovement(startDragPolygon, stopDragPolygon);
    };

    trackMouseMovement(startDragPolygon, stopDragPolygon);
  };
  const dragPolygonPoint = (event, index) => {
    event.preventDefault();
    event.stopPropagation();

    const startDragPoint = (event) => {
      event.preventDefault();
      event.stopPropagation();

      const cursorPoint = getCursorPointValues(event);
      const newAreaPoints = props.polygons.map((polygon) => {
        const isActivePolygon = polygon?.unitId === props?.unitId;

        if (isActivePolygon) {
          return {
            unitId: polygon?.unitId,
            points: polygon?.points.map((p, i) =>
              index === i
                ? {
                  x: Math.max(
                    Math.min(cursorPoint.x.toFixed(2), dimensions?.width),
                    0
                  ),
                  y: Math.max(
                    Math.min(cursorPoint.y.toFixed(2), dimensions?.height),
                    0
                  ),
                }
                : p
            )
          };
        }
        return polygon;
      });

      props.updatePoints(newAreaPoints);
    };
    const stopDragPoint = () => {
      stopTrackMouseMovement(startDragPoint, stopDragPoint);
    };

    trackMouseMovement(startDragPoint, stopDragPoint);
  };
  const addPoint = (event) => {
    event.stopPropagation();
    const newPoint = getCursorPointValues(event);

    const distance = (p) => {
      return Math.sqrt(
        Math.pow(newPoint.x - p.x, 2) + Math.pow(newPoint.y - p.y, 2)
      );
    };

    const newAreaPoints = props.polygons.map((polygon) => {
      const isActivePolygon = polygon?.unitId === props?.unitId;

      if (isActivePolygon) {
        let polygonPoints = polygon?.points;
        let closestPoint = 0;
        let closestDistance = Number.MAX_VALUE;
        let numPoints = polygonPoints.length;

        for (let i = 0; i < numPoints; i++) {
          let curr = polygonPoints[i];
          let next = polygonPoints[i + 1 === numPoints ? 0 : i + 1];
          for (let n = 0; n < 1; n += 0.05) {
            let interpolatedDist = distance(interpolate(curr, next, n));
            if (interpolatedDist < closestDistance) {
              closestPoint = i;
              closestDistance = interpolatedDist;
            }
          }
        }
        polygonPoints.splice(closestPoint + 1, 0, newPoint);

        return {
          unitId: polygon?.unitId,
          points: polygonPoints
        };
      }
      return polygon;
    });

    props.updatePoints(newAreaPoints);
  };
  const removePoint = (event, index) => {
    event.stopPropagation();
    const newAreaPoints = props.polygons.map((polygon) => {
      const isActivePolygon = polygon?.unitId === props?.unitId;

      if (isActivePolygon) {
        let polygonPoints = polygon?.points;

        if (polygonPoints.length > 3) {
          polygonPoints.splice(index, 1);

          return {
            unitId: polygon?.unitId,
            points: polygonPoints
          };
        }
      }
      return polygon;
    });

    props.updatePoints(newAreaPoints);
  };

  let pos = {top: 0, left: 0, x: 0, y: 0};
  const mouseDownHandler = (e) => {
    ipiWrapper.current.style.cursor = "grabbing";
    ipiWrapper.current.style.userSelect = "none";

    pos = {
      left: ipiWrapper.current.scrollLeft,
      top: ipiWrapper.current.scrollTop,
      x: e.clientX,
      y: e.clientY,
    };

    document.addEventListener("mousemove", mouseMoveHandler);
    document.addEventListener("mouseup", mouseUpHandler);
  };
  const mouseUpHandler = () => {
    document.removeEventListener("mousemove", mouseMoveHandler);
    document.removeEventListener("mouseup", mouseUpHandler);

    ipiWrapper.current.style.cursor = "grab";
    ipiWrapper.current.style.removeProperty("user-select");
  };
  const mouseMoveHandler = (e) => {
    const dx = e.clientX - pos.x;
    const dy = e.clientY - pos.y;

    ipiWrapper.current.scrollTop = pos.top - dy;
    ipiWrapper.current.scrollLeft = pos.left - dx;
  };

  const handleActiveUnitChange = (unitId) => {
    const {onActiveUnitChange, editMode} = props;

    editMode && onActiveUnitChange && unitId && onActiveUnitChange(unitId);
  }
  const handleZoomLevel = direction => {
    const zoomTo = direction === 'zoomIn' ? +0.1 : direction === 'zoomOut' && -0.1;
    const newZoom = direction === 'reset' ? 1 : Number(Number(zoom + zoomTo).toFixed(1));

    setZoom(newZoom);
  }

  return (
    <div className={styles.ipiContainer}>
      <div
        ref={ipiWrapper}
        className={cn(styles.ipiWrapper, (props?.sizeModeOrigin || props?.editMode) && styles.origin)}
        onMouseDown={mouseDownHandler}
      >
        {props?.image &&
          <div className={cn(styles.ipiDrawBoxWrapper, props?.editMode && styles.editMode, zoom > 1 && styles.zoomMode)}
               style={{"--zoom": `scale(${zoom})`}}>
            <img
              ref={props.imageRef}
              src={props?.image}
              alt="IPI Background"
              className={styles.ipiBgImage}
            />
            <svg
              width={dimensions?.width}
              height={dimensions?.height}
              viewBox={`0 0 ${planOriginalWidth || 0} ${planOriginalHeight || 0}`}
              className={styles.svgDesktop}
              ref={svgRef}
            >
              <defs>
                <linearGradient
                  id="diagonalHatch"
                  gradientUnits="userSpaceOnUse"
                  x2="30"
                  spreadMethod="repeat"
                  gradientTransform="rotate(45)"
                >
                  <stop offset="0" stopColor="#ffb822"/>
                  <stop offset="0.5" stopColor="#ffb822"/>
                  <stop offset="0.5" stopColor="#FF4626"/>
                  <stop offset="1.0" stopColor="#FF4626"/>
                </linearGradient>
              </defs>
              {props?.polygons.length && props.polygons.map((polygon) => {
                const polygonID = polygon?.unitId;
                const polygonPoints = polygon?.points;

                if (polygonPoints) {
                  const isActivePolygon = props?.unitId === polygonID;
                  const isEditable = props?.editMode && isActivePolygon;
                  const hoverPolygonData = props?.units?.find(item => item?.id === polygonID);

                  return (
                    <Fragment key={getPolygonPointsString(polygonPoints)}>
                      <polygon
                        className={cn(styles.polygon, !isEditable && styles.staticPolygon, props?.editMode && !isActivePolygon && styles.polygonCanBeActive)}
                        points={getPolygonPointsString(polygonPoints)}
                        fill={(isEditable || isActivePolygon) ? `url(#diagonalHatch)` : props?.editMode ? initialSvgEditModeColor : initialSvgPreviewColor}
                        fillOpacity={isEditable ? "20%" : "50%"}
                        stroke={isEditable ? initialSvgEditModeActiveColor : isActivePolygon ? initialSvgPreviewActiveColor : props?.editMode ? initialSvgEditModeColor : initialSvgPreviewColor}
                        strokeWidth={isEditable ? "2px" : "3px"}
                        onDoubleClick={(e) => isEditable && addPoint(e)}
                        onMouseDown={(e) => isEditable && dragPolygon(e)}
                        onMouseEnter={() => !isEditable && setPopupVisible(hoverPolygonData)}
                        onMouseLeave={() => setPopupVisible(null)}
                        onClick={() => handleActiveUnitChange(polygonID)}
                      />
                      {isEditable &&
                        polygonPoints.map((point, index) => (
                          <circle
                            key={index}
                            cx={point.x}
                            cy={point.y}
                            r="6"
                            fill={initialSvgEditModeActiveColor}
                            fillOpacity="100%"
                            stroke={initialSvgEditModeActiveColor}
                            onMouseDown={(event) =>
                              dragPolygonPoint(
                                event,
                                index
                              )
                            }
                            onDoubleClick={(event) =>
                              removePoint(event, index)
                            }
                          />
                        ))}
                    </Fragment>
                  );
                }
                return null;
              })}
            </svg>
          </div>
        }
      </div>
      <div
        className={cn(styles.ipiPopup, !_.isEmpty(popupsVisible) && styles.active)}
      >
        <table>
          <tbody>
          <tr>
            <td><FormattedMessage id="BUILDING.PHRASE.AREA"/></td>
            <td>{toNumberFormat(popupsVisible?.area, "area")}</td>
          </tr>
          {props?.editMode ?
            <tr>
              <td colSpan={2} style={{borderRight: 0, textAlign: "center"}}><FormattedMessage
                id="INDUSTRIAL.PHRASE.IPI_CLICK_TO_EDIT_INFO"/></td>
            </tr> :
            <>
              <tr>
                <td><FormattedMessage id="BUILDING.PHRASE.TYPE"/></td>
                <td>
                  {popupsVisible?.isOffice
                    ? <FormattedMessage id="INDUSTRIAL.PHRASE.UNIT_IPI_OFFICE"/>
                    : <FormattedMessage id="INDUSTRIAL.PHRASE.UNIT_IPI_WAREHOUSE"/>}
                </td>
              </tr>
              <tr>
                <td><FormattedMessage id="BUILDING.PHRASE.STATUS"/></td>
                <td>{
                  popupsVisible?.status === UNIT_STATUS.AVAILABLE ?
                    <FormattedMessage id="GENERAL.PHRASE.AVAILABLE"/> :
                    popupsVisible?.status === UNIT_STATUS.RESERVED ?
                      <FormattedMessage id="GENERAL.PHRASE.RESERVED"/> :
                      popupsVisible?.status === UNIT_STATUS.UNAVAILABLE &&
                      <FormattedMessage id="GENERAL.PHRASE.UNAVAILABLE"/>
                }</td>
              </tr>
              <tr>
                <td><FormattedMessage id="BUILDING.PHRASE.AVAILABLE_FROM"/></td>
                <td>
                  {toDateFormat(popupsVisible?.availableFrom, null, true)}
                </td>
              </tr>
              <tr>
                <td><FormattedMessage id="BUILDING.PHRASE.UPDATED_AT"/></td>
                <td>{toDateFormat(popupsVisible?.updatedAt)}</td>
              </tr>
              <tr>
                <td><FormattedMessage id="INDUSTRIAL.PHRASE.DOCKS"/></td>
                <td>{toNumberFormat(popupsVisible?.docks)}</td>
              </tr>
            </>
          }
          </tbody>
        </table>
      </div>
      {zoom !== 1 &&
        <div className={styles.ipiSidebarActions}>
          <Button
            type={"icon"}
            tooltip={"Zoom In"}
            tooltipPlacement="left"
            onClick={() => handleZoomLevel('zoomIn')}
          >
            <Icon icon={"add-circle"}/>
          </Button>
          <Button
            type={"icon"}
            color={zoom === 1 ? "simple" : "danger"}
            tooltip={"Zoom Out"}
            tooltipPlacement="left"
            inactive={zoom === 1}
            onClick={() => handleZoomLevel('zoomOut')}
          >
            <Icon icon={"minus-circle"}/>
          </Button>
          <Button
            type={"icon"}
            color={zoom === 1 ? "simple" : "danger"}
            tooltip={"Reset"}
            tooltipPlacement="left"
            inactive={zoom === 1}
            onClick={() => handleZoomLevel('reset')}
          >
            <Icon icon={"refresh"}/>
          </Button>
          <div className={styles.zoomActionsLabel}>{`${zoom}/1`}</div>
        </div>
      }
    </div>
  );
}

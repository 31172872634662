import React from "react";
import {Grid, Typography} from "@material-ui/core";
import {updateOffice} from "app/crud/estates/offices/offices.crud";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import InputAdornments from "app/partials/components/InputAdornments";
import {onlyNumbers} from "app/utils/helpers";
import {GoogleMaps} from "../../../GoogleMaps";
import _ from "lodash";

class EditOfficeLocation extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      addressCity: props.data.addressCity || '',
      addressStreet: props.data.addressStreet || '',
      addressZipcode: props.data.addressZipcode || '',
      district: props.data.district || '',
      geoLatitude: props.data.geoLatitude,
      geoLongitude: props.data.geoLongitude
    }
    this.initialState = this.state;
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    // Handle Change Detect
    this.props.onUnsavedData(!_.isEqual(this.initialState, this.state));
    // Handle Save
    if(!prevProps.requestSave && this.props.requestSave) {
      this.handleSave();
    }
    // Detect Office Updating
    if(prevProps.officeUpdating !== this.props.officeUpdating) {
      this.props.onRequestSaving(this.props.officeUpdating);
    }
  }

  // Handlers
  handleChange = (event) => {
    const onlyNums = onlyNumbers(event.target.value);
    const allowOnlyNumbers = event.target.name === "area" || event.target.name === "floor";
    this.setState({
      [event.target.name]: allowOnlyNumbers ? onlyNums : event.target.value
    });
  };
  handleMarkerPositionChange = (position) => {
    if(position) {
      const {latLng} = position;
      this.setState({
        geoLatitude: latLng.lat(),
        geoLongitude: latLng.lng()
      });
    }
  };
  handleSave = () => {
    const {data: {id}, updateOffice} = this.props;
    const {addressCity, addressStreet, addressZipcode, district, geoLatitude, geoLongitude} = this.state;
    const data = {
      addressCity,
      addressStreet,
      addressZipcode,
      district,
      geoLatitude,
      geoLongitude
    };
    this.initialState = this.state;
    updateOffice(id, data, "location");
  };

  render() {
    const {officeUpdating} = this.props;
    const {addressCity, addressStreet, addressZipcode, district, geoLatitude, geoLongitude} = this.state;
    let address = '';
    if (addressCity) address += addressCity;
    if (addressStreet) address += ` ${addressStreet}`;

    return(
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" style={{color: "#ff4626"}}><FormattedMessage id="VOCABULARY.Location"/></Typography>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <InputAdornments
                label={<FormattedMessage id="GENERAL.PHRASE.STREET"/>}
                name="addressStreet"
                value={addressStreet}
                onChange={this.handleChange}
                disabled={officeUpdating}
              />
            </Grid>
            <Grid item>
              <InputAdornments
                label={<FormattedMessage id="GENERAL.PHRASE.DISTRICT"/>}
                name="district"
                value={district}
                onChange={this.handleChange}
                disabled={officeUpdating}
              />
            </Grid>
            <Grid item>
              <InputAdornments
                label={<FormattedMessage id="GENERAL.PHRASE.CITY"/>}
                name="addressCity"
                value={addressCity}
                onChange={this.handleChange}
                disabled={officeUpdating}
              />
            </Grid>
            <Grid item>
              <InputAdornments
                label={<FormattedMessage id="GENERAL.PHRASE.ZIPCODE"/>}
                name="addressZipcode"
                value={addressZipcode}
                onChange={this.handleChange}
                disabled={officeUpdating}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <GoogleMaps
            address={address}
            markerDraggable={true}
            markerPosition={{
              lat: geoLatitude,
              lng: geoLongitude,
            }}
            lat={geoLatitude}
            lng={geoLongitude}
            height={500}
            draggable
            onDragEnd={this.handleMarkerPositionChange}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = store => ({
  officeUpdating: store.offices.officeUpdating
});
const mapDispatchToProps = {
  updateOffice: (officeId, data, backgroundRefresh) => updateOffice(officeId, data, backgroundRefresh)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditOfficeLocation)
);
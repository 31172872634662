export const authLogin = (email, password) => ({
  type: 'AUTH_LOGIN_REQUEST',
  payload: {email, password}
});
export const authOneTimeLogin = (otp, recipient, url) => ({
  type: 'AUTH_ONE_TIME_LOGIN_REQUEST',
  payload: {otp, recipient, url}
});
export const authResetPassword = (email) => ({
  type: 'AUTH_RESET_PASSWORD_REQUEST',
  payload: email
});
export const resetAuthLoading = () => ({
  type: 'AUTH_LOGIN_RESET_REQUEST'
});
export const leadTracking = (checksum, interaction, url) => ({
  type: 'LEAD_TRACKING_REQUEST',
  payload: {checksum, interaction, url}
});

// RESET AUTH APP LOADING STATES
export const resetAuthAppLoadingStates = () => ({
  type: 'RESET_AUTH_APP_LOADING_STATES_REQUEST'
});
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {put, takeLatest} from "redux-saga/effects";
import axios from "axios";
import {actionNotification} from "app/utils/notifications";
import {handleErrorResponse} from "app/utils/helpers";
import {setFilters} from "../../utils/filters";
import _ from "lodash";

const initialState = {
  leads: [],
  itemsPerPage: 20,
  itemsCount: null,
  total: null,
  loading: false,
  lead: null,
  loadingLead: false,
  leadsPreview: [],
  loadingLeadsPreview: false,
  updating: false,
  sending: false,
  adding: false,
  removing: false,
  addingLead: false
};
const handleRemovePropertyFromLead = (lead, dataRemove, dataStatus) => {
  const removeID = dataRemove?.offices ? dataRemove?.offices[0] : dataRemove?.parks ? dataRemove?.parks[0] : null;

  if(removeID) {
    const newStatus = dataStatus?.status;
    const filteredObjects = _.filter(lead.objects, (item) => item?.id !== removeID);
    const updatedObjects = _.update(lead, "objects", () => filteredObjects);
    if(dataStatus) {
      return _.update(updatedObjects, "offerStatus", () => newStatus);
    }
    return updatedObjects;
  }
  return lead;
}
const handleUpdateListLead = (leads, updatedLead) => {
  if(leads && updatedLead) {
    const updatedID = updatedLead?.id;
    return leads?.map(lead => {
      if(lead?.id === updatedID) {
        return updatedLead;
      }
      return lead;
    })
  }
}
const handleAddLead = (leads, newLead) => {
  if(newLead) {
    leads.unshift(newLead);
    return leads;
  }
  return leads;
}

export const reducer = persistReducer(
  { storage, key: "leadManagement" },
  (state = initialState, action) => {
    switch (action.type) {
      // RESET LEADS MANAGEMENT LOADING STATES
      case "RESET_LEADS_MANAGEMENT_LOADING_STATES_REQUEST": {
        return {
          ...state,
          loading: false,
          loadingLead: false,
          loadingLeadsPreview: false
        };
      }
      // LEADS
      case "FETCH_LEADS_REQUEST": {
        return { ...state, loading: true, leads: [] };
      }
      case "FETCH_LEADS_SUCCESS": {
        return {
          ...state,
          loading: false,
          leads: action?.payload?.items,
          itemsPerPage: action?.payload?.itemsPerPage,
          itemsCount: action?.payload?.itemsCount,
          total: action?.payload?.total
        };
      }
      case "FETCH_LEADS_FAILED": {
        return { ...state, loading: false, leads: [] };
      }
      // LEAD
      case "FETCH_LEAD_REQUEST": {
        return { ...state, loadingLead: true, lead: null };
      }
      case "FETCH_LEAD_SUCCESS": {
        return { ...state, loadingLead: false, lead: action.payload };
      }
      case "FETCH_LEAD_FAILED": {
        return { ...state, loadingLead: false, lead: null };
      }
      // LEADS PREVIEW LIST
      case "FETCH_LEADS_PREVIEW_REQUEST": {
        return { ...state, loadingLeadsPreview: true, leadsPreview: [] };
      }
      case "FETCH_LEADS_PREVIEW_SUCCESS": {
        return {
          ...state,
          loadingLeadsPreview: false,
          leadsPreview: action?.payload
        };
      }
      case "FETCH_LEADS_PREVIEW_FAILED": {
        return { ...state, loadingLeadsPreview: false, leadsPreview: [] };
      }
      // UPDATE LEAD INTERVIEW
      case "UPDATE_LEAD_INTERVIEW_FORM_REQUEST": {
        return { ...state, updating: true };
      }
      case "UPDATE_LEAD_INTERVIEW_FORM_SUCCESS": {
        return {
          ...state,
          updating: false,
          leads: handleUpdateListLead(state.leads, action.payload.updatedLead)
        };
      }
      case "UPDATE_LEAD_INTERVIEW_FORM_FAILED": {
        return { ...state, updating: false };
      }
      // UPDATE LEAD STATUS
      case "UPDATE_LEAD_STATUS_REQUEST": {
        return { ...state, updating: true };
      }
      case "UPDATE_LEAD_STATUS_SUCCESS": {
        return {
          ...state,
          updating: false
        };
      }
      case "UPDATE_LEAD_STATUS_FAILED": {
        return { ...state, updating: false };
      }
      // ADD LEAD PROPERTY
      case "ADD_LEAD_PROPERTIES_REQUEST": {
        return { ...state, adding: true };
      }
      case "ADD_LEAD_PROPERTIES_SUCCESS": {
        return { ...state, adding: false };
      }
      case "ADD_LEAD_PROPERTIES_FAILED": {
        return { ...state, adding: false };
      }
      // REMOVE LEAD PROPERTY
      case "REMOVE_LEAD_PROPERTIES_REQUEST": {
        return { ...state, removing: true };
      }
      case "REMOVE_LEAD_PROPERTIES_SUCCESS": {
        return {
          ...state,
          removing: false,
          lead: handleRemovePropertyFromLead(state?.lead, action?.payload?.removeData, action?.payload?.response),
        };
      }
      case "REMOVE_LEAD_PROPERTIES_FAILED": {
        return { ...state, removing: false };
      }
      // SEND LEAD
      case "SEND_LEAD_REQUEST": {
        return { ...state, sending: true };
      }
      case "SEND_LEAD_SUCCESS": {
        return { ...state, sending: false };
      }
      case "SEND_LEAD_FAILED": {
        return { ...state, sending: false };
      }
      // ADD LEAD
      case "ADD_LEAD_REQUEST": {
        return { ...state, addingLead: true };
      }
      case "ADD_LEAD_SUCCESS": {
        return {
          ...state,
          addingLead: false,
          leads: handleAddLead(state?.leads, action?.payload)
        };
      }
      case "ADD_LEAD_FAILED": {
        return { ...state, addingLead: false };
      }

      default:
        return state;
    }
  }
);

// FETCH DATA
function* fetchLeads(action) {
  try {
    const params = action.payload.params;
    const filters = setFilters(params);
    const leads = yield axios.get(`/lead-management/${filters}`);
    yield put({
      type: "FETCH_LEADS_SUCCESS",
      payload: leads.data || [{ error: leads.statusText }],
    });
  }
  catch (err) {
    console.log(err);
    yield put({
      type: "FETCH_LEADS_FAILED",
      payload: err.status,
    });
  }
}
function* fetchLead(action) {
  try {
    const leadID = action.payload.leadID;
    const lead = yield axios.get(`/lead-management/${leadID}/`);
    yield put({
      type: "FETCH_LEAD_SUCCESS",
      payload: lead.data || [{ error: lead.statusText }],
    });
  }
  catch (err) {
    console.log(err);
    yield put({
      type: "FETCH_LEAD_FAILED",
      payload: err.status,
    });
  }
}
function* fetchLeadsPreview() {
  try {
    const previewList = yield axios.get(`/lead-management/simple_list/`);
    yield put({
      type: "FETCH_LEADS_PREVIEW_SUCCESS",
      payload: previewList.data || [{ error: previewList.statusText }],
    });
  }
  catch (err) {
    console.log(err);
    yield put({
      type: "FETCH_LEADS_PREVIEW_FAILED",
      payload: err.status,
    });
  }
}
// ACTION
function* updateLeadInterviewForm(action) {
  const { leadID, data } = action.payload;
  try {
    const updateLead = yield axios.post(`/lead-management/${leadID}/update_form/`, data);
    yield put({
      type: "UPDATE_LEAD_INTERVIEW_FORM_SUCCESS",
      payload: {
        leadID,
        updatedLead: updateLead.data
      },
      meta: actionNotification("Lead interview has been updated.", "success"),
    });
  }
  catch (err) {
    yield put({
      type: "UPDATE_LEAD_INTERVIEW_FORM_FAILED",
      payload: err.status,
      meta: actionNotification(
        handleErrorResponse(err.data.name),
        "error"
      ),
    });
  }
}
function* updateLeadStatus(action) {
  const { leadID, data } = action.payload;
  try {
    yield axios.post(`/lead-management/${leadID}/update_status/`, data);
    yield put({
      type: "UPDATE_LEAD_STATUS_SUCCESS",
      payload: {
        leadID,
        dataStatus: data
      },
      meta: actionNotification("Lead status has been updated.", "success")
    });
  }
  catch (err) {
    yield put({
      type: "UPDATE_LEAD_STATUS_FAILED",
      payload: err.status,
      meta: actionNotification(
        handleErrorResponse(err.data.name),
        "error"
      )
    });
  }
}
function* addLeadProperties(action) {
  const { leadID, data } = action.payload;
  try {
    const addLeadProperties = yield axios.post(`/lead-management/${leadID}/add_object/`, data);
    yield put({
      type: "ADD_LEAD_PROPERTIES_SUCCESS",
      payload: addLeadProperties.data,
      meta: actionNotification("Lead properties has been updated.", "success"),
    });
  }
  catch (err) {
    yield put({
      type: "ADD_LEAD_PROPERTIES_FAILED",
      payload: err.status,
      meta: actionNotification(
        handleErrorResponse(err.data.name),
        "error"
      ),
    });
  }
}
function* removeLeadProperties(action) {
  const { leadID, data } = action.payload;
  try {
    const removeLeadProperties = yield axios.post(`/lead-management/${leadID}/remove_object/`, data);
    yield put({
      type: "REMOVE_LEAD_PROPERTIES_SUCCESS",
      payload: {
        response: removeLeadProperties.data,
        removeData: data,
        leadID
      },
      meta: actionNotification("Lead properties has been updated.", "success"),
    });
  }
  catch (err) {
    yield put({
      type: "REMOVE_LEAD_PROPERTIES_FAILED",
      payload: err.status,
      meta: actionNotification(
        handleErrorResponse(err.data.name),
        "error"
      ),
    });
  }
}
function* sendLead(action) {
  const { leadID, data } = action.payload;
  try {
    const send = yield axios.post(`/lead-management/${leadID}/send_lead/`, data);
    yield put({
      type: "SEND_LEAD_SUCCESS",
      payload: send.data,
      meta: actionNotification("Lead has been sent.", "success"),
    });
  }
  catch (err) {
    yield put({
      type: "SEND_LEAD_FAILED",
      payload: err.status,
      meta: actionNotification(
        handleErrorResponse(err.data.name),
        "error"
      ),
    });
  }
}
function* addLead(action) {
  const { data } = action.payload;
  try {
    const addLead = yield axios.post(`/lead-management/create_lead/`, data);
    yield put({
      type: "ADD_LEAD_SUCCESS",
      payload: addLead.data,
      meta: actionNotification("Lead has been created.", "success"),
    });
    window.location.reload();
  }
  catch (err) {
    yield put({
      type: "ADD_LEAD_FAILED",
      payload: err.status,
      meta: actionNotification(
        handleErrorResponse(err.data.name),
        "error"
      ),
    });
  }
}

export function* saga() {
  // FETCH
  yield takeLatest("FETCH_LEADS_REQUEST", fetchLeads);
  yield takeLatest("FETCH_LEAD_REQUEST", fetchLead);
  yield takeLatest("FETCH_LEADS_PREVIEW_REQUEST", fetchLeadsPreview);
  // ACTIONS
  yield takeLatest("UPDATE_LEAD_INTERVIEW_FORM_REQUEST", updateLeadInterviewForm);
  yield takeLatest("UPDATE_LEAD_STATUS_REQUEST", updateLeadStatus);
  yield takeLatest("ADD_LEAD_PROPERTIES_REQUEST", addLeadProperties);
  yield takeLatest("REMOVE_LEAD_PROPERTIES_REQUEST", removeLeadProperties);
  yield takeLatest("SEND_LEAD_REQUEST", sendLead);
  yield takeLatest("ADD_LEAD_REQUEST", addLead);
}

import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import OfferPage from "./OfferPage";
import {callReddClickEvent} from "app/crud/user.crud";
import {fetchOfferOffices, updateOffer} from "app/crud/offers.crud";

const mapStateToProps = (store) => ({
  data: store.shortlists.offer,
  loading: store.shortlists.offerLoading,
  deletingOffer: store.shortlists.deletingOffer,
  offerDeleted: store.shortlists.offerDeleted,
  offerUpdating: store.shortlists.offerUpdating
});
const mapDispatchToProps = {
  fetchOfferOffices: (offerID) => fetchOfferOffices(offerID),
  updateOffer: (offerID, data) => updateOffer(offerID, data),
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params, notification) => callReddClickEvent(clickType, contentType, appLabel, objectId, params, notification),
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OfferPage)
);

import React from "react";
import {toAbsoluteUrl} from "_metronic";
import {arrayOnlyUnique, toNumberFormat} from "app/utils/helpers";
import {Portlet, PortletBody} from "app/partials/content/Portlet";
import {Grid, Typography} from "@material-ui/core";
import {Avatar} from "app/partials/components/Avatar";
import styles from "app/pages/offices/OwnerPage/index.module.scss";
import {FormattedMessage} from "react-intl";
import HighlightLabel from "app/partials/components/HighlightLabel";
import IconLabel from "app/partials/components/IconLabel";

export default class OwnerHeader extends React.Component {
  render() {
    const {data} = this.props;
    const {buildingStats} = data;
    const avatar = data.logo ? data.logo : data.photo ? data.photo : toAbsoluteUrl("/media/office-placeholder.jpg");
    const displayName = data.shortName !== null ? data.shortName : data.name !== null ? data.name : 'N/A';
    // Portfolio Summary Variables
    const buildingsClasses = buildingStats.buildingClasses ? buildingStats.buildingClasses.filter(arrayOnlyUnique).join(', ') : 'N/A';
    const buildingsCertificates = buildingStats.certificates ? buildingStats.certificates.filter(arrayOnlyUnique).join(', ') : 'N/A';
    const commissioningYearsArray = buildingStats.commissioningYears ? buildingStats.commissioningYears.filter(arrayOnlyUnique).filter(item => item !== null).sort() : [];
    const commissioningYears = `${Math.min(...commissioningYearsArray)} - ${Math.max(...commissioningYearsArray)}`;
    const displayWww = data.website ? data.website.replace("http://", "").replace("https://", "").replace("www.", "") : 'N/A';

    return(
      <Portlet>
        <PortletBody>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <Grid container justifyContent="space-between" wrap="nowrap">
                <Grid item>
                  <Grid container alignItems="center" spacing={2} wrap="nowrap">
                    <Grid item>
                      <Avatar avatar={avatar} alt={displayName} size={110}/>
                    </Grid>
                    <Grid item>
                      <Typography variant="h1">{displayName}</Typography>
                      <Typography>{data.addressZipcode}</Typography>
                      <Typography>{data.addressCity}</Typography>
                      <Typography>{data.addressStreet}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container justifyContent="flex-end" spacing={3} wrap="nowrap">
                    <Grid item>
                      <Typography variant="h4" className={styles.buildingHeaderLabel}><FormattedMessage id="OWNERS.PHRASE.MAIN_MARKET"/></Typography>
                      <HighlightLabel color="success">{data.mainMarket}</HighlightLabel>
                    </Grid>
                    <Grid item>
                      <Typography variant="h4" className={styles.buildingHeaderLabel}><FormattedMessage id="FORM.FIELD.TOTAL_SALES"/></Typography>
                      <HighlightLabel color="warning" tooltip="GENERAL.PHRASE.Last year">{toNumberFormat(data.totalOperatingRevenue, 'currency', 'PLN')}</HighlightLabel>
                    </Grid>
                    <Grid item>
                      <Typography variant="h4" className={styles.buildingHeaderLabel}><FormattedMessage id="OWNERS.PHRASE.AVERAGE_RENT_FEE"/></Typography>
                      <HighlightLabel color="danger">{toNumberFormat(data.avgRentFee.toFixed(2), 'currency')}</HighlightLabel>
                    </Grid>
                    <Grid item>
                      <Typography variant="h4" className={styles.buildingHeaderLabel}><FormattedMessage id="OWNERS.PHRASE.MIN_RENTAL_PERIOD"/></Typography>
                      <HighlightLabel color="neutral">{data.minRentalPeriod} <FormattedMessage id="GENERAL.PHRASE.YEARS"/></HighlightLabel>
                    </Grid>
                    <Grid item>
                      <Typography variant="h4" className={styles.buildingHeaderLabel}><FormattedMessage id="FORM.FIELD.AVG_VACANCY_EXIST"/></Typography>
                      <HighlightLabel tooltip="FORM.FIELD.AVG_VACANCY_EXIST_DESC">{toNumberFormat(data.totalVacancy, '%')}</HighlightLabel>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <span className={styles.separator}></span>
            </Grid>
            <Grid item>
              <Grid container spacing={1} justifyContent="space-between">
                <Grid item xs={2}>
                  <IconLabel icon="apps" label={<FormattedMessage id="OWNERS.PHRASE.TOTAL_PORTFOLIO_SPACE"/>} value={toNumberFormat(data.totalAreaNetto, 'area')}/>
                </Grid>
                <Grid item xs={2}>
                  <IconLabel icon="language" label={<FormattedMessage id="OWNERS.PHRASE.TOTAL_MARKETS"/>} value={toNumberFormat(data.markets)}/>
                </Grid>
                <Grid item xs={2}>
                  <IconLabel icon="apartment" label={<FormattedMessage id="OWNERS.PHRASE.BUILDINGS_CLASSES"/>} value={buildingsClasses}/>
                </Grid>
                <Grid item xs={2}>
                  <IconLabel icon="event" label={<FormattedMessage id="OWNERS.PHRASE.COMMISSIONING_YEARS"/>} value={commissioningYears}/>
                </Grid>
                <Grid item xs={2}>
                  <IconLabel icon="eco" label={<FormattedMessage id="OWNERS.PHRASE.BUILDINGS_CERTIFICATES"/>} value={buildingsCertificates}/>
                </Grid>
                <Grid item xs={2}>
                  <IconLabel icon="link" label="www" value={displayWww}/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          </PortletBody>
      </Portlet>
    );
  }
}
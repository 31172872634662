// FETCH ACTIONS
export const fetchOwners = (params) => ({
  type: 'FETCH_OWNERS_REQUEST',
  payload: {
    params
  },
});
export const fetchOwnersReset = () => ({
  type: 'FETCH_OWNERS_RESET_REQUEST'
});
export const fetchOwner = (ownerId) => ({
  type: 'FETCH_OWNER_REQUEST',
  payload: ownerId,
});
export const fetchOwnerPortfolio = (ownerId) => ({
  type: 'FETCH_OWNER_PORTFOLIO_REQUEST',
  payload: ownerId,
});
export const fetchOwnerBoard = (ownerId) => ({
  type: 'FETCH_OWNER_BOARD_REQUEST',
  payload: ownerId,
});
export const fetchOwnerFinances = (ownerId, filters) => ({
  type: 'FETCH_OWNER_FINANCES_REQUEST',
  payload: {
    ownerId,
    filters
  },
});
export const fetchOwnerLoans = (ownerId) => ({
  type: 'FETCH_OWNER_LOANS_REQUEST',
  payload: ownerId,
});
export const fetchOwnerPhotos = (ownerId) => ({
  type: 'FETCH_OWNER_PHOTOS_REQUEST',
  payload: ownerId,
});
export const fetchOwnerUnitsTracking = (ownerId) => ({
  type: 'FETCH_OWNER_UNITS_TRACKING_REQUEST',
  payload: ownerId,
});
export const fetchOwnerLeasingTeams = (ownerId) => ({
  type: 'FETCH_OWNER_LEASING_TEAMS_REQUEST',
  payload: ownerId,
});
export const fetchOwnerSectors = (ownerId) => ({
  type: 'FETCH_OWNER_SECTORS_REQUEST',
  payload: ownerId,
});
export const fetchOwnerVacancyRate = (ownerId) => ({
  type: 'FETCH_OWNER_VACANCY_RATE_REQUEST',
  payload: ownerId,
});
export const fetchOwnerReddIndex = (ownerId) => ({
  type: 'FETCH_OWNER_REDD_INDEX_REQUEST',
  payload: ownerId,
});
export const fetchOwnerLeasingContact = (ownerId) => ({
  type: 'FETCH_OWNER_LEASING_CONTACT_REQUEST',
  payload: ownerId,
});
export const fetchOwnerAcquisitions = (ownerId) => ({
  type: 'FETCH_OWNER_ACQUISITIONS_REQUEST',
  payload: ownerId,
});

// RESET OWNERS LOADING STATES
export const resetOwnersLoadingStates = () => ({
  type: 'RESET_OWNERS_LOADING_STATES_REQUEST'
});

import React, {Component} from "react";
import LoadingScreen from "app/partials/components/LoadingScreen";
import {FormattedMessage} from "react-intl";
import INDUSTRIAL_FILES_FORMATS from "../../../../constants/INDUSTRIAL_FILES_FORMATS";
import {getFieldByIdFromObject} from "../../../../utils/helpers";
import Button from "../../../../partials/components/Button";

export class IndustrialUploadFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
    this.fileInputRef = React.createRef();
  }
  triggerInputFile = () => {
    this.fileInputRef.current.click();
  };

  handleSelectFiles = e => {
    const {propertyID, fileDetails, onFileUpload, onFormReset} = this.props;
    const { target } = e;
    if (target.files) {
      Object.values(target.files).forEach((file) => {
        this.reader = new FileReader();
        this.setState({
          loading: true
        });
        this.reader.onload = readerEvent => {
          onFileUpload(
              propertyID,
              this.fileInputRef.current.files[0],
              fileDetails
          );
          onFormReset && onFormReset();
          this.setState({
            loading: false
          });
        };
        this.reader.readAsArrayBuffer(file);
      });
    }
  };

  handleAllowedFormats = () => {
    const {fileDetails} = this.props;
    return getFieldByIdFromObject(INDUSTRIAL_FILES_FORMATS, fileDetails.fileType, 'format');
  };

  render() {
    const {fileDetails} = this.props;
    const isReadyToUpload = fileDetails && (fileDetails.name && fileDetails.nameEn && fileDetails.fileType !== '' && fileDetails.fileCategory !== '');
    return (
      <form>
        <input style={{ display: 'none' }} ref={this.fileInputRef} type="file" accept={this.handleAllowedFormats()} multiple onChange={this.handleSelectFiles} />
        {this.state.loading ? <LoadingScreen/> : (
          <Button
            color={"success"}
            inactive={!isReadyToUpload}
            onClick={this.triggerInputFile}
          >
            <FormattedMessage id="GENERAL.PHRASE.SELECT_FILE"/>
          </Button>
        )}
      </form>
    );
  }
}
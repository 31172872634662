import React from 'react';
import clsx from 'clsx';
import styles from './index.module.scss';

export default class NavItem extends React.Component {
  render() {
    const {title, label, icon, onClick, name} = this.props;
    return(
      <div className={clsx("kt-notification__item", styles.navItem)} onClick={onClick} data-name={name}>
        {icon &&
          <div className="kt-notification__item-icon">
            {icon}
          </div>
        }
        <div className="kt-notification__item-details">
          <div className="kt-notification__item-title kt-font-bold">
            {title}
          </div>
          {label &&
            <div className="kt-notification__item-time">
              {label}
            </div>
          }
        </div>
      </div>
    );
  }
}
import React from "react";
import styles from "../../../../../common/external/components/index.module.scss";
import IconColorMUI from "app/partials/components/IconColorMUI";
import {FormattedMessage} from "react-intl";
import {snakeToCamelCase} from "../../../../../../utils/helpers";

export default class OffersOnlineSingleAmenities extends React.Component {
  render() {
    const { data, intl } = this.props;
    if (data.length) {
      const dataSorted = data && data.sort((a, b) => b.length - a.length);
      return (
        <div ref={this.props.reference} className={styles.boxContainer}>
          <h2>
            <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.AMENITIES" />
          </h2>
          <div className={styles.amenitiesWrapper}>
            {dataSorted.map((amenity) => (
              <span key={amenity} className={styles.amenityItem}>
                <IconColorMUI icon="done" />
                {intl.formatMessage({ id: snakeToCamelCase(amenity) })}
              </span>
            ))}
          </div>
        </div>
      );
    }
    return false;
  }
}

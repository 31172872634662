// Set Filter Params
export const filterByModules = (params) => ({
  type: 'FILTER_BY_MODULE',
  filters: params
});
// Reset Filter Params
export const filterReset = (filtersToOmit = null, currentApplicationView = null, fullReset = false) => ({
  type: 'FILTER_RESET',
  filtersToOmit,
  currentApplicationView,
  fullReset
});

import React from "react";
import {FormattedMessage} from "react-intl";
import {callReddClickEvent} from "../../../../../crud/user.crud";
import {fetchOfficeComparisonXls} from "../../../../../crud/estates/offices/officeBuilding.crud";
import connect from "react-redux/es/connect/connect";
import PaywallModal from "../../../../../partials/components/PayWall/components/PaywallModal";
import IsPro from "../../../../../utils/IsPro";
import {downloadFileFromUrl, toDateFormat} from "../../../../../utils/helpers";
import {toAbsoluteUrl} from "../../../../../../_metronic";
import Button from "../../../../../partials/components/Button";
import Icon from "../../../../../partials/components/Icon";

function PropertyComparisonXlsDownload({basic, xlsLoading, fetchOfficeComparisonXls, reddClick, ...props}) {
  const isDummyViewActive = props?.isDummyViewActive;
  // Handlers
  const handleDownloadXls = () => {
    const officeID = basic?.id;
    const officeName = basic?.name;

    if(isDummyViewActive) {
      const currentDate = new Date();
      const dateFormatted = `${toDateFormat(currentDate).replaceAll(".", "-")}`;
      const xlsFile = toAbsoluteUrl("media/placeholders/redd-tower/redd-office-comparison-redd-tower-demo-dummy-data.xlsx");
      const fileName = `redd-office-comparison-redd-tower-${dateFormatted}`;
      downloadFileFromUrl(xlsFile, fileName);
    }
    else if(!xlsLoading && fetchOfficeComparisonXls && officeID) {
      callReddClickEvent(
        reddClick,
        'office',
        null,
        officeID
      );
      fetchOfficeComparisonXls(officeID, officeName);
    }
  }

  return(
    <IsPro
      paywall
      paywallComponent={
      <PaywallModal
        triggerBtn={
        <Button
          color={"simple"}
          textIcon={<Icon icon={"download"} size={"1.1rem"}/>}
        >
          <FormattedMessage id="LEADS.PHRASE.DOWNLOAD_XLS"/>
        </Button>
        }
      />
      }
      overwriteRule={isDummyViewActive}
    >
      <Button
        color={"simple"}
        textIcon={<Icon icon={"download"} size={"1.1rem"}/>}
        loading={xlsLoading}
        onClick={() => handleDownloadXls()}
      >
        <FormattedMessage id="LEADS.PHRASE.DOWNLOAD_XLS"/>
      </Button>
    </IsPro>
  );
}

const mapStateToProps = store => ({
  basic: store.officeBuilding.basic,
  xlsLoading: store.officeBuilding.xlsLoading
});
const mapDispatchToProps = {
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params),
  fetchOfficeComparisonXls: (officeID, officeName) => fetchOfficeComparisonXls(officeID, officeName)
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertyComparisonXlsDownload);
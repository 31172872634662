import React, {Component} from "react";
import {Typography} from "@material-ui/core";
import styles from "./index.module.scss";
import clsx from "clsx";
import {FormattedMessage} from "react-intl";

export class InputUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
    this.fileInputRef = React.createRef();
    this.reader = new FileReader();
  }


  handleSave = () => {
    if (this.fileInputRef.current && this.fileInputRef.current.files.length > 0) {
      this.setState({ loading: true });
      const file = this.fileInputRef.current.files[0];

      this.props.onFileUpload(file);
    }
  };

  triggerInputFile = () => {
    this.fileInputRef.current.click();
  };

  onChangePlan = e => {
    const { target } = e;
    if (target.files && target.files.length > 0) {
      this.reader.onload = readerEvent => {
        this.setState({ fileData: readerEvent.target.result }, () => this.handleSave());
      };
      this.reader.onerror = z => {
        console.log('error', z);
      };
      this.reader.readAsDataURL(target.files[0]);
    }
  };

  renderUploadPlanBox = () => {
    const { fileData } = this.state;
    const { currentFile, placeholder, fileTarget } = this.props;
    if (fileData || currentFile) {
      return (
        <img src={fileData || currentFile} alt="" onClick={this.triggerInputFile} />
      );
    }
    return (
      <>
        {placeholder ? (
          <>
            <span style={{fontSize: 20}}>{placeholder}</span>
            <Typography variant="body2">
              {fileTarget === "userAvatar" ? <FormattedMessage id="USER.PHRASE.UPLOAD_AVATAR" values={{breakingLine: <br/>}}/> : <FormattedMessage id="USER.PHRASE.UPLOAD_LOGO" values={{breakingLine: <br/>}}/>}
            </Typography>
          </>
          ) : (
          <>
            <Typography><FormattedMessage id="USER.PHRASE.CLICK_TO_SELECT_THE_FILE_TO_UPLOAD" values={{click: <FormattedMessage id="GENERAL.PHRASE.CLICK"/>}}/></Typography>
            <Typography variant="body2">(<FormattedMessage id="USER.PHRASE.ALLOWED_FORMATS"/>: <strong>.jpg</strong>, <strong>.png</strong>)</Typography>
          </>
        )}
      </>
    );
  };

  render() {
    const {width, height, noFrame, responsiveBox} = this.props;
    return (
      <form>
        <input style={{ display: 'none' }} ref={this.fileInputRef} type="file" accept=".jpg,.jpeg,.png" onChange={this.onChangePlan} />
        <div className={clsx(styles.uploadPlanBox, noFrame && (this.state.fileData || this.props.currentFile) && styles.noFrame, responsiveBox && styles.responsiveBox)} style={{width: width, height: height}} onClick={this.triggerInputFile}>
          {this.renderUploadPlanBox()}
        </div>
      </form>
    );
  }
}

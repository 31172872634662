import {fetchTeamMembers} from "app/crud/team.crud";
import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import TeamPage from "./TeamPage";
import {setLatestActiveTablePage} from "app/crud/tablePage.crud";

const mapStateToProps = (state) => ({
  teamMembers: state.team,
  loaded: state.team.loaded,
  loading: state.team.loading,
  user: state.user.data,
  isTeamOwner: state.user.data.isTeamOwner
});
const mapDispatchToProps = {
  fetchTeamMembers: ({params}) => fetchTeamMembers(params),
  setLatestActiveTablePage: (page) => setLatestActiveTablePage(page)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TeamPage)
);

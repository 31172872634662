import React from "react";
import {Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import BuildingCardPreview from "app/partials/components/_DataParts/BuildingCardPreview";
import styles from "../index.module.scss";
import {theExcerpt, toDateFormat, toNumberFormat} from "../../../../utils/helpers";
import {Link as RouterLink} from "react-router-dom";
import {ROUTES} from "../../../../constants";
import {Avatar} from "../../Avatar";
import HighlightLabel from "../../HighlightLabel";
import ModalCustom from "../../ModalCustom";
import {toAbsoluteUrl} from "../../../../../_metronic";
import OwnerCardPreview from "../../_DataParts/OwnerCardPreview";
import {updateUserMarket} from "../../../../crud/user.crud";
import LoadingScreen from "app/partials/components/LoadingScreen";
import TransactionTypePreview from "../../_DataParts/TransactionTypePreview";
import TooltipCustom from "../../TooltipCustom";
import {callReddClickEvent} from "app/crud/user.crud";

class TableAcquisitionCells extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
  }

  // Handlers
  handleTrackClicks = (target, id, paramID) => {
    const {callReddClickEvent} = this.props;
    const eventName = target === "building" ? "acquisitions-page-building-click" :
      target === "buyer" ? "acquisitions-page-buyer-click" :
        target === "seller" && "acquisitions-page-seller-click";
    const eventParams = {
      ...target === "building" && paramID ? {propertyID: paramID} : {},
      ...target === "buyer" && paramID ? {ownerID: paramID} : {},
      ...target === "seller" && paramID ? {ownerID: paramID} : {}
    };
    if(eventName) {
      callReddClickEvent(
        eventName,
        "acquisition",
        null,
        id ? id : null,
        {...eventParams}
      );
    }
  }
  // Helpers
  renderSellerBuyerData = (data, isSingle, type, buildingName, acquisitionID) => {
    if (data && data.length) {
      const isBuyer = type === 'buyer';
      const clickTarget = isBuyer ? "buyer" : "seller";
      if (isSingle) {
        const {logo, id, shortName, name} = data[0];
        const companyLogo = logo ? logo : null;
        const nameExcerpt = name && theExcerpt(name, 30);
        const anyName = shortName ? shortName : nameExcerpt;
        if (id) {
          return (
            <span
              onClick={() => this.handleTrackClicks(clickTarget, acquisitionID, id)}
            >
              <RouterLink to={id ? `${ROUTES.OWNER_PAGE}/${id}` : ""}>
                {logo ?
                  (anyName ? (
                    <TooltipCustom placement="top" title={anyName}>
                      <span><Avatar avatar={companyLogo}/></span>
                    </TooltipCustom>
                  ) : <Avatar avatar={companyLogo}/>)
                  : <Typography>{anyName ? anyName : "N/A"}</Typography>}
              </RouterLink>
            </span>
          );
        }
        return (
          logo ?
            (anyName ? (
              <TooltipCustom placement="top" title={anyName}>
                <span><Avatar avatar={companyLogo}/></span>
              </TooltipCustom>
            ) : <Avatar avatar={companyLogo}/>)
            : <Typography>{anyName ? anyName : "N/A"}</Typography>
        );
      }
      const modalTitle = buildingName ? (`${buildingName} | ${this.props.intl.formatMessage({id: isBuyer ? "ACQUISITIONS.PHRASE.BUYER" : "ACQUISITIONS.PHRASE.SELLER"})}`) :
        <FormattedMessage id={isBuyer ? "ACQUISITIONS.PHRASE.BUYER" : "ACQUISITIONS.PHRASE.SELLER"}/>;
      return (
        <ModalCustom
          ref={this.child}
          btn={<HighlightLabel color={isBuyer ? "danger" : "success"}><FormattedMessage
            id={isBuyer ? "ACQUISITIONS.PHRASE.CHECK_BUYERS" : "ACQUISITIONS.PHRASE.CHECK_SELLERS"}/></HighlightLabel>}
          title={modalTitle}
          btnStyle={{cursor: "pointer"}}
          onOpen={() => this.handleTrackClicks(clickTarget, acquisitionID)}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell><FormattedMessage id="FORM.FIELD.COMPANY_NAME"/></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data && data.length && data.map((item, index) => {
                const {logo, shortName, name} = item;
                const companyLogo = logo ? logo : null;
                const nameExcerpt = name && theExcerpt(name, 30);
                const anyName = shortName ? shortName : nameExcerpt;
                return (
                  <TableRow key={index}>
                    <TableCell>{`${index + 1}`}</TableCell>
                    <TableCell>
                      <OwnerCardPreview name={anyName ? anyName : "N/A"} thumb={companyLogo} ownerID={item.id}
                                        thumbLogo/>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </ModalCustom>
      );
    }
  };
  renderBuildingsData = (data, isSingle, acquisitionID) => {
    const {updateUserMarket} = this.props;
    const dataOffice = data?.[0]?.office;
    if (isSingle && dataOffice) {
      const {id, mainPhotoThumbnail, name, addressStreet, marketId} = dataOffice;
      const bldImg = mainPhotoThumbnail ? mainPhotoThumbnail : toAbsoluteUrl("media/office-placeholder.jpg");
      const diffBuildingMarket = marketId !== this.props.userMarket;
      return (
        <BuildingCardPreview
          name={name}
          street={addressStreet}
          thumb={bldImg}
          officeID={id}
          marketID={marketId}
          diffMarket={diffBuildingMarket}
          onMarketChange={updateUserMarket}
          onTrackOpen={() => this.handleTrackClicks("building", acquisitionID, id)}
        />
      );
    }
    const bldImgPlaceholder = dataOffice?.mainPhotoThumbnail ? dataOffice?.mainPhotoThumbnail : toAbsoluteUrl("media/office-placeholder.jpg");
    return (
      <ModalCustom
        ref={this.child}
        btn={<span style={{display: "flex", alignItems: "center"}}><Avatar avatar={bldImgPlaceholder}/><HighlightLabel
          color="neutral"><FormattedMessage id="ACQUISITIONS.PHRASE.CHECK_BUILDINGS"/></HighlightLabel></span>}
        title={<FormattedMessage id="ACQUISITIONS.PHRASE.TRANSACTION_BUILDINGS"/>}
        btnStyle={{cursor: "pointer"}}
        onOpen={() => this.handleTrackClicks("building", acquisitionID)}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell><FormattedMessage id="ACQUISITIONS.PHRASE.BUILDING"/></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.length && data.map((item, index) => {
              const building = item.office;
              const buildingImg = building.mainPhotoThumbnail ? building.mainPhotoThumbnail : null;
              const diffBuildingMarket = building.marketId !== this.props.userMarket;
              return (
                <TableRow key={index}>
                  <TableCell>{`${index + 1}`}</TableCell>
                  <TableCell>
                    <BuildingCardPreview
                      name={building.name}
                      street={building.addressStreet}
                      thumb={buildingImg}
                      officeID={building.id}
                      marketID={building.marketId}
                      diffMarket={diffBuildingMarket}
                      onMarketChange={updateUserMarket}
                      themeDark
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </ModalCustom>
    );
  };

  render() {
    const {row} = this.props;
    const acquisitionOffice = row.transactions ? row.transactions : null;
    const isSingleBuilding = acquisitionOffice && acquisitionOffice.length <= 1;
    const isSingleSeller = row.sellerCompanies && row.sellerCompanies.length <= 1;
    const isSingleBuyer = row.acquiringCompanies && row.acquiringCompanies.length <= 1;
    const finalTransactionDate = row?.transactionType?.dateOfTransaction || row.transactionDate;

    return (
      <>
        <TableCell component="th" scope="row" className={styles.wrapCell}>
          {acquisitionOffice ? this.renderBuildingsData(acquisitionOffice, isSingleBuilding, row?.id) : <LoadingScreen/>}
        </TableCell>
        <TableCell>{this.renderSellerBuyerData(row.acquiringCompanies, isSingleBuyer, 'buyer', acquisitionOffice?.[0]?.office?.name, row?.id)}</TableCell>
        <TableCell>{this.renderSellerBuyerData(row.sellerCompanies, isSingleSeller, 'seller', acquisitionOffice?.[0]?.office?.name, row?.id)}</TableCell>
        <TableCell><TransactionTypePreview type={row?.transactionType?.agreementType}/></TableCell>
        <TableCell align="right">{toDateFormat(finalTransactionDate)}</TableCell>
        <TableCell align="right">{toNumberFormat(row.share, '%')}</TableCell>
        <TableCell align="right">{toNumberFormat(row.price, 'currency')}</TableCell>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userMarket: state.user.data.market
});
const mapDispatchToProps = {
  updateUserMarket: (market, redirect) => updateUserMarket(market, redirect),
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params)
};
export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TableAcquisitionCells)
);

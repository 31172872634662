import React from "react";
import {Link, Redirect, Route, Switch} from "react-router-dom";
import ForgotPassword from "./ForgotPassword";
import Login from "./Login";
import {FormattedMessage, injectIntl} from "react-intl";
import {toAbsoluteUrl} from "../../../_metronic";
import "../../../_metronic/_assets/sass/pages/login/login-1.scss";
import {ROUTES} from "app/constants";
import SetUserPassword from "app/pages/auth/PasswordReset/SetUserPassword";
import {Typography} from "@material-ui/core";
import PageMessages from "app/partials/components/PageMessages";
import {destroyNotification} from "app/constants/actionCreators/notifications";
import connect from "react-redux/es/connect/connect";
import styles from "./index.module.scss";
import {resetAuthLoading} from "app/crud/authApp.crud";
import {
  createRealTimeEmailSignInLog,
  createRealTimeEmailWeeklySummaryLog
} from "app/crud/offers.crud";
import {
  createRealTimeEmailIndustrialFirstOpenLog,
  createRealTimeEmailIndustrialSignInLog,
  createRealTimeEmailIndustrialWeeklySummaryLog
} from "app/crud/estates/industrial/offers-industrial.crud";
import {getParameterByName, handleEmptyValue} from "../../utils/helpers";

// Main Component Wrapper - before Signing In to application
class AuthPage extends React.Component {
  constructor(props) {
    super(props);
    props.resetAuthLoading();
    const realTimeMailSourceUrl = localStorage.getItem("redirectSourceUrl");
    const redirectApplicationView = getParameterByName('applicationView', realTimeMailSourceUrl);
    // Save ApplicationView redirect value
    if(redirectApplicationView) {
      localStorage.setItem("redirectApplicationView", redirectApplicationView);
    }

    // OFFICES - Log Unit Add to Offer mail button click
    if(realTimeMailSourceUrl && getParameterByName('redirect', realTimeMailSourceUrl) === 'offers-analysis') {
      const token = handleEmptyValue(getParameterByName('token', realTimeMailSourceUrl), true);
      const officePk = handleEmptyValue(getParameterByName('office_pk', realTimeMailSourceUrl), true);
      const userThankingEmail = handleEmptyValue(getParameterByName('user_thanking_email', realTimeMailSourceUrl));
      const userWhereSent = handleEmptyValue(getParameterByName('user_where_sent', realTimeMailSourceUrl));
      const data = {
        token,
        officePk,
        userThankingEmail,
        userWhereSent
      };
      props.createRealTimeEmailSignInLog(data);
    }
    // OFFICES - Log Offer Weekly Summary mail button click
    if(realTimeMailSourceUrl && getParameterByName('redirect', realTimeMailSourceUrl) === 'offers-analysis' && getParameterByName('salt', realTimeMailSourceUrl)) {
      const token = handleEmptyValue(getParameterByName('token', realTimeMailSourceUrl), true);
      const teamPk = handleEmptyValue(getParameterByName('team_pk', realTimeMailSourceUrl), true);
      const count = handleEmptyValue(getParameterByName('count', realTimeMailSourceUrl));
      const email = handleEmptyValue(getParameterByName('email', realTimeMailSourceUrl));
      const salt = handleEmptyValue(getParameterByName('salt', realTimeMailSourceUrl));
      const data = {
        token,
        teamPk,
        count,
        email,
        salt
      };
      props.createRealTimeEmailWeeklySummaryLog(data);
    }

    // INDUSTRIAL - Log Unit Add to Offer mail button click
    if(realTimeMailSourceUrl && getParameterByName('redirect', realTimeMailSourceUrl) === 'industrial-offers-analysis') {
      const token = handleEmptyValue(getParameterByName('token', realTimeMailSourceUrl), true);
      const officePk = handleEmptyValue(getParameterByName('office_pk', realTimeMailSourceUrl), true);
      const userThankingEmail = handleEmptyValue(getParameterByName('user_thanking_email', realTimeMailSourceUrl));
      const userWhereSent = handleEmptyValue(getParameterByName('user_where_sent', realTimeMailSourceUrl));
      const data = {
        token,
        officePk,
        userThankingEmail,
        userWhereSent
      };
      props.createRealTimeEmailIndustrialSignInLog(data);
    }
    // INDUSTRIAL - Log Offer Weekly Summary mail button click
    if(realTimeMailSourceUrl && getParameterByName('redirect', realTimeMailSourceUrl) === 'industrial-offers-analysis' && getParameterByName('salt', realTimeMailSourceUrl)) {
      const token = handleEmptyValue(getParameterByName('token', realTimeMailSourceUrl), true);
      const teamPk = handleEmptyValue(getParameterByName('team_pk', realTimeMailSourceUrl), true);
      const count = handleEmptyValue(getParameterByName('count', realTimeMailSourceUrl));
      const email = handleEmptyValue(getParameterByName('email', realTimeMailSourceUrl));
      const salt = handleEmptyValue(getParameterByName('salt', realTimeMailSourceUrl));
      const data = {
        token,
        teamPk,
        count,
        email,
        salt
      };
      props.createRealTimeEmailIndustrialWeeklySummaryLog(data);
    }
    // INDUSTRIAL - Log Offer First Open mail button click
    if(realTimeMailSourceUrl && getParameterByName('redirect', realTimeMailSourceUrl) === '-industrial-offers-online-analysis') {
      const token = handleEmptyValue(getParameterByName('token', realTimeMailSourceUrl), true);
      const offerPk = handleEmptyValue(getParameterByName('offer_pk', realTimeMailSourceUrl), true);
      const emailTo = handleEmptyValue(getParameterByName('email_to', realTimeMailSourceUrl));
      const emailFrom = handleEmptyValue(getParameterByName('email_from', realTimeMailSourceUrl));
      const data = {
        token,
        offerPk: Number(offerPk),
        emailTo,
        emailFrom
      };
      props.createRealTimeEmailIndustrialFirstOpenLog(data);
    }
  }
  render() {
    const { destroyNotification, intl: {locale}, notifications: {message, type, timeout, isOpen} } = this.props;
    const today = new Date().getFullYear();
    const isEN = locale === 'en';

    return (
      <>
        {/* Main Wrapper */}
        <div className="kt-grid kt-grid--ver kt-grid--root">
          {/* Error/Notification Component - display when needed */}
          <PageMessages message={message} variant={type} isOpen={isOpen} timeout={timeout} destroyNotification={destroyNotification}/>
          <div
            id="kt_login"
            className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
          >
            {/* Main Content Wrapper */}
            <div className={styles.authContainer}>
              {/* Render Header Section */}
              <div className={styles.authHeader}>
                <Link to="/">
                  <img src={toAbsoluteUrl("media/branding/redd-logo-neon-200px.png")} alt="REDD Logo"/>
                </Link>
                <div className={styles.headerRegister}>
                  <Typography variant="body1"><FormattedMessage id="AUTH.GENERAL.NO_ACCOUNT" /></Typography>
                  <Typography variant="body1">
                    {/* Link to Register Page outside of application */}
                    <a href={`${ROUTES.REDD_LANDING_PAGE}/${isEN ? 'en/#home-contact' : '#home-contact'}`} target="_blank" rel="noopener noreferrer">
                      <FormattedMessage id="AUTH.GENERAL.SIGNUP_BUTTON" />
                    </a>
                  </Typography>
                </div>
              </div>
              {/* Render Content Section - Route Base */}
              <div className={styles.authContent}>
                {/* Possible Routes for Auth Views */}
                <Switch>
                  <Redirect from="/auth" exact={true} to="/auth/login" />
                  <Route path="/auth/login" component={Login} />
                  <Route path="/auth/forgot-password" component={ForgotPassword} />
                  <Route path={`/auth${ROUTES.SET_USER_PASSWORD}/:token`} component={SetUserPassword} />
                </Switch>
              </div>
              {/* Render Footer Section */}
              <div className={styles.authFooter}>
                <div>
                  {today} &copy; REDD Group
                </div>
                <div>
                  <a
                    href={isEN ? `${ROUTES.REDD_LANDING_PAGE}/terms-of-service/` : `${ROUTES.REDD_LANDING_PAGE}/pl/regulamin/`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="kt-link"
                  >
                    <FormattedMessage id="AUTH.GENERAL.LEGAL"/>
                  </a>
                  <a
                    href={isEN ? `${ROUTES.REDD_LANDING_PAGE}/privacy-policy/` : `${ROUTES.REDD_LANDING_PAGE}/pl/polityka-prywatnosci/`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="kt-link"
                  >
                    <FormattedMessage id="AUTH.GENERAL.PRIVACY"/>
                  </a>
                  <a href={isEN ? `${ROUTES.REDD_LANDING_PAGE}/contact/` : `${ROUTES.REDD_LANDING_PAGE}/pl/kontakt/`} target="_blank" rel="noopener noreferrer" className="kt-link">
                    <FormattedMessage id="AUTH.GENERAL.CONTACT"/>
                  </a>
                </div>
              </div>
              {/* Render Background Animation for all Auth Views */}
              <div className={styles.bgAnimationWrapper}>
                <img src={toAbsoluteUrl("media/branding/globe-1.png")} alt="REDD Globe"/>
                <img src={toAbsoluteUrl("media/branding/globe-2.png")} alt="REDD Globe"/>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => ({
  loading: store.authApp.loading,
  notifications: store.notifications
});
const mapDispatchToProps = {
  destroyNotification: destroyNotification,
  resetAuthLoading: resetAuthLoading,
  createRealTimeEmailSignInLog: (data) => createRealTimeEmailSignInLog(data),
  createRealTimeEmailWeeklySummaryLog: (data) => createRealTimeEmailWeeklySummaryLog(data),
  createRealTimeEmailIndustrialSignInLog: (data) => createRealTimeEmailIndustrialSignInLog(data),
  createRealTimeEmailIndustrialWeeklySummaryLog: (data) => createRealTimeEmailIndustrialWeeklySummaryLog(data),
  createRealTimeEmailIndustrialFirstOpenLog: (data) => createRealTimeEmailIndustrialFirstOpenLog(data)
};
export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AuthPage)
);
import React from "react";
import {FormattedMessage} from "react-intl";
import {toNumberFormat} from "app/utils/helpers";
import TableCustom from "app/partials/components/TableCustom";

export default class OwnerFinancialOverview extends React.Component {
  render() {
    const {ownerFinances} = this.props;
    const {
      totalOperatingRevenue,
      grossProfitMargin,
      ebit,
      operatingProfitMarginRos,
      ebitda,
      assets,
      liabilities,
      roaMargin,
      salesProfit,
      debtToEquity,
      totalAssets
    } = ownerFinances;
    const tHead = [
      {
        "label": "",
        "number": false
      },
      {
        "label": <FormattedMessage id="GENERAL.PHRASE.VALUE"/>,
        "number": true
      }
    ];
    const tBody = [
      {
        "data": [<FormattedMessage id="OWNERS.PHRASE.TOTAL_OPERATING_REVENUE"/>, toNumberFormat(totalOperatingRevenue, 'currency', 'PLN')]
      },
      {
        "data": [<FormattedMessage id="OWNERS.PHRASE.PROFIT_LOSS_FROM_SALES"/>, toNumberFormat(salesProfit, 'currency', 'PLN')]
      },
      {
        "data": [<FormattedMessage id="OWNERS.PHRASE.GROSS_PROFIT_MARGIN"/>, toNumberFormat(grossProfitMargin, '%')]
      },
      {
        "data": [<FormattedMessage id="OWNERS.PHRASE.OPERATING_PROFIT_EBIT"/>, toNumberFormat(ebit, 'currency', 'PLN')]
      },
      {
        "data": [<FormattedMessage id="OWNERS.PHRASE.OPERATING_PROFIT_MARGIN_ROS"/>, toNumberFormat(operatingProfitMarginRos, '%')]
      },
      {
        "data": ["EBITDA", toNumberFormat(ebitda, 'currency', 'PLN')]
      },
      {
        "data": [<FormattedMessage id="OWNERS.PHRASE.TOTAL_ASSETS"/>, toNumberFormat(totalAssets, 'currency', 'PLN')]
      },
      {
        "data": [<FormattedMessage id="OWNERS.PHRASE.CURRENT_ASSETS"/>, toNumberFormat(assets, 'currency', 'PLN')]
      },
      {
        "data": [<FormattedMessage id="OWNERS.PHRASE.TOTAL_LIABILITIES"/>, toNumberFormat(liabilities, 'currency', 'PLN')]
      },
      {
        "data": ["ROA", toNumberFormat(roaMargin, '%')]
      },
      {
        "data": [<FormattedMessage id="OWNERS.PHRASE.DEBT_TO_EQUITY"/>, toNumberFormat(debtToEquity)]
      }
    ];
    return(
      <TableCustom tHead={tHead} tBody={tBody} theadStyleOrigin/>
    );
  }
}
import React from "react";
import connect from "react-redux/es/connect/connect";
import styles from "../../components/index.module.scss";
import parentStyles from "../../components/index.module.scss";
import {toAbsoluteUrl} from "_metronic";
import {TextField, Typography} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import clsx from "clsx";
import {Formik} from "formik";
import {authLogin} from "app/crud/authApp.crud";
import {
  requestPropertyParticularsAccess,
  verifyPropertyParticularsAccess,
  verifyPropertyParticularsAccessReset
} from "app/crud/propertyParticulars/industrial.crud";
import LoadingScreen from "../../../../../partials/components/LoadingScreen";
import LanguageSelector from "../../../../../partials/layout/LanguageSelector";
import PageMessages from "app/partials/components/PageMessages";

class PropertyParticularsAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      teamID: props.match.params.team,
      verifyMail: ""
    };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.isSubscriber !== this.props.isSubscriber) {
      if(this.props.isSubscriber) {
       localStorage.setItem("ppMail", this.state.verifyMail);
       window.location.reload();
      }
      else {
        localStorage.removeItem("ppMail");
      }
    }
  }
  handleBackToMail = (e) => {
    e.preventDefault();
    this.props.verifyPropertyParticularsAccessReset();
  };
  handleRequestAccess = (e) => {
    e.preventDefault();
    if(this.state.teamID && this.state.verifyMail) {
      this.props.requestPropertyParticularsAccess(this.state.teamID, this.state.verifyMail);
    }
  };

  render() {
    const primaryColor = "#FF4626";
    const secondaryColor = "#ffffff";
    const {
      intl,
      authLogin,
      authLoading,
      verifyingAccess,
      requestingAccess,
      isUser,
      isSubscriber,
      isNotAllowed,
      verifyPropertyParticularsAccess,
      ppBranding,
      loadingBranding,
      message,
      type,
      isOpen,
      destroyNotification
    } = this.props;
    const {teamID, verifyMail} = this.state;

    if(loadingBranding || !ppBranding) {
      return <LoadingScreen/>;
    }
    return(
      <div
          className={styles.mainAuthContainer}
          style={{
            "--bg-image": `url(${toAbsoluteUrl("media/bg/bg-property-particulars-online.jpg")})`,
            "--brand-bg-color": ppBranding.brandBgColor || primaryColor,
            "--brand-bg-font-color": ppBranding.brandBgFontColor || secondaryColor,
            "--brand-font-color": ppBranding.brandFontColor || primaryColor,
            "--brand-btn-bg-color": ppBranding.brandBtnBgColor || primaryColor,
            "--brand-btn-bg-font-color": ppBranding.brandBtnBgFontColor || secondaryColor
          }}
      >
        <div className={styles.authWrapper}>
          <div className={styles.authImage}>
            {ppBranding.ppCompanyLogo ? <img src={ppBranding.ppCompanyLogo} alt={ppBranding.name}/> : <span className={styles.authName}>{ppBranding.name}</span>}
          </div>
          <div className={styles.authContent}>
            <div className={styles.contentHeader}>
              <h3>Property Particulars</h3>
              <div className={parentStyles.langMenu}>
                  <LanguageSelector preventReload/>
              </div>
            </div>
            <div className={styles.contentBody}>
              <p><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.HELLO"/>,</p>
              <p><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.AUTH_DESCRIPTION_INDUSTRIAL" values={{companyName: <strong>{ppBranding.name}</strong>}}/></p>
            </div>
            <div className={styles.contentFooter}>
              <p><strong><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.AUTH_FORM_INFO"/>:</strong></p>
              <Formik
                initialValues={{
                  email: "",
                  password: ""
                }}
                validate={values => {
                  const errors = {};
                  // Email address is required
                  if (!values.email) {
                    errors.email = intl.formatMessage({
                      id: "AUTH.VALIDATION.REQUIRED_FIELD"
                    });
                  }
                  else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                    errors.email = intl.formatMessage({
                      id: "AUTH.VALIDATION.INVALID_FIELD"
                    });
                  }
                  // Password is required
                  if (!values.password && isUser) {
                    errors.password = intl.formatMessage({
                      id: "AUTH.VALIDATION.REQUIRED_FIELD"
                    });
                  }
                  return errors;
                }}
                onSubmit={(values) => {
                  // Update given email address
                  this.setState({
                    verifyMail: values.email
                  });
                  // Verify common based on provided email address
                  if(!isSubscriber && !isUser) {
                    verifyPropertyParticularsAccess(teamID, values.email);
                  }
                  // If verified as User next step is verify password
                  else if(isUser) {
                    authLogin(values.email, values.password);
                  }
                }}
              >
                {({
                  values,
                  status,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit
                }) => (
                  <form
                    noValidate={true}
                    autoComplete="off"
                    className="kt-form"
                    onSubmit={handleSubmit}
                  >
                    {status && (
                      <div role="alert" className="alert alert-danger">
                        <div className="alert-text">{status}</div>
                      </div>
                    )}
                    {!isUser && (
                    <div className="form-group">
                      <TextField
                        type="email"
                        label={intl.formatMessage({
                          id: "FORM.FIELD.EMAIL_ADDRESS"
                        })}
                        margin="normal"
                        className={styles.customInput}
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        helperText={touched.email && errors.email}
                        error={Boolean(touched.email && errors.email)}
                      />
                    </div>
                    )}
                    {isUser && (
                    <div className="form-group">
                      <TextField
                        type="password"
                        margin="normal"
                        label={intl.formatMessage({
                          id: "FORM.FIELD.PASSWORD"
                        })}
                        className={styles.customInput}
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.password}
                        helperText={touched.password && errors.password}
                        error={Boolean(touched.password && errors.password)}
                      />
                    </div>
                    )}
                    <Typography variant="body2" className={styles.formNote}><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.AUTH_CONSENTS_INFO"/></Typography>
                    <div className={styles.contentActions}>
                      {isNotAllowed && verifyMail ?
                      <button
                          className={clsx(styles.btnSimpleText, requestingAccess && styles.inactive)}
                          onClick={(e) => this.handleRequestAccess(e)}
                      >
                        <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.REQUEST_ACCESS"/>
                      </button> :
                      <button
                          className={clsx(styles.btnSimpleText, !isUser && styles.inactive)}
                          onClick={(e) => this.handleBackToMail(e)}
                      >
                        <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.CHANGE_MAIL"/>
                      </button>
                      }
                      <button
                        id="kt_login_signin_submit"
                        type="submit"
                        disabled={authLoading}
                        className={clsx("btn btn-primary btn-elevate kt-login__btn-primary", styles.btnSubmit, (authLoading || verifyingAccess) && styles.inactive)}
                      >
                        <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.BTN_AUTH_SUBMIT" />
                      </button>
                      <img src={toAbsoluteUrl("media/branding/redd-loader.gif")} alt="REDD Loader" className={clsx(styles.btnLoader, requestingAccess && styles.left, (authLoading || verifyingAccess || requestingAccess) && styles.active)}/>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
          {/* Error/Notification Component - display when needed */}
          <PageMessages
            message={message}
            variant={type}
            isOpen={isOpen}
            timeout={5000}
            destroyNotification={destroyNotification}
            vertical="bottom"
            style={{
              position: "absolute",
              right: "0",
              bottom: "-6.4rem"
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  authLoading: store.authApp.loading,
  isUser: store.ppOnlineIndustrial.isUser,
  isSubscriber: store.ppOnlineIndustrial.isSubscriber,
  isNotAllowed: store.ppOnlineIndustrial.isNotAllowed,
  verifyingAccess: store.ppOnlineIndustrial.verifyingAccess,
  requestingAccess: store.ppOnlineIndustrial.requestingAccess,
  ppBranding: store.ppOnlineIndustrial.ppBranding,
  loadingBranding: store.ppOnlineIndustrial.loadingBranding
});
const mapDispatchToProps = {
  authLogin: (email, password) => authLogin(email, password),
  verifyPropertyParticularsAccess: (teamID, email) => verifyPropertyParticularsAccess(teamID, email),
  requestPropertyParticularsAccess: (teamID, email) => requestPropertyParticularsAccess(teamID, email),
  verifyPropertyParticularsAccessReset: () => verifyPropertyParticularsAccessReset()
};
export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PropertyParticularsAuth)
);

import React from "react";
import ModalCustom from "app/partials/components/ModalCustom";
import {Menu, MenuItem} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {PDFViewer} from "@react-pdf/renderer";
import OwnerFinancialPdfDocument from "app/pages/offices/OwnerPage/components/OwnerFinancialPdfDocument";
import styles from "./index.module.scss";
import LoadingScreen from "app/partials/components/LoadingScreen";
import {fetchOwnerPortfolio} from "../../../../crud/owners.crud";
import {fetchOfficeFinances} from "../../../../crud/estates/offices/offices.crud";
import {
  fetchIndustrialWarehouseFinances,
  fetchIndustrialWarehousePortfolio
} from "app/crud/estates/industrial/industrial.crud";
import {callReddClickEvent} from "../../../../crud/user.crud";
import Button from "../../../../partials/components/Button";

class OwnerFinancialGeneratePDF extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      anchorEl: null,
      isIndustrialWarehouseView: props.view === 'industrialWarehouse'
    };
    if (props.ownerId) {
      if (this.state.isIndustrialWarehouseView) {
        props.fetchIndustrialWarehousePortfolio(props.ownerId);
      } else {
        props.fetchOwnerPortfolio(props.ownerId);
      }
    }
  }

  handleOpen = (event) => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };
  handleClose = () => {
    this.setState({
      anchorEl: null
    })
  };

  render() {
    const {
      user,
      inactive,
      officeFinances,
      ownerPortfolio,
      warehouseFinances,
      warehouseOwnersPortfolio,
      directGenerate,
      fetchOfficeFinances,
      fetchIndustrialWarehouseFinances,
      officeID
    } = this.props;
    const {anchorEl, isIndustrialWarehouseView} = this.state;
    const dataFinances = isIndustrialWarehouseView ? warehouseFinances : officeFinances;
    const dataPortfolio = isIndustrialWarehouseView ? warehouseOwnersPortfolio : ownerPortfolio;

    if (inactive) {
      return (
        <Button inactive>
          <FormattedMessage id="SHORTLIST.PHRASE.GENERATE_PDF"/>
        </Button>
      );
    } else if (directGenerate) {
      return (
        <div>
          {directGenerate && directGenerate.length && directGenerate.map((year, index) => {
            return (
              <ModalCustom
                ref={this.child}
                btn={
                <Button
                  color={"success"}
                  onClick={() => isIndustrialWarehouseView ? fetchIndustrialWarehouseFinances(officeID) : fetchOfficeFinances(officeID)}
                >
                  {year}
                </Button>
                }
                title={<FormattedMessage id="SHORTLIST.PHRASE.GENERATE_PDF"/>}
                btnReject={<FormattedMessage id="GENERAL.PHRASE.CLOSE"/>}
                maxWidth="lg"
              >
                {dataFinances ? (
                  <div>
                    <LoadingScreen absolute/>
                    <PDFViewer
                      style={{width: 1200, height: "80vh", border: 0, position: "relative"}}>
                      <OwnerFinancialPdfDocument
                        user={user}
                        intl={this.props.intl}
                        officeFinances={dataFinances && dataFinances[index]}
                        ownerPortfolio={dataPortfolio}
                      />
                    </PDFViewer>
                  </div>
                ) : <LoadingScreen/>}
              </ModalCustom>
            )
          })}
        </div>
      );
    }
    return (
      <div>
        <Button
          color="success"
          onClick={this.handleOpen}
        >
          <FormattedMessage id="SHORTLIST.PHRASE.GENERATE_PDF"/>
        </Button>
        <Menu
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          id="generatePdf"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          className={styles.customPopoverContainer}
        >
          {dataFinances && dataFinances.map((item, index) => (
            <MenuItem key={index} onClick={this.handleClose}>
              <ModalCustom
                ref={this.child}
                btn={
                <Button color={"success"}>
                  {item?.year}
                </Button>
                }
                title={<FormattedMessage id="SHORTLIST.PHRASE.GENERATE_PDF"/>}
                btnReject={<FormattedMessage id="GENERAL.PHRASE.CLOSE"/>}
                maxWidth="lg"
                onOpen={() => this.props.dispatch(callReddClickEvent(
                  "owner-financial-statement-generate-pdf-click",
                  "office",
                  null,
                  officeID
                ))}
              >
                {dataFinances ? (
                  <div>
                    <LoadingScreen absolute/>
                    <PDFViewer
                      style={{width: 1200, height: "80vh", border: 0, position: "relative"}}>
                      <OwnerFinancialPdfDocument
                        user={user}
                        intl={this.props.intl}
                        officeFinances={dataFinances && dataFinances[index]}
                        ownerPortfolio={dataPortfolio}
                      />
                    </PDFViewer>
                  </div>
                ) : <LoadingScreen/>}
              </ModalCustom>
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  user: store.user.data,
  officeFinances: store.offices.officeFinances,
  ownerPortfolio: store.owners.ownerPortfolio,
  warehouseFinances: store.industrial.warehouseFinances,
  warehouseOwnerPortfolio: store.industrial.warehouseOwnerPortfolio
});
const mapDispatchToProps = dispatch => ({
  fetchOwnerPortfolio: (ownerId) => dispatch(fetchOwnerPortfolio(ownerId)),
  fetchOfficeFinances: (officeID) => dispatch(fetchOfficeFinances(officeID)),
  fetchIndustrialWarehousePortfolio: (ownerID) => dispatch(fetchIndustrialWarehousePortfolio(ownerID)),
  fetchIndustrialWarehouseFinances: (warehouseID) => dispatch(fetchIndustrialWarehouseFinances(warehouseID)),
  dispatch
});
export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OwnerFinancialGeneratePDF)
);
import connect from "react-redux/es/connect/connect";
import {resetOfficeLoadingStates} from "../crud/estates/offices/offices.crud";
import {resetOfficeBuildingLoadingStates} from "../crud/estates/offices/officeBuilding.crud";
import {resetOfficeAnalyticsLoadingStates} from "../crud/estates/offices/officesPremium.crud";
import {resetOfficeUnitsLoadingStates} from "../crud/estates/offices/units.crud";
import {resetOfficePropertyParticularsLoadingStates} from "../crud/propertyParticulars/offices.crud";
import {resetOfficeOffersLoadingStates} from "../crud/offers.crud";
import {resetAcquisitionsLoadingStates} from "../crud/estates/offices/acquisitions.crud";
import {resetIndustrialLoadingStates} from "../crud/estates/industrial/industrial.crud";
import {resetIndustrialParkLoadingStates} from "../crud/estates/industrial/industrialPark.crud";
import {resetIndustrialWarehouseLoadingStates} from "../crud/estates/industrial/industrialWarehouse.crud";
import {resetIndustrialOffersLoadingStates} from "../crud/estates/industrial/offers-industrial.crud";
import {resetIndustrialPropertyParticularsLoadingStates} from "../crud/propertyParticulars/industrial.crud";
import {resetAuthAppLoadingStates} from "../crud/authApp.crud";
import {resetFinneLeadsLoadingStates} from "../crud/finneLeads.crud";
import {resetLeadsManagementLoadingStates} from "../crud/leadsManagement.crud";
import {resetOwnersLoadingStates} from "../crud/owners.crud";
import {resetReddIndexLoadingStates} from "../crud/reddIndex.crud";
import {resetSubscribersLoadingStates} from "../crud/subscribers.crud";
import {resetSymmcoTransactionsLoadingStates} from "../crud/symmco.crud";
import {resetTeamMembersLoadingStates} from "../crud/team.crud";
import {resetUserLoadingStates} from "../crud/user.crud";
import {resetAccountRequestsStates} from "../crud/accountRequest.crud";

const ResetLoadingStates = (
  {
    resetOfficeLoadingStates,
    resetOfficeBuildingLoadingStates,
    resetOfficeAnalyticsLoadingStates,
    resetOfficeUnitsLoadingStates,
    resetOfficePropertyParticularsLoadingStates,
    resetOfficeOffersLoadingStates,
    resetAcquisitionsLoadingStates,
    resetIndustrialLoadingStates,
    resetIndustrialParkLoadingStates,
    resetIndustrialWarehouseLoadingStates,
    resetIndustrialOffersLoadingStates,
    resetIndustrialPropertyParticularsLoadingStates,
    resetAuthAppLoadingStates,
    resetFinneLeadsLoadingStates,
    resetLeadsManagementLoadingStates,
    resetOwnersLoadingStates,
    resetReddIndexLoadingStates,
    resetSubscribersLoadingStates,
    resetSymmcoTransactionsLoadingStates,
    resetTeamMembersLoadingStates,
    resetUserLoadingStates,
    resetAccountRequestsStates
  }
) => {
  // Offices Loading Status
  resetOfficeLoadingStates();
  // Office Building Loading States
  resetOfficeBuildingLoadingStates();
  // Office Analytics Loading States
  resetOfficeAnalyticsLoadingStates();
  // Office Units Loading States
  resetOfficeUnitsLoadingStates();
  // Office Property Particulars Loading States
  resetOfficePropertyParticularsLoadingStates();
  // Office Offers Loading States
  resetOfficeOffersLoadingStates();
  // Office Acquisitions Loading States
  resetAcquisitionsLoadingStates();
  // Industrial Loading States
  resetIndustrialLoadingStates();
  // Industrial Park Loading States
  resetIndustrialParkLoadingStates();
  // Industrial Warehouse Loading States
  resetIndustrialWarehouseLoadingStates();
  // Industrial Offers Loading States
  resetIndustrialOffersLoadingStates();
  // Industrial Property Particulars Loading States
  resetIndustrialPropertyParticularsLoadingStates();
  // Global - Auth App Loading States
  resetAuthAppLoadingStates();
  // Global - finne Leads
  resetFinneLeadsLoadingStates();
  // Global - Owners
  resetOwnersLoadingStates();
  // Global - Redd Index Loading States
  resetReddIndexLoadingStates();
  // Global - Subscribers (Followers) Loading States
  resetSubscribersLoadingStates();
  // Global - Symmco Loading States
  resetSymmcoTransactionsLoadingStates();
  // Global - Team Loading States
  resetTeamMembersLoadingStates();
  // Global - User Loading States
  resetUserLoadingStates();
  // Admin - Leads Management Loading States
  resetLeadsManagementLoadingStates();
  // Admin - Account Requests Management Loading States
  resetAccountRequestsStates();

  return true;
}

const mapDispatchToProps = {
  resetOfficeLoadingStates: resetOfficeLoadingStates,
  resetOfficeBuildingLoadingStates: resetOfficeBuildingLoadingStates,
  resetOfficeAnalyticsLoadingStates: resetOfficeAnalyticsLoadingStates,
  resetOfficeUnitsLoadingStates: resetOfficeUnitsLoadingStates,
  resetOfficePropertyParticularsLoadingStates: resetOfficePropertyParticularsLoadingStates,
  resetOfficeOffersLoadingStates: resetOfficeOffersLoadingStates,
  resetAcquisitionsLoadingStates: resetAcquisitionsLoadingStates,
  resetIndustrialLoadingStates: resetIndustrialLoadingStates,
  resetIndustrialParkLoadingStates: resetIndustrialParkLoadingStates,
  resetIndustrialWarehouseLoadingStates: resetIndustrialWarehouseLoadingStates,
  resetIndustrialOffersLoadingStates: resetIndustrialOffersLoadingStates,
  resetIndustrialPropertyParticularsLoadingStates: resetIndustrialPropertyParticularsLoadingStates,
  resetAuthAppLoadingStates: resetAuthAppLoadingStates,
  resetFinneLeadsLoadingStates: resetFinneLeadsLoadingStates,
  resetLeadsManagementLoadingStates: resetLeadsManagementLoadingStates,
  resetOwnersLoadingStates: resetOwnersLoadingStates,
  resetReddIndexLoadingStates: resetReddIndexLoadingStates,
  resetSubscribersLoadingStates: resetSubscribersLoadingStates,
  resetSymmcoTransactionsLoadingStates: resetSymmcoTransactionsLoadingStates,
  resetTeamMembersLoadingStates: resetTeamMembersLoadingStates,
  resetUserLoadingStates: resetUserLoadingStates,
  resetAccountRequestsStates: resetAccountRequestsStates
}

export default connect(null, mapDispatchToProps)(ResetLoadingStates);
import React from "react";
import PropertySectionTitle from "app/pages/common/components/PropertyPage/PropertySectionTitle";
import {Grid} from "@material-ui/core";
import PropertyRegionAreaTable from "./content/PropertyRegionAreaTable";
import ReactVisibilitySensor from "react-visibility-sensor";
import _ from "lodash";
import {FormattedMessage} from "react-intl";
import PropertyRegionMap from "./PropertyRegionMap";
import styles from "./index.module.scss";
import PropertyRegionXlsDownload from "./PropertyRegionXlsDownload";
import cn from "classnames";

export default class PropertyRegionArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initLoaded: false
    }
  }
  // Handlers
  handleOnViewportFetch = (isVisible) => {
    const {fetchIndustrialParkRegion, regionStats, regionStatsLoading, match} = this.props;
    const {initLoaded} = this.state;
    const {id} = match?.params;

    setTimeout(() => {
      if (isVisible && !initLoaded && !regionStatsLoading && _.isEmpty(regionStats) && id) {
        this.setState({
          initLoaded: true,
        }, () => fetchIndustrialParkRegion(id));
      }
    }, 200);
  }

  render() {
    const {basicLoading, basic} = this.props;
    const isMapAvailable = basic?.distanceMap;

    return (
      <>
        <ReactVisibilitySensor
          onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
          scrollCheck
          partialVisibility
        >
          <PropertySectionTitle
            title={<FormattedMessage id="VOCABULARY.Available space"/>}
            toolbar={!basicLoading && <PropertyRegionXlsDownload/>}
            style={{
              marginBottom: "1.6rem"
            }}
          />
        </ReactVisibilitySensor>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={cn(
              styles.regionWrapper,
              isMapAvailable ? styles.mapVisible : undefined
            )}>
              <PropertyRegionAreaTable {...this.props}/>
              {isMapAvailable && <PropertyRegionMap {...this.props}/>}
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
}
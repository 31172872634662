import React from "react";
import styles from "../../../../../common/external/components/index.module.scss";
import clsx from "clsx";
import IconColorMUI from "app/partials/components/IconColorMUI";
import {FormattedMessage} from "react-intl";
import {handleEmptyValue, toNumberFormat} from "app/utils/helpers";
import Typography from "@material-ui/core/Typography/Typography";
import {Tooltip} from "@material-ui/core";
import UnitAvailabilityPreview from "../../../../../../partials/components/_DataParts/UnitAvailabilityPreview";

export default class OffersOnlineSingleDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          copied: false
        };
    }
    handleCopyShareUrl = () => {
        this.setState({
            copied: true
        }, () => {
            setTimeout(() => {
                this.setState({
                    copied: false
                });
            }, 5000);
            return navigator.clipboard.writeText(window.location.href);
        });
    };
    render() {
        const {data} = this.props;
        const {copied} = this.state;
        const officeAddress = [
            ...data.addressStreet ? [data.addressStreet] : [],
            ...data.district ? [data.district] : [],
            ...data.addressCity ? [data.addressCity] : []
        ];
        const officeCompletionYear = [
            ...data.commissioningQuarter ? [`Q${data.commissioningQuarter}`] : [],
            ...data.commissioningYear ? [data.commissioningYear] : []
        ];
        const officeRentRange = [
            ...data.rentFromEur ? [toNumberFormat(data.rentFromEur)] : [],
            ...data.rentToEur ? [toNumberFormat(data.rentToEur)] : []
        ];
        const officeRetailRentRange = [
            ...data.retailRentPriceEur ? [toNumberFormat(data.retailRentPriceEur)] : [],
            ...data.retailRentPriceToEur ? [toNumberFormat(data.retailRentPriceToEur)] : []
        ];
        return(
            <div ref={this.props.reference} className={clsx(styles.detailsWrapper, styles.boxContainer)}>
                <div className={styles.detailsHeader}>
                    <div className={styles.detailsTitle}>
                        <h1>{handleEmptyValue(data.name)}</h1>
                        <p>{officeAddress.join(", ")}</p>
                    </div>
                    <Tooltip placement="top" title={<Typography variant="body1"><FormattedMessage id={copied ? "PROPERTY_PARTICULARS.PHRASE.COPIED_TO_CLIPBOARD" : "PROPERTY_PARTICULARS.PHRASE.COPY_LINK"}/></Typography>}>
                      <span style={{display: 'flex', alignItems: 'center'}}>
                        <button className={styles.btnShare} onClick={() => this.handleCopyShareUrl()}><IconColorMUI icon="share"/></button>
                      </span>
                    </Tooltip>
                </div>
                <h2><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.OFFICE_DETAILS"/></h2>
                <div className={styles.detailsTablesWrapper}>
                    <div className={styles.tableWrapper}>
                        <table>
                            <tbody>
                                <tr>
                                    <td className={styles.tdLabel}><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.NET_AREA"/>:</td>
                                    <td className={styles.tdValue}>{toNumberFormat(data.totalAreaNetto, 'area')}</td>
                                </tr>
                                <tr>
                                    <td className={styles.tdLabel}><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.GROSS_AREA"/>:</td>
                                    <td className={styles.tdValue}>{toNumberFormat(data.totalAreaBrutto, 'area')}</td>
                                </tr>
                                <tr>
                                    <td className={styles.tdLabel}><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.BUILDING_CLASS"/>:</td>
                                    <td className={styles.tdValue}>{handleEmptyValue(data.buildingClass)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className={styles.tableWrapper}>
                        <table>
                            <tbody>
                                <tr>
                                    <td className={styles.tdLabel}><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.COMPLETION_YEAR"/>:</td>
                                    <td className={styles.tdValue}>{officeCompletionYear.join(" ")}</td>
                                </tr>
                                <tr>
                                    <td className={styles.tdLabel}><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.CERTIFICATE"/>:</td>
                                    <td className={styles.tdValue}>{handleEmptyValue(data.certificate)}</td>
                                </tr>
                                <tr>
                                    <td className={styles.tdLabel}><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.AVAILABLE_FROM"/>:</td>
                                    <td className={styles.tdValue}><UnitAvailabilityPreview date={data.buildingAvailableFrom}/></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <h2><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.LEASE_TERMS"/></h2>
                <div className={styles.detailsTablesWrapper}>
                    <div className={styles.tableWrapper}>
                        <table>
                            <tbody>
                                <tr>
                                    <td className={styles.tdLabel}><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.OFFICE_RENT"/>:</td>
                                    <td className={styles.tdValue}>{toNumberFormat(officeRentRange.join(" - "), 'range', '€')}</td>
                                </tr>
                                <tr>
                                    <td className={styles.tdLabel}><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.RETAIL_RENT"/>:</td>
                                    <td className={styles.tdValue}>{toNumberFormat(officeRetailRentRange.join(" - "), 'range', '€')}</td>
                                </tr>
                                <tr>
                                    <td className={styles.tdLabel}><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.SERVICE_CHARGE"/>:</td>
                                    <td className={styles.tdValue}>{toNumberFormat(data.serviceChargesPln, 'currency', 'PLN')}</td>
                                </tr>
                                <tr>
                                    <td className={styles.tdLabel}><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.PARKING_GROUND"/>:</td>
                                    <td className={styles.tdValue}>{toNumberFormat(data.parkingGroundRentEur, 'currency')}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className={styles.tableWrapper}>
                        <table>
                            <tbody>
                                <tr>
                                    <td className={styles.tdLabel}><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.PARKING_UNDERGROUND"/>:</td>
                                    <td className={styles.tdValue}>{toNumberFormat(data.parkingUndergroundRentEur, 'currency')}</td>
                                </tr>
                                <tr>
                                    <td className={styles.tdLabel}><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.COMMON_AREA_FACTOR"/>:</td>
                                    <td className={styles.tdValue}>{toNumberFormat(data.commonAreaFactor)}</td>
                                </tr>
                                <tr>
                                    <td className={styles.tdLabel}><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.PARKING_FACTOR"/>:</td>
                                    <td className={styles.tdValue}>{handleEmptyValue(data.parkingFactor)}</td>
                                </tr>
                                <tr>
                                    <td className={styles.tdLabel}><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.MIN_RENTAL_PERIOD"/>:</td>
                                    <td className={styles.tdValue}>{handleEmptyValue(data.minRentalYears)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}
import React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {downloadIndustrialParkFiles} from "app/crud/estates/industrial/industrial.crud";
import LoadingScreen from "../../../../partials/components/LoadingScreen";
import {downloadFileBase64} from "../../../../utils/helpers";
import Button from "../../../../partials/components/Button";
import Icon from "../../../../partials/components/Icon";

class IndustrialDownloadFiles extends React.Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.downloadData && prevProps.downloadData !== this.props.downloadData) {
      downloadFileBase64('test', this.props.downloadData);
    }
  }

  handleDownloadFiles = () => {
    const {parkID, selectedFiles, parentData, intl, directDownload, downloadIndustrialParkFiles} = this.props;
    if (parkID && selectedFiles && selectedFiles.length) {
      const fileName = directDownload ? `${parentData.parentName}-${parentData.fileName}` : `${parentData.parentName}-${intl.formatMessage({id: 'files'})}`;
      downloadIndustrialParkFiles(parkID, selectedFiles, fileName);
    }
  }

  render() {
    const {downloading, label, btnMultiselect} = this.props;

    if (downloading) {
      return <LoadingScreen/>;
    } else if (btnMultiselect) {
      return (
        <Button
          textIcon={<Icon icon={"download"}/>}
          onClick={() => this.handleDownloadFiles()}
        >
          <FormattedMessage id="GENERAL.PHRASE.DOWNLOAD_SELECTED_FILES"/>
        </Button>
      );
    }
    return (
      <Button
        type={"icon"}
        tooltip={label}
        onClick={() => this.handleDownloadFiles()}
      >
          <Icon icon={"download"}/>
      </Button>
    );
  }
}

const mapStateToProps = store => ({
  downloading: store.industrial.parkFilesDownloading,
  downloadData: store.industrial.parkDownloadFilesData
});
const mapDispatchToProps = {
  downloadIndustrialParkFiles: (parkID, data, fileName) => downloadIndustrialParkFiles(parkID, data, fileName)
}
export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(IndustrialDownloadFiles)
);
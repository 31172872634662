import React from "react";
import styles from "app/pages/common/external/components/index.module.scss";
import clsx from "clsx";
import PropertyParticularsSingleDetails from "./PropertyParticularsSingleDetails";
import PropertyParticularsSingleAmenities from "./PropertyParticularsSingleAmenities";
import PropertyParticularsSingleLocation from "./PropertyParticularsSingleLocation";
import PropertyParticularsSingleTransport from "./PropertyParticularsSingleTransport";
import {FormattedMessage, injectIntl} from "react-intl";
import PropertyParticularsSingleHeader from "./PropertyParticularsSingleHeader";
import LoadingScreen from "app/partials/components/LoadingScreen";
import PropertyParticularsHeader
  from "app/pages/offices/external/PropertyParticularsPage/components/PropertyParticularsHeader";
import PropertyParticularsSingleNavigation from "./PropertyParticularsSingleNavigation";
import {handleEmptyValue} from "app/utils/helpers";
import PropertyParticularsSingleWarehouses from "./PropertyParticularsSingleWarehouses";
import PropertyParticularsFooter from "app/pages/common/external/components/PropertyParticularsFooter";
import PropertyParticularsSingleDescription from "./PropertyParticularsSingleDescription";
import PropertyParticularsSingleOwner from "./PropertyParticularsSingleOwner";
import NoData from "../../../../../../partials/components/NoData/NoData";
import connect from "react-redux/es/connect/connect";
import {callReddClickEvent} from "app/crud/user.crud";

class PropertyParticularsSingle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isReady: false
        }
        // Refs
        this.refNavigation = React.createRef();
        this.propertyDetails = React.createRef();
        this.propertyUnits = React.createRef();
        this.propertyAmenities = React.createRef();
        this.propertyLocation = React.createRef();
        this.propertyTransport = React.createRef();
        this.propertyDescription = React.createRef();
        this.propertyOwner = React.createRef();

        if (props.match && props.match.params && props.match.params.team && props.match.params.park && !props.loading) {
            const teamID = props.match.params.team;
            const propertyID = props.match.params.park;
            const authEmail = handleEmptyValue(localStorage.getItem("ppMail"), true);
            props.onFetchData(teamID, propertyID, authEmail);
        }
    }
    componentDidMount() {
        const teamID = this.props.match.params.team;
        const propertyID = this.props.match.params.park;
        const authEmail = handleEmptyValue(localStorage.getItem("ppMail"), true);

        if(!this.state.isReady && teamID) {
            this.setState({
                isReady: true
            }, () => {
                this.props.dispatch(callReddClickEvent(
                  "property-particulars-industrial-pp-online-park-details",
                  "park",
                  null,
                  propertyID,
                  {teamID, email: authEmail}
                ));
            });
        }
    }

    handleGoBack = () => {
        const fullPath = this.props.history.location.pathname;
        const fullPathArray = fullPath.split("/");
        const goBackPath = fullPath.replace(fullPathArray[fullPathArray.length - 2] + '/', '');
        this.props.history.replace(goBackPath);
    };
    handleScrollToAnchor = (targetRef) => {
        if (this[targetRef] && this[targetRef].current) {
            const overflowParent = document.getElementById("root");
            const headerOffset = this.refNavigation.current?.offsetHeight + 50;
            const targetPosition = this[targetRef].current?.getBoundingClientRect().top;
            const offsetPosition = targetPosition + overflowParent.scrollTop - headerOffset;

            overflowParent.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }
    };
    handleLoadContacts = () => {
        const {onFetchContact, match} = this.props;
        if (match && match.params && match.params.team && match.params.park) {
            const teamID = match.params.team;
            const propertyID = match.params.park;
            const authEmail = handleEmptyValue(localStorage.getItem("ppMail"), true);
            onFetchContact(teamID, propertyID, authEmail);
            //
            this.props.dispatch(callReddClickEvent(
              "property-particulars-industrial-pp-online-park-contact",
              "park",
              null,
              propertyID,
              {teamID, email: authEmail}
            ));
        }
        return false;
    };

    render() {
        const {loading, data, intl, branding, contact, loadingContact} = this.props;
        const isLangPL = intl?.locale === "pl";

        if (loading) {
            return <LoadingScreen absolute/>;
        }
        return (
            <>
                {/* Render PP Single Header Nav */}
                <PropertyParticularsHeader view="single" branding={branding} onGoBack={this.handleGoBack}/>
                {data && <PropertyParticularsSingleHeader photos={data.photos} planImage={data?.planImage}/>}
                {data &&
                    <PropertyParticularsSingleNavigation
                        reference={this.refNavigation}
                        intl={intl}
                        data={data}
                        photos={data.photos}
                        planImage={data?.planImage}
                        onScrollToAnchor={this.handleScrollToAnchor}
                    />
                }
                {/* Main Content Wrapper*/}
                {data && (
                    <div className={styles.mainOfficeContainer}>
                        <div className={styles.contentWrapper}>
                            <PropertyParticularsSingleDetails reference={this.propertyDetails} data={data}/>
                            {data.amenities && <PropertyParticularsSingleAmenities reference={this.propertyAmenities}
                                                                                   data={data.amenities} intl={intl}/>}
                            {data.warehouses && <PropertyParticularsSingleWarehouses reference={this.propertyUnits}
                                                                                     properties={data.warehouses}
                                                                                     branding={branding}/>}
                            <PropertyParticularsSingleLocation
                                reference={this.propertyLocation}
                                data={data}
                                intl={intl}
                            />
                            {data.publicTransport && data.publicTransport.length ?
                                <PropertyParticularsSingleTransport
                                    reference={this.propertyTransport}
                                    data={data.publicTransport}
                                    intl={intl}
                                /> :
                                null
                            }
                            {
                                (
                                    (intl.locale === "pl" && (data?.description || data?.descriptionLocation)) ||
                                    (intl.locale === "en" && (data?.descriptionEn || data?.descriptionLocationEn))
                                ) &&
                                <PropertyParticularsSingleDescription
                                    reference={this.propertyDescription}
                                    intl={intl}
                                    description={isLangPL ? data?.description : data?.descriptionEn}
                                    descriptionLocation={isLangPL ? data?.descriptionLocation : data?.descriptionLocationEn}
                                />
                            }
                            {data?.owners && data?.owners.length > 0 &&
                                <PropertyParticularsSingleOwner
                                    reference={this.propertyOwner}
                                    owner={data?.owners[0]}
                                />
                            }
                        </div>
                        <div className={styles.contentSidebar}>
                            <div className={clsx(styles.boxContainer, styles.contactBox)}>
                                <div className={styles.contactHeader}>
                                    <h3><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.LEASE"/></h3>
                                    {branding && branding.ppCompanyLogo && (
                                        <div className={styles.contactLogoWrapper}>
                                            <img src={branding.ppCompanyLogo} alt={handleEmptyValue(branding.name)}/>
                                        </div>
                                    )}
                                </div>
                                <div className={styles.contactBody}>
                                    {loadingContact ? <LoadingScreen/> :
                                        !contact ?
                                            <button type="button" className={styles.btnLeaseShow}
                                                    onClick={() => this.handleLoadContacts()}><FormattedMessage
                                                id="PROPERTY_PARTICULARS.PHRASE.SHOW_CONTACT_DETAILS"/></button>
                                            :
                                            contact && contact.length ? contact.map(person => {
                                                const avatar = person.mainPhotoThumbnail ? person.mainPhotoThumbnail : null;
                                                return (
                                                    <div key={person.id} className={styles.contactPerson}>
                                                        {avatar && <img src={avatar} alt={person.companyName}/>}
                                                        <div className={styles.contactDetails}>
                                                            <h3>{[...person.firstName ? [person.firstName] : [], ...person.lastName ? [person.lastName] : []].join(" ")}</h3>
                                                            <p>{handleEmptyValue(person.companyName)}</p>
                                                            {handleEmptyValue(person.phoneNumber, true) &&
                                                                <a href={`tel:${person.phoneNumber}`}>{person.phoneNumber}</a>}
                                                            {handleEmptyValue(person.email, true) &&
                                                                <a href={`mailto:${person.email}`}>{person.email}</a>}
                                                        </div>
                                                    </div>
                                                );
                                            }) : <NoData/>}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <PropertyParticularsFooter intl={intl}/>
            </>
        );
    }
}

export default injectIntl(connect()(PropertyParticularsSingle));
import React from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import Button from "../Button";

export default class AlertFormDialog extends React.Component {
  handleClose = () => {
    const {onClose} = this.props;
    this.setState({
      open: false
    }, () => onClose && onClose());
  };

  constructor(props) {
    super(props);
    this.state = {
      open: props.open || false
    }
  }

  componentWillReceiveProps(nextProps) {
    if(this.state.open !== nextProps.open) {
      this.setState({
        open: nextProps.open
      });
    }
  };

  render() {
    const { open } = this.state;
    return (
      <Dialog
        open={open}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle disableTypography>
          <Typography variant="h3"><FormattedMessage id="GENERAL.PHRASE.FORM_ALERT"/></Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">{this.props.message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleClose()}>
            <FormattedMessage id="GENERAL.PHRASE.CLOSE"/>
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import OffersOnlineIndustrial from "./OffersOnlinePage";
import {
  createRealTimeEmailOfferIndustrialOpenLog,
  fetchOfferIndustrialOnline,
  fetchOfferIndustrialOnlineBranding,
  fetchOfferIndustrialOnlineListPhotos,
  fetchOfferIndustrialOnlineOfficeContact,
  fetchOfferIndustrialOnlinePark
} from "app/crud/estates/industrial/offers-industrial.crud";

const mapStateToProps = (state) => ({
  offerItems: state.offersIndustrial.offerItems,
  loadingOfferItems: state.offersIndustrial.loadingOfferItems,
  offerBranding: state.offersIndustrial.offerBranding,
  offerBranding404: state.offersIndustrial.offerBranding404,
  loadingOfferBranding: state.offersIndustrial.loadingOfferBranding,
  offerOffice: state.offersIndustrial.offerOffice,
  offerOffice404: state.offersIndustrial.offerOffice404,
  loadingOfferOffice: state.offersIndustrial.loadingOfferOffice,
  offerContact: state.offersIndustrial.offerContact,
  loadingOfferContact: state.offersIndustrial.loadingOfferContact,
  offerPhotos: state.offersIndustrial.offerPhotos,
  loadingPhotos: state.offersIndustrial.loadingPhotos
});
const mapDispatchToProps = {
  fetchOfferOnline: (offerID, email, market) => fetchOfferIndustrialOnline(offerID, email, market),
  fetchOfferOnlineOffice: (offerID, officeID, email) => fetchOfferIndustrialOnlinePark(offerID, officeID, email),
  fetchOfferOnlineBranding: (offerID) => fetchOfferIndustrialOnlineBranding(offerID),
  fetchOfferOnlineOfficeContact: (offerID, officeID, email) => fetchOfferIndustrialOnlineOfficeContact(offerID, officeID, email),
  fetchOfferOnlineListPhotos: (offerID, email) => fetchOfferIndustrialOnlineListPhotos(offerID, email),
  createRealTimeEmailOfferOpenLog: (data) => createRealTimeEmailOfferIndustrialOpenLog(data)
};
export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OffersOnlineIndustrial)
);
import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import OwnerPage from "./OwnerPage";
import {
  fetchOwner,
  fetchOwnerAcquisitions,
  fetchOwnerBoard,
  fetchOwnerFinances,
  fetchOwnerLeasingContact,
  fetchOwnerLeasingTeams,
  fetchOwnerLoans,
  fetchOwnerPhotos,
  fetchOwnerPortfolio,
  fetchOwnerReddIndex,
  fetchOwnerSectors,
  fetchOwnerUnitsTracking,
  fetchOwnerVacancyRate
} from "app/crud/owners.crud";
import {callReddClickEvent} from "app/crud/user.crud";

const mapStateToProps = (state) => ({
  user: state.user,
  loading: state.owners.loading,
  owner: state.owners.owner,
  ownerLoaded: state.owners.ownerLoaded,
  ownerBoard: state.owners.ownerBoard,
  ownerFinances: state.owners.ownerFinances,
  ownerLoans: state.owners.ownerLoans,
  ownerPhotos: state.owners.ownerPhotos,
  ownerUnitsTracking: state.owners.ownerUnitsTracking,
  ownerLeasingTeams: state.owners.ownerLeasingTeams,
  ownerPortfolio: state.owners.ownerPortfolio,
  ownerPortfolioLoading: state.owners.ownerPortfolioLoading,
  ownerSectors: state.owners.ownerSectors,
  ownerVacancyRate: state.owners.ownerVacancyRate,
  ownerReddIndex: state.owners.ownerReddIndex,
  ownerLeasingContact: state.owners.ownerLeasingContact,
  ownerAcquisitions: state.owners.ownerAcquisitions,
  ownerAcquisitionsLoading: state.owners.ownerAcquisitionsLoading
});
const mapDispatchToProps = {
  fetchOwner: (ownerId) => fetchOwner(ownerId),
  fetchOwnerPortfolio: (ownerId) => fetchOwnerPortfolio(ownerId),
  fetchOwnerBoard: (ownerId) => fetchOwnerBoard(ownerId),
  fetchOwnerFinances: (ownerId, filters) => fetchOwnerFinances(ownerId, filters),
  fetchOwnerLoans: (ownerId) => fetchOwnerLoans(ownerId),
  fetchOwnerPhotos: (ownerId) => fetchOwnerPhotos(ownerId),
  fetchOwnerUnitsTracking: (ownerId) => fetchOwnerUnitsTracking(ownerId),
  fetchOwnerLeasingTeams: (ownerId) => fetchOwnerLeasingTeams(ownerId),
  fetchOwnerSectors: (ownerId) => fetchOwnerSectors(ownerId),
  fetchOwnerVacancyRate: (ownerId) => fetchOwnerVacancyRate(ownerId),
  fetchOwnerReddIndex: (ownerId) => fetchOwnerReddIndex(ownerId),
  fetchOwnerLeasingContact: (ownerId) => fetchOwnerLeasingContact(ownerId),
  fetchOwnerAcquisitions: (ownerId) => fetchOwnerAcquisitions(ownerId),
  callReddClickEvent: (clickType, contentType, appLabel, objectId) => callReddClickEvent(clickType, contentType, appLabel, objectId)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OwnerPage)
);

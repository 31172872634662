import React, {useEffect, useState} from 'react';
import styles from "./index.module.scss";
import {ReactComponent as IconCheck} from "app/media/icons/check.svg";
import cn from "classnames";

export default function CheckboxCustom({checked, onClick, ...props}) {
  const [isChecked, setIsChecked] = useState(checked || false);
  // Handlers
  const handleClick = () => {
    const checkedStatus = !isChecked;
    const pseudoEvent = {
      target: {
        checked: checkedStatus,
        ...props?.value ? {value: props?.value} : {}
      }
    };

    setIsChecked(checkedStatus);
    onClick && onClick(pseudoEvent);
  }
  //
  useEffect(() => {
    if(checked !== isChecked) {
      setIsChecked(checked);
    }
  }, [checked, isChecked]);

  return(
    <span
      className={cn(
        styles.checkboxContainer,
        isChecked ? styles.active : undefined,
        props?.inactive ? styles.inactive : undefined
      )}
      style={props?.style}
      onClick={handleClick}
    >
      <input type="checkbox" checked={isChecked} readOnly {...props} />
      <span className={styles.checkboxIcon}>
        <IconCheck/>
      </span>
    </span>
  );
}
import get from "lodash/get";
import {createNotification} from "app/constants/actionCreators/notifications";
import store from "../store/store";

export const actionNotification = (message, type = 'success', timeout = 5000) => ({
  notification: {
    type,
    message,
    timeout
  },
});

export const notificationMiddleware = store => next => action => {

  let result = next(action);
  const notificationData = get(action, 'meta.notification', null);
  if (notificationData) {
    store.dispatch(
      createNotification({
        message: notificationData.message,
        type: get(notificationData, 'type', 'success'),
        timeout: notificationData.timeout,
        isOpen: true
      })
    );
  }
  return result;
};

export const errorMessageData = (message) => {
  const isEmpty = message === null || message === undefined || message === '';
  const isArray = !isEmpty && Array.isArray(message);
  const isObject = !isEmpty &&  (typeof message === "object" || typeof message === 'function');
  let finalMessage;
  if(isArray) {
    finalMessage = message[0].map(item => {
      const isArray = Array.isArray(item);
      const isObject = typeof item === "object" || typeof item === 'function';
      if(isArray) {
        return item.map(itm => itm.join(' | '))
      } else if(isObject) {
        return item[0];
      } else {
        return item;
      }
    });
  }
  else if(isObject) {
    finalMessage = Object.values(message).map(item => {
      if(typeof item === "object") {
        return Object.entries(item).map(it => it.join(" - ")).join(" | ");
      }
      else {
        return item.join(' | ');
      }
    });
  } else if(isEmpty) {
    finalMessage = 'Oops, something went wrong! Try again later.';
  } else {
    finalMessage = message;
  }
  return finalMessage;
};

export const triggerNotification = (message, type, timeout = 5000) => {
  return store.dispatch({
    type: 'NOTIFICATIONS_CREATE',
    meta: actionNotification(message, type, timeout)
  });
}
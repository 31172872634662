import React from "react";
import ModalCustom from "../../../../partials/components/ModalCustom";
import {FormattedMessage} from "react-intl";
import {Grid, TextField, Typography} from "@material-ui/core";
import InputAdornments from "../../../../partials/components/InputAdornments";
import {Link} from "react-router-dom";
import {ROUTES} from "../../../../constants";
import connect from "react-redux/es/connect/connect";
import {fetchLead} from "../../../../crud/leadsManagement.crud";
import LoadingScreen from "../../../../partials/components/LoadingScreen";
import {handleEmptyValue} from "../../../../utils/helpers";
import Button from "../../../../partials/components/Button";
import Icon from "../../../../partials/components/Icon";

class LeadsManagementDetails extends React.Component {
  render() {
    const {loadingLead, fetchLead, leadID, lead} = this.props;

    return(
      <ModalCustom
        btn={
        <Button
          type={"icon"}
          tooltip={<FormattedMessage id="LM.PHRASE.LEAD_DETAILS"/>}
        >
          <Icon icon={"card-clusters"}/>
        </Button>
        }
        title={<FormattedMessage id="LM.PHRASE.LEAD_DETAILS"/>}
        maxWidth="sm"
        fetch={() => fetchLead(leadID)}
      >
        {loadingLead ? <LoadingScreen/> :
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" style={{color: "#ff4626"}}>
              <FormattedMessage id="LM.PHRASE.CLIENT"/>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <InputAdornments
              label={<FormattedMessage id="LM.PHRASE.FULL_NAME" />}
              name="crmLastName"
              value={lead?.crmLastName}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <InputAdornments
              label={<FormattedMessage id="LM.PHRASE.COMPANY_NAME" />}
              name="crmName"
              value={lead?.crmName}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <InputAdornments
              label={<FormattedMessage id="FORM.FIELD.EMAIL_ADDRESS" />}
              name="crmEmail"
              value={lead?.crmEmail}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <InputAdornments
              label={<FormattedMessage id="FORM.FIELD.PHONE_NUMBER" />}
              name="crmPhoneNumber"
              value={lead?.crmPhoneNumber}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <InputAdornments
              label={<FormattedMessage id="BI.TABLE.AREA_FROM" />}
              name="crmAreaFrom"
              value={lead?.crmAreaFrom}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <InputAdornments
              label={<FormattedMessage id="BI.TABLE.AREA_TO" />}
              name="crmAreaTo"
              value={lead?.crmAreaTo}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={<FormattedMessage id="LM.PHRASE.MESSAGE" />}
              name="crmMessage"
              value={lead?.crmMessage}
              style={{width: "100%"}}
              multiline
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" style={{marginBottom: "1rem", color: "#ff4626"}}>
              <FormattedMessage id="LM.PHRASE.PROPERTY"/>
            </Typography>
            <Typography variant="h3">
              {handleEmptyValue(lead?.crmBuildingName)}
            </Typography>
            <Grid container spacing={1} justifyContent="flex-start" style={{marginTop: "0.5rem"}}>
              <Grid item>
                <Link to={`${lead?.contentType === "office" ? ROUTES.BUILDING_PAGE : ROUTES.INDUSTRIAL_PARK}/${lead?.reddId}`}><Typography variant="h3" style={{color: "#ff4626"}}><strong>REDD</strong></Typography></Link>
              </Grid>
              <Grid item>
                <a href={`${lead?.crmUrl}`} target="_blank" rel="noopener noreferrer"><Typography variant="h3" style={{color: "#1493e8"}}><strong>finne</strong></Typography></a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        }
      </ModalCustom>
    );
  }
}

const mapStateToProps = ({ leadsManagement }) => ({
  lead: leadsManagement?.lead,
  loadingLead: leadsManagement?.loadingLead
});
const mapDispatchToProps = {
  fetchLead: (leadID) => fetchLead(leadID)
};
export default connect(mapStateToProps, mapDispatchToProps)(LeadsManagementDetails);
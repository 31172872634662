import React from "react";
import {useSelector} from "react-redux";
import {addLocaleData, IntlProvider} from "react-intl";

import * as en from "react-intl/locale-data/en";
import * as pl from "react-intl/locale-data/pl";

import enMessages from "./messages/en";
import plMessages from "./messages/pl";

const allMessages = {
  en: enMessages,
  pl: plMessages
};

addLocaleData([...en, ...pl]);

export default function I18nProvider({ children }) {
  const locale = useSelector(({ i18n }) => i18n.lang);
  const messages = allMessages[locale];

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}

import React from "react";
import styles from "app/pages/common/external/components/index.module.scss";
import {toAbsoluteUrl} from "_metronic";
import IconColorMUI from "app/partials/components/IconColorMUI";
import clsx from "clsx";
import {Link} from "react-router-dom";
import {FormattedMessage, injectIntl} from "react-intl";
import PropertyParticularsHeader from "../PropertyParticularsHeader";
import LoadingScreen from "../../../../../../partials/components/LoadingScreen";
import {handleEmptyValue, toNumberFormat} from "app/utils/helpers";
import {Helmet} from "react-helmet";
import UnitAvailabilityPreview from "../../../../../../partials/components/_DataParts/UnitAvailabilityPreview";
import NoData from "../../../../../../partials/components/NoData/NoData";
import PropertyParticularsFooter from "../../../../../common/external/components/PropertyParticularsFooter";
import {callReddClickEvent} from "app/crud/user.crud";
import connect from "react-redux/es/connect/connect";

class ListItemAccordion extends React.Component {
  constructor(props) {
    super(props);
    const isMobile = window.outerWidth < 760;
    this.state = {
      collapsed: isMobile
    };
  }

  handleToggleCollapse = () => {
    this.setState({
      collapsed: !this.state.collapsed
    })
  };

  render() {
    const {title, children} = this.props;
    const {collapsed} = this.state;
    return (
      <div className={styles.accordionWrapper}>
        <div className={styles.accordionHeader} onClick={() => this.handleToggleCollapse()}>
          {title}
          <IconColorMUI
            icon={collapsed ? "add" : "remove"}
            style={{color: "var(--brand-btn-bg-color)"}}
          />
        </div>
        <div className={clsx(styles.accordionContent, collapsed && styles.collapsed)}>
          {children}
        </div>
      </div>
    );
  }
}

class PropertyParticularsList extends React.Component {
  constructor(props) {
    super(props);
    this.propertiesWrapper = React.createRef();
    this.propertyItem = React.createRef();
    this.propertiesContainer = React.createRef();
    this.state = {
      btnNavPrev: false,
      btnNavNext: true,
      btnNavPause: false,
      scrollPosition: 0,
      isReady: false
    };
    // Set custom HTML font-size
    const html = document.querySelector('html');
    html.style.fontSize = '10px';
    // Fetch Data on Init
    const teamID = props.match && props.match.params && props.match.params.team;
    const authEmail = handleEmptyValue(localStorage.getItem("ppMail"), true);

    if (teamID && !props.loading) {
      props.onFetchData(teamID, authEmail);
    }
  }

  componentDidMount() {
    const {match} = this.props;
    const teamID = match && match.params && Number(match.params.team);
    const authEmail = handleEmptyValue(localStorage.getItem("ppMail"), true);

    if(!this.state.isReady && teamID) {
      this.setState({
        isReady: true
      }, () => {
        this.props.dispatch(callReddClickEvent(
          "property-particulars-pp-online-visit",
          "team",
          null,
          teamID,
          {email: authEmail}
        ));
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const {onFetchPhotos, match} = this.props;
    const teamID = match && match.params && match.params.team;
    const authEmail = handleEmptyValue(localStorage.getItem("ppMail"), true);
    if (
      (prevProps.items && this.props.items && prevProps.items.length && this.props.items.length && prevProps.items[0].pk !== this.props.items[0].pk) ||
      (prevProps.items && prevProps.items.length && this.props.items && !this.props.items.length) ||
      (prevProps.items && !prevProps.items.length && this.props.items && this.props.items.length) ||
      (prevProps.items && !this.props.items) ||
      (!prevProps.items && this.props.items)
    ) {
      onFetchPhotos(teamID, authEmail);
      this.setState({
        btnNavPrev: false,
        btnNavNext: true,
        scrollPosition: 0
      });
    }
  }

  handleListHorizontalScroll = (direction) => {
    const scrollElement = this.propertiesWrapper;
    const scrollValue = this.propertyItem.current.offsetWidth + Number(window.getComputedStyle(this.propertyItem.current).getPropertyValue("margin-right").replace("px", ""));
    const wrapperWidth = this.propertiesContainer.current.offsetWidth + Number(window.getComputedStyle(this.propertiesContainer.current).getPropertyValue("margin-right").replace("px", ""));
    const totalWidth = scrollElement.current.offsetWidth + Number(window.getComputedStyle(scrollElement.current).getPropertyValue("margin-right").replace("px", ""));
    const maxScroll = Math.floor((totalWidth - (wrapperWidth - scrollValue)) / 100) * 100;
    const currentPos = window.getComputedStyle(scrollElement.current).getPropertyValue("-webkit-transform").split(/[()]/)[1];
    const currentX = currentPos.split(',')[4];

    // Block button until animation is finished
    this.setState({
      btnNavPause: true
    }, () => {
      setTimeout(() => {
        this.setState({
          btnNavPause: false
        });
      }, 400);
    });

    // Handle PREV scroll
    if (direction === "prev") {
      const newX = Number(currentX) + Number(scrollValue);

      if (-newX <= maxScroll) {
        this.setState({
          btnNavNext: true,
          scrollPosition: newX
        });
      }
      if (newX >= 0) {
        this.setState({
          btnNavPrev: false
        });
      }
    }
    // Handle NEXT scroll
    else if (direction === "next") {
      const newX = Number(currentX) - Number(scrollValue);

      if (-newX <= maxScroll) {
        this.setState({
          btnNavPrev: true,
          scrollPosition: newX
        });
      }
      if (-newX >= maxScroll) {
        this.setState({
          btnNavNext: false
        });
      }
    }
  };

  render() {
    const {match, items, loading, branding, intl, onFetchData, photos, loadingPhotos} = this.props;
    const {btnNavPrev, btnNavNext, btnNavPause, scrollPosition} = this.state;
    const teamID = match && match.params && match.params.team;
    const metaLocale = intl.locale === "pl" ? "pl_PL" : "en_GB";
    const hasBuildings = items && items.length > 0;

    return (
      <div className={styles.mainContainer}>
        <Helmet>
          <title>{`${branding.name} Property Particular | REDD`}</title>
          <meta property="og:title" content={`${branding.name} Property Particular | REDD`}/>
          <meta property="og:description"
                content={intl.formatMessage({id: "PROPERTY_PARTICULARS.PHRASE.META_DESCRIPTION"}, {team: branding.name})}/>
          <meta property="og:image" content={toAbsoluteUrl("/media/meta-image-property-particulars.png")}/>
          <meta property="og:locale" content={metaLocale}/>
          <meta property="og:url" content={window.location.href}/>
        </Helmet>
        <PropertyParticularsHeader
          view="list"
          branding={branding}
          teamID={teamID}
          onFilterMarket={onFetchData}
        />
        {loading ? <LoadingScreen brand brandColor={branding.brandBgColor || "#4a9dfd"}/> :
          <div ref={this.propertiesContainer} className={styles.propertiesContainer}>
            {hasBuildings ?
              <div ref={this.propertiesWrapper} className={styles.propertiesWrapper}
                   style={{transform: `translateX(${scrollPosition}px)`}}>
                {hasBuildings && items.map((item, index) => {
                    const officeAddress = [
                      ...item.addressStreet ? [item.addressStreet] : [],
                      ...item.district ? [item.district] : [],
                      ...item.addressCity ? [item.addressCity] : []
                    ];
                    const officeCompletionYear = [
                      ...item.commissioningQuarter ? [`Q${item.commissioningQuarter}`] : [],
                      ...item.commissioningYear ? [item.commissioningYear] : []
                    ];
                    const officeRentRange = [
                      ...item.rentFromEur ? [toNumberFormat(item.rentFromEur)] : [],
                      ...item.rentToEur ? [toNumberFormat(item.rentToEur)] : []
                    ];
                    const officeRetailRentRange = [
                      ...item.retailRentPriceEur ? [toNumberFormat(item.retailRentPriceEur)] : [],
                      ...item.retailRentPriceToEur ? [toNumberFormat(item.retailRentPriceToEur)] : []
                    ];
                    const propertyUrlSeparator = match?.url.substr(-1) !== '/' ? '/' : '';
                    const propertyUrl = `${match?.url}${propertyUrlSeparator}${item?.pk}/`;

                    return (
                      <div key={index} ref={this.propertyItem} className={styles.propertyItem}>
                        <div className={styles.propertyHeader}>
                          <h2>{handleEmptyValue(item.name, true)}</h2>
                          <p className={styles.propertyAddress}>{officeAddress.join(", ")}</p>
                        </div>

                        <div className={styles.propertySlider}>
                          {loadingPhotos ? <LoadingScreen brand
                                                          brandColor={branding.brandBgColor || "#4a9dfd"}/> : photos && photos.length && photos.filter(photo => photo.officeId === item.pk).length && (
                            <img key={index}
                                 src={photos.filter(photo => photo.officeId === item.pk)[0].mainPhotoThumbnail}
                                 alt={item.name}/>
                          )}
                          {/*<div className={styles.mediaActions}>*/}
                          {/*    <span><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.3D_TOUR"/></span>*/}
                          {/*    <span><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.VIDEO"/></span>*/}
                          {/*</div>*/}
                        </div>
                        <div className={styles.propertyContent}>
                          <h3><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.AVAILABLE_AREA"/></h3>
                          <div className={styles.previewContainer}>
                            <div className={styles.previewWrapper}>
                              <div className={styles.previewItem}>
                                <span
                                  className={styles.previewValue}>{toNumberFormat(item.totalAreaOfficeAvailable, 'area')}</span>
                                <span className={styles.previewLabel}><FormattedMessage
                                  id="PROPERTY_PARTICULARS.PHRASE.OFFICE_AREA"/></span>
                              </div>
                              <div className={styles.previewItem}>
                                <span
                                  className={styles.previewValue}>{toNumberFormat(item?.totalAreaRetailAvailable, 'area')}</span>
                                <span className={styles.previewLabel}><FormattedMessage
                                  id="PROPERTY_PARTICULARS.PHRASE.RETAIL_AREA"/></span>
                              </div>
                              <div className={styles.previewItem}>
                                <span
                                  className={styles.previewValue}>{handleEmptyValue(item?.numberOfUnitsAvailable, true)}</span>
                                <span className={styles.previewLabel}><FormattedMessage
                                  id="PROPERTY_PARTICULARS.PHRASE.AVAILABLE_UNITS"/></span>
                              </div>
                              <div className={styles.previewItem}>
                                <Link to={propertyUrl} className={styles.previewLink}><FormattedMessage
                                  id="PROPERTY_PARTICULARS.PHRASE.CHECK"/><IconColorMUI icon="arrow_forward_ios"/></Link>
                              </div>
                            </div>
                          </div>
                          <ListItemAccordion
                            title={<h3><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.OFFICE_DETAILS"/></h3>}>
                            <div className={styles.dataWrapper}>
                              <div className={styles.dataItem}>
                                <span className={styles.dataValue}>{toNumberFormat(item.totalAreaNetto, 'area')}</span>
                                <span className={styles.dataLabel}><FormattedMessage
                                  id="PROPERTY_PARTICULARS.PHRASE.NET_AREA"/></span>
                              </div>
                              <div className={styles.dataItem}>
                                <span className={styles.dataValue}>{toNumberFormat(item.totalAreaBrutto, 'area')}</span>
                                <span className={styles.dataLabel}><FormattedMessage
                                  id="PROPERTY_PARTICULARS.PHRASE.GROSS_AREA"/></span>
                              </div>
                              <div className={styles.dataItem}>
                                <span className={styles.dataValue}>{handleEmptyValue(item.buildingClass)}</span>
                                <span className={styles.dataLabel}><FormattedMessage
                                  id="PROPERTY_PARTICULARS.PHRASE.BUILDING_CLASS"/></span>
                              </div>
                              <div className={styles.dataItem}>
                                <span className={styles.dataValue}>{officeCompletionYear.join(" ")}</span>
                                <span className={styles.dataLabel}><FormattedMessage
                                  id="PROPERTY_PARTICULARS.PHRASE.COMPLETION_YEAR"/></span>
                              </div>
                              <div className={styles.dataItem}>
                                <span className={styles.dataValue}>{handleEmptyValue(item.certificate)}</span>
                                <span className={styles.dataLabel}><FormattedMessage
                                  id="PROPERTY_PARTICULARS.PHRASE.CERTIFICATE"/></span>
                              </div>
                              <div className={styles.dataItem}>
                                <span className={styles.dataValue}><UnitAvailabilityPreview
                                  date={item.buildingAvailableFrom}/></span>
                                <span className={styles.dataLabel}><FormattedMessage
                                  id="PROPERTY_PARTICULARS.PHRASE.AVAILABLE_FROM"/></span>
                              </div>
                            </div>
                          </ListItemAccordion>
                          <ListItemAccordion
                            title={<h3><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.LEASE_TERMS"/></h3>}>
                            <div className={styles.dataWrapper}>
                              <div className={styles.dataItem}>
                                <span
                                  className={styles.dataValue}>{toNumberFormat(officeRentRange.join(" - "), 'range', '€')}</span>
                                <span className={styles.dataLabel}><FormattedMessage
                                  id="PROPERTY_PARTICULARS.PHRASE.OFFICE_RENT"/></span>
                              </div>
                              <div className={styles.dataItem}>
                                <span
                                  className={styles.dataValue}>{toNumberFormat(officeRetailRentRange.join(" - "), 'range', '€')}</span>
                                <span className={styles.dataLabel}><FormattedMessage
                                  id="PROPERTY_PARTICULARS.PHRASE.RETAIL_RENT"/></span>
                              </div>
                              <div className={styles.dataItem}>
                                <span
                                  className={styles.dataValue}>{toNumberFormat(item.serviceChargesPln, "currency", "PLN")}</span>
                                <span className={styles.dataLabel}><FormattedMessage
                                  id="PROPERTY_PARTICULARS.PHRASE.SERVICE_CHARGE"/></span>
                              </div>
                              <div className={styles.dataItem}>
                                <span
                                  className={styles.dataValue}>{toNumberFormat(item.parkingGroundRentEur, 'currency')}</span>
                                <span className={styles.dataLabel}><FormattedMessage
                                  id="PROPERTY_PARTICULARS.PHRASE.PARKING_GROUND"/></span>
                              </div>
                              <div className={styles.dataItem}>
                                <span
                                  className={styles.dataValue}>{toNumberFormat(item.parkingUndergroundRentEur, 'currency')}</span>
                                <span className={styles.dataLabel}><FormattedMessage
                                  id="PROPERTY_PARTICULARS.PHRASE.PARKING_UNDERGROUND"/></span>
                              </div>
                              <div className={styles.dataItem}>
                                <span className={styles.dataValue}>{toNumberFormat(item.commonAreaFactor)}</span>
                                <span className={styles.dataLabel}><FormattedMessage
                                  id="PROPERTY_PARTICULARS.PHRASE.COMMON_AREA_FACTOR"/></span>
                              </div>
                              <div className={styles.dataItem}>
                                <span className={styles.dataValue}>{handleEmptyValue(item.parkingFactor)}</span>
                                <span className={styles.dataLabel}><FormattedMessage
                                  id="PROPERTY_PARTICULARS.PHRASE.PARKING_FACTOR"/></span>
                              </div>
                              <div className={styles.dataItem}>
                                <span className={styles.dataValue}>{handleEmptyValue(item.minRentalYears)}</span>
                                <span className={styles.dataLabel}><FormattedMessage
                                  id="PROPERTY_PARTICULARS.PHRASE.MIN_RENTAL_PERIOD"/></span>
                              </div>
                            </div>
                          </ListItemAccordion>
                          {item.amenities && item.amenities.length ? (
                            <ListItemAccordion
                              title={<h3><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.AMENITIES"/></h3>}>
                              <div className={clsx(styles.dataWrapper, styles.slim)}>
                                {item.amenities.sort((a, b) => b.length - a.length).map((amenity, index) => {
                                  return (
                                    <div key={index} className={styles.dataItem}>
                                      <span className={styles.itemChecked}><IconColorMUI
                                        icon="done"/>{intl.formatMessage({id: amenity})}</span>
                                    </div>
                                  );
                                })}
                              </div>
                            </ListItemAccordion>
                          ) : null}
                        </div>
                        <div className={styles.propertyActions}>
                          <Link to={propertyUrl}><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.MORE_INFO"/></Link>
                        </div>
                      </div>
                    );
                  }
                )}
              </div> : <NoData message={<FormattedMessage id="No buildings with available space."/>}/>
            }
            {hasBuildings &&
              <div className={styles.carouselNavigation}>
                <button id="navPrev"
                        className={clsx(styles.btnNav, !btnNavPrev && styles.disabled, btnNavPause && styles.inactive)}
                        onClick={() => this.handleListHorizontalScroll("prev")}>
                  <IconColorMUI icon="arrow_back_ios" size={30}/>
                </button>
                <button id="navNext"
                        className={clsx(styles.btnNav, !btnNavNext && styles.disabled, btnNavPause && styles.inactive)}
                        onClick={() => this.handleListHorizontalScroll("next")}>
                  <IconColorMUI icon="arrow_forward_ios" size={30}/>
                </button>
              </div>
            }
          </div>
        }
        <PropertyParticularsFooter intl={intl}/>
      </div>
    );
  }
}

export default injectIntl(connect()(PropertyParticularsList));
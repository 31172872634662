import get from "lodash/get";
import axios from "axios";

export function removeCSSClass(ele, cls) {
  const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
  ele.className = ele.className.replace(reg, " ");
}
export function addCSSClass(ele, cls) {
    ele.classList.add(cls);
}
export const toAbsoluteUrl = (pathname, host) => {
  return (host ? host : "") + "/" + pathname;
};
const errorHandler = (error) => {
  if (error.response?.status === 500) {
    return Promise.reject(error);
  }
  return Promise.reject({ ...error.response })
};
const successHandler = (axios, response) => {
  const token = get(response, 'data.key');
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Token ${token}`;
    localStorage.setItem('storageToken', token);
  }
  return response;
};

export const cancelTokenSource = axios.CancelToken.source();
const configureDefaults = axios => {
  axios.defaults.baseURL = process.env.REACT_APP_API_HOST || 'https://apitest.reddplatform.com';
  axios.defaults.responseType = 'json';
  axios.defaults.withCredentials = true;
  axios.defaults.cancelToken = cancelTokenSource.token;
};
const configureRequestInterceptor = (axios, store) => {
  axios.interceptors.request.use(
    config => {
      const {
        authApp: { authToken }
      } = store.getState();
      const storageToken = localStorage.getItem('storageToken');
      const token = authToken ? authToken : storageToken;

      if (token) {
        config.headers.Authorization = `Token ${token}`;
      }

      return config;
    },
    err => Promise.reject(err)
  );
};
const configureResponseInterceptor = axios => {
  axios.interceptors.response.use(
    response => successHandler(axios, response),
    error => errorHandler(error)
  );
};

export function setupAxios(axios, store) {
  configureDefaults(axios);
  configureRequestInterceptor(axios, store);
  configureResponseInterceptor(axios);
}
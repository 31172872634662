import React from "react";
import AdvanceListItem from "app/partials/components/AdvanceListItem/index";
import styles from "./UnitsPage.module.scss";
import tableStyles from "app/partials/components/TableProperties/index.module.scss";
import LoadingScreen from "app/partials/components/LoadingScreen";
import {Grid, Typography} from "@material-ui/core";
import {Portlet, PortletBody, PortletHeader, PortletHeaderToolbar} from "app/partials/content/Portlet";
import {FormattedMessage} from "react-intl";
import store from "app/store/store";
import {VIEW_TYPES} from "app/constants";
import {withRouter} from "react-router-dom";
import MapView from "app/pages/offices/components/MapView/MapView";
import MapLegend from "app/pages/offices/components/MapView/MapLegend";
import {TABLE_CELLS} from "app/partials/components/TableProperties";
import TableProperties from "app/partials/components/TableProperties/TableProperties";
import BadgeTitle from "app/partials/components/BadgeTitle";
import {isFiltersActive} from "app/partials/components/FiltersManageBar/constants";
import IsPro from "../../../utils/IsPro";
import ReactVisibilitySensor from "react-visibility-sensor";

let prevLocation;
class UnitsPage extends React.Component {
  constructor(props) {
    super(props);
    props.history.listen(nextLocation => {
      prevLocation = nextLocation.pathname;
    });
    this.state = {
      offset: 0,
      page: 1,
      viewType: props.viewType,
      selectedOffice: null,
      selectedItem: null,
      isReady: false,
      initLoaded: false,
      markerFilters: []
    };
  }
  componentDidMount() {
    const {viewType, getClusters, fetchUnitsList, fetchUnitsInfiniteList, cleanUnitsInfiniteList, setLatestActiveTablePage, filterReset} = this.props;
    const updateData = prevLocation === undefined || !prevLocation.includes('/building/');
    if(updateData) {
      setLatestActiveTablePage(0);
      filterReset(["market", "isEditable"]);

      const params = {
        page: 1,
        ...store.getState().filters,
        order: "-",
        orderBy: ""
      };
      if(viewType === VIEW_TYPES.MAP_VIEW) {
        getClusters({params});
        cleanUnitsInfiniteList();
        fetchUnitsInfiniteList({params});
      }
      else {
        fetchUnitsList({params});
      }
    }
  }
  componentDidUpdate() {
    const {viewType, getClusters, fetchUnitsList, fetchUnitsInfiniteList, cleanUnitsInfiniteList} = this.props;
    const updateData = prevLocation === undefined || !prevLocation.includes('/building/');
    if(viewType !== this.state.viewType) {
      const params = {
        page: 1,
        ...store.getState().filters,
        order: "-",
        orderBy: ""
      };
      this.setState({
        viewType
      }, () => {
        if(updateData) {
          if(viewType === VIEW_TYPES.MAP_VIEW) {
            getClusters({params});
            cleanUnitsInfiniteList();
            fetchUnitsInfiniteList({params});
          } else {
            fetchUnitsList({params});
          }
        }
      });
    }
  }

  // Render List View
  renderUnitsListView = () => {
    const {units, fetchUnitsList, loading, user: {marketName}, totalItems} = this.props;

    return <TableProperties
      tableLabel={
        <BadgeTitle badge={marketName}>
          <FormattedMessage id="MENU.UNITS"/>
        </BadgeTitle>
      }
      headRows={
        [
          { id: 'checkable', numeric: false, disablePadding: false, label: '', sort: false },
          { id: 'office_name', numeric: false, disablePadding: false, label: 'VOCABULARY.Building', sort: true },
          { id: 'area', numeric: true, disablePadding: false, label: 'VOCABULARY.Area', sort: true },
          { id: 'floor', numeric: true, disablePadding: false, label: 'GENERAL.PHRASE.FLOOR', sort: true },
          { id: 'type', numeric: false, disablePadding: false, label: 'GENERAL.PHRASE.TYPE', sort: true },
          { id: 'status', numeric: false, disablePadding: false, label: 'BUILDING.PHRASE.STATUS', sort: true },
          { id: 'available_from', numeric: false, disablePadding: false, label: 'VOCABULARY.Availability', sort: true },
          { id: 'updated_at', numeric: false, disablePadding: false, label: 'VOCABULARY.Update', sort: true },
          { id: 'actions', numeric: true, disablePadding: false, label: '', sort: false },
        ]
      }
      bodyRows={units?.items}
      itemsPage={units?.itemsPage}
      itemsPerPage={units?.itemsPerPage}
      itemsCount={units?.itemsCount}
      totalItems={totalItems}
      dataSource="units"
      dataTarget={TABLE_CELLS.UNITS}
      handleFetchData={fetchUnitsList}
      loading={loading}
      filterBarEnable
      onEnterViewport={this.handleOnViewport}
    />;
  };
  // Render Map View
  renderUnitsMapView = () => {
    const {
      clustersLoaded,
      allUnits,
      maxPages,
      itemsCount,
      allUserStates,
      fetchOffice,
      fetchOfficeUnits,
      officeUnits,
      officeUnitsLoading,
      marketName,
    } = this.props;
    const {markerFilters} = this.state;

    return(
      <Portlet style={{marginBottom: 0}} className={isFiltersActive() && styles.filterBarEnable}>
        <PortletHeader
          title={
            <Typography variant="h3">
              <BadgeTitle badge={marketName}><FormattedMessage id="BUILDING.PHRASE.ALL_UNITS"/></BadgeTitle>
              {itemsCount && <FormattedMessage id="GENERAL.PHRASE.TOTAL_RESULTS" values={{results: <span className={tableStyles.resultsValue}>{itemsCount}</span>, prefix: " - "}}/>}
            </Typography>
          }
          toolbar={
            <PortletHeaderToolbar>
              <MapLegend markerFilters={markerFilters} onFilter={this.handleFilterMarkers}/>
            </PortletHeaderToolbar>
          }
        />
        <PortletBody fit>
          <ReactVisibilitySensor
            onChange={(isVisible) => this.handleOnViewport(isVisible)}
            scrollCheck
            partialVisibility
          >
            <Grid container spacing={0}>
              <Grid item xs={9}>
                {clustersLoaded ?
                  <MapView
                    getOffice={fetchOffice}
                    getOfficeUnits={fetchOfficeUnits}
                    markers={this.props.clusters?.markers}
                    office={this.props.office}
                    officeLoading={this.props.officeLoading}
                    officeUnits={officeUnits}
                    officeUnitsLoading={officeUnitsLoading}
                    clustersLoading={this.props.clustersLoading}
                    selectedOffice={this.state.selectedOffice}
                    markerFilters={markerFilters}
                    onSelectOffice={this.handleSelectOffice}
                    onCloseSelectedOffice={this.handleListItemClick}
                    allUserStates={allUserStates}
                  /> : <LoadingScreen/>}

              </Grid>
              <Grid item xs={3}>
                <div className={styles.listView}>
                  <Grid container direction="column">
                    {allUnits !== null && allUnits.map((item, index) => {
                      const {office: {name, addressCity, addressStreet, mainPhotoThumbnail, isEditable}} = item;
                      return (
                        <AdvanceListItem
                          data={item}
                          key={index+this.state.offset}
                          name={name}
                          city={addressCity}
                          street={addressStreet}
                          thumb={mainPhotoThumbnail}
                          isEditable={isEditable}
                          onListItemClick={this.handleListItemClick}
                          selectedItem={this.state.selectedItem}
                        />
                      );
                    })}
                  </Grid>
                  <ReactVisibilitySensor
                    onChange={(isVisible) => this.handleWaypointEnter(isVisible)}
                    scrollCheck
                    partialVisibility
                  >
                    <div className={styles.listLoader}>
                      {this.props.clusters === null || !this.props.clusters?.markers.length ? <Typography variant="body1"><FormattedMessage id="GENERAL.PHRASE.No result found"/></Typography> : (this.state.page + 1 >= maxPages ? '' : <LoadingScreen narrow/>)}
                    </div>
                  </ReactVisibilitySensor>
                </div>
              </Grid>
            </Grid>
          </ReactVisibilitySensor>
        </PortletBody>
      </Portlet>
    );
  };

  // Handlers
    // Handle fetch data on enter viewport
  handleOnViewportFetch = (isVisible) => {
    const {
      viewType,
      fetchUnitsList,
      getClusters,
      fetchUnitsInfiniteList,
      cleanUnitsInfiniteList,
      callReddClickEvent,
      loading
    } = this.props;
    const {initLoaded} = this.state;
    const isMapView = viewType === VIEW_TYPES.MAP_VIEW;
    const updateData = prevLocation === undefined || !prevLocation.includes('/building/');
    // Fetch Data on enter viewport
    if(isVisible && !loading && !initLoaded) {
      this.setState({
        initLoaded: true
      }, () => {
        if(updateData) {
          const params = {
            ...store.getState().filters,
            page: this.state.page,
            orderBy: 'updated_at'
          };
          if(isMapView) {
            getClusters({params});
            cleanUnitsInfiniteList();
            fetchUnitsInfiniteList({params});
          } else {
            fetchUnitsList({params});
          }
        }
      });
    }
    // Tracking event
    if(isVisible && !this.state.isReady) {
      this.setState({
        isReady: true
      }, () => {
        callReddClickEvent(
          "estates-officeunit-list",
          "officeunit"
        );
      })
    }
  };
  handleOnViewport = (isInViewport) => {
    this.handleOnViewportFetch(isInViewport);
  }
  // Load next units results to Units Infinite List when scroll reach bottom of the list
  handleWaypointEnter = (isVisible) => {
    const {offset, page} = this.state;
    const {loading, fetchUnitsInfiniteList, maxPages} = this.props;
    let params;

    if(isVisible && !loading && this.state.page + 1 < maxPages) {
      this.setState({
        offset: parseInt(offset) + 20,
        page: parseInt(page) + 1
      }, () => {
        params = {
          page: this.state.page,
          ...store.getState().filters
        };
        fetchUnitsInfiniteList({params});
      });
    }
  };
  // On ListItem click pass office details from AdvanceListItem to AgentMapView
  // Also trigger when closing TableDrawer from AgentMapView component (selectedOffice - set to null)
  handleListItemClick = (officeData) => {
    const { fetchOfficeUnits } = this.props;
    this.setState({
      selectedOffice: officeData
    }, () => officeData && fetchOfficeUnits(officeData.officeId))
  };
  // On Map Marker click pass UnitId from AgentMapView to AdvanceListItem
  handleSelectOffice = (unitId) => {
    this.setState({
      selectedItem: unitId
    });
  };
  // Handle filter markers
  handleFilterMarkers = (filters) => {
    this.setState({
      markerFilters: filters
    });
  }

  render() {
    const {viewType, clustersLoaded} = this.props;
    const isMapView = viewType === VIEW_TYPES.MAP_VIEW;
    return (
      <IsPro paywall>
        {isMapView ? (clustersLoaded ? this.renderUnitsMapView() : <LoadingScreen/>) : this.renderUnitsListView()}
      </IsPro>
    )
  }
}

export default withRouter(UnitsPage);

import React from "react";
import styles from "../index.module.scss";
import {FormattedMessage} from "react-intl";

export default function TransactionPolicyMessage({market, quarter}) {
  return(
    <div className={styles.policyMessageWrapper}>
      <p><FormattedMessage id="VOCABULARY.This market is locked due to symmetry policy"/></p>
      <p>
        <FormattedMessage
          id="VOCABULARY.Add your lease transactions from market for this quater to unlock comparables."
          values={{
            market: <strong>{market}</strong>,
            quarter: <strong>{quarter}</strong>,
          }}
        />
      </p>
    </div>
  );
}
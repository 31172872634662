import React, {Component} from "react";
import {Typography} from "@material-ui/core";
import styles from "./index.module.scss";
import LoadingScreen from "app/partials/components/LoadingScreen";
import {FormattedMessage} from "react-intl";

export class InputUploadMultiple extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
    this.fileInputRef = React.createRef();
  }
  triggerInputFile = () => {
    this.fileInputRef.current.click();
  };

  handleSelectFiles = e => {
    const {propertyID, onFileUpload} = this.props;
    const { target } = e;
    if (target.files) {
      Object.values(target.files).forEach((file) => {
        const fileType = file?.type;
        const fileTypeSplit = fileType.split("/");
        const finalFileType = fileTypeSplit[1];
        this.reader = new FileReader();
        this.setState({
          loading: true
        });
        this.reader.onload = readerEvent => {
          onFileUpload(propertyID, {
            data: readerEvent.target.result,
            type: file.type,
            name: `temp_file_name.${finalFileType}`,
          });
          this.setState({
            loading: false
          });
        };
        this.reader.readAsArrayBuffer(file);
      });
    }
  };

  render() {
    return (
      <form>
        <input style={{ display: 'none' }} ref={this.fileInputRef} type="file" accept=".jpg,.jpeg,.png" multiple onChange={this.handleSelectFiles} />
        {this.state.loading ? <LoadingScreen/> : (
          <div className={styles.uploadPlanBox} onClick={this.triggerInputFile}>
            <Typography><FormattedMessage id="GENERAL.PHRASE.UPLOAD_IMAGES_MESSAGE" values={{click: <strong><FormattedMessage id="GENERAL.PHRASE.CLICK"/></strong>}}/></Typography>
            <Typography variant="body2">(<FormattedMessage id="GENERAL.PHRASE.UPLOAD_IMAGES_ALLOWED"/>: <strong>.jpg</strong>, <strong>.png</strong>)</Typography>
          </div>
        )}
      </form>
    );
  }
}
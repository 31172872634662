import React from "react";
import styles from "./index.module.scss";
import {FormattedMessage} from "react-intl";
import {toAbsoluteUrl} from "../../../../../_metronic";
import APPLICATION_VIEWS from "../../../../constants/APPLICATION_VIEWS";
import cn from "classnames";


export default class CAStepsIntro extends React.Component {
  render() {
    const {currentApplicationView} = this.props;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    const isIndustrialView = currentApplicationView === APPLICATION_VIEWS.INDUSTRIAL;

    return(
      <div className={cn(
        styles.introBoxContainer,
        isIndustrialView ? styles.introBoxIndustrial : undefined
      )}>
        {isOfficeView &&
        <div className={styles.introBoxWrapper}>
          <h3>Time to lease</h3>
          <p>
            <FormattedMessage
              id="COMPETITION_ANALYSIS.PHRASE.TIME_TO_LEASE_DESCRIPTION"
            />
          </p>
          <img
            src={toAbsoluteUrl(`media/icons/competition-analysis/ca-key.svg`)}
            alt="Time to lease"
          />
        </div>
        }
        <div className={styles.introBoxWrapper}>
          <h3>6M New Vacancy</h3>
          <p>
            <FormattedMessage
              id="COMPETITION_ANALYSIS.PHRASE.6M_NEW_VACANCY_DESCRIPTION"
            />
          </p>
          <img
            src={toAbsoluteUrl(`media/icons/competition-analysis/ca-area.svg`)}
            alt="6M New Vacancy"
          />
        </div>
        <div className={styles.introBoxWrapper}>
          <h3>6M Take-Up</h3>
          <p>
            <FormattedMessage
              id="COMPETITION_ANALYSIS.PHRASE.6M_LEASE_UPTAKE_DESCRIPTION"
            />
          </p>
          <img
            src={toAbsoluteUrl(`media/icons/competition-analysis/ca-area.svg`)}
            alt="6M Take-Up"
          />
        </div>
        <div className={styles.introBoxWrapper}>
          <h3>Last Transaction</h3>
          <p>
            <FormattedMessage
              id="COMPETITION_ANALYSIS.PHRASE.DEAL_DONE_STAMP_DESCRIPTION"
            />
          </p>
          <img
            src={toAbsoluteUrl(`media/icons/competition-analysis/ca-deal.svg`)}
            alt="Last Transaction"
          />
        </div>
      </div>
    );
  }
}
import React, {useEffect} from "react";
import {Grid, TextField, Typography} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import {withFormik} from "formik";
import SingleSelect from "../../SingleSelect";

const Form = (
  {
    values,
    handleChange,
    handleFormikValues,
    handleIsActive,
    officeView,
    previewOnly,
    importTenantData,
    data,
    mode
  }
) => {
  useEffect(() => {
    handleFormikValues(values);
  }, [values, handleFormikValues]);

  useEffect(() => {
    if (values.fullName && values.email && values.phoneNumber && (mode === "create" ? values.market : true)) {
      handleIsActive(false);
    } else {
      handleIsActive(true);
    }
  }, [values, handleIsActive, mode]);

  useEffect(() => {
    if(importTenantData) {
      const formData = data?.form;
      values.fullName = data?.crmLastName;
      values.email = data?.crmEmail;
      values.phoneNumber = data?.crmPhoneNumber;
      values.areaFrom = data?.crmAreaFrom;
      values.areaTo = data?.crmAreaTo;
      values.tenantsName = formData?.Name;
      values.tenantsDescription = formData?.TenantsDescription;
      values.location = formData?.Location;
      values.startDate = formData?.StartDate;
      values.typeOfActivity = formData?.TypeOfActivity;
      values.technicalRequirements = formData?.TechnicalRequirements;
      values.additionalInformation = formData?.Message;
    }
  }, [values, data, importTenantData]);

  return (
    <form>
      <Grid container spacing={2} style={{marginBottom: "2rem"}}>
        <Grid item xs={12}>
          <Typography variant="body1" style={{color: "#ff4626"}}><FormattedMessage id="GENERAL.PHRASE.Basic data"/></Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={<FormattedMessage id="FORM.FIELD.FULLNAME" />}
            value={values.fullName}
            onChange={handleChange}
            name="fullName"
            required
            fullWidth
            disabled={previewOnly}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={<FormattedMessage id="FORM.FIELD.EMAIL_ADDRESS" />}
            value={values.email}
            onChange={handleChange}
            name="email"
            required
            fullWidth
            disabled={previewOnly}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={<FormattedMessage id="FORM.FIELD.PHONE_NUMBER" />}
            value={values.phoneNumber}
            onChange={handleChange}
            name="phoneNumber"
            required
            fullWidth
            disabled={previewOnly}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={<FormattedMessage id="BI.TABLE.AREA_FROM"/>}
            value={values.areaFrom}
            onChange={handleChange}
            name="areaFrom"
            fullWidth
            disabled={previewOnly}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={<FormattedMessage id="BI.TABLE.AREA_TO"/>}
            value={values.areaTo}
            onChange={handleChange}
            name="areaTo"
            fullWidth
            disabled={previewOnly}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={<FormattedMessage id="ADDITIONAL INFORMATION"/>}
            value={values.additionalInformation}
            onChange={handleChange}
            name="additionalInformation"
            multiline
            fullWidth
            disabled={previewOnly}
          />
        </Grid>
        {mode === "create" &&
        <Grid item xs={4}>
          <TextField
            label={<FormattedMessage id="MARKET"/>}
            value={values.market}
            onChange={handleChange}
            name="market"
            fullWidth
            required
            disabled={previewOnly}
          />
        </Grid>
        }
        <Grid item xs={mode === "create" ? 4 : 6}>
          <SingleSelect
            label={<FormattedMessage id="GENERAL.PHRASE.REGION"/>}
            inputName="region"
            options={["PL", "CS", "SK", "HU", "RO"]}
            defaultSelect={values?.region}
            onChange={handleChange}
            disabled={previewOnly}
          />
        </Grid>
        <Grid item xs={mode === "create" ? 4 : 6}>
          <SingleSelect
            label={<FormattedMessage id="GENERAL.PHRASE.LANGUAGE"/>}
            inputName="lang"
            options={["EN", "PL", "CS", "SK", "HU", "RO"]}
            defaultSelect={values?.lang}
            onChange={handleChange}
            disabled={previewOnly}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" style={{marginTop: "2rem", color: "#ff4626"}}><FormattedMessage id="GENERAL.PHRASE.Interview"/></Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={<FormattedMessage id="LM.PHRASE.LEAD_NAME" />}
            value={values.leadName}
            onChange={handleChange}
            name="leadName"
            fullWidth
            disabled={previewOnly}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={<FormattedMessage id="TENANT'S NAME"/>}
            value={values.tenantsName}
            onChange={handleChange}
            name="tenantsName"
            multiline
            fullWidth
            disabled={previewOnly}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={<FormattedMessage id="LOCATION"/>}
            value={values.location}
            onChange={handleChange}
            name="location"
            fullWidth
            disabled={previewOnly}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={<FormattedMessage id="START DATE"/>}
            value={values.startDate}
            onChange={handleChange}
            name="startDate"
            fullWidth
            disabled={previewOnly}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={<FormattedMessage id="TENANT'S DESCRIPTION"/>}
            value={values.tenantsDescription}
            onChange={handleChange}
            name="tenantsDescription"
            multiline
            fullWidth
            disabled={previewOnly}
          />
        </Grid>
        {!officeView && (
        <Grid item xs={12}>
          <TextField
            label={<FormattedMessage id="TYPE OF ACTIVITY"/>}
            value={values.typeOfActivity}
            onChange={handleChange}
            name="typeOfActivity"
            multiline
            fullWidth
            disabled={previewOnly}
          />
        </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            label={<FormattedMessage id="TECHNICAL REQUIREMENTS"/>}
            value={values.technicalRequirements}
            onChange={handleChange}
            name="technicalRequirements"
            multiline
            fullWidth
            disabled={previewOnly}
          />
        </Grid>
      </Grid>
    </form>
  );
};

const FormWithFormik = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({data}) => ({
    leadName: data?.leadName || "",
    fullName: data?.form?.LastName || "",
    email: data?.form?.Email || "",
    phoneNumber: data?.form?.PhoneNumber || "",
    areaFrom: data?.form?.AreaFrom ||"",
    areaTo: data?.form?.AreaTo || "",
    tenantsName: data?.form?.Name || "",
    tenantsDescription: data?.form?.TenantsDescription || "",
    additionalInformation: data?.form?.Message || "",
    location: data?.form?.Location || "",
    startDate: data?.form?.StartDate || "",
    typeOfActivity: data?.form?.TypeOfActivity || "",
    technicalRequirements: data?.form?.TechnicalRequirements || "",
    region: data?.region || "PL",
    lang: data?.lang?.toUpperCase() || "PL",
    market: data?.form?.Market || ""
  }),
})(Form);

export default FormWithFormik;

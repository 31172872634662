import React from "react";
import {FormControl, InputLabel, makeStyles, MenuItem, Select} from "@material-ui/core";
import LoadingScreen from "../LoadingScreen";

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120,
    width: '100%'
  },
  maxHeight: {
    maxHeight: 300
  }
}));

export default function SingleSelect(props) {
  const {label, options, optionsValue, optionsLabel, defaultSelect, inputName, required, onChange, disabled, loading} = props;
  const classes = useStyles();
  const defSelects = defaultSelect !== null ? defaultSelect : '';
  const [selected, setSelected] = React.useState(defSelects);

  function handleChange(event) {
    setSelected(event.target.value);
    if(onChange) {
      onChange(event);
    }
    else if(props?.onSelectedValues) {
      props.onSelectedValues(event.target.value, event.target.name);
    }
  }
  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor={inputName}>{label}</InputLabel>
      {loading ? <LoadingScreen loaderStyle={{width: "1rem", height: "1rem"}}/> :
      <Select
        value={selected}
        onChange={handleChange}
        inputProps={{
          name: inputName,
          id: inputName,
        }}
        MenuProps={{ classes: { paper: classes.maxHeight } }}
        disabled={disabled}
      >
        {!required && (
        <MenuItem value="">
          <em>---</em>
        </MenuItem>
        )}
        {options?.map((option) => {
          return(
            <MenuItem
              key={optionsValue ? option[optionsValue] : option}
              value={optionsValue ? option[optionsValue] : option}
            >
              {optionsLabel ? option[optionsLabel] : option}
            </MenuItem>
          );
        })}
      </Select>
      }
    </FormControl>
  );
}

import React from "react";
import objectPath from "object-path";
import {Link} from "react-router-dom";
import MenuItemText from "./MenuItemText";
import MenuSubmenu from "./MenuSubmenu";
import clsx from "clsx";
import {callReddClickEvent} from "app/crud/user.crud";
import connect from "react-redux/es/connect/connect";
import {onClassChange} from "../../../app/utils/helpers";
import {injectIntl} from "react-intl";
import _ from "lodash";
import BadgeLabel from "../../../app/partials/components/BadgeLabel";
import {ROUTES} from "../../../app/constants";

class MenuItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuCollapsed: document.body.classList.contains("kt-aside--minimize")
    }
  }
  componentDidMount() {
    onClassChange(document.body, () => {
      const isMenuCollapsed = document.body.classList.contains("kt-aside--minimize");
      this.setState({
        menuCollapsed: isMenuCollapsed
      });
    });
  }

  asideLeftLIRef = React.createRef();
  isDropdown = document.body.classList.contains("kt-aside-menu--dropdown");

  submenuToggle =
    this.props.item.toggle === "click"
      ? "click"
      : objectPath.get(this.props.item, "submenu.type") === "tabs"
      ? "tabs"
      : "hover";

  /**
   * Use for fixed left aside menu, to show menu on mouseenter event.
   * @param event Event
   */
  mouseEnter = (event) => {
    if (!this.isDropdown) {
      return;
    }

    if (this.props.item.submenu) {
      this.asideLeftLIRef.current.classList.add("kt-menu__item--hover");
      this.asideLeftLIRef.current.setAttribute(
        "data-ktmenu-submenu-toggle",
        this.submenuToggle
      );
    }
  };

  /**
   * Mouse Leave event
   * @param event: MouseEvent
   */
  mouseLeave = (event) => {
    if (!this.isDropdown) {
      return;
    }

    if (this.props.item.submenu && this.submenuToggle === "hover") {
      this.asideLeftLIRef.current.classList.remove("kt-menu__item--hover");
      this.asideLeftLIRef.current.removeAttribute("data-ktmenu-submenu-toggle");
    }
  };

  isMenuItemIsActive = (item) => {
    if (item.submenu) {
      return this.isMenuRootItemIsActive(item);
    }

    if (!item.page) {
      return false;
    }
    return this.props.currentUrl === `/${item.page}`;
  };

  isMenuRootItemIsActive = (item) => {
    for (const subItem of item.submenu) {
      if (this.isMenuItemIsActive(subItem)) {
        return true;
      }
    }

    return false;
  };

  handleMenuItemClick = () => {
    const {item, onMenuItemClick, callReddClickEvent} = this.props;

    if(item?.submenu && item?.reddClickEvent) {
      callReddClickEvent && callReddClickEvent(
        item?.reddClickEvent,
        'user'
      );
    }
    else {
      onMenuItemClick && onMenuItemClick();
    }
  }

  render() {
    const {
      intl,
      item,
      currentUrl,
      parentItem,
      layoutConfig,
      onMenuItemClick,
      userFollowersCount,
      navMetrics,
      isDummyViewActive
    } = this.props;
    const isActive = this.isMenuItemIsActive(item);

    return (
      <li
        ref={this.asideLeftLIRef}
        aria-haspopup="true"
        data-placement="right"
        data-ktmenu-submenu-mode={item.mode}
        onMouseEnter={this.mouseEnter}
        onMouseLeave={this.mouseLeave}
        onClick={() => this.handleMenuItemClick()}
        className={clsx(
          "kt-menu__item",
          {
            "kt-menu__item--submenu": item.submenu,
            "kt-menu__item--open kt-menu__item--here": isActive && item.submenu,
            // "kt-menu__item--open": item.submenu && (item.title === "Offices" || item.title === "Industrial"),
            "kt-menu__item--active kt-menu__item--here":
              isActive && !item.submenu,
            "kt-menu__item--icon-only": item["icon-only"],
          },
          item["custom-class"]
        )}
        data-ktmenu-dropdown-toggle-class={item["dropdown-toggle-class"]}
      >
        {!item.submenu && !item?.customFunction &&
          ((item?.pageExternal || isDummyViewActive) ? (
            <a
              href={isDummyViewActive ? ROUTES.REDD_TOWER_REDIRECT_WWW2 : `${item?.page}`}
              target="_blank"
              rel="noopener noreferrer"
              className="kt-menu__link kt-menu__toggle"
            >
              <MenuItemText
                item={item}
                parentItem={parentItem}
                userFollowersCount={userFollowersCount}
              />
            </a>
          ) : (
            <Link
              to={`/${item.page || ""}`}
              className="kt-menu__link kt-menu__toggle"
              style={item?.inactive ? {pointerEvents: "none"} : undefined}
            >
              <MenuItemText
                item={item}
                parentItem={parentItem}
                userFollowersCount={userFollowersCount}
              />
              {
                item?.isFeatured &&
                (
                  item?.navMetricsKey ?
                    _.isNumber(navMetrics?.[`${item?.navMetricsKey}`]) &&
                    navMetrics?.[`${item?.navMetricsKey}`] !== 0 :
                    true
                )
                && (
                <BadgeLabel
                  label={
                    item?.featuredLabel ? intl?.formatMessage({id: item?.featuredLabel}) :
                      item?.navMetricsKey ? navMetrics?.[`${item?.navMetricsKey}`] : null
                  }
                  type={item?.featuredLabel === "AO" ? "admin" : undefined}
                />
              )}
            </Link>
          ))
        }

        {item.submenu && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a className="kt-menu__link kt-menu__toggle">
            <MenuItemText item={item} parentItem={parentItem} />
          </a>
        )}

        {item.submenu && (
          <div className="kt-menu__submenu">
            <span className="kt-menu__arrow" />

            {item["custom-class"] === "kt-menu__item--submenu-fullheight" && (
              <div className="kt-menu__wrapper">
                <MenuSubmenu
                  item={item}
                  parentItem={item}
                  currentUrl={currentUrl}
                  userFollowersCount={userFollowersCount}
                  onMenuItemClick={onMenuItemClick}
                />
              </div>
            )}

            {item["custom-class"] !== "kt-menu__item--submenu-fullheight" && (
              <MenuSubmenu
                item={item}
                parentItem={item}
                currentUrl={currentUrl}
                layoutConfig={layoutConfig}
                userFollowersCount={userFollowersCount}
                onMenuItemClick={onMenuItemClick}
                isDummyViewActive={isDummyViewActive}
              />
            )}
          </div>
        )}
      </li>
    );
  }
}

const mapDispatchToProps = {
  callReddClickEvent: (clickType, contentType) => callReddClickEvent(clickType, contentType)
};
export default injectIntl(connect(null, mapDispatchToProps)(MenuItem));
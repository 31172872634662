export const fetchOfficePremiumListLatestReleases = (params) => ({
  type: 'FETCH_OFFICE_PREMIUM_LIST_LATEST_RELEASES_REQUEST',
  payload: {
    params
  },
});
export const fetchOfficePremiumListLatestReleasesReset = () => ({
  type: 'FETCH_OFFICE_PREMIUM_LIST_LATEST_RELEASES_RESET_REQUEST',
});

export const fetchOfficePremiumListLatestRentChanged = (params) => ({
  type: 'FETCH_OFFICE_PREMIUM_LIST_LATEST_RENT_CHANGED_REQUEST',
  payload: {
    params
  },
});
export const fetchOfficePremiumListLatestRentChangedReset = () => ({
  type: 'FETCH_OFFICE_PREMIUM_LIST_LATEST_RENT_CHANGED_RESET_REQUEST',
});

export const fetchOfficePremiumListLatestRented = (params) => ({
  type: 'FETCH_OFFICE_PREMIUM_LIST_LATEST_RENTED_REQUEST',
  payload: {
    params
  },
});
export const fetchOfficePremiumListLatestRentedReset = () => ({
  type: 'FETCH_OFFICE_PREMIUM_LIST_LATEST_RENTED_RESET_REQUEST',
});

export const fetchOfficePremiumListLatestSoldOffices = (params) => ({
  type: 'FETCH_OFFICE_PREMIUM_LIST_LATEST_SOLD_OFFICES_REQUEST',
  payload: {
    params
  },
});
export const fetchOfficePremiumListLatestSoldOfficesReset = () => ({
  type: 'FETCH_OFFICE_PREMIUM_LIST_LATEST_SOLD_OFFICES_RESET_REQUEST',
});

// RESET OFFICE ANALYTICS LOADING STATES
export const resetOfficeAnalyticsLoadingStates = () => ({
  type: 'RESET_OFFICE_ANALYTICS_LOADING_STATES_REQUEST',
});
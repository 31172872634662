import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import PropertyParkPage from "./PropertyParkPage";
import {
  fetchIndustrialParkBasic,
  fetchIndustrialParkBuildings,
  fetchIndustrialParkChanges,
  fetchIndustrialParkLeaseTerms,
  fetchIndustrialParkLeasingContacts,
  fetchIndustrialParkMarketplaceStats,
  fetchIndustrialParkOwner,
  fetchIndustrialParkSimilarProperties,
  fetchIndustrialParkUnitsBasic,
  fetchIndustrialParkRegion,
  fetchIndustrialParkRegionSupplyBoost,
  fetchIndustrialParkRegionRent,
  fetchIndustrialParkRegionMap,
  resetIndustrialParkState,
} from "app/crud/estates/industrial/industrialPark.crud";
import {callReddClickEvent} from "app/crud/user.crud";

const mapStateToProps = (store) => ({
  basic: store.industrialPark.basic,
  basicLoading: store.industrialPark.basicLoading,
  buildings: store.industrialPark.buildings,
  buildingsLoading: store.industrialPark.buildingsLoading,
  leaseTerms: store.industrialPark.leaseTerms,
  leaseTermsLoading: store.industrialPark.leaseTermsLoading,
  marketplaceStats: store.industrialPark.marketplaceStats,
  marketplaceStatsLoading: store.industrialPark.marketplaceStatsLoading,
  changes: store.industrialPark.changes,
  changesLoading: store.industrialPark.changesLoading,
  similarProperties: store.industrialPark.similarProperties,
  similarPropertiesLoading: store.industrialPark.similarPropertiesLoading,
  owner: store.industrialPark.owner,
  ownerLoading: store.industrialPark.ownerLoading,
  leasingContacts: store.industrialPark.leasingContacts,
  leasingContactsLoading: store.industrialPark.leasingContactsLoading,
  units: store.industrialPark.units,
  unitsLoading: store.industrialPark.unitsLoading,
  regionStats: store.industrialPark.regionStats,
  regionStatsLoading: store.industrialPark.regionStatsLoading,
  regionSupplyBoost: store.industrialPark.regionSupplyBoost,
  regionSupplyBoostLoading: store.industrialPark.regionSupplyBoostLoading,
  regionRent: store.industrialPark.regionRent,
  regionRentLoading: store.industrialPark.regionRentLoading,
  regionMap: store.industrialPark.regionMap,
  regionMapLoading: store.industrialPark.regionMapLoading,
  resetting: store.industrialPark.resetting,
  isStaff: store.user.data.isStaff,
  currentApplicationView: store.user.data.currentApplicationView
});
const mapDispatchToProps = {
  fetchIndustrialParkBasic: (parkID) => fetchIndustrialParkBasic(parkID),
  fetchIndustrialParkBuildings: (parkID, ordering) => fetchIndustrialParkBuildings(parkID, ordering),
  fetchIndustrialParkLeaseTerms: (parkID) => fetchIndustrialParkLeaseTerms(parkID),
  fetchIndustrialParkMarketplaceStats: (parkID) => fetchIndustrialParkMarketplaceStats(parkID),
  fetchIndustrialParkChanges: (parkID) => fetchIndustrialParkChanges(parkID),
  fetchIndustrialParkSimilarProperties: (parkID) => fetchIndustrialParkSimilarProperties(parkID),
  fetchIndustrialParkOwner: (parkID) => fetchIndustrialParkOwner(parkID),
  fetchIndustrialParkLeasingContacts: (parkID) => fetchIndustrialParkLeasingContacts(parkID),
  fetchIndustrialParkUnits: (parkID) => fetchIndustrialParkUnitsBasic(parkID),
  fetchIndustrialParkRegion: (parkID) => fetchIndustrialParkRegion(parkID),
  fetchIndustrialParkRegionSupplyBoost: (parkID) => fetchIndustrialParkRegionSupplyBoost(parkID),
  fetchIndustrialParkRegionRent: (parkID) => fetchIndustrialParkRegionRent(parkID),
  fetchIndustrialParkRegionMap: (parkID) => fetchIndustrialParkRegionMap(parkID),
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params, notification) => callReddClickEvent(clickType, contentType, appLabel, objectId, params, notification),
  resetIndustrialParkState: resetIndustrialParkState
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PropertyParkPage)
);

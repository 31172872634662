import React from "react";
import {withStyles} from "@material-ui/core";
import {Pagination, PaginationItem} from "@material-ui/lab";

export default class TablePaginationCustom extends React.Component {
  render() {
    const { totalPages, currentPage, onChangePage } = this.props;
    const StyledPaginationItem = withStyles({
      selected: {
        backgroundColor: '#FF4626 !important',
        color: '#ffffff !important',
        fontWeight: 'bold !important'
      },
    })(PaginationItem);

    return (
      <div>
        <Pagination
          count={totalPages}
          page={currentPage}
          defaultPage={1}
          onChange={onChangePage}
          renderItem={(item) => <StyledPaginationItem {...item} disabled={item.selected}/>}
          hidePrevButton={currentPage === 1 || totalPages <= 1}
          hideNextButton={currentPage === totalPages || totalPages <= 1}
          shape="rounded"
        />
      </div>
    );
  }
}
import React from "react";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import ModalCustom from "app/partials/components/ModalCustom";
import LoadingScreen from "app/partials/components/LoadingScreen";
import {FormattedMessage} from "react-intl";
import {handleEmptyValue, sortArrayAlph} from "app/utils/helpers";
import parentStyles from "app/pages/common/external/components/index.module.scss";

export default class OffersOnlineSelectCity extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    const initMarkets = sortArrayAlph(handleEmptyValue(props.branding.markets, true), "name");

    this.state = {
        open: false,
        selected: "",
        selectedName: null,
        markets: initMarkets
    };
  }

  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleToggleSelect = () => {
    this.setState({
      open: !this.state.open
    })
  };
  handleChange = (event) => {
    const selectedMarket = this.state.markets.filter(market => market.id === event.target.value);
    const selectedMarketName = selectedMarket && selectedMarket.length ? selectedMarket[0]["name"] : null;
    this.setState({
      selected: event.target.value,
      selectedName: selectedMarketName
    });
  };
  handleSave = () => {
    const {selected} = this.state;
    const {onFilter, offerID} = this.props;
    const email = handleEmptyValue(localStorage.getItem("offerMail"), true);
    onFilter(offerID, email, selected);
    this.handleCloseModal();
  };

  render() {
    const {open, selected, markets, selectedName} = this.state;
    const {btnSelect} = this.props;
    if(markets === undefined) {
      return <LoadingScreen/>;
    }
    return(
      <ModalCustom
        ref={this.child}
        btn={
          <span className={parentStyles.menuItem} style={{marginRight: 15}}>{selectedName ? selectedName : <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.CHOOSE_CITY"/>}</span>
        }
        title={<FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.CHOOSE_CITY"/>}
        handleSave={this.handleSave}
        btnFlex={btnSelect}
        btnStyle={{marginRight: 15}}
      >
        <FormControl style={{width: "100%"}}>
          <InputLabel htmlFor="new_user_market"><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.CHOOSE_CITY"/></InputLabel>
          <Select
            open={open}
            onClose={this.handleToggleSelect}
            onOpen={this.handleToggleSelect}
            value={selected}
            onChange={this.handleChange}
            inputProps={{
              name: 'market',
              id: 'new_user_market',
            }}
          >
            <MenuItem value="">---</MenuItem>
            {markets.map((market, index) => {
              return(
                <MenuItem key={index} value={market.id}>{market.name}</MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </ModalCustom>
    );
  }
}
import React from "react";
import {FormattedMessage} from "react-intl";
import styles from "./index.module.scss";
import {Menu, MenuItem, Typography} from "@material-ui/core";
import HighlightLabel from "../../../../partials/components/HighlightLabel";
import Button from "../../../../partials/components/Button";

export default class OwnerFinancialSummary extends React.Component {
  constructor(props){
    super(props);
    this.child = React.createRef();
    this.state = {
      anchorEl: null,
      currentYear: props.lastYear
    }
  }
  handleOpen = (event) => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };
  handleClose = () => {
    this.setState({
      anchorEl: null
    })
  };

  handleFilterFinances = (year) => {
    const { fetchOwnerFinances, ownerId } = this.props;
    this.setState({
      currentYear: year
    }, () => fetchOwnerFinances(ownerId, year));
  };

  render() {
    const {inactive, financialYears} = this.props;
    const {anchorEl, currentYear} = this.state;
    if(inactive) {
      return(
        <Button inactive>
          <FormattedMessage id="SHORTLIST.PHRASE.GENERATE_PDF"/>
        </Button>
      );
    }
    return (
      <div>
        <HighlightLabel className={styles.selectedBuildingsTriggerContainer} color={financialYears && financialYears.length ? "success" : "default"} onClick={this.handleOpen}>
            <Typography><FormattedMessage id="OWNERS.PHRASE.FILTER_BY_YEARS" values={{year: currentYear ? `: ${currentYear}` : ''}}/></Typography>
        </HighlightLabel>
        <Menu
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          id="generatePdf"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          className={styles.customPopoverContainer}
        >
          {financialYears && financialYears.filter(year => year !== currentYear).map((year, index) => (
          <MenuItem key={index} onClick={this.handleClose}>
            <Button
              color={"success"}
              onClick={() => this.handleFilterFinances(year)}
            >
              {year}
            </Button>
          </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }
}
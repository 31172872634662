export const fetchSubscribers = (params) => ({
  type: 'FETCH_SUBSCRIBERS_REQUEST',
  payload: { params },
});
export const fetchSubscriber = (id) => ({
  type: 'FETCH_SUBSCRIBER_REQUEST',
  payload: id,
});
export const addSubscriber = (officeId, data) => ({
  type: 'ADD_SUBSCRIBER_REQUEST',
  payload: { officeId, data },
});
export const addSubscribersXlsError = () => ({
  type: 'ADD_SUBSCRIBERS_XLS_REQUEST'
});
export const inviteSubscriber = (data) => ({
  type: 'INVITE_SUBSCRIBER_REQUEST',
  payload: data ,
});
export const updateSubscriber = (id, data) => ({
  type: 'UPDATE_SUBSCRIBER_REQUEST',
  payload: { id, data },
});
export const deleteSubscriber = (subscriberId) => ({
  type: 'DELETE_SUBSCRIBER_REQUEST',
  payload: subscriberId,
});
// Reset Subscribers Loading States
export const resetSubscribersLoadingStates = () => ({
  type: 'RESET_SUBSCRIBERS_LOADING_STATES_REQUEST'
});
import React from 'react';
import {Text, View, StyleSheet, Image} from '@react-pdf/renderer';
import {toDateFormat} from "../../../../../utils/helpers";

// Create styles
const styles = StyleSheet.create({
  pageHeader: {
    // position: 'absolute',
    // top: 20, // Increased from 10 to 20 (50% lower)
    // left: 36,
    // right: 36,
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%'
  },
  pageHeaderWrapper: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 5,
    marginBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#E6E6E6',
    borderBottomStyle: 'solid',
  },
  pageHeaderContent: {
    flex: 1,
  },
  pageHeaderTitle: {
    fontSize: 11,
    fontWeight: 'medium',
    color: '#575757',
    marginBottom: 1.5
  },
  pageHeaderSubtitle: {
    color: '#2d2d2d',
  },
  pageHeaderImage: {
    width: 'auto',
    maxWidth: 120,
    maxHeight: 55
  }
});

// Create Document Component
function OfferPdfPageHeader(props) {
  const {
    intl,
    data
  } = props;

  const companyName = data?.company?.shortName;
  const companyLogo = data?.company?.logo;
  const ownerLogo = data?.owner?.companyLogo;
  const logo = ownerLogo || companyLogo;
  const offerDate = data?.offerDate || data?.createdAt || null;
  const offerDateFinal = offerDate ? toDateFormat(offerDate) : "";
  // Function to translate strings
  const handleStringTranslation = (id, values) => {
    return intl.formatMessage({id: id}, values);
  };
  const handleDisplayPageBased = (pageNumber) => {
    return props?.isSingleProperty && props?.target === "summary" ? pageNumber > 0 : pageNumber === 1;
  }

  return (
    <View 
      style={styles.pageHeader}
      render={({ pageNumber }) => (
        handleDisplayPageBased(pageNumber) ? (
        <View style={styles.pageHeaderWrapper}>
          <View style={styles.pageHeaderContent}>
            <Text style={styles.pageHeaderTitle}>{handleStringTranslation("VOCABULARY.Offer dated")} {offerDateFinal}</Text>
            <Text style={styles.pageHeaderSubtitle}>{companyName}</Text>
          </View>
          <Image
            style={styles.pageHeaderImage}
            src={`${logo}`}
          />
        </View>
        ) : null
      )}
    />
  );
};

export default OfferPdfPageHeader;


import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import BuildingsPage from "./BuildingsPage";
import {filterByModules, filterReset} from "app/crud/estates/filters.crud";
import {fetchOffice, fetchOfficeClusters, fetchOfficeList} from "app/crud/estates/offices/offices.crud";
import {setLatestActiveTablePage} from "app/crud/tablePage.crud";
import {fetchOfficeUnits} from "app/crud/estates/offices/units.crud";
import {callReddClickEvent} from "app/crud/user.crud";


const mapStateToProps = (state) => ({
  offices: state.offices,
  items: state.offices.items,
  loaded: state.offices.loaded,
  loading: state.offices.loading,
  marketName: state.user.data.marketName,
  filters: state.filters,
  viewType: state.offices.viewType,
  clusters: state.offices.clusters,
  clustersLoaded: state.offices.clustersLoaded,
  clustersLoading: state.offices.clustersLoading,
  office: state.offices.office,
  officeLoading: state.offices.officeLoading,
  allUserStates: state.user,
  officeUnits: state.units.officeUnits,
  officeUnitsLoading: state.units.officeUnitsLoading,
  user: state.user.data
});
const mapDispatchToProps = {
  fetchOfficeList: ({params}) => fetchOfficeList(params),
  fetchOffice: (officeId) => fetchOffice(officeId),
  fetchOfficeClusters: ({params}) => fetchOfficeClusters(params),
  filterReset: (filtersToOmit) => filterReset(filtersToOmit),
  filterByModules: (params) => filterByModules(params),
  setLatestActiveTablePage: (page) => setLatestActiveTablePage(page),
  fetchOfficeUnits: (officeId) => fetchOfficeUnits(officeId),
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BuildingsPage)
);

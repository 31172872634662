import React from "react";
import styles from "../../../../../common/external/components/index.module.scss";
import clsx from "clsx";
import IconColorMUI from "app/partials/components/IconColorMUI";
import {FormattedMessage} from "react-intl";
import {handleEmptyValue, toNumberFormat,} from "app/utils/helpers";
import Typography from "@material-ui/core/Typography/Typography";
import {Tooltip} from "@material-ui/core";

export default class OffersOnlineSingleDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false,
    };
  }
  handleCopyShareUrl = () => {
    this.setState(
      {
        copied: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            copied: false,
          });
        }, 5000);
        return navigator.clipboard.writeText(window.location.href);
      }
    );
  };
  render() {
    const {data} = this.props;
    const {copied} = this.state;
    const propertyCity = [
        ...data.addressZipcode ? [data?.addressZipcode] : [],
        ...data?.addressCity ? [data?.addressCity] : []
    ].join(" ");
    const propertyAddress = [
        ...data.addressStreet ? [data.addressStreet] : [],
        propertyCity,
        ...data.region ? [data.region] : []
    ];
    const warehouseRentRange = [
        ...data.rentWarehouseFrom ? [toNumberFormat(data.rentWarehouseFrom)] : [],
        ...data.rentWarehouseTo ? [toNumberFormat(data.rentWarehouseTo)] : []
    ];
    const officeRentRange = [
        ...data.rentOfficeFrom ? [toNumberFormat(data.rentOfficeFrom)] : [],
        ...data.rentOfficeTo ? [toNumberFormat(data.rentOfficeTo)] : []
    ];
    const warehouseServiceRange = [
        ...data.serviceWarehouseFrom ? [toNumberFormat(data.serviceWarehouseFrom)] : [],
        ...data.serviceWarehouseTo ? [toNumberFormat(data.serviceWarehouseTo)] : []
    ];
    const officeServiceRange = [
        ...data.serviceOfficeFrom ? [toNumberFormat(data.serviceOfficeFrom)] : [],
        ...data.serviceOfficeTo ? [toNumberFormat(data.serviceOfficeTo)] : []
    ];


    return (
      <div
        ref={this.props.reference}
        className={clsx(styles.detailsWrapper, styles.boxContainer)}
      >
        <div className={styles.detailsHeader}>
          <div className={styles.detailsTitle}>
            <h1>{handleEmptyValue(data.name)}</h1>
            <p>{propertyAddress.join(", ")}</p>
          </div>
          <Tooltip
            placement="top"
            title={
              <Typography variant="body1">
                <FormattedMessage
                  id={
                    copied
                      ? "PROPERTY_PARTICULARS.PHRASE.COPIED_TO_CLIPBOARD"
                      : "PROPERTY_PARTICULARS.PHRASE.COPY_LINK"
                  }
                />
              </Typography>
            }
          >
            <span style={{ display: "flex", alignItems: "center" }}>
              <button
                className={styles.btnShare}
                onClick={() => this.handleCopyShareUrl()}
              >
                <IconColorMUI icon="share" />
              </button>
            </span>
          </Tooltip>
        </div>
        <h2>
          <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.PARK_DETAILS" />
        </h2>
        <div className={styles.detailsTablesWrapper}>
                    <div className={styles.tableWrapper}>
                        <table>
                            <tbody>
                                <tr>
                                    <td className={styles.tdLabel}><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.TOTAL_PARK_AREA"/>:</td>
                                    <td className={styles.tdValue}>{toNumberFormat(data?.totalArea, 'area')}</td>
                                </tr>
                                <tr>
                                    <td className={styles.tdLabel}><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.EXISTING_AREA"/>:</td>
                                    <td className={styles.tdValue}>{toNumberFormat(data?.marketplaceTotalAreaExisting, 'area')}</td>
                                </tr>
                                <tr>
                                    <td className={styles.tdLabel}><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.AREA_UNDER_CONSTRUCTION"/>:</td>
                                    <td className={styles.tdValue}>{toNumberFormat(data?.marketplaceTotalAreaUnderConstruction, 'area')}</td>
                                </tr>
                                <tr>
                                    <td className={styles.tdLabel}><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.PLANNED_AREA"/>:</td>
                                    <td className={styles.tdValue}>{toNumberFormat(data?.marketplaceTotalAreaPlanned, 'area')}</td>
                                </tr>
                                <tr>
                                    <td className={styles.tdLabel}><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.EXPANDABLE"/>:</td>
                                    <td className={styles.tdValue}>{<FormattedMessage id={data?.isExpandable ? "Yes" : "No"}/>}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className={styles.tableWrapper}>
                        <table>
                            <tbody>
                                <tr>
                                    <td className={styles.tdLabel}><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.AVAILABLE_AREA"/>:</td>
                                    <td className={styles.tdValue}>{toNumberFormat(data?.totalAreaAvailable, 'area')}</td>
                                </tr>
                                <tr>
                                    <td className={styles.tdLabel}><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.MINIMUM_UNIT"/>:</td>
                                    <td className={styles.tdValue}>{toNumberFormat(data?.marketplaceIndustrialUnitMinArea, 'area')}</td>
                                </tr>
                                <tr>
                                    <td className={styles.tdLabel}><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.AVAILABLE_FROM"/>:</td>
                                    <td className={styles.tdValue}>{handleEmptyValue(data?.parkAvailableFrom)}</td>
                                </tr>
                                <tr>
                                    <td className={styles.tdLabel}><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.MIN_RENTAL_PERIOD_YEARS"/>:</td>
                                    <td className={styles.tdValue}>{handleEmptyValue(data?.parkMinRentalYears)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
        <h2>
          <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.LEASE_TERMS" />
        </h2>
        <div className={styles.detailsTablesWrapper}>
            <div className={styles.tableWrapper}>
                <table>
                    <tbody>
                        <tr>
                            <td className={styles.tdLabel}><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.WAREHOUSE_RENT"/>:</td>
                            <td className={styles.tdValue}>{toNumberFormat(warehouseRentRange.join(" - "), 'range', '€')}</td>
                        </tr>
                        <tr>
                            <td className={styles.tdLabel}><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.WAREHOUSE_SERVICE"/>:</td>
                            <td className={styles.tdValue}>{toNumberFormat(warehouseServiceRange.join(" - "), 'range', 'PLN')}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className={styles.tableWrapper}>
                <table>
                    <tbody>
                        <tr>
                            <td className={styles.tdLabel}><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.OFFICE_RENT"/>:</td>
                            <td className={styles.tdValue}>{toNumberFormat(officeRentRange.join(" - "), 'range', '€')}</td>
                        </tr>
                        <tr>
                            <td className={styles.tdLabel}><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.OFFICE_SERVICE"/>:</td>
                            <td className={styles.tdValue}>{toNumberFormat(officeServiceRange.join(" - "), 'range', 'PLN')}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
      </div>
    );
  }
}

import React from "react";
import TableCell from "@material-ui/core/TableCell/TableCell";
import {Typography} from "@material-ui/core";
import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import IconColorMUI from "app/partials/components/IconColorMUI";
import SubscriberRemove from "../../Subscribers/SubscriberRemove";

class TableSubscribersCells extends React.Component {
  // RENDER Table Cell Values
  renderCustomCell = (value) => {
    return (
      <Typography variant="h4">{value}</Typography>
    );
  };
  renderApproved = (state) => {
    const icon = state ? 'check' : 'access_time';
    const color = state ? 'success' : 'warning';
    return(
      <IconColorMUI icon={icon} color={color}/>
    );
  };

  render() {
    const {row} = this.props;
    return (
      <>
        <TableCell>{this.renderCustomCell(row.email)}</TableCell>
        <TableCell align="right">{this.renderApproved(row.hasConsent)}</TableCell>
        <TableCell align="right">
            <SubscriberRemove subscriberId={row.id}/>
        </TableCell>
      </>
    );
  }
}

export default injectIntl(
  connect(
    null
  )(TableSubscribersCells)
);

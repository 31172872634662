import {GoogleMap as ReactGoogleMaps, withGoogleMap, withScriptjs} from "react-google-maps";
import {compose, defaultProps, withProps} from "recompose";
import React from "react";
import GOOGLE_MAPS_OPTIONS from "app/constants/GOOGLE_MAPS_OPTIONS";

class GoogleMap extends React.Component {
  static defaultProps = {
    clusterRadius: 60,
  };

  render() {
    const { children, mapRef, ...props } = this.props;
    return (
      <ReactGoogleMaps ref={mapRef} onBoundsChanged={this.onBoundsChanged} {...props}>
        {children}
      </ReactGoogleMaps>
    );
  }
}

const withScriptJsEnable = document.getElementById("redd-gm") ? null : withScriptjs;
export const withGoogleApi = compose(
  defaultProps({
    clusterRadius: 60,
    style: {},
  }),

  withProps({
    googleMapURL: !document.getElementById('redd-gm') && `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_OPTIONS.API_KEY}&v=3.exp&libraries=${GOOGLE_MAPS_OPTIONS.LIBRARIES}`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptJsEnable,
  withGoogleMap
);

export default withGoogleApi(GoogleMap);

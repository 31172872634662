// FETCH DATA
export const fetchUserData = () => ({
  type: 'FETCH_USER_DATA_REQUEST'
});
export const fetchOfficesMarketDistricts = (marketId) => ({
  type: 'FETCH_OFFICES_MARKET_DISTRICTS_REQUEST',
  payload: {
    marketId
  }
});
export const fetchVisibleTeams = () => ({
  type: 'FETCH_VISIBLE_TEAMS_REQUEST'
});
export const fetchVisibleOwners = () => ({
  type: 'FETCH_VISIBLE_OWNERS_REQUEST'
});
export const fetchVisibleMarketOwners = () => ({
  type: 'FETCH_VISIBLE_MARKET_OWNERS_REQUEST'
});
export const fetchVisibleNeighborhoods = (data) => ({
  type: 'FETCH_VISIBLE_NEIGHBORHOODS_REQUEST',
  payload: data
});
export const fetchTeamBuildings = (teamId) => ({
  type: 'FETCH_TEAM_BUILDINGS_REQUEST',
  payload: teamId
});
export const fetchVoivodeships = () => ({
  type: 'FETCH_VOIVODESHIP_REQUEST'
});
export const fetchMarkets = () => ({
  type: 'FETCH_MARKETS_REQUEST'
});
export const fetchMarketsIndustrial = () => ({
  type: 'FETCH_MARKETS_INDUSTRIAL_REQUEST'
});
export const fetchAllUsers = () => ({
  type: 'FETCH_ALL_USERS_REQUEST'
});
// ACTIONS
export const updateUserData = (data, avatar, companyLogo, pdfCover, redirect) => ({
  type: 'UPDATE_USER_DATA_REQUEST',
  payload: {data, avatar, companyLogo, pdfCover, redirect}
});
export const updateUserMarket = (market, redirect, redirectNewTab) => ({
  type: 'UPDATE_USER_MARKET_REQUEST',
  payload: {market, redirect, redirectNewTab}
});
export const updateUserLangCode = (langCode) => ({
  type: 'UPDATE_USER_LANG_CODE_REQUEST',
  payload: langCode
});
export const updateUserQuestionnaireStatus = (questionnaireStatus) => ({
  type: 'UPDATE_USER_QUESTIONNAIRE_CHANGE_REQUEST',
  payload: questionnaireStatus
});
export const updateUserAffiliateCodeVisible = () => ({
  type: 'UPDATE_USER_AFFILIATE_CODE_VISIBLE_REQUEST'
});
export const removeUserImage = (target) => ({
  type: 'REMOVE_USER_IMAGE_REQUEST',
  payload: target
});
export const changePassword = (data) => ({
  type: 'CHANGE_PASSWORD_REQUEST',
  payload: data
});
export const resetPassword = (data) => ({
  type: 'RESET_PASSWORD_REQUEST',
  payload: data
});
export const userAddWatchListItem = (officeId) => ({
  type: 'USER_ADD_WATCH_LIST_ITEM_REQUEST',
  payload: officeId
});
export const userRemoveWatchListItem = (officeId) => ({
  type: 'USER_REMOVE_WATCH_LIST_ITEM_REQUEST',
  payload: officeId
});
export const requestUpgradeToPremium = () => ({
  type: 'REQUEST_UPGRADE_TO_PREMIUM_REQUEST'
});
export const loginAsUser = (id) => ({
  type: 'LOGIN_AS_USER_REQUEST',
  payload: {
    id
  }
})
export const callReddClickEvent = (clickType, contentType, appLabel, objectId, params, notification) => ({
  type: "TRIGGER_REDD_CLICK_EVENT_REQUEST",
  payload: {
    clickType, contentType, appLabel, objectId, params, notification
  },
});
export const adminCreateUser = (data) => ({
  type: 'ADMIN_CREATE_USER_REQUEST',
  payload: {
    data
  }
});

// Reset User Loading States
export const resetUserLoadingStates = () => ({
  type: 'RESET_USER_LOADING_STATES_REQUEST'
});
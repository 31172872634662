import React from "react";
import {AppBar, Grid, Tab, Tabs, Typography} from "@material-ui/core";
import {Portlet, PortletBody, PortletHeader, PortletHeaderToolbar,} from "app/partials/content/Portlet";
import {FormattedMessage} from "react-intl";
import {HEAD_ROWS, TABLE_CELLS,} from "app/partials/components/TableProperties";
import TableProperties from "app/partials/components/TableProperties/TableProperties";
import LoadingScreen from "app/partials/components/LoadingScreen";
import NoData from "app/partials/components/NoData/NoData";
import TrackingDashboard from "app/partials/components/TrackingData/TrackingDashboard";
import DashboardExtendedDataItem
  from "app/partials/components/DashboardExtendedDataBox/DashboardExtendedDataItem";
import DashboardExtendedDataWrapper
  from "app/partials/components/DashboardExtendedDataBox/DashboardExtendedDataWrapper";
import OwnerReddIndex from "./components/OwnerReddIndex";
import OwnerVacancyRate from "./components/OwnerVacancyRate";
import OwnerSectors from "./components/OwnerSectors";
import OwnerLeasingTeams from "./components/OwnerLeasingTeams";
import OwnerBoard from "./components/OwnerBoard";
import OwnerFinancialOverview from "./components/OwnerFinancialOverview";
import OwnerLoans from "./components/OwnerLoans";
import OwnerHeader from "./components/OwnerHeader";
import OwnerMortgageBuildingsFilter from "app/pages/offices/OwnerPage/components/OwnerMortgageBuildingsFilter";
import OwnerFinancialSummary from "./components/OwnerFinancialSummary";
import styles from "../components/index.module.scss";
import tabsStyles from "../../../partials/components/TabsComponent/index.module.scss";
import {toNumberFormat} from "../../../utils/helpers";
import {DataPreviewContainer, DataPreviewItem,} from "../../../partials/components/SimpleDataPreview";
import IconLabel from "../../../partials/components/IconLabel";
import TabItem from "../../../partials/components/TabsComponent/TabItem";
import cx from "classnames";
import IsPro from "../../../utils/IsPro";

class OwnerTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "tab_1",
    };
  }
  // Handle set new active tab
  handleChangeActiveTab = (event, newValue) => {
    this.setState({
      activeTab: newValue,
    });
  };

  render() {
    const { activeTab } = this.state;
    const {
      owner,
      fetchOwnerPortfolio,
      fetchOwnerBoard,
      fetchOwnerSectors,
      fetchOwnerUnitsTracking,
      fetchOwnerVacancyRate,
      fetchOwnerReddIndex,
      fetchOwnerFinances,
      fetchOwnerLoans,
      fetchOwnerLeasingTeams,
      fetchOwnerLeasingContact,
      fetchOwnerAcquisitions
    } = this.props;
    const ownerId = owner.id;
    const tabsBarStyles = {
      marginBottom: 18,
      backgroundColor: "#ffffff",
      color: "#3a3a3a",
    };
    // Check if given Tab is a active one
    const isActiveTabBarItem = (tab) => {
      return tab === activeTab;
    };
    return (
      <div className={tabsStyles.tabsContainer}>
        {/* Tabs Navigation Bar */}
        <AppBar position="static" style={tabsBarStyles}>
          <Tabs
            TabIndicatorProps={{
              style: {
                backgroundColor: "#FF4626",
              },
            }}
            value={activeTab}
            onChange={this.handleChangeActiveTab}
          >
            <Tab
              value="tab_1"
              className={cx(
                styles.tabBarItem,
                isActiveTabBarItem("tab_1") && styles.active
              )}
              label={<FormattedMessage id="BUILDING.PHRASE.TABS.SUMMARY" />}
              wrapped
            />
            <Tab
              value="tab_2"
              className={cx(
                styles.tabBarItem,
                isActiveTabBarItem("tab_2") && styles.active
              )}
              label={<FormattedMessage id="OWNERS.PHRASE.TABS.BUILDINGS" />}
              onClick={() => fetchOwnerPortfolio(ownerId)}
            />
            <Tab
              value="tab_3"
              className={cx(
                styles.tabBarItem,
                isActiveTabBarItem("tab_3") && styles.active
              )}
              label={<FormattedMessage id="BUILDING.PHRASE.TABS.CONTACT" />}
              onClick={() => {
                fetchOwnerBoard(ownerId);
                fetchOwnerLeasingContact(ownerId);
              }}
            />
            <Tab
              value="tab_4"
              className={cx(
                styles.tabBarItem,
                isActiveTabBarItem("tab_4") && styles.active
              )}
              label={<FormattedMessage id="BUILDING.PHRASE.TABS.TENANTS" />}
              onClick={() => fetchOwnerSectors(ownerId)}
            />
            <Tab
              value="tab_5"
              className={cx(
                styles.tabBarItem,
                isActiveTabBarItem("tab_5") && styles.active
              )}
              label={<FormattedMessage id="BUILDING.PHRASE.TABS.ANALYSIS" />}
              onClick={() => {
                fetchOwnerVacancyRate(ownerId);
                fetchOwnerUnitsTracking(ownerId);
                fetchOwnerReddIndex(ownerId);
              }}
            />
            <Tab
              value="tab_6"
              className={cx(
                styles.tabBarItem,
                isActiveTabBarItem("tab_6") && styles.active
              )}
              label={
                <FormattedMessage id="OWNERS.PHRASE.TABS.FINANCES_AND_MORTGAGE" />
              }
              onClick={() => {
                fetchOwnerFinances(ownerId);
                fetchOwnerLoans(ownerId);
              }}
            />
            <Tab
              value="tab_7"
              className={cx(
                styles.tabBarItem,
                isActiveTabBarItem("tab_7") && styles.active
              )}
              label={
                <FormattedMessage id="OWNERS.PHRASE.TABS.ACQUISITIONS" />
              }
              onClick={() => fetchOwnerAcquisitions(ownerId)}
            />
            <Tab
              value="tab_8"
              className={cx(
                styles.tabBarItem,
                isActiveTabBarItem("tab_8") && styles.active
              )}
              label={
                <FormattedMessage id="OWNERS.PHRASE.TABS.LEASING_TEAMS" />
              }
              onClick={() => fetchOwnerLeasingTeams(ownerId)}
            />
          </Tabs>
        </AppBar>
        {/* Tabs Content */}
        {activeTab === "tab_1" && <OwnerTab1 {...this.props} />}
        {activeTab === "tab_2" && <OwnerTab2 {...this.props} />}
        {activeTab === "tab_3" && <OwnerTab3 {...this.props} />}
        {activeTab === "tab_4" && <OwnerTab4 {...this.props} />}
        {activeTab === "tab_5" && <OwnerTab5 {...this.props} />}
        {activeTab === "tab_6" && <OwnerTab6 {...this.props} />}
        {activeTab === "tab_7" && <OwnerTab7 {...this.props} />}
        {activeTab === "tab_8" && <OwnerTab8 {...this.props} />}
      </div>
    );
  }
}
class OwnerTab1 extends React.Component {
  render() {
    const { owner } = this.props;
    const {
      portfolio,
      totalVacancy,
      totalVacancyUnderConstruction,
      avgRentFee,
      minRentalPeriod,
      tenantsCount,
      leasing,
      acquisitions,
    } = owner;

    // CUSTOM STYLES
    const availableUnitsStyles = {
      transform: "scale(1.2)",
      textAlign: "center",
    };
    const availableUnitsSoonStyles = {
      textAlign: "center",
    };
    const mortgageBoxStyles = {
      display: "block",
      justifyContent: "inherit",
      alignItems: "inherit",
    };
    const avgRentFeeFixed = avgRentFee && avgRentFee.toFixed(2);

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2" className={styles.tabTitle}>
            <FormattedMessage id="BUILDING.PHRASE.TABS.SUMMARY" />
          </Typography>
        </Grid>
          <Grid item xs={4}>
            <Portlet fluidHeight>
              <PortletHeader
                title={
                  <Typography variant="h3">
                    <FormattedMessage id="OWNERS.PHRASE.TABS.BUILDINGS" />
                  </Typography>
                }
              />
              <PortletBody fluid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={4}>
                    <IconLabel
                      icon="business"
                      label={<FormattedMessage id="GENERAL.PHRASE.OFFICE" />}
                      value={toNumberFormat(portfolio)}
                      direction="column"
                      spacing={1}
                      style={availableUnitsStyles}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <IconLabel
                      icon="storefront"
                      label={<FormattedMessage id="GENERAL.PHRASE.RETAIL" />}
                      value={
                        <FormattedMessage id="GENERAL.PHRASE.COMING_SOON" />
                      }
                      direction="column"
                      spacing={1}
                      style={availableUnitsSoonStyles}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <IconLabel
                      icon="house_siding"
                      label={<FormattedMessage id="GENERAL.PHRASE.WAREHOUSE" />}
                      value={
                        <FormattedMessage id="GENERAL.PHRASE.COMING_SOON" />
                      }
                      direction="column"
                      spacing={1}
                      style={availableUnitsSoonStyles}
                    />
                  </Grid>
                </Grid>
              </PortletBody>
            </Portlet>
          </Grid>
          <Grid item xs={4}>
            <Portlet fluidHeight>
              <PortletHeader
                title={
                  <Typography variant="h3">
                    <FormattedMessage id="GENERAL.PHRASE.TENANTS" />
                  </Typography>
                }
              />
              <PortletBody fluid>
                <Grid
                  container
                  spacing={2}
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="h2">
                      {toNumberFormat(tenantsCount)}*
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">
                      *{" "}
                      <FormattedMessage id="BUILDING.PHRASE.REGISTERED_COMPANIES" />
                    </Typography>
                  </Grid>
                </Grid>
              </PortletBody>
            </Portlet>
          </Grid>
          <Grid item xs={4}>
            <Portlet fluidHeight>
              <PortletHeader
                title={
                  <Typography variant="h3">
                    <FormattedMessage id="BUILDING.PHRASE.TABS.ANALYSIS" />
                  </Typography>
                }
              />
              <PortletBody>
                <DataPreviewContainer>
                  <DataPreviewItem
                    title={
                      <FormattedMessage id="OWNERS.PHRASE.AVERAGE_RENT_FEE" />
                    }
                    value={toNumberFormat(avgRentFeeFixed, "currency")}
                  />
                  <DataPreviewItem
                    title={
                      <FormattedMessage id="OWNERS.PHRASE.MIN_RENTAL_PERIOD_FROM_TO" />
                    }
                    value={minRentalPeriod}
                  />
                  <DataPreviewItem
                    title={
                      <FormattedMessage id="FORM.FIELD.AVG_VACANCY_EXIST" />
                    }
                    tooltip="FORM.FIELD.AVG_VACANCY_EXIST_DESC"
                    value={toNumberFormat(totalVacancy, "%")}
                  />
                  <DataPreviewItem
                    title={
                      <FormattedMessage id="FORM.FIELD.AVG_VACANCY_UNDER_CONSTRUCTION" />
                    }
                    tooltip="FORM.FIELD.AVG_VACANCY_UNDER_CONSTRUCTION_DESC"
                    value={toNumberFormat(totalVacancyUnderConstruction, "%")}
                  />
                </DataPreviewContainer>
              </PortletBody>
            </Portlet>
          </Grid>
          <Grid item xs={4}>
            <Portlet fluidHeight>
              <PortletHeader
                title={
                  <Typography variant="h3">
                    <FormattedMessage id="BUILDING.PHRASE.TABS.FINANCES" />
                  </Typography>
                }
              />
              <PortletBody fluid style={mortgageBoxStyles}>
                {owner ? (
                  <DataPreviewContainer>
                    <DataPreviewItem
                      title={
                        <FormattedMessage id="OWNERS.PHRASE.TOTAL_OPERATING_REVENUE" />
                      }
                      value={toNumberFormat(
                        owner["totalOperatingRevenue"],
                        "currency",
                        "PLN"
                      )}
                    />
                    <DataPreviewItem
                      title={
                        <FormattedMessage id="OWNERS.PHRASE.PROFIT_LOSS_FROM_SALES" />
                      }
                      value={toNumberFormat(
                        owner["salesProfit"],
                        "currency",
                        "PLN"
                      )}
                    />
                    <DataPreviewItem
                      title={
                        <FormattedMessage id="OWNERS.PHRASE.GROSS_PROFIT_MARGIN" />
                      }
                      value={toNumberFormat(owner["grossProfitMargin"], "%")}
                    />
                    <DataPreviewItem
                      title={
                        <FormattedMessage id="OWNERS.PHRASE.OPERATING_PROFIT_EBIT" />
                      }
                      value={toNumberFormat(owner["ebit"], "currency", "PLN")}
                    />
                  </DataPreviewContainer>
                ) : (
                  <NoData />
                )}
              </PortletBody>
            </Portlet>
          </Grid>
          <Grid item xs={4}>
            <Portlet fluidHeight>
              <PortletHeader
                title={
                  <Typography variant="h3">
                    <FormattedMessage id="OWNERS.PHRASE.TABS.ACQUISITIONS" />
                  </Typography>
                }
              />
              <PortletBody fluid>
                {acquisitions ? (
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                      <IconLabel
                        icon="bookmark_remove"
                        label={<FormattedMessage id="OWNERS.PHRASE.SELLERS" />}
                        value={toNumberFormat(acquisitions.seller)}
                        direction="column"
                        spacing={1}
                        style={availableUnitsStyles}
                        colorIcon="warning"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <IconLabel
                        icon="bookmark_add"
                        label={
                          <FormattedMessage id="OWNERS.PHRASE.ACQUIRING" />
                        }
                        value={toNumberFormat(acquisitions.acquiring)}
                        direction="column"
                        spacing={1}
                        style={availableUnitsStyles}
                        colorIcon="success"
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <NoData />
                )}
              </PortletBody>
            </Portlet>
          </Grid>
          <Grid item xs={4}>
            <Portlet fluidHeight>
              <PortletHeader
                title={
                  <Typography variant="h3">
                    <FormattedMessage id="GENERAL.PHRASE.LEASING" />
                  </Typography>
                }
              />
              <PortletBody fluid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={6}>
                    <IconLabel
                      icon="poll"
                      label={<FormattedMessage id="GENERAL.PHRASE.INTERNAL" />}
                      value={leasing && toNumberFormat(leasing.internal, "%")}
                      direction="column"
                      spacing={1}
                      style={availableUnitsStyles}
                      colorIcon="success"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <IconLabel
                      icon="poll"
                      label={<FormattedMessage id="GENERAL.PHRASE.EXTERNAL" />}
                      value={leasing && toNumberFormat(leasing.external, "%")}
                      direction="column"
                      spacing={1}
                      style={availableUnitsStyles}
                      colorIcon="neutral"
                    />
                  </Grid>
                </Grid>
              </PortletBody>
            </Portlet>
          </Grid>
      </Grid>
    );
  }
}
class OwnerTab2 extends React.Component {
  render() {
    const { owner, ownerPortfolio, ownerPortfolioLoading } = this.props;

    return (
      <TabItem>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h2" className={styles.tabTitle}>
              <FormattedMessage id="OWNERS.PHRASE.TABS.BUILDINGS" />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Portlet fluidHeight>
              <PortletBody fit={true}>
                {ownerPortfolioLoading || ownerPortfolio === null ? (
                  <LoadingScreen />
                ) : (
                  <TableProperties
                    tableLabel={
                      <FormattedMessage id="OWNERS.PHRASE.BUILDINGS_PORTFOLIO" />
                    }
                    headRows={HEAD_ROWS.OWNER_PORTFOLIO}
                    bodyRows={ownerPortfolio}
                    dataSource="owner"
                    dataTarget={TABLE_CELLS.OWNER_PORTFOLIO}
                    maxHeight={500}
                    parentId={owner.id}
                  />
                )}
              </PortletBody>
            </Portlet>
          </Grid>
        </Grid>
      </TabItem>
    );
  }
}
class OwnerTab3 extends React.Component {
  render() {
    const { owner, ownerBoard, ownerLeasingContact } = this.props;
    const {
      logo,
      name,
      shortName,
      addressStreet,
      addressZipcode,
      addressCity,
    } = owner;
    const ownerLocZipCity =
      addressZipcode && addressCity
        ? `${addressZipcode} ${addressCity}`
        : addressZipcode && !addressCity
        ? addressZipcode
        : !addressZipcode && addressCity
        ? addressCity
        : "";
    return (
      <TabItem>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h2" className={styles.tabTitle}>
              <FormattedMessage id="BUILDING.PHRASE.TABS.CONTACT" />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Portlet fluidHeight>
              <PortletHeader
                title={
                  <Typography variant="h3">
                    <FormattedMessage id="GENERAL.PHRASE.OWNERS" />
                  </Typography>
                }
              />
              <PortletBody>
                {owner && owner.length <= 0 ? (
                  <LoadingScreen />
                ) : owner && owner.name === "" ? (
                  <NoData />
                ) : (
                  owner && (
                    <Grid
                      container
                      direction="row"
                      spacing={2}
                      alignItems="center"
                    >
                      {logo && (
                        <Grid item>
                          <img src={logo} className={styles.logo} alt="" />
                        </Grid>
                      )}
                      <Grid item>
                        <Typography variant="h4" className={styles.mb1}>
                          {shortName ? shortName : name}
                        </Typography>
                        <Typography>{ownerLocZipCity}</Typography>
                        <Typography>
                          {addressStreet ? addressStreet : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  )
                )}
              </PortletBody>
              <PortletHeader
                title={
                  <Typography variant="h3">
                    <FormattedMessage id="GENERAL.PHRASE.LEASING" />
                  </Typography>
                }
              />
              <PortletBody className={styles.portletBodyCenter}>
                {shortName && shortName.length <= 0 ? (
                  <LoadingScreen />
                ) : shortName && !shortName.length ? (
                  <NoData />
                ) : (
                  shortName && (
                    <Grid
                      container
                      direction="row"
                      spacing={10}
                      alignItems="center"
                    >
                      <Grid item>
                        <Grid container spacing={2} alignItems="center">
                          {logo && (
                            <Grid item>
                              <img
                                src={logo}
                                className={styles.logo}
                                alt=""
                              />
                            </Grid>
                          )}
                          <Grid item>
                            <Typography variant="h4" className={styles.mb1}>
                              {shortName}
                            </Typography>
                            <Typography>{ownerLocZipCity}</Typography>
                            <Typography>
                              {addressStreet ? addressStreet : ""}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      {ownerLeasingContact &&
                        ownerLeasingContact.map((contact, index) => {
                          return (
                            <Grid key={index} item>
                              <Typography variant="h4" className={styles.mb1}>
                                {contact.email}
                              </Typography>
                              <Typography variant="h4" className={styles.mb1}>
                                {contact.phoneNumber}
                              </Typography>
                            </Grid>
                          );
                        })}
                    </Grid>
                  )
                )}
              </PortletBody>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                    <PortletHeader
                      title={
                        <Typography variant="h3">
                          <FormattedMessage id="OWNERS.PHRASE.MANAGEMENT_BOARD" />
                        </Typography>
                      }
                    />
                    <PortletBody
                      className={styles.portletBodyCenter}
                      fit={ownerBoard && ownerBoard.length}
                    >
                      {ownerBoard && ownerBoard.length ? (
                        <OwnerBoard ownerBoard={ownerBoard} />
                      ) : (
                        <NoData />
                      )}
                    </PortletBody>
                  </Grid>
                <Grid item xs={6}>
                    <PortletHeader
                      title={
                        <Typography variant="h3">
                          <FormattedMessage id="OWNERS.PHRASE.REGISTRY_DETAILS" />
                        </Typography>
                      }
                    />
                    <PortletBody className={styles.portletBodyCenter}>
                      <DashboardExtendedDataWrapper>
                        <DashboardExtendedDataItem
                          value={owner.nip ? owner.nip : "N/A"}
                          label="NIP"
                          variant="h3"
                        />
                        <DashboardExtendedDataItem
                          value={owner.regon ? owner.regon : "N/A"}
                          label="REGON"
                          variant="h3"
                        />
                        <DashboardExtendedDataItem
                          value={owner.krs ? owner.krs : "N/A"}
                          label="KRS"
                          variant="h3"
                        />
                        <DashboardExtendedDataItem
                          value={owner.pkd ? owner.pkd : "N/A"}
                          label="PKD"
                          variant="h3"
                        />
                      </DashboardExtendedDataWrapper>
                    </PortletBody>
                  </Grid>
              </Grid>
            </Portlet>
          </Grid>
        </Grid>
      </TabItem>
    );
  }
}
class OwnerTab4 extends React.Component {
  render() {
    const { ownerSectors, intl } = this.props;
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2" className={styles.tabTitle}>
            <FormattedMessage id="BUILDING.PHRASE.TABS.TENANTS" />
          </Typography>
        </Grid>
        <Grid item xs={6}>
            <Portlet fluidHeight>
              <PortletHeader
                title={
                  <Typography variant="h3">
                    <FormattedMessage id="BUILDING.PHRASE.TENANTS_CHART" />
                  </Typography>
                }
              />
              <PortletBody fit={true}>
                {ownerSectors !== null ? (
                  <OwnerSectors ownerSectors={ownerSectors} intl={intl} />
                ) : (
                  <LoadingScreen />
                )}
              </PortletBody>
            </Portlet>
          </Grid>
      </Grid>
    );
  }
}
class OwnerTab5 extends React.Component {
  render() {
    const { ownerVacancyRate, ownerUnitsTracking, ownerReddIndex } = this.props;
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2" className={styles.tabTitle}>
            <FormattedMessage id="BUILDING.PHRASE.TABS.ANALYSIS" />
          </Typography>
        </Grid>
        <Grid item xs={8}>
            <Portlet fluidHeight>
              <PortletHeader
                title={
                  <Typography variant="h3">
                    <FormattedMessage id="BUILDING.PHRASE.VACANCY_RATE_CHART" />
                  </Typography>
                }
              />
              <PortletBody fit={true}>
                {ownerVacancyRate !== null ? (
                  <OwnerVacancyRate
                    ownerVacancyRate={ownerVacancyRate}
                    height={337}
                  />
                ) : (
                  <LoadingScreen />
                )}
              </PortletBody>
            </Portlet>
          </Grid>
        <Grid item xs={4}>
            {ownerUnitsTracking !== null ? (
              <TrackingDashboard
                label={
                  <Typography variant="h3">
                    <FormattedMessage id="BUILDING.PHRASE.UNITS_TRACKING" />
                  </Typography>
                }
                data={ownerUnitsTracking}
                target="dashboard"
                expanded
              />
            ) : (
              <LoadingScreen />
            )}
          </Grid>
          <Grid item xs={12}>
            <Portlet fluidHeight>
              <PortletHeader
                title={
                  <Typography variant="h3">
                    <FormattedMessage
                      id="DASHBOARD.PHRASE.REDD_INDEX"
                      values={{ market: "" }}
                    />
                  </Typography>
                }
              />
              <PortletBody fit={true}>
                {ownerReddIndex !== null ? (
                  <OwnerReddIndex
                    ownerReddIndex={ownerReddIndex}
                    height={400}
                  />
                ) : (
                  <LoadingScreen />
                )}
              </PortletBody>
            </Portlet>
          </Grid>
      </Grid>
    );
  }
}
class OwnerTab6 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeLoans: null,
    };
  }
  handleFilterLoans = (selected) => {
    const { ownerLoans } = this.props;
    const emptyFilter = selected.length === 0;
    const filterSingleOffice = ownerLoans.filter((loan) =>
      selected.includes(loan.office)
    );
    const filterMultipleOffices = ownerLoans.filter(
      (loan) =>
        loan.offices &&
        loan.offices.some((office) => selected.includes(office.name))
    );
    let filterActiveLoans;
    filterActiveLoans = [
      ...new Set([...filterSingleOffice, ...filterMultipleOffices]),
    ];
    this.setState({
      // eslint-disable-next-line array-callback-return
      activeLoans: emptyFilter ? this.props.ownerLoans : filterActiveLoans,
    });
  };
  render() {
    const { owner, ownerFinances, ownerLoans, fetchOwnerFinances } = this.props;
    let loansBuildingsNames = [];
    if (ownerLoans) {
      // eslint-disable-next-line array-callback-return
      ownerLoans.map((item) => {
        item.office && loansBuildingsNames.push(item.office);
        item.offices &&
          item.offices.map((office) => loansBuildingsNames.push(office.name));
      });
    }
    const uniqueLoansBuildingsNames = loansBuildingsNames.filter(function(
      elem,
      index,
      self
    ) {
      return index === self.indexOf(elem);
    });

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2" className={styles.tabTitle}>
            <FormattedMessage id="OWNERS.PHRASE.TABS.FINANCES_AND_MORTGAGE" />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} style={{ height: "100%" }}>
            <Grid item xs={6}>
              <Portlet fluidHeight>
                <PortletHeader
                  title={
                    <Typography variant="h3">
                      <FormattedMessage id="OWNERS.PHRASE.FINANCES_OVERVIEW" />
                    </Typography>
                  }
                  toolbar={
                    owner.financialYears && owner.financialYears.length ? (
                      <PortletHeaderToolbar>
                        <OwnerFinancialSummary
                          fetchOwnerFinances={fetchOwnerFinances}
                          ownerId={owner.id}
                          financialYears={owner.financialYears}
                          lastYear={ownerFinances && ownerFinances.year}
                        />
                      </PortletHeaderToolbar>
                    ) : (
                      ""
                    )
                  }
                />
                <PortletBody fit>
                  {ownerFinances !== null ? (
                    <OwnerFinancialOverview ownerFinances={ownerFinances} />
                  ) : (
                    <LoadingScreen />
                  )}
                </PortletBody>
              </Portlet>
            </Grid>
            <Grid item xs={6}>
                <Portlet fluidHeight>
                  <PortletHeader
                    title={
                      <Typography variant="h3">
                        <FormattedMessage id="BUILDING.PHRASE.MORTGAGE" />
                      </Typography>
                    }
                    toolbar={
                      <PortletHeaderToolbar>
                        <OwnerMortgageBuildingsFilter
                          loansBuildings={uniqueLoansBuildingsNames}
                          filterLoans={this.handleFilterLoans}
                        />
                      </PortletHeaderToolbar>
                    }
                  />
                  <PortletBody fit={ownerLoans !== null && ownerLoans.length}>
                    {ownerLoans !== null ? (
                      <OwnerLoans
                        ownerLoans={this.state.activeLoans || ownerLoans}
                      />
                    ) : (
                      <LoadingScreen />
                    )}
                  </PortletBody>
                </Portlet>
              </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
class OwnerTab7 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeLoans: null,
    };
  }
  render() {
    const { ownerAcquisitions, ownerAcquisitionsLoading } = this.props;
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2" className={styles.tabTitle}>
            <FormattedMessage id="OWNERS.PHRASE.TABS.ACQUISITIONS" />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Portlet fluidHeight>
            <PortletBody fit={true}>
              {ownerAcquisitionsLoading ? (
                <LoadingScreen />
              ) : ownerAcquisitions ? (
                <TableProperties
                  tableLabel={<FormattedMessage id="MENU.ACQUISITIONS" />}
                  headRows={HEAD_ROWS.ACQUISITIONS_OWNER}
                  bodyRows={ownerAcquisitions}
                  dataSource="acquisitionsOwner"
                  dataTarget={TABLE_CELLS.ACQUISITIONS_OWNER}
                  maxHeight="52.5rem"
                />
              ) : (
                <NoData />
              )}
            </PortletBody>
          </Portlet>
        </Grid>
      </Grid>
    );
  }
}
class OwnerTab8 extends React.Component {
  render() {
    const { ownerLeasingTeams } = this.props;
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2" className={styles.tabTitle}>
            <FormattedMessage id="OWNERS.PHRASE.LEASING_TEAMS" />
          </Typography>
        </Grid>
        <Grid item xs={12}>
            <Portlet fluidHeight>
              <PortletHeader
                title={
                  <Typography variant="h3">
                    <FormattedMessage id="OWNERS.PHRASE.LEASING_TEAMS" />
                  </Typography>
                }
              />
              <PortletBody
                fit={ownerLeasingTeams !== null && ownerLeasingTeams.length}
              >
                {ownerLeasingTeams !== null ? (
                  <OwnerLeasingTeams ownerLeasingTeams={ownerLeasingTeams} />
                ) : (
                  <LoadingScreen />
                )}
              </PortletBody>
            </Portlet>
          </Grid>
      </Grid>
    );
  }
}

export default class OwnerPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ownerLoaded: false,
      isReady: false
    };
    const { fetchOwner } = props;
    const { id } = props.match.params;
    fetchOwner(id);
  }
  componentWillReceiveProps(nextProps) {
    // Set page meta title
    if (nextProps.owner) {
      document.title = nextProps.owner.name
        ? `REDD | ${nextProps.owner.name}`
        : "REDD | Real Estate Digital Data";
    }
    if (nextProps.ownerLoaded !== this.state.ownerLoaded) {
      this.setState({
        ownerLoaded: nextProps.ownerLoaded,
      });
    }
  }
  componentDidMount() {
    const {id} = this.props.match.params;

    if(!this.state.isReady && id) {
      this.setState({
        isReady: true
      }, () => {
        this.props.callReddClickEvent(
          "contacts-owner-detail",
          "company",
          null,
          id
        );
      });
    }
  }
  componentWillUnmount() {
    // Reset page meta title
    document.title = "REDD | Real Estate Digital Data";
  }

  render() {
    const { owner } = this.props;
    return (
      <IsPro paywall>
        {this.state.ownerLoaded ? (
          <Grid container direction="column" wrap="nowrap">
            <Grid item>
              <OwnerHeader data={owner} />
            </Grid>
            <Grid item>
              <OwnerTabs {...this.props} />
            </Grid>
          </Grid>
        ) : (
          <LoadingScreen />
        )}
      </IsPro>
    );
  }
}

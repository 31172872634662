import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import DashboardPage from "./DashboardPage";

const mapStateToProps = (store) => ({
  currentApplicationView: store.user?.data?.currentApplicationView
});

export default injectIntl(
  connect(
    mapStateToProps
  )(DashboardPage)
);
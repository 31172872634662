import React from "react";
import {TABLE_CELLS} from "app/partials/components/TableProperties/index";
import OfficeAddUnit from "app/partials/components/Offices/OfficeAddUnit";
import OfficeAddBuilding from "app/partials/components/Offices/OfficeAddBuilding";
import TeamAddMember from "app/partials/components/Team/TeamAddMember";
import {Grid, Typography} from "@material-ui/core";
import InputSwitcher from "app/partials/components/InputSwitcher";
import {FormattedMessage} from "react-intl";
import LackOfAvailableArea from "app/partials/components/LackOfAvailableArea/LackOfAvailableArea";
import LandlordBuildingsGeneratePDF from "app/pages/offices/BuildingsPage/components/LandlordBuildingsGeneratePDF";
import store from "app/store/store";
import OfficeCommercializationSuspended from "../../../Offices/OfficeCommercializationSuspended";
import FollowerAddNew from "../../../Subscribers/FollowerAddNew/FollowerAddNew";

export default class TableActionsLandlord extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showUnavailable: false,
      showOwned: store.getState().filters && store.getState().filters.isEditable === "true"
    };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const isEditableFilterOn = store.getState().filters && store.getState().filters.isEditable === "true";
    if(this.state.showOwned !== isEditableFilterOn) {
      this.setState({
        showOwned: isEditableFilterOn
      });
    }
  }

  handleChangeSwitcher = name => event => {
    this.setState({
      [name]: event.target.checked
    }, () => {
      if(name === "showUnavailable") {
        this.props.onShowUnavailableUnits(this.state.showUnavailable);
      }
      else if(name === "showOwned") {
        this.props.onShowOwnedBuildings(this.state.showOwned);
      }
    });
  };

  render() {
    const {dataTarget, parentId, parentData, user, teamActiveSubsCount, officeUnitsUnavailable, total} = this.props;
    const {showOwned} = this.state;
    const isUserHasTeam = user && user.team ? "true" : "false";
    const permissionFrontAddBuilding = isUserHasTeam === "true" && showOwned;
    const maxSubs = user.team && user.team.maxActiveSubs && user.team.maxActiveSubs > 0 ? user.team.maxActiveSubs : user.team.maxActiveSubs <= 0 ? <span style={{fontSize: 22, lineHeight: 0, position: "relative", top: 2}}>&#8734;</span> : '-';
    const freeUserShowOwned = user?.team && !user?.isPremium;

    return(
      <>
        {(
            dataTarget === TABLE_CELLS.OFFICES ||
            dataTarget === TABLE_CELLS.UNITS
        ) &&
        <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
          {(
            dataTarget === TABLE_CELLS.OFFICES ||
            dataTarget === TABLE_CELLS.UNITS
          ) && isUserHasTeam && (
            <Grid item>
              <InputSwitcher
                checked={showOwned}
                value="showOwned"
                label={<FormattedMessage id="GENERAL.PHRASE.SHOW_OWNED_PROPERTIES"/>}
                onChange={this.handleChangeSwitcher("showOwned")}
              />
            </Grid>
          )}
          {(dataTarget === TABLE_CELLS.OFFICES) && showOwned && this.props.user?.team && (
            <Grid item>
              <LandlordBuildingsGeneratePDF/>
            </Grid>
          )}
          {(dataTarget === TABLE_CELLS.OFFICES) && (permissionFrontAddBuilding || freeUserShowOwned) && (
            <Grid item>
              <OfficeAddBuilding/>
            </Grid>
          )}
        </Grid>
        }
        {dataTarget === TABLE_CELLS.OFFICE_UNITS && (
          <Grid container spacing={2} alignItems="center" wrap="nowrap">
            {officeUnitsUnavailable && parentData?.isEditable &&
              <Grid item>
                <InputSwitcher
                  checked={this.state.showUnavailable}
                  value="showUnavailable"
                  label={<FormattedMessage id="GENERAL.PHRASE.SHOW_UNAVAILABLE"/>}
                  onChange={this.handleChangeSwitcher("showUnavailable")}
                />
              </Grid>
            }
            {(parentData?.numberOfUnitsAvailable <= 0 || parentData?.lackOfAvailableArea) && parentData?.isEditable && (
            <Grid item>
              <LackOfAvailableArea
                propertyID={parentId}
                lackOfAvailableArea={parentData?.lackOfAvailableArea}
                buildingAvailableFrom={parentData?.buildingAvailableFrom}
                tooltipPosition="bottom"
                target="office"
                modeSwitcher
              />
            </Grid>
            )}
            {parentData?.isEditable &&
            <Grid item>
              <OfficeCommercializationSuspended
                officeId={parentId}
                commercializationSuspended={parentData?.commercializationSuspended}
                commercializationSuspendedDate={parentData?.commercializationSuspendedDate}
                tooltipPosition="bottom"
              />
            </Grid>
            }
            {parentData?.isEditable && total > 0 &&
            <Grid item>
              <OfficeAddUnit officeId={parentId} officeStatus={parentData?.status} lackOfAvailableArea={parentData?.lackOfAvailableArea}/>
            </Grid>
            }
          </Grid>
        )}
        {dataTarget === TABLE_CELLS.TEAM && (
          <Grid container spacing={2} alignItems="center" wrap="nowrap">
            <Grid item style={{marginRight: 25}}>
              {user?.isTeamOwner &&
              <Typography variant="h4"><FormattedMessage id="TEAM.PHRASE.ACTIVE_SUBSCRIPTIONS_COUNT" values={{activeSubs: teamActiveSubsCount, maxSubs}}/></Typography>
              }
            </Grid>
            <Grid item>
              {user?.isTeamOwner && <TeamAddMember/>}
            </Grid>
          </Grid>
        )}
        {dataTarget === TABLE_CELLS.SUBSCRIBERS && <FollowerAddNew/>}
      </>
    );
  }
}
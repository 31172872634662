import React from 'react';
import styles from './index.module.scss';
import clsx from 'clsx';

export default class Thumbnail extends React.Component {
  render() {
    const {src, alt, spacing, size = 60} = this.props;
    return(
        <div className={clsx(spacing === "right" ? styles.spacingRight : spacing === "left" ? styles.spacingLeft : "", styles.thumbImageBox)} style={{width: size, height: size}}>
          <img src={src} alt={alt}/>
        </div>
    );
  }
}
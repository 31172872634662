import React from "react";
import {TextField, Typography} from "@material-ui/core";
import ModalCustom from "app/partials/components/ModalCustom";
import IconColorMUI from "app/partials/components/IconColorMUI";
import NavItem from "app/partials/components/Navigations/NavItem";
import {FormattedMessage} from "react-intl";
import axios from "axios";
import {clearCacheAndStorage} from "../../../../utils/helpers";

export default class UserDeleteAccount extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      confirmPhrase: ''
    }
  }
  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleChange = (event) => {
    this.setState({
      confirmPhrase: event.target.value
    })
  };
  handleSave = () => {
    if(this.state.confirmPhrase === 'DELETE') {
      return axios.delete('/team-members/delete_me/').then(response => {
        if(response.status === 200 || response.status === 204) {
          clearCacheAndStorage();
        }
        return false;
      });
    } else {
      alert('Invalid confirmation phrase');
    }
  };

  render() {
    const {confirmPhrase} = this.state;
    return(
      <ModalCustom
        ref={this.child}
        btn={
          <NavItem name="deleteAccount" title={<FormattedMessage id="USER.PHRASE.DELETE_ACCOUNT"/>} label={<FormattedMessage id="USER.PHRASE.DELETE_ACCOUNT_PERMANENTLY"/>} icon={<IconColorMUI icon="delete" color="danger"/>}/>
        }
        title={<FormattedMessage id="USER.PHRASE.DELETE_ACCOUNT"/>}
        handleSave={this.handleSave}
      >
        <>
          <Typography><FormattedMessage id="USER.PHRASE.DELETE_ACCOUNT_MESSAGE"/> <br/><FormattedMessage id="USER.PHRASE.DELETE_ACCOUNT_MESSAGE_CONFIRM"/></Typography>
          <TextField
            type="text"
            label={<FormattedMessage id="USER.PHRASE.CONFIRMATION_PHRASE"/>}
            margin="normal"
            className="kt-width-full"
            name="confirmPhrase"
            onChange={this.handleChange}
            value={confirmPhrase}
            autoComplete="off"
            style={{whiteSpace: "nowrap"}}
          />
        </>
      </ModalCustom>
    );
  }
}
import React from "react";
import {FormattedMessage} from "react-intl";
import ModalCustom from "../../../../../partials/components/ModalCustom";
import {Grid} from "@material-ui/core";
import LeadInfoBox from "./LeadInfoBox";
import styles from "./index.module.scss";
import LoadingScreen from "../../../../../partials/components/LoadingScreen";
import {handleEmptyValue, toNumberFormat} from "../../../../../utils/helpers";
import PropertyCardPreview from "../../../../../partials/components/_DataParts/PropertyCardPreview";
import {ROUTES} from "../../../../../constants";
import PropertySubscription from "../../../components/LeadsPages/components/PropertySubscription";
import APPLICATION_VIEWS from "../../../../../constants/APPLICATION_VIEWS";
import IconColorMUI from "../../../../../partials/components/IconColorMUI";
import Button from "../../../../../partials/components/Button";

export default class LeadDetailsModal extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
  }
  // Handlers
  handleOpen = () => {
    const {currentApplicationView, leadID, onFetch, callReddClickEvent} = this.props;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    callReddClickEvent && callReddClickEvent(
      isOfficeView ? 'finne-leads-offices-lead-view-details-click' : 'finne-leads-industrial-lead-view-details-click',
      'lead',
      'marketplace',
      leadID
    );
    onFetch && onFetch();
  }

  render() {
    const {data, loading, property, currentApplicationView} = this.props;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    const notZero = (value) => {
      const numValue = value ? Number(value).toFixed(0) : null;
      return numValue && numValue !== "0";
    }
    const areaRange = [
      ...notZero(data?.crmAreaFrom) ? [Number(data?.crmAreaFrom).toFixed(0)] : [],
      ...notZero(data?.crmAreaTo) ? [Number(data?.crmAreaTo).toFixed(0)] : []
    ].join(" - ");

    return (
      <ModalCustom
        ref={this.child}
        btn={
          <Button
            style={{width: "9.5rem"}}
          >
            <FormattedMessage id="VOCABULARY.Tenant details"/>
          </Button>
        }
        maxWidth="sm"
        title={loading || !data ? "" : <span className={styles.infoModalTitle}>{data?.crmName}</span>}
        onOpen={this.handleOpen}
      >
        {loading ? <LoadingScreen/> :
        <>
          <LeadInfoBox
            contactName={data?.crmLastName}
            contactEmail={data?.crmEmail}
            contactPhone={data?.crmPhoneNumber}
          >
            <p>{data?.crmTenantsDescription}</p>
          </LeadInfoBox>
        <h3 className={styles.infoSectionTitle}><FormattedMessage id="LEADS.PHRASE.DETAILS"/></h3>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LeadInfoBox title={<FormattedMessage id="LEADS.PHRASE.PROPERTY"/>}>
              <PropertyCardPreview
                propertyID={property?.id}
                marketID={property?.marketId}
                route={isOfficeView ? ROUTES.BUILDING_PAGE : ROUTES.INDUSTRIAL_PARK}
                routeNewTab
              >
                <Grid container spacing={0}>
                  <Grid item>
                    <IconColorMUI icon="open_in_new" style={{paddingRight: "5px"}}/>
                  </Grid>
                  <Grid item>
                    <span className={styles.propertyName}>{property?.name}</span>
                  </Grid>
                </Grid>
              </PropertyCardPreview>
              <PropertySubscription subscription={property?.subscription}/>
            </LeadInfoBox>
          </Grid>
          <Grid item xs={6}>
            <LeadInfoBox title={<FormattedMessage id="LEADS.PHRASE.LOCATION"/>}>
              <p>{handleEmptyValue(data?.crmLocation)}</p>
            </LeadInfoBox>
          </Grid>
          <Grid item xs={6}>
            <LeadInfoBox title={<FormattedMessage id="LEADS.PHRASE.AREA"/>}>
              <p>{toNumberFormat(areaRange, 'range', false, 'area')}</p>
            </LeadInfoBox>
          </Grid>
          {data?.crmStartDate ?
          <Grid item xs={12}>
            <LeadInfoBox title={<FormattedMessage id="LEADS.PHRASE.LEASE_DATE"/>}>
              <p>{data?.crmStartDate}</p>
            </LeadInfoBox>
          </Grid> : null
          }
          {data?.crmTechnicalRequirements ?
          <Grid item xs={12}>
            <LeadInfoBox title={<FormattedMessage id="LEADS.PHRASE.REQUIREMENTS"/>}>
              <p>{data?.crmTechnicalRequirements}</p>
            </LeadInfoBox>
          </Grid> : null
          }
          {data?.crmAdditionalInfo ?
          <Grid item xs={12}>
            <LeadInfoBox title={<FormattedMessage id="LEADS.PHRASE.ADDITIONAL_INFORMATION"/>}>
              <p>{data?.crmAdditionalInfo}</p>
            </LeadInfoBox>
          </Grid> : null
          }
        </Grid>
        </>
        }
      </ModalCustom>
    );
  }
}
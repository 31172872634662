import React from "react";
import {FormattedMessage} from "react-intl";
import {Typography} from "@material-ui/core";
import {Portlet, PortletBody, PortletHeader} from "../../../partials/content/Portlet";
import ReactVisibilitySensor from "react-visibility-sensor";
import APPLICATION_VIEWS from "../../../constants/APPLICATION_VIEWS";
import SupplyBoostBody from "./components/SupplyBoostBody";
import store from "../../../store/store";
import {createVisibleArray} from "../../../utils/helpers";
import IsApplicationView from "../../../utils/IsApplicationView";
import styles from "../components/LeadsPages/index.module.scss";
import IconColorMUI from "../../../partials/components/IconColorMUI";
import AutocompleteCustom from "../../../partials/components/AutocompleteCustom";
import {FILTERS_STATES} from "../../../partials/components/FiltersDrawer/constants";

export default class SupplyBoostPage extends React.Component {
  constructor(props) {
    super(props);
    const isOfficeView = IsApplicationView({expectedAppView: APPLICATION_VIEWS.OFFICES});
    this.state = {
      initLoaded: false,
      marketsLoaded: false,
      isReady: false,
      filters: {
        ...isOfficeView ? FILTERS_STATES.SUPPLY_BOOST : FILTERS_STATES.SUPPLY_BOOST_INDUSTRIAL,
        ...{market: store.getState().filters?.market}
      }
    }
  }
  componentDidMount() {
    const {setLatestActiveTablePage, filterReset} = this.props;
    setLatestActiveTablePage(1);
    filterReset(["market"]);
  }
  // Handlers
  handleOnViewportFetch = (isVisible) => {
    const {
      fetchOfficesSupplyBoost,
      fetchIndustrialSupplyBoost,
      fetchMarkets,
      fetchMarketsIndustrial,
      officesSupplyBoostLoading,
      industrialSupplyBoostLoading,
      callReddClickEvent,
      marketsLoading,
      marketsIndustrialLoading,
      filterByModules
    } = this.props;
    const {isReady, initLoaded, marketsLoaded} = this.state;
    const isOfficeView = IsApplicationView({expectedAppView: APPLICATION_VIEWS.OFFICES});
    const isIndustrialView = IsApplicationView({expectedAppView: APPLICATION_VIEWS.INDUSTRIAL});
    const params = this.state.filters;
    filterByModules(params);

    // Fetch Offices Data
    if(isVisible && isOfficeView && !officesSupplyBoostLoading && fetchOfficesSupplyBoost && !initLoaded) {
      const params = {
        page: 1,
        ...store.getState().filters
      };
      this.setState({
        initLoaded: true
      }, () => fetchOfficesSupplyBoost({params}));
    }
    // Fetch Industrial Data
    if(isVisible && isIndustrialView && !industrialSupplyBoostLoading && fetchIndustrialSupplyBoost && !initLoaded) {
      const params = {
        page: 1,
        ...store.getState().filters
      };
      this.setState({
        initLoaded: true
      }, () => fetchIndustrialSupplyBoost({params}));
    }
    // Fetch Offices Markets Data
    if(isVisible && isOfficeView && !marketsLoading && fetchMarkets && !marketsLoaded) {
      this.setState({
        marketsLoaded: true
      }, () => fetchMarkets());
    }
    // Fetch Industrial Markets Data
    if(isVisible && isIndustrialView && !marketsIndustrialLoading && fetchMarketsIndustrial && !marketsLoaded) {
      this.setState({
        marketsLoaded: true
      }, () => fetchMarketsIndustrial());
    }
    // Tracking event
    if(isVisible && !isReady) {
      this.setState({
        isReady: true
      }, () => {
        if (isOfficeView) {
          callReddClickEvent(
            "supply-boost-offices-page-enter",
            "user"
          );
        }
        else if(isIndustrialView) {
          callReddClickEvent(
            "supply-boost-industrial-page-enter",
            "user"
          );
        }
      });
    }
  }
  handleMarketChange = (name, value) => {
    const selectedMarket = value?.id
    this.setState(
      {
        filters: {
          ...this.state.filters,
          [name]: selectedMarket ? [selectedMarket] : "",
        },
      },
      () => {
        const {filterByModules, setLatestActiveTablePage} = this.props;
        const params = this.state.filters;
        filterByModules(params);

        if (name === "market") {
          const { fetchOfficesSupplyBoost, fetchIndustrialSupplyBoost } = this.props;
          const params = {
            ...store.getState().filters,
            page: 1
          }
          setLatestActiveTablePage(1);

          if(IsApplicationView({expectedAppView: APPLICATION_VIEWS.OFFICES})) {
            fetchOfficesSupplyBoost && fetchOfficesSupplyBoost({params});
          }
          else if(IsApplicationView({expectedAppView: APPLICATION_VIEWS.INDUSTRIAL})) {
            fetchIndustrialSupplyBoost && fetchIndustrialSupplyBoost({params});
          }
        }
      }
    );
  };

  render() {
    const {
      officesSupplyBoost,
      industrialSupplyBoost,
      officesSupplyBoostLoading,
      industrialSupplyBoostLoading,
      markets,
      marketsIndustrial,
      marketsLoading,
      marketsIndustrialLoading,
      fetchOfficesSupplyBoost,
      fetchIndustrialSupplyBoost
    } = this.props;
    const {filters} = this.state;
    const isOfficeView = IsApplicationView({expectedAppView: APPLICATION_VIEWS.OFFICES});
    const isIndustrialView = IsApplicationView({expectedAppView: APPLICATION_VIEWS.INDUSTRIAL});
    const supplyBoostData = isOfficeView ? officesSupplyBoost : isIndustrialView ? industrialSupplyBoost : null;
    const supplyBoostLoading = isOfficeView ? officesSupplyBoostLoading : isIndustrialView ? industrialSupplyBoostLoading : null;
    const supplyBoostFetch = isOfficeView ? fetchOfficesSupplyBoost : isIndustrialView ? fetchIndustrialSupplyBoost : null;
    const loadingMarkets = isOfficeView ? marketsLoading : isIndustrialView ? marketsIndustrialLoading : false;
    const marketsData = isOfficeView ? markets : isIndustrialView ? marketsIndustrial : null;
    const visibleMarkets = !loadingMarkets && marketsData ? createVisibleArray(marketsData, ["id", "name"]) : [];
    const selectedMarket = visibleMarkets?.filter(item => item?.id === filters?.market?.[0])?.[0];

    return(
      <Portlet fluidHeight>
        <PortletHeader
          title={
          <ReactVisibilitySensor
            onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
            scrollCheck
            partialVisibility
          >
            <Typography variant="h3"><FormattedMessage id="MENU.SUPPLY_BOOST"/></Typography>
          </ReactVisibilitySensor>
          }
          style={{borderBottom: 0}}
          titleToolbar={
          !loadingMarkets && marketsData &&
            <AutocompleteCustom
              name="market"
              value={selectedMarket || undefined}
              options={visibleMarkets}
              optionLabelKey="name"
              inputLabel={<FormattedMessage id="FILTER.FORM.FIELD.MARKET" />}
              inputValue={selectedMarket?.name}
              style={{
                width: "18rem"
              }}
              groupedEnable={true}
              onChange={(event, value) => this.handleMarketChange("market", value)}
            />
          }
          titleToolbarBottom={
          <div className={styles.titleDescriptionWrapper}>
            <IconColorMUI
              icon="info_outlined"
              size="1.8rem"
              style={{lineHeight: "0"}}
            />
            <Typography variant="body2">
              <FormattedMessage id="VOCABULARY.Supply boost in the last 365 days."/>
            </Typography>
          </div>
          }
        />
        <PortletBody style={{paddingTop: 0}}>
          <SupplyBoostBody
            items={supplyBoostData?.items}
            itemsPerPage={supplyBoostData?.itemsPerPage}
            itemsCount={supplyBoostData?.itemsCount}
            loading={supplyBoostLoading}
            onFetch={supplyBoostFetch}
            {...this.props}
          />
        </PortletBody>
      </Portlet>
    );
  }
}

import React from "react";
import styles from "../index.module.scss";
import {FormattedMessage} from "react-intl";

export default function TransactionsDatabaseStatus({databaseStatus}) {
  return(
    <div className={styles.databaseStatusWrapper}>
      <div className={styles.dataPreview}>
        <p><strong><FormattedMessage id="VOCABULARY.Recorded transactions"/>:</strong> {databaseStatus?.recordedTransactions}</p>
        <p><strong><FormattedMessage id="VOCABULARY.Date range"/>:</strong> {databaseStatus?.dateRange}</p>
      </div>
      <div className={styles.marketsPreview}>
        <p><strong><FormattedMessage id="VOCABULARY.Markets"/>:</strong> {databaseStatus?.markets?.join(", ")}</p>
      </div>
      <div className={styles.contributorsPreview}>
        <p><strong><FormattedMessage id="VOCABULARY.Contributing companies"/>:</strong> {databaseStatus?.contributors?.slice(0, 10)?.join(", ")}</p>
      </div>
    </div>
  );
}
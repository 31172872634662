import {Grid} from "@material-ui/core";
import React from "react";
import {toNumberFormat} from "app/utils/helpers";
import BuildingCardPreview from "app/partials/components/_DataParts/BuildingCardPreview";
import {FormattedMessage} from "react-intl";
import IconLabel from "app/partials/components/IconLabel";
import connect from "react-redux/es/connect/connect";
import APPLICATION_VIEWS from "../../../constants/APPLICATION_VIEWS";

class BuildingExpandedCardPreview extends React.Component {
  render() {
    const {data, simpleView, currentApplicationView} = this.props;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    const rentFromFinal = isOfficeView ? data?.rentFromEur : data?.rentFrom;
    const serviceChargeFinal = isOfficeView ? data?.serviceChargesPln : data?.serviceCharge;

    return (
      <Grid container spacing={4} alignItems="center">
        <Grid item>
          <BuildingCardPreview name={data?.name} street={data?.addressStreet} thumb={data?.mainPhotoThumbnail} officeID={data?.id} margin/>
        </Grid>
        {!simpleView && <Grid item>
          <IconLabel
            icon="apps"
            label={<FormattedMessage id="BUILDING.PHRASE.AVAILABLE_UNITS"/>}
            value={toNumberFormat(data?.numberOfUnitsAvailable)}
          />
        </Grid>}
        {!simpleView && <Grid item>
          <IconLabel
            icon="flip_to_back"
            label={<FormattedMessage id="BUILDING.PHRASE.AVAILABLE_AREA"/>}
            value={toNumberFormat(data?.totalAreaNoSubleaseOfficeAvailable, 'area')}
          />
        </Grid>}
        {!simpleView && <Grid item>
          <IconLabel
            icon="apartment"
            label={<FormattedMessage id="BUILDING.PHRASE.RENT_FROM"/>}
            value={toNumberFormat(rentFromFinal, 'currency')}
          />
        </Grid>}
        {!simpleView && <Grid item>
          <IconLabel
            icon="local_convenience_store"
            label={<FormattedMessage id="BUILDING.PHRASE.SERVICE_CHARGE"/>}
            value={toNumberFormat(serviceChargeFinal, 'currency', 'PLN')}
          />
        </Grid>}
      </Grid>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  currentApplicationView: user?.data?.currentApplicationView
});

export default connect(
  mapStateToProps,
  null
)(BuildingExpandedCardPreview);
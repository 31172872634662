import React from 'react';
import {Icon} from '@material-ui/core';
import styles from './index.module.scss';
import clsx from 'clsx';

export default class IconColorMUI extends React.Component {
  render() {
    const {icon, color, className, onClick, size = "inherit", style} = this.props;
    let iconColor = styles.default;
    if(color === "success") {
      iconColor = styles.success;
    } else if(color === "warning") {
      iconColor = styles.warning;
    } else if(color === "danger") {
      iconColor = styles.danger;
    } else if(color === "neutral") {
      iconColor = styles.neutral;
    } else if(color === "white") {
      iconColor = styles.white;
    } else {
      iconColor = styles.inherit;
    }
    return(
      <span className={clsx(styles.iconWrapper, iconColor, className)} onClick={onClick} style={style}>
        <Icon style={{fontSize: size}}>{icon}</Icon>
      </span>
    );
  }
}
import React from "react";
import TableProperties from "app/partials/components/TableProperties/TableProperties";

// Component that handle Table List View for Properties data (offices, units)

export default class ListView extends React.Component {
  render() {
    const {
      tableLabel,
      tableHeadRows,
      tableBodyRows,
      tableDataSource,
      tableDataTarget,
      handleFetchData,
      loading,
      itemsPage,
      itemsPerPage,
      itemsCount,
      totalItems,
      filterBarEnable,
      onEnterViewport
    } = this.props;

    return <TableProperties
      tableLabel={tableLabel}
      headRows={tableHeadRows}
      bodyRows={tableBodyRows}
      dataSource={tableDataSource}
      dataTarget={tableDataTarget}
      itemsPage={itemsPage}
      itemsPerPage={itemsPerPage}
      itemsCount={itemsCount}
      totalItems={totalItems}
      handleFetchData={handleFetchData}
      loading={loading}
      filterBarEnable={filterBarEnable}
      onEnterViewport={onEnterViewport}
    />;
  }
}
import React from "react";
import {injectIntl} from "react-intl";
import {Grid} from "@material-ui/core";
import DashboardLatestLeads from "app/pages/common/DashboardPage/components/DashboardLatestLeads";
import DashboardPropertiesOffices
  from "app/pages/common/DashboardPage/components/DashboardPropertiesOffices";
import DashboardPropertiesIndustrial
  from "app/pages/common/DashboardPage/components/DashboardPropertiesIndustrial";
import DashboardVisitingCompanies from "app/pages/common/DashboardPage/components/DashboardVisitingCompanies";
import DashboardLatestOffers from "app/pages/common/DashboardPage/components/DashboardLatestOffers";
import APPLICATION_VIEWS from "../../../constants/APPLICATION_VIEWS";
import IsAccountType from "../../../utils/IsAccountType";
import ACCOUNT_TYPE from "../../../constants/ACCOUNT_TYPE";
import IsApplicationView from "../../../utils/IsApplicationView";
import IsPro from "../../../utils/IsPro";
import PaywallBannerRowTable from "../../../partials/components/PayWall/banners/PaywallBannerRowTable";
import PaywallBannerDefault from "../../../partials/components/PayWall/banners/PaywallBannerDefault";
import DashboardLatestTransactions from "./components/DashboardLatestTransactions";
import DashboardSupplyBoost from "./components/DashboardSupplyBoost";

class DashboardPage extends React.Component {
  render() {
    const isLandlordAccount = IsAccountType({expectedAccountType: ACCOUNT_TYPE.LANDLORD});
    return(
      <Grid container spacing={2}>
        <IsAccountType expectedAccountType={ACCOUNT_TYPE.LANDLORD}>
          <Grid item xs={12}>
            <DashboardLatestLeads/>
          </Grid>
        </IsAccountType>
        <Grid item xs={6}>
          <DashboardLatestTransactions/>
        </Grid>
        <Grid item xs={6}>
          <DashboardSupplyBoost/>
        </Grid>
        <Grid item xs={12}>
          <IsApplicationView expectedAppView={APPLICATION_VIEWS.OFFICES}>
            <DashboardPropertiesOffices/>
          </IsApplicationView>
          <IsApplicationView expectedAppView={APPLICATION_VIEWS.INDUSTRIAL}>
            <DashboardPropertiesIndustrial/>
          </IsApplicationView>
        </Grid>
        <IsAccountType expectedAccountType={ACCOUNT_TYPE.LANDLORD}>
          <Grid item xs={6}>
            <DashboardVisitingCompanies/>
          </Grid>
        </IsAccountType>
        <Grid item xs={isLandlordAccount ? 6 : 12}>
          <IsPro
            paywall
            paywallComponent={
            isLandlordAccount ?
              <PaywallBannerDefault
                style={{width: "100%", height: "100%"}}
                bannerVariant="default-page"
              /> :
              <PaywallBannerRowTable/>
            }
          >
            <DashboardLatestOffers/>
          </IsPro>
        </Grid>
      </Grid>
    );
  }
}

export default injectIntl(DashboardPage);
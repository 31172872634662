// Filter Reducer
import {FILTERS_STATES} from "app/partials/components/FiltersDrawer/constants";
import _ from "lodash";
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import APPLICATION_VIEWS from "../../../constants/APPLICATION_VIEWS";

const initialState = {
  ...FILTERS_STATES.BUILDING_DETAILS,
  ...FILTERS_STATES.UNIT_DETAILS,
  ...FILTERS_STATES.COWORKS_DETAILS,
  ...FILTERS_STATES.RENT_TERMS,
  ...FILTERS_STATES.AREA_FILTERS,
  ...FILTERS_STATES.PREMIUM_FILTERS,
  ...FILTERS_STATES.ORDER,
  ...FILTERS_STATES.ROWS_PER_PAGE,
  ...FILTERS_STATES.OFFICES_FILTERS,
  ...FILTERS_STATES.OWNERS_FILTERS,
  ...FILTERS_STATES.INDUSTRIAL_FILTERS,
  ...FILTERS_STATES.SYMMCO,
  ...FILTERS_STATES.LATEST_TRANSACTIONS,
  ...FILTERS_STATES.LATEST_TRANSACTIONS_INDUSTRIAL,
  ...FILTERS_STATES.SUPPLY_BOOST,
  ...FILTERS_STATES.SUPPLY_BOOST_INDUSTRIAL,
  ...FILTERS_STATES.INTERESTED_IN_MY_BUILDINGS
};

export const reducer = persistReducer(
  { storage, key: "filters" },
  (state = initialState, action) => {
    switch (action.type) {
      case 'FILTER_BY_MODULE':
        return {
           ...state, pageReset: false, ...action.filters
        };
      case 'FILTER_RESET':
        const filtersToOmit = action?.filtersToOmit;
        const filtersAsArray = Object.entries(state);
        // Persist filters
        const filtersToPersistArray = filtersToOmit?.length > 0 ? filtersAsArray?.filter(([key, value]) => filtersToOmit.includes(key) && !_.isEmpty(value)) : null;
        const filtersToPersist = filtersToPersistArray?.length > 0 ? Object.fromEntries(filtersToPersistArray) : {};
        // Prep Default Filters
        const marketIsEmpty = filtersAsArray?.filter(([key, value]) => key === "market" && _.isEmpty(value))?.length > 0;
        const initFilters = {
          ...initialState,
          ...action?.currentApplicationView === APPLICATION_VIEWS.OFFICES ? {market: FILTERS_STATES.OFFICES_FILTERS.market} : {},
          ...action?.currentApplicationView === APPLICATION_VIEWS.INDUSTRIAL ? {market: FILTERS_STATES.INDUSTRIAL_FILTERS.market} : {},
          ...action?.fullReset || marketIsEmpty ? {market: ""} : {},
          ...{district: ""},
          ...FILTERS_STATES.ORDER
        };

        return {
          ...initFilters,
          ...filtersToPersist,
          pageReset: true
        };

      default:
        return state;
    }
  }
);


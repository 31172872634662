import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {put, takeLatest} from "redux-saga/effects";

const initialState = {
  latestPage: 1
};

export const reducer = persistReducer(
  { storage, key: "tablePage" },
  (state = initialState, action) => {
    switch (action.type) {
      case 'SET_LATEST_ACTIVE_TABLE_PAGE_REQUEST': {
        return { ...state};
      }
      case 'SET_LATEST_ACTIVE_TABLE_PAGE_SUCCESS': {
        return {...state, latestPage: action.payload};
      }

      default:
        return state;
    }
  }
);

function* setLatestActiveTablePage(action) {
    const page = action.payload;
  try {
    yield put({ type: "SET_LATEST_ACTIVE_TABLE_PAGE_SUCCESS", payload: page });
  }
  catch(err) {
    console.log(err);
  }
}

export function* saga() {
  yield takeLatest('SET_LATEST_ACTIVE_TABLE_PAGE_REQUEST', setLatestActiveTablePage);
}

import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {put, takeLatest} from "redux-saga/effects";
import axios from "axios";
import {resetFilters, setFilters} from "app/utils/filters";

const initialState = {
  loaded: false,
  loading: false,
  items: {},
  itemsCount: null,
  itemsPerPage: null,
  owner: null,
  ownerLoaded: false,
  ownerPortfolio: null,
  ownerPortfolioLoading: false,
  ownerBoard: null,
  ownerFinances: null,
  ownerLoans: null,
  ownerPhotos: null,
  ownerUnitsTracking: null,
  ownerLeasingTeams: null,
  ownerSectors: null,
  ownerVacancyRate: null,
  ownerReddIndex: null,
  ownerAcquisitions: null,
  ownerAcquisitionsLoading: false
};

const handleOwnerFinancesFilters = (filters) => {
    return `?year=${filters}`;
};

export const reducer = persistReducer(
  { storage, key: "owners" },
  (state = initialState, action) => {
    switch (action.type) {
      // RESET OWNERS LOADING STATES
      case 'RESET_OWNERS_LOADING_STATES_REQUEST': {
        return {
          ...state,
          loading: false,
          ownerPortfolioLoading: false,
          ownerAcquisitionsLoading: false
        };
      }
      //
      case 'FETCH_OWNERS_REQUEST': {
        return { ...state, loading: true};
      }
      case 'FETCH_OWNERS_SUCCESS': {
        const { items, itemsCount, itemsPerPage } = action.payload;
        return { ...state, items, itemsCount, itemsPerPage, loaded: true, loading: false }
      }
      case 'FETCH_OWNER_REQUEST': {
        return { ...state, owner: null, loading: true, ownerLoaded: false};
      }
      case 'FETCH_OWNER_SUCCESS': {
        return { ...state, owner: action.payload, loading: false, ownerLoaded: true }
      }
      case 'FETCH_OWNER_PORTFOLIO_REQUEST': {
        return { ...state, ownerPortfolio: null, ownerPortfolioLoading: true }
      }
      case 'FETCH_OWNER_PORTFOLIO_SUCCESS': {
        return { ...state, ownerPortfolio: action.payload, ownerPortfolioLoading: false }
      }
      case 'FETCH_OWNER_BOARD_REQUEST': {
        return { ...state, ownerBoard: null }
      }
      case 'FETCH_OWNER_BOARD_SUCCESS': {
        return { ...state, ownerBoard: action.payload }
      }
      case 'FETCH_OWNER_FINANCES_REQUEST': {
        return { ...state, ownerFinances: null }
      }
      case 'FETCH_OWNER_FINANCES_SUCCESS': {
        return { ...state, ownerFinances: action.payload }
      }
      case 'FETCH_OWNER_LOANS_REQUEST': {
        return { ...state, ownerLoans: null }
      }
      case 'FETCH_OWNER_LOANS_SUCCESS': {
        return { ...state, ownerLoans: action.payload }
      }
      case 'FETCH_OWNER_PHOTOS_REQUEST': {
        return { ...state, ownerPhotos: null }
      }
      case 'FETCH_OWNER_PHOTOS_SUCCESS': {
        return { ...state, ownerPhotos: action.payload }
      }
      case 'FETCH_OWNER_UNITS_TRACKING_REQUEST': {
        return { ...state, ownerUnitsTracking: null }
      }
      case 'FETCH_OWNER_UNITS_TRACKING_SUCCESS': {
        return { ...state, ownerUnitsTracking: action.payload }
      }
      case 'FETCH_OWNER_LEASING_TEAMS_REQUEST': {
        return { ...state, ownerLeasingTeams: null }
      }
      case 'FETCH_OWNER_LEASING_TEAMS_SUCCESS': {
        return { ...state, ownerLeasingTeams: action.payload }
      }
      case 'FETCH_OWNER_SECTORS_REQUEST': {
        return { ...state, ownerSectors: null }
      }
      case 'FETCH_OWNER_SECTORS_SUCCESS': {
        return { ...state, ownerSectors: action.payload }
      }
      case 'FETCH_OWNER_VACANCY_RATE_REQUEST': {
        return { ...state, ownerVacancyRate: null }
      }
      case 'FETCH_OWNER_VACANCY_RATE_SUCCESS': {
        return { ...state, ownerVacancyRate: action.payload }
      }
      case 'FETCH_OWNER_REDD_INDEX_REQUEST': {
        return { ...state, ownerReddIndex: null }
      }
      case 'FETCH_OWNER_REDD_INDEX_SUCCESS': {
        return { ...state, ownerReddIndex: action.payload }
      }
      case 'FETCH_OWNER_LEASING_CONTACT_REQUEST': {
        return { ...state, ownerLeasingContact: null }
      }
      case 'FETCH_OWNER_LEASING_CONTACT_SUCCESS': {
        return { ...state, ownerLeasingContact: action.payload }
      }
      case 'FETCH_OWNER_ACQUISITIONS_REQUEST': {
        return { ...state, ownerAcquisitions: null, ownerAcquisitionsLoading: true }
      }
      case 'FETCH_OWNER_ACQUISITIONS_SUCCESS': {
        return { ...state, ownerAcquisitions: action.payload, ownerAcquisitionsLoading: false }
      }

      default:
        return state;
    }
  }
);

// FETCH DATA
function* fetchOwners(action) {
  const params = action.payload.params;
  const filters = setFilters(params, 'ownerdetail__portfolio');
  try {
    const ownersData = yield axios.get(`/owners/${filters}`);
    yield put({ type: "FETCH_OWNERS_SUCCESS", payload: ownersData.data || [{ error: ownersData.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchOwnersReset() {
  const filtersReset = resetFilters('ownerdetail__portfolio');
  try {
    const owners = yield axios.get(`/owners/${filtersReset}`);
    yield put({ type: "FETCH_OWNERS_SUCCESS", payload: owners.data || [{ error: owners.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchOwner(action) {
  const ownerId = action.payload;
  try {
    const ownersData = yield axios.get(`/owners/${ownerId}/`);
    yield put({ type: "FETCH_OWNER_SUCCESS", payload: ownersData.data || [{ error: ownersData.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchOwnerPortfolio(action) {
  const id = action.payload;
  try {
    const ownerPortfolio = yield axios.get(`/owners/${id}/get-portfolio/`);
    yield put({ type: "FETCH_OWNER_PORTFOLIO_SUCCESS", payload: ownerPortfolio.data || [{ error: ownerPortfolio.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchOwnerBoard(action) {
  const id = action.payload;
  try {
    const ownerBoard = yield axios.get(`/owners/${id}/get-board/`);
    yield put({ type: "FETCH_OWNER_BOARD_SUCCESS", payload: ownerBoard.data || [{ error: ownerBoard.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchOwnerFinances(action) {
  const {ownerId, filters} = action.payload;
  const financesFilters = filters ? handleOwnerFinancesFilters(filters) : '';
  try {
    const ownerFinances = yield axios.get(`/owners/${ownerId}/get-finances/${financesFilters}`);
    yield put({ type: "FETCH_OWNER_FINANCES_SUCCESS", payload: ownerFinances.data || [{ error: ownerFinances.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchOwnerLoans(action) {
  const id = action.payload;
  try {
    const ownerLoans = yield axios.get(`/owners/${id}/get-loans/`);
    yield put({ type: "FETCH_OWNER_LOANS_SUCCESS", payload: ownerLoans.data || [{ error: ownerLoans.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchOwnerPhotos(action) {
  const id = action.payload;
  try {
    const ownerPhotos = yield axios.get(`/owners/${id}/get-photos/`);
    yield put({ type: "FETCH_OWNER_PHOTOS_SUCCESS", payload: ownerPhotos.data || [{ error: ownerPhotos.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchOwnerUnitsTracking(action) {
  const id = action.payload;
  try {
    const ownerUnitsTracking = yield axios.get(`/owners/${id}/get-units_tracking/`);
    yield put({ type: "FETCH_OWNER_UNITS_TRACKING_SUCCESS", payload: ownerUnitsTracking.data || [{ error: ownerUnitsTracking.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchOwnerLeasingTeams(action) {
  const id = action.payload;
  try {
    const ownerLeasingTeams = yield axios.get(`/owners/${id}/get-leasing-teams/`);
    yield put({ type: "FETCH_OWNER_LEASING_TEAMS_SUCCESS", payload: ownerLeasingTeams.data || [{ error: ownerLeasingTeams.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchOwnerSectors(action) {
  const id = action.payload;
  try {
    const ownerSectors = yield axios.get(`/owners/${id}/get-sectors/`);
    yield put({ type: "FETCH_OWNER_SECTORS_SUCCESS", payload: ownerSectors.data || [{ error: ownerSectors.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchOwnerVacancyRate(action) {
  const id = action.payload;
  try {
    const ownerVacancyRate = yield axios.get(`/owners/${id}/get_vacancy-rate-logs/`);
    yield put({ type: "FETCH_OWNER_VACANCY_RATE_SUCCESS", payload: ownerVacancyRate.data || [{ error: ownerVacancyRate.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchOwnerReddIndex(action) {
  const id = action.payload;
  try {
    const ownerReddIndex = yield axios.get(`/owners/${id}/get-index/`);
    yield put({ type: "FETCH_OWNER_REDD_INDEX_SUCCESS", payload: ownerReddIndex.data || [{ error: ownerReddIndex.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchOwnerLeasingContact(action) {
  const ownerId = action.payload;
  try {
    const ownerLeasingContact = yield axios.get(`/owners/${ownerId}/get-contact-persons/`);
    yield put({ type: "FETCH_OWNER_LEASING_CONTACT_SUCCESS", payload: ownerLeasingContact.data || [{ error: ownerLeasingContact.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchOwnerAcquisitions(action) {
  const ownerId = action.payload;
  try {
    const ownerAcquisitions = yield axios.get(`/owners/${ownerId}/acquisitions/`);
    yield put({ type: "FETCH_OWNER_ACQUISITIONS_SUCCESS", payload: ownerAcquisitions.data || [{ error: ownerAcquisitions.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}

export function* saga() {
  yield takeLatest('FETCH_OWNERS_REQUEST', fetchOwners);
  yield takeLatest('FETCH_OWNERS_RESET_REQUEST', fetchOwnersReset);
  yield takeLatest('FETCH_OWNER_REQUEST', fetchOwner);
  yield takeLatest('FETCH_OWNER_PORTFOLIO_REQUEST', fetchOwnerPortfolio);
  yield takeLatest('FETCH_OWNER_BOARD_REQUEST', fetchOwnerBoard);
  yield takeLatest('FETCH_OWNER_FINANCES_REQUEST', fetchOwnerFinances);
  yield takeLatest('FETCH_OWNER_LOANS_REQUEST', fetchOwnerLoans);
  yield takeLatest('FETCH_OWNER_PHOTOS_REQUEST', fetchOwnerPhotos);
  yield takeLatest('FETCH_OWNER_UNITS_TRACKING_REQUEST', fetchOwnerUnitsTracking);
  yield takeLatest('FETCH_OWNER_LEASING_TEAMS_REQUEST', fetchOwnerLeasingTeams);
  yield takeLatest('FETCH_OWNER_SECTORS_REQUEST', fetchOwnerSectors);
  yield takeLatest('FETCH_OWNER_VACANCY_RATE_REQUEST', fetchOwnerVacancyRate);
  yield takeLatest('FETCH_OWNER_REDD_INDEX_REQUEST', fetchOwnerReddIndex);
  yield takeLatest('FETCH_OWNER_LEASING_CONTACT_REQUEST', fetchOwnerLeasingContact);
  yield takeLatest('FETCH_OWNER_ACQUISITIONS_REQUEST', fetchOwnerAcquisitions);
}

import React from "react";
import BarChart from "app/partials/components/Charts/BarChart";
import {FormattedMessage} from "react-intl";
import NoData from "app/partials/components/NoData/NoData";

export default class OwnerVacancyRate extends React.Component {
  getVacancyRate = () => {
    const {ownerVacancyRate} = this.props;
    if(ownerVacancyRate) {
      let vacancyRate = [];
      Object.entries(ownerVacancyRate).map((item) => {
        const set = {
          "label": item[0],
          "value": item[1].toFixed(2)
        };
        return vacancyRate.push(set);
      });
      return vacancyRate.reverse();
    }
    return null;
  };
  render() {
    if(this.getVacancyRate().length) {
      return(
        <BarChart desc={<FormattedMessage id="OWNERS.PHRASE.VACANCY_RATE_CHART_MESSAGE"/>} dataChart={this.getVacancyRate()} height={this.props.height}/>
      );
    }
    return <NoData/>;
  }
}
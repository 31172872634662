import React from "react";
import TableCell from "@material-ui/core/TableCell/TableCell";
import {Typography} from "@material-ui/core";
import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import IconColorMUI from "app/partials/components/IconColorMUI";
import EditModal from "../../EditModal";
import IsAdmin from "../../../../utils/IsAdmin";

class TableTeamCells extends React.Component {
  // RENDER Table Cell Values
  renderCustomCell = (value) => {
    return (
      <Typography variant="h4">{value}</Typography>
    );
  };
  renderTrueFalse = (state) => {
    const icon = state ? 'check' : 'close';
    const color = state ? 'success' : 'danger';
    return(
      <IconColorMUI icon={icon} color={color}/>
    );
  };

  render() {
    const {row, isTeamOwner} = this.props;
    const {firstName, lastName} = row.user;
    const name = firstName !== null && lastName !== null ? `${firstName} ${lastName}` : (firstName !== null ? firstName : lastName !== null ? lastName : '');
    const isAdmin = IsAdmin();

    return (
      <>
        <TableCell component="th" scope="row">
          {this.renderCustomCell(name)}
        </TableCell>
        <TableCell>{this.renderCustomCell(row.user.email)}</TableCell>
        <TableCell>{this.renderCustomCell(row.user.phoneNumber)}</TableCell>
        <TableCell align="right">{this.renderTrueFalse(row.isOwner)}</TableCell>
        <TableCell align="right">{this.renderTrueFalse(row.isActiveSubscriber)}</TableCell>
        {(isTeamOwner || isAdmin) &&
        <TableCell align="right">
          <EditModal
            target="teamMember"
            initialTab={isAdmin ? "basic" : "teamOwner"}
            data={row}
            isPropertyEditable={(isTeamOwner || isAdmin)}
            tooltipPlacement="left"
            {...this.props}
          />
        </TableCell>
        }
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isTeamOwner: state.user.data.isTeamOwner
});
export default injectIntl(
  connect(
    mapStateToProps
  )(TableTeamCells)
);

import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {
  fetchMarketplaceLead,
  fetchMarketplaceLeads,
  fetchMarketplaceLeadsCsv,
  sendMarketplaceLeadUnlockNotification,
  sendMarketplaceLeadUnlockVisitorsNotification
} from "app/crud/finneLeads.crud";
import LeadsTenantIntroductionsPage from "./LeadsTenantIntroductionsPage";
import {callReddClickEvent} from "app/crud/user.crud";

const mapStateToProps = (store) => ({
  team: store.user.data.team,
  items: store.finneLeads.items,
  itemsCount: store.finneLeads.itemsCount,
  itemsPerPage: store.finneLeads.itemsPerPage,
  hasPaid: store.finneLeads.hasPaid,
  pendingLeadsCount: store.finneLeads.pendingLeadsCount,
  loading: store.finneLeads.loading,
  lead: store.finneLeads.lead,
  leadLoading: store.finneLeads.leadLoading,
  leadsCsvLoading: store.finneLeads.leadsCsvLoading,
  currentApplicationView: store.user.data.currentApplicationView,
  user: store.user.data
});
const mapDispatchToProps = {
  fetchMarketplaceLeads: (target, {params}) => fetchMarketplaceLeads(target, params),
  fetchMarketplaceLead: (leadID) => fetchMarketplaceLead(leadID),
  fetchMarketplaceLeadsCsv: (target, team) => fetchMarketplaceLeadsCsv(target, team),
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params),
  sendMarketplaceLeadUnlockNotification: (leadID) => sendMarketplaceLeadUnlockNotification(leadID),
  sendMarketplaceLeadUnlockVisitorsNotification: sendMarketplaceLeadUnlockVisitorsNotification
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LeadsTenantIntroductionsPage)
);
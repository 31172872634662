import React from "react";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import LoadingScreen from "../../../LoadingScreen";
import NoData from "../../../NoData/NoData";
import EditTeamMemberBasics from "./EditTeamMemberBasics";
import EditTeamMemberAsOwner from "./EditTeamMemberAsOwner";
import EditTeamMemberActiveSubscription from "./EditTeamMemberActiveSubscription";
import EditTeamMemberRemove from "./EditTeamMemberRemove";

function EditTeamMember({data, loading, activeTab, requestSave, onRequestSaving, onUnsavedData, ...props}) {
  if(loading) {
    return <LoadingScreen/>
  }
  else if(data) {
    return(
      <>
        {activeTab === "basic" &&
        <EditTeamMemberBasics
          data={data}
          requestSave={requestSave}
          onRequestSaving={onRequestSaving}
          onUnsavedData={onUnsavedData}
        />
        }
        {activeTab === "teamOwner" &&
        <EditTeamMemberAsOwner data={data}/>
        }
        {activeTab === "subscription" &&
        <EditTeamMemberActiveSubscription data={data} {...props}/>
        }
        {activeTab === "removeMember" &&
        <EditTeamMemberRemove data={data}/>
        }
      </>
    );
  }
  return <NoData/>;
}

export default withRouter(
  connect()(EditTeamMember)
);

import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {put, takeLatest} from "redux-saga/effects";
import axios from "axios";
import {actionNotification} from "app/utils/notifications";
import {userAddWatchListItem, userRemoveWatchListItem} from "app/crud/user.crud";

const initialState = {
  items: [],
  loading: false
};

export const reducer = persistReducer(
  { storage, key: "subscribers" },
  (state = initialState, action) => {
    switch (action.type) {
      case 'FETCH_FOLLOWER_OFFICES_REQUEST': {
        return { ...state, loading: true };
      }
      case 'FETCH_FOLLOWER_OFFICES_SUCCESS': {
        return { ...state, items: action.payload, loading: false }
      }
      case 'FETCH_FOLLOWER_OFFICES_FAILED': {
        return { ...state, items: [], loading: false }
      }
      case 'FOLLOW_OFFICE_REQUEST': {
        return { ...state };
      }
      case 'FOLLOW_OFFICE_SUCCESS': {
        return { ...state, ...action.payload }
      }
      case 'UNFOLLOW_OFFICE_REQUEST': {
        return { ...state };
      }
      case 'UNFOLLOW_OFFICE_SUCCESS': {
        return { ...state, items: state.items ? [...state.items.filter(item => item.id !== action.payload)] : null }
      }
      case 'UNFOLLOW_OFFICES_BULK_REQUEST': {
        return { ...state };
      }
      case 'UNFOLLOW_OFFICES_BULK_SUCCESS': {
        return { ...state, items: [], loading: false }
      }

      default:
        return state;
    }
  }
);

// FETCH DATA
function* fetchFollowerOffices() {
  try {
    const followedOffices = yield axios.get(`/office-followers/`);
    yield put({ type: "FETCH_FOLLOWER_OFFICES_SUCCESS", payload: followedOffices.data || [{ error: followedOffices.statusText }] });
  }
  catch(err) {
    console.log(err);
    yield put({
      type: "FETCH_FOLLOWER_OFFICES_FAILED",
      payload: err.status
    });
  }
}

// USER ACTIONS
function* followOffice(action) {
  try {
    const {officeId, data} = action.payload;
    const followOffice = yield axios.post(`/office-followers/`, data);
    yield put({
      type: "FOLLOW_OFFICE_SUCCESS",
      payload: followOffice.data,
      meta: actionNotification('Selected office has been added to your Watchlist.', 'success')
    });
    if(officeId) {
      yield put(userAddWatchListItem(officeId));
    }
  }
  catch(err) {
    yield put({
      type: "FOLLOW_OFFICE_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* unfollowOffice(action) {
  try {
    const officeId = action.payload;
    yield axios.delete(`/office-followers/${officeId}/`);
    yield put({
      type: "UNFOLLOW_OFFICE_SUCCESS",
      payload: officeId,
      meta: actionNotification('Selected office has been removed from your Watchlist.', 'success')
    });
    yield put(userRemoveWatchListItem(officeId));
  }
  catch(err) {
    yield put({
      type: "UNFOLLOW_OFFICE_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* unfollowOffices(action) {
  try {
    const {data} = action.payload;
    const unfollowOffices = yield axios.delete(`/office-followers/unfollow-bulk/`, data);
    yield put({
      type: "UNFOLLOW_OFFICES_BULK_SUCCESS",
      payload: unfollowOffices.data,
      meta: actionNotification('All items from your Watchlist has been removed.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "UNFOLLOW_OFFICES_BULK_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}

export function* saga() {
  yield takeLatest('FETCH_FOLLOWER_OFFICES_REQUEST', fetchFollowerOffices);
  yield takeLatest('FOLLOW_OFFICE_REQUEST', followOffice);
  yield takeLatest('UNFOLLOW_OFFICE_REQUEST', unfollowOffice);
  yield takeLatest('UNFOLLOW_OFFICES_BULK_REQUEST', unfollowOffices);
}

import React from "react";
import {Portlet, PortletBody} from "../../../partials/content/Portlet";
import {Grid} from "@material-ui/core";
import LoadingScreen from "../../../partials/components/LoadingScreen";
import OfferHeader from "./components/OfferHeader";
import OfferUnits from "./components/OfferUnits";
import OfferDescription from "./components/OfferDescription";
import OfferOptions from "./components/OfferOptions";
import {ROUTES} from "../../../constants";
import OfferSettings from "./components/OfferSettings";
import ReactVisibilitySensor from "react-visibility-sensor";

export default class OfferPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initLoaded: false,
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {deletingOffer, offerDeleted, history} = this.props;

    if(prevProps.deletingOffer && !deletingOffer && offerDeleted) {
      return history.push(`${ROUTES.SHORTLISTS_PAGE}/`);
    }
  }
  // Handlers
  handleOnViewportFetch = (isVisible) => {
    const {loading, fetchOfferOffices, match} = this.props;
    const {initLoaded} = this.state;
    const offerID = match?.params?.id;

    if(isVisible && !loading && !initLoaded && fetchOfferOffices && offerID) {
      this.setState({
        initLoaded: true
      }, () => fetchOfferOffices(offerID));
    }
  };

  render() {
    if(this.props.resetting) {
      return <LoadingScreen/>;
    }
    const {match, deletingOffer, loading} = this.props;
    const offerID = match?.params?.id;
    const isLoading = loading || deletingOffer;

    return(
      <ReactVisibilitySensor
        onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
        scrollCheck
        partialVisibility
      >
        <Grid container>
          <Grid item xs={8}>
            <Portlet
              shadowDisabled
            >
              <PortletBody>
                {isLoading ? <LoadingScreen/> :
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <OfferHeader {...this.props}/>
                    <OfferDescription {...this.props}/>
                  </Grid>
                  <Grid item xs={12}>
                    <OfferUnits
                      units={this.props?.data?.records}
                      {...this.props}
                    />
                  </Grid>
                </Grid>
                }
              </PortletBody>
            </Portlet>
          </Grid>
          <Grid item xs={4}>
            <Portlet
              fluidHeight
              shadowDisabled
              style={{backgroundColor: "rgba(250, 250, 250, 0.8)"}}
            >
              <PortletBody>
                {isLoading ? <LoadingScreen/> :
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  justifyContent="space-between"
                  style={{height: "100%"}}
                >
                  <Grid item>
                    <OfferOptions
                      offerID={offerID}
                      offerHash={this.props.data?.urlHash}
                      offerName={this.props.data?.name}
                      {...this.props}
                    />
                    <OfferSettings {...this.props}/>
                  </Grid>
                  <Grid item>
                    <OfferOptions
                      offerID={offerID}
                      offerHash={this.props.data?.urlHash}
                      offerName={this.props.data?.name}
                      {...this.props}
                    />
                  </Grid>
                </Grid>
                }
              </PortletBody>
            </Portlet>
          </Grid>
        </Grid>
      </ReactVisibilitySensor>
    );
  }
}
import React from "react";
import {FormattedMessage} from "react-intl";
import TableCustom from "app/partials/components/TableCustom";
import {handleEmptyValue, toNumberFormat} from "../../../../../../utils/helpers";
import styles from "app/pages/common/external/components/index.module.scss";
import {Typography} from "@material-ui/core";
import WarehouseAvailabilityPreview
  from "../../../../../../partials/components/_DataParts/WarehouseAvailabilityPreview";
import PropertyStatusPreview from "../../../../../../partials/components/_DataParts/PropertyStatusPreview";

export default class PropertyParticularsSingleWarehouses extends React.Component {
    render() {
        const {properties, branding} = this.props;
        const tHead = [
            {
                "label": <FormattedMessage id="GENERAL.PHRASE.TYPE"/>,
                "number": false
            },
            {
                "label": <FormattedMessage id="BUILDING.PHRASE.STATUS"/>,
                "number": false
            },
            {
                "label": <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.AREA_AVAILABILITY"/>,
                "number": false
            },
            {
                "label": <FormattedMessage id="GENERAL.PHRASE.AREA"/>,
                "number": true
            },
            {
                "label": <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.LOADING_DOCKS"/>,
                "number": true
            }
        ];
        const tBody = properties && properties.map(property => {
            const {name, status, docks, totalAreaManual, statusUnit} = property;
            return {
                "data": [
                    handleEmptyValue(name),
                    <PropertyStatusPreview status={status}/>,
                    <WarehouseAvailabilityPreview
                        availabilityDate={statusUnit?.date}
                        isAvailable={statusUnit?.id === 1}
                        isAvailableFrom={statusUnit?.id === 2}
                        isUnavailable={statusUnit?.id === 3}
                    />,
                    toNumberFormat(totalAreaManual, 'area'),
                    toNumberFormat(docks)
                ]
            }
        });
        if (properties.length) {
            return (
                <div ref={this.props.reference} className={styles.boxContainer}>
                    <h2 className={styles.withSemiTitle}><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.TECHNICAL_DETAILS"/></h2>
                    <Typography variant="body1"><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.TECHNICAL_DETAILS_DESCRIPTION"/></Typography>
                    <TableCustom
                        tHead={tHead}
                        tBody={tBody}
                        tHeadBgColor={branding.brandBgColor}
                        tHeadBgFontColor={branding.brandBgFontColor}
                        thStyle={{fontSize: "1.4rem"}}
                        tdStyle={{fontSize: "1.4rem"}}
                        responsive
                    />
                </div>
            );
        }
        return false;
    }
}
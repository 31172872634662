import React from "react";
import styles from "./index.module.scss";

export default class BadgeTitle extends React.Component {
  render() {
    const {badge, children} = this.props;
    return(
      <span>
        <span className={styles.badge}>{badge}</span>
        {children}
      </span>
    );
  }
}
import React from "react";
import store from "../store/store";
import PayWall from "../partials/components/PayWall";

const IsPro = ({paywall = false, paywallComponent, additionalCondition = undefined, overwriteRule, reverseCheck,  ...props}) => {
  const storeData = store.getState();
  const user = storeData?.user?.data;
  const isUserPro = user?.proData?.isPro;
  const isPro = additionalCondition !== undefined ? (additionalCondition && isUserPro) : isUserPro;
  const isProFinal = isPro || overwriteRule || false;

  if(props?.children) {
    if(reverseCheck ? !isProFinal : isProFinal) {
      return props?.children;
    }
    else if(paywall) {
      return paywallComponent || <PayWall/>;
    }
    return null;
  }
  return reverseCheck ? !isProFinal : isProFinal;
}

export default IsPro;
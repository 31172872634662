import React from "react";
import {FormattedMessage} from "react-intl";
import {UNIT_TYPES} from "app/constants";
import BetaLabel from "app/partials/components/BetaLabel";
import TooltipCustom from "app/partials/components/TooltipCustom";
import styles from "./index.module.scss";
import Icon from "app/partials/components/Icon";

export default class UnitTypePreview extends React.Component {
  render() {
    const {unitType, isSublease, industrialType, simple, simpleWithIcon} = this.props;
    return (
      <span className={styles.unitTypeWrapper}>
        {isSublease &&
          <BetaLabel
            top={simpleWithIcon ? "0" : "-17px"}
            right={simpleWithIcon ? "0" : "0"}
            style={simpleWithIcon ? {transform: "translate(10%, -100%)"} : undefined}
          >
            <FormattedMessage id="UNITS.PHRASE.SUBLEASE"/>
          </BetaLabel>
        }
        {
          industrialType ? (
          industrialType === "office" ? (
            simple ? <FormattedMessage id="GENERAL.PHRASE.OFFICE"/> :
              simpleWithIcon ? <span className={styles.unitTypeSimpleWithIconWrapper}><Icon icon={"building"} size={"2rem"}/> <FormattedMessage id="GENERAL.PHRASE.OFFICE"/></span> :
                <TooltipCustom title={<FormattedMessage id="GENERAL.PHRASE.OFFICE"/>} defaultCursor><Icon icon={"building"} size={"2rem"}/></TooltipCustom>
          ) :
            (
              simple ? <FormattedMessage id="GENERAL.PHRASE.WAREHOUSE"/> :
                simpleWithIcon ? <span className={styles.unitTypeSimpleWithIconWrapper}><Icon icon={"warehouse"} size={"2rem"}/> <FormattedMessage id="GENERAL.PHRASE.WAREHOUSE"/></span> :
                  <TooltipCustom title={<FormattedMessage id="GENERAL.PHRASE.WAREHOUSE"/>} defaultCursor><Icon icon={"warehouse"} size={"2rem"}/></TooltipCustom>
            )
          ) : (
          unitType === UNIT_TYPES.RETAIL ? (
            simple ? <FormattedMessage id="GENERAL.PHRASE.RETAIL"/> :
              simpleWithIcon ? <span className={styles.unitTypeSimpleWithIconWrapper}><Icon icon={"retail"} size={"2rem"}/> <FormattedMessage id="GENERAL.PHRASE.RETAIL"/></span> :
                <TooltipCustom title={<FormattedMessage id="GENERAL.PHRASE.RETAIL"/>} defaultCursor><Icon icon={"retail"} size={"2rem"}/></TooltipCustom>
          ) :
          unitType === UNIT_TYPES.OFFICE_RETAIL ? (
            simple ? <FormattedMessage id="GENERAL.PHRASE.OFFICE_RETAIL"/> :
              simpleWithIcon ? <span className={styles.unitTypeSimpleWithIconWrapper}><Icon icon={"buildings"} size={"2rem"}/> <FormattedMessage id="GENERAL.PHRASE.OFFICE_RETAIL"/></span> :
                <TooltipCustom title={<FormattedMessage id="GENERAL.PHRASE.OFFICE_RETAIL"/>} defaultCursor><Icon icon={"buildings"} size={"2rem"}/></TooltipCustom>
          ) :
            (
              simple ? <FormattedMessage id="GENERAL.PHRASE.OFFICE"/> :
                simpleWithIcon ? <span className={styles.unitTypeSimpleWithIconWrapper}><Icon icon={"building"} size={"2rem"}/> <FormattedMessage id="GENERAL.PHRASE.OFFICE"/></span> :
                  <TooltipCustom title={<FormattedMessage id="GENERAL.PHRASE.OFFICE"/>} defaultCursor><Icon icon={"building"} size={"2rem"}/></TooltipCustom>
            )
          )
        }
      </span>
    );
  }
}
const amenities = [
  {
    id: "greenPatio",
    icon: "🌿"
  },
  {
    id: "powerGenerator",
    icon: "🪫"
  },
  {
    id: "terrace",
    icon: "🌤️"
  },
  {
    id: "airConditioning",
    icon: "🌀"
  },
  {
    id: "raisedFloor",
    icon: "🏗️"
  },
  {
    id: "suspendedCeiling",
    icon: "📐"
  },
  {
    id: "powerCabling",
    icon: "🔌"
  },
  {
    id: "telephoneCabling",
    icon: "📞"
  },
  {
    id: "computerCabling",
    icon: "💻"
  },
  {
    id: "smokeDetectors",
    icon: "🚨"
  },
  {
    id: "carpeting",
    icon: "🧹"
  },
  {
    id: "reception",
    icon: "💼"
  },
  {
    id: "security",
    icon: "👮‍♂️"
  },
  {
    id: "fiberOptics",
    icon: "📡"
  },
  {
    id: "accessControl",
    icon: "🔒"
  },
  {
    id: "tiltWindows",
    icon: "🪟"
  },
  {
    id: "ledLighting",
    icon: "💡"
  },
  {
    id: "BMS",
    icon: "🏢"
  },
  {
    id: "wallPartitioning",
    icon: "🔨"
  },
  {
    id: "sprinklers",
    icon: "💧"
  },
  {
    id: "smoke_flap",
    icon: ""
  },
  {
    id: "air_conditioning",
    icon: "🌀"
  },
  {
    id: "access_ramp"
  },
  {
    id: "fire_resistance_4000_plus"
  },
  {
    id: "electric_car_charges",
    icon: "🔋"
  },
  {
    id: "lockersAndShowersForCyclists",
    icon: "🚿"
  },
  {
    id: "petFriendlyBuilding",
    icon: "🐾"
  },
  {
    id: "wifiInTheHall",
    icon: "📶"
  },
  {
    id: "freeBikeRentalForTenants",
    icon: "🚲"
  },
  {
    id: "bicycleRoom",
    icon: "🚲"
  },
  {
    id: "parkingForGuests",
    icon: "🅿️"
  },
  {
    id: "gym",
    icon: "💪"
  },
  {
    id: "bistroOrCanteen",
    icon: "🍽️"
  },
  {
    id: "electricCarCharges",
    icon: "🔋"
  },
  {
    id: "monitoringSystem",
    icon: "🎥"
  },
  {
    id: "bicycleStands",
    icon: "🚲"
  },
  {
    id: "emergencyPowerSupply",
    icon: "⚡️"
  },
  {
    id: "bms",
    icon: "🏢"
  },
  {
    id: "fireResistance4000Plus"
  },
  {
    id: "fireResistance_4000Plus"
  },
  {
    id: "smokeFlap",
    icon: ""
  },
  {
    id: "monitoring",
    icon: "🎥"
  },
  {
    id: "led",
    icon: "💡"
  },
  {
    id: "accessRamp",
  },
  {
    id: "cross_dock",
  },
  {
    id: "crossDock",
  },
  {
    id: "gantry",
  },
  {
    id: "railwaySiding",
  },
  {
    id: "cooler",
  },
  {
    id: "nonDustingFloor",
  },
  {
    id: "ventilation",
  },
  {
    id: "alarm",
  },
  {
    id: "gasHeating",
  },
  {
    id: "relaxationArea",
  },
  {
    id: "photovoltaics",
  },
  {
    id: "highStorageUnits",
  },
  {
    id: "conferenceRooms",
  },
  {
    id: "temperatureMonitoringSystems",
  },
  {
    id: "daylighting",
  },
  {
    id: "onSiteTechnicalAssistance",
  },
  {
    id: "automatedEntryExitSystem",
  }
];

export default amenities;
import React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import SelectSearch from "../SelectSearch";
import connect from "react-redux/es/connect/connect";
import {fetchOfficesMinified} from "app/crud/estates/offices/offices.crud";
import {fetchIndustrialParksMinified} from "app/crud/estates/industrial/industrial.crud";
import APPLICATION_VIEWS from "../../../constants/APPLICATION_VIEWS";
import {createVisibleArray, sortArrayAlph} from "../../../utils/helpers";
import styles from "./index.module.scss";
import _ from "lodash";
import {ROUTES} from "../../../constants";
import LoadingScreen from "../LoadingScreen";
import IconColorMUI from "../IconColorMUI";
import {callReddClickEvent} from "app/crud/user.crud";
import REDD_TOWER_DUMMY_DATA from "../../../pages/offices/external/PropertyPageDemo/REDD_TOWER_DUMMY_DATA";

class SearchProperties extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      propertyName: '',
      typingValue: ''
    }
  }
  componentDidMount() {
    const {
      loading,
      fetchOfficesMinified,
      parksLoading,
      fetchIndustrialParksMinified,
      currentApplicationView
    } = this.props;
    if(currentApplicationView !== undefined) {
      const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
      const isIndustrialView = currentApplicationView === APPLICATION_VIEWS.INDUSTRIAL;

      if (isOfficeView && !loading && fetchOfficesMinified) {
        fetchOfficesMinified();
      } else if (isIndustrialView && !parksLoading && fetchIndustrialParksMinified) {
        fetchIndustrialParksMinified();
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      loading,
      fetchOfficesMinified,
      parksLoading,
      fetchIndustrialParksMinified,
      currentApplicationView
    } = this.props;

    if(currentApplicationView !== undefined && prevProps?.currentApplicationView !== currentApplicationView) {
      const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
      const isIndustrialView = currentApplicationView === APPLICATION_VIEWS.INDUSTRIAL;

      if(isOfficeView && !loading && fetchOfficesMinified) {
        fetchOfficesMinified();
      }
      else if(isIndustrialView && !parksLoading && fetchIndustrialParksMinified) {
        fetchIndustrialParksMinified();
      }
    }
  }

  // Handlers
  handleAutocompleteChange = (target) => {
    const {currentApplicationView, callReddClickEvent} = this.props;
    const {typingValue} = this.state;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;

    const propertyID = target?.value?.[0];
    const basePropertyUrl = isOfficeView ? ROUTES.BUILDING_PAGE : ROUTES.INDUSTRIAL_PARK;
    const protocol = window?.location?.protocol;
    const host = window?.location?.host;
    const baseUrl = `${protocol}//${host}`;

    if(propertyID) {
      const redirectUrl = `${baseUrl}${basePropertyUrl}/${propertyID}`;

      if(propertyID === REDD_TOWER_DUMMY_DATA?.basic?.id) {
        const reddTowerUrl = `${baseUrl}${ROUTES.REDD_TOWER_DEMO}`;
        // redd-click
        callReddClickEvent(
          "topbar-offices-search-property-option-click-redirect",
          "office",
          null,
          null,
          {searchBy: typingValue}
        );
        // redirect
        window.open(reddTowerUrl, "_blank");
      }
      else {
        // click event
        if(isOfficeView) {
          callReddClickEvent(
            "topbar-offices-search-property-option-click-redirect",
            "office",
            null,
            propertyID,
            {searchBy: typingValue}
          );
        }
        else {
          callReddClickEvent(
            "topbar-industrial-search-property-option-click-redirect",
            "park",
            null,
            propertyID,
            {searchBy: typingValue}
          );
        }
        // redirect
        window.open(redirectUrl, "_blank");
      }
    }
  };
  handleInputChange = (value) => {
    this.setState({
      typingValue: value || '',
    });
  };
  handleTrackingOnInputFocus = () => {
    const {currentApplicationView, callReddClickEvent} = this.props;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;


    if(isOfficeView) {
      callReddClickEvent(
        "topbar-offices-search-property-input-click",
        "user"
      );
    }
    else {
      callReddClickEvent(
        "topbar-industrial-search-property-input-click",
        "user"
      );
    }
  }

  render() {
    const {
      currentApplicationView,
      offices,
      loading,
      parks,
      parksLoading
    } = this.props;
    const {typingValue} = this.state;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    const isIndustrialView = currentApplicationView === APPLICATION_VIEWS.INDUSTRIAL;
    const extendedOfficesList = [
      ...offices?.length > 0 ? offices : [],
      {
        id: REDD_TOWER_DUMMY_DATA?.basic?.id,
        name: "REDD Tower (Demo)"
      }
    ];
    const sortedProperties = isOfficeView ? (
      offices?.length > 0 ? sortArrayAlph(createVisibleArray(extendedOfficesList, "name")) : []
    ) : (
      parks?.length > 0 ? sortArrayAlph(createVisibleArray(parks, "name")) : []
    );

    if(loading || parksLoading) {
      return <LoadingScreen/>;
    }
    else if((isOfficeView && !_.isEmpty(offices) && !loading) || (isIndustrialView && !_.isEmpty(parks) && !parksLoading)) {
      return(
        <div className={styles.searchWrapper}>
          <span className={styles.iconSearch}>
            <IconColorMUI
              icon="search"
            />
          </span>
          <SelectSearch
            width="25rem"
            name="propertyName"
            inputValue={typingValue}
            inputVariant="outlined"
            label={<FormattedMessage id="GENERAL.PHRASE.FIND_PROPERTY"/>}
            options={sortedProperties}
            optionsFull={isOfficeView ? extendedOfficesList : parks}
            optionFullLabel="name"
            optionFullValue="id"
            loading={loading}
            disabled={loading}
            minLength={3}
            marginOff
            searchMode
            onChange={this.handleAutocompleteChange}
            onInputChange={this.handleInputChange}
            onInputFocus={this.handleTrackingOnInputFocus}
          />
        </div>
      );
    }
    return <span/>;
  }
}

const mapStateToProps = store => ({
  currentApplicationView: store?.user?.data?.currentApplicationView,
  offices: store?.offices?.officesMinified,
  loading: store?.offices?.officesMinifiedLoading,
  parks: store.industrial?.parksMinified,
  parksLoading: store.industrial?.parksMinifiedLoading,
  user: store?.use?.data
});
const mapDispatchToProps = {
  fetchOfficesMinified: (params) => fetchOfficesMinified(params),
  fetchIndustrialParksMinified: (params) => fetchIndustrialParksMinified(params),
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params)
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SearchProperties));
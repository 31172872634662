export default {
  WAREHOUSE_PLAN: {
    id: 0,
    name: 'GENERAL.PHRASE.FILE_CATEGORY.WAREHOUSE_PLAN'
  },
  PARK_PLAN: {
    id: 1,
    name: 'GENERAL.PHRASE.FILE_CATEGORY.PARK_PLAN'
  },
  LAND_DEVELOPMENT_PLAN:  {
    id: 2,
    name: 'GENERAL.PHRASE.FILE_CATEGORY.LAND_DEVELOPMENT_PLAN'
  },
  BROCHURE:  {
    id: 3,
    name: 'GENERAL.PHRASE.FILE_CATEGORY.BROCHURE'
  },
  MAP:  {
    id: 4,
    name: 'GENERAL.PHRASE.FILE_CATEGORY.MAP'
  }
};
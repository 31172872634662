import React from "react";
import styles from "./index.module.scss";
import {toAbsoluteUrl} from "_metronic";
import {FormattedMessage} from "react-intl";
import {getParameterByName, handleEmptyValue} from "app/utils/helpers";

const isIndustrialOffer = (props) => props.location.search.includes("market-type=2");

export default class RealTimeMailPage extends React.Component {
  constructor(props) {
    super(props);
    const token = handleEmptyValue(getParameterByName("token"), true);
    const userThankingEmail = handleEmptyValue(
      getParameterByName("useremailtothank")
    );
    const officeName = handleEmptyValue(getParameterByName("officename"));
    const officePk = handleEmptyValue(getParameterByName("officeid"), true);
    const userPkToSend = handleEmptyValue(getParameterByName("userid"), true);
    const userPkThanking = handleEmptyValue(
      getParameterByName("usertothank"),
      true
    );
    const data = {
      userPkThanking,
      userPkToSend,
      officePk,
      officeName,
      userThankingEmail,
      token,
    };

    if (isIndustrialOffer(props)) {
      props.sendKudosEmailIndustrialConfirmation(data);
    } else {
      props.sendKudosEmailConfirmation(data);
    }
  }
  handleSignInLog = () => {
    const { createKudosEmailSignInLog, createKudosEmailIndustrialSignInLog} = this.props;
    const token = handleEmptyValue(getParameterByName("token"), true);
    const officePk = handleEmptyValue(getParameterByName("officeid"), true);
    const userThankingEmail = handleEmptyValue(
      getParameterByName("useremailtothank")
    );
    const userWhereSent = handleEmptyValue(getParameterByName("userwheresent"));
    const data = {
      token,
      officePk,
      userThankingEmail,
      userWhereSent,
    };
    if(isIndustrialOffer(this.props)){
      createKudosEmailIndustrialSignInLog(data)
    } else {
      createKudosEmailSignInLog(data);
    }
  };
  render() {
    const { intl } = this.props;
    const agentName = handleEmptyValue(getParameterByName("username"));
    const agentEmail = handleEmptyValue(getParameterByName("usermail"));
    const agentPhone = handleEmptyValue(getParameterByName("userphonenumber"));
    const agentCompany = handleEmptyValue(getParameterByName("userteam"));
    return (
      <div className={styles.realTimeMailContainer}>
        <div className={styles.realTimeMailWrapper}>
          <div className={styles.rtmHeader}>
            <img
              src={toAbsoluteUrl("media/branding/redd-logo-neon-200px.png")}
              alt="REDD"
            />
          </div>
          <div className={styles.rtmContent}>
            <img
              src={toAbsoluteUrl("media/branding/icons/heart.png")}
              alt="REDD"
            />
            <p className={styles.contentTitle}>
              <FormattedMessage
                id="MAIL.PHRASE.KUDOS_RECEIVED"
                values={{
                  user: <span className={styles.brand}>{agentName}</span>,
                  br: <br />,
                }}
              />{" "}
              <span role="img" aria-label="Company name">
                🎉
              </span>
            </p>
            <p className={styles.contentText}>
              <FormattedMessage
                id="MAIL.PHRASE.KUDOS_MESSAGE_1"
                values={{
                  strong: (
                    <strong>
                      {intl.formatMessage({
                        id: "MAIL.PHRASE.KUDOS_MESSAGE_OFFER",
                      })}
                    </strong>
                  ),
                }}
              />
              <br />
              <FormattedMessage
                id="MAIL.PHRASE.KUDOS_MESSAGE_2"
                values={{
                  user: <span className={styles.brand}>{agentName}</span>,
                }}
              />
            </p>
            <div className={styles.contactWrapper}>
              <p>
                <img
                  src={toAbsoluteUrl("media/branding/icons/pin.png")}
                  alt="REDD"
                />
                {agentCompany}
              </p>
              <p>
                <img
                  src={toAbsoluteUrl("media/branding/icons/phone.png")}
                  alt="REDD"
                />
                {agentPhone}
              </p>
              <p>
                <img
                  src={toAbsoluteUrl("media/branding/icons/mail.png")}
                  alt="REDD"
                />
                {agentEmail}
              </p>
              <p className={styles.contactSeparator}>
                <FormattedMessage id="MAIL.PHRASE.KUDOS_OR" />
              </p>
              <span
                className={styles.btnLogin}
                onClick={() => this.handleSignInLog()}
              >
                <FormattedMessage id="MAIL.PHRASE.KUDOS_SIGN_IN" />
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import React, {useEffect, useState, useRef} from 'react';
import styles from "../index.module.scss";
import LoadingScreen from "../../../../partials/components/LoadingScreen";
import InputWithActions from "../../../../partials/components/InputWithActions";
import EditButton from "../../../../partials/components/EditModal/components/EditButton";
import {FormattedMessage} from "react-intl";

export default function OfferHeader(props) {
  const {
    match,
    data,
    offerUpdating,
    updateOffer,
    intl
  } = props;
  const offerID = match?.params?.id;
  const offerNameValue = data?.name || "";
  const [editModeEnable, setEditModeEnable] = useState(false);
  const [offerName, setOfferName] = useState(offerNameValue);
  const prevOfferUpdating = useRef(false);

  //
  useEffect(() => {
    setOfferName(offerNameValue);
  }, [offerNameValue]);
  // Close edit mode when change has been saved
  useEffect(() => {
    prevOfferUpdating.current = offerUpdating;
    if(prevOfferUpdating && !offerUpdating) {
      setEditModeEnable(false);
    }
  }, [offerUpdating]);
  const handleSaveChanges = () => {
    const data = {
      name: offerName
    };
    if(offerID && updateOffer) {
      updateOffer(offerID, data);
    }
  }
  const handleRejectChanges = () => {
    setOfferName(data?.name || "");
    setEditModeEnable(false);
  }

  return(
    <div className={styles.offerHeaderWrapper}>
      {data ?
        (
          editModeEnable ? (
              <InputWithActions
                name="offerName"
                label={<FormattedMessage id="VOCABULARY.Offer name"/>}
                value={offerName}
                btnConfirmLabel={<FormattedMessage id="VOCABULARY.Save"/>}
                btnRejectLabel={data?.name !== offerName ? intl.formatMessage({id: "VOCABULARY.Reject & Close"}) : intl.formatMessage({id: "VOCABULARY.Close"})}
                loading={offerUpdating}
                onChange={(e) => setOfferName(e.target.value)}
                onConfirm={() => handleSaveChanges()}
                onReject={() => handleRejectChanges()}
                enterSubmitEnabled
                limit={50}
              />
            ) :
            <h1>{data?.name}</h1>
        ) :
        <LoadingScreen/>
      }
      {data && !editModeEnable &&
        <EditButton
          iconStyle={{
            width: "1.4rem",
            height: "1.4rem"
          }}
          onClick={() => setEditModeEnable(!editModeEnable)}
        />
      }
    </div>
  );
}
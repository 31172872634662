import React from 'react';
import {Checkbox, FormControlLabel} from '@material-ui/core';

export default class InputCheckbox extends React.Component {
  handleChange = name => event => {
    this.props.onCheckboxChange(name, event.target.checked);
  };

  render() {
    const {value, label, name, disabled} = this.props;
    return(
      <FormControlLabel
        control={
          <Checkbox
            checked={value}
            onChange={this.handleChange(name)}
            value={value}
            color="primary"
          />
        }
        label={label}
        disabled={disabled}
      />
    );
  }
}
import React from "react";
import ModalCustom from "app/partials/components/ModalCustom";
import {FormattedMessage} from "react-intl";
import HighlightLabel from "app/partials/components/HighlightLabel";
import styles from "app/pages/offices/OwnerPage/index.module.scss";
import {Checkbox, Table, Typography} from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody/TableBody";

export default class OwnerMortgageBuildingsFilter extends React.Component {
  handleCloseModal = () => {
    this.child.current.handleClose();
  };

  handleSelectAllClick = event => {
    const {loansBuildings} = this.props;
    if (event.target.checked) {
      const newSelected = loansBuildings.map(n => n);
      this.setState({
        selected: newSelected
      });
      return;
    }
    this.setState({
      selected: []
    });
  };
  handleSelect = (event, name) => {
    const {selected} = this.state;
    const selectedItemIndex = selected.indexOf(name);

    let newSelectedItems = [];
    if (selectedItemIndex === -1) {
      newSelectedItems = newSelectedItems.concat(selected, name);
    } else if (selectedItemIndex === 0) {
      newSelectedItems = newSelectedItems.concat(selected.slice(1));
    } else if (selectedItemIndex === selected.length - 1) {
      newSelectedItems = newSelectedItems.concat(selected.slice(0, -1));
    } else if (selectedItemIndex > 0) {
      newSelectedItems = newSelectedItems.concat(
        selected.slice(0, selectedItemIndex),
        selected.slice(selectedItemIndex + 1),
      );
    }
    this.setState({
      selected: newSelectedItems
    });
  };
  handleFilterFinances = () => {
    const { filterLoans } = this.props;
    const { selected } = this.state;
    filterLoans(selected);
    this.handleCloseModal();
  };

  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      selected: []
    }
  }

  render() {
    const { selected } = this.state;
    const {loansBuildings} = this.props;
    const notEmpty = loansBuildings.length;

    const isSelected = name => selected.indexOf(name) !== -1;
    const numSelected = selected.length;
    const rowCount = loansBuildings.length;
    const selectedNumberDisplay = selected.length > 2 ? selected.length - 2 : 0;

    return(
      <ModalCustom
        ref={this.child}
        btn={
          <HighlightLabel className={styles.selectedBuildingsTriggerContainer} color={notEmpty ? "success" : "default"}>
            <span>
            {selected.length ? selected.map((item, index) => index <= 1 && <span key={index} className={styles.selectedBuildingsTriggerElement}>{item}</span>) : ''}
            </span>
            {selected.length === 0 ?
            <Typography><FormattedMessage id="OWNERS.PHRASE.FILTER_BY_BUILDINGS"/></Typography> : selectedNumberDisplay !== 0 &&
            <Typography className={styles.selectedBuildingsTrigger}><FormattedMessage id="OWNERS.PHRASE.AND_N_MORE" values={{more: selectedNumberDisplay}}/></Typography>
            }
          </HighlightLabel>
        }
        title={<FormattedMessage id="OWNERS.PHRASE.BUILDINGS_COVERED"/>}
        btnStyle={{cursor: "pointer"}}
        btnConfirm={<FormattedMessage id="GENERAL.PHRASE.SHOW"/>}
        handleSave={this.handleFilterFinances}
        inactive={!notEmpty}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={numSelected === rowCount}
                  onChange={this.handleSelectAllClick}
                  inputProps={{ 'aria-label': 'Select all desserts' }}
                  color="primary"
                />
              </TableCell>
              <TableCell><FormattedMessage id="BUILDING.PHRASE.BUILDING_NAME"/></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loansBuildings.map((building, index) => {
              const isItemSelected = isSelected(building);
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow
                    key={index}
                    onClick={event => this.handleSelect(event, building)}
                    className={styles.selectFinancesBuildingsTableRow}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      inputProps={{ 'aria-labelledby': labelId }}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell>{building}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </ModalCustom>
    );
  }
}
import React, {useEffect, useState} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import styles from "./index.module.scss";
import {toAbsoluteUrl} from "../../../../../../_metronic";
import {callReddClickEvent} from "../../../../../crud/user.crud";
import connect from "react-redux/es/connect/connect";
import LoadingScreen from "../../../../../partials/components/LoadingScreen";
import IsAdmin from "../../../../../utils/IsAdmin";
import LeadsManagementAddProperty from "../../../../common/LeadsManagementPage/components/LeadsManagementAddProperty";
import {BUILDING_STATUS} from "../../../../../constants";
import {Tooltip} from "@material-ui/core";
import IconColorMUI from "../../../../../partials/components/IconColorMUI";
import EditModal from "../../../../../partials/components/EditModal";
import SimpleMap from "../../../../../partials/components/SimpleMap";
import PropertyHeaderTitle from "../../../../common/components/PropertyPage/PropertyHeaderTitle";
import {returnOfficeBuildingStatus, toNumberFormat, toTimeFromDate} from "../../../../../utils/helpers";
import cn from "classnames";
import ModalCustom from "../../../../../partials/components/ModalCustom";
import SlickSlider from "../../../../../partials/components/SlickSlider";
import SliderCustom from "../../../../../partials/components/SliderCustom";
import SlickNavigation from "../../../../../partials/components/SlickSlider/components/SlickNavigation";
import {fetchIndustrialParkPhotos} from "../../../../../crud/estates/industrial/industrial.crud";
import IPI_PLAN_SOURCE from "../../../../../constants/IPI_PLAN_SOURCE";
import IndustrialPreviewIPI from "../../../components/IndustrialPreviewIPI";
import {FILTERS_SELECTS} from "../../../../../partials/components/FiltersDrawer/constants";
import Button from "../../../../../partials/components/Button";
import Icon from "../../../../../partials/components/Icon";


function PropertyHeaderPreview({...props}) {
  const {basic, basicLoading, photos, photosLoading, fetchIndustrialParkPhotos} = props;
  const [photosLoaded, setPhotosLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  // Fetch Data
  useEffect(() => {
    const propertyID = basic?.id;

    if(propertyID && !photosLoaded && !photosLoading) {
      setPhotosLoaded(true);
      fetchIndustrialParkPhotos(propertyID);
    }
  }, [basic, photosLoaded, photosLoading, fetchIndustrialParkPhotos]);

  // Handlers
  const handleValueLoading = (loading, value) => {
    if(loading) {
      return <LoadingScreen loaderStyle={{width: "1rem", height: "1rem"}}/>;
    }
    return value;
  }

  // Variables
  const loading = basicLoading;
  const propertyLocation = [
    ...basic?.addressCity ? [basic?.addressCity] : [],
    ...basic?.addressStreet ? [basic?.addressStreet] : []
  ].join(", ");
  const officeStatus = returnOfficeBuildingStatus(basic?.status);
  const isPropertyEditable = basic?.isEditable;
  const propertyImage = basic?.mainPhotoThumbnail400x400 ?
    basic?.mainPhotoThumbnail400x400 :
    photos?.length > 0 ? photos?.[0]?.image :
    toAbsoluteUrl("media/industrial-placeholder-outside.jpg");
  const galleryItems = [
    ...basic?.ipiPlanImage ? [{image: basic?.ipiPlanImage, ipiPlan: true}] : [],
    ...photos ? photos : []
  ];
  const parkTypes = basic?.types?.map(type => FILTERS_SELECTS?.INDUSTRIAL_WAREHOUSE_TYPE?.filter(item => item?.id === type)?.[0]?.label);
  const isEditableNoPlan = isPropertyEditable && !basic?.ipiPlanImage;

  return(
    <div className={cn(
      styles.propertyHeaderPreviewWrapper,
      props?.breakPoint ? styles.singleColumn : undefined
    )}>
      {loading ? <LoadingScreen/> :
      <>
      <div className={cn(
        styles.propertyHeaderGalleryWrapper,
        isEditableNoPlan ? styles.shadowExtended : undefined
      )}>
        <div className={styles.galleryWrapper}>
          {photosLoading ? <LoadingScreen/> :
          <>
          <>
            {galleryItems?.length >= 1 ?
              <ModalCustom
                btn={
                <SlickSlider
                  slidesToShow={1}
                  newSlideRequest={currentSlide}
                >
                  {galleryItems?.map((item, index) => {
                    if(item?.ipiPlan) {
                      return(
                        <React.Fragment key={index}>
                          <IndustrialPreviewIPI
                            title={basic?.name}
                            planSource={IPI_PLAN_SOURCE.PARK}
                            planPark={basic?.ipiPlanImage}
                            parkID={basic?.id}
                            isEditable={basic?.ipiEditable}
                            modalTrigger={
                              <div key={index} className={styles.propertyGalleryPreviewItemWrapper}>
                                <img src={item?.image} alt={`${item?.name}`}/>
                              </div>
                            }
                          />
                        </React.Fragment>
                      );
                    }
                    return(
                      <div key={index} className={styles.propertyGalleryPreviewItemWrapper}>
                        <img src={item?.image} alt={`${item?.name}`}/>
                      </div>
                    );
                  })}
                </SlickSlider>
                }
                title=""
                btnReject={<FormattedMessage id="GENERAL.PHRASE.CLOSE"/>}
                btnFlex
                fullScreen
                disabled={!galleryItems || (galleryItems?.length === 0) || (currentSlide === 0 && galleryItems?.[0]?.ipiPlan)}
              >
                <SliderCustom
                  images={photos?.map(item => item?.image)}
                  interval={4000}
                />
              </ModalCustom> :
              (
                isPropertyEditable ?
                  <div className={styles.noMediaEditableWrapper}>
                    <p><FormattedMessage id="VOCABULARY.No plan and photos"/></p>
                  </div> :
                  <img src={propertyImage} alt={`${basic?.name}`}/>
              )
            }
            {isEditableNoPlan &&
            <IndustrialPreviewIPI
              title={basic?.name}
              planSource={IPI_PLAN_SOURCE.PARK}
              planPark={basic?.ipiPlanImage}
              parkID={basic?.id}
              isEditable={basic?.ipiEditable}
              modalTrigger={
              <Button
                textIcon={<Icon icon={"plan"}/>}
                style={{width: "100%"}}
              >
                <FormattedMessage id="VOCABULARY.Add plan"/>
              </Button>
              }
              triggerBtnStyle={{
                display: "flex",
                justifyContent: "center",
                position: "absolute",
                bottom: "1.2rem",
                left: "0",
                zIndex: 2
              }}
            />
            }
            </>
          {galleryItems?.length > 1 &&
          <SlickNavigation
            slides={galleryItems}
            currentSlide={currentSlide}
            onSetCurrentSlide={setCurrentSlide}
            style={{
              position: "absolute",
              bottom: isEditableNoPlan ? "6rem" : "1.2rem",
              right: "1.2rem",
              zIndex: 2
            }}
          />
          }
          </>
          }
        </div>
        <div className={styles.optionsWrapper}>
          {basic?.isEditable &&
          <EditModal
            target="park"
            parentData={basic}
            isPropertyEditable={isPropertyEditable}
            tooltipPlacement="right"
          />
          }
          <IsAdmin markup="font">
            <LeadsManagementAddProperty propertyID={basic?.id} tooltipPosition={"right"}/>
          </IsAdmin>
        </div>
      </div>
      <div className={styles.propertyHeaderDataWrapper}>
        <PropertyHeaderTitle
          title={basic?.name}
          location={propertyLocation}
          titleActions={
          <>
            {basic?.status !== BUILDING_STATUS.STATUS_EXISTS && officeStatus &&
            <Tooltip
              title={<span style={{fontSize: "1.2rem"}}>{officeStatus}</span>}
              placement="top"
            >
              <span className={styles.propertyStatus}>
                <IconColorMUI icon="schedule" size="20px"/>
              </span>
            </Tooltip>
            }
          </>
          }
          locationActions={
          <>
            <span>•</span>
            <SimpleMap lat={basic?.geoLatitude} lng={basic?.geoLongitude} propertyID={basic?.id} />
          </>
          }
        />
        <table className={styles.propertyHeaderDataTable}>
          <tbody>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Type"/>:</td>
              <td>
                {handleValueLoading(
                  basicLoading,
                  parkTypes?.map((type, index) => {
                    const separator = index+1 < parkTypes?.length ? ', ' : '';
                    return <React.Fragment key={index}>{type}{separator}</React.Fragment>;
                  }
                  )
                )}
              </td>
            </tr>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Class"/>:</td>
              <td>{basic?.classes}</td>
            </tr>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Completion year"/>:</td>
              <td>{basic?.commissioningYear}</td>
            </tr>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Park area"/>:</td>
              <td>{toNumberFormat(basic?.totalAreaGross, 'area')}</td>
            </tr>
          </tbody>
        </table>
        <p className={styles.propertyLastUpdate}>
          <FormattedMessage id="VOCABULARY.Data update"/>{" "}
          <span className={styles.time}>{toTimeFromDate(basic?.updatedAtByUser)}</span></p>
      </div>
      </>
      }
    </div>
  );
}

const mapStateToProps = store => ({
  basic: store.industrialPark.basic,
  basicLoading: store.industrialPark.basicLoading,
  photos: store.industrial.parkPhotos,
  photosLoading: store.industrial.parkPhotosLoading,
  currentApplicationView: store.user.data.currentApplicationView
});
const mapDispatchToProps = {
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params),
  fetchIndustrialParkPhotos: (parkID) => fetchIndustrialParkPhotos(parkID),
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PropertyHeaderPreview)
);
import React from "react";
import {Grid, Typography} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import InputAdornments from "app/partials/components/InputAdornments";
import {onlyNumbers, sortArrayAlph} from "app/utils/helpers";
import {GoogleMaps} from "../../../GoogleMaps";
import _ from "lodash";
import LoadingScreen from "../../../LoadingScreen";
import SingleSelect from "../../../SingleSelect";
import {fetchMarketsIndustrial, fetchVoivodeships} from "../../../../../crud/user.crud";
import {updateIndustrialPark} from "../../../../../crud/estates/industrial/industrial.crud";

class EditParkLocation extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      addressCity: props.data.addressCity || '',
      addressStreet: props.data.addressStreet || '',
      addressZipcode: props.data.addressZipcode || '',
      geoLatitude: props.data.geoLatitude,
      geoLongitude: props.data.geoLongitude,
      market: props.data.market || null,
      voivodeship: props.data.voivodeship || null
    }
    this.initialState = this.state;
  }
  componentDidMount() {
    const {fetchMarketsIndustrial, fetchVoivodeships} = this.props;
    fetchMarketsIndustrial && fetchMarketsIndustrial();
    fetchVoivodeships && fetchVoivodeships();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Handle Change Detect
    this.props.onUnsavedData(!_.isEqual(this.initialState, this.state));
    // Handle Save
    if(!prevProps.requestSave && this.props.requestSave) {
      this.handleSave();
    }
    // Detect Office Updating
    if(prevProps.parkUpdateLoading !== this.props.parkUpdateLoading) {
      this.props.onRequestSaving(this.props.parkUpdateLoading);
    }
  }

  // Handlers
  handleChange = (event) => {
    const onlyNums = onlyNumbers(event.target.value);
    const allowOnlyNumbers = event.target.name === "area" || event.target.name === "floor";
    this.setState({
      [event.target.name]: allowOnlyNumbers ? onlyNums : event.target.value
    });
  };
  handleMarkerPositionChange = (position) => {
    if (position) {
      const {latLng} = position;
      this.setState({
        geoLatitude: latLng.lat(),
        geoLongitude: latLng.lng()
      });
    }
  };
  handleSelectedMarket = selected => {
    this.setState({
      market: selected
    });
  };
  handleSelectedVoivodeship = selected => {
    this.setState({
      voivodeship: selected
    });
  };

  handleSave = () => {
    const {data: {id}, updateIndustrialPark} = this.props;
    const {addressCity, addressStreet, addressZipcode, geoLatitude, geoLongitude, market, voivodeship} = this.state;
    const data = {
      addressCity,
      addressStreet,
      addressZipcode,
      geoLatitude,
      geoLongitude,
      market,
      voivodeship
    };
    this.initialState = this.state;
    updateIndustrialPark(id, data, "location");
  };

  render() {
    const {
      voivodeships,
      voivodeshipLoading,
      markets,
      loading,
      parkUpdateLoading
    } = this.props;
    const {
      addressCity,
      addressStreet,
      addressZipcode,
      geoLatitude,
      geoLongitude,
      market,
      voivodeship
    } = this.state;
    const sortedMarkets = markets?.length > 0 ? sortArrayAlph(markets, "name") : [];
    let address = '';
    if (addressCity) address += addressCity;
    if (addressStreet) address += ` ${addressStreet}`;

    return(
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" style={{color: "#ff4626"}}><FormattedMessage id="VOCABULARY.Location"/></Typography>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              {loading ? <LoadingScreen/> :
                <SingleSelect
                  label={<FormattedMessage id="GENERAL.PHRASE.MARKET"/>}
                  inputName="new_park_market"
                  options={sortedMarkets}
                  optionsValue="id"
                  optionsLabel="name"
                  defaultSelect={market}
                  onSelectedValues={this.handleSelectedMarket}
                  disabled={parkUpdateLoading}
                />
              }
            </Grid>
            <Grid item>
              {voivodeshipLoading ? <LoadingScreen/> :
                <SingleSelect
                  label={<FormattedMessage id="GENERAL.PHRASE.VOIVODESHIP"/>}
                  inputName="new_park_voivodeship"
                  options={voivodeships}
                  optionsValue="id"
                  optionsLabel="name"
                  defaultSelect={voivodeship}
                  onSelectedValues={this.handleSelectedVoivodeship}
                  disabled={parkUpdateLoading}
                />
              }
            </Grid>
            <Grid item>
              <InputAdornments
                label={<FormattedMessage id="GENERAL.PHRASE.ZIPCODE"/>}
                name="addressZipcode"
                value={addressZipcode}
                onChange={this.handleChange}
                disabled={parkUpdateLoading}
              />
            </Grid>
            <Grid item>
              <InputAdornments
                label={<FormattedMessage id="GENERAL.PHRASE.CITY"/>}
                name="addressCity"
                value={addressCity}
                onChange={this.handleChange}
                disabled={parkUpdateLoading}
              />
            </Grid>
            <Grid item>
              <InputAdornments
                label={<FormattedMessage id="GENERAL.PHRASE.STREET"/>}
                name="addressStreet"
                value={addressStreet}
                onChange={this.handleChange}
                disabled={parkUpdateLoading}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <GoogleMaps
            address={address}
            markerDraggable={true}
            markerPosition={{
              lat: geoLatitude,
              lng: geoLongitude,
            }}
            lat={geoLatitude}
            lng={geoLongitude}
            height={500}
            draggable
            onDragEnd={this.handleMarkerPositionChange}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = store => ({
  parkUpdateLoading: store.industrial.parkUpdateLoading,
  voivodeships: store.user.voivodeships,
  voivodeshipLoading: store.user.voivodeshipLoading,
  markets: store?.user?.marketsIndustrial,
  loading: store?.user?.marketsIndustrialLoading
});
const mapDispatchToProps = {
  updateIndustrialPark: (parkID, data, backgroundRefresh) => updateIndustrialPark(parkID, data, backgroundRefresh),
  fetchVoivodeships: () => fetchVoivodeships(),
  fetchMarketsIndustrial: () => fetchMarketsIndustrial()
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditParkLocation)
);
import React from "react";
import {Grid} from "@material-ui/core";
import PropertyParticularsAnalysisCharts
  from "../../../partials/components/PropertyParticularAnalysis/PropertyParticularsAnalysisCharts";
import PropertyParticularsAnalysisPortfolio
  from "../../../partials/components/PropertyParticularAnalysis/PropertyParticularsAnalysisPortfolio";
import PropertyParticularsAnalysisCTA
  from "../../../partials/components/PropertyParticularAnalysis/PropertyParticularsAnalysisCTA";
import {ROUTES} from "../../../constants";
import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {callReddClickEvent} from "app/crud/user.crud";
import IsPro from "../../../utils/IsPro";

class PropertyParticularsAnalysisPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isReady: false
        }
    }
    componentDidMount() {
        if(!this.state.isReady) {
            this.setState({
                isReady: true
            }, () => {
                this.props.dispatch(callReddClickEvent(
                  "property-particulars-industrial-click",
                  "team"
                ));
            });
        }
    }

    render() {
        const {
            data,
            chartsData,
            loadingCharts,
            loading,
            user,
            intl,
            sharingUpdated,
            fetchPropertyParticularsAnalytics,
            fetchPropertyParticularsAnalyticsCharts,
            shareUpdatedPropertyParticulars
        } = this.props;
        const teamID = user && user.team && user.team.id;

        return(
          <IsPro paywall>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                    <PropertyParticularsAnalysisCTA
                        teamID={teamID}
                        ppUpdate={data?.ppUpdate}
                        sharingUpdated={sharingUpdated}
                        route={ROUTES.INDUSTRIAL_PROPERTY_PARTICULARS_ONLINE}
                        target="industrial"
                        onSendUpdated={shareUpdatedPropertyParticulars}
                    />
                </Grid>
                <Grid item xs={12}>
                    <PropertyParticularsAnalysisCharts
                        intl={intl}
                        data={chartsData}
                        loading={loadingCharts}
                        teamID={teamID}
                        onFetchData={fetchPropertyParticularsAnalyticsCharts}
                    />
                </Grid>
                <Grid item xs={12}>
                    <PropertyParticularsAnalysisPortfolio
                        data={data}
                        loading={loading}
                        teamID={teamID}
                        target="industrial"
                        onFetchData={fetchPropertyParticularsAnalytics}
                    />
                </Grid>
            </Grid>
          </IsPro>
        );
    }
}

export default injectIntl(connect()(PropertyParticularsAnalysisPage));
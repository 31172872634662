import React from "react";
import TableCell from "@material-ui/core/TableCell/TableCell";
import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import BuildingCardPreview from "app/partials/components/_DataParts/BuildingCardPreview";
import {toDateFormat, toNumberFormat} from "app/utils/helpers";
import styles from "../index.module.scss";
import HighlightLabel from "../../HighlightLabel";

class TableInterestedInMyBuildingsCells extends React.Component {
  render() {
    const {
      row
    } = this.props;

    return (
      <>
        <TableCell component="th" scope="row" className={styles.wrapCell}>
          <BuildingCardPreview
            name={row?.assetName}
            street={row?.assetAddressStreet}
            city={row?.assetAddressCity}
            thumb={row?.mainPhotoThumbnail}
            officeID={row?.assetId}
          />
        </TableCell>
        <TableCell align="right">
          <HighlightLabel color="neutral2">
            {toNumberFormat(row?.area, "area")}
          </HighlightLabel>
        </TableCell>
        <TableCell>
          {row?.companyName}
        </TableCell>
        <TableCell>
          {toDateFormat(row?.shortlistCreatedAt)}
        </TableCell>
      </>
    );
  }
}

export default injectIntl(
  connect()(TableInterestedInMyBuildingsCells)
);

import React from "react";
import TableProperties from "app/partials/components/TableProperties/TableProperties";
import {HEAD_ROWS, TABLE_CELLS} from "app/partials/components/TableProperties";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {callReddClickEvent} from "app/crud/user.crud";
import IsPro from "../../../utils/IsPro";

class SubscribersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      initLoaded: false
    }
  }

  // Handle fetch data on enter viewport
  handleOnViewportFetch = (isVisible) => {
    const {fetchSubscribers, setLatestActiveTablePage, loading} = this.props;
    const {initLoaded} = this.state;
    const params = {
      page: 1
    };
    setLatestActiveTablePage(0);
    if(isVisible && !loading && !initLoaded) {
      this.setState({
        initLoaded: true
      }, () => fetchSubscribers({params}));
    }
    //
    if(isVisible && !this.state.isReady) {
      this.setState({
        isReady: true
      }, () => {
        this.props.dispatch(callReddClickEvent(
          "subscribers-visit",
          "subscriber"
        ));
      });
    }
  };
  handleOnViewport = (isInViewport) => {
    this.handleOnViewportFetch(isInViewport);
  }

  render() {
    const {subscribers, loading} = this.props;
    return(
      <IsPro paywall>
        <TableProperties
          tableLabel={<FormattedMessage id="MENU.SUBSCRIBERS"/>}
          dataSource="office"
          headRows={HEAD_ROWS.SUBSCRIBERS}
          bodyRows={subscribers?.items}
          itemsPerPage={subscribers?.itemsPerPage}
          itemsCount={subscribers?.itemsCount}
          dataTarget={TABLE_CELLS.SUBSCRIBERS}
          loading={loading}
          handleFetchData={this.props.fetchSubscribers}
          onEnterViewport={this.handleOnViewport}
        />
      </IsPro>
    );
  }
}

export default injectIntl(connect()(SubscribersPage));
import React from "react";
import PropertySectionTitle from "app/pages/common/components/PropertyPage/PropertySectionTitle";
import {handleEmptyValue, toNumberFormat} from "../../../../../utils/helpers";
import styles from "./index.module.scss";
import {Grid} from "@material-ui/core";
import ReactVisibilitySensor from "react-visibility-sensor";
import _ from "lodash";
import LoadingScreen from "../../../../../partials/components/LoadingScreen";
import {FormattedMessage} from "react-intl";
import EditModal from "../../../../../partials/components/EditModal";
import {injectIntl} from "react-intl";

class PropertyLeaseTerms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initLoaded: false
    }
  }

  // Handlers
  handleOnViewportFetch = (isVisible) => {
    const {fetchOfficeLeaseTerms, leaseTerms, leaseTermsLoading, match, dummyData} = this.props;
    const {initLoaded} = this.state;
    const {id} = match?.params;

    if(isVisible && !initLoaded && !leaseTermsLoading && _.isEmpty(leaseTerms) && id && !dummyData) {
      this.setState({
        initLoaded: true,
      }, () => fetchOfficeLeaseTerms(id));
    }
  }
  handleValueLoading = (loading, value, negotiable) => {
    const {intl} = this.props;

    if(loading) {
      return <LoadingScreen loaderStyle={{width: "1rem", height: "1rem"}}/>;
    }
    else if(value === "-" && negotiable) {
      return intl.formatMessage({id: "BUILDING.PHRASE.TO_NEGOTIATE"});
    }
    return value;
  }

  render() {
    const {parentRef, basic, leaseTerms, leaseTermsLoading} = this.props;
    const isOfficeEditable = basic?.isEditable;
    const rentOfficeRange = [
      ...leaseTerms?.rentFromEur ? [toNumberFormat(leaseTerms?.rentFromEur)] : [],
      ...leaseTerms?.rentToEur ? [toNumberFormat(leaseTerms?.rentToEur)] : []
    ].join(" - ");
    const rentRetailRange = [
      ...leaseTerms?.retailRentPriceEur ? [toNumberFormat(leaseTerms?.retailRentPriceEur)] : [],
      ...leaseTerms?.retailRentPriceToEur ? [toNumberFormat(leaseTerms?.retailRentPriceToEur)] : []
    ].join(" - ");
    const rentOffice = leaseTerms?.rentToNegotiate ? <FormattedMessage id="BUILDING.PHRASE.TO_NEGOTIATE"/> : toNumberFormat(rentOfficeRange, 'range', null, '€');
    const rentRetail = leaseTerms?.rentToNegotiate ? <FormattedMessage id="BUILDING.PHRASE.TO_NEGOTIATE"/> : toNumberFormat(rentRetailRange, 'range', null, '€');
    const parkingFactor = [
      ...leaseTerms?.parkingFactorNumber ? [leaseTerms?.parkingFactorNumber] : leaseTerms?.parkingFactorArea ? ["1"] : [],
      ...leaseTerms?.parkingFactorArea ? [leaseTerms?.parkingFactorArea] : []
    ].join("/");
    const parkingGround = leaseTerms?.parkingGroundNotExist ? <FormattedMessage id="VOCABULARY.Not exist"/> :
      leaseTerms?.parkingGroundToNegotiate ? <FormattedMessage id="BUILDING.PHRASE.TO_NEGOTIATE"/> :
        toNumberFormat(leaseTerms?.parkingGroundRentEur, "currency");
    const parkingUnderground = leaseTerms?.parkingUndergroundNotExist ? <FormattedMessage id="VOCABULARY.Not exist"/> :
      leaseTerms?.parkingUndergroundToNegotiate ? <FormattedMessage id="BUILDING.PHRASE.TO_NEGOTIATE"/> :
        toNumberFormat(leaseTerms?.parkingUndergroundRentEur, "currency");

    return(
      <div className={styles.leaseTermsContainer}>
        <ReactVisibilitySensor
          onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
          scrollCheck
          partialVisibility
        >
          <>
          <PropertySectionTitle
            parentRef={parentRef}
            title={<FormattedMessage id="VOCABULARY.Lease terms"/>}
            titleActions={
              basic?.isEditable &&
              <EditModal
                target="office"
                parentData={basic}
                isPropertyEditable={isOfficeEditable}
                initialTab="leaseTerms"
              />
            }
          />
          <Grid container>
            <Grid item xs={12}>
              <table className={styles.simpleTable}>
              <tbody>
                <tr>
                  <td><FormattedMessage id="VOCABULARY.Rent - office"/></td>
                  <td>{this.handleValueLoading(leaseTermsLoading, rentOffice, true)}</td>
                </tr>
                <tr>
                  <td><FormattedMessage id="VOCABULARY.Rent - retail"/></td>
                  <td>{this.handleValueLoading(leaseTermsLoading, rentRetail, true)}</td>
                </tr>
                <tr>
                  <td><FormattedMessage id="VOCABULARY.Service charge"/></td>
                  <td>{this.handleValueLoading(leaseTermsLoading, toNumberFormat(leaseTerms?.serviceChargesPln, 'currency', 'PLN'), true)}</td>
                </tr>
              </tbody>
            </table>
            </Grid>
            <Grid item xs={12}>
              <table className={styles.simpleTable}>
              <tbody>
                <tr>
                  <td><FormattedMessage id="VOCABULARY.Add-on factor"/></td>
                  <td>{this.handleValueLoading(leaseTermsLoading, toNumberFormat(leaseTerms?.commonAreaFactor, '%'))}</td>
                </tr>
                <tr>
                  <td><FormattedMessage id="VOCABULARY.Lease period"/></td>
                  <td>
                    {
                      this.handleValueLoading(
                        leaseTermsLoading,
                        leaseTerms?.minRentalYearsToNegotiate ? <FormattedMessage id="BUILDING.PHRASE.TO_NEGOTIATE"/> :
                        leaseTerms?.minRentalYearsIndefinite ? <FormattedMessage id="BUILDING.PHRASE.INDEFINITE"/> :
                          leaseTerms?.minRentalYears ?
                            <FormattedMessage
                              id="BUILDING.PHRASE.YEARS_VALUE"
                              values={{
                                years: leaseTerms?.minRentalYears,
                                count: Number(leaseTerms?.minRentalYears)
                              }}
                            /> : "-"
                      )
                    }
                  </td>
                </tr>
                <tr>
                  <td><FormattedMessage id="VOCABULARY.Parking space ratio"/></td>
                  <td>{this.handleValueLoading(leaseTermsLoading, handleEmptyValue(parkingFactor))}</td>
                </tr>
              </tbody>
            </table>
            </Grid>
            <Grid item xs={12}>
              <table className={styles.simpleTable}>
              <tbody>
                <tr>
                  <td><FormattedMessage id="VOCABULARY.Rent for parking space (underground)"/></td>
                  <td>{this.handleValueLoading(leaseTermsLoading, parkingUnderground)}</td>
                </tr>
                <tr>
                  <td><FormattedMessage id="VOCABULARY.Rent for parking space (surface)"/></td>
                  <td>{this.handleValueLoading(leaseTermsLoading, parkingGround)}</td>
                </tr>
              </tbody>
            </table>
            </Grid>
          </Grid>
          </>
        </ReactVisibilitySensor>
      </div>
    );
  }
}

export default injectIntl(PropertyLeaseTerms);
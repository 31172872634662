import React from "react";
import parentStyles from "app/pages/common/external/components/index.module.scss";
import {FormattedMessage} from "react-intl";
import LanguageSelector from "app/partials/layout/LanguageSelector";
import OffersOnlineSelectCity from "./OffersOnlineSelectCity";
import {toDateFormat} from "../../../../../utils/helpers";

export default class OffersOnlineHeader extends React.Component {
    render() {
        const {view, onGoBack, branding, offerID, onFilterMarket} = this.props;
        const offerDate = branding?.offerDate || branding?.createdAt || null;
        const offerDateFinal = offerDate ? toDateFormat(offerDate) : "";

        return(
            <div ref={this.props.reference} className={parentStyles.headerSection}>
                <div className={parentStyles.headerTitle}>
                    {branding && branding.offerCompanyLogo ? <img src={branding.offerCompanyLogo} alt={branding.name}/> : branding && <span className={parentStyles.headerName}>{branding.name}</span>}
                    <h2><FormattedMessage id="VOCABULARY.Offer dated"/> {offerDateFinal}</h2>
                </div>
                <div className={parentStyles.headerOptions}>
                    {view === "list" && branding && branding.markets && branding.markets.length > 1 &&
                        <OffersOnlineSelectCity
                            offerID={offerID}
                            branding={branding}
                            onFilter={onFilterMarket}
                        />
                    }
                    {view === "single" && onGoBack && <button className={parentStyles.menuItem} onClick={onGoBack}><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.BACK_TO_LIST"/></button>}
                    <div className={parentStyles.langMenu}>
                        <LanguageSelector preventReload/>
                    </div>
                </div>
            </div>
        );
    }
}
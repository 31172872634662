import React from "react";
import styles from "app/pages/common/external/components/index.module.scss";
import clsx from "clsx";
import Slider from "react-slick/lib";

export default class PropertyParticularsSingleHeader extends React.Component {
    render() {
        const {photos, planImage} = this.props;
        const isMobile = window.outerWidth <= 760;
        const slickSettings = {
            dots: false,
            infinite: true,
            speed: 500,
            slideToShow: 1,
            slidesToScroll: 1,
        };
        const totalImages = photos.length + (planImage ? 1 : 0);

        if (photos.length || planImage) {
            if (isMobile) {
                return (
                    <div className={styles.propertySlider}>
                        <Slider {...slickSettings}>
                            {photos.map((photo, index) => <img key={index} src={photo}
                                                               alt="REDD | Property Particular Online"/>)}
                        </Slider>
                    </div>
                );
            }
            return (
                <div className={styles.galleryWrapper}>
                    {totalImages === 1 && (
                        <div className={styles.singleImage}>
                            <div style={{"--bg-image": `url(${planImage ? planImage : photos[0]})`}}/>
                        </div>
                    )}
                    {totalImages === 2 && planImage && (
                        <div className={styles.featureImage}>
                            <div style={{"--bg-image": `url(${planImage})`}}/>
                        </div>
                    )}
                    {totalImages === 2 && photos.map((photo, index) =>
                        <div key={index} className={styles.featureImage}>
                            <div style={{"--bg-image": `url(${photo})`}}/>
                        </div>
                    )}
                    {totalImages === 3 && planImage && (
                        <div className={styles.threeImage}>
                            <div style={{"--bg-image": `url(${planImage})`}}/>
                        </div>
                    )}
                    {totalImages === 3 && photos.map((photo, index) =>
                        <div key={index} className={styles.threeImage}>
                            <div style={{"--bg-image": `url(${photo})`}}/>
                        </div>
                    )}
                    {totalImages === 4 && (
                        <div className={clsx(styles.gridImages, styles.gridFull)}>
                            {planImage &&
                            <div className={styles.fourImage}>
                                <div style={{"--bg-image": `url(${planImage})`}}/>
                            </div>
                            }
                            {photos.map((photo, index) =>
                                <div key={index} className={styles.fourImage}>
                                    <div
                                        style={{"--bg-image": `url(${index === 0 && planImage ? planImage : photo})`}}/>
                                </div>
                            )}
                        </div>
                    )}
                    {totalImages >= 5 && (
                        <>
                            <div className={styles.featureImage}>
                                <div style={{"--bg-image": `url(${planImage ? planImage : photos[0]})`}}/>
                            </div>
                            <div className={styles.gridImages}>
                                <div style={{"--bg-image": `url(${photos[planImage ? 1 : 0]})`}}/>
                                <div style={{"--bg-image": `url(${photos[planImage ? 2 : 1]})`}}/>
                                <div style={{"--bg-image": `url(${photos[planImage ? 3 : 2]})`}}/>
                                {photos.length >= 4 && <div style={{"--bg-image": `url(${photos[planImage ? 4 : 3]})`}}/>}
                            </div>
                        </>
                    )}
                </div>
            );
        }
        return false;
    }
}
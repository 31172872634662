import React from "react";
import styles from "app/pages/common/external/components/index.module.scss";
import IconColorMUI from "../../../../../../partials/components/IconColorMUI";
import {FormattedMessage} from "react-intl";
import {handleEmptyValue, toNumberFormat} from "app/utils/helpers";

export default class PropertyParticularsSingleTransport extends React.Component {
    render() {
        const {data, intl} = this.props;
        const poisSorted = data.reduce(function (r, a) {
            r[a.category] = r[a.category] || [];
            r[a.category].push(a);
            return r;
        }, Object.create(null));

        return(
          <div ref={this.props.reference} className={styles.boxContainer}>
              <h2><FormattedMessage id="PROPERTY_PARTICULARS.TAB.PUBLIC_TRANSPORT"/></h2>
              <div className={styles.poisWrapper}>
                  <table className={styles.poiTable}>
                      <tbody>
                      {poisSorted && Object.entries(poisSorted).map((poi, index) => {
                          const categoryName = Object.keys(poisSorted)[index];
                          return(
                            <tr key={index}>
                              <td>
                                <table>
                                  <tbody>
                                    <tr className={styles.tHeadRow}>
                                        <th className={styles.alignLeft}>{intl.formatMessage({id: categoryName})}</th>
                                        <th className={styles.alignLeft}/>
                                        <th><IconColorMUI icon="route"/></th>
                                        <th><IconColorMUI icon="directions_walk"/></th>
                                        <th><IconColorMUI icon="directions_car"/></th>
                                        <th><IconColorMUI icon="departure_board"/></th>
                                    </tr>
                                    {poi && poi[1] && poi[1].map((item, index) => {
                                    return(
                                        <tr key={index}>
                                            <td data-label={categoryName}>{item.name}</td>
                                            <td data-label={intl.formatMessage({id: "PROPERTY_PARTICULARS.PHRASE.Lines"})} className={styles.transportLinesWrapper}>{item.lines && item.lines.map((line, index) => <span key={index}>{line}</span>)}</td>
                                            <td data-label={intl.formatMessage({id: "PROPERTY_PARTICULARS.PHRASE.Distance"})}>{toNumberFormat(item.distance)} m</td>
                                            <td data-label={intl.formatMessage({id: "PROPERTY_PARTICULARS.PHRASE.Time by foot"})}>{handleEmptyValue(toNumberFormat(item.timeOnFoot, 'minutes'))}</td>
                                            <td data-label={intl.formatMessage({id: "PROPERTY_PARTICULARS.PHRASE.Time by car"})}>{handleEmptyValue(toNumberFormat(item.timeWithCar, 'minutes'))}</td>
                                            <td data-label={intl.formatMessage({id: "PROPERTY_PARTICULARS.PHRASE.Time by public transport"})}>{handleEmptyValue(toNumberFormat(item.timeWithPublicTransport, 'minutes'))}</td>
                                        </tr>
                                    );
                                    })}
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                              );
                          })}
                      </tbody>
                  </table>
              </div>
          </div>
        );
    }
}
import React, {Component} from "react";
import styles from "./index.module.scss";
import {toAbsoluteUrl} from "_metronic";
import {Card, CardActionArea, CardContent, Grid} from "@material-ui/core";
import {Image} from "react-bootstrap";
import tableStyles from "app/partials/components/TableProperties/index.module.scss";
import clsx from "clsx";
import {FormattedMessage} from "react-intl";
import BetaLabel from "app/partials/components/BetaLabel";
import {areaFormat} from "../../../utils/helpers";

export default class AdvanceListItem extends Component {
  handleShowOnMap = (data, isEditable) => {
    const {onListItemClick} = this.props;
    const officeData = {
      lat: data?.office?.geoLatitude,
      lng: data?.office?.geoLongitude,
      officeId: data?.office?.id,
      name: data?.office?.name,
      addressCity: data?.office?.addressCity,
      addressStreet: data?.office?.addressStreet,
      mainPhotoThumbnail: data?.office?.mainPhotoThumbnail,
      unitId: data?.id,
      isEditable: isEditable
    };
    onListItemClick(officeData);
  };

  render() {
    const {data, name, street, thumb, selectedItem, isEditable} = this.props;
    const avatar = thumb !== null ? thumb : toAbsoluteUrl("media/office-placeholder.jpg");

    return (
      <Grid item className={styles.listItem}>
        <Card className={clsx(selectedItem === data.id ? styles.activeBox : '', styles.shadowDisabled)} style={{boxShadow: "none !important"}}>
          <CardActionArea>
            <CardContent>
              <div onClick={() => this.handleShowOnMap(data, isEditable)} style={{position: "relative"}}>
                {data.isSublease && <BetaLabel right="0" top="0"><FormattedMessage id="UNITS.PHRASE.SUBLEASE"/></BetaLabel>}
                <Grid container justifyContent="flex-start" alignItems="center" wrap="nowrap">
                  <Grid item>
                    <Image src={avatar} className={styles.unitAvatar} rounded />
                  </Grid>
                  <Grid item>
                    <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
                      <span className={tableStyles.unitValue}>Unit {data.area.toLocaleString('pl-PL')} {areaFormat()}</span>
                      <span className={tableStyles.unitLabel}>{name}</span>
                      {street && (
                        <span className={tableStyles.unitLabel}>{street}</span>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    )
  }
}
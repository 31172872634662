import {toAbsoluteUrl} from "../../../../../_metronic";

let unitUpdateDate = new Date();
unitUpdateDate.setDate(unitUpdateDate.getDate() - 3);
const unitUpdatedAt = unitUpdateDate;
const user = {
  "pk": null,
  "email": "jan.kowalski@reddplatform.com",
  "firstName": "Jan",
  "lastName": "Kowalski",
  "phoneNumber": "+48 123-123-123",
  "position": "REDD Demo Man",
  "forceLogOut": false,
  "team": {
    "id": 0,
    "name": "REDD",
    "teamType": 1,
    "followersCount": 0,
    "company": {
      "id": 0,
      "name": "REDD Group PSA",
      "shortName": "REDD Group PSA",
      "addressStreet": null,
      "addressCity": null,
      "addressZipcode": null
    },
    "buildingsCount": 0,
    "buildingsAvailableCount": 0
  },
  "isTeamOwner": true,
  "avatar": "https://s3.eu-west-1.amazonaws.com/reddplatform.redd/media/office_photos/thumbnails/e1953550-abaa-4b1a-8924-9851b3f5df58_150x150.png",
  "currentApplicationView": 0,
  "country": "PL",
  "companyLogo": "https://s3.eu-west-1.amazonaws.com/reddplatform.redd/media/office_photos/thumbnails/40029a47-26ae-497e-b029-f4042dbafe3c_150x150.png",
  "companyLogoThumb": "https://s3.eu-west-1.amazonaws.com/reddplatform.redd/media/cache/1f/d4/1fd4ea75d0d672aafc12807344c29bdf.png?AWSAccessKeyId=AKIA5AWTZEI4MTHN25WQ&Signature=WYC7rFyGHJ8JsJY2tWWAdGjoQZY%3D&Expires=1726826677",
  "companyName": "REDD",
  "langCode": "pl",
  "mailLangCode": "pl",
  "rwdSize": 9,
  "proData": {
    "isPro": true,
    "proUntil": null,
    "proIndefinite": true,
    "proGroup": "Landlord",
    "symmcoAccess": false,
    "canChangeApplicationView": false
  },
  "proAccessRequestSent": false,
  "adminMarkup": false,
  "adminEnableScripts": false,
  "dummyViewActive": true
};
const data = {
  user,
  "basic": {
    "id": 99999999,
    "name": "REDD Tower",
    "mainPhotoThumbnail400x400": toAbsoluteUrl("media/placeholders/redd-tower/dummy-data-redd-tower.jpg"),
    "isDeleted": false,
    "isEditable": false,
    "market": 1,
    "status": 0,
    "addressCity": "Warszawa",
    "addressZipcode": "00-838",
    "geoLongitude": "20.9836345631937430",
    "geoLatitude": "52.2294677785727540",
    "neighborhood": null,
    "addressStreet": "Prosta",
    "totalAreaAvailable": 19816,
    "occupancyRateV2": "52.9",
    "updatedAtByUser": "2024-09-20T10:49:37+02:00",
    "timeToLease": 127,
    "numberOfUnitsAvailable": 21,
    "lackOfAvailableArea": false,
    "buildingAvailableFrom": "2023-10-01",
    "commercializationSuspended": false,
    "commercializationSuspendedDate": null,
    "buildingType": 1,
    "buildingClass": "A",
    "commissioningYear": 2026,
    "totalAreaBrutto": 43334,
    "totalAreaNetto": 42032,
    "occupancyRate6m": "49.1",
    "occupancyRate12m": "1.8",
    "totalAreaAvailable6m": 21382,
    "totalAreaAvailable12m": 41267,
    "timeToLease6m": 245,
    "timeToLease12m": 278
  },
  "units": [
    {
      "id": 28637,
      "unitType": 1,
      "unitTypeDisplay": "Office",
      "availableFrom": "2023-10-01T02:00:00+02:00",
      "availableFromAdditionalTime": null,
      "isSublease": false,
      "canBeDivided": false,
      "dividedMinValue": null,
      "dividedUnits": [],
      "area": 946,
      "floor": 5,
      "status": 0,
      "subleaseRentToNegotiate": false,
      "subleaseRentEur": null,
      "subleaseServiceChargePln": null,
      "updateLevelCache": 1,
      "updateLevel": 4,
      "updatedAt": unitUpdatedAt
    },
    {
      "id": 28653,
      "unitType": 1,
      "unitTypeDisplay": "Office",
      "availableFrom": "2023-10-01T02:00:00+02:00",
      "availableFromAdditionalTime": null,
      "isSublease": false,
      "canBeDivided": false,
      "dividedMinValue": null,
      "dividedUnits": [],
      "area": 946,
      "floor": 21,
      "status": 0,
      "subleaseRentToNegotiate": false,
      "subleaseRentEur": null,
      "subleaseServiceChargePln": null,
      "updateLevelCache": 1,
      "updateLevel": 4,
      "updatedAt": unitUpdatedAt
    },
    {
      "id": 28646,
      "unitType": 1,
      "unitTypeDisplay": "Office",
      "availableFrom": "2023-10-01T02:00:00+02:00",
      "availableFromAdditionalTime": null,
      "isSublease": false,
      "canBeDivided": false,
      "dividedMinValue": null,
      "dividedUnits": [],
      "area": 946,
      "floor": 14,
      "status": 0,
      "subleaseRentToNegotiate": false,
      "subleaseRentEur": null,
      "subleaseServiceChargePln": null,
      "updateLevelCache": 1,
      "updateLevel": 4,
      "updatedAt": unitUpdatedAt
    },
    {
      "id": 28643,
      "unitType": 1,
      "unitTypeDisplay": "Office",
      "availableFrom": "2023-10-01T02:00:00+02:00",
      "availableFromAdditionalTime": null,
      "isSublease": false,
      "canBeDivided": false,
      "dividedMinValue": null,
      "dividedUnits": [],
      "area": 946,
      "floor": 11,
      "status": 0,
      "subleaseRentToNegotiate": false,
      "subleaseRentEur": null,
      "subleaseServiceChargePln": null,
      "updateLevelCache": 1,
      "updateLevel": 4,
      "updatedAt": unitUpdatedAt
    },
    {
      "id": 28649,
      "unitType": 1,
      "unitTypeDisplay": "Office",
      "availableFrom": "2023-10-01T02:00:00+02:00",
      "availableFromAdditionalTime": null,
      "isSublease": false,
      "canBeDivided": false,
      "dividedMinValue": null,
      "dividedUnits": [],
      "area": 946,
      "floor": 17,
      "status": 0,
      "subleaseRentToNegotiate": false,
      "subleaseRentEur": null,
      "subleaseServiceChargePln": null,
      "updateLevelCache": 1,
      "updateLevel": 4,
      "updatedAt": unitUpdatedAt
    },
    {
      "id": 28641,
      "unitType": 1,
      "unitTypeDisplay": "Office",
      "availableFrom": "2023-10-01T02:00:00+02:00",
      "availableFromAdditionalTime": null,
      "isSublease": false,
      "canBeDivided": false,
      "dividedMinValue": null,
      "dividedUnits": [],
      "area": 946,
      "floor": 9,
      "status": 0,
      "subleaseRentToNegotiate": false,
      "subleaseRentEur": null,
      "subleaseServiceChargePln": null,
      "updateLevelCache": 1,
      "updateLevel": 4,
      "updatedAt": unitUpdatedAt
    },
    {
      "id": 28648,
      "unitType": 1,
      "unitTypeDisplay": "Office",
      "availableFrom": "2023-10-01T02:00:00+02:00",
      "availableFromAdditionalTime": null,
      "isSublease": false,
      "canBeDivided": false,
      "dividedMinValue": null,
      "dividedUnits": [],
      "area": 946,
      "floor": 16,
      "status": 0,
      "subleaseRentToNegotiate": false,
      "subleaseRentEur": null,
      "subleaseServiceChargePln": null,
      "updateLevelCache": 1,
      "updateLevel": 4,
      "updatedAt": unitUpdatedAt
    },
    {
      "id": 28650,
      "unitType": 1,
      "unitTypeDisplay": "Office",
      "availableFrom": "2023-10-01T02:00:00+02:00",
      "availableFromAdditionalTime": null,
      "isSublease": false,
      "canBeDivided": false,
      "dividedMinValue": null,
      "dividedUnits": [],
      "area": 946,
      "floor": 18,
      "status": 0,
      "subleaseRentToNegotiate": false,
      "subleaseRentEur": null,
      "subleaseServiceChargePln": null,
      "updateLevelCache": 1,
      "updateLevel": 4,
      "updatedAt": unitUpdatedAt
    },
    {
      "id": 28644,
      "unitType": 1,
      "unitTypeDisplay": "Office",
      "availableFrom": "2023-10-01T02:00:00+02:00",
      "availableFromAdditionalTime": null,
      "isSublease": false,
      "canBeDivided": false,
      "dividedMinValue": null,
      "dividedUnits": [],
      "area": 946,
      "floor": 12,
      "status": 0,
      "subleaseRentToNegotiate": false,
      "subleaseRentEur": null,
      "subleaseServiceChargePln": null,
      "updateLevelCache": 1,
      "updateLevel": 4,
      "updatedAt": unitUpdatedAt
    },
    {
      "id": 28642,
      "unitType": 1,
      "unitTypeDisplay": "Office",
      "availableFrom": "2023-10-01T02:00:00+02:00",
      "availableFromAdditionalTime": null,
      "isSublease": false,
      "canBeDivided": false,
      "dividedMinValue": null,
      "dividedUnits": [],
      "area": 946,
      "floor": 10,
      "status": 0,
      "subleaseRentToNegotiate": false,
      "subleaseRentEur": null,
      "subleaseServiceChargePln": null,
      "updateLevelCache": 1,
      "updateLevel": 4,
      "updatedAt": unitUpdatedAt
    },
    {
      "id": 28654,
      "unitType": 1,
      "unitTypeDisplay": "Office",
      "availableFrom": "2023-10-01T02:00:00+02:00",
      "availableFromAdditionalTime": null,
      "isSublease": false,
      "canBeDivided": false,
      "dividedMinValue": null,
      "dividedUnits": [],
      "area": 946,
      "floor": 22,
      "status": 0,
      "subleaseRentToNegotiate": false,
      "subleaseRentEur": null,
      "subleaseServiceChargePln": null,
      "updateLevelCache": 1,
      "updateLevel": 4,
      "updatedAt": unitUpdatedAt
    },
    {
      "id": 28635,
      "unitType": 1,
      "unitTypeDisplay": "Office",
      "availableFrom": "2023-10-01T02:00:00+02:00",
      "availableFromAdditionalTime": null,
      "isSublease": false,
      "canBeDivided": false,
      "dividedMinValue": null,
      "dividedUnits": [],
      "area": 946,
      "floor": 3,
      "status": 0,
      "subleaseRentToNegotiate": false,
      "subleaseRentEur": null,
      "subleaseServiceChargePln": null,
      "updateLevelCache": 1,
      "updateLevel": 4,
      "updatedAt": unitUpdatedAt
    },
    {
      "id": 28640,
      "unitType": 1,
      "unitTypeDisplay": "Office",
      "availableFrom": "2023-10-01T02:00:00+02:00",
      "availableFromAdditionalTime": null,
      "isSublease": false,
      "canBeDivided": false,
      "dividedMinValue": null,
      "dividedUnits": [],
      "area": 946,
      "floor": 8,
      "status": 0,
      "subleaseRentToNegotiate": false,
      "subleaseRentEur": null,
      "subleaseServiceChargePln": null,
      "updateLevelCache": 1,
      "updateLevel": 4,
      "updatedAt": unitUpdatedAt
    },
    {
      "id": 28647,
      "unitType": 1,
      "unitTypeDisplay": "Office",
      "availableFrom": "2023-10-01T02:00:00+02:00",
      "availableFromAdditionalTime": null,
      "isSublease": false,
      "canBeDivided": false,
      "dividedMinValue": null,
      "dividedUnits": [],
      "area": 946,
      "floor": 15,
      "status": 0,
      "subleaseRentToNegotiate": false,
      "subleaseRentEur": null,
      "subleaseServiceChargePln": null,
      "updateLevelCache": 1,
      "updateLevel": 4,
      "updatedAt": unitUpdatedAt
    },
    {
      "id": 28652,
      "unitType": 1,
      "unitTypeDisplay": "Office",
      "availableFrom": "2023-10-01T02:00:00+02:00",
      "availableFromAdditionalTime": null,
      "isSublease": false,
      "canBeDivided": false,
      "dividedMinValue": null,
      "dividedUnits": [],
      "area": 946,
      "floor": 20,
      "status": 0,
      "subleaseRentToNegotiate": false,
      "subleaseRentEur": null,
      "subleaseServiceChargePln": null,
      "updateLevelCache": 1,
      "updateLevel": 4,
      "updatedAt": unitUpdatedAt
    },
    {
      "id": 28639,
      "unitType": 1,
      "unitTypeDisplay": "Office",
      "availableFrom": "2023-10-01T02:00:00+02:00",
      "availableFromAdditionalTime": null,
      "isSublease": false,
      "canBeDivided": false,
      "dividedMinValue": null,
      "dividedUnits": [],
      "area": 946,
      "floor": 7,
      "status": 0,
      "subleaseRentToNegotiate": false,
      "subleaseRentEur": null,
      "subleaseServiceChargePln": null,
      "updateLevelCache": 1,
      "updateLevel": 4,
      "updatedAt": unitUpdatedAt
    },
    {
      "id": 28636,
      "unitType": 1,
      "unitTypeDisplay": "Office",
      "availableFrom": "2023-10-01T02:00:00+02:00",
      "availableFromAdditionalTime": null,
      "isSublease": false,
      "canBeDivided": false,
      "dividedMinValue": null,
      "dividedUnits": [],
      "area": 946,
      "floor": 4,
      "status": 0,
      "subleaseRentToNegotiate": false,
      "subleaseRentEur": null,
      "subleaseServiceChargePln": null,
      "updateLevelCache": 1,
      "updateLevel": 4,
      "updatedAt": unitUpdatedAt
    },
    {
      "id": 28651,
      "unitType": 1,
      "unitTypeDisplay": "Office",
      "availableFrom": "2023-10-01T02:00:00+02:00",
      "availableFromAdditionalTime": null,
      "isSublease": false,
      "canBeDivided": false,
      "dividedMinValue": null,
      "dividedUnits": [],
      "area": 946,
      "floor": 19,
      "status": 0,
      "subleaseRentToNegotiate": false,
      "subleaseRentEur": null,
      "subleaseServiceChargePln": null,
      "updateLevelCache": 1,
      "updateLevel": 4,
      "updatedAt": unitUpdatedAt
    },
    {
      "id": 28645,
      "unitType": 1,
      "unitTypeDisplay": "Office",
      "availableFrom": "2023-10-01T02:00:00+02:00",
      "availableFromAdditionalTime": null,
      "isSublease": false,
      "canBeDivided": false,
      "dividedMinValue": null,
      "dividedUnits": [],
      "area": 946,
      "floor": 13,
      "status": 0,
      "subleaseRentToNegotiate": false,
      "subleaseRentEur": null,
      "subleaseServiceChargePln": null,
      "updateLevelCache": 1,
      "updateLevel": 4,
      "updatedAt": unitUpdatedAt
    },
    {
      "id": 28655,
      "unitType": 1,
      "unitTypeDisplay": "Office",
      "availableFrom": "2023-10-01T02:00:00+02:00",
      "availableFromAdditionalTime": null,
      "isSublease": false,
      "canBeDivided": false,
      "dividedMinValue": null,
      "dividedUnits": [],
      "area": 896,
      "floor": 23,
      "status": 0,
      "subleaseRentToNegotiate": false,
      "subleaseRentEur": null,
      "subleaseServiceChargePln": null,
      "updateLevelCache": 1,
      "updateLevel": 4,
      "updatedAt": unitUpdatedAt
    },
    {
      "id": 28638,
      "unitType": 1,
      "unitTypeDisplay": "Office",
      "availableFrom": "2023-10-01T02:00:00+02:00",
      "availableFromAdditionalTime": null,
      "isSublease": false,
      "canBeDivided": false,
      "dividedMinValue": null,
      "dividedUnits": [],
      "area": 946,
      "floor": 6,
      "status": 0,
      "subleaseRentToNegotiate": false,
      "subleaseRentEur": null,
      "subleaseServiceChargePln": null,
      "updateLevelCache": 1,
      "updateLevel": 4,
      "updatedAt": unitUpdatedAt
    }
  ],
  "leaseTerms": {
    "rentCurrency": 1,
    "rentToNegotiate": false,
    "rentFrom": "25.00",
    "rentFromEur": "25.00",
    "rentTo": "27.00",
    "rentToEur": "27.00",
    "retailRentPrice": "25.00",
    "retailRentPriceEur": "25.00",
    "retailRentPriceTo": "27.00",
    "retailRentPriceToEur": "27.00",
    "serviceChargesCurrency": 0,
    "serviceChargesPln": "25.00",
    "serviceCharge": "25.00",
    "commonAreaFactor": "2.91",
    "minRentalYears": 5,
    "minRentalYearsToNegotiate": false,
    "minRentalYearsIndefinite": false,
    "parkingUndergroundNotExist": false,
    "parkingUndergroundToNegotiate": false,
    "parkingUndergroundRentCurrency": 1,
    "parkingUndergroundRent": "200.00",
    "parkingUndergroundRentEur": "200.00",
    "parkingGroundNotExist": true,
    "parkingGroundToNegotiate": false,
    "parkingGroundRentCurrency": 1,
    "parkingGroundRent": null,
    "parkingGroundRentEur": null,
    "parkingFactorNumber": 1,
    "parkingFactorArea": 130
  },
  "amenities": {
    "airConditioning": true,
    "raisedFloor": true,
    "suspendedCeiling": true,
    "powerCabling": true,
    "telephoneCabling": true,
    "computerCabling": true,
    "smokeDetectors": true,
    "fiberOptics": true,
    "tiltWindows": true,
    "ledLighting": true,
    "wallPartitioning": true,
    "sprinklers": true,
    "monitoringSystem": true,
    "emergencyPowerSupply": true,
    "powerGenerator": true,
    "reception": true,
    "security": true,
    "accessControl": true,
    "gym": true,
    "bistroOrCanteen": true,
    "electricCarCharges": true,
    "wifiInTheHall": true,
    "freeBikeRentalForTenants": true,
    "bicycleRoom": true,
    "parkingForGuests": true,
    "bicycleStands": true,
    "greenPatio": true,
    "terrace": true,
    "BMS": true,
    "carpeting": true,
    "lockersAndShowersForCyclists": true,
    "petFriendlyBuilding": true,
  },
  "changes": [
    {
      "id": 28655,
      "area": 896,
      "unitType": 1,
      "isSublease": false,
      "floor": 23,
      "status": "ADDED",
      "date": "2024-10-01T13:24:12.310308Z"
    },
    {
      "id": 28654,
      "area": 946,
      "unitType": 1,
      "isSublease": false,
      "floor": 22,
      "status": "ADDED",
      "date": "2024-09-27T13:23:42.893443Z"
    },
    {
      "id": 28653,
      "area": 946,
      "unitType": 1,
      "isSublease": false,
      "floor": 21,
      "status": "RENTED",
      "date": "2024-09-21T13:23:37.947553Z"
    },
    {
      "id": 28652,
      "area": 946,
      "unitType": 1,
      "isSublease": false,
      "floor": 20,
      "status": "ADDED",
      "date": "2024-09-12T13:23:24.224436Z"
    },
    {
      "id": 28651,
      "area": 946,
      "unitType": 1,
      "isSublease": false,
      "floor": 19,
      "status": "RENTED",
      "date": "2024-09-02T13:23:18.509800Z"
    },
    {
      "id": 28650,
      "area": 946,
      "unitType": 1,
      "isSublease": false,
      "floor": 18,
      "status": "RENTED",
      "date": "2024-09-02T13:23:18.509800Z"
    },
    {
      "id": 28649,
      "area": 946,
      "unitType": 1,
      "isSublease": false,
      "floor": 17,
      "status": "ADDED",
      "date": "2024-09-02T13:23:18.509800Z"
    },
    {
      "id": 28648,
      "area": 946,
      "unitType": 1,
      "isSublease": false,
      "floor": 16,
      "status": "ADDED",
      "date": "2024-09-02T13:23:18.509800Z"
    },
    {
      "id": 28647,
      "area": 946,
      "unitType": 1,
      "isSublease": false,
      "floor": 15,
      "status": "RENTED",
      "date": "2024-09-02T13:23:18.509800Z"
    },
    {
      "id": 28646,
      "area": 946,
      "unitType": 1,
      "isSublease": false,
      "floor": 14,
      "status": "ADDED",
      "date": "2024-07-20T13:22:19.256474Z"
    }
  ],
  "similarProperties": [
    {
      "id": 120,
      "name": "Status Quo",
      "mainPhotoThumbnail400x400": toAbsoluteUrl("media/placeholders/redd-tower/dummy-data-status-quo.webp"),
      "market": 1,
      "addressCity": "Warszawa",
      "addressZipcode": "01-208",
      "neighborhood": "Czyste",
      "addressStreet": "Przyokopowa 26"
    },
    {
      "id": 2258,
      "name": "Michael Scott Company",
      "mainPhotoThumbnail400x400": toAbsoluteUrl("media/placeholders/redd-tower/dummy-data-michael-company.webp"),
      "market": 1,
      "addressCity": "Warszawa",
      "addressZipcode": "00-839",
      "neighborhood": "Mirów",
      "addressStreet": "Towarowa 22"
    },
    {
      "id": 436,
      "name": "Empire Copy Paste Building",
      "mainPhotoThumbnail400x400": null,
      "market": 1,
      "addressCity": "Warszawa",
      "addressZipcode": "00-105",
      "neighborhood": "Mirów",
      "addressStreet": "Towarowa 28"
    },
    {
      "id": 848,
      "name": "Old Order Hub",
      "mainPhotoThumbnail400x400": toAbsoluteUrl("media/placeholders/redd-tower/dummy-data-old-order.webp"),
      "market": 1,
      "addressCity": "Warszawa",
      "addressZipcode": "01-211",
      "neighborhood": "Czyste",
      "addressStreet": "Kasprzaka 4"
    },
    {
      "id": 119,
      "name": "Excel Tower",
      "mainPhotoThumbnail400x400": toAbsoluteUrl("media/placeholders/redd-tower/dummy-data-excel.webp"),
      "market": 1,
      "addressCity": "Warszawa",
      "addressZipcode": "00-838",
      "neighborhood": "Czyste",
      "addressStreet": "Prosta 68"
    },
    {
      "id": 713,
      "name": "No friends building ",
      "mainPhotoThumbnail400x400": null,
      "market": 1,
      "addressCity": "Warszawa",
      "addressZipcode": "00-844",
      "neighborhood": "Mirów",
      "addressStreet": "Plac Europejski 2"
    },
    {
      "id": 438,
      "name": "PowerPoint only building",
      "mainPhotoThumbnail400x400": null,
      "market": 1,
      "addressCity": "Warszawa",
      "addressZipcode": "00-105",
      "neighborhood": "Czyste",
      "addressStreet": "Prosta 36"
    },
    {
      "id": 847,
      "name": "JanuszPol HQ",
      "mainPhotoThumbnail400x400": null,
      "market": 1,
      "addressCity": "Warszawa",
      "addressZipcode": "01-211",
      "neighborhood": "Czyste",
      "addressStreet": "Kasprzaka 6"
    },
    {
      "id": 93,
      "name": "Szwagier corporate center",
      "mainPhotoThumbnail400x400": null,
      "market": 1,
      "addressCity": "Warszawa",
      "addressZipcode": "00-844",
      "neighborhood": "Mirów",
      "addressStreet": "Plac Europejski 6"
    },
    {
      "id": 1858,
      "name": "It could have been an email building",
      "mainPhotoThumbnail400x400": null,
      "market": 1,
      "addressCity": "Warszawa",
      "addressZipcode": "00-844",
      "neighborhood": "Mirów",
      "addressStreet": "Grzybowska 78"
    }
  ],
  "owner": {
    "ownerLogo": toAbsoluteUrl("media/branding/redd-logo-neon-200px.png"),
    "ownerShortName": "REDD",
    "ownerName": "REDD Group PSA"
  },
  "leasingContacts": {
    "landlordName": "REDD",
    "landlordLogo": toAbsoluteUrl("media/branding/redd-logo-neon-200px.png"),
    "landlordShortName": "REDD",
    "landlordContactPersons": [
      {
        "name": "Edyta Kowal",
        "email": "edyta.kowal@reddplatform.com",
        "phone": "+48 602 684 731"
      },
      {
        "name": "Magdalena Mróz",
        "email": "magdalena.mroz@reddplatform.com",
        "phone": "+48 668 379 420"
      }
    ],
    "isEditable": false
  },
  "photos": [
    {
      "id": 16108,
      "image": toAbsoluteUrl("media/placeholders/redd-tower/dummy-data-redd-tower.webp"),
      "contentType": 10,
      "objectId": 3026,
      "imageThumbnail": toAbsoluteUrl("media/placeholders/redd-tower/dummy-data-redd-tower.webp"),
      "mainPhotoThumbnail": toAbsoluteUrl("media/placeholders/redd-tower/dummy-data-redd-tower.webp"),
    }
  ],
  "certificates": {
    "dgnbCertification": true,
    "hqeCertification": true,
    "wellCertification": true,
    "breeamCertification": true,
    "leedCertification": true,
    "withoutBarrierCertification": true
  },
  "competitors": [
    {
      "id": 1688,
      "name": "REDD Tower",
      "imageThumbnail": toAbsoluteUrl("media/placeholders/redd-tower/dummy-data-redd-tower.webp"),
      "addressStreet": "Rondo Daszyńskiego 12",
      "addressCity": "Warszawa",
      "areaAvailable": 9870,
      "transactions6m": 23953,
      "supplyBoost6m": 7213,
      "rent": "24.50",
      "timeToLease": 127.0
    },
    {
      "id": 1099,
      "name": "Status Quo",
      "imageThumbnail": toAbsoluteUrl("media/placeholders/redd-tower/dummy-data-status-quo.webp"),
      "addressStreet": "Powstańców Śląskich 7A",
      "addressCity": "Warszawa",
      "areaAvailable": 8125,
      "transactions6m": 1500,
      "supplyBoost6m": 176,
      "rent": "24.00",
      "timeToLease": 1231.0
    },
    {
      "id": 1100,
      "name": "Michael Scott Company",
      "imageThumbnail": toAbsoluteUrl("media/placeholders/redd-tower/dummy-data-michael-company.webp"),
      "addressStreet": "Przyokopowa 26",
      "addressCity": "Warszawa",
      "areaAvailable": 562,
      "transactions6m": 862,
      "supplyBoost6m": 1245,
      "rent": "27.50",
      "timeToLease": 982
    },
    {
      "id": 1042,
      "name": "Empire Copy Paste Building",
      "imageThumbnail": toAbsoluteUrl("media/placeholders/redd-tower/dummy-data-copy.webp"),
      "addressStreet": "Towarowa 28",
      "addressCity": "Warszawa",
      "areaAvailable": 11230,
      "transactions6m": 6021,
      "supplyBoost6m": 17251,
      "rent": "28.00",
      "timeToLease": 756.0
    },
    {
      "id": 1068,
      "name": "Old Order Hub",
      "imageThumbnail": toAbsoluteUrl("media/placeholders/redd-tower/dummy-data-old-order.webp"),
      "addressStreet": "Kasprzaka 4",
      "addressCity": "Warszawa",
      "areaAvailable": 862,
      "transactions6m": 962,
      "supplyBoost6m": 135,
      "rent": "21.75",
      "timeToLease": 756.0
    },
    {
      "id": 1502,
      "name": "Excel Tower",
      "imageThumbnail": toAbsoluteUrl("media/placeholders/redd-tower/dummy-data-excel.webp"),
      "addressStreet": "Prosta 68",
      "addressCity": "Warszawa",
      "areaAvailable": 9233,
      "transactions6m": 2927,
      "supplyBoost6m": 1762,
      "rent": "24.00",
      "timeToLease": 238.0
    },
    {
      "id": 1150,
      "name": "No friends building ",
      "imageThumbnail": null,
      "addressStreet": "Plac Europejski 2",
      "addressCity": "Warszawa",
      "areaAvailable": 1502,
      "transactions6m": 823,
      "supplyBoost6m": 297,
      "rent": "22.00",
      "timeToLease": 563.0
    },
    {
      "id": 2590,
      "name": "PowerPoint only building",
      "imageThumbnail": null,
      "addressStreet": "Prosta 36",
      "addressCity": "Warszawa",
      "areaAvailable": 3111,
      "transactions6m": 4750,
      "supplyBoost6m": 2116,
      "rent": "22.00",
      "timeToLease": 495.0
    },
    {
      "id": 1043,
      "name": "JanuszPol HQ",
      "imageThumbnail": null,
      "addressStreet": "Kasprzaka 6",
      "addressCity": "Warszawa",
      "areaAvailable": 3657,
      "transactions6m": 2231,
      "supplyBoost6m": 1578,
      "rent": "23.00",
      "timeToLease": 721.0
    },
    {
      "id": 1050,
      "name": "Szwagier corporate center",
      "imageThumbnail": null,
      "addressStreet": "Plac Europejski 6",
      "addressCity": "Warszawa",
      "areaAvailable": 16983,
      "transactions6m": 8562,
      "supplyBoost6m": null,
      "rent": "23.00",
      "timeToLease": 346.0
    },
    {
      "id": 1095,
      "name": "IT could have been an email building",
      "imageThumbnail": null,
      "addressStreet": "Grzybowska 78",
      "addressCity": "Warszawa",
      "areaAvailable": 39,
      "transactions6m": 11245,
      "supplyBoost6m": 0,
      "rent": "24.00",
      "timeToLease": 221.0
    }
  ],
  "marketComparison": [
    {
      "key": "vacancy_rate",
      "obj": 20.79,
      "competitors": 24.21,
      "marketWithClass": 11.21,
      "market": 14.21
    },
    {
      "key": "time_to_lease",
      "obj": 127.0,
      "competitors": 475.6111111111111,
      "marketWithClass": 508.97058823529414,
      "market": 259.2135922330098
    },
    {
      "key": "rent",
      "obj": 24.50,
      "competitors": 23.93,
      "marketWithClass": 16.566875,
      "market": 14.667857142857143
    },
    {
      "key": "area_available",
      "obj": 9879,
      "competitors": 42320,
      "marketWithClass": 493807,
      "market": 706788
    },
    {
      "key": "area_available_future",
      "obj": 0,
      "competitors": 12984,
      "marketWithClass": 160156,
      "market": 186651
    },
    {
      "key": "transactions_6m",
      "obj": 23953,
      "competitors": 39883,
      "marketWithClass": 170346,
      "market": 255973
    },
    {
      "key": "transactions_12m",
      "obj": 33953,
      "competitors": 49283,
      "marketWithClass": 230316,
      "market": 375273
    },
    {
      "key": "transactions_24m",
      "obj": 34152,
      "competitors": 61222,
      "marketWithClass": 270316,
      "market": 395273
    },
    {
      "key": "supply_boost_6m",
      "obj": 7213,
      "competitors": 24560,
      "marketWithClass": 49163,
      "market": 73387
    },
    {
      "key": "supply_boost_12m",
      "obj": 7213,
      "competitors": 21530,
      "marketWithClass": 62103,
      "market": 98712
    },
    {
      "key": "supply_boost_24m",
      "obj": 2154,
      "competitors": 35370,
      "marketWithClass": 91603,
      "market": 123412
    },
    {
      "key": "transactions_count_6m",
      "obj": 8,
      "competitors": 51,
      "marketWithClass": 324,
      "market": 770
    },
    {
      "key": "transactions_count_12m",
      "obj": 11,
      "competitors": 51,
      "marketWithClass": 432,
      "market": 970
    },
    {
      "key": "transactions_count_24m",
      "obj": 12,
      "competitors": 55,
      "marketWithClass": 532,
      "market": 992
    },
    {
      "key": "marketplace_leads_6m",
      "obj": 23,
      "competitors": 61,
      "marketWithClass": 2336,
      "market": 4103
    },
    {
      "key": "marketplace_leads_12m",
      "obj": 32,
      "competitors": 96,
      "marketWithClass": 3612,
      "market": 6103
    },
    {
      "key": "marketplace_leads_24m",
      "obj": 92,
      "competitors": 126,
      "marketWithClass": 4316,
      "market": 9132
    },
    {
      "key": "marketplace_views_6m",
      "obj": 1273,
      "competitors": 2316,
      "marketWithClass": 80954,
      "market": 109857
    },
    {
      "key": "marketplace_views_12m",
      "obj": 2373,
      "competitors": 3162,
      "marketWithClass": 120954,
      "market": 219857
    },
    {
      "key": "marketplace_views_24m",
      "obj": 3731,
      "competitors": 6212,
      "marketWithClass": 195412,
      "market": 298571
    },
    {
      "key": "marketplace_agent_interest_6m",
      "obj": 615,
      "competitors": 952,
      "marketWithClass": 4523,
      "market": 4852
    },
    {
      "key": "marketplace_agent_interest_12m",
      "obj": 775,
      "competitors": 1522,
      "marketWithClass": 6523,
      "market": 6952
    },
    {
      "key": "marketplace_agent_interest_24m",
      "obj": 985,
      "competitors": 1712,
      "marketWithClass": 8513,
      "market": 9122
    }
  ]
}

export default data;
import {connect} from "react-redux";
import LeadsManagement from "./LeadsManagement";
import {fetchLeads} from "../../../crud/leadsManagement.crud";

const mapStateToProps = ({ user, leadsManagement }) => ({
  user: user.data,
  leads: leadsManagement?.leads,
  leadsLoading: leadsManagement?.loading,
  itemsPerPage: leadsManagement?.itemsPerPage,
  itemsCount: leadsManagement?.itemsCount,
  totalItems: leadsManagement?.total
});
const mapDispatchToProps = {
  fetchLeads: ({params}) => fetchLeads(params)
};

const withRedux = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withRedux(LeadsManagement);

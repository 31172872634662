// FETCH
export const fetchMarketplaceLeads = (target, params) => ({
  type: "FETCH_MARKETPLACE_LEADS_REQUEST",
  payload: {
    target,
    params,
  },
});
export const fetchMarketplaceLead = (leadID) => ({
  type: "FETCH_MARKETPLACE_LEAD_REQUEST",
  payload: {leadID},
});
export const fetchMarketplaceLeadsCompanies = (target, params) => ({
  type: "FETCH_MARKETPLACE_LEADS_COMPANIES_REQUEST",
  payload: {
    target,
    params,
  },
});
export const fetchMarketplaceLeadsCsv = (target, team) => ({
  type: "FETCH_MARKETPLACE_LEADS_CSV_REQUEST",
  payload: {
    target,
    team
  },
});
export const fetchMarketplaceLeadsCompaniesXls = (target, team) => ({
  type: "FETCH_MARKETPLACE_LEADS_COMPANIES_XLS_REQUEST",
  payload: {
    target,
    team
  },
});
export const sendMarketplaceLeadUnlockNotification = (leadID) => ({
  type: "SEND_MARKETPLACE_LEADS_UNLOCK_NOTIFICATION_REQUEST",
  payload: {leadID},
});
export const sendMarketplaceLeadUnlockVisitorsNotification = () => ({
  type: "SEND_MARKETPLACE_LEADS_UNLOCK_VISITORS_NOTIFICATION_REQUEST"
});
export const changeLeadStatus = (leadID, status) => ({
  type: "CHANGE_LEAD_STATUS_REQUEST",
  payload: {leadID, status},
});

// RESET FINNE LEADS LOADING STATES
export const resetFinneLeadsLoadingStates = () => ({
  type: "RESET_FINNE_LEADS_LOADING_STATES_REQUEST"
});
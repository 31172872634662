import React from "react";
import {Typography} from "@material-ui/core";
import IconColorMUI from "app/partials/components/IconColorMUI";
import styles from "./index.module.scss";
import {toAbsoluteUrl} from "_metronic";
import LoadingScreen from "app/partials/components/LoadingScreen";
import {handleEmptyValue, toDateFormat} from "app/utils/helpers";
import {HEAD_ROWS, TABLE_CELLS} from "app/partials/components/TableProperties";
import HighlightLabel from "app/partials/components/HighlightLabel";
import {FormattedMessage, injectIntl} from "react-intl";
import TableDrawer from "app/partials/components/TableDrawer";
import cn from "classnames";
import {isFiltersActive} from "../../../../partials/components/FiltersManageBar/constants";
import PropertyCardPreview from "../../../../partials/components/_DataParts/PropertyCardPreview";
import {ROUTES} from "../../../../constants";
import connect from "react-redux/es/connect/connect";
import {callReddClickEvent} from "app/crud/user.crud";
import APPLICATION_VIEWS from "../../../../constants/APPLICATION_VIEWS";

class ShortlistsLandlordList extends React.Component {
  render() {
    const {
      data,
      landlordShortlistUnits,
      landlordShortlistUnitsLoading,
      fetchShortlistUnitsLandlord,
      fetchShortlistUnitsLandlordReset,
      target,
      currentApplicationView
    } = this.props;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    const isIndustrialView = currentApplicationView === APPLICATION_VIEWS.INDUSTRIAL;

    if (data) {
      return (
        <div className={cn(styles.shortlistsWrapper, isFiltersActive() && styles.filtersActive)}>
          {data.map((item, index) => {
            const image = item.mainPhoto || toAbsoluteUrl("media/office-card-placeholder.jpg");
            const params = {
              officeId: item.id
            };
            return (
              <div key={index} className={styles.shortlistItem}>
                <div className={styles.itemImage}>
                  <img src={image} alt="shortlist analytics"/>
                </div>
                <div className={styles.itemContent}>
                  <Typography variant="h3">{handleEmptyValue(item.buildingName)}</Typography>
                  <div className={styles.itemDetails}>
                    <div className={styles.detail}>
                      <IconColorMUI icon="apps" color="neutral" size={30}/>
                      <div>
                        <Typography variant="h4">{handleEmptyValue(item.shortlistsCount)}</Typography>
                        <Typography variant="body1" className={styles.detailLabel}><FormattedMessage
                          id="SHORTLIST.PHRASE.NO_OF_SHORTLISTS"/></Typography>
                      </div>
                    </div>
                    <div className={styles.detail}>
                      <IconColorMUI icon="edit_calendar" color="neutral" size={30}/>
                      <div>
                        <Typography variant="h4">{toDateFormat(item.latestShortlist)}</Typography>
                        <Typography variant="body1" className={styles.detailLabel}><FormattedMessage
                          id="SHORTLIST.PHRASE.LAST_SHORTLIST_ADDED"/></Typography>
                      </div>
                    </div>
                  </div>
                  <div className={styles.itemActions}>
                    <TableDrawer
                      title={
                        <div className={styles.drawerTitleWrapper}>
                          <PropertyCardPreview
                            title={item.buildingName}
                            semiTitle={item.addressStreet}
                            thumb={item.mainPhotoThumbnail}
                            propertyID={item.id}
                            marketID={target !== "industrial" && item.marketId}
                            route={target === "industrial" ? ROUTES.INDUSTRIAL_PARK : ROUTES.BUILDING_PAGE}
                          />
                          <Typography variant="h3" className={styles.drawerSubtitle}><FormattedMessage
                            id="SHORTLIST.PHRASE.SHORTLISTS_COUNT"
                            values={{count: handleEmptyValue(item.shortlistsCount, true)}}/></Typography>
                        </div>
                      }
                      headRows={HEAD_ROWS.SHORTLIST_LANDLORD_UNITS}
                      bodyRows={landlordShortlistUnits}
                      bodyRowsReady={landlordShortlistUnitsLoading}
                      target={TABLE_CELLS.SHORTLIST_LANDLORD_UNITS}
                      parentId={params}
                      parentPreviewData={item}
                      isEditable={true}
                      onClick={fetchShortlistUnitsLandlord}
                      onClose={fetchShortlistUnitsLandlordReset}
                      onOpen={() => {
                        isOfficeView && this.props.dispatch(callReddClickEvent(
                          "offers-analysis-details",
                          "office",
                          null,
                          item?.id
                        ));
                        isIndustrialView && this.props.dispatch(callReddClickEvent(
                          "offers-industrial-analysis-details",
                          "park",
                          null,
                          item?.id
                        ));
                      }}
                    >
                      <HighlightLabel color="success" hover><FormattedMessage
                        id="GENERAL.PHRASE.CHECK_DETAILS"/></HighlightLabel>
                    </TableDrawer>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
    return <LoadingScreen/>;
  }
}

const mapStateToProps = (store) => ({
  currentApplicationView: store.user?.data?.currentApplicationView,
});

export default injectIntl(
  connect(mapStateToProps)(ShortlistsLandlordList)
);
import React from "react";
import {Grid, Icon, Typography} from "@material-ui/core";
import styles from "./index.module.scss";

export default class IconLabel extends React.Component {
  render() {
    const {icon, label, value, valueVariant = "h4", style, size = 24, color = "#a2a5b9", colorValue, colorIcon, direction = "row", spacing = 1} = this.props;
    if(direction === "column") {
        return(
          <Grid container alignItems="center" spacing={spacing} wrap="nowrap" direction="column" style={style}>
              <Grid item xs={12}>
                <Typography variant={valueVariant} className={colorValue && styles[colorValue]}>{value}</Typography>
              </Grid>
              <Grid item xs={12}>
                <span style={{color: color}} className={colorIcon && styles[colorIcon]}>
                    <Icon style={{fontSize: size}}>{icon}</Icon>
                </span>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">{label}</Typography>
              </Grid>
          </Grid>
        );
    }
    return(
      <Grid container alignItems="center" spacing={spacing} wrap="nowrap" direction={direction} style={style}>
        <Grid item>
          <span style={{color: color}} className={colorIcon && styles[colorIcon]}>
            <Icon style={{fontSize: size}}>{icon}</Icon>
          </span>
        </Grid>
        <Grid item>
          <Typography variant={valueVariant} className={colorValue && styles[colorValue]}>{value}</Typography>
          <Typography variant="body2">{label}</Typography>
        </Grid>
      </Grid>
    );
  }
}
import {handleActions} from 'redux-actions';
import {createNotification, destroyNotification} from 'app/constants/actionCreators/notifications';

const initialState = {
  message: null,
  isOpen: false,
  timeout: 3000,
  type: null,
};

export const notifications = handleActions(
  {
    [createNotification]: (state, action) => ({
      ...state,
      isOpen: true,
      ...action.payload,
    }),
    [destroyNotification]: state => ({
      ...state,
      message: null,
      type: null,
      isOpen: false,
    }),
  },
  initialState
);

import React from "react";
import {Grid, Typography} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import InputAdornments from "app/partials/components/InputAdornments";
import _ from "lodash";
import {updateTeamMemberPhone} from "../../../../../crud/team.crud";

class EditTeamMemberBasics extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      phone: props.data?.user?.phoneNumber || ''
    };
    this.initialState = this.state;
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    // Handle Change Detect
    this.props.onUnsavedData(!_.isEqual(this.initialState, this.state));
    // Handle Save
    if(!prevProps.requestSave && this.props.requestSave) {
      this.handleSave();
    }
    // Detect Office Updating
    if(prevProps.updatingTeamMember !== this.props.updatingTeamMember) {
      this.props.onRequestSaving(this.props.updatingTeamMember);
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  handleSave = () => {
    const {updateTeamMemberPhone} = this.props;
    const {phone} = this.state;
    const memberId = this.props.data?.id;

    this.initialState = this.state;
    const data = {
      phone
    };
    if(memberId) {
      updateTeamMemberPhone(memberId, data);
    }
  };

  render() {
    const {updatingTeamMember} = this.props;
    const {phone} = this.state;

    return(
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" style={{color: "#ff4626"}}><FormattedMessage id="VOCABULARY.Basic data"/></Typography>
        </Grid>
        <Grid item xs={6}>
          <InputAdornments
            label={<FormattedMessage id="FORM.FIELD.PHONE_NUMBER"/>}
            name="phone"
            value={phone}
            onChange={this.handleChange}
            disabled={updatingTeamMember}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = store => ({
  updatingTeamMember: store.team.updatingTeamMember
});
const mapDispatchToProps = {
  updateTeamMemberPhone: (memberId, data) => updateTeamMemberPhone(memberId, data)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditTeamMemberBasics)
);
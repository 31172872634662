import React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {pdf} from "@react-pdf/renderer";
import {fetchOfferOfficesPdf} from "app/crud/offers.crud"
import PaywallModal from "app/partials/components/PayWall/components/PaywallModal";
import IsPro from "app/utils/IsPro";
import OfferPdfDocument from "./OfferPdfDocument";
import {downloadFileFromBlob} from "../../../../utils/helpers";
import {callReddClickEvent} from "../../../../crud/user.crud";
import Button from "../../../../partials/components/Button";

class GenerateOfferPdf extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initLoaded: false,
      generatingPdf: false,
      dataReady: false
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.initLoaded && !this.props.loading && this.props.data && prevProps.data !== this.props.data) {
      this.setState({
        generatingPdf: false,
        dataReady: true
      }, () => {
        this.handleDirectGeneratePdf();
      });
    }
  }

  handleOnViewportFetch = (isVisible) => {
    const {
      loading,
      fetchOfferOfficesPdf,
      offerID
    } = this.props;
    const {initLoaded} = this.state;

    if (isVisible && !initLoaded && offerID) {
      this.setState({
        initLoaded: true,
        generatingPdf: true
      }, () => {
        if (!loading) {
          fetchOfferOfficesPdf(offerID);
        }
      });
    }
  }
  handleDirectGeneratePdf = () => {
    this.setState({
      generatingPdf: true
    }, async () => {
      const offerID = this.props.offerID;
      const offerName = this.props.data?.name;
      const offerFileName = [
        ...offerName ? [offerName?.replaceAll(".", "_")] : ['Offer'],
        ' - ',
        'REDD',
        '.pdf'
      ].join("");
      const asPdf = pdf(<OfferPdfDocument {...this.props} />);
      const blob = await asPdf.toBlob();
      setTimeout(() => {
        this.setState({generatingPdf: false}, () =>
          this.props.dispatch(callReddClickEvent(
            "offers-v2-download-pdf",
            "offer",
            null,
            offerID
          ))
        );
        downloadFileFromBlob(blob, offerFileName);
      }, 1000);
    });
  }

  render() {
    const {
      intl,
      triggerBtn,
      inactive
    } = this.props;
    const {generatingPdf, dataReady} = this.state;

    return (
      <IsPro
        paywall
        paywallComponent={
          <PaywallModal
            triggerBtn={
              triggerBtn ? triggerBtn :
                <Button inactive={inactive}>
                  <FormattedMessage id="VOCABULARY.Download PDF"/>
                </Button>
            }
            triggerButtonVariant={intl.formatMessage({id: "VOCABULARY.Download PDF"})}
          />
        }
      >
        <Button
          inactive={inactive}
          loading={generatingPdf}
          tooltip={<FormattedMessage id="VOCABULARY.Add units to generate an offer"/>}
          tooltipInactive={!inactive}
          onClick={() => {
            if (dataReady) {
              this.handleDirectGeneratePdf();
            } else {
              this.handleOnViewportFetch(true)
            }
          }}
        >
          <FormattedMessage id="VOCABULARY.Download PDF"/>
        </Button>
      </IsPro>
    );
  }
}

const mapStateToProps = (store) => ({
  data: store.shortlists.offerPdf,
  loading: store.shortlists.offerPdfLoading
});
const mapDispatchToProps = dispatch => ({
  fetchOfferOfficesPdf: (offerID) => dispatch(fetchOfferOfficesPdf(offerID)),
  dispatch
});
export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(GenerateOfferPdf)
);


import React from "react";
import {Portlet, PortletBody, PortletHeader} from "app/partials/content/Portlet";
import {TextField, Typography} from "@material-ui/core";
import clsx from "clsx";
import {FormattedMessage, injectIntl} from "react-intl";
import styles from "../index.module.scss";
import LoadingScreen from "../../../../partials/components/LoadingScreen";
import {InputUpload} from "../../../../partials/components/InputUpload";
import _ from "lodash";
import {callReddClickEvent} from "app/crud/user.crud";
import connect from "react-redux/es/connect/connect";

const primaryColor = "#ff4626";
const secondaryColor = "#ffffff";
const initofferBgColor = primaryColor;
const initofferBgFontColor = secondaryColor;
const initofferFontColor = primaryColor;
const initofferBtnBgColor = primaryColor;
const initofferBtnBgFontColor = secondaryColor;

class UserPersonalizeOffers extends React.Component {
  constructor(props) {
    super(props);
    const initialStyles = {
      offerBgColor: (props.data && props.data.offerBgColor) || initofferBgColor,
      offerBgFontColor: (props.data && props.data.offerBgFontColor) || initofferBgFontColor,
      offerFontColor: (props.data && props.data.offerFontColor) || initofferFontColor,
      offerBtnBgColor: (props.data && props.data.offerBtnBgColor) || initofferBtnBgColor,
      offerBtnBgFontColor: (props.data && props.data.offerBtnBgFontColor) || initofferBtnBgFontColor
    };
    this.state = {
      ...initialStyles,
      logo: props.data && props.data.offerCompanyLogo,
      logoFile: null,
      initStyles: initialStyles,
      isReady: false
    };
    const {onFetch, teamID} = props;
    onFetch && onFetch(teamID);
  }
  componentDidMount() {
    if(!this.state.isReady) {
      this.setState({
        isReady: true
      }, () => this.props.dispatch(callReddClickEvent(
        "user-customize-offers-online-view",
        "user"
      )));
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {data} = this.props;
    if(
        data && !_.isEqual(_.omit(prevProps.data, ['offerCompanyLogo']), _.omit(data, ['offerCompanyLogo']))
    ) {
      this.setState({
        offerBgColor: data?.offerBgColor || initofferBgColor,
        offerBgFontColor: data?.offerBgFontColor || initofferBgFontColor,
        offerFontColor: data?.offerFontColor || initofferFontColor,
        offerBtnBgColor: data?.offerBtnBgColor || initofferBtnBgColor,
        offerBtnBgFontColor: data?.offerBtnBgFontColor || initofferBtnBgFontColor
      });
    }
    if(data && !_.isEqual(this.state.logo, data?.offerCompanyLogo)) {
      this.setState({
        logo: this.props.data?.offerCompanyLogo
      });
    }
  }

  handleSave = () => {
    const {onUpdate, teamID} = this.props;
    const {
      offerBgColor,
      offerBgFontColor,
      offerFontColor,
      offerBtnBgColor,
      offerBtnBgFontColor,
      initStyles
    } = this.state;
    const data = {
      offerBgColor,
      offerBgFontColor,
      offerFontColor,
      offerBtnBgColor,
      offerBtnBgFontColor
    };
    if(!_.isEqual(_.omit(this.state, ['logo', 'logoFile', 'initStyles']), initStyles)) {
      onUpdate(teamID, data);
    }
  };
  handleUpdateLogo = () => {
    const {onUpdateLogo} = this.props;
    const {logoFile} = this.state;

    logoFile && onUpdateLogo(logoFile);
  };
  handleRestoreDefault = () => {
    const {onUpdate, teamID} = this.props;
    this.setState({
      offerBgColor: initofferBgColor,
      offerBgFontColor: initofferBgFontColor,
      offerFontColor: initofferFontColor,
      offerBtnBgColor: initofferBtnBgColor,
      offerBtnBgFontColor: initofferBtnBgFontColor,
    }, () => {
      const {
        offerBgColor,
        offerBgFontColor,
        offerFontColor,
        offerBtnBgColor,
        offerBtnBgFontColor
      } = this.state;
      const data = {
        offerBgColor,
        offerBgFontColor,
        offerFontColor,
        offerBtnBgColor,
        offerBtnBgFontColor
      };
      onUpdate(teamID, data);
    });
  };
  handleRemoveLogo = () => {
    const {onUpdateLogo} = this.props;
    this.setState({
      logo: null,
      logoFile: null
    }, () => {
      onUpdateLogo(null);
    });
  }
  handleColorPicker = e => {
    const selectType = e.target.type;
    const selectedColor = e.target.value;
    const selectedInputColor = e.target.id;
    const selectedInputText = e.target.name;
    if(selectType === "color") {
      this.setState({
        [selectedInputColor]: selectedColor
      });
    } else if(selectType === "text") {
      this.setState({
        [selectedInputText]: selectedColor
      });
    }
  };
  handleLogoUpload = logoFile => {
    this.setState({
      logoFile
    });
  };

  render() {
    const {loading, updatingLogo} = this.props;
    const {
      offerBgColor,
      offerBgFontColor,
      offerFontColor,
      offerBtnBgColor,
      offerBtnBgFontColor,
      logo
    } = this.state;

    return(
      <Portlet fluidHeight>
        <PortletHeader title={<Typography variant="h4"><FormattedMessage id="OFFERS.PERSONALIZE.TITLE"/></Typography>}/>
        <PortletBody>
          {loading ? <LoadingScreen/> :
              <>
                <Typography variant="h4" style={{marginBottom: 35}}><FormattedMessage
                    id="OFFERS.PERSONALIZE.INFO"/></Typography>
                <Typography variant="h4"><FormattedMessage id="OFFERS.PERSONALIZE.BRANDING_COLORS"/></Typography>
                <div className={clsx("form-group", styles.inputGroupPdfStyles)}>
                  <div className={styles.inputPdfPickerContainer}>
                    <TextField
                        type="text"
                        margin="normal"
                        label={<FormattedMessage id="OFFERS.PERSONALIZE.BRAND_BG_COLOR"/>}
                        name="offerBgColor"
                        value={offerBgColor}
                        onChange={this.handleColorPicker}
                    />
                    <input type="color" value={offerBgColor} id="offerBgColor" className={styles.inputPdfColorPicker}
                           onChange={this.handleColorPicker}/>
                  </div>
                  <div className={styles.inputPdfPickerContainer}>
                    <TextField
                        type="text"
                        margin="normal"
                        label={<FormattedMessage id="OFFERS.PERSONALIZE.BRAND_BG_FONT_COLOR"/>}
                        name="offerBgFontColor"
                        value={offerBgFontColor}
                        onChange={this.handleColorPicker}
                    />
                    <input type="color" value={offerBgFontColor} id="offerBgFontColor"
                           className={styles.inputPdfColorPicker} onChange={this.handleColorPicker}/>
                  </div>
                  <div className={styles.inputPdfPickerContainer}>
                    <TextField
                        type="text"
                        margin="normal"
                        label={<FormattedMessage id="OFFERS.PERSONALIZE.BRAND_FONT_COLOR"/>}
                        name="offerFontColor"
                        value={offerFontColor}
                        onChange={this.handleColorPicker}
                    />
                    <input type="color" value={offerFontColor} id="offerFontColor"
                           className={styles.inputPdfColorPicker} onChange={this.handleColorPicker}/>
                  </div>
                </div>
                <Typography variant="h4"><FormattedMessage
                    id="OFFERS.PERSONALIZE.BRANDING_BUTTONS_COLORS"/></Typography>
                <div className={clsx("form-group", styles.inputGroupPdfStyles)}>
                  <div className={styles.inputPdfPickerContainer}>
                    <TextField
                        type="text"
                        margin="normal"
                        label={<FormattedMessage id="OFFERS.PERSONALIZE.BRAND_BUTTON_BG_COLOR"/>}
                        name="offerBtnBgColor"
                        value={offerBtnBgColor}
                        onChange={this.handleColorPicker}
                    />
                    <input type="color" value={offerBtnBgColor} id="offerBtnBgColor"
                           className={styles.inputPdfColorPicker} onChange={this.handleColorPicker}/>
                  </div>
                  <div className={styles.inputPdfPickerContainer}>
                    <TextField
                        type="text"
                        margin="normal"
                        label={<FormattedMessage id="OFFERS.PERSONALIZE.BRAND_BUTTON_BG_FONT_COLOR"/>}
                        name="offerBtnBgFontColor"
                        value={offerBtnBgFontColor}
                        onChange={this.handleColorPicker}
                    />
                    <input type="color" value={offerBtnBgFontColor} id="offerBtnBgFontColor"
                           className={styles.inputPdfColorPicker} onChange={this.handleColorPicker}/>
                  </div>
                </div>
                <Typography variant="h4" style={{marginBottom: 15}}><FormattedMessage
                    id="PROPERTY_PARTICULARS.PHRASE.UPLOAD_COMPANY_LOGO"/></Typography>
              </>
          }
          {updatingLogo ? <LoadingScreen/> :
          <>
          <div className={clsx("form-group")}>
            <div>
              <InputUpload
                currentFile={logo}
                onFileUpload={this.handleLogoUpload}
                width="100%"
                height="100%"
                noFrame
                responsiveBox
                fileTarget="logo"
              />
            </div>
          </div>
          </>
          }
          <div className="kt-login__actions">
            <button
              type="button"
              className="btn btn-primary btn-elevate kt-login__btn-primary"
              onClick={this.handleSave}
              style={{marginRight: 10}}
            >
              {<FormattedMessage id="GENERAL.PHRASE.SAVE_STYLES"/>}
            </button>
            <button
              type="button"
              className="btn btn-primary btn-elevate kt-login__btn-primary"
              onClick={this.handleUpdateLogo}
              style={{marginRight: 10}}
            >
              {<FormattedMessage id="GENERAL.PHRASE.SAVE_LOGOTYPE"/>}
            </button>
            <button
              type="button"
              className="btn btn-secondary btn-elevate kt-login__btn-primary"
              onClick={this.handleRestoreDefault}
              style={{marginRight: 10}}
            >
              {<FormattedMessage id="GENERAL.PHRASE.RESET_STYLES"/>}
            </button>
            <button
              type="button"
              className="btn btn-secondary btn-elevate kt-login__btn-primary"
              onClick={this.handleRemoveLogo}
            >
              {<FormattedMessage id="GENERAL.PHRASE.RESET_LOGOTYPE"/>}
            </button>
          </div>
        </PortletBody>
      </Portlet>
    );
  }
}

export default injectIntl(connect()(UserPersonalizeOffers));
import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import AcquisitionsPage from "./AcquisitionsPage";
import {fetchAcquisitions} from "app/crud/estates/offices/acquisitions.crud";
import {setLatestActiveTablePage} from "../../../crud/tablePage.crud";
import {filterReset} from "../../../crud/estates/filters.crud";
import {fetchVisibleOwners} from "../../../crud/user.crud";
import {callReddClickEvent} from "app/crud/user.crud";

const mapStateToProps = (state) => ({
  items: state.acquisitions.items,
  itemsPerPage: state.acquisitions.itemsPerPage,
  itemsCount: state.acquisitions.itemsCount,
  totalItems: state.acquisitions.totalItems,
  itemsPage: state.acquisitions.itemsPage,
  loading: state.acquisitions.loading,
  loaded: state.acquisitions.loaded
});
const mapDispatchToProps = {
  fetchAcquisitions: ({params}) => fetchAcquisitions(params),
  fetchVisibleOwners: () => fetchVisibleOwners(),
  filterReset: (filtersToOmit, currentApplicationView, fullReset) => filterReset(filtersToOmit, currentApplicationView, fullReset),
  setLatestActiveTablePage: (page) => setLatestActiveTablePage(page),
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AcquisitionsPage)
);

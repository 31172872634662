import {fetchOffersOffices} from "app/crud/offers.crud";
import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import ShortlistsPage from "./OffersPage";
import {callReddClickEvent} from "app/crud/user.crud";

const mapStateToProps = (state) => ({
  shortlists: state.shortlists,
  items: state.shortlists.items,
  loaded: state.shortlists.loaded,
  loading: state.shortlists.loading,
  isTeamOwner: state.user.data.isTeamOwner,
  user: state.user?.data
});
const mapDispatchToProps = {
  fetchOffersOffices: ({params}) => fetchOffersOffices(params),
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ShortlistsPage)
);

const regions = [
  {
    regionCode: "PL",
    domain: "finne.pl"
  },
  {
    regionCode: "CS",
    domain: "finne.cz"
  },
  {
    regionCode: "SK",
    domain: "finne.sk"
  },
  {
    regionCode: "HU",
    domain: "finne.hu"
  },
  {
    regionCode: "RO",
    domain: "finne.com.ro"
  }
]

export default regions;
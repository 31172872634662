import React from "react";
import {toAbsoluteUrl} from "../../../../_metronic";
import {Typography} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import "./index.module.scss";
import Button from "../Button";

export default class Page404 extends React.Component {
  render() {
    const {onBack, message} = this.props;

    return (
      <div className="page404Container">
        <img src={toAbsoluteUrl("media/branding/redd-logo-neon-200px.png")} alt="REDD"/>

        <div className="page404ContentWrapper">
          <Typography variant="h3">404</Typography>
          <Typography variant="h2">
            <FormattedMessage
              id={message ? message : "The page you are looking for does not exist."}
            />
          </Typography>
          <Button
            redirect={!onBack ? "/" : undefined}
            redirectTarget={!onBack ? "_blank" : undefined}
            onClick={onBack}
          >
            <FormattedMessage id={onBack ? "PROPERTY_PARTICULARS.PHRASE.BACK_TO_LIST" : "Go to REDD"}/>
          </Button>
        </div>
      </div>
    );
  }
}
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {put, takeLatest} from "redux-saga/effects";
import axios from "axios";
import reverse from "lodash/reverse";
import slice from "lodash/slice";
import sortBy from "lodash/sortBy";
import {actionNotification} from "app/utils/notifications";
import {handleErrorResponse} from "app/utils/helpers";
import {resetFilters, setFilters} from "../../utils/filters";

const initialState = {
  items: [],
  itemsPerPage: 20,
  itemsCount: null,
  loaded: false,
  loading: false,
  shortlistPdf: null,
  shortlistPdfLoading: false,
  shortlistUnits: null,
  shortlistUnitsLoading: false,
  landlordItems: [],
  landlordItemsCount: null,
  landlordItemsPerPage: 20,
  landlordLoading: false,
  landlordShortlistUnits: null,
  landlordShortlistUnitsLoading: false,
  offerItems: null,
  offerOffice: null,
  offerOffice404: false,
  offerContact: null,
  offerBranding: null,
  offerBranding404: false,
  loadingOfferItems: false,
  loadingOfferOffice: false,
  loadingOfferContact: false,
  verifyingAccess: false,
  requestingAccess: false,
  hasAccess: false,
  offersAll: null,
  loadingOffersAll: false,
  offerPhotos: null,
  loadingPhotos: false,
  offerUpdated: false,
  offerUpdating: false,
  landlordShortlistBuildings: null,
  landlordShortlistBuildingsLoading: false,
  // NEW OFFERS - REFACTOR
  offer: null,
  offerLoading: false,
  offerPdf: null,
  offerPdfLoading: false,
  removingUnit: false,
  deletingOffer: false,
  offerDeleted: false
};
const handleUpdateOfferData = (currentList, updatedItem) => {
  return currentList.map(
    (obj) => [updatedItem].find((o) => o.id === obj.id) || obj
  );
};
export const reducer = persistReducer(
  { storage, key: "shortlists" },
  (state = initialState, action) => {
    switch (action.type) {
      // RESET OFFICE OFFERS LOADING STATES
      case "RESET_OFFICE_OFFERS_LOADING_STATES_REQUEST": {
        return {
          ...state,
          loading: false,
          shortlistPdfLoading: false,
          shortlistUnitsLoading: false,
          landlordLoading: false,
          landlordShortlistUnitsLoading: false,
          loadingOfferItems: false,
          loadingOfferOffice: false,
          loadingOfferContact: false,
          loadingOffersAll: false,
          loadingPhotos: false,
          landlordShortlistBuildingsLoading: false,
          removingUnit: false,
          deletingOffer: false
        };
      }
      // OFFER
      case "FETCH_OFFERS_ALL_REQUEST": {
        return { ...state, loadingOffersAll: true };
      }
      case "FETCH_OFFERS_ALL_SUCCESS": {
        return { ...state, offersAll: action.payload, loadingOffersAll: false };
      }
      case "FETCH_SHORTLISTS_LANDLORD_RESET_REQUEST": {
        return { ...state, landlordLoading: true };
      }
      case "FETCH_SHORTLISTS_LANDLORD_REQUEST": {
        return { ...state, landlordLoading: true };
      }
      case "FETCH_SHORTLISTS_LANDLORD_SUCCESS": {
        const { items, itemsCount, itemsPerPage } = action.payload;
        return {
          ...state,
          landlordItems: items,
          landlordItemsCount: itemsCount,
          landlordItemsPerPage: itemsPerPage,
          landlordLoading: false,
        };
      }
      case "FETCH_SHORTLISTS_LANDLORD_FAILED": {
        return {
          ...state,
          landlordLoading: false
        };
      }
      case "FETCH_SHORTLISTS_LANDLORD_BUILDINGS_REQUEST": {
        return { ...state, landlordShortlistBuildingsLoading: true };
      }
      case "FETCH_SHORTLISTS_LANDLORD_BUILDINGS_SUCCESS": {
        return {
          ...state,
          landlordShortlistBuildings: action.payload,
          landlordShortlistBuildingsLoading: false,
        };
      }
      case "FETCH_SHORTLISTS_LANDLORD_BUILDINGS_FAILED": {
        return {
          ...state,
          landlordShortlistBuildingsLoading: false
        };
      }
      case "FETCH_SHORTLIST_PDF_REQUEST": {
        return { ...state, shortlistPdfLoading: true };
      }
      case "FETCH_SHORTLIST_PDF_SUCCESS": {
        return {
          ...state,
          shortlistPdf: action.payload,
          shortlistPdfLoading: false,
        };
      }
      case "FETCH_SHORTLIST_UNITS_REQUEST": {
        return { ...state, shortlistUnits: null, shortlistUnitsLoading: true };
      }
      case "FETCH_SHORTLIST_UNITS_SUCCESS": {
        return {
          ...state,
          shortlistUnits: action.payload,
          shortlistUnitsLoading: false,
        };
      }
      case "FETCH_SHORTLIST_UNITS_RESET_SUCCESS": {
        return { ...state, shortlistUnits: null, shortlistUnitsLoading: false };
      }
      case "FETCH_SHORTLISTS_LANDLORD_UNITS_REQUEST": {
        return { ...state, landlordShortlistUnitsLoading: true };
      }
      case "FETCH_SHORTLISTS_LANDLORD_UNITS_SUCCESS": {
        return {
          ...state,
          landlordShortlistUnits: action.payload ? action.payload : [],
          landlordShortlistUnitsLoading: false,
        };
      }
      case "FETCH_SHORTLISTS_LANDLORD_UNITS_RESET_SUCCESS": {
        return {
          ...state,
          landlordShortlistUnits: null,
          landlordShortlistUnitsLoading: false,
        };
      }
      // OFFER ONLINE
      case "FETCH_OFFER_ONLINE_REQUEST": {
        return { ...state, loadingOfferItems: true, offerItems: null };
      }
      case "FETCH_OFFER_ONLINE_SUCCESS": {
        return {
          ...state,
          offerItems: action.payload,
          loadingOfferItems: false,
          offerPhotos: null,
        };
      }
      case "FETCH_OFFER_ONLINE_FAILED": {
        return { ...state, offerItems: null, loadingOfferItems: false };
      }
      case "FETCH_OFFER_ONLINE_OFFICE_REQUEST": {
        return {
          ...state,
          loadingOfferOffice: true,
          offerOffice: null,
          offerContact: null,
          offerOffice404: false,
        };
      }
      case "FETCH_OFFER_ONLINE_OFFICE_SUCCESS": {
        return {
          ...state,
          offerOffice: action.payload,
          loadingOfferOffice: false,
          offerContact: null,
          offerOffice404: false,
        };
      }
      case "FETCH_OFFER_ONLINE_OFFICE_FAILED": {
        return {
          ...state,
          offerOffice: null,
          loadingOfferOffice: false,
          offerContact: null,
          offerOffice404: action.payload >= 400 && action.payload <= 500,
        };
      }
      case "FETCH_OFFER_ONLINE_OFFICE_CONTACT_REQUEST": {
        return { ...state, loadingOfferContact: true, offerContact: null };
      }
      case "FETCH_OFFER_ONLINE_OFFICE_CONTACT_SUCCESS": {
        return {
          ...state,
          offerContact: action.payload,
          loadingOfferContact: false,
        };
      }
      case "FETCH_OFFER_ONLINE_OFFICE_CONTACT_FAILED": {
        return { ...state, offerContact: null, loadingOfferContact: false };
      }
      case "FETCH_OFFER_ONLINE_BRANDING_REQUEST": {
        return {
          ...state,
          loadingOfferBranding: true,
          offerBranding: null,
          offerBranding404: false,
          offerItems: null,
        };
      }
      case "FETCH_OFFER_ONLINE_BRANDING_SUCCESS": {
        return {
          ...state,
          offerBranding: action.payload,
          loadingOfferBranding: false,
          offerBranding404: false,
        };
      }
      case "FETCH_OFFER_ONLINE_BRANDING_FAILED": {
        return {
          ...state,
          offerBranding: null,
          loadingOfferBranding: false,
          offerBranding404: action.payload === 404,
        };
      }
      case "FETCH_OFFER_ONLINE_LIST_PHOTOS_REQUEST": {
        return { ...state, loadingPhotos: true, offerPhotos: null };
      }
      case "FETCH_OFFER_ONLINE_LIST_PHOTOS_SUCCESS": {
        return { ...state, loadingPhotos: false, offerPhotos: action.payload };
      }
      case "FETCH_OFFER_ONLINE_LIST_PHOTOS_FAILED": {
        return { ...state, loadingPhotos: false, offerPhotos: null };
      }
      // OFFER ACTIONS
      case "ADD_SHORTLIST_REQUEST": {
        return { ...state };
      }
      case "ADD_SHORTLIST_SUCCESS": {
        let record = action.payload;
        record["emails"] = [];
        return {
          ...state,
          items: slice(
            reverse(sortBy([...state.items, record], "id")),
            0,
            state.itemsPerPage
          ),
          itemsCount: state.itemsCount + 1,
        };
      }
      case "ADD_UNIT_TO_EXISTING_SHORTLIST_REQUEST": {
        return {
          ...state,
          offerUpdated: false,
          offerUpdating: true
        };
      }
      case "ADD_UNIT_TO_EXISTING_SHORTLIST_SUCCESS": {
        return {
          ...state,
          offerUpdated: true,
          offerUpdating: false
        };
      }
      case "ADD_UNIT_TO_EXISTING_SHORTLIST_FAILED": {
        return {
          ...state,
          offerUpdated: false,
          offerUpdating: false
        };
      }
      case "ADD_UNIT_TO_NEW_SHORTLIST_REQUEST": {
        return {
          ...state,
          offerUpdated: false,
          offerUpdating: true
        };
      }
      case "ADD_UNIT_TO_NEW_SHORTLIST_SUCCESS": {
        return {
          ...state,
          offerUpdated: true,
          offerUpdating: false
        };
      }
      case "ADD_UNIT_TO_NEW_SHORTLIST_FAILED": {
        return {
          ...state,
          offerUpdated: false,
          offerUpdating: false
        };
      }
      case "DELETE_OFFER_REQUEST": {
        return {
          ...state,
          deletingOffer: true,
          offerDeleted: false
        };
      }
      case "DELETE_OFFER_SUCCESS": {
        return {
          ...state,
          items: state.items.filter((item) => item.id !== action.payload),
          itemsCount: state.itemsCount > 0 ? state.itemsCount - 1 : null,
          deletingOffer: false,
          offerDeleted: true
        };
      }
      case "DELETE_OFFER_FAILED": {
        return {
          ...state,
          deletingOffer: false,
          offerDeleted: false
        };
      }
      case "UPDATE_OFFER_REQUEST": {
        return {
          ...state,
          offerUpdating: true
        };
      }
      case "UPDATE_OFFER_SUCCESS": {
        return {
          ...state,
          items: handleUpdateOfferData(state.items, action.payload),
          offer: action.payload,
          offerUpdating: false
        };
      }
      case "UPDATE_OFFER_FAILED": {
        return {
          ...state,
          offerUpdating: false
        };
      }
      // OFFER ONLINE ACTIONS
      case "VERIFY_OFFER_ONLINE_ACCESS_REQUEST": {
        return { ...state, verifyingAccess: true, hasAccess: false };
      }
      case "VERIFY_OFFER_ONLINE_ACCESS_SUCCESS": {
        const { hasAccess } = action.payload;
        return { ...state, hasAccess, verifyingAccess: false };
      }
      case "VERIFY_OFFER_ONLINE_ACCESS_FAILED": {
        return { ...state, hasAccess: false, verifyingAccess: false };
      }
      case "VERIFY_OFFER_ONLINE_ACCESS_RESET_REQUEST": {
        return { ...state, hasAccess: false, verifyingAccess: true };
      }
      case "VERIFY_OFFER_ONLINE_ACCESS_RESET_SUCCESS": {
        return { ...state, verifyingAccess: false };
      }
      case "REQUEST_OFFER_ONLINE_ACCESS_REQUEST": {
        return { ...state, requestingAccess: true };
      }
      case "REQUEST_OFFER_ONLINE_ACCESS_SUCCESS": {
        return { ...state, requestingAccess: false };
      }
      case "REQUEST_OFFER_ONLINE_ACCESS_FAILED": {
        return { ...state, requestingAccess: false };
      }

      // NEW OFFERS - REFACTOR
      case "FETCH_OFFERS_OFFICES_REQUEST": {
        return {
          ...state,
          items: [],
          itemsCount: null,
          loading: true
        };
      }
      case "FETCH_OFFERS_OFFICES_SUCCESS": {
        const { items, itemsCount, itemsPerPage } = action.payload;
        return {
          ...state,
          items,
          itemsCount,
          itemsPerPage,
          loading: false,
          loaded: true,
        };
      }
      case "FETCH_OFFERS_OFFICES_FAILED": {
        return {
          ...state,
          items: [],
          itemsCount: null,
          loading: false
        };
      }
      //
      case "FETCH_OFFER_OFFICES_REQUEST": {
        return {
          ...state,
          offer: null,
          offerLoading: true
        };
      }
      case "FETCH_OFFER_OFFICES_SUCCESS": {
        return {
          ...state,
          offer: action.payload,
          offerLoading: false,
        };
      }
      case "FETCH_OFFER_OFFICES_FAILED": {
        return {
          ...state,
          offer: null,
          offerLoading: false,
        };
      }
      //
      case "REMOVE_UNIT_FROM_OFFER_REQUEST": {
        return {
          ...state,
          removingUnit: true
        };
      }
      case "REMOVE_UNIT_FROM_OFFER_SUCCESS": {
        return {
          ...state,
          offer: action.payload,
          removingUnit: false,
        };
      }
      case "REMOVE_UNIT_FROM_OFFER_FAILED": {
        return {
          ...state,
          removingUnit: false,
        };
      }
      //
      case "FETCH_OFFER_OFFICES_PDF_REQUEST": {
        return {
          ...state,
          offerPdfLoading: true
        };
      }
      case "FETCH_OFFER_OFFICES_PDF_SUCCESS": {
        return {
          ...state,
          offerPdf: action.payload,
          offerPdfLoading: false,
        };
      }
      case "FETCH_OFFER_OFFICES_PDF_FAILED": {
        return {
          ...state,
          offerPdf: null,
          offerPdfLoading: false
        };
      }

      default:
        return state;
    }
  }
);

// FETCH DATA
function* fetchOffersAll() {
  try {
    const offersAll = yield axios.get(`/offers/all/`);
    yield put({
      type: "FETCH_OFFERS_ALL_SUCCESS",
      payload: offersAll.data || [{ error: offersAll.statusText }],
    });
  } catch (err) {
    console.log(err);
  }
}
function* fetchShortlistsLandlord(action) {
  try {
    const params = action.payload.params;
    const filters = setFilters(params);
    const interestedInMyBuildings = yield axios.get(`/interest-in-buildings/${filters}`);
    yield put({
      type: "FETCH_SHORTLISTS_LANDLORD_SUCCESS",
      payload: interestedInMyBuildings.data || [{ error: interestedInMyBuildings.statusText }],
    });
  }
  catch (err) {
    console.log(err);
    yield put({
      type: "FETCH_SHORTLISTS_LANDLORD_FAILED"
    });
  }
}
function* fetchShortlistsLandlordBuildings() {
  try {
    const buildings = yield axios.get(`/interest-in-buildings/assets/`);
    yield put({
      type: "FETCH_SHORTLISTS_LANDLORD_BUILDINGS_SUCCESS",
      payload: buildings.data || [{ error: buildings.statusText }],
    });
  }
  catch (err) {
    console.log(err);
    yield put({
      type: "FETCH_SHORTLISTS_LANDLORD_BUILDINGS_FAILED"
    });
  }
}
function* fetchShortlistLandlordReset(action) {
  const initOrderBy = action.payload;
  const filtersReset = resetFilters(initOrderBy);
  try {
    const interestedInMyBuildings = yield axios.get(
      `/interest-in-buildings/${filtersReset}`
    );
    yield put({
      type: "FETCH_SHORTLISTS_LANDLORD_SUCCESS",
      payload: interestedInMyBuildings.data || [
        { error: interestedInMyBuildings.statusText },
      ],
    });
  } catch (err) {
    console.log(err);
    yield put({
      type: "FETCH_SHORTLISTS_LANDLORD_FAILED"
    });
  }
}
function* fetchShortlistPdf(action) {
  try {
    const shortlistID = action.payload;
    const shortlistPdf = yield axios.get(
      `/offers/${shortlistID}/get_presentation_data/`
    );
    yield put({
      type: "FETCH_SHORTLIST_PDF_SUCCESS",
      payload: shortlistPdf.data || [{ error: shortlistPdf.statusText }],
    });
  } catch (err) {
    console.log(err);
  }
}
function* fetchShortlistUnits(action) {
  try {
    const shortlistID = action.payload;
    const shortlistUnits = yield axios.get(`/offers/${shortlistID}/`);
    yield put({
      type: "FETCH_SHORTLIST_UNITS_SUCCESS",
      payload: shortlistUnits.data || [{ error: shortlistUnits.statusText }],
    });
  } catch (err) {
    console.log(err);
  }
}
function* fetchShortlistUnitsLandlord(action) {
  try {
    const params = action.payload.params;
    const officeID = params.officeId || null;
    const shortlistUnits = yield axios.get(`/offers-landlord/${officeID}/`);
    yield put({
      type: "FETCH_SHORTLISTS_LANDLORD_UNITS_SUCCESS",
      payload: shortlistUnits.data || [{ error: shortlistUnits.statusText }],
    });
  } catch (err) {
    console.log(err);
  }
}
function* fetchShortlistUnitsReset() {
  try {
    yield put({ type: "FETCH_SHORTLIST_UNITS_RESET_SUCCESS" });
  } catch (err) {
    console.log(err);
  }
}
function* fetchShortlistUnitsLandlordReset() {
  try {
    yield put({ type: "FETCH_SHORTLISTS_LANDLORD_UNITS_RESET_SUCCESS" });
  } catch (err) {
    console.log(err);
  }
}

// FETCH OFFERS ONLINE DATA
function* fetchOfferOnline(action) {
  const { offerID, email, market } = action.payload;
  const authMail = email ? `?email=${email}` : "";
  const filterMarket = market ? `${email ? "&" : "?"}market=${market}` : "";
  const filter = `${authMail}${filterMarket}`;
  try {
    const offer = yield axios.get(`/offers/${offerID}/details/${filter}`);
    yield put({
      type: "FETCH_OFFER_ONLINE_SUCCESS",
      payload: offer.data || [{ error: offer.statusText }],
    });
  } catch (err) {
    if (err.status === 403) {
      localStorage.removeItem("offerMail");
    }
    yield put({
      type: "FETCH_OFFER_ONLINE_FAILED",
      payload: err.status,
      meta: actionNotification(
        handleErrorResponse(err.data) ||
          "Oops, something went wrong! Try again later.",
        "error"
      ),
    });
  }
}
function* fetchOfferOnlineOffice(action) {
  const { offerID, officeID, email } = action.payload;
  const authMail = email ? `?email=${email}` : "";
  try {
    const offerOffice = yield axios.get(
      `/offers/${offerID}/details/${officeID}/${authMail}`
    );
    yield put({
      type: "FETCH_OFFER_ONLINE_OFFICE_SUCCESS",
      payload: offerOffice.data || [{ error: offerOffice.statusText }],
    });
  } catch (err) {
    if (err.status === 403) {
      localStorage.removeItem("offerMail");
    }
    yield put({
      type: "FETCH_OFFER_ONLINE_OFFICE_FAILED",
      payload: err.status,
      meta: actionNotification(
        handleErrorResponse(err.data) ||
          "Oops, something went wrong! Try again later..",
        "error"
      ),
    });
  }
}
function* fetchOfferOnlineOfficeContact(action) {
  const { offerID, officeID, email } = action.payload;
  const authMail = email ? `?email=${email}` : "";
  try {
    const offerContact = yield axios.get(
      `/offers/${offerID}/details/${officeID}/contact/${authMail}`
    );
    yield put({
      type: "FETCH_OFFER_ONLINE_OFFICE_CONTACT_SUCCESS",
      payload: offerContact.data || [{ error: offerContact.statusText }],
    });
  } catch (err) {
    yield put({
      type: "FETCH_OFFER_ONLINE_OFFICE_CONTACT_FAILED",
      payload: err.status,
      meta: actionNotification(
        handleErrorResponse(err.data) ||
          "Oops, something went wrong! Try again later..",
        "error"
      ),
    });
  }
}
function* fetchOfferOnlineBranding(action) {
  const { offerID } = action.payload;
  try {
    const offerBranding = yield axios.get(`/offers/${offerID}/branding/`);
    yield put({
      type: "FETCH_OFFER_ONLINE_BRANDING_SUCCESS",
      payload: offerBranding.data || [{ error: offerBranding.statusText }],
    });
  } catch (err) {
    yield put({
      type: "FETCH_OFFER_ONLINE_BRANDING_FAILED",
      payload: err.status,
      meta: actionNotification(
        handleErrorResponse(err.data) ||
          "Oops, something went wrong! Try again later.",
        "error"
      ),
    });
  }
}
function* fetchOfferOnlineListPhotos(action) {
  const { offerID, email } = action.payload;
  try {
    const offerPhotos = yield axios.get(
      `/offers/${offerID}/get-main-photos/?email=${email}`
    );
    yield put({
      type: "FETCH_OFFER_ONLINE_LIST_PHOTOS_SUCCESS",
      payload: offerPhotos.data || [{ error: offerPhotos.statusText }],
    });
  } catch (err) {
    if (err.status === 403) {
      localStorage.removeItem("offerMail");
    }
    yield put({
      type: "FETCH_OFFER_ONLINE_LIST_PHOTOS_FAILED",
      payload: err.status,
      meta: actionNotification(
        handleErrorResponse(err.data) ||
          "Oops, something went wrong! Try again later.",
        "error"
      ),
    });
  }
}

// OFFER ACTION
function* addShortlist(action) {
  const { data } = action.payload;
  try {
    const newShortlist = yield axios.post(`/offers/`, data);
    yield put({
      type: "ADD_SHORTLIST_SUCCESS",
      payload: newShortlist.data,
      meta: actionNotification("Offer has been added.", "success"),
    });
  } catch (err) {
    yield put({
      type: "ADD_SHORTLIST_FAILED",
      payload: err.status,
      meta: actionNotification(
        handleErrorResponse(err.data.name) ||
          "You have reached the limit of 30 units on a single shortlist.",
        "error"
      ),
    });
  }
}
function* addUnitToExistingShortlist(action) {
  const { id, units } = action.payload;
  try {
    const unitToShortlist = yield axios.post(`/offers/${id}/add_unit/`, {
      units,
    });
    yield put({
      type: "ADD_UNIT_TO_EXISTING_SHORTLIST_SUCCESS",
      payload: unitToShortlist.data,
      meta: actionNotification(
        "Unit has been added to selected shortlist.",
        "success"
      ),
    });
  } catch (err) {
    yield put({
      type: "ADD_UNIT_TO_EXISTING_SHORTLIST_FAILED",
      payload: err.status,
      meta: actionNotification(
        "You have reached the limit of 30 units on a single shortlist.",
        "error"
      ),
    });
  }
}
function* addUnitToNewShortlist(action) {
  const { name, units, forTesting } = action.payload;
  try {
    const unitToShortlist = yield axios.post(`/offers/`, { name, units, forTesting });
    yield put({
      type: "ADD_UNIT_TO_NEW_SHORTLIST_SUCCESS",
      payload: unitToShortlist.data,
      meta: actionNotification(
        "Unit has been added to new shortlist.",
        "success"
      ),
    });
  } catch (err) {
    yield put({
      type: "ADD_UNIT_TO_NEW_SHORTLIST_FAILED",
      payload: err.status,
      meta: actionNotification(
        handleErrorResponse(err.data.name) ||
          "You have reached the limit of 30 units on a single shortlist.",
        "error"
      ),
    });
  }
}
function* deleteOffer(action) {
  const { offerID } = action.payload;
  try {
    yield axios.delete(`/offers/${offerID}/`);
    yield put({
      type: "DELETE_OFFER_SUCCESS",
      payload: offerID,
      meta: actionNotification("Offer has been deleted", "success"),
    });
  } catch (err) {
    console.log(err);
    yield put({
      type: "DELETE_OFFER_FAILED",
    });
  }
}
function* updateOffer(action) {
  const { offerID, data } = action.payload;
  try {
    const clients = yield axios.patch(`/offers/${offerID}/`, data);
    yield put({
      type: "UPDATE_OFFER_SUCCESS",
      payload: clients.data,
      meta: actionNotification("Offer has been updated", "success"),
    });
  } catch (err) {
    yield put({
      type: "UPDATE_OFFER_FAILED",
      payload: err.status,
      meta: actionNotification(
        handleErrorResponse(err.data) ||
          "Oops, something went wrong! Try again later.",
        "error"
      ),
    });
  }
}

// OFFER ONLINE ACTIONS
function* verifyOfferOnlineAccess(action) {
  try {
    const { offerID, email } = action.payload;
    const authMail = email ? `?email=${email}` : "";
    const offerVerifyAccess = yield axios.get(
      `/offers/${offerID}/verify/${authMail}`
    );
    const isNotAllowed =
      offerVerifyAccess &&
      offerVerifyAccess.data &&
      !offerVerifyAccess.data.hasAccess;
    yield put({
      type: "VERIFY_OFFER_ONLINE_ACCESS_SUCCESS",
      payload: offerVerifyAccess.data,
      meta:
        isNotAllowed &&
        actionNotification(
          "You are not allowed to view this Offer. You can request access to it by clicking link above.",
          "warning"
        ),
    });
  } catch (err) {
    yield put({
      type: "VERIFY_OFFER_ONLINE_ACCESS_FAILED",
      payload: err.status,
      meta: actionNotification(
        handleErrorResponse(err.data) ||
          "Oops, something went wrong! Try again later.",
        "error"
      ),
    });
  }
}
function* requestOfferOnlineAccess(action) {
  try {
    const { offerID, email } = action.payload;
    const authMail = email ? `?email=${email}` : "";
    const offerRequestAccess = yield axios.get(
      `/offers/${offerID}/get-access/${authMail}`
    );
    yield put({
      type: "REQUEST_OFFER_ONLINE_ACCESS_SUCCESS",
      payload: offerRequestAccess.data,
      meta: actionNotification(
        "Your request for access to this Offer has been sent.",
        "success"
      ),
    });
  } catch (err) {
    yield put({
      type: "REQUEST_OFFER_ONLINE_ACCESS_FAILED",
      payload: err.status,
      meta: actionNotification(
        handleErrorResponse(err.data) ||
          "Oops, something went wrong! Try again later.",
        "error"
      ),
    });
  }
}

// OFFER KUDOS SEND MAIL
function* sendKudosEmailConfirmation(action) {
  try {
    const { data } = action.payload;
    const kudosConfirm = yield axios.post(`/offers/send_kudos/`, data);
    yield put({
      type: "SEND_KUDOS_EMAIL_SUCCESS",
      payload: kudosConfirm.data,
    });
  } catch (err) {
    yield put({
      type: "SEND_KUDOS_EMAIL_FAILED",
      payload: err.status,
      meta: actionNotification(
        handleErrorResponse(err.data) ||
          "Oops, something went wrong! Try again later.",
        "error"
      ),
    });
  }
}
function* createKudosEmailSignInLog(action) {
  try {
    const { data } = action.payload;
    const kudosLog = yield axios.post(
      `/offers/create_mail_log_in_redd_record/`,
      data
    );
    yield put({
      type: "CREATE_KUDOS_EMAIL_SIGN_IN_LOG_SUCCESS",
      payload: kudosLog.data,
    });
    window.location.href = "/";
  } catch (err) {
    yield put({
      type: "CREATE_KUDOS_EMAIL_SIGN_IN_LOG_FAILED",
      payload: err.status,
      meta: actionNotification(
        handleErrorResponse(err.data) ||
          "Oops, something went wrong! Try again later.",
        "error"
      ),
    });
  }
}
function* createRealTimeEmailSignInLog(action) {
  try {
    const { data } = action.payload;
    const kudosLog = yield axios.post(
      `/offers/create_mail_check_module_record/`,
      data
    );
    yield put({
      type: "CREATE_REAL_TIME_EMAIL_SIGN_IN_LOG_SUCCESS",
      payload: kudosLog.data,
    });
    localStorage.removeItem("redirectSourceUrl");
  } catch (err) {
    yield put({
      type: "CREATE_REAL_TIME_EMAIL_SIGN_IN_LOG_FAILED",
      payload: err.status,
      meta: actionNotification(
        handleErrorResponse(err.data) ||
          "Oops, something went wrong! Try again later.",
        "error"
      ),
    });
  }
}

// OFFER WEEKLY SUMMARY - BUTTON REDIRECT
function* createRealTimeEmailWeeklySummaryLog(action) {
  try {
    const { data } = action.payload;
    const weeklySummaryLog = yield axios.post(
      `/offers/create_offer_weekly_summary_check_record/`,
      data
    );
    yield put({
      type: "CREATE_REAL_TIME_EMAIL_WEEKLY_SUMMARY_LOG_SUCCESS",
      payload: weeklySummaryLog.data,
    });
    localStorage.removeItem("redirectSourceUrl");
  } catch (err) {
    yield put({
      type: "CREATE_REAL_TIME_EMAIL_WEEKLY_SUMMARY_LOG_FAILED",
      payload: err.status,
      meta: actionNotification(
        handleErrorResponse(err.data) ||
          "Oops, something went wrong! Try again later.",
        "error"
      ),
    });
  }
}

// OFFER SHARE - OFFER OPEN - BUTTON REDIRECT
function* createRealTimeEmailOfferOpenLog(action) {
  try {
    const { data } = action.payload;
    const offerOpenLog = yield axios.post(
      `/offers/create_offer_real_time_mail_check_record/`,
      data
    );
    yield put({
      type: "CREATE_REAL_TIME_EMAIL_OFFER_OPEN_LOG_SUCCESS",
      payload: offerOpenLog.data,
    });
    localStorage.removeItem("redirectSourceUrl");
  } catch (err) {
    yield put({
      type: "CREATE_REAL_TIME_EMAIL_OFFER_OPEN_LOG_FAILED",
      payload: err.status,
      meta: actionNotification(
        handleErrorResponse(err.data) ||
          "Oops, something went wrong! Try again later.",
        "error"
      ),
    });
  }
}

// NEW OFFERS - REFACTOR
function* fetchOffersOffices(action) {
  try {
    const params = action.payload.params;
    const filters = setFilters(params);
    const officeOffers = yield axios.get(`/offers/offices/${filters}`);
    yield put({
      type: "FETCH_OFFERS_OFFICES_SUCCESS",
      payload: officeOffers.data || [{ error: officeOffers.statusText }],
    });
  } catch (err) {
    console.log(err);
    yield put({
      type: "FETCH_OFFERS_OFFICES_FAILED",
      payload: err.status,
    });
  }
}
function* fetchOfferOffices(action) {
  try {
    const offerID = action.payload;
    const officeOffer = yield axios.get(`/offers/${offerID}/`);
    yield put({
      type: "FETCH_OFFER_OFFICES_SUCCESS",
      payload: officeOffer.data || [{ error: officeOffer.statusText }],
    });
  } catch (err) {
    console.log(err);
    yield put({
      type: "FETCH_OFFER_OFFICES_FAILED",
      payload: err.status,
    });
  }
}
function* fetchOfferOfficesPdf(action) {
  try {
    const offerID = action.payload;
    const offerPdf = yield axios.get(
      `/offers/${offerID}/pdf/`
    );
    yield put({
      type: "FETCH_OFFER_OFFICES_PDF_SUCCESS",
      payload: offerPdf.data || [{ error: offerPdf.statusText }],
    });
  } catch (err) {
    console.log(err);
    yield put({
      type: "FETCH_OFFER_OFFICES_PDF_FAILED"
    });
  }
}
function* removeUnitFromOffer(action) {
  const { offerID, data } = action.payload;
  try {
    const updatedOffer = yield axios.post(`/offers/${offerID}/remove-unit/`, data);
    yield put({
      type: "REMOVE_UNIT_FROM_OFFER_SUCCESS",
      payload: updatedOffer.data || [{ error: updatedOffer.statusText }],
      meta: actionNotification("Unit has been removed from offer", "success"),
    });
  }
  catch (err) {
    console.log(err);
    yield put({
      type: "REMOVE_UNIT_FROM_OFFER_FAILED",
      payload: err.status,
      meta: actionNotification(
        handleErrorResponse(err.data) ||
          "Oops, something went wrong! Try again later.",
        "error"
      ),
    });
  }
}

export function* saga() {
  // OFFER
  yield takeLatest("FETCH_OFFERS_ALL_REQUEST", fetchOffersAll);
  yield takeLatest("FETCH_SHORTLISTS_LANDLORD_REQUEST", fetchShortlistsLandlord);
  yield takeLatest("FETCH_SHORTLISTS_LANDLORD_BUILDINGS_REQUEST", fetchShortlistsLandlordBuildings);
  yield takeLatest("FETCH_SHORTLIST_PDF_REQUEST", fetchShortlistPdf);
  yield takeLatest("FETCH_SHORTLIST_UNITS_REQUEST", fetchShortlistUnits);
  yield takeLatest(
    "FETCH_SHORTLISTS_LANDLORD_UNITS_REQUEST",
    fetchShortlistUnitsLandlord
  );
  yield takeLatest(
    "FETCH_SHORTLISTS_LANDLORD_RESET_REQUEST",
    fetchShortlistLandlordReset
  );
  yield takeLatest(
    "FETCH_SHORTLIST_UNITS_RESET_REQUEST",
    fetchShortlistUnitsReset
  );
  yield takeLatest(
    "FETCH_SHORTLISTS_LANDLORD_UNITS_RESET_REQUEST",
    fetchShortlistUnitsLandlordReset
  );
  // OFFER ONLINE
  yield takeLatest("FETCH_OFFER_ONLINE_REQUEST", fetchOfferOnline);
  yield takeLatest("FETCH_OFFER_ONLINE_OFFICE_REQUEST", fetchOfferOnlineOffice);
  yield takeLatest(
    "FETCH_OFFER_ONLINE_OFFICE_CONTACT_REQUEST",
    fetchOfferOnlineOfficeContact
  );
  yield takeLatest(
    "FETCH_OFFER_ONLINE_BRANDING_REQUEST",
    fetchOfferOnlineBranding
  );
  yield takeLatest(
    "FETCH_OFFER_ONLINE_LIST_PHOTOS_REQUEST",
    fetchOfferOnlineListPhotos
  );
  // OFFER ACTIONS
  yield takeLatest("ADD_SHORTLIST_REQUEST", addShortlist);
  yield takeLatest(
    "ADD_UNIT_TO_EXISTING_SHORTLIST_REQUEST",
    addUnitToExistingShortlist
  );
  yield takeLatest("ADD_UNIT_TO_NEW_SHORTLIST_REQUEST", addUnitToNewShortlist);
  yield takeLatest("DELETE_OFFER_REQUEST", deleteOffer);
  yield takeLatest("UPDATE_OFFER_REQUEST", updateOffer);
  // OFFER ONLINE ACTIONS
  yield takeLatest(
    "VERIFY_OFFER_ONLINE_ACCESS_REQUEST",
    verifyOfferOnlineAccess
  );
  yield takeLatest(
    "REQUEST_OFFER_ONLINE_ACCESS_REQUEST",
    requestOfferOnlineAccess
  );
  // OFFER KUDOS SEND MAIL
  yield takeLatest("SEND_KUDOS_EMAIL_REQUEST", sendKudosEmailConfirmation);
  yield takeLatest(
    "CREATE_KUDOS_EMAIL_SIGN_IN_LOG_REQUEST",
    createKudosEmailSignInLog
  );
  yield takeLatest(
    "CREATE_REAL_TIME_EMAIL_SIGN_IN_LOG_REQUEST",
    createRealTimeEmailSignInLog
  );
  // OFFER WEEKLY SUMMARY - BUTTON REDIRECT
  yield takeLatest(
    "CREATE_REAL_TIME_EMAIL_WEEKLY_SUMMARY_LOG_REQUEST",
    createRealTimeEmailWeeklySummaryLog
  );
  // OFFER FIRST OPEN - BUTTON REDIRECT
  yield takeLatest(
    "CREATE_REAL_TIME_EMAIL_OFFER_OPEN_LOG_REQUEST",
    createRealTimeEmailOfferOpenLog
  );
  // NEW OFFERS - REFACTOR
  yield takeLatest("FETCH_OFFERS_OFFICES_REQUEST", fetchOffersOffices);
  yield takeLatest("FETCH_OFFER_OFFICES_REQUEST", fetchOfferOffices);
  yield takeLatest("FETCH_OFFER_OFFICES_PDF_REQUEST", fetchOfferOfficesPdf);
  yield takeLatest("REMOVE_UNIT_FROM_OFFER_REQUEST", removeUnitFromOffer);
}

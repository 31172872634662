import React from "react";
import {Carousel} from "react-bootstrap";
import {toAbsoluteUrl} from "_metronic/utils/utils";
import styles from "./index.module.scss";
import clsx from "classnames";

export default class SliderCustom extends React.Component {
    state = {
        index: 0,
        direction: null
    };

    render() {
        const {images, controls = true, interval, options, fitVariant} = this.props;
        const {index, direction} = this.state;
        const handleSelect = (selectedIndex, e) => {
            this.setState({
                index: selectedIndex,
                direction: e.direction
            });
        };

        return (
            <Carousel
              activeIndex={index}
              direction={direction}
              onSelect={handleSelect}
              className={clsx(
                styles.slider,
                fitVariant ? styles.fitVariant : undefined
              )}
              controls={images?.length > 1 && controls}
              interval={interval}
              {...options}
            >
                {images.length ? images.map((image, index) => {
                    let imageOrientation = 'horizontal';
                    let tmpImg = new Image();
                    tmpImg.src = image;
                    tmpImg.onload = function () {
                        const orgWidth = tmpImg.width;
                        const orgHeight = tmpImg.height;
                        imageOrientation = orgWidth > orgHeight ? 'horizontal' : 'vertical';
                    };
                    return (
                        <Carousel.Item key={index}>
                            <img
                                className={clsx("d-block", imageOrientation === "horizontal" ? "w-100" : "h-100")}
                                src={image}
                                alt={`Property #${index+1}`}
                            />
                        </Carousel.Item>
                    );
                }) : <Carousel.Item key={index}>
                    <img
                        className="d-block h-100"
                        src={toAbsoluteUrl("media/office-card-placeholder.jpg")}
                        alt="First slide"
                    />
                </Carousel.Item>}
            </Carousel>
        );
    }
}
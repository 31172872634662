import React from "react";
import TableMUI from "app/partials/components/TableMUI";
import {TableCell, TableRow} from "@material-ui/core";
import _ from "lodash";
import styles from "./index.module.scss";
import PropertySectionTitle from "../../../../common/components/PropertyPage/PropertySectionTitle";
import {toNumberFormat} from "../../../../../utils/helpers";
import cn from "classnames";
import TooltipCustom from "../../../../../partials/components/TooltipCustom";
import ReactVisibilitySensor from "react-visibility-sensor";
import {FormattedMessage} from "react-intl";

function RentRangeBar({data, from, to, avg, isFirstRow}) {
  const isFromToSame = from === to;
  // Global
  const rentFromArray = data?.map(item => item?.rentFrom) || [];
  const rentToArray = data?.map(item => item?.rentTo) || [];
  const rentMergedArray = [...rentFromArray, ...rentToArray];
  const rentMin = Math.min(...rentMergedArray);
  const rentMax = Math.max(...rentMergedArray);
  // Item
  let minPercentagePos = null;
  let maxPercentagePos = null;
  let barPercentageWidth = 5;
  let barPercentagePos = 0;
  let avgPercentagePos = 50;

  // Calculate "minPercentagePos"
  if(from) {
    minPercentagePos = Number((Number(from - rentMin)*100/Number(rentMax - rentMin)).toFixed(2));
  }
  // Calculate "maxPercentagePos"
  if(!isFromToSame && to) {
    maxPercentagePos = Number((Number(to - rentMin)*100/Number(rentMax - rentMin)).toFixed(2));
  }
  // Set "barPercentageWidth" and "barPercentagePos"
  if(_.isNumber(minPercentagePos) && _.isNumber(maxPercentagePos)) {
    barPercentageWidth = Number(maxPercentagePos)-Number(minPercentagePos);
    barPercentagePos = minPercentagePos;
  }
  else if(_.isNumber(minPercentagePos) && !_.isNumber(maxPercentagePos)) {
    barPercentagePos = minPercentagePos;
  }
  else if(!_.isNumber(minPercentagePos) && _.isNumber(maxPercentagePos)) {
    barPercentagePos = maxPercentagePos;
  }
  // Calculate "avgPercentagePos"
  if(avg) {
    avgPercentagePos = Number((Number(avg - rentMin)*100/Number(rentMax - rentMin)).toFixed(2));
  }

  if(from || to) {
    return(
      <span className={styles.rentRangeBarWrapper}>
        <span
          className={styles.rangeBarWrapper}
          style={{
            "--bar-width": `${barPercentageWidth}%`,
            "--bar-left": `${barPercentagePos}%`
          }}
        >
          <span className={styles.barWrapper}/>
          {from &&
            <span
              className={cn(
                styles.value,
                styles.left
              )}
            >{toNumberFormat(from, 'currency')}</span>
          }
          {!isFromToSame && to &&
            <span
              className={cn(
                styles.value,
                styles.right
              )}
            >{toNumberFormat(to, 'currency')}</span>
          }
        </span>
        {avg &&
          <span className={styles.avgLineWrapper} style={{"--avg-left": `${avgPercentagePos}%`}}>
            <TooltipCustom
              title={avg ? toNumberFormat(avg, 'currency') : null}
              disabled={!avg}
              childStyle={{
                width: "100%",
                height: "100%"
              }}
              placement={isFirstRow ? "bottom" : "top"}
            >
              <span className={styles.avgTooltipTrigger}/>
            </TooltipCustom>
          </span>
        }
      </span>
    );
  }
  else if(isFirstRow) {
    return <span style={{display: "block", textAlign: "center", fontWeight: "400"}}><FormattedMessage id="BUILDING.PHRASE.TO_NEGOTIATE"/></span>;
  }
  return null;
}
export default class PropertyRegionRent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initLoaded: false
    }
  }
  // Handlers
  handleOnViewportFetch = (isVisible) => {
    const {fetchIndustrialParkRegionRent, regionRent, regionRentLoading, match} = this.props;
    const {initLoaded} = this.state;
    const {id} = match?.params;

    setTimeout(() => {
      if(isVisible && !initLoaded && !regionRentLoading && _.isEmpty(regionRent) && id) {
        this.setState({
          initLoaded: true,
        }, () => fetchIndustrialParkRegionRent(id));
      }
    }, 200);
  }
  // Helpers
  getDistanceLabel = (distance) => {
    if(distance === "park") {
      return <FormattedMessage id="VOCABULARY.Active park"/>;
    }
    else if(distance === "country") {
      return <FormattedMessage id="GENERAL.PHRASE.COUNTRY"/>;
    }
    else if(distance === "region") {
      return <FormattedMessage id="GENERAL.PHRASE.REGION"/>;
    }
    else if(distance === "5") {
      return <><FormattedMessage id="to"/> {distance} km</>;
    }
    else {
      return <>{distance} km</>
    }
  }

  render() {
    const {regionRent, regionRentLoading} = this.props;

    return(
      <>
        <ReactVisibilitySensor
          onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
          scrollCheck
          partialVisibility
        >
          <PropertySectionTitle
            title={<FormattedMessage id="VOCABULARY.Rent"/>}
            style={{
              marginBottom: "1.6rem"
            }}
          />
        </ReactVisibilitySensor>
        <TableMUI
          loading={regionRentLoading}
          containerStyle={{minHeight: "20rem"}}
          totalItems={regionRent?.length}
          tableContainerClasses={styles.regionAreaTable}
        >
          {regionRent?.length > 0 && regionRent?.map((item, index) => {
            return(
              <TableRow key={index}>
                <TableCell align="left">
                  {this.getDistanceLabel(item?.distance)}
                </TableCell>
                <TableCell align="right" style={{width: "100%"}}>
                  <RentRangeBar
                    data={regionRent}
                    from={item?.rentFrom}
                    to={item?.rentTo}
                    avg={item?.rentAvg}
                    isFirstRow={index === 0}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableMUI>
      </>
    );
  }
}
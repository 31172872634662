import React from "react";
import {HEAD_ROWS, TABLE_CELLS} from "app/partials/components/TableProperties/index";
import {FormattedMessage} from "react-intl";
import {withRouter} from "react-router-dom";
import ListView from "app/pages/offices/components/ListView/ListView";
import store from "app/store/store";
import IsPro from "../../../utils/IsPro";

let prevLocation;
class OwnersPage extends React.Component {
  constructor(props) {
    super(props);
    props.history.listen(nextLocation => {
      prevLocation = nextLocation.pathname;
    });
    this.state = {
      page: 1,
      isReady: false,
      initLoaded: false
    };
  }
  componentDidMount() {
    const {setLatestActiveTablePage, filterReset} = this.props;
    const updateData = prevLocation === undefined || (!prevLocation.includes('/owner/') && !prevLocation.includes('/building/'));
    if(updateData) {
      setLatestActiveTablePage(0);
      filterReset(null, null, true);
    }
  }
  // Handle fetch data on enter viewport
  handleOnViewportFetch = (isVisible) => {
    const {fetchOwners, fetchVisibleOwners, loading, callReddClickEvent} = this.props;
    const {initLoaded} = this.state;
    const updateData = prevLocation === undefined || (!prevLocation.includes('/owner/') && !prevLocation.includes('/building/'));

    if(isVisible && !loading && !initLoaded) {
      this.setState({
        initLoaded: true
      }, () => {
        if(updateData) {
          fetchVisibleOwners();
          const params = {
            ...store.getState().filters,
            page: this.state.page,
            orderBy: 'ownerdetail__portfolio'
          };
          fetchOwners({params});
        }
      });
    }
    // Tracking event
    if(isVisible && !this.state.isReady) {
      this.setState({
        isReady: true
      }, () => {
        callReddClickEvent(
          "contacts-owner-list",
          "company"
        );
      })
    }
  };
  handleOnViewport = (isInViewport) => {
    this.handleOnViewportFetch(isInViewport);
  }

  render() {
    const { items, itemsPerPage, itemsCount, loading} = this.props;
    const buildingItems =  Array.isArray(items) ? items : [];

    return (
      <IsPro paywall>
        <ListView
          tableLabel={<FormattedMessage id="OWNERS.PHRASE.BUILDINGS_OWNERS"/>}
          tableHeadRows={HEAD_ROWS.OWNERS}
          tableBodyRows={buildingItems}
          tableDataSource="owner"
          tableDataTarget={TABLE_CELLS.OWNERS}
          itemsPerPage={itemsPerPage}
          itemsCount={itemsCount}
          filterBarEnable
          handleFetchData={this.props.fetchOwners}
          loading={loading}
          onEnterViewport={this.handleOnViewport}
        />
      </IsPro>
    )
  }
}

export default withRouter(OwnersPage);
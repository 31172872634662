import React from "react";
import cn from "classnames";
import styles from "../index.module.scss";
import IconColorMUI from "../../IconColorMUI";

export default function SlickNavigation({slides, currentSlide, onSetCurrentSlide, slidesToShow = 1, slidesToScroll, style, themeDark}) {
  const slidesCount = slides?.length;
  const slidesToShowLeft = slidesCount-slidesToShow;
  const totalSlides = slidesToScroll ? Math.ceil(slidesToShowLeft/slidesToScroll) : slidesCount;

  if(slides) {
    return(
      <div
        className={cn(
          styles.slickSlideNavigation,
          themeDark ? styles.themeDark : undefined
        )}
         style={style}
      >
        <button
          className={cn(
            currentSlide <= 0 ? styles.inactive : undefined
          )}
          onClick={() => onSetCurrentSlide(currentSlide-1)}
        >
          <IconColorMUI
            color={themeDark ? undefined : "white"}
            icon="chevron_left"
            size="2.4rem"
            style={{lineHeight: 0}}
          />
        </button>
        <span className={styles.slidesCounterWrapper}>{`${currentSlide+1}/${totalSlides}`}</span>
        <button
          className={cn(
            currentSlide >= totalSlides-1 ? styles.inactive : undefined
          )}
          onClick={() => onSetCurrentSlide(currentSlide+1)}
        >
          <IconColorMUI
            color={themeDark ? undefined : "white"}
            icon="chevron_right"
            size="2.4rem"
            style={{lineHeight: 0}}
          />
        </button>
      </div>
    );
  }
  return null;
}
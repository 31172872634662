import React from "react";
import TableProperties from "app/partials/components/TableProperties/TableProperties";
import {TABLE_CELLS} from "app/partials/components/TableProperties";
import {FormattedMessage} from "react-intl";
import IsAdmin from "../../../utils/IsAdmin";

export default class TeamPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initLoaded: false
    }
  }

  // Handle fetch data on enter viewport
  handleOnViewportFetch = (isVisible) => {
    const {fetchTeamMembers, setLatestActiveTablePage, loading} = this.props;
    const {initLoaded} = this.state;
    const params = {
      page: 1
    };

    if(isVisible && !loading && !initLoaded) {
      this.setState({
        initLoaded: true
      }, () => {
        setLatestActiveTablePage(1);
        fetchTeamMembers({params});
      });
    }
  };
  handleOnViewport = (isInViewport) => {
    this.handleOnViewportFetch(isInViewport);
  }

  render() {
    const {
      teamMembers: {items, itemsPerPage, itemsCount},
      loading,
      user,
      isTeamOwner
    } = this.props;
    const isTeam = user && user.team ? "true" : "false";
    const actionVisible = isTeamOwner || IsAdmin();

    return(
      <TableProperties
        headRows={[
          { id: 'member_name', numeric: false, disablePadding: false, label: 'FORM.FIELD.FULLNAME', sort: false },
          { id: 'member_email', numeric: false, disablePadding: false, label: 'FORM.FIELD.EMAIL_ADDRESS', sort: false },
          { id: 'member_phone', numeric: false, disablePadding: false, label: 'FORM.FIELD.PHONE_NUMBER', sort: false },
          { id: 'team_owner', numeric: true, disablePadding: false, label: 'GENERAL.PHRASE.OWNER', sort: false },
          { id: 'member_active_subscription', numeric: true, disablePadding: false, label: 'TEAM.PHRASE.SUBSCRIPTION', sort: false },
          ...actionVisible ? [{ id: 'actions', numeric: true, disablePadding: false, label: '', sort: false }] : [],
        ]}
        bodyRows={items}
        tableLabel={<FormattedMessage id="TEAM.PHRASE.MANAGE_TEAM" values={{team: isTeam === "true" ? user.team.name : '---'}}/>}
        dataSource="office"
        dataTarget={TABLE_CELLS.TEAM}
        itemsPerPage={itemsPerPage}
        itemsCount={itemsCount}
        loading={loading}
        handleFetchData={this.props.fetchTeamMembers}
        onEnterViewport={this.handleOnViewport}
      />
    );
  }
}
import React, {useState} from "react";
import styles from "../index.module.scss";
import PaywallModal from "../components/PaywallModal";
import _ from "lodash";
import {fetchBannerData, getBannerDataValue} from "../helpers";
import ReactVisibilitySensor from "react-visibility-sensor";
import LoadingScreen from "../../LoadingScreen";
import connect from "react-redux/es/connect/connect";

const PaywallBannerRowSimple = (
  {
    style,
    userAccountType,
    bannerVariant = "row_simple-property",
    modalVariant = "default"
  }
) => {
  const [bannerData, setBannerData] = useState(null);
  const [bannerDataLoading, setBannerDataLoading] = useState(false);

  // Handlers
  const handleOnViewportFetch = async (isVisible) => {
    if(isVisible && !bannerDataLoading && _.isEmpty(bannerData)) {
      setBannerDataLoading(true);
      const bannerData = await fetchBannerData(bannerVariant, userAccountType);
      setBannerData(bannerData);
      setBannerDataLoading(false);
    }
  };

  return(
    <ReactVisibilitySensor
      onChange={(isVisible) => handleOnViewportFetch(isVisible)}
      scrollCheck
      partialVisibility
    >
      <div className={styles.paywallBannerRowSimpleWrapper} style={style}>
        {bannerDataLoading ? <LoadingScreen/> :
        <>
        <div className={styles.paywallBody}>
          {getBannerDataValue(bannerData, "title") && <p><strong>{getBannerDataValue(bannerData, "title")}</strong></p>}
          {getBannerDataValue(bannerData, "description") && <p>{getBannerDataValue(bannerData, "description")}</p>}
        </div>
        <PaywallModal
          triggerLabel={getBannerDataValue(bannerData, "button")}
          bannerVariant={modalVariant}
          triggerBannerVariant={bannerVariant}
        />
        </>
        }
      </div>
    </ReactVisibilitySensor>
  );
};

const mapStateToProps = store => ({
  userAccountType: store?.user?.data?.proData?.proGroup
});
export default connect(
  mapStateToProps
)(PaywallBannerRowSimple);
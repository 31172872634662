import React from 'react';
import ModalCustom from "app/partials/components/ModalCustom";
import InputIcon from "app/partials/components/InputIcon";
import {addIndustrialPark} from "app/crud/estates/industrial/industrial.crud";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {fetchMarketsIndustrial} from "app/crud/user.crud";
import LoadingScreen from "app/partials/components/LoadingScreen";
import SingleSelect from "app/partials/components/SingleSelect";
import {Grid} from '@material-ui/core';
import Button from "../../../../Button";
import Icon from "../../../../Icon";

class IndustrialParkAdd extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      name: '',
      market: null,
      nameError: false,
      marketError: false
    };
  }

  handleOnOpen = () => {
    const {fetchMarketsIndustrial} = this.props;
    fetchMarketsIndustrial && fetchMarketsIndustrial();
  }
  handleChange = (event) => {
    this.setState({
      name: event.target.value
    });
  };
  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleSelectedMarket = selected => {
    this.setState({
      market: selected
    });
  };
  handleSave = () => {
    const {name, market} = this.state;
    const {addIndustrialPark} = this.props;
    this.setState({
      nameError: !name.length,
      marketError: market === null
    }, () => {
      if(name.length && market !== null) {
        const data = {
          name,
          market: market
        }
        addIndustrialPark(data);
        this.handleCloseModal();
        return true;
      }
    });
  };

  render() {
    const {markets, loading} = this.props;
    const {name, market, nameError} = this.state;

    return(
      <ModalCustom
        ref={this.child}
        btn={
        <Button
          color="success"
          textIcon={<Icon icon={"add-clean"}/>}
        >
          <FormattedMessage id="INDUSTRIAL.PHRASE.ADD_PARK"/>
        </Button>
        }
        btnConfirm={<FormattedMessage id="GENERAL.PHRASE.ADD"/>}
        title={<FormattedMessage id="INDUSTRIAL.PHRASE.ADD_PARK"/>}
        handleSave={this.handleSave}
        onOpen={() => this.handleOnOpen()}
      >
        <Grid container spacing={2} direction="column">
          <Grid item>
            <InputIcon label={<FormattedMessage id="INDUSTRIAL.PHRASE.PARK_NAME"/>} name="new_park_name" value={name} error={nameError} onChange={this.handleChange}/>
          </Grid>
          <Grid item>
            {loading ? <LoadingScreen/> :
            <SingleSelect
                label={<FormattedMessage id="GENERAL.PHRASE.MARKET"/>}
                inputName="new_building_market"
                options={markets}
                optionsValue="id"
                optionsLabel="name"
                defaultSelect={market}
                onSelectedValues={this.handleSelectedMarket}
                required
            />
            }
          </Grid>
        </Grid>
      </ModalCustom>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  user: user.data,
  markets: user?.marketsIndustrial,
  loading: user?.marketsIndustrialLoading
});
const mapDispatchToProps = {
  addIndustrialPark: data => addIndustrialPark(data),
  fetchMarketsIndustrial: fetchMarketsIndustrial
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(IndustrialParkAdd)
);
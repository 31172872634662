import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {put, takeLatest} from "redux-saga/effects";
import axios from "axios";
import {actionNotification, errorMessageData} from "app/utils/notifications";
import qs from "qs";
import {clearCacheAndStorage, handleErrorResponse} from "../../utils/helpers";

const initialState = {
  data: null,
  loading: false,
  visibleTeams: null,
  visibleTeamsLoading: false,
  visibleOwners: null,
  visibleMarketOwners: null,
  visibleNeighborhoods: null,
  updateLoading: false,
  updateAwaitRefresh: false,
  didInvalidate: false,
  resetPasswordSuccess: false,
  teamBuildings: null,
  teamBuildingsLoading: false,
  userVideos: null,
  userVideosLoading: false,
  voivodeships: [],
  voivodeshipsLoading: false,
  allUsers: null,
  loadingAllUsers: false,
  loggingAsUser: false,
  markets: null,
  marketsIndustrial: null,
  marketsLoading: false,
  marketsIndustrialLoading: false,
  officeMarketDistricts: null,
  officeMarketDistrictsLoading: false,
  creatingUser: false,
  langUpdating: false
};

export const reducer = persistReducer(
  { storage, key: "user" },
  (state = initialState, action) => {
    switch (action.type) {
      // Reset User Loading States
      case 'RESET_USER_LOADING_STATES_REQUEST': {
        return {
          ...state,
          loading: false,
          visibleTeamsLoading: false,
          updateLoading: false,
          teamBuildingsLoading: false,
          userVideosLoading: false,
          voivodeshipsLoading: false,
          loadingAllUsers: false,
          marketsLoading: false,
          marketsIndustrialLoading: false,
          officeMarketDistrictsLoading: false
        };
      }
      //
      case 'FETCH_USER_DATA_REQUEST': {
        return { ...state, loading: true, updateAwaitRefresh: false};
      }
      case 'FETCH_USER_DATA_SUCCESS': {
        return {...state, data: action.payload, loading: false};
      }
      case 'FETCH_OFFICES_MARKET_DISTRICTS_REQUEST': {
        return {
          ...state,
          officeMarketDistrictsLoading: true,
          officeMarketDistricts: null
        }
      }
      case 'FETCH_OFFICES_MARKET_DISTRICTS_SUCCESS': {
        return {
          ...state,
          officeMarketDistrictsLoading: false,
          officeMarketDistricts: action.payload
        }
      }
      case 'FETCH_OFFICES_MARKET_DISTRICTS_FAILED': {
        return {
          ...state,
          officeMarketDistrictsLoading: false,
          officeMarketDistricts: null
        }
      }
      case 'FETCH_VISIBLE_TEAMS_REQUEST': {
        return { ...state, visibleTeamsLoading: true, visibleTeams: null }
      }
      case 'FETCH_VISIBLE_TEAMS_SUCCESS': {
        return { ...state, visibleTeamsLoading: false, visibleTeams: action.payload }
      }
      case 'FETCH_VISIBLE_TEAMS_FAILED': {
        return { ...state, visibleTeamsLoading: false, visibleTeams: null }
      }
      case 'FETCH_VISIBLE_OWNERS_SUCCESS': {
        return { ...state, visibleOwners: action.payload.filter(item => item !== null) }
      }
      case 'FETCH_VISIBLE_MARKET_OWNERS_SUCCESS': {
        return { ...state, visibleMarketOwners: action.payload.filter(item => item !== null) }
      }
      case 'FETCH_VISIBLE_NEIGHBORHOODS_SUCCESS': {
        return { ...state, visibleNeighborhoods: action.payload }
      }
      case 'FETCH_TEAM_BUILDINGS_REQUEST': {
        return { ...state, teamBuildings: null, teamBuildingsLoading: true }
      }
      case 'FETCH_TEAM_BUILDINGS_SUCCESS': {
        return { ...state, teamBuildings: action.payload, teamBuildingsLoading: false }
      }
      case 'FETCH_VOIVODESHIP_REQUEST': {
        return { ...state, voivodeships: null, voivodeshipsLoading: true };
      }
      case 'FETCH_VOIVODESHIP_SUCCESS': {
        return { ...state, voivodeships: action.payload, voivodeshipsLoading: false };
      }
      case 'FETCH_VOIVODESHIP_FAILED': {
        return { ...state, voivodeships: null, voivodeshipsLoading: false };
      }
      case 'FETCH_ALL_USERS_REQUEST': {
        return { ...state, allUsers: null, loadingAllUsers: true };
      }
      case 'FETCH_ALL_USERS_SUCCESS': {
        return { ...state, allUsers: action.payload, loadingAllUsers: false };
      }
      case 'FETCH_ALL_USERS_FAILED': {
        return { ...state, allUsers: null, loadingAllUsers: false };
      }
      case 'FETCH_MARKETS_REQUEST': {
        return {
          ...state,
          markets: null,
          marketsLoading: true
        };
      }
      case 'FETCH_MARKETS_SUCCESS': {
        return {
          ...state,
          markets: action.payload,
          marketsLoading: false
        };
      }
      case 'FETCH_MARKETS_FAILED': {
        return {
          ...state,
          markets: null,
          marketsLoading: false
        };
      }
      case 'FETCH_MARKETS_INDUSTRIAL_REQUEST': {
        return {
          ...state,
          marketsIndustrial: null,
          marketsIndustrialLoading: true
        };
      }
      case 'FETCH_MARKETS_INDUSTRIAL_SUCCESS': {
        return {
          ...state,
          marketsIndustrial: action.payload,
          marketsIndustrialLoading: false
        };
      }
      case 'FETCH_MARKETS_INDUSTRIAL_FAILED': {
        return {
          ...state,
          marketsIndustrial: null,
          marketsIndustrialLoading: false
        };
      }
      // UPDATES
      case 'UPDATE_USER_DATA_REQUEST': {
        return {
          ...state,
          updateLoading: true,
          visibleTeams: null,
          visibleOwners: null,
          visibleMarketOwners: null,
          updateAwaitRefresh: false
        };
      }
      case 'UPDATE_USER_DATA_SUCCESS': {
        const awaitRefresh = action.payload?.awaitRefresh;
        localStorage.removeItem("redirectApplicationView")
        return {
          ...state,
          data: {...state.data, ...action.payload?.data},
          updateLoading: false,
          markets: null,
          marketsLoading: false,
          marketsIndustrial: null,
          marketsIndustrialLoading: false,
          updateAwaitRefresh: awaitRefresh
        }
      }
      case 'UPDATE_USER_DATA_FAILED': {
        return { ...state, updateLoading: false, updateAwaitRefresh: false };
      }
      case 'UPDATE_USER_LANG_CODE_REQUEST': {
        return {
          ...state,
          langUpdating: true,
          updateAwaitRefresh: false
        };
      }
      case 'UPDATE_USER_LANG_CODE_SUCCESS': {
        const awaitRefresh = action.payload?.awaitRefresh;
        return {
          ...state,
          langUpdating: false,
          updateAwaitRefresh: awaitRefresh
        }
      }
      case 'UPDATE_USER_LANG_CODE_FAILED': {
        return {
          ...state,
          langUpdating: false,
          updateAwaitRefresh: false
        };
      }
      case 'UPDATE_USER_QUESTIONNAIRE_CHANGE_REQUEST': {
        return { ...state, updateLoading: true };
      }
      case 'UPDATE_USER_QUESTIONNAIRE_CHANGE_FAILED': {
        return { ...state, updateLoading: false };
      }
      case 'UPDATE_USER_QUESTIONNAIRE_CHANGE_SUCCESS': {
        return { ...state, data: {...state.data, ...action.payload}, updateLoading: false }
      }
      case 'REQUEST_UPGRADE_TO_PREMIUM_SUCCESS': {
        return { ...state }
      }
      case 'CHANGE_PASSWORD_REQUEST': {
        return { ...state, updateLoading: true }
      }
      case 'CHANGE_PASSWORD_FAILED': {
        return { ...state, updateLoading: false }
      }
      case 'CHANGE_PASSWORD_SUCCESS': {
        return { ...state, data: { ...state.data, ...action.payload }, updateLoading: false }
      }
      case 'RESET_PASSWORD_REQUEST': {
        return { ...state, resetPasswordSuccess: false }
      }
      case 'RESET_PASSWORD_SUCCESS': {
        return { ...state, resetPasswordSuccess: true }
      }
      case 'RESET_PASSWORD_FAILED': {
        return { ...state, resetPasswordSuccess: false }
      }
      case 'USER_ADD_WATCH_LIST_ITEM_SUCCESS': {
        return { ...state, data: { ...state.data } }
      }
      case 'USER_REMOVE_WATCH_LIST_ITEM_SUCCESS': {
        return { ...state, data: { ...state.data } }
      }
      case 'UPDATE_CONTACT_DETAILS_VIEWS_SUCCESS': {
        return { ...state, data: {...state.data, ...action.payload} }
      }
      case 'LOGIN_AS_USER_REQUEST': {
        return { ...state, loggingAsUser: true }
      }
      case 'LOGIN_AS_USER_SUCCESS': {
        return { ...state, loggingAsUser: false }
      }
      case 'LOGIN_AS_USER_FAILED': {
        return { ...state, loggingAsUser: false }
      }
      case 'UPDATE_USER_MARKET_REQUEST': {
        return {
          ...state,
          officeMarketDistricts: null,
          visibleNeighborhoods: null,
          visibleMarketOwners: null
        }
      }
      case 'ADMIN_CREATE_USER_REQUEST': {
        return { ...state, creatingUser: true }
      }
      case 'ADMIN_CREATE_USER_SUCCESS': {
        return { ...state, creatingUser: false }
      }
      case 'ADMIN_CREATE_USER_FAILED': {
        return { ...state, creatingUser: false }
      }

      default:
        return state;
    }
  }
);

// FETCH DATA
function* fetchUserData() {
  try {
    const userData = yield axios.get('/auth/user/');
    yield put({ type: "FETCH_USER_DATA_SUCCESS", payload: userData.data || [{ error: userData.statusText }] });
  }
  catch(err) {
    if(err?.data.detail === 'Invalid token.') {
      clearCacheAndStorage();
    }
    console.log(err);
  }
}
function* fetchMarkets() {
  try {
    const markets = yield axios.get('/markets/');
    yield put({ type: "FETCH_MARKETS_SUCCESS", payload: markets.data || [{ error: markets.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_MARKETS_FAILED" });
    console.log(err);
  }
}
function* fetchMarketsIndustrial() {
  try {
    const markets = yield axios.get('/markets/warehouse/');
    yield put({ type: "FETCH_MARKETS_INDUSTRIAL_SUCCESS", payload: markets.data || [{ error: markets.statusText }] });
  }
  catch(err) {
    yield put({ type: "FETCH_MARKETS_INDUSTRIAL_FAILED" });
    console.log(err);
  }
}
function* fetchOfficesMarketDistricts(action) {
  const {marketId} = action?.payload;
  try {
    if(marketId) {
      const marketDistricts = yield axios.get(`/markets/${marketId}/districts/`);
      yield put({ type: "FETCH_OFFICES_MARKET_DISTRICTS_SUCCESS", payload: marketDistricts.data || [{ error: marketDistricts.statusText }] });
    }
    else {
      yield put({ type: "FETCH_OFFICES_MARKET_DISTRICTS_FAILED" });
    }
  }
  catch(err) {
    console.log(err);
    yield put({ type: "FETCH_OFFICES_MARKET_DISTRICTS_FAILED" });
  }
}
function* fetchVisibleTeams() {
  try {
    const visibleTeams = yield axios.get('/teams/my-teams/');
    yield put({ type: "FETCH_VISIBLE_TEAMS_SUCCESS", payload: visibleTeams.data || [{ error: visibleTeams.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchVisibleOwners() {
  try {
    const visibleOwners = yield axios.get('/teams/my-owners/');
    yield put({ type: "FETCH_VISIBLE_OWNERS_SUCCESS", payload: visibleOwners.data || [{ error: visibleOwners.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchVisibleMarketOwners() {
  try {
    const visibleOwners = yield axios.get('/teams/my-market-owners/');
    yield put({ type: "FETCH_VISIBLE_MARKET_OWNERS_SUCCESS", payload: visibleOwners.data || [{ error: visibleOwners.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchVisibleNeighborhoods(action) {
  const districts = action.payload !== undefined ? action.payload : [];
  try {
    const visibleNeighborhoods = yield axios.get('/teams/my-neighborhoods/', {
      params: {
        district: districts
      },
      paramsSerializer: params => {
        return qs.stringify(params, {arrayFormat: 'repeat'})
      }
    });
    yield put({ type: "FETCH_VISIBLE_NEIGHBORHOODS_SUCCESS", payload: visibleNeighborhoods.data || [{ error: visibleNeighborhoods.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchTeamBuildings(action) {
  const teamId = action.payload;
  try {
    const teamBuildings = yield axios.get(`/teams/${teamId}/get-buildings`);
    yield put({ type: "FETCH_TEAM_BUILDINGS_SUCCESS", payload: teamBuildings.data || [{ error: teamBuildings.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchVoivodeships() {
  try {
    const voivodeships = yield axios.get('/voivodeships/');
    yield put({ type: "FETCH_VOIVODESHIP_SUCCESS", payload: voivodeships.data || [{ error: voivodeships.statusText }] });
  }
  catch(err) {
    yield put({
      type: "FETCH_VOIVODESHIP_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* fetchAllUsers() {
  try {
    const allUsers = yield axios.get('/auth/user/login-as/');
    yield put({
      type: "FETCH_ALL_USERS_SUCCESS",
      payload: allUsers.data || [{error: allUsers.statusText}]
    });
  }
  catch(err) {
    yield put({
      type: "FETCH_ALL_USERS_FAILED",
      payload: null,
      meta: actionNotification(handleErrorResponse(err), 'error')
    });
    console.log(err);
  }
}

// USER ACTIONS
function* updateUserData(action) {
  try {
    const {data, avatar, companyLogo, pdfCover, redirect} = action.payload;
    if(avatar || companyLogo || pdfCover) {
      const formData = new FormData();
      avatar && formData.append('avatar', avatar);
      companyLogo && formData.append('company_logo', companyLogo);
      pdfCover && formData.append('pdf_cover', pdfCover);
      yield axios.post('/auth/user/avatar/', formData);
    }
    const userData = yield axios.patch('/auth/user/', data);
    yield put({
      type: "UPDATE_USER_DATA_SUCCESS",
      payload: {
        data: userData?.data,
        awaitRefresh: redirect?.length > 0
      }
    });
    yield put({
      type: "FILTER_RESET"
    })
    if(redirect) {
      window.location.href = redirect;
    }
    else {
      window.location.reload();
    }
  }
  catch(err) {
    yield put({
      type: "UPDATE_USER_DATA_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* updateUserMarket(action) {
  try {
    const {market, redirect, redirectNewTab} = action.payload;
    const userMarket = yield axios.patch('/auth/user/', {market});
    yield put({
      type: "UPDATE_USER_MARKET_SUCCESS",
      payload: userMarket.data,
      meta: actionNotification('Active market has been changed.', 'success')
    });
    if(redirect) {
      if(redirectNewTab) {
        const protocol = window?.location?.protocol;
        const host = window?.location?.host;
        const baseUrl = `${protocol}//${host}`;
        const redirectUrl = `${baseUrl}${redirect}`;
        window.open(redirectUrl, "_blank");
      }
      else {
        window.location.href = redirect;
      }
    }
  }
  catch(err) {
    yield put({
      type: "UPDATE_USER_MARKET_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* updateUserLangCode(action) {
  try {
    const langCode = action.payload;
    const userLangCode = yield axios.patch('/auth/user/', langCode);
    yield put({
      type: "UPDATE_USER_LANG_CODE_SUCCESS",
      payload: {
        lang: userLangCode.data,
        awaitRefresh: true
      }
    });
    window.location.reload();
  }
  catch(err) {
    yield put({
      type: "UPDATE_USER_LANG_CODE_FAILED",
      payload: err.status
    });
  }
}
function* updateUserQuestionnaireStatus(action) {
  try {
    const questionnaireStatus = action.payload;
    const statusChange = yield axios.patch('/auth/user/', questionnaireStatus);
    yield put({
      type: "UPDATE_USER_QUESTIONNAIRE_CHANGE_SUCCESS",
      payload: statusChange.data
    });
  }
  catch(err) {
    yield put({
      type: "UPDATE_USER_QUESTIONNAIRE_CHANGE_FAILED",
      payload: err.status
    });
  }
}
function* updateUserAffiliateCodeVisible() {
  try {
    const userAffiliateCode = yield axios.patch('/auth/user/', {affiliateCodeVisible: true});
    yield put({
      type: "UPDATE_USER_AFFILIATE_CODE_VISIBLE_SUCCESS",
      payload: userAffiliateCode.data
    });
  }
  catch(err) {
    yield put({
      type: "UPDATE_USER_AFFILIATE_CODE_VISIBLE_FAILED",
      payload: err.status
    });
  }
}
function* removeUserImage(action) {
  try {
    const target = action.payload;
    const data = target === 'avatar' ? {avatar:null} : target === 'companyLogo' ? {company_logo:null} : target === 'pdfCover' && {pdf_cover:null};

    const userImage = yield axios.patch('/auth/user/', data);
    yield put({
      type: "REMOVE_USER_IMAGE_SUCCESS",
      payload: userImage.data,
      meta: actionNotification('Selected image has been removed.', 'success')
    });
    window.location.reload();
  }
  catch(err) {
    yield put({
      type: "REMOVE_USER_IMAGE_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* changePassword(action) {
  try {
    const data = action.payload;
    const changePass = yield axios.post('/auth/password/change/', data);
    yield put({
      type: "CHANGE_PASSWORD_SUCCESS",
      payload: changePass.data,
      meta: actionNotification('Your password has been changed.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "CHANGE_PASSWORD_FAILED",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data), 'error')
    });
  }
}
function* resetPassword(action) {
  try {
    const data = action.payload;
    const changePass = yield axios.post('/set-new-user-password/', data);
    yield put({
      type: "RESET_PASSWORD_SUCCESS",
      payload: changePass.data,
      meta: actionNotification('Your password has been changed.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "RESET_PASSWORD_FAILED",
      payload: err.status,
      meta: actionNotification(errorMessageData(err.data), 'error', 6000)
    });
  }
}
function* requestUpgradeToPremium() {
  try {
    const sendRequest = yield axios.post('/auth/user/request-premium-account/');
    yield put({
      type: "REQUEST_UPGRADE_TO_PREMIUM_SUCCESS",
      payload: sendRequest.data,
      meta: actionNotification('Your request has been sent.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "REQUEST_UPGRADE_TO_PREMIUM_FAILED",
      payload: err.status,
      meta: actionNotification('Oops, something went wrong! Try again later.', 'error')
    });
  }
}
function* loginAsUser(action) {
  try {
    const {id} = action.payload;
    const asUser = yield axios.post('/auth/user/login-as/', {id});
    yield put({
      type: "LOGIN_AS_USER_SUCCESS",
      payload: asUser.data
    });
    window.location.href = "/";
  }
  catch(err) {
    yield put({
      type: "LOGIN_AS_USER_FAILED",
      payload: null,
      meta: actionNotification(handleErrorResponse(err), 'error')
    })
    console.log(err);
  }
}
function* callReddClickEvent(action) {
  try {
    const {clickType, contentType, appLabel, objectId, params, notification = false} = action.payload;
    const timestamp = Math.floor(new Date().getTime() / 1000);
    const finalParams = {
      ...params,
      timestamp
    };
    const isDummyViewActive = window?.location?.pathname === "/redd-tower-demo";

    const data = {
      ...clickType ? {"click_type": clickType} : {},
      ...contentType ? {"content_type": contentType} : {},
      ...appLabel ? {"app_label": appLabel} : {},
      ...objectId ? {"object_id": objectId} : {},
      ...finalParams ? {"params": finalParams} : {},
      "sendCliqNotification": notification
    };

    if(!isDummyViewActive) {
      const response = yield axios.post(`redd-click/record/`, data);

      yield put({
        type: "TRIGGER_REDD_CLICK_EVENT_SUCCESS",
        payload: response.data,
      });
    }
  }
  catch (err) {
    console.log(err);
    yield put({
      type: "TRIGGER_REDD_CLICK_EVENT_FAILED",
      payload: err.status,
      meta: actionNotification(
        "Oops, something went wrong! Try again later.",
        "error"
      ),
    });
  }
}
function* adminCreateUser(action) {
  try {
    const {data} = action.payload;
    const newUser = yield axios.post('/user-create-form/', data);
    yield put({
      type: "ADMIN_CREATE_USER_SUCCESS",
      payload: newUser?.data,
      meta: actionNotification('User has been created.', 'success')
    });
  }
  catch(err) {
    yield put({
      type: "ADMIN_CREATE_USER_FAILED",
      payload: err.status,
      meta: actionNotification(handleErrorResponse(err.data?.userErrors?.[0]) || 'Oops, something went wrong! Try again later.', 'error')
    });
  }
}

// BACKGROUND ACTIONS
function* userAddWatchListItem(action) {
  yield put({type: 'USER_ADD_WATCH_LIST_ITEM_SUCCESS', payload: action.payload})
}
function* userRemoveWatchListItem(action) {
  yield put({type: 'USER_REMOVE_WATCH_LIST_ITEM_SUCCESS', payload: action.payload})
}

export function* saga() {
  yield takeLatest('FETCH_USER_DATA_REQUEST', fetchUserData);
  yield takeLatest('FETCH_OFFICES_MARKET_DISTRICTS_REQUEST', fetchOfficesMarketDistricts);
  yield takeLatest('FETCH_VISIBLE_TEAMS_REQUEST', fetchVisibleTeams);
  yield takeLatest('FETCH_VISIBLE_OWNERS_REQUEST', fetchVisibleOwners);
  yield takeLatest('FETCH_VISIBLE_MARKET_OWNERS_REQUEST', fetchVisibleMarketOwners);
  yield takeLatest('FETCH_VISIBLE_NEIGHBORHOODS_REQUEST', fetchVisibleNeighborhoods);
  yield takeLatest('FETCH_TEAM_BUILDINGS_REQUEST', fetchTeamBuildings);
  yield takeLatest('FETCH_VOIVODESHIP_REQUEST', fetchVoivodeships);
  yield takeLatest('FETCH_ALL_USERS_REQUEST', fetchAllUsers);
  yield takeLatest('FETCH_MARKETS_REQUEST', fetchMarkets);
  yield takeLatest('FETCH_MARKETS_INDUSTRIAL_REQUEST', fetchMarketsIndustrial);
  // UPDATE DATA
  yield takeLatest('UPDATE_USER_DATA_REQUEST', updateUserData);
  yield takeLatest('UPDATE_USER_MARKET_REQUEST', updateUserMarket);
  yield takeLatest('UPDATE_USER_LANG_CODE_REQUEST', updateUserLangCode);
  yield takeLatest('UPDATE_USER_QUESTIONNAIRE_CHANGE_REQUEST', updateUserQuestionnaireStatus);
  yield takeLatest('UPDATE_USER_AFFILIATE_CODE_VISIBLE_REQUEST', updateUserAffiliateCodeVisible);
  yield takeLatest('REMOVE_USER_IMAGE_REQUEST', removeUserImage);
  yield takeLatest('CHANGE_PASSWORD_REQUEST', changePassword);
  yield takeLatest('RESET_PASSWORD_REQUEST', resetPassword);
  yield takeLatest('USER_ADD_WATCH_LIST_ITEM_REQUEST', userAddWatchListItem);
  yield takeLatest('USER_REMOVE_WATCH_LIST_ITEM_REQUEST', userRemoveWatchListItem);
  yield takeLatest('REQUEST_UPGRADE_TO_PREMIUM_REQUEST', requestUpgradeToPremium);
  yield takeLatest('LOGIN_AS_USER_REQUEST', loginAsUser);
  yield takeLatest("TRIGGER_REDD_CLICK_EVENT_REQUEST", callReddClickEvent);
  yield takeLatest("ADMIN_CREATE_USER_REQUEST", adminCreateUser);
}

import React from "react";
import styles from "../index.module.scss";
import {toAbsoluteUrl} from "../../../../../_metronic";

export default function PaywallBlurContent({blurImage, style, ...props}) {
  return(
    <div className={styles.paywallBlurContentWrapper} style={style ? style : undefined}>
      <div className={styles.blurContentChildrenWrapper}>
        {props?.children}
      </div>
      {blurImage && <img src={toAbsoluteUrl(blurImage)} alt="REDD Pro" className={styles.blurImage}/>}
    </div>
  );
}
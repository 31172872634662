import React from "react";
import {Document, Font, Image, Link, Page, StyleSheet, Text, View} from "@react-pdf/renderer";
import {toAbsoluteUrl} from "_metronic";
import {toNumberFormat} from "app/utils/helpers";
import {BUILDING_STATUS} from "../../../../constants";
import FONTS_ALLOWED from "../../../../constants/FONTS_ALLOWED";

// Component to render Area value in proper format
class RenderAreaValue extends React.Component {
  render() {
    const {children, styles} = this.props;
    return(
      <View style={styles}>
        {children}
        <Text style={{fontSize: 9, marginLeft: 3, position: "relative", top: 0}}> m&sup2;</Text>
      </View>
    );
  }
}
// Function to convert number value to proper format
const numberFormat = (number, fixedMin = 0, fixedMax = 2) => {
  if(isNaN(number)) {
    return number;
  }
  return number.toLocaleString('en-US', {minimumFractionDigits: fixedMin, maximumFractionDigits: fixedMax}).replace(',', ' ');
};

class Footer extends React.Component {
  render() {
    const {states, paddingRight, paddingRightDouble} = this.props;
    const styles = StyleSheet.create({
      footer: {
        position: 'absolute',
        bottom: 30,
        left: 30,
        right: 30,
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%"
      },
      paddingRight: {
        paddingRight: 35
      },
      paddingRightDouble: {
        paddingRight: 70
      },
      footerText: {
        fontSize: 8,
        fontWeight: 300
      },
      pageNumber: {
        fontSize: 8,
        color: states.textColor,
      }
    });
    return(
      <View style={[styles.footer, paddingRight && styles.paddingRight, paddingRightDouble && styles.paddingRightDouble]} fixed>
        <Text style={styles.footerText}>All rights reserved. Presentation generated from REDD Platform.</Text>
        <View style={{flexGrow: 1, height: 1, marginHorizontal: 10, backgroundColor: colorSecondary}}/>
        <Text style={styles.pageNumber} render={({ pageNumber }) => (`${pageNumber}`)}/>
      </View>
    );
  }
}
class FrontPage extends React.Component {
  render() {
    const {pdfStyles, user, renderTranslation, states, intl} = this.props;
    let today = new Date();
    const yyyy = today.getFullYear();
    const month = today.toLocaleString(intl.locale, { month: 'long' });
    const monthCapitalized = intl.formatMessage({id: month.charAt(0).toUpperCase() + month.slice(1)});
    today = monthCapitalized + " " + yyyy;
    const styles = StyleSheet.create({
      frontPage: {
        paddingLeft: 0
      },
      firstPage: {
        position: "relative",
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start"
      },
      companyLogoBox: {
        position: "absolute",
        top: 0,
        right: 0,
        display: "block",
        width: "auto",
        height: 100
      },
      companyLogo: {
        maxWidth: 150,
        maxHeight: 52,
        width: "auto",
        height: "100%",
      },
      pdfTitleContainer: {
        position: "relative",
        width: "80%",
        height: 180,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
        paddingHorizontal: 35,
        paddingTop: 50,
        paddingBottom: 35,
        backgroundColor: states.frontBoxBgColor
      },
      pdfTitleContent: {
        flex: 1,
        flexDirection: "column",
      },
      pdfTitleCompany: {
        flex: 1,
        flexDirection: "column",
        paddingTop: 7
      },
      titleBackground: {
        width: "100%"
      },
      titleText: {
        fontSize: 35,
        fontWeight: 600,
        color: states.frontBoxTextColor,
        display: "block",
        lineHeight: 1
      },
      textThin: {
        fontSize: 32,
        fontWeight: 300
      },
      titleCompany: {
        fontSize: 22,
        fontWeight: 600,
        color: states.frontBoxTextColor,
        display: "block",
        lineHeight: 1,
        marginBottom: 10
      },
      titleDate: {
        fontSize: 17,
        fontWeight: 300,
        color: states.frontBoxTextColor,
        display: "block",
        lineHeight: 1
      }
    });
    return(
      <Page style={[pdfStyles.body, styles.frontPage]} orientation="landscape" wrap>
        <View style={styles.firstPage}>
          <View style={styles.companyLogoBox}>
            {user.companyLogo &&
            <Image
              style={styles.companyLogo}
              src={user.companyLogo}
            />
            }
          </View>
          <View style={styles.pdfTitleContainer}>
            <View style={styles.pdfTitleContent}>
              <Text style={[styles.titleText, styles.textThin]}>{renderTranslation("PDF.PHRASE.TITLE_1_LANDLORD")}</Text>
              <Text style={styles.titleText}>{renderTranslation("PDF.PHRASE.TITLE_2_LANDLORD")}</Text>
              <Text style={styles.titleText}>{renderTranslation("PDF.PHRASE.TITLE_3_LANDLORD")}</Text>
            </View>
            <View style={styles.pdfTitleCompany}>
              <Text style={[styles.titleCompany]}>{user.team && user.team.name}</Text>
              <Text style={[styles.titleDate]}>{today}</Text>
            </View>
          </View>
        </View>
        <Footer states paddingRight/>
      </Page>
    );
  }
}
class MarketsPage extends React.Component {
  render() {
    const {pdfStyles, states, user, data} = this.props;
    const styles = StyleSheet.create({
      marketBgContainer: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%"
      },
      marketBgImage: {
        width: "100%",
        height: "100%",
        opacity: 0.5
      },
      marketsContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: 400,
        marginTop: 70
      },
      marketBox: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: states.grayBoxesBgColor,
        width: 100,
        height: 100,
        borderRadius: 50,
        marginBottom: 20,
        marginHorizontal: 30
      },
      marketTitle: {
        fontSize: 14,
        color: states.grayBoxesTextColor,
        textAlign: "center",
        display: "block",
        width: "100%"
      },
      companyLogoBox: {
        position: "absolute",
        top: 35,
        right: 35,
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        width: "100%",
        height: 55,
        marginBottom: 30
      },
      companyLogo: {
        width: "auto",
        height: "100%",
      }
    });
    const pageBg = user.pdfCover ? user.pdfCover : toAbsoluteUrl("media/bg/bg-redd-intro.jpg");

    return(
      <Page orientation="landscape" wrap>
        <View style={styles.marketBgContainer}>
          <Image
            style={styles.marketBgImage}
            src={pageBg}
          />
        </View>
        <View style={[pdfStyles.body, {height: "100%"}]} wrap>
          <View style={styles.companyLogoBox}>
            {user.companyLogo &&
            <Image
              style={styles.companyLogo}
              src={user.companyLogo}
            />
            }
          </View>
          <View style={styles.marketsContainer}>
            {data && data.map((market, index) => (
            <View key={index} style={styles.marketBox}>
              <Text style={styles.marketTitle}>{Object.keys(market)[0]}</Text>
            </View>
            ))}
          </View>
        </View>
        <Footer states paddingRightDouble/>
      </Page>
    );
  }
}
class BuildingsPage extends React.Component {
  render() {
    const {pdfStyles, data, user, market, states, renderTranslation} = this.props;
    const styles = StyleSheet.create({
      buildingTitleContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%"
      },
      buildingTitle: {
        fontSize: 40,
        fontWeight: 600,
      },
      buildingsRowContainer: {
        justifyContent: "flex-start"
      },
      colBuilding: {
        display: "flex",
        direction: "column",
        width: "20%"
      },
      rowImageContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        height: 80,
        width: "100%",
        borderTop: 2,
        borderBottom: 2,
        borderLeft: 1,
        borderRight: 1,
        borderColor: "#ffffff"
      },
      rowImage: {
        height: "100%",
        width: "100%"
      },
      rowLogo: {
        height: 55,
        width: "auto"
      },
      rowLabel: {
        fontSize: 10,
        paddingVertical: 3,
        paddingHorizontal: 5,
        borderBottom: 1,
        borderRight: 1,
        borderColor: states.grayBoxesBgColor,
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "flex-end",
        width: "100%",
        height: 22,
      },
      rowLabelCaption: {
        backgroundColor: states.grayBoxesBgColor,
        color: states.grayBoxesTextColor,
        borderColor: "#ffffff"
      },
      rowLabelDoubleHeight: {
        height: 37
      },
      buildingLinkContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        padding: 5,
        backgroundColor: states.grayBoxesBgColor
      },
      buildingLink: {
        display: "block",
        textDecoration: "none",
        fontSize: 11,
        textAlign: "center",
        color: states.grayBoxesTextColor
      }
    });
    const buildingStatus = (status) => {
      if(status === BUILDING_STATUS.STATUS_EXISTS) {
        return renderTranslation("GENERAL.PHRASE.EXISTING");
      } else if(status === BUILDING_STATUS.STATUS_UNDER_CONSTRUCTION) {
        return renderTranslation("GENERAL.PHRASE.UNDER_CONSTRUCTION");
      } else if(status === BUILDING_STATUS.STATUS_PLANNED) {
        return renderTranslation("GENERAL.PHRASE.PLANNED");
      } else if(status === BUILDING_STATUS.STATUS_UNDER_REVITALIZATION) {
        return renderTranslation("GENERAL.PHRASE.UNDER_REVITALIZATION");
      } else if(status === BUILDING_STATUS.STATUS_UNDER_REDEVELOPMENT) {
        return renderTranslation("GENERAL.PHRASE.UNDER_REDEVELOPMENT");
      }
      return false;
    };
    const buildingRent = (rentFromEur, rentToEur) => {
      const from = rentFromEur ? toNumberFormat(rentFromEur) : null;
      const to = rentToEur ? toNumberFormat(rentToEur) : null;
      if(from && to) {
        return `${from} € - ${to} €`;
      } else if(from && !to) {
        return `${from} € - N/A`;
      } else if(!from && to) {
        return `N/A - ${to} €`;
      } else {
        return "N/A";
      }
    };
    const buildingCertificates = (building) => {
      const dgnb = building.dgnbCertification ? "DGNB" : null;
      const hqe = building.hqeCertification ? "HQE" : null;
      const well = building.wellCertification ? "WELL" : null;
      const breeam = building.breeamCertification ? "BREEAM" : null;
      const leed = building.leedCertification ? "LEED" : null;
      const certificates = [dgnb,hqe,well,breeam,leed];
      const renderCerts = certificates.filter(cert => cert).join(", ");
      return renderCerts ? renderCerts : "N/A";
    };
    const buildingParkingFactor = (factorNumber, factorArea) => {
      if(factorNumber && factorArea) {
        return `${factorNumber}/${factorArea}`;
      }
      return "N/A";
    };
    const buildingParkingRent = (groundRent, undergroundRent) => {
      if(groundRent && undergroundRent) {
        return `${groundRent} € / ${undergroundRent} €`;
      } else if(groundRent && !undergroundRent) {
        return `${groundRent} €/ N/A`;
      } else if(!groundRent && undergroundRent) {
        return `N/A / ${undergroundRent} €`;
      } else {
        return 'N/A';
      }
    };
    const getUrl = window.location;
    const baseUrl = getUrl.protocol + "//" + getUrl.host;

    return(
      <Page key={Math.random()} style={pdfStyles.body} size="A4" orientation="landscape" wrap>
        <View style={[pdfStyles.row, styles.buildingTitleContainer]}>
          <Text style={styles.buildingTitle}>{market}</Text>
        </View>
        <View style={[pdfStyles.row, styles.buildingsRowContainer]}>
          {data && data.map((building, index) =>
          <>
          {index % 4 === 0 && (
          <View key={Math.random()+index} style={styles.colBuilding} wrap break>
            <View style={styles.rowImageContainer}>
              {user.companyLogo &&
              <Image
                style={styles.rowLogo}
                src={user.companyLogo}
              />
              }
            </View>
            <Text style={[styles.rowLabel, styles.rowLabelDoubleHeight, styles.rowLabelCaption]}>{renderTranslation("PDF.PHRASE.LANDLORD.BUILDING_NAME")}</Text>
            <Text style={[styles.rowLabel, styles.rowLabelCaption]}>{renderTranslation("PDF.PHRASE.LANDLORD.ADDRESS")}</Text>
            <Text style={[styles.rowLabel, styles.rowLabelCaption]}>{renderTranslation("PDF.PHRASE.LANDLORD.NEIGHBOURHOOD")}</Text>
            <Text style={[styles.rowLabel, styles.rowLabelCaption]}>{renderTranslation("PDF.PHRASE.LANDLORD.BUILDING_STATUS")}</Text>
            <Text style={[styles.rowLabel, styles.rowLabelCaption]}>{renderTranslation("PDF.PHRASE.LANDLORD.TOTAL_AREA")}</Text>
            <Text style={[styles.rowLabel, styles.rowLabelCaption]}>{renderTranslation("PDF.PHRASE.LANDLORD.COMPLETION_YEAR")}</Text>
            <Text style={[styles.rowLabel, styles.rowLabelCaption]}>{renderTranslation("PDF.PHRASE.LANDLORD.BUILDING_CLASS")}</Text>
            <Text style={[styles.rowLabel, styles.rowLabelCaption]}>{renderTranslation("PDF.PHRASE.LANDLORD.CERTIFICATE")}</Text>
            <Text style={[styles.rowLabel, styles.rowLabelDoubleHeight, styles.rowLabelCaption]}>{renderTranslation("PDF.PHRASE.LANDLORD.PARKING_SLOT_GROUND_UNDERGROUND", "\n")}</Text>
            <Text style={[styles.rowLabel, styles.rowLabelCaption]}>{renderTranslation("PDF.PHRASE.LANDLORD.TOTAL_AVAILABLE_AREA")}</Text>
            <Text style={[styles.rowLabel, styles.rowLabelCaption]}>{renderTranslation("PDF.PHRASE.LANDLORD.OFFICE_RENT")}</Text>
            <Text style={[styles.rowLabel, styles.rowLabelCaption]}>{renderTranslation("PDF.PHRASE.LANDLORD.RETAIL_RENT")}</Text>
            <Text style={[styles.rowLabel, styles.rowLabelCaption]}>{renderTranslation("PDF.PHRASE.LANDLORD.SERVICE_CHARGE")}</Text>
            <Text style={[styles.rowLabel, styles.rowLabelCaption]}>{renderTranslation("PDF.PHRASE.LANDLORD.COMMON_AREA_FACTOR")}</Text>
            <Text style={[styles.rowLabel, styles.rowLabelCaption]}>{renderTranslation("PDF.PHRASE.LANDLORD.PARKING_AREA_FACTOR")}</Text>
            <Text style={[styles.rowLabel, styles.rowLabelCaption, {borderColor: states.grayBoxesBgColor}]}>{renderTranslation("PDF.PHRASE.LANDLORD.MIN_RENTAL_YEARS")}</Text>
          </View>
          )}
          <View key={Math.random()+index} style={styles.colBuilding} wrap>
            <View style={styles.rowImageContainer}>
              {building.mainPhotoPdfThumbnail &&
              <Image
                style={styles.rowImage}
                src={building.mainPhotoPdfThumbnail}
              />
              }
            </View>
            <Text style={[styles.rowLabel, styles.rowLabelDoubleHeight, styles.rowLabelCaption]}>{building.name}</Text>
            <Text style={styles.rowLabel}>{building.addressStreet}</Text>
            <Text style={styles.rowLabel}>{building.district}</Text>
            <Text style={styles.rowLabel}>{buildingStatus(building.status)}</Text>
            <RenderAreaValue styles={styles.rowLabel}>
              <Text style={{fontSize: 10}}>{numberFormat(building.totalArea)}</Text>
            </RenderAreaValue>
            <Text style={styles.rowLabel}>{building.commissioningYear}</Text>
            <Text style={styles.rowLabel}>{building.buildingClass}</Text>
            <Text style={styles.rowLabel}>{buildingCertificates(building)}</Text>
            <Text style={[styles.rowLabel, styles.rowLabelDoubleHeight]}>{buildingParkingRent(building.parkingGroundRentEur, building.parkingUndergroundRentEur)}</Text>
            <RenderAreaValue styles={styles.rowLabel}>
              <Text style={{fontSize: 10}}>{numberFormat(building.totalAreaNoSubleaseOfficeAvailable)}</Text>
            </RenderAreaValue>
            <Text style={styles.rowLabel}>{buildingRent(building.rentFromEur, building.rentToEur)}</Text>
            <Text style={styles.rowLabel}>{buildingRent(building.retailRentPriceEur, building.retailRentPriceToEur)}</Text>
            <Text style={styles.rowLabel}>{toNumberFormat(building.serviceChargesPln)} PLN</Text>
            <Text style={[styles.rowLabel]}>{toNumberFormat(building.commonAreaFactor)}%</Text>
            <Text style={[styles.rowLabel]}>{buildingParkingFactor(building.parkingFactorNumber, building.parkingFactorArea)}</Text>
            <Text style={[styles.rowLabel]}>{building.minRentalYears}</Text>
            <Text style={[styles.buildingLinkContainer]}>
              <Link src={`${baseUrl}/building/${building.id}`} style={styles.buildingLink}>Property Particulars ></Link>
            </Text>
          </View>
          </>
          )}
        </View>
        <Footer states/>
      </Page>
    );
  }
}
class ContactPage extends React.Component {
  render() {
    const {pdfStyles, user} = this.props;
    const userAvatar = user.avatar ? user.avatar : toAbsoluteUrl("media/office-placeholder.jpg");
    const styles = StyleSheet.create({
      contactContainer: {
        height: "100%",
        justifyContent: "center",
        alignItems: "center"
      },
      personName: {
        fontSize: 16,
        fontWeight: 400
      },
      personContact: {
        fontSize: 14,
        fontWeight: 300
      },
      personPosition: {
        fontSize: 12,
        fontWeight: 400
      },
      personCompany: {
        fontSize: 12,
        fontWeight: 400,
        marginBottom: 10
      }
    });
    return(
        <Page style={pdfStyles.body} size="A4" orientation="landscape" wrap>
          <View style={[pdfStyles.column, styles.contactContainer]}>
            <View style={{width: "30%", marginBottom: 10}}>
              <Image
                style={styles.buildingImage}
                src={userAvatar}
              />
            </View>
            <Text style={styles.personName}>{`${user.firstName} ${user.lastName}`}</Text>
            <Text style={styles.personPosition}>{user.position}</Text>
            <Text style={styles.personCompany}>{user.team ? user.team.name : user.companyName}</Text>
            <Text style={styles.personContact}>{user.email}</Text>
            <Text style={styles.personContact}>{user.phoneNumber}</Text>
          </View>
          <Footer states/>
        </Page>
    );
  }
}

// Default PDF styles
const colorPrimary = "#5D6472";
const colorSecondary = "#d9d9d9";
const pdfFontFamily = "Poppins";

export default class LandlordBuildingsPdfDocument extends React.Component {
  constructor(props) {
    super(props);
    const {user} = props;
    // Set initial PDF styles states
    this.state = {
      frontBoxBgColor: user.pdfFrontPageBoxBg || colorPrimary,
      frontBoxTextColor: user.pdfFrontPageBoxText || "#ffffff",
      grayBoxesBgColor: user.pdfGreyBoxesBg || colorSecondary,
      grayBoxesTextColor: user.pdfGreyBoxesText || colorPrimary,
      h1Color: user.pdfH1Text || colorPrimary,
      h2Color: user.pdfH2Text || colorPrimary,
      textColor: user.pdfPText || colorPrimary,
      textFontFamily: user.pdfFontFamily || pdfFontFamily
    }
  }
  render() {
    const { data, user, intl } = this.props;
    const {textFontFamily} = this.state;
    // Register fonts
    FONTS_ALLOWED.map(font => Font.register(font.code));
    // Primary PDF styles
    const styles = StyleSheet.create({
      body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
        fontFamily: textFontFamily,
        color: this.state.textColor,
        position: "relative"
      },
      subtitle: {
        fontSize: 16,
        fontWeight: 400,
        marginBottom: 5,
        color: this.state.h2Color
      },
      row: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
        flexWrap: "wrap"
      },
      column: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-start"
      },
      label: {
        fontSize: 10,
        fontWeight: 300,
        textTransform: "uppercase"
      },
      labelWithBg: {
        display: "block",
        width: "100%",
        fontSize: 12,
        fontWeight: 300,
        backgroundColor: this.state.grayBoxesBgColor,
        color: this.state.grayBoxesTextColor,
        padding: 3
      },
      labelValue: {
        fontSize: 13,
        fontWeight: 400
      },
    });
    // Function to translate strings
    const handleStringTranslation = (id, br) => {
      return intl.formatMessage({id: id}, {br})
    };

    return(
      <Document>
        <FrontPage states={this.state} pdfStyles={styles} user={user} intl={intl} renderTranslation={handleStringTranslation}/>
        <MarketsPage states={this.state} data={data} user={user} pdfStyles={styles} renderTranslation={handleStringTranslation}/>
        {data.map((market) =>
          <BuildingsPage key={Math.random()} market={Object.keys(market)[0]} states={this.state} data={market[Object.keys(market)[0]]} user={user} pdfStyles={styles} renderTranslation={handleStringTranslation}/>
        )}
        <ContactPage states={this.state} pdfStyles={styles} user={user}/>
      </Document>
    );
  }
}
import React from 'react';
import {Page, StyleSheet, Font} from '@react-pdf/renderer';
import RobotoLight from "app/assets/fonts/Roboto-Light.ttf";
import RobotoRegular from "app/assets/fonts/Roboto-Regular.ttf";
import RobotoMedium from "app/assets/fonts/Roboto-Medium.ttf";
import RobotoBold from "app/assets/fonts/Roboto-Bold.ttf";

// Register fonts that support Polish characters
Font.register({
  family: 'Roboto',
  fonts: [
    {src: RobotoRegular, fontWeight: 'normal'},
    {src: RobotoLight, fontWeight: 'light'},
    {src: RobotoMedium, fontWeight: 'medium'},
    {src: RobotoBold, fontWeight: 'bold'},
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: 36,
    paddingTop: 46,
    paddingBottom: 46,
    fontFamily: 'Roboto',
    fontSize: 9,
    width: 595.28, // A4 width in points (72 points per inch)
  }
});

// Create Document Component
function OfferPdfPage(props) {
  return (
    <Page
      size="A4"
      style={[
        styles.page,
        props?.buildingPage ? styles.pageBuilding : undefined,
      ]}
    >
      {props?.children}
    </Page>
  );
};

export default OfferPdfPage;


import React from "react";
import ModalCustom from "../ModalCustom";
import IPI from "./index";
import {FormattedMessage, injectIntl} from "react-intl";
import styles from "./index.module.scss";
import componentsStyles from "./components/index.module.scss";
import {Typography} from "@material-ui/core";
import UploadIpiPlan from "./components/UploadIpiPlan";
import IPI_PLAN_SOURCE from "../../../constants/IPI_PLAN_SOURCE";
import connect from "react-redux/es/connect/connect";
import LoadingScreen from "../LoadingScreen";
import Button from "../Button";
import Icon from "../Icon";

class ModalIPI extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      savingIPI: false,
      title: props?.title,
      ipiHasBeenChanged: false
    }
  }

  handleSaveIpi = () => {
    this.setState({
      savingIPI: true
    });
  }
  handleSavedIpi = () => {
    this.setState({
      savingIPI: false,
      ipiHasBeenChanged: false
    });
  }
  handleCloseModal = () => {
    this.setState({
      ipiHasBeenChanged: false
    });
  };
  handleUpdateTitle = (title) => {
    this.setState({
      title
    });
  }
  handleIpiHasBeenChanged = (changed) => {
    this.setState({
      ipiHasBeenChanged: changed
    });
  }

  render() {
    const {
      planSource,
      planPark,
      planBuilding,
      parkID,
      buildingID,
      modalTrigger,
      btnLabel,
      btnColor,
      btnStyle,
      ipiUnitsUpdating,
      units,
      unitId,
      isEditable
    } = this.props;
    const {savingIPI, title, ipiHasBeenChanged} = this.state;
    const ipiPlan = planSource === IPI_PLAN_SOURCE.WAREHOUSE ? (planBuilding ? planBuilding : planPark) : planSource === IPI_PLAN_SOURCE.PARK && planPark;
    const activePlanWorkspace =
      planSource === IPI_PLAN_SOURCE.WAREHOUSE && planBuilding ? IPI_PLAN_SOURCE.WAREHOUSE :
        planSource === IPI_PLAN_SOURCE.WAREHOUSE && !planBuilding && planPark ? IPI_PLAN_SOURCE.PARK :
          planSource === IPI_PLAN_SOURCE.WAREHOUSE && !planBuilding && !planPark ? null :
            planSource === IPI_PLAN_SOURCE.PARK && planPark ? IPI_PLAN_SOURCE.PARK : null;

    return (
      <ModalCustom
        ref={this.child}
        btn={modalTrigger ? modalTrigger :
          <Button
            type={"icon"}
            color={btnColor ? btnColor : "danger"}
            tooltip={btnLabel ? btnLabel : <FormattedMessage id="INDUSTRIAL.PHRASE.TABS.INTERACTIVE_PLAN"/>}
          >
            <Icon icon={"plan"}/>
          </Button>
        }
        btnConfirm={isEditable && ipiPlan && <FormattedMessage id="GENERAL.PHRASE.SAVE_CHANGES"/>}
        handleSave={isEditable && ipiPlan && this.handleSaveIpi}
        handleClose={this.handleCloseModal}
        fullScreen
        closeConfirmationRequired={isEditable && ipiHasBeenChanged}
        btnStyle={btnStyle}
      >
        {ipiPlan ? (
          <div className={componentsStyles.planUploadWrapper}>
            <IPI
              title={title}
              image={ipiPlan}
              unitId={unitId}
              units={units}
              planSource={planSource}
              planWorkspace={activePlanWorkspace}
              parkID={parkID}
              buildingID={buildingID}
              savingIPI={savingIPI}
              isEditable={isEditable}
              onSavedIPI={this.handleSavedIpi}
              onActiveUnitChanged={this.handleUpdateTitle}
              onChange={this.handleIpiHasBeenChanged}
            />
            {ipiUnitsUpdating &&
              <div className={componentsStyles.planUploading}><LoadingScreen/></div>
            }
          </div>
        ) : (
          <div className={styles.noPlanWrapper}>
            <div className={styles.noPlanContent}>
              <Typography variant="h2"><FormattedMessage
                id={planSource === IPI_PLAN_SOURCE.WAREHOUSE ? "INDUSTRIAL.PHRASE.IPI_UPLOAD_PLAN_MESSAGE" : "INDUSTRIAL.PHRASE.IPI_UPLOAD_PARK_PLAN_MESSAGE"}/></Typography>
            </div>
            <div className={styles.uploadPlanOptionsWrapper}>
              <UploadIpiPlan
                planTarget={IPI_PLAN_SOURCE.PARK}
                planTargetID={parkID}
                planSource={planSource}
                planSourceID={planSource === IPI_PLAN_SOURCE.WAREHOUSE ? buildingID : planSource === IPI_PLAN_SOURCE.PARK && parkID}
                btnLabel={<FormattedMessage id="INDUSTRIAL.PHRASE.UPLOAD_IPI_PARK_PLAN"/>}
              />
              {planSource === IPI_PLAN_SOURCE.WAREHOUSE &&
                <UploadIpiPlan
                  planTarget={IPI_PLAN_SOURCE.WAREHOUSE}
                  planTargetID={buildingID}
                  planSource={planSource}
                  planSourceID={planSource === IPI_PLAN_SOURCE.WAREHOUSE ? buildingID : planSource === IPI_PLAN_SOURCE.PARK && parkID}
                  btnLabel={<FormattedMessage id="INDUSTRIAL.PHRASE.UPLOAD_IPI_WAREHOUSE_PLAN"/>}
                />
              }
            </div>
          </div>
        )}
      </ModalCustom>
    );
  }
}

const mapStateToProps = (store) => ({
  parkUnits: store.industrial.parkUnits,
  parkUnitsLoading: store.industrial.parkUnitsLoading,
  warehouseUnits: store.industrial.warehouseUnits,
  warehouseUnitsLoading: store.industrial.warehouseUnitsLoading,
  ipiUnitsUpdating: store.industrial.ipiUnitsUpdating
});
export default injectIntl(
  connect(
    mapStateToProps,
    null
  )(ModalIPI)
)
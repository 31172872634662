import React from "react";
import {FormattedMessage} from "react-intl";
import {toDateFormat, toNumberFormat} from "../../../../../utils/helpers";
import {DataPreviewContainer, DataPreviewItem} from "../../../SimpleDataPreview";
import LoadingScreen from "../../../LoadingScreen";
import {Grid, Typography} from "@material-ui/core";

export default class EditUnitSubleaseTerms extends React.Component {
  render() {
    const { data } = this.props;
    if(data) {
      const {
        subleaseRent,
        subleaseRentToNegotiate,
        subleaseAvailableFrom,
        subleaseAvailableTo,
        subleaseParkingPlaces,
        subleaseServiceCharge,
        subleaseServiceChargesCurrency
      } = data;
      const subleaseRentValue = subleaseRentToNegotiate ? (
        <FormattedMessage id="BUILDING.PHRASE.TO_NEGOTIATE" />
      ) : (
        toNumberFormat(subleaseRent)
      );

      return (
        <Grid container spacing={2} direction="column">
          <Grid item xs={12}>
            <Typography variant="h3" style={{color: "#ff4626"}}><FormattedMessage id="VOCABULARY.Sublease terms"/></Typography>
          </Grid>
          <Grid item xs={6}>
            <DataPreviewContainer>
              <DataPreviewItem
                title={<FormattedMessage id="BUILDING.PHRASE.RENT_FROM" />}
                value={subleaseRentValue}
                solid
              />
              <DataPreviewItem
                title={<FormattedMessage id="BUILDING.PHRASE.SERVICE_CHARGE" />}
                value={toNumberFormat(subleaseServiceCharge, 'currency', `${subleaseServiceChargesCurrency}`)}
                solid
              />
              <DataPreviewItem
                title={<FormattedMessage id="BUILDING.PHRASE.AVAILABLE_FROM" />}
                value={toDateFormat(subleaseAvailableFrom)}
                solid
              />
              <DataPreviewItem
                title={<FormattedMessage id="BUILDING.PHRASE.AVAILABLE_TO" />}
                value={toDateFormat(subleaseAvailableTo)}
                solid
              />
              <DataPreviewItem
                title={<FormattedMessage id="BUILDING.PHRASE.PARKING_PLACES" />}
                value={toNumberFormat(subleaseParkingPlaces)}
                solid
              />
            </DataPreviewContainer>
          </Grid>
        </Grid>
      );
    }
    return <LoadingScreen/>;
  }
}
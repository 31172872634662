import React from "react";
import {Portlet, PortletBody, PortletHeader} from "app/partials/content/Portlet";
import {MenuItem, Select, TextField, Typography} from "@material-ui/core";
import clsx from "clsx";
import {FormattedMessage, injectIntl} from "react-intl";
import styles from "../index.module.scss";
import {InputUpload} from "../../../../partials/components/InputUpload";
import FONTS from "../../../../constants/FONTS_ALLOWED";
import connect from "react-redux/es/connect/connect";
import {callReddClickEvent} from "app/crud/user.crud";

const colorPrimary = "#5D6472";
const colorSecondary = "#d9d9d9";

class UserPersonalizePDF extends React.Component {
  constructor(props) {
    super(props);
    const {user} = props;
    this.state = {
      pdfFrontPageBoxBg: user.pdfFrontPageBoxBg || colorPrimary,
      pdfFrontPageBoxText: user.pdfFrontPageBoxText || '#ffffff',
      pdfGreyBoxesBg: user.pdfGreyBoxesBg || colorSecondary,
      pdfGreyBoxesText: user.pdfGreyBoxesText || colorPrimary,
      pdfH1Text: user.pdfH1Text || colorPrimary,
      pdfH2Text: user.pdfH2Text || colorPrimary,
      pdfPText: user.pdfPText || colorPrimary,
      pdfCover: user.pdfCover,
      pdfFontFamily: user.pdfFontFamily || "Poppins",
      filePdfCover: null,
      selectOpen: false,
      isReady: false
    };
  }
  componentDidMount() {
    if(!this.state.isReady) {
      this.setState({
        isReady: true
      }, () => this.props.dispatch(callReddClickEvent(
        "user-customize-pdf-view",
        "user"
      )));
    }
  }

  handleSave = () => {
    const {onSave} = this.props;
    const {
      pdfFrontPageBoxBg,
      pdfFrontPageBoxText,
      pdfGreyBoxesBg,
      pdfGreyBoxesText,
      pdfH1Text,
      pdfH2Text,
      pdfPText,
      pdfFontFamily
    } = this.state;
    const data = {
      pdfFrontPageBoxBg,
      pdfFrontPageBoxText,
      pdfGreyBoxesBg,
      pdfGreyBoxesText,
      pdfH1Text,
      pdfH2Text,
      pdfPText,
      pdfFontFamily
    };
    onSave(data, null, null, this.state.filePdfCover);
  };
  handleRestoreDefault = () => {
    const {onSave} = this.props;
    this.setState({
      pdfFrontPageBoxBg: colorPrimary,
      pdfFrontPageBoxText: '#ffffff',
      pdfGreyBoxesBg: colorSecondary,
      pdfGreyBoxesText: colorPrimary,
      pdfH1Text: colorPrimary,
      pdfH2Text: colorPrimary,
      pdfPText: colorPrimary,
      pdfCover: null,
      pdfFontFamily: "Poppins"
    }, () => {
      const {
        pdfFrontPageBoxBg,
        pdfFrontPageBoxText,
        pdfGreyBoxesBg,
        pdfGreyBoxesText,
        pdfH1Text,
        pdfH2Text,
        pdfPText,
        pdfCover,
        pdfFontFamily
      } = this.state;
      const data = {
        pdfFrontPageBoxBg,
        pdfFrontPageBoxText,
        pdfGreyBoxesBg,
        pdfGreyBoxesText,
        pdfH1Text,
        pdfH2Text,
        pdfPText,
        pdfCover,
        pdfFontFamily
      };
      onSave(data);
    });
  };
  handleColorPicker = e => {
    const selectType = e.target.type;
    const selectedColor = e.target.value;
    const selectedInputColor = e.target.id;
    const selectedInputText = e.target.name;
    if(selectType === "color") {
      this.setState({
        [selectedInputColor]: selectedColor
      });
    } else if(selectType === "text") {
      this.setState({
        [selectedInputText]: selectedColor
      });
    }
  };
  handlePdfCoverUpload = filePdfCover => {
    this.setState({
      filePdfCover
    });
  };
  handleToggleSelect = () => {
    this.setState({
      selectOpen: !this.state.selectOpen
    });
  };
  handleChangeFont = (event) => {
    this.setState({
      pdfFontFamily: event.target.value
    });
  };

  render() {
    const {
      pdfFrontPageBoxBg,
      pdfFrontPageBoxText,
      pdfGreyBoxesBg,
      pdfGreyBoxesText,
      pdfH1Text,
      pdfH2Text,
      pdfPText,
      pdfCover,
      pdfFontFamily,
      selectOpen
    } = this.state;
    return(
      <Portlet fluidHeight>
        <PortletHeader title={<Typography variant="h4"><FormattedMessage id="PDF.PHRASE.PERSONALIZE_PDF"/></Typography>}/>
        <PortletBody>
          <Typography variant="h4" style={{marginBottom: 35}}><FormattedMessage id="PDF.PHRASE.PERSONALIZE_PDF_INFO"/></Typography>
          <Typography variant="h4"><FormattedMessage id="PDF.PHRASE.FRONT_PAGE_COLORS"/></Typography>
          <div className={clsx("form-group", styles.inputGroupPdfStyles)}>
            <div className={styles.inputPdfPickerContainer}>
              <TextField
                type="text"
                margin="normal"
                label={<FormattedMessage id="PDF.PHRASE.FRONT_PAGE_BOX_BG"/>}
                name="pdfFrontPageBoxBg"
                value={pdfFrontPageBoxBg}
                onChange={this.handleColorPicker}
              />
              <input type="color" value={pdfFrontPageBoxBg} id="pdfFrontPageBoxBg" className={styles.inputPdfColorPicker} onChange={this.handleColorPicker}/>
            </div>
            <div className={styles.inputPdfPickerContainer}>
              <TextField
                type="text"
                margin="normal"
                label={<FormattedMessage id="PDF.PHRASE.FRONT_PAGE_BOX_TEXT"/>}
                name="pdfFrontPageBoxText"
                value={pdfFrontPageBoxText}
                onChange={this.handleColorPicker}
              />
              <input type="color" value={pdfFrontPageBoxText} id="pdfFrontPageBoxText" className={styles.inputPdfColorPicker} onChange={this.handleColorPicker}/>
            </div>
          </div>
          <Typography variant="h4"><FormattedMessage id="PDF.PHRASE.GREY_BOXES_COLORS"/></Typography>
          <div className={clsx("form-group", styles.inputGroupPdfStyles)}>
            <div className={styles.inputPdfPickerContainer}>
              <TextField
                type="text"
                margin="normal"
                label={<FormattedMessage id="PDF.PHRASE.GREY_BOXES_BG"/>}
                name="pdfGreyBoxesBg"
                value={pdfGreyBoxesBg}
                onChange={this.handleColorPicker}
              />
              <input type="color" value={pdfGreyBoxesBg} id="pdfGreyBoxesBg" className={styles.inputPdfColorPicker} onChange={this.handleColorPicker}/>
            </div>
            <div className={styles.inputPdfPickerContainer}>
              <TextField
                type="text"
                margin="normal"
                label={<FormattedMessage id="PDF.PHRASE.GREY_BOXES_TEXT"/>}
                name="pdfGreyBoxesText"
                value={pdfGreyBoxesText}
                onChange={this.handleColorPicker}
              />
              <input type="color" value={pdfGreyBoxesText} id="pdfGreyBoxesText" className={styles.inputPdfColorPicker} onChange={this.handleColorPicker}/>
            </div>
          </div>
          <Typography variant="h4"><FormattedMessage id="PDF.PHRASE.TYPOGRAPHY_COLORS"/></Typography>
          <div className={clsx("form-group", styles.inputGroupPdfStyles)}>
            <div className={styles.inputPdfPickerContainer}>
              <TextField
                type="text"
                margin="normal"
                label={<FormattedMessage id="PDF.PHRASE.H1_TEXT"/>}
                name="pdfH1Text"
                value={pdfH1Text}
                onChange={this.handleColorPicker}
              />
              <input type="color" value={pdfH1Text} id="pdfH1Text" className={styles.inputPdfColorPicker} onChange={this.handleColorPicker}/>
            </div>
            <div className={styles.inputPdfPickerContainer}>
              <TextField
                type="text"
                margin="normal"
                label={<FormattedMessage id="PDF.PHRASE.H2_TEXT"/>}
                name="pdfH2Text"
                value={pdfH2Text}
                onChange={this.handleColorPicker}
              />
              <input type="color" value={pdfH2Text} id="pdfH2Text" className={styles.inputPdfColorPicker} onChange={this.handleColorPicker}/>
            </div>
            <div className={styles.inputPdfPickerContainer}>
              <TextField
                type="text"
                margin="normal"
                label={<FormattedMessage id="PDF.PHRASE.P_TEXT"/>}
                name="pdfPText"
                value={pdfPText}
                onChange={this.handleColorPicker}
              />
              <input type="color" value={pdfPText} id="pdfPText" className={styles.inputPdfColorPicker} onChange={this.handleColorPicker}/>
            </div>
          </div>
          <Typography variant="h4"><FormattedMessage id="PDF.PHRASE.FONT_FAMILY"/></Typography>
          <div className={clsx("form-group", styles.inputGroupPdfStyles)}>
            <div className={styles.inputPdfPickerContainer} style={{marginTop: 16, marginBottom: 8}}>
              <Select
                open={selectOpen}
                onClose={this.handleToggleSelect}
                onOpen={this.handleToggleSelect}
                value={pdfFontFamily}
                onChange={this.handleChangeFont}
                inputProps={{
                  name: 'pdfFontFamily',
                  id: 'pdf_font_select',
                }}
                style={{fontFamily: pdfFontFamily}}
              >
                <MenuItem value="" disabled>
                  <em>Select font</em>
                </MenuItem>
                {FONTS.map((font, index) => {
                  return(
                    <MenuItem key={index} value={font.name} style={{fontFamily: font.name}}>{font.name}</MenuItem>
                  );
                })}
              </Select>
            </div>
          </div>
          <>
          <Typography variant="h4" style={{marginBottom: 15}}><FormattedMessage id="PDF.PHRASE.BACKGROUND_IMAGE"/></Typography>
          <div className={clsx("form-group")}>
            <div>
              <InputUpload
                currentFile={pdfCover}
                onFileUpload={this.handlePdfCoverUpload}
                width="100%"
                height="100%"
                noFrame
                responsiveBox
                fileTarget="pdfCover"
              />
            </div>
          </div>
          </>

          <div className="kt-login__actions">
            <button
              id="kt_login_signin_submit"
              type="button"
              className="btn btn-primary btn-elevate kt-login__btn-primary"
              onClick={this.handleSave}
              style={{marginRight: 10}}
            >
              {<FormattedMessage id="GENERAL.PHRASE.SAVE_CHANGES"/>}
            </button>
            <button
              type="button"
              className="btn btn-secondary btn-elevate kt-login__btn-primary"
              onClick={this.handleRestoreDefault}
            >
              {<FormattedMessage id="GENERAL.PHRASE.RESET_AND_SAVE"/>}
            </button>
          </div>
        </PortletBody>
      </Portlet>
    );
  }
}

export default injectIntl(connect()(UserPersonalizePDF));
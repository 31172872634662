import React from "react";
import {Grid, Typography} from "@material-ui/core";
import {Portlet, PortletHeader} from "../../content/Portlet";
import BadgeTitle from "../BadgeTitle";
import {FormattedMessage, injectIntl} from "react-intl";
import {getBaseUrl, handleGoogleAnalyticsTracking} from "../../../utils/helpers";
import PropertyParticularsManageProperties from "./PropertyParticularsManageProperties";
import connect from "react-redux/es/connect/connect";
import {callReddClickEvent} from "app/crud/user.crud";
import SharePropertyParticular from "../SharePropertyParticular/SharePropertyParticular";
import APPLICATION_VIEWS from "../../../constants/APPLICATION_VIEWS";
import Button from "../Button";

class PropertyParticularsAnalysisCTA extends React.Component {
  constructor(props) {
    super(props);
    const {teamID, route} = this.props;
    const baseUrl = getBaseUrl(window.location);
    const ppLink = `${baseUrl}${route}/${teamID}/`;
    this.state = {
      ppLink,
      copied: false
    };
  }

  render() {
    const {teamID, target, currentApplicationView} = this.props;
    const {ppLink} = this.state;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    const isIndustrialView = currentApplicationView === APPLICATION_VIEWS.INDUSTRIAL;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Portlet fluidHeight={true}>
            <PortletHeader
              title={
                <Typography variant="h3">
                  <BadgeTitle badge="Property Particulars">Online</BadgeTitle>
                </Typography>
              }
              toolbar={
                <div style={{display: "flex"}}>
                  <Grid container spacing={1} alignItems="center">
                    {teamID &&
                      <Grid item>
                        <PropertyParticularsManageProperties
                          teamID={teamID}
                          target={target}
                        />
                      </Grid>
                    }
                    {teamID &&
                      <Grid item>
                        <Button
                          color={"simple"}
                          redirect={ppLink}
                          redirectTarget="_blank"
                          onClick={() => {
                            handleGoogleAnalyticsTracking('Button', 'Click', 'Go To Property Particulars - PP Page');
                            isOfficeView && this.props.dispatch(callReddClickEvent(
                              "property-particulars-pp-online-button",
                              "team"
                            ));
                            isIndustrialView && this.props.dispatch(callReddClickEvent(
                              "property-particulars-industrial-pp-online-button",
                              "team"
                            ));
                          }}
                        >
                          <FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.REDIRECT_TO_PP_ONLINE"/>
                        </Button>
                      </Grid>
                    }
                    {teamID &&
                      <Grid item>
                        <SharePropertyParticular
                          target={
                            currentApplicationView === APPLICATION_VIEWS.INDUSTRIAL
                              ? "industrial"
                              : "offices"
                          }
                          onOpen={() => {
                            isOfficeView && this.props.dispatch(callReddClickEvent(
                              "property-particulars-share-button",
                              "team"
                            ));
                            isIndustrialView && this.props.dispatch(callReddClickEvent(
                              "property-particulars-industrial-share-button",
                              "team"
                            ));
                          }}
                          ppLink={ppLink}
                        />
                      </Grid>
                    }
                  </Grid>
                </div>
              }
            />
          </Portlet>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  currentApplicationView: state?.user?.data?.currentApplicationView
});
export default injectIntl(connect(mapStateToProps)(PropertyParticularsAnalysisCTA));
import React from "react";
import styles from "../index.module.scss";
import {toAbsoluteUrl} from "../../../../../_metronic";

export default function PaywallLogo({brand}) {
  const logoUrl = brand ? "media/branding/redd-logo-neon-200px.png" : "media/branding/redd-logo-white-200px.png";

  return(
    <div className={styles.paywallHeaderLogoWrapper}>
      <img src={toAbsoluteUrl(logoUrl)} alt="REDD App"/>
      <span className={styles.proBadge}>PRO</span>
    </div>
  );
}
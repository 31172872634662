import React, {Component} from "react";
import {Formik} from "formik";
import {connect} from "react-redux";
import {TextField} from "@material-ui/core";
import {Link} from "react-router-dom";
import {FormattedMessage, injectIntl} from "react-intl";
import {authResetPassword} from "app/crud/authApp.crud";
import PasswordResetMailSendConfirmation from "app/pages/auth/PasswordReset/PasswordResetMailSendConfirmation";
import styles from "./index.module.scss";
import clsx from "clsx";

class ForgotPassword extends Component {
  constructor(props){
    super(props);
    this.state = {
      sendConfirm: false
    }
  }
  render() {
    const { intl, authResetPassword, loading } = this.props;
    // Display Confirmation Message after mail with next step has been sent
    if(this.state.sendConfirm) {
      return <PasswordResetMailSendConfirmation/>
    }
    return (
      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__title">
            <h3>
              <FormattedMessage id="AUTH.FORGOT.TITLE" />
            </h3>
          </div>

          <Formik
            initialValues={{ email: "" }}
            validate={values => {
              const errors = {};

              if (!values.email) {
                errors.email = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = intl.formatMessage({
                  id: "AUTH.VALIDATION.INVALID_FIELD"
                });
              }

              return errors;
            }}
            onSubmit={(values) => {
              // On form Submit, send mail via API, with next steps
              authResetPassword(values.email);
              this.setState({
                sendConfirm: true
              })
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit
            }) => (
              <form
                noValidate={true}
                autoComplete="off"
                onSubmit={handleSubmit}
                className="kt-form"
              >
                {status && (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                )}

                <div className="form-group">
                  <TextField
                    type="email"
                    label="Email"
                    margin="normal"
                    fullWidth={true}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    helperText={touched.email && errors.email}
                    error={Boolean(touched.email && errors.email)}
                    className={styles.customInput}
                  />
                </div>

                <div className={styles.contentActions}>
                  <Link to="/auth">
                    <FormattedMessage id="AUTH.GENERAL.BACK_BUTTON"/>
                  </Link>
                  <button
                    type="submit"
                    className={clsx("btn btn-primary btn-elevate kt-login__btn-primary", styles.btnSubmit)}
                    disabled={loading}
                  >
                    <FormattedMessage id="FORM.BUTTON.SUBMIT"/>
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  loading: store.authApp.loading
});
const mapDispatchToProps = {
  authResetPassword: (email) => authResetPassword(email)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ForgotPassword)
);

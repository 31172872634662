import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {put, takeLatest} from "redux-saga/effects";
import axios from "axios";

const initialState = {
  data: null,
  loaded: false,
  allowedMarketNames: [
    'Polska',
    'Warszawa',
    'Kraków',
    'Wrocław',
    'Poznań',
    'Gdańsk',
    'Gdynia',
    'Sopot',
    'Katowice',
    'Zabrze',
    'Tychy',
    'Jaworzno',
    'Gliwice',
    'Sosnowiec',
    'Bytom',
    'Chorzów',
    'Łódź',
    'Lublin',
    'Katowice i Metropolia',
    'Trójmiasto'
  ],
  industrialData: null,
  industrialLoaded: false,
  industrialLoading: false
};

const filterReddIndex = (response) => {
  const allowed = [
    'indexPolska',
    'indexWarszawa',
    'indexKrakow',
    'indexWroclaw',
    'indexPoznan',
    'indexGdansk',
    'indexGdynia',
    'indexSopot',
    'indexKatowice',
    'indexZabrze',
    'indexTychy',
    'indexJaworzno',
    'indexGliwice',
    'indexSosnowiec',
    'indexBytom',
    'indexChorzow',
    'indexLodz',
    'indexLublin',
    'indexKatowice i Metropolia',
    'indexTrojmiasto'
  ];
  return response.filter(function(item){
      return allowed.indexOf(item.id) > -1;
  });
};

export const reducer = persistReducer(
  { storage, key: "reddIndex" },
  (state = initialState, action) => {
    switch (action.type) {
      // RESET REDD INDEX LOADING STATES
      case 'RESET_REDD_INDEX_LOADING_STATES_REQUEST': {
        return {
          ...state,
          industrialLoading: false
        };
      }
      //
      case 'FETCH_REDD_INDEX_REQUEST': {
        return { ...state};
      }
      case 'FETCH_REDD_INDEX_SUCCESS': {
        return {...state, data: filterReddIndex(action.payload), loaded: true};
      }
      case 'FETCH_REDD_INDEX_INDUSTRIAL_REQUEST': {
        return { ...state, industrialLoading: true, industrialLoaded: false, industrialData: null};
      }
      case 'FETCH_REDD_INDEX_INDUSTRIAL_SUCCESS': {
        return {...state, industrialLoading: false, industrialLoaded: true, industrialData: action.payload};
      }
      case 'FETCH_REDD_INDEX_INDUSTRIAL_FAILED': {
        return {...state, industrialLoading: false, industrialLoaded: false, industrialData: null};
      }

      default:
        return state;
    }
  }
);

// FETCH DATA
function* fetchReddIndex() {
  try {
    const reddIndex = yield axios.get('/redd-index/');
    yield put({ type: "FETCH_REDD_INDEX_SUCCESS", payload: reddIndex.data || [{ error: reddIndex.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchReddIndexIndustrial() {
  try {
    const reddIndex = yield axios.get('/redd-index/industrial/');
    yield put({ type: "FETCH_REDD_INDEX_INDUSTRIAL_SUCCESS", payload: reddIndex.data || [{ error: reddIndex.statusText }] });
  }
  catch(err) {
    console.log(err);
    yield put({ type: "FETCH_REDD_INDEX_INDUSTRIAL_FAILED"});
  }
}

export function* saga() {
  yield takeLatest('FETCH_REDD_INDEX_REQUEST', fetchReddIndex);
  yield takeLatest('FETCH_REDD_INDEX_INDUSTRIAL_REQUEST', fetchReddIndexIndustrial);
}

import React from "react";
import connect from "react-redux/es/connect/connect";
import {Typography} from "@material-ui/core";
import PortletAccordion from "app/partials/components/PortletAccordion";
import {FormattedMessage} from "react-intl";
import TableCustom from "app/partials/components/TableCustom";
import {fetchOfficesVisitingCompanies} from "app/crud/estates/offices/offices.crud";
import {fetchIndustrialParksVisitingCompanies} from "app/crud/estates/industrial/industrial.crud";
import _ from "lodash";
import LoadingScreen from "app/partials/components/LoadingScreen";
import NoData from "app/partials/components/NoData/NoData";
import {ROUTES} from "app/constants";
import APPLICATION_VIEWS from "app/constants/APPLICATION_VIEWS";
import PropertyCardPreview from "app/partials/components/_DataParts/PropertyCardPreview";
import ReactVisibilitySensor from "react-visibility-sensor";
import styles from "../../components/LeadsPages/index.module.scss";
import Button from "../../../../partials/components/Button";
import {callReddClickEvent} from "../../../../crud/user.crud";

class DashboardVisitingCompanies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tBody: null,
      initLoaded: false
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {currentApplicationView, visitingCompanies, visitingCompaniesIndustrial} = this.props;
    const isOfficesView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    const data = isOfficesView ? visitingCompanies : visitingCompaniesIndustrial;
    const dataPrev = isOfficesView ? prevProps?.visitingCompanies : prevProps?.visitingCompaniesIndustrial;
    // Re-render TableBody
    if(
      // if offices was empty
      (_.isEmpty(dataPrev) && data?.length > 0) ||
      // if offices was updated
      (!_.isEqual(dataPrev, data))
    ) {
      this.setState({
        tBody: this.handlePrepTableBody()
      });
    }
  }
  // Handlers
  handlePrepTableBody = () => {
    const {currentApplicationView, visitingCompanies, visitingCompaniesIndustrial} = this.props;
    const isOfficesView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    const data = isOfficesView ? visitingCompanies : visitingCompaniesIndustrial;

    if(data?.length > 0) {
      return data?.map(property => {
        return {
          data: [
            <span className={styles.propertyNameWrapper}>
              <PropertyCardPreview
                propertyID={property?.id}
                route={isOfficesView ? ROUTES.BUILDING_PAGE : ROUTES.INDUSTRIAL_PARK}
                routeNewTab
              >
                <span className={styles.propertyName}>{property?.name}</span>
              </PropertyCardPreview>
            </span>,
            property?.companiesCount,
            <Button
              redirect={ROUTES.LEADS_POTENTIAL_TENANTS}
              onClick={() => {
                localStorage.setItem("redirectOfficeID", property?.id);
                localStorage.setItem("redirectViewMode", "companies");
              }}
              trackingData={{
                clickType: isOfficesView ?
                  "dashboard-visiting-companies-view-company-click" :
                  "dashboard-industrial-visiting-companies-view-company-click",
                contentType: "user"
              }}
            >
              <FormattedMessage id="GENERAL.PHRASE.VIEW_DETAILS"/>
            </Button>
          ]
        }
      });
    }
  }
  handleOnViewportFetch = (isVisible) => {
    const {
      fetchOfficesVisitingCompanies,
      fetchIndustrialParksVisitingCompanies,
      loading,
      loadingIndustrial,
      currentApplicationView
    } = this.props;
    const {initLoaded} = this.state;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    const isIndustrialView = currentApplicationView === APPLICATION_VIEWS.INDUSTRIAL;
    const params = {
      isEditable: 'true',
      size: 3
    };

    if(isOfficeView && isVisible && !loading && !initLoaded) {
      this.setState({
        initLoaded: true
      }, () => fetchOfficesVisitingCompanies({params}));
    }
    else if(isIndustrialView && isVisible && !loadingIndustrial && !initLoaded) {
      this.setState({
        initLoaded: true
      }, () => fetchIndustrialParksVisitingCompanies({params}));
    }
  };

  render() {
    const {loading, loadingIndustrial, currentApplicationView} = this.props;
    const {tBody} = this.state;
    const isOfficeView = currentApplicationView === APPLICATION_VIEWS.OFFICES;
    const tHead = [
      {
        "label": isOfficeView ? <FormattedMessage id="GENERAL.PHRASE.BUILDING"/> : <FormattedMessage id="GENERAL.PHRASE.INDUSTRIAL_PARK"/>,
        "number": false
      },
      {
        "label": <FormattedMessage id="GENERAL.PHRASE.NUMBER_OF_COMPANIES"/>,
        "number": true
      },
      {
        "label": "",
        "number": true
      }
    ];

    return(
      <PortletAccordion
        title={
        <ReactVisibilitySensor
            onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
            scrollCheck
            partialVisibility
        >
          <Typography variant="h3">
            <FormattedMessage id="DASHBOARD.PHRASE.VISITING_COMPANIES_PREFIX"/>{" "}
            <FormattedMessage id="DASHBOARD.PHRASE.VISITING_COMPANIES_SUFFIX"/>
          </Typography>
        </ReactVisibilitySensor>
        }
        fluidHeight
        disableAccordion
        footer={
        !_.isEmpty(tBody) &&
          <Button
            type={"plainText"}
            variant={"outlined"}
            redirect={ROUTES.LEADS_POTENTIAL_TENANTS}
            trackingData={{
              clickType: isOfficeView ?
                "dashboard-potential-tenants-see-all-click" :
                "dashboard-industrial-potential-tenants-see-all-click",
              contentType: "user"
            }}
            style={{fontSize: "1.6rem", fontWeight: "400"}}
          >
            <FormattedMessage id="VOCABULARY.Check all"/>
          </Button>
        }
      >
        {(isOfficeView ? loading : loadingIndustrial) ? <LoadingScreen/> :
          _.isEmpty(tBody) ? <NoData message={<FormattedMessage id="GENERAL.PHRASE.NO_VISITING_COMPANIES"/>} withIcon/> :
            <TableCustom
              tHead={tHead}
              tBody={tBody}
              responsive
              tdStyle={{height: "9rem"}}
            />
        }
      </PortletAccordion>
    );
  }
}

const mapStateToProps = (state) => ({
  visitingCompanies: state.offices.visitingCompanies,
  loading: state.offices.visitingCompaniesLoading,
  visitingCompaniesIndustrial: state.industrial.visitingCompanies,
  loadingIndustrial: state.industrial.visitingCompaniesLoading,
  currentApplicationView: state.user?.data?.currentApplicationView
});
const mapDispatchToProps = {
  fetchOfficesVisitingCompanies: ({params}) => fetchOfficesVisitingCompanies(params),
  fetchIndustrialParksVisitingCompanies: ({params}) => fetchIndustrialParksVisitingCompanies(params),
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params)
};
export default connect(mapStateToProps, mapDispatchToProps)(DashboardVisitingCompanies)
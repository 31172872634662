import React from "react";
import styles from "../index.module.scss";
import {toNumberFormat} from "../../../../utils/helpers";
import {FormattedMessage} from "react-intl";
import cn from "classnames";
import LoadingScreen from "../../../../partials/components/LoadingScreen";

export default class TransactionsDynamicData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true
    }
  }
  render() {
    const {dynamicData, dynamicDataLoading} = this.props;
    const {expanded} = this.state;
    const grossTakeup = dynamicData?.grossTakeup;
    const netTakeup = dynamicData?.netTakeup;
    const avgHeadlineRent = dynamicData?.avgHeadlineRent;
    const avgEffectiveRent = dynamicData?.avgEffectiveRent;
    const avgLeasePeriod = dynamicData?.avgLeasePeriod;

    return(
      <div className={cn(
        styles.transactionDynamicDataWrapper,
        expanded ? styles.active : undefined
      )}>
        {dynamicDataLoading ? <LoadingScreen absolute/> :
        <table>
          <thead>
            <tr>
              <th/>
              <th>
                <span className={styles.label}><FormattedMessage id="VOCABULARY.Gross Take-Up"/></span>
                <span className={styles.value}>{toNumberFormat(grossTakeup?.None?.val, 'area')}</span>
              </th>
              <th>
                <span className={styles.label}><FormattedMessage id="VOCABULARY.Net Take-Up"/></span>
                <span className={styles.value}>{toNumberFormat(netTakeup?.None?.val, 'area')}</span>
              </th>
              <th>
                <span className={styles.label}><FormattedMessage id="VOCABULARY.Average headline rent"/></span>
                <span className={styles.value}>{avgHeadlineRent?.None ? toNumberFormat(Number(avgHeadlineRent?.None).toFixed(2), 'currency') : "-"}</span>
              </th>
              <th>
                <span className={styles.label}><FormattedMessage id="VOCABULARY.Average effective rent"/></span>
                <span className={styles.value}>{avgEffectiveRent?.None ? toNumberFormat(Number(avgEffectiveRent?.None).toFixed(2), 'currency') : "-"}</span>
              </th>
              <th>
                <span className={styles.label}><FormattedMessage id="VOCABULARY.Average lease period"/></span>
                <span className={styles.value}>
                  {avgLeasePeriod?.None ?
                  <FormattedMessage
                    id="BUILDING.PHRASE.YEARS_VALUE"
                    values={{
                      count: Number(avgLeasePeriod?.None).toFixed(0),
                      years: Number(avgLeasePeriod?.None).toFixed(0)
                    }}
                  /> : "-"
                  }
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><FormattedMessage id="Inner city"/></td>
              <td><>{toNumberFormat(grossTakeup?.["2"]?.["val"], 'area')} {grossTakeup?.["2"]?.perc && `(${Number(grossTakeup?.["2"]?.perc).toFixed(0)}%)`}</></td>
              <td><>{toNumberFormat(netTakeup?.["2"]?.["val"], 'area')} {netTakeup?.["2"]?.perc && `(${Number(netTakeup?.["2"]?.perc).toFixed(0)}%)`}</></td>
              <td><>{avgHeadlineRent?.["2"] ? toNumberFormat(Number(avgHeadlineRent?.["2"]).toFixed(2), 'currency') : "-"}</></td>
              <td><>{avgEffectiveRent?.["2"] ? toNumberFormat(Number(avgEffectiveRent?.["2"]).toFixed(2), 'currency') : "-"}</></td>
              <td>
                {avgLeasePeriod?.["2"] ?
                <FormattedMessage
                  id="BUILDING.PHRASE.YEARS_VALUE"
                  values={{
                    count: Number(avgLeasePeriod?.["2"]).toFixed(0),
                    years: Number(avgLeasePeriod?.["2"]).toFixed(0)
                  }}
                /> : "-"
                }
              </td>
            </tr>
            <tr>
              <td><FormattedMessage id="Near city"/></td>
              <td><>{toNumberFormat(grossTakeup?.["0"]?.["val"], 'area')} {grossTakeup?.["0"]?.perc && `(${Number(grossTakeup?.["0"]?.perc).toFixed(0)}%)`}</></td>
              <td><>{toNumberFormat(netTakeup?.["0"]?.["val"], 'area')} {netTakeup?.["0"]?.perc && `(${Number(netTakeup?.["0"]?.perc).toFixed(0)}%)`}</></td>
              <td><>{avgHeadlineRent?.["0"] ? toNumberFormat(Number(avgHeadlineRent?.["0"]).toFixed(2), 'currency') : "-"}</></td>
              <td><>{avgEffectiveRent?.["0"] ? toNumberFormat(Number(avgEffectiveRent?.["0"]).toFixed(2), 'currency') : "-"}</></td>
              <td>
                {avgLeasePeriod?.["0"] ?
                <FormattedMessage
                  id="BUILDING.PHRASE.YEARS_VALUE"
                  values={{
                    count: Number(avgLeasePeriod?.["0"]).toFixed(0),
                    years: Number(avgLeasePeriod?.["0"]).toFixed(0)
                  }}
                /> : "-"
                }
              </td>
            </tr>
            <tr>
              <td><FormattedMessage id="Out of city"/></td>
              <td><>{toNumberFormat(grossTakeup?.["1"]?.["val"], 'area')} {grossTakeup?.["1"]?.perc && `(${Number(grossTakeup?.["1"]?.perc).toFixed(0)}%)`}</></td>
              <td><>{toNumberFormat(netTakeup?.["1"]?.["val"], 'area')} {netTakeup?.["1"]?.perc && `(${Number(netTakeup?.["1"]?.perc).toFixed(0)}%)`}</></td>
              <td><>{avgHeadlineRent?.["1"] ? toNumberFormat(Number(avgHeadlineRent?.["1"]).toFixed(2), 'currency') : "-"}</></td>
              <td><>{avgEffectiveRent?.["1"] ? toNumberFormat(Number(avgEffectiveRent?.["1"]).toFixed(2), 'currency') : "-"}</></td>
              <td>
                {avgLeasePeriod?.["1"] ?
                <FormattedMessage
                  id="BUILDING.PHRASE.YEARS_VALUE"
                  values={{
                    count: Number(avgLeasePeriod?.["1"]).toFixed(0),
                    years: Number(avgLeasePeriod?.["1"]).toFixed(0)
                  }}
                /> : "-"
                }
              </td>
            </tr>
          </tbody>
        </table>
        }
      </div>
    );
  }
}

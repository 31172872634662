import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import PropertyParticulars from "./PropertyParticularsPage";
import {destroyNotification} from "../../../../constants/actionCreators/notifications";
import {
  fetchPropertyParticulars,
  fetchPropertyParticularsBranding,
  fetchPropertyParticularsListPhotos,
  fetchPropertyParticularsOffice,
  fetchPropertyParticularsOfficeContact,
  sharePropertyParticularsRealTimeLog,
  shareUpdatedPropertyParticularsRealTimeLog
} from "app/crud/propertyParticulars/offices.crud";

const mapStateToProps = (state) => ({
  hasAuthToken: Boolean(state.authApp.authToken),
  notifications: state.notifications,
  ppItems: state.ppOnlineOffices.ppItems,
  loadingItems: state.ppOnlineOffices.loadingItems,
  ppBranding: state.ppOnlineOffices.ppBranding,
  loadingBranding: state.ppOnlineOffices.loadingBranding,
  isNotPro: state.ppOnlineOffices.isNotPro,
  ppPhotos: state.ppOnlineOffices.ppPhotos,
  loadingPhotos: state.ppOnlineOffices.loadingPhotos,
  ppOffice: state.ppOnlineOffices.ppOffice,
  loadingOffice: state.ppOnlineOffices.loadingOffice,
  ppContact: state.ppOnlineOffices.ppContact,
  loadingContact: state.ppOnlineOffices.loadingContact
});
const mapDispatchToProps = {
  destroyNotification: destroyNotification,
  fetchPropertyParticulars: (teamID, email, market) => fetchPropertyParticulars(teamID, email, market),
  fetchPropertyParticularsListPhotos: (teamID, email) => fetchPropertyParticularsListPhotos(teamID, email),
  fetchPropertyParticularsBranding: (teamID) => fetchPropertyParticularsBranding(teamID),
  sharePropertyParticularsRealTimeLog: (data) => sharePropertyParticularsRealTimeLog(data),
  shareUpdatedPropertyParticularsRealTimeLog: (data) => shareUpdatedPropertyParticularsRealTimeLog(data),
  fetchPropertyParticularsOffice: (teamID, officeID, email) => fetchPropertyParticularsOffice(teamID, officeID, email),
  fetchPropertyParticularsOfficeContact: (teamID, officeID, email) => fetchPropertyParticularsOfficeContact(teamID, officeID, email)
};
export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PropertyParticulars)
);
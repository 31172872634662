import React from "react";
import clsx from "clsx";
import styles from "./index.module.scss";
import {Icon, Typography} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import ButtonTooltip from "../ButtonTooltip";

export default class HighlightLabel extends React.Component {
  render() {
    const {
      color,
      children,
      className,
      hover,
      variant,
      style,
      solid,
      tooltip,
      disableTooptipInfo,
      onClick,
      tooltipPosition = "top"
    } = this.props;
    let labelColor;
    if(color === "success") {
      labelColor = styles.labelSuccess;
    } else if(color === "warning") {
      labelColor = styles.labelWarning;
    } else if(color === "danger") {
      labelColor = styles.labelDanger;
    } else if(color === "neutral") {
      labelColor = styles.labelNeutral;
    } else if(color === "neutral2") {
      labelColor = styles.labelNeutral2;
    } else {
      labelColor = styles.labelDefault
    }
    if(tooltip) {
      const isTooltipObject = typeof tooltip === 'object';

      return(
          <ButtonTooltip
            tPlacement={tooltipPosition}
            tOverlay={isTooltipObject ? tooltip : <FormattedMessage id={tooltip}/>}
            color={color}
            onClick={onClick}
          >
            <span style={{display: 'flex', alignItems: 'center'}}>
              <Typography
                variant={variant || "h4"}
                className={clsx(styles.label, labelColor, className, hover && styles.hover, solid && styles.solid)}
                style={style}
              >{children}</Typography>
              {!disableTooptipInfo && <Icon fontSize="small" color="error">help_outline</Icon>}
            </span>
          </ButtonTooltip>
      );
    }
    return (
      <Typography variant={variant || "h4"} className={clsx(styles.label, labelColor, className, hover && styles.hover, solid && styles.solid)} style={style} onClick={onClick}>{children}</Typography>
    );
  }
}
import React from "react";
import {Grid, Typography} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import InputAdornments from "app/partials/components/InputAdornments";
import {onlyNumbers} from "app/utils/helpers";
import _ from "lodash";
import {
  fetchIndustrialColumnGridDimensions,
  updateIndustrialWarehouse
} from "app/crud/estates/industrial/industrial.crud";
import SingleSelect from "../../../SingleSelect";

class EditWarehouseTechnicalDetails extends React.Component {
  constructor(props) {
    super(props);
    const {
      storageHeightMin,
      storageHeightMax,
      columnGridMin,
      columnGridMax,
      flooringCarrierMin,
      flooringCarrierMax,
      parkingSlotsTruck,
      parkingSlotsCar,
      docks
    } = props?.data;
    this.state = {
      storageHeightMin,
      storageHeightMax,
      columnGridMin,
      columnGridMax,
      flooringCarrierMin,
      flooringCarrierMax,
      parkingSlotsTruck,
      parkingSlotsCar,
      docks
    };
    this.initialState = this.state;

    // Fetch Column Grid
    props.fetchIndustrialColumnGridDimensions();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Handle Change Detect
    this.props.onUnsavedData(!_.isEqual(this.initialState, this.state));
    // Handle Save
    if (!prevProps.requestSave && this.props.requestSave) {
      this.handleSave();
    }
    // Detect Office Updating
    if (prevProps.warehouseUpdateLoading !== this.props.warehouseUpdateLoading) {
      this.props.onRequestSaving(this.props.warehouseUpdateLoading);
    }
  }

  // Handlers
  handleChange = (event) => {
    const onlyNums = onlyNumbers(event.target.value);
    const allowOnlyNumbers = event.target.name === "area" || event.target.name === "floor";
    this.setState({
      [event.target.name]: allowOnlyNumbers ? onlyNums : event.target.value
    });
  };
  handleSelectedCGMin = selected => {
    this.setState({
      columnGridMin: selected
    });
  };
  handleSelectedCGMax = selected => {
    this.setState({
      columnGridMax: selected
    });
  };
  handleValueApiRequest = (value) => {
    if(!value || value === "" || value === 'None') {
      return null;
    }
    return value;
  }
  handleSave = () => {
    const {data: {id}, updateIndustrialWarehouse} = this.props;
    const {
      storageHeightMin,
      storageHeightMax,
      columnGridMin,
      columnGridMax,
      flooringCarrierMin,
      flooringCarrierMax,
      parkingSlotsTruck,
      parkingSlotsCar,
      docks
    } = this.state;
    this.initialState = this.state;
    const data = {
      storageHeightMin: this.handleValueApiRequest(storageHeightMin),
      storageHeightMax: this.handleValueApiRequest(storageHeightMax),
      columnGridMin: this.handleValueApiRequest(columnGridMin),
      columnGridMax: this.handleValueApiRequest(columnGridMax),
      flooringCarrierMin: this.handleValueApiRequest(flooringCarrierMin),
      flooringCarrierMax: this.handleValueApiRequest(flooringCarrierMax),
      parkingSlotsTruck: this.handleValueApiRequest(parkingSlotsTruck),
      parkingSlotsCar: this.handleValueApiRequest(parkingSlotsCar),
      docks: this.handleValueApiRequest(docks)
    };
    updateIndustrialWarehouse(id, data, "technicalDetails");
  };

  render() {
    const {columnGridDimensions, columnGridDimensionsLoading, warehouseUpdateLoading} = this.props;
    const {
      storageHeightMin,
      storageHeightMax,
      columnGridMin,
      columnGridMax,
      flooringCarrierMin,
      flooringCarrierMax,
      parkingSlotsTruck,
      parkingSlotsCar,
      docks
    } = this.state;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" style={{color: "#ff4626"}}><FormattedMessage id="VOCABULARY.Technical spec"/></Typography>
        </Grid>
        <Grid item xs={6}>
          <SingleSelect
            label={<FormattedMessage id="INDUSTRIAL.PHRASE.COLUMN_GRID_MIN"/>}
            inputName="columnGridMin"
            options={columnGridDimensions}
            optionsValue="id"
            optionsLabel="name"
            defaultSelect={columnGridMin}
            onSelectedValues={this.handleSelectedCGMin}
            loading={columnGridDimensionsLoading}
            disabled={warehouseUpdateLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <SingleSelect
            label={<FormattedMessage id="INDUSTRIAL.PHRASE.COLUMN_GRID_MAX"/>}
            inputName="columnGridMax"
            options={columnGridDimensions}
            optionsValue="id"
            optionsLabel="name"
            defaultSelect={columnGridMax}
            onSelectedValues={this.handleSelectedCGMax}
            loading={columnGridDimensionsLoading}
            disabled={warehouseUpdateLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <InputAdornments
            label={<FormattedMessage id="INDUSTRIAL.PHRASE.STORAGE_HEIGHT_MIN"/>}
            name="storageHeightMin"
            value={storageHeightMin}
            onChange={this.handleChange}
            disabled={warehouseUpdateLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <InputAdornments
            label={<FormattedMessage id="INDUSTRIAL.PHRASE.STORAGE_HEIGHT_MAX"/>}
            name="storageHeightMax"
            value={storageHeightMax}
            onChange={this.handleChange}
            disabled={warehouseUpdateLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <InputAdornments
            label={<FormattedMessage id="INDUSTRIAL.PHRASE.FLOOR_LOADING_CAPACITY_MIN"/>}
            name="flooringCarrierMin"
            value={flooringCarrierMin}
            onChange={this.handleChange}
            disabled={warehouseUpdateLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <InputAdornments
            label={<FormattedMessage id="INDUSTRIAL.PHRASE.FLOOR_LOADING_CAPACITY_MAX"/>}
            name="flooringCarrierMax"
            value={flooringCarrierMax}
            onChange={this.handleChange}
            disabled={warehouseUpdateLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <InputAdornments
            label={<FormattedMessage id="INDUSTRIAL.PHRASE.PARKING_SLOTS_TRUCK"/>}
            name="parkingSlotsTruck"
            value={parkingSlotsTruck}
            onChange={this.handleChange}
            disabled={warehouseUpdateLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <InputAdornments
            label={<FormattedMessage id="INDUSTRIAL.PHRASE.PARKING_SLOTS_CAR"/>}
            name="parkingSlotsCar"
            value={parkingSlotsCar}
            onChange={this.handleChange}
            disabled={warehouseUpdateLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <InputAdornments
            label={<FormattedMessage id="INDUSTRIAL.PHRASE.LOADING_DOCKS"/>}
            name="docks"
            value={docks}
            onChange={this.handleChange}
            disabled={warehouseUpdateLoading}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = store => ({
  warehouseUpdateLoading: store.industrial.warehouseUpdateLoading,
  columnGridDimensions: store.industrial.columnGridDimensions,
  columnGridDimensionsLoading: store.industrial.columnGridDimensionsLoading
});
const mapDispatchToProps = {
  updateIndustrialWarehouse: (warehouseID, data, backgroundRefresh) => updateIndustrialWarehouse(warehouseID, data, backgroundRefresh),
  fetchIndustrialColumnGridDimensions: () => fetchIndustrialColumnGridDimensions()
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditWarehouseTechnicalDetails)
);
import React from "react";
import ModalCustom from "app/partials/components/ModalCustom";
import {Typography} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {pdf} from "@react-pdf/renderer";
import PropertyPdfDocument from "./PropertyPdfDocument";
import {
  fetchOfficeAmenities,
  fetchOfficeBasic,
  fetchOfficeLeaseTerms,
  fetchOfficeOwner,
  fetchOfficeUnits,
  fetchOfficeCertificates,
  fetchOfficeCompetitors,
  fetchOfficeMarketComparison,
  fetchOfficeLeasingContacts
} from "app/crud/estates/offices/officeBuilding.crud";
import ReactVisibilitySensor from "react-visibility-sensor";
import {UNIT_STATUS} from "app/constants";
import commonStyles from "app/pages/common/components/LeadsPages/index.module.scss";
import IconColorMUI from "app/partials/components/IconColorMUI";
import cn from "classnames";
import InputSwitcher from "app/partials/components/InputSwitcher";
import styles from "./index.module.scss";
import PaywallModal from "../../../../../partials/components/PayWall/components/PaywallModal";
import IsPro from "../../../../../utils/IsPro";
import REDD_TOWER_DUMMY_DATA from "../../../external/PropertyPageDemo/REDD_TOWER_DUMMY_DATA";
import {callReddClickEvent} from "../../../../../crud/user.crud";
import {downloadFileFromBlob} from "../../../../../utils/helpers";
import Button from "../../../../../partials/components/Button";
import Icon from "../../../../../partials/components/Icon";

class GeneratePropertyPdf extends React.Component {
  constructor(props){
    super(props);
    this.child = React.createRef();
    this.state = {
      initLoaded: false,
      availableUnits: null,
      visibleMarketComparison: null,
      generatePdf: false,
      isComparisonEnable: false,
      isContactDataEnable: false,
      generatingPdf: false
    }
  }
  componentDidUpdate(prevProps, prevState) {
    // Filter available units
    if(
      (prevProps.units !== this.props.units) ||
      (
        this.props.isDummyViewActive &&
        !this.state.availableUnits &&
        prevState.availableUnits !== this.props.units
      )
    ) {
      const availableUnits = this.props.units?.filter(unit => unit?.status !== UNIT_STATUS.UNAVAILABLE);

      this.setState({
        availableUnits
      });
    }
    // Filter market comparison
    if(
      (prevProps.marketComparison !== this.props.marketComparison) ||
      (
        this.props.isDummyViewActive &&
        !this.state.visibleMarketComparison &&
        prevState.visibleMarketComparison !== this.props.marketComparison
      )
    ) {
      const visibleMarketComparison = this.props.marketComparison?.filter(item => 
        !item?.key?.startsWith("marketplace_") && !item?.key?.endsWith("_6m") && !item?.key?.endsWith("_24m")
      );

      this.setState({
        visibleMarketComparison
      });
    }
  }
  // Function to close Modal
  handleCloseModal = () => {
    this.setState({
      generatePdf: false
    });
  };

  handleOnViewportFetch = (isVisible) => {
    const {
      basic,
      unitsLoading,
      leaseTermsLoading,
      amenitiesLoading,
      ownerLoading,
      certificatesLoading,
      competitorsLoading,
      marketComparisonLoading,
      leasingContactsLoading,
      fetchOfficeUnits,
      fetchOfficeLeaseTerms,
      fetchOfficeAmenities,
      fetchOfficeOwner,
      fetchOfficeCertificates,
      fetchOfficeCompetitors,
      fetchOfficeMarketComparison,
      fetchOfficeLeasingContacts,
      isDummyViewActive
    } = this.props;
    const {initLoaded, isContactDataEnable, isComparisonEnable} = this.state;
    const officeID = basic?.id;

    if (isVisible && !initLoaded && officeID) {
      this.setState({
        initLoaded: true
      }, () => {
        if(!isDummyViewActive) {
          if(!unitsLoading) {
            fetchOfficeUnits(officeID);
          }
          if(!leaseTermsLoading) {
            fetchOfficeLeaseTerms(officeID);
          }
          if(!amenitiesLoading) {
            fetchOfficeAmenities(officeID);
          }
          if(!ownerLoading) {
            fetchOfficeOwner(officeID);
          }
          if(!certificatesLoading) {
            fetchOfficeCertificates(officeID);
          }
          if(!competitorsLoading && isComparisonEnable) {
            fetchOfficeCompetitors(officeID);
          }
          if(!marketComparisonLoading && isComparisonEnable) {
            fetchOfficeMarketComparison(officeID);
          }
          if(!leasingContactsLoading && isContactDataEnable) {
            fetchOfficeLeasingContacts(officeID);
          }
        }
      });
    }
  }
  handleChangeSwitcher = (name) => (event) => {
    this.setState({
      [name]: event.target.checked
    });
  };
  handleDirectGeneratePdf = () => {
    const {availableUnits, visibleMarketComparison, isComparisonEnable, isContactDataEnable} = this.state;

    this.setState({
      generatingPdf: true
    }, async () => {
      const buildingName = this.props.basic?.name;
      const buildingFileName = [
        ...buildingName ? [buildingName?.replaceAll(".", "_")] : ['Property'],
        ' - ',
        'REDD',
        '.pdf'
      ].join("");
      const asPdf = pdf(
        <PropertyPdfDocument
          availableUnits={availableUnits}
          visibleMarketComparison={visibleMarketComparison}
          isComparisonEnable={isComparisonEnable}
          isContactDataEnable={isContactDataEnable}
          {...this.props}
        />
      );
      const blob = await asPdf.toBlob();
      this.setState({generatingPdf: false});
      downloadFileFromBlob(blob, buildingFileName);
    });
  };

  render() {
    const {
      intl,
      basic,
      basicLoading,
      unitsLoading,
      leaseTermsLoading,
      amenitiesLoading,
      ownerLoading,
      certificatesLoading,
      competitorsLoading,
      marketComparisonLoading,
      leasingContactsLoading,
      isDummyViewActive,
      callReddClickEvent
    } = this.props;
    const {generatingPdf, generatePdf, isComparisonEnable, isContactDataEnable} = this.state;
    const isLoading = basicLoading || unitsLoading || leaseTermsLoading || amenitiesLoading || ownerLoading || certificatesLoading || competitorsLoading || marketComparisonLoading || leasingContactsLoading || generatingPdf;
    const officeID = basic?.id;
    const pdfLimitReached = basic?.pdfLimitReached;

    return (
      <ModalCustom
        ref={this.child}
        btn={
        <IsPro
          paywall
          paywallComponent={
            <PaywallModal
              triggerBtn={
              <Button
                type={"plainText"}
                textIcon={<Icon icon="download" size={"1.1rem"}/>}
                style={{fontSize: "1.2rem"}}
              >
                <FormattedMessage id="VOCABULARY.Download PDF"/>
              </Button>
              }
              triggerButtonVariant={intl.formatMessage({id: "VOCABULARY.Download PDF"})}
            />
          }
          overwriteRule={isDummyViewActive}
        >
          <Button
            type={"plainText"}
            color={"simple"}
            textIcon={<Icon icon="download" size={"1.1rem"}/>}
            inactive={pdfLimitReached}
            tooltip={<FormattedMessage id="VOCABULARY.You used your daily limit"/>}
            tooltipInactive={!pdfLimitReached}
            style={{fontSize: "1.2rem"}}
          >
            <FormattedMessage id="VOCABULARY.Download PDF"/>
          </Button>
        </IsPro>
        }
        title={
          <FormattedMessage
            id="SHORTLIST.PHRASE.GENERATE_PDF"
            values={{
              breakingLine: <br/>,
              subtitle: <Typography variant="body2"><FormattedMessage id="GENERAL.PHRASE.MONTHLY"/></Typography>}}
          />
        }
        btnReject={<FormattedMessage id="GENERAL.PHRASE.CLOSE"/>}
        btnConfirm={!generatePdf ? <FormattedMessage id="SHORTLIST.PHRASE.GENERATE_PDF"/> : undefined}
        maxWidth="sm"
        handleSave={() => this.handleDirectGeneratePdf()}
        handleClose={() => this.handleCloseModal()}
        onOpen={() => callReddClickEvent(
          "office-property-generate-pdf-settings",
          "office",
          null,
          officeID
        )}
        saving={isLoading}
        inactive={pdfLimitReached || isLoading || IsPro({reverseCheck: true, overwriteRule: isDummyViewActive})}
      >
        <ReactVisibilitySensor
          onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
          scrollCheck
          partialVisibility
        >
          <div className={styles.pdfConfigWrapper}>
            <div
              className={cn(commonStyles.titleDescriptionWrapper, commonStyles.warning)}
              style={{marginTop: 0}}
            >
              <IconColorMUI
                icon="info_outlined"
                size="1.8rem"
                style={{lineHeight: "0"}}
              />
              <Typography variant="body2">
                <FormattedMessage id="VOCABULARY.The PDF file will contain basic information about the building, lease terms and available modules."/>
              </Typography>
            </div>
            <div className={styles.pdfConfigContentWrapper}>
              <label><FormattedMessage id="VOCABULARY.Add to file"/></label>
              <div className={styles.pdfConfigOptionsWrapper}>
                <div className={styles.pdfConfigItemWrapper}>
                  <label><FormattedMessage id="VOCABULARY.Market and competition comparison"/></label>
                  <InputSwitcher
                    checked={isComparisonEnable}
                    value="isComparisonEnable"
                    onChange={this.handleChangeSwitcher("isComparisonEnable")}
                    style={{margin: 0}}
                  />
                </div>
                <div className={styles.pdfConfigItemWrapper}>
                  <label><FormattedMessage id="VOCABULARY.Leasing Manager contact details"/></label>
                  <InputSwitcher
                    checked={isContactDataEnable}
                    value="isContactDataEnable"
                    onChange={this.handleChangeSwitcher("isContactDataEnable")}
                    style={{margin: 0}}
                  />
                </div>
              </div>
            </div>
          </div>
        </ReactVisibilitySensor>
      </ModalCustom>
    );
  }
}

const mapStateToProps = (store, ownProps) => ({
  basic: ownProps?.isDummyViewActive ? REDD_TOWER_DUMMY_DATA.basic : store.officeBuilding.basic,
  basicLoading: store.officeBuilding.basicLoading,
  units: ownProps?.isDummyViewActive ? REDD_TOWER_DUMMY_DATA.units : store.officeBuilding.units,
  unitsLoading: store.officeBuilding.unitsLoading,
  leaseTerms: ownProps?.isDummyViewActive ? REDD_TOWER_DUMMY_DATA.leaseTerms : store.officeBuilding.leaseTerms,
  leaseTermsLoading: store.officeBuilding.leaseTermsLoading,
  amenities: ownProps?.isDummyViewActive ? REDD_TOWER_DUMMY_DATA.amenities : store.officeBuilding.amenities,
  amenitiesLoading: store.officeBuilding.amenitiesLoading,
  owner: ownProps?.isDummyViewActive ? REDD_TOWER_DUMMY_DATA.owner : store.officeBuilding.owner,
  ownerLoading: store.officeBuilding.ownerLoading,
  leasingContacts: ownProps?.isDummyViewActive ? REDD_TOWER_DUMMY_DATA.leasingContacts : store.officeBuilding.leasingContacts,
  leasingContactsLoading: store.officeBuilding.leasingContactsLoading,
  certificates: ownProps?.isDummyViewActive ? REDD_TOWER_DUMMY_DATA.certificates : store.officeBuilding.certificates,
  certificatesLoading: store.officeBuilding.certificatesLoading,
  competitors: ownProps?.isDummyViewActive ? REDD_TOWER_DUMMY_DATA.competitors : store.officeBuilding.competitors,
  competitorsLoading: store.officeBuilding.competitorsLoading,
  marketComparison: ownProps?.isDummyViewActive ? REDD_TOWER_DUMMY_DATA.marketComparison : store.officeBuilding.marketComparison,
  marketComparisonLoading: store.officeBuilding.marketComparisonLoading,
});
const mapDispatchToProps = {
  fetchOfficeBasic: (officeID) => fetchOfficeBasic(officeID),
  fetchOfficeUnits: (officeID, ordering) => fetchOfficeUnits(officeID, ordering),
  fetchOfficeLeaseTerms: (officeID) => fetchOfficeLeaseTerms(officeID),
  fetchOfficeAmenities: (officeID) => fetchOfficeAmenities(officeID),
  fetchOfficeOwner: (officeID) => fetchOfficeOwner(officeID),
  fetchOfficeLeasingContacts: (officeID) => fetchOfficeLeasingContacts(officeID),
  fetchOfficeCertificates: (officeID) => fetchOfficeCertificates(officeID),
  fetchOfficeCompetitors: (officeID) => fetchOfficeCompetitors(officeID),
  fetchOfficeMarketComparison: (officeID) => fetchOfficeMarketComparison(officeID),
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params)
};
export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(GeneratePropertyPdf)
);


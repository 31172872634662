import React, {useEffect} from "react";
import {Portlet, PortletBody} from "app/partials/content/Portlet";
import {Grid} from "@material-ui/core";
import PropertyUnits from "app/pages/offices/PropertyPage/components/content/PropertyUnits";
import PropertyLeaseTerms from "app/pages/offices/PropertyPage/components/content/PropertyLeaseTerms";
import PropertyAmenities from "app/pages/offices/PropertyPage/components/content/PropertyAmenities";
import PropertyChangesTracker from "app/pages/offices/PropertyPage/components/content/PropertyChangesTracker";
import PropertySimilarProperties from "app/pages/common/components/PropertyPage/PropertySimilarProperties";
import PropertyHeader from "app/pages/offices/PropertyPage/components/PropertyHeader";
import PropertyContact from "app/pages/common/components/PropertyPage/PropertyContact";
import PropertyCertificates from "app/pages/common/components/PropertyPage/PropertyCertificates";
import PropertyCompetitionCompare from "app/pages/offices/PropertyPage/components/content/PropertyCompetitionCompare";
import PropertyMarketComparison from "app/pages/offices/PropertyPage/components/content/PropertyMarketComparison";
import {FormattedMessage} from "react-intl";
import Header from "../../../../../_metronic/layout/header/Header";
import KtContent from "../../../../../_metronic/layout/KtContent";
import Footer from "../../../../../_metronic/layout/footer/Footer";
import AsideLeft from "../../../../../_metronic/layout/aside/AsideLeft";
import HTMLClassService from "../../../../../_metronic/layout/HTMLClassService";
import {isMobile, useWindowSize} from "../../../../utils/helpers";
import MobilePage from "../../../auth/MobilePage";
import LayoutConfig from "../../../../../_metronic/layout/LayoutConfig";
import MenuConfig from "../../../../../_metronic/layout/MenuConfig";
import LayoutInitializer from "../../../../../_metronic/layout/LayoutInitializer";

const breakPointValue = 1054; // Content Container width, NOT window width
const breakPointValueSummary = 1365; // Content Container width, NOT window width
const breakPointValueLogotypes = 1300; // Content Container width, NOT window width

class PropertyPageDemoContainer extends React.Component {
  constructor(props) {
    super(props);
    const contentRoot = document.getElementById("kt_content")?.offsetWidth;
    const breakPointState = contentRoot < breakPointValue;
    const breakPointSummaryState = contentRoot < breakPointValueSummary;
    const breakPointLogotypesState = contentRoot < breakPointValueLogotypes;

    this.state = {
      breakPoint: breakPointState || false,
      breakPointSummary: breakPointSummaryState || false,
      breakPointLogotypes: breakPointLogotypesState || false
    }
  }
  componentDidMount() {
    const body = document.getElementById("root");
    setTimeout(() => {
      body.scrollTo({
        top: 0
      });
    }, 100);
    //
    const contentRoot = document.getElementById("kt_content");
    if(contentRoot) {
      const observer = new ResizeObserver(entries => {
      const e = entries[0]; // should be only one
      const width = e.contentRect.width;
      const breakPointState = width < breakPointValue;
      const breakPointSummaryState = width < breakPointValueSummary;
      const breakPointLogotypesState = width < breakPointValueLogotypes;
      //
      if(this.state.breakPoint !== breakPointState) {
        this.setState({
          breakPoint: breakPointState
        });
      }
      //
      if(this.state.breakPointSummary !== breakPointSummaryState) {
        this.setState({
          breakPointSummary: breakPointSummaryState
        });
      }
      //
      if(this.state.breakPointLogotypes !== breakPointLogotypesState) {
        this.setState({
          breakPointLogotypes: breakPointLogotypesState
        });
      }
    })
      // start listening for size changes
      observer.observe(contentRoot);
    }
  }

  render() {
    const {breakPoint, breakPointSummary, breakPointLogotypes} = this.state;

    // Columns content ORDER based on Account Type
    let leftColumnOrder = ["header", "competitionCompare", "marketComparison", "units", "changes", "similar"];
    let rightColumnOrder = ["contact", "leaseTerms", "certificates", "amenities"];
    let mergedColumnOrder = ["header", "contact", "competitionCompare", "marketComparison", "units", "changes", "leaseTerms", "certificates", "amenities", "similar"];
    // Columns content
    const leftColumnContent = [
      {
        key: "header",
        section: (
          <Grid item xs={12}>
            <PropertyHeader
              breakPoint={breakPoint}
              breakPointSummary={breakPointSummary}
              dummyData
              {...this.props}
            />
          </Grid>
        )
      },
      {
        key: "units",
        section: (
          <Grid item xs={12}>
            <PropertyUnits
              breakPointSummary={breakPointSummary}
              dummyData
              {...this.props}
            />
          </Grid>
        )
      },
      {
        key: "changes",
        section: (
          <Grid item xs={12}>
            <PropertyChangesTracker
              initialLimit={5}
              limitBtnLabel={<FormattedMessage id="VOCABULARY.Show all"/>}
              dummyData
              {...this.props}
            />
          </Grid>
        )
      },
      {
        key: "similar",
        section: (
          <Grid item xs={12}>
            <PropertySimilarProperties
              dummyData
              {...this.props}
            />
          </Grid>
        )
      },
      {
        key: "competitionCompare",
        section: (
          <Grid item xs={12}>
            <PropertyCompetitionCompare
              dummyData
              {...this.props}
            />
          </Grid>
        )
      },
      {
        key: "marketComparison",
        section: (
          <Grid item xs={12}>
            <PropertyMarketComparison
              dummyData
              {...this.props}
            />
          </Grid>
        )
      }
    ];
    const rightColumnContent = [
      {
        key: "leaseTerms",
        section: (
          <Grid item xs={breakPoint ? 6 : 12}>
            <PropertyLeaseTerms dummyData {...this.props}/>
          </Grid>
        )
      },
      {
        key: "amenities",
        section: (
          <Grid item xs={12}>
            <PropertyAmenities dummyData {...this.props}/>
          </Grid>
        )
      },
      {
        key: "contact",
        section: (
          <Grid item xs={12}>
            <PropertyContact
              oneLine={breakPoint}
              semiBreak={breakPointSummary}
              logoBreak={breakPointLogotypes}
              dummyData
            />
          </Grid>
        )
      },
      {
        key: "certificates",
        section: (
          <PropertyCertificates
            breakPoint={breakPoint}
            dummyData
            {...this.props}
          />
        )
      }
    ];
    const mergedColumnContent = leftColumnContent.concat(rightColumnContent);
    const leftColumnFinalOrder = leftColumnOrder.map(orderItem=> leftColumnContent.find(contentItem => contentItem?.key === orderItem));
    const rightColumnFinalOrder = rightColumnOrder.map(orderItem=> rightColumnContent.find(contentItem => contentItem?.key === orderItem));
    const mergedColumnFinalOrder = mergedColumnOrder.map(orderItem=> mergedColumnContent.find(contentItem => contentItem?.key === orderItem));

    if(breakPoint) {
      return(
        <Grid container>
          <Grid item xs={12}>
            <Portlet
              shadowDisabled
            >
              <PortletBody>
                <Grid container spacing={5}>
                  {mergedColumnFinalOrder?.map((item,index) => {
                    return <React.Fragment key={index}>{item?.section}</React.Fragment>;
                  })}
                </Grid>
              </PortletBody>
            </Portlet>
          </Grid>
        </Grid>
      );
    }
    return(
      <Grid container>
        <Grid item xs={9}>
          <Portlet
            shadowDisabled
          >
            <PortletBody>
              <Grid container spacing={5}>
                {leftColumnFinalOrder?.map((item,index) => {
                  return <React.Fragment key={index}>{item?.section}</React.Fragment>;
                })}
              </Grid>
            </PortletBody>
          </Portlet>
        </Grid>
        <Grid item xs={3}>
          <Portlet
            fluidHeight
            shadowDisabled
            style={{backgroundColor: "rgba(250, 250, 250, 0.8)"}}
          >
            <PortletBody>
              <Grid container spacing={5}>
                {rightColumnFinalOrder?.map((item,index) => {
                  return <React.Fragment key={index}>{item?.section}</React.Fragment>;
                })}
              </Grid>
            </PortletBody>
          </Portlet>
        </Grid>
      </Grid>
    );
  }
}
export default function PropertyPageDemo({intl, layoutConfig, ...props}) {
  const htmlClassService = new HTMLClassService();
  const [width] = useWindowSize();
  const [contentCssClasses, setContentCssClasses] = React.useState(null);

  // Init
  useEffect(() => {
    htmlClassService.setConfig(layoutConfig, false);
    const newContentCssClasses = htmlClassService.classes.content.join(" ");
    setContentCssClasses(newContentCssClasses);
  }, [layoutConfig, htmlClassService]);
  // App Font Size
  useEffect(() => {
    const html = document.querySelector("html");

    if (width >= 1100) {
      html.style.fontSize = "9px";
    } else if (width < 1100) {
      html.style.fontSize = "8px";
    }
  }, [width]);

  if(isMobile(width)) {
    return <MobilePage />;
  }
  return(
    <LayoutInitializer
      menuConfig={MenuConfig}
      layoutConfig={LayoutConfig}
      htmlClassService={htmlClassService}
    >
      <div id="redd-tower-page" className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
        <AsideLeft
          htmlClassService={htmlClassService}
          dummyData
        />
        <div
          className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
          id="kt_wrapper"
        >
          <Header
            intl={intl}
            htmlClassService={htmlClassService}
            dummyData
          />
          <div
            id="kt_content"
            className={`kt-content ${contentCssClasses} kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor`}
          >
            <KtContent>
              <PropertyPageDemoContainer intl={intl} {...props}/>
            </KtContent>
          </div>
          <Footer intl={intl} />
        </div>
      </div>
    </LayoutInitializer>
  );
}
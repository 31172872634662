import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import PropertyWarehousePage from "./PropertyWarehousePage";
import {
  fetchIndustrialWarehouseAmenities,
  fetchIndustrialWarehouseBasic,
  fetchIndustrialWarehouseChanges,
  fetchIndustrialWarehouseLeaseTerms,
  fetchIndustrialWarehouseLeasingContacts,
  fetchIndustrialWarehouseMetrics,
  fetchIndustrialWarehouseOwner,
  fetchIndustrialWarehouseSimilarProperties,
  fetchIndustrialWarehouseTechnicalDetails,
  fetchIndustrialWarehouseUnits,
  resetIndustrialWarehouseState,
} from "app/crud/estates/industrial/industrialWarehouse.crud";
import {fetchIndustrialParkPhotos} from "../../../crud/estates/industrial/industrial.crud";
import {callReddClickEvent} from "app/crud/user.crud";

const mapStateToProps = (store) => ({
  basic: store.industrialWarehouse.basic,
  basicLoading: store.industrialWarehouse.basicLoading,
  units: store.industrialWarehouse.units,
  unitsLoading: store.industrialWarehouse.unitsLoading,
  leaseTerms: store.industrialWarehouse.leaseTerms,
  leaseTermsLoading: store.industrialWarehouse.leaseTermsLoading,
  technicalDetails: store.industrialWarehouse.technicalDetails,
  technicalDetailsLoading: store.industrialWarehouse.technicalDetailsLoading,
  amenities: store.industrialWarehouse.amenities,
  amenitiesLoading: store.industrialWarehouse.amenitiesLoading,
  metrics: store.industrialWarehouse.metrics,
  metricsLoading: store.industrialWarehouse.metricsLoading,
  changes: store.industrialWarehouse.changes,
  changesLoading: store.industrialWarehouse.changesLoading,
  similarProperties: store.industrialWarehouse.similarProperties,
  similarPropertiesLoading: store.industrialWarehouse.similarPropertiesLoading,
  owner: store.industrialWarehouse.owner,
  ownerLoading: store.industrialWarehouse.ownerLoading,
  leasingContacts: store.industrialWarehouse.leasingContacts,
  leasingContactsLoading: store.industrialWarehouse.leasingContactsLoading,
  resetting: store.industrialWarehouse.resetting,
  photos: store.industrial.parkPhotos,
  photosLoading: store.industrial.parkPhotosLoading,
  isStaff: store.user.data.isStaff,
  currentApplicationView: store.user.data.currentApplicationView
});
const mapDispatchToProps = {
  fetchIndustrialWarehouseBasic: (warehouseID) => fetchIndustrialWarehouseBasic(warehouseID),
  fetchIndustrialWarehouseUnits: (warehouseID, ordering) => fetchIndustrialWarehouseUnits(warehouseID, ordering),
  fetchIndustrialWarehouseLeaseTerms: (warehouseID) => fetchIndustrialWarehouseLeaseTerms(warehouseID),
  fetchIndustrialWarehouseTechnicalDetails: (warehouseID) => fetchIndustrialWarehouseTechnicalDetails(warehouseID),
  fetchIndustrialWarehouseAmenities: (warehouseID) => fetchIndustrialWarehouseAmenities(warehouseID),
  fetchIndustrialWarehouseMetrics: (warehouseID) => fetchIndustrialWarehouseMetrics(warehouseID),
  fetchIndustrialWarehouseChanges: (warehouseID) => fetchIndustrialWarehouseChanges(warehouseID),
  fetchIndustrialWarehouseSimilarProperties: (warehouseID) => fetchIndustrialWarehouseSimilarProperties(warehouseID),
  fetchIndustrialWarehouseOwner: (warehouseID) => fetchIndustrialWarehouseOwner(warehouseID),
  fetchIndustrialWarehouseLeasingContacts: (warehouseID) => fetchIndustrialWarehouseLeasingContacts(warehouseID),
  fetchIndustrialParkPhotos: (parkID) => fetchIndustrialParkPhotos(parkID),
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params, notification) => callReddClickEvent(clickType, contentType, appLabel, objectId, params, notification),
  resetIndustrialWarehouseState: resetIndustrialWarehouseState
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PropertyWarehousePage)
);

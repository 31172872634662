import {BUILDING_STATUS, UNIT_STATUS, UNIT_TYPES} from "app/constants";
import {FormattedMessage} from "react-intl";
import React from "react";
import INDUSTRIAL_BUILDING_TYPE from "../../../../constants/INDUSTRIAL_BUILDING_TYPE";
import INDUSTRIAL_FILES_FORMATS from "../../../../constants/INDUSTRIAL_FILES_FORMATS";
import INDUSTRIAL_POIS_TYPES from "../../../../constants/INDUSTRIAL_POIS_TYPES";
import INDUSTRIAL_FILES_CATEGORIES from "../../../../constants/INDUSTRIAL_FILES_CATEGORIES";

export default {
  BUILDINGS_CLASSES: ["A", "B", "C"],
  BUILDINGS_CERTIFICATES: [
    {
      id: 1,
      label: "DGNB"
    },
    {
      id: 2,
      label: "HQE"
    },
    {
      id: 3,
      label: "WELL"
    },
    {
      id: 4,
      label: "BREEAM"
    },
    {
      id: 5,
      label: "LEED"
    },
    {
      id: 6,
      label: <FormattedMessage id="Building Without Barriers"/>
    }
  ],
  BUILDINGS_CERTIFICATES_LANDLORD: [
    {
      id: 'dgnbCertification',
      label: "DGNB"
    },
    {
      id: 'hqeCertification',
      label: "HQE"
    },
    {
      id: 'wellCertification',
      label: "WELL"
    },
    {
      id: 'breeamCertification',
      label: "BREEAM"
    },
    {
      id: 'leedCertification',
      label: "LEED"
    },
    {
      id: 'withoutBarrierCertification',
      label: <FormattedMessage id="Building Without Barriers"/>
    }
  ],
  BUILDINGS_COMMISIONING_QUARTERS: [
    {
      id: 0,
      label: "I"
    },
    {
      id: 1,
      label: "II"
    },
    {
      id: 2,
      label: "III"
    },
    {
      id: 3,
      label: "IV"
    }
  ],
  BUILDINGS_STATUSES: [
    {
      id: BUILDING_STATUS.STATUS_EXISTS,
      label: <FormattedMessage id="GENERAL.PHRASE.EXISTING"/>
    },
    {
      id: BUILDING_STATUS.STATUS_UNDER_CONSTRUCTION,
      label: <FormattedMessage id="GENERAL.PHRASE.UNDER_CONSTRUCTION"/>
    },
    {
      id: BUILDING_STATUS.STATUS_PLANNED,
      label: <FormattedMessage id="GENERAL.PHRASE.PLANNED"/>
    },
    {
      id: BUILDING_STATUS.STATUS_UNDER_REVITALIZATION,
      label: <FormattedMessage id="GENERAL.PHRASE.UNDER_REVITALIZATION"/>
    },
    {
      id: BUILDING_STATUS.STATUS_UNDER_REDEVELOPMENT,
      label: <FormattedMessage id="GENERAL.PHRASE.UNDER_REDEVELOPMENT"/>
    }
  ],
  BUILDINGS_TYPES: [
    {
      id: 1,
      label: <FormattedMessage id='BUILDING.TYPE.OFFICE'/>
    },
    {
      id: 2,
      label: <FormattedMessage id='BUILDING.TYPE.APARTMENT'/>
    },
    {
      id: 3,
      label: <FormattedMessage id='BUILDING.TYPE.WAREHOUSE'/>
    },
    {
      id: 4,
      label: <FormattedMessage id='BUILDING.TYPE.SHOPPING_CENTER'/>
    },
    {
      id: 5,
      label: <FormattedMessage id='BUILDING.TYPE.HQ'/>
    },
    {
      id: 6,
      label: <FormattedMessage id='BUILDING.TYPE.TENEMENT_HOUSE'/>
    },
    {
      id: 7,
      label: <FormattedMessage id='BUILDING.TYPE.OTHER'/>
    }
  ],
  UNITS_STATUSES: [
    {
      id: UNIT_STATUS.AVAILABLE,
      label: <FormattedMessage id="GENERAL.PHRASE.AVAILABLE"/>
    },
    {
      id: UNIT_STATUS.RESERVED,
      label: <FormattedMessage id="GENERAL.PHRASE.RESERVED"/>
    },
    {
      id: UNIT_STATUS.UNAVAILABLE,
      label: <FormattedMessage id="GENERAL.PHRASE.UNAVAILABLE"/>
    }
  ],
  UNITS_TYPES: [
    {
      id: UNIT_TYPES.OFFICE,
      label: <FormattedMessage id="GENERAL.PHRASE.OFFICE"/>
    },
    {
      id: UNIT_TYPES.RETAIL,
      label: <FormattedMessage id="GENERAL.PHRASE.RETAIL"/>
    },
    {
      id: UNIT_TYPES.OFFICE_RETAIL,
      label: <FormattedMessage id="GENERAL.PHRASE.OFFICE_RETAIL"/>
    }
  ],
  BOOLEAN: [
    {
      id: "ANY",
      label: <FormattedMessage id="GENERAL.PHRASE.ANY"/>
    },
    {
      id: "YES",
      label: <FormattedMessage id="GENERAL.PHRASE.YES"/>
    },
    {
      id: "NO",
      label: <FormattedMessage id="GENERAL.PHRASE.NO"/>
    }
  ],
  CURRENCY: [
    {
      id: 0,
      label: "PLN"
    },
    {
      id: 1,
      label: "EUR"
    },
    {
      id: 2,
      label: "USD"
    }
  ],
  INDUSTRIAL_WAREHOUSE_TYPE: [
    {
      id: INDUSTRIAL_BUILDING_TYPE.WAREHOUSE_SBU.id,
      label: <FormattedMessage id={INDUSTRIAL_BUILDING_TYPE.WAREHOUSE_SBU.name}/>
    },
    {
      id: INDUSTRIAL_BUILDING_TYPE.WAREHOUSE_BTS.id,
      label: <FormattedMessage id={INDUSTRIAL_BUILDING_TYPE.WAREHOUSE_BTS.name}/>
    },
    {
      id: INDUSTRIAL_BUILDING_TYPE.WAREHOUSE_BIG_BOX.id,
      label: <FormattedMessage id={INDUSTRIAL_BUILDING_TYPE.WAREHOUSE_BIG_BOX.name}/>
    },
    {
      id: INDUSTRIAL_BUILDING_TYPE.WAREHOUSE_CITY_FLEX.id,
      label: <FormattedMessage id={INDUSTRIAL_BUILDING_TYPE.WAREHOUSE_CITY_FLEX.name}/>
    },
    {
      id: INDUSTRIAL_BUILDING_TYPE.WAREHOUSE_OTHER.id,
      label: <FormattedMessage id={INDUSTRIAL_BUILDING_TYPE.WAREHOUSE_OTHER.name}/>
    },
  ],
  BIURA_INFO_LEADS_STATUSES: [
    {
      id: 0,
      name: <FormattedMessage id="BI.PHRASE.WAITING_FOR_ACTION"/>,
      color: "default"
    },
    {
      id: 1,
      name: <FormattedMessage id="BI.PHRASE.FIRST_CONTACT"/>,
      color: "neutral"
    },
    {
      id: 2,
      name: <FormattedMessage id="BI.PHRASE.OFFER_SENT"/>,
      color: "success"
    },
    {
      id: 3,
      name: <FormattedMessage id="BI.PHRASE.BEFORE_VIEWING"/>,
      color: "warning"
    },
    {
      id: 4,
      name: <FormattedMessage id="BI.PHRASE.AFTER_VIEWING"/>,
      color: "neutral"
    },
    {
      id: 5,
      name: <FormattedMessage id="BI.PHRASE.NEGOTIATIONS"/>,
      color: "warning"
    },
    {
      id: 6,
      name: <FormattedMessage id="BI.PHRASE.CONTRACT_SIGNED"/>,
      color: "success"
    },
    {
      id: 7,
      name: <FormattedMessage id="BI.PHRASE.TENANT_LOST"/>,
      color: "danger"
    },
    {
      id: 8,
      name: <FormattedMessage id="BI.PHRASE.INCORRECT_TENANT"/>,
      color: "danger"
    }
  ],
  INDUSTRIAL_FILES_FORMATS: [
    {
      id: INDUSTRIAL_FILES_FORMATS.TYPE_CSV.id,
      label: <FormattedMessage id={INDUSTRIAL_FILES_FORMATS.TYPE_CSV.name}/>
    },
    {
      id: INDUSTRIAL_FILES_FORMATS.TYPE_XLSX.id,
      label: <FormattedMessage id={INDUSTRIAL_FILES_FORMATS.TYPE_XLSX.name}/>
    },
    {
      id: INDUSTRIAL_FILES_FORMATS.TYPE_DOCX.id,
      label: <FormattedMessage id={INDUSTRIAL_FILES_FORMATS.TYPE_DOCX.name}/>
    },
    {
      id: INDUSTRIAL_FILES_FORMATS.TYPE_PDF.id,
      label: <FormattedMessage id={INDUSTRIAL_FILES_FORMATS.TYPE_PDF.name}/>
    },
    {
      id: INDUSTRIAL_FILES_FORMATS.TYPE_PNG.id,
      label: <FormattedMessage id={INDUSTRIAL_FILES_FORMATS.TYPE_PNG.name}/>
    },
    {
      id: INDUSTRIAL_FILES_FORMATS.TYPE_JPG.id,
      label: <FormattedMessage id={INDUSTRIAL_FILES_FORMATS.TYPE_JPG.name}/>
    }
  ],
  INDUSTRIAL_FILES_CATEGORIES: [
    {
      id: INDUSTRIAL_FILES_CATEGORIES.WAREHOUSE_PLAN.id,
      label: <FormattedMessage id={INDUSTRIAL_FILES_CATEGORIES.WAREHOUSE_PLAN.name}/>
    },
    {
      id: INDUSTRIAL_FILES_CATEGORIES.PARK_PLAN.id,
      label: <FormattedMessage id={INDUSTRIAL_FILES_CATEGORIES.PARK_PLAN.name}/>
    },
    {
      id: INDUSTRIAL_FILES_CATEGORIES.LAND_DEVELOPMENT_PLAN.id,
      label: <FormattedMessage id={INDUSTRIAL_FILES_CATEGORIES.LAND_DEVELOPMENT_PLAN.name}/>
    },
    {
      id: INDUSTRIAL_FILES_CATEGORIES.BROCHURE.id,
      label: <FormattedMessage id={INDUSTRIAL_FILES_CATEGORIES.BROCHURE.name}/>
    },
    {
      id: INDUSTRIAL_FILES_CATEGORIES.MAP.id,
      label: <FormattedMessage id={INDUSTRIAL_FILES_CATEGORIES.MAP.name}/>
    }
  ],
  INDUSTRIAL_POIS_TYPES: [
    {
      id: INDUSTRIAL_POIS_TYPES.TYPE_AIRPORT.id,
      label: <FormattedMessage id={INDUSTRIAL_POIS_TYPES.TYPE_AIRPORT.name}/>
    },
    {
      id: INDUSTRIAL_POIS_TYPES.TYPE_CITY_CENTER.id,
      label: <FormattedMessage id={INDUSTRIAL_POIS_TYPES.TYPE_CITY_CENTER.name}/>
    },
    {
      id: INDUSTRIAL_POIS_TYPES.TYPE_RAILWAY_STATION.id,
      label: <FormattedMessage id={INDUSTRIAL_POIS_TYPES.TYPE_RAILWAY_STATION.name}/>
    },
    {
      id: INDUSTRIAL_POIS_TYPES.TYPE_MOTORWAY_JUNCTION.id,
      label: <FormattedMessage id={INDUSTRIAL_POIS_TYPES.TYPE_MOTORWAY_JUNCTION.name}/>
    },
    {
      id: INDUSTRIAL_POIS_TYPES.TYPE_MOTORWAY.id,
      label: <FormattedMessage id={INDUSTRIAL_POIS_TYPES.TYPE_MOTORWAY.name}/>
    }
  ]
}
import React from "react";
import PropertySectionTitle from "../../../../common/components/PropertyPage/PropertySectionTitle";
import styles from "./index.module.scss";
import {toNumberFormat} from "../../../../../utils/helpers";
import _ from "lodash";
import ReactVisibilitySensor from "react-visibility-sensor";
import LoadingScreen from "../../../../../partials/components/LoadingScreen";
import {FormattedMessage} from "react-intl";
import EditModal from "../../../../../partials/components/EditModal";
import {injectIntl} from "react-intl";

class PropertyLeaseTerms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initLoaded: false
    }
  }

  // Handlers
  handleOnViewportFetch = (isVisible) => {
    const {fetchIndustrialWarehouseLeaseTerms, leaseTerms, leaseTermsLoading, match} = this.props;
    const {initLoaded} = this.state;
    const {id} = match?.params;

    if(isVisible && !initLoaded && !leaseTermsLoading && _.isEmpty(leaseTerms) && id) {
      this.setState({
        initLoaded: true,
      }, () => fetchIndustrialWarehouseLeaseTerms(id));
    }
  }
  handleValueLoading = (loading, value, negotiable) => {
    const {intl} = this.props;
    if(loading) {
      return <LoadingScreen loaderStyle={{width: "1rem", height: "1rem"}}/>;
    }
    else if(value === "-" && negotiable) {
      return intl.formatMessage({id: "BUILDING.PHRASE.TO_NEGOTIATE"});
    }
    return value;
  }

  render() {
    const {parentRef, leaseTerms, leaseTermsLoading, basic} = this.props;
    const rentIndustrial = [
      ...leaseTerms?.rentWarehouseFrom ? [toNumberFormat(leaseTerms?.rentWarehouseFrom)] : [],
      ...leaseTerms?.rentWarehouseTo ? [toNumberFormat(leaseTerms?.rentWarehouseTo)] : []
    ].join(" - ");
    const rentOffices = [
      ...leaseTerms?.rentOfficeFrom ? [toNumberFormat(leaseTerms?.rentOfficeFrom)] : [],
      ...leaseTerms?.rentOfficeTo ? [toNumberFormat(leaseTerms?.rentOfficeTo)] : []
    ].join(" - ");
    const serviceIndustrial = [
      ...leaseTerms?.serviceWarehouseFrom ? [toNumberFormat(leaseTerms?.serviceWarehouseFrom)] : [],
      ...leaseTerms?.serviceWarehouseTo ? [toNumberFormat(leaseTerms?.serviceWarehouseTo)] : []
    ].join(" - ");
    const serviceOffices = [
      ...leaseTerms?.serviceOfficeFrom ? [toNumberFormat(leaseTerms?.serviceOfficeFrom)] : [],
      ...leaseTerms?.serviceOfficeTo ? [toNumberFormat(leaseTerms?.serviceOfficeTo)] : []
    ].join(" - ");

    return(
      <ReactVisibilitySensor
        onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
        scrollCheck
        partialVisibility
      >
        <>
        <PropertySectionTitle
          parentRef={parentRef}
          title={<FormattedMessage id="VOCABULARY.Lease terms"/>}
          titleActions={
          basic?.isEditable &&
            <EditModal
              target="warehouse"
              initialTab="leaseTerms"
              parentData={basic}
              isPropertyEditable={basic?.isEditable}
              {...this.props}
            />
          }
        />
        <table className={styles.simpleTable}>
          <tbody>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Rent - industrial"/></td>
              <td>
                {this.handleValueLoading(leaseTermsLoading, toNumberFormat(rentIndustrial, 'range', null, '€'), true)}
              </td>
            </tr>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Rent - office"/></td>
              <td>{this.handleValueLoading(leaseTermsLoading, toNumberFormat(rentOffices, 'range', null, '€'), true)}</td>
            </tr>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Service charge  - industrial"/></td>
              <td>{this.handleValueLoading(leaseTermsLoading, toNumberFormat(serviceIndustrial, 'range', null, 'PLN'), true)}</td>
            </tr>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Service charge  - office"/></td>
              <td>{this.handleValueLoading(leaseTermsLoading, toNumberFormat(serviceOffices, 'range', null, 'PLN'), true)}</td>
            </tr>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Lease period"/></td>
              <td>{this.handleValueLoading(leaseTermsLoading, leaseTerms?.minRentalYears ? <FormattedMessage id="BUILDING.PHRASE.YEARS_VALUE" values={{years: leaseTerms?.minRentalYears, count: leaseTerms?.minRentalYears}}/> : "-")}</td>
            </tr>
            <tr>
              <td><FormattedMessage id="VOCABULARY.Minimum lease size"/></td>
              <td>{this.handleValueLoading(leaseTermsLoading, toNumberFormat(leaseTerms?.minAreaForRent, 'area'))}</td>
            </tr>
          </tbody>
        </table>
        </>
      </ReactVisibilitySensor>
    );
  }
}

export default injectIntl(PropertyLeaseTerms);
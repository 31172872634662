import React from "react";
import {Grid, Typography} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import InputAdornments from "app/partials/components/InputAdornments";
import SingleSelect from "app/partials/components/SingleSelect";
import {FILTERS_SELECTS} from "app/partials/components/FiltersDrawer/constants";
import {onlyNumbers} from "app/utils/helpers";
import _ from "lodash";
import InputSwitcher from "../../../InputSwitcher";
import AlertFormDialog from "../../../AlertFormDialog";
import format from "date-fns/format";
import {updateIndustrialWarehouseUnit} from "../../../../../crud/estates/industrial/industrial.crud";

class EditWarehouseUnitBasics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      area: props.data.area,
      availableFrom: props?.data?.availableFrom ? format(new Date(props?.data?.availableFrom), "yyyy-MM-dd") : "",
      availableFromAdditionalTime: props.data.availableFromAdditionalTime,
      status: props.data.status,
      isOffice: props.data.isOffice,
      docks: props.data.docks,
      confirmationOpen: false,
      confirmationAction: false,
      alertFormOpen: false,
    };
    this.initialState = this.state;
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    // Handle Change Detect
    this.props.onUnsavedData(!_.isEqual(this.initialState, this.state));
    // Handle Save
    if (!prevProps.requestSave && this.props.requestSave) {
      this.handleSave();
    }
    // Detect Unit Updating
    if(prevProps.unitUpdating !== this.props.unitUpdating) {
      this.props.onRequestSaving(this.props.unitUpdating);
    }
  }

  // Handlers
  handleChange = (event) => {
    const onlyNums = onlyNumbers(event.target.value);
    const allowOnlyNumbers = event.target.name === "area";
    this.setState({
      alertFormOpen: false,
      [event.target.name]: allowOnlyNumbers ? onlyNums : event.target.value,
    });
  };
  handleChangeDate = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleSelectedStatus = (selected) => {
    this.setState({
      status: selected,
    });
  };
  handleChangeSwitcher = (name) => (event) => {
    this.setState({
      [name]: event.target.checked,
    });
  };
  handleSave = () => {
    const { unitId, updateIndustrialWarehouseUnit } = this.props;
    const {
      area,
      availableFrom,
      availableFromAdditionalTime,
      status,
      isOffice,
      docks,
    } = this.state;
    const data = {
      area: area,
      availableFrom: availableFrom
        ? new Date(availableFrom).toISOString()
        : null,
      availableFromAdditionalTime:
        availableFromAdditionalTime !== "" ? availableFromAdditionalTime : null,
      status: status,
      isOffice: isOffice,
      docks: docks,
    };

    if (Number(area) < 5) {
      this.setState({
        alertFormOpen: true,
      });
    } else {
      this.initialState = this.state;
      const backgroundRefreshObject = {
        target: "basic",
        warehouseID: this.props.extraData?.warehouseID,
        parkID: this.props.extraData?.parkID,
        ordering: this.props.extraData?.ordering
      };
      updateIndustrialWarehouseUnit(unitId, data, backgroundRefreshObject);
    }
  };

  render() {
    const {unitUpdating} = this.props;
    const {
      area,
      availableFrom,
      availableFromAdditionalTime,
      status,
      isOffice,
      docks,
    } = this.state;

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h3" style={{color: "#ff4626"}}><FormattedMessage id="VOCABULARY.Basic data"/></Typography>
          </Grid>
          <Grid item xs={6}>
            <InputAdornments
              label={<FormattedMessage id="GENERAL.PHRASE.AREA" />}
              name="area"
              value={area}
              onChange={this.handleChange}
              disabled={unitUpdating}
            />
          </Grid>
          <Grid item xs={6}>
            <InputAdornments
              label={<FormattedMessage id="INDUSTRIAL.PHRASE.DOCKS" />}
              name="docks"
              value={docks}
              onChange={this.handleChange}
              disabled={unitUpdating}
            />
          </Grid>
          <Grid item xs={6}>
            <InputAdornments
              type="date"
              label={<FormattedMessage id="BUILDING.PHRASE.AVAILABLE_FROM" />}
              name="availableFrom"
              value={availableFrom}
              onChange={this.handleChangeDate}
              disabled={unitUpdating}
            />
          </Grid>
          <Grid item xs={6}>
            <InputAdornments
              label={
                <FormattedMessage id="BUILDING.PHRASE.AVAILABLE_FROM_ADDITIONAL_TIME" />
              }
              name="availableFromAdditionalTime"
              value={availableFromAdditionalTime}
              onChange={this.handleChange}
              disabled={unitUpdating}
            />
          </Grid>
          <Grid item xs={6}>
            <SingleSelect
              label={<FormattedMessage id="BUILDING.PHRASE.STATUS" />}
              inputName="status"
              options={FILTERS_SELECTS.UNITS_STATUSES}
              optionsValue="id"
              optionsLabel="label"
              defaultSelect={status}
              onSelectedValues={this.handleSelectedStatus}
              disabled={unitUpdating}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Typography>
                  <FormattedMessage id="INDUSTRIAL.PHRASE.IS_OFFICE_UNIT" />
                </Typography>
              </Grid>
              <Grid item>
                <InputSwitcher
                  checked={isOffice}
                  value="isOffice"
                  label={<FormattedMessage id="GENERAL.PHRASE.YES" />}
                  onChange={this.handleChangeSwitcher("isOffice")}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <AlertFormDialog
          open={this.state.alertFormOpen}
          message={<FormattedMessage id="GENERAL.PHRASE.FORM_ALERT_MIN_AREA" />}
        />
      </>
    );
  }
}

const mapStateToProps = store => ({
  unitUpdating: store.industrial.updateWarehouseUnitLoading
});
const mapDispatchToProps = {
  updateIndustrialWarehouseUnit: (unitId, data, backgroundRefreshObject) => updateIndustrialWarehouseUnit(unitId, data, backgroundRefreshObject),
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditWarehouseUnitBasics)
);
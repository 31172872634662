import React from "react";
import clsx from "clsx";
import styles from "./index.module.scss";
import {toNumberFormat} from "app/utils/helpers";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import BetaLabel from "../BetaLabel";
import HighlightLabel from "../HighlightLabel";
import BlurText from "../BlurText";
import IsPro from "../../../utils/IsPro";

class UnitAreaPreview extends React.Component {
  render() {
    const { data, parentData, isOfficeEditable, style, blurEnable, flexibleUnitForm } = this.props;
    const isCommercializationSuspended =
      (data && data.commercializationSuspended) ||
      (parentData && parentData.commercializationSuspended);
    return (
      <span className={clsx(styles.unitValue, styles.unitArea)} style={style}>
        {isCommercializationSuspended && (
          <BetaLabel top={data?.canBeDivided ? "-6px" : "-21px"} right="0">
            <FormattedMessage id="BUILDING.PHRASE.COMMERCIALIZATION_SUSPENDED" />
          </BetaLabel>
        )}
        {data?.canBeDivided && flexibleUnitForm}
        <HighlightLabel color="neutral2">
          <BlurText
            type="area"
            isBlurred={
            blurEnable &&
              IsPro({
                reverseCheck: true,
                overwriteRule: isOfficeEditable
              })
            }
          >
            {toNumberFormat(data.area, "area")}
          </BlurText>
        </HighlightLabel>
      </span>
    );
  }
}

export default injectIntl(connect()(UnitAreaPreview));

import React from "react";
import {
  addOfficePhoto,
  deleteOfficePhoto,
  fetchOfficePhotos,
  updateOffice
} from "app/crud/estates/offices/offices.crud";
import {updateOfficePhotosOrder} from "app/crud/estates/offices/officeBuilding.crud";
import {updateIndustrialWarehousePhotosOrder} from "app/crud/estates/industrial/industrialWarehouse.crud";
import {updateIndustrialParkPhotosOrder} from "app/crud/estates/industrial/industrialPark.crud";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {
  addParkPhoto,
  addWarehousePhoto,
  deleteParkPhoto,
  deleteWarehousePhoto,
  fetchIndustrialParkPhotos,
  fetchIndustrialWarehousePhotos,
  updateIndustrialPark,
  updateIndustrialWarehouse
} from "../../../../../crud/estates/industrial/industrial.crud";
import styles from "./index.module.scss";
import {InputUploadMultiple} from "../../../InputUploadMultiple";
import LoadingScreen from "../../../LoadingScreen";
import {Grid, Typography} from "@material-ui/core";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import {arrayMove} from "react-sortable-hoc";
import _ from "lodash";
import cn from "classnames";
import Button from "../../../Button";

const SortableItem = SortableElement((
  {item, data, onDelete, onSetDefault}
) => (
  <div className={styles.imageItem}>
    <div className={styles.imgBg} style={{'--bg-image': `url(${item?.image})`}}/>
    <div className={styles.imageItemToolbar}>
      {item?.id !== data.mainPhotoId && (
        <Button
          color="success"
          onClick={onSetDefault(item?.id)}
        >
          <FormattedMessage id="BUILDING.PHRASE.SET_AS_MAIN"/>
        </Button>
      )}
      <Button onClick={() => onDelete(item?.id)}>
        <FormattedMessage id="GENERAL.PHRASE.REMOVE"/>
      </Button>
    </div>
  </div>
));
const SortableList = SortableContainer((
  {items, data, uploading, onDelete, onSetDefault, ...props}
) => {
  return(
    <div className={cn(
      styles.manageGalleryImagesWrapper,
      props?.photosOrderUpdating ? styles.inactive : undefined
    )}>
      {items?.length > 0 && items?.map((photo, index) => (
        <SortableItem
          key={photo?.id}
          index={index}
          item={photo}
          data={data}
          disabled={props?.photosOrderUpdating || false}
          onDelete={onDelete}
          onSetDefault={onSetDefault}
        />
      ))}
      {(uploading || props?.photosOrderUpdating) && <div className={styles.imageItem}><LoadingScreen/></div>}
    </div>
  );
});

class EditPropertyManageImages extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    const {
      data,
      view,
      fetchOfficePhotos,
      fetchIndustrialParkPhotos,
      fetchIndustrialWarehousePhotos,
      addOfficePhoto,
      addParkPhoto,
      addWarehousePhoto,
      deleteOfficePhoto,
      deleteParkPhoto,
      deleteWarehousePhoto,
      updateOffice,
      updateIndustrialPark,
      updateIndustrialWarehouse,
      officePhotos,
      parkPhotos,
      warehousePhotos
    } = props;
    const isViewOffice = view === 'office';
    const isViewIndustrialPark = view === 'industrialPark';
    const isViewIndustrialWarehouse = view === 'industrialWarehouse';
    const photos = isViewOffice ? officePhotos : isViewIndustrialPark ? parkPhotos : isViewIndustrialWarehouse ? warehousePhotos : null;

    this.state = {
      isViewOffice,
      isViewIndustrialPark,
      isViewIndustrialWarehouse,
      onAddPhoto: isViewOffice ? addOfficePhoto : isViewIndustrialPark ? addParkPhoto : isViewIndustrialWarehouse ? addWarehousePhoto : null,
      onDeletePhoto: isViewOffice ? deleteOfficePhoto : isViewIndustrialPark ? deleteParkPhoto : isViewIndustrialWarehouse ? deleteWarehousePhoto : null,
      onUpdate: isViewOffice ? updateOffice : isViewIndustrialPark ? updateIndustrialPark : isViewIndustrialWarehouse ? updateIndustrialWarehouse : null,
      photos
    }
    if (isViewOffice) {
      data?.id && fetchOfficePhotos(data.id);
    } else if (isViewIndustrialPark) {
      data?.id && fetchIndustrialParkPhotos(data.id);
    } else if (isViewIndustrialWarehouse) {
      data?.id && fetchIndustrialWarehousePhotos(data.id);
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      data,
      officePhotos,
      parkPhotos,
      warehousePhotos,
      photosOrderUpdating,
      updateOfficePhotosOrder,
      updateIndustrialParkPhotosOrder,
      updateIndustrialWarehousePhotosOrder
    } = this.props;
    const {
      isViewOffice,
      isViewIndustrialPark,
      isViewIndustrialWarehouse
    } = this.state;
    const propertyID = data?.id;
    const photos = isViewOffice ? officePhotos : isViewIndustrialPark ? parkPhotos : isViewIndustrialWarehouse ? warehousePhotos : null;

    if (!prevProps.requestSave && this.props.requestSave) {
      // this.handleSave();
      console.log('trigger save basics');
    }
    // Photos loaded
    if(this.state.photos !== photos && !this.state.photos) {
      this.setState({
        photos
      });
    }
    //
    if(!photosOrderUpdating && !_.isEqual(prevState.photos, this.state.photos) && propertyID) {
      const data = this.state.photos.map(photo => photo?.id);
      const updatePhotosOrder = isViewOffice ? updateOfficePhotosOrder :
        isViewIndustrialPark ? updateIndustrialParkPhotosOrder :
          isViewIndustrialWarehouse ? updateIndustrialWarehousePhotosOrder : null;

      if(updatePhotosOrder) {
        updatePhotosOrder(propertyID, data);
      }
    }
  }

  // Handlers
  handleSetAsDefault = photoId => () => {
    const {data} = this.props;
    const {onUpdate} = this.state;
    data?.id && onUpdate(data.id, {mainPhotoId: photoId});
  };
  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState({
      photos: arrayMove(this.state.photos, oldIndex, newIndex),
    });
  };

  render() {
    const {
      data,
      officePhotoUploading,
      parkPhotoUploading,
      warehousePhotoUploading
    } = this.props;
    const {onAddPhoto, onDeletePhoto, isViewOffice, isViewIndustrialPark, isViewIndustrialWarehouse, photos} = this.state;
    const photoUploading = isViewOffice ? officePhotoUploading : isViewIndustrialPark ? parkPhotoUploading : isViewIndustrialWarehouse ? warehousePhotoUploading : false;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" style={{color: "#ff4626"}}><FormattedMessage id="VOCABULARY.Photos"/></Typography>
        </Grid>
        <Grid item xs={12}>
          <div className={styles.manageGalleryContainer}>
            <div className={styles.manageGalleryUploadWrapper}>
              <InputUploadMultiple propertyID={data?.id} onFileUpload={onAddPhoto}/>
            </div>
            <SortableList
              items={photos}
              axis="xy"
              data={data}
              uploading={photoUploading}
              onSortEnd={this.onSortEnd}
              onDelete={onDeletePhoto}
              onSetDefault={this.handleSetAsDefault}
              {...this.props}
            />
          </div>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  officePhotos: state.offices?.officePhotos,
  officePhotoUploading: state.offices?.officePhotoUploading,
  parkPhotos: state.industrial.parkPhotos,
  parkPhotoUploading: state.industrial.parkPhotoUploading,
  warehousePhotos: state.industrial.warehousePhotos,
  warehousePhotoUploading: state.industrial.warehousePhotoUploading,
  photosOrderUpdating: ownProps?.view === "office" ? state.officeBuilding.photosOrderUpdating :
    ownProps?.view === "industrialPark" ? state.industrialPark.photosOrderUpdating :
      ownProps?.view === "industrialWarehouse" ? state.industrialWarehouse.photosOrderUpdating : false
})
const mapDispatchToProps = {
  updateOffice: (officeId, data) => updateOffice(officeId, data),
  addOfficePhoto: (officeId, file) => addOfficePhoto(officeId, file),
  fetchOfficePhotos: (officeId) => fetchOfficePhotos(officeId),
  deleteOfficePhoto: photoId => deleteOfficePhoto(photoId),
  updateOfficePhotosOrder: (officeId, data) => updateOfficePhotosOrder(officeId, data),
  updateIndustrialPark: (parkID, data) => updateIndustrialPark(parkID, data),
  fetchIndustrialParkPhotos: (parkID) => fetchIndustrialParkPhotos(parkID),
  addParkPhoto: (parkID, file) => addParkPhoto(parkID, file),
  deleteParkPhoto: photoId => deleteParkPhoto(photoId),
  updateIndustrialParkPhotosOrder: (parkID, data) => updateIndustrialParkPhotosOrder(parkID, data),
  updateIndustrialWarehouse: (warehouseID, data) => updateIndustrialWarehouse(warehouseID, data),
  fetchIndustrialWarehousePhotos: (warehouseID) => fetchIndustrialWarehousePhotos(warehouseID),
  addWarehousePhoto: (warehouseID, file) => addWarehousePhoto(warehouseID, file),
  deleteWarehousePhoto: photoId => deleteWarehousePhoto(photoId),
  updateIndustrialWarehousePhotosOrder: (warehouseID, data) => updateIndustrialWarehousePhotosOrder(warehouseID, data)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditPropertyManageImages)
);
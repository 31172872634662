import React from "react";
import ModalCustom from "app/partials/components/ModalCustom";
import {inviteTeamMember} from "app/crud/team.crud";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import InputIcon from "app/partials/components/InputIcon";
import Button from "../Button";
import Icon from "app/partials/components/Icon";

class TeamAddMember extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
  }
  state = {
    open: false,
    memberEmail: ""
  };
  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleChange = (event) => {
    this.setState({
      memberEmail: event.target.value
    })
  };
  handleSave = () => {
    const {team, inviteTeamMember} = this.props;
    const {memberEmail} = this.state;
    const teamId = team.id;
    if(memberEmail !== "") {
      inviteTeamMember({teamId, email:memberEmail});
      this.handleCloseModal();
    }
  };

  render() {
    const {memberEmail} = this.state;
    return(
      <ModalCustom
        ref={this.child}
        btn={
          <Button
            color={"success"}
            textIcon={<Icon icon={"add-clean"}/>}
          >
            <FormattedMessage id="TEAM.PHRASE.INVITE_NEW_MEMBER"/>
          </Button>
        }
        btnConfirm={<FormattedMessage id="GENERAL.PHRASE.INVITE"/>}
        title={<FormattedMessage id="TEAM.PHRASE.INVITE_NEW_MEMBER"/>}
        handleSave={this.props.team !== null && this.handleSave}
      >
        <InputIcon
          icon={<Icon icon={"edit"} size={"1.8rem"}/>}
          id="invite_new_member"
          label={<FormattedMessage id="TEAM.PHRASE.NEW_MEMBER_EMAIL"/>}
          name="invite_new_member"
          value={memberEmail}
          onChange={this.handleChange}
        />
      </ModalCustom>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  team: user.data.team,
});
const mapDispatchToProps = {
  inviteTeamMember: newMember => inviteTeamMember(newMember)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TeamAddMember)
);
import React from "react";
import {FormattedMessage} from "react-intl";
import {Typography} from "@material-ui/core";
import APPLICATION_VIEWS from "../../../constants/APPLICATION_VIEWS";
import InputSwitcher from "../InputSwitcher";
import connect from "react-redux/es/connect/connect";
import {updateUserData} from "../../../crud/user.crud";
import styles from "./index.module.scss";
import cn from "classnames";
import {filterReset} from "../../../crud/estates/filters.crud";
import IsApplicationView from "../../../utils/IsApplicationView";

class ApplicationViewSwitcher extends React.Component {
  handleChangeSwitcher = (event) => {
    const isIndustrial = event.target.checked;
    const toggleStatus = isIndustrial ? 1 : 0;
    const data = {
      currentApplicationView: toggleStatus,
      country: "PL"
    };
    this.props.updateUserData(data, false, false, false, "/");
  };

  render() {
    const {hideLabel} = this.props;

    return(
      <div className={cn(
        styles.applicationViewSwitcherWrapper,
        hideLabel ? styles.hiddenLabels : undefined
      )}>
        {!hideLabel && <Typography style={{marginRight: "1.2rem"}}><FormattedMessage id="MENU.OFFICES"/></Typography>}
        <div className={styles.switcher}>
          <InputSwitcher
            checked={IsApplicationView({expectedAppView: APPLICATION_VIEWS.INDUSTRIAL})}
            label={!hideLabel && <FormattedMessage id="MENU.INDUSTRIAL"/>}
            extraClass={
              hideLabel ? styles.hiddenLabels : undefined
            }
            onChange={this.handleChangeSwitcher}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  user: store.user.data,
  userAuth: store.authApp.user
});
const mapDispatchToProps = {
  updateUserData: (userData, avatar, companyLogo, pdfCover, redirect) =>
    updateUserData(userData, avatar, companyLogo, pdfCover, redirect),
  filterReset: (filtersToOmit, currentApplicationView) => filterReset(filtersToOmit, currentApplicationView),
};
export default connect(mapStateToProps, mapDispatchToProps)(ApplicationViewSwitcher);
import {toAbsoluteUrl} from "_metronic";
import {Link as RouterLink, withRouter} from "react-router-dom";
import {Grid, Typography} from "@material-ui/core";
import React from "react";
import {Avatar} from "app/partials/components/Avatar";
import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import styles from "./index.module.scss";
import cn from "classnames";
import {ReactComponent as IconBack} from "app/media/icons/back.svg";
import {ROUTES} from "../../../constants";

class PropertyCardPreview extends React.Component {
  renderPreview = () => {
    const {
      title,
      semiTitle,
      semiTitle2,
      thumb,
      margin,
      small,
      themeDark,
      vertical,
      semiTitleSolid,
      width,
      onClick,
      tag,
      tagTheme,
      noOverflow,
      titleStyles,
      target,
      gridSpacing = 1,
      backArrow,
      route,
      gridStyle
    } = this.props;
    const isIndustrialParkView = route?.startsWith("/industrial-park");
    const isIndustrialWarehouseView = route?.startsWith("/industrial-building");
    const thumbPlaceholder = isIndustrialParkView ? "media/industrial-placeholder-outside.jpg" :
      isIndustrialWarehouseView ? "media/industrial-placeholder-inside.jpg" : "media/office-card-placeholder.jpg";
    const avatar = thumb
      ? thumb
      : toAbsoluteUrl(thumbPlaceholder);
    const semiTitleFinal = [
      ...semiTitle ? [`${semiTitle}`] : [],
      ...semiTitle2 && semiTitle2 !== semiTitle ? [`${semiTitle2}`] : []
    ].join(", ");

    return (
      <Grid
        container
        direction={vertical ? "column" : "row"}
        justifyContent="flex-start"
        alignItems={vertical ? "stretch" : "center"}
        wrap="nowrap"
        onClick={onClick}
        spacing={gridSpacing}
        style={gridStyle}
      >
        {backArrow &&
          <Grid item>
            <IconBack/>
          </Grid>
        }
        {thumb !== "disabled" && (
          <Grid item xs={vertical && 12}>
            <Avatar
              avatar={avatar}
              margin={margin}
              size={vertical && "100%"}
              width={width}
            />
          </Grid>
        )}
        <Grid
          item
          xs={vertical && 12}
          style={noOverflow ? {
            overflow: "hidden"
          } : undefined}
        >
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Typography
              variant={small ? "h5" : "h4"}
              color="primary"
              style={{
                fontWeight: "700",
                marginBottom: "2px",
                ...vertical ? {marginTop: 10, minHeight: 40} : {},
                ...titleStyles ? titleStyles : {}
              }}
              className={noOverflow ? styles.propertyTitleNoOverflow : undefined}
            >
              {title}
            </Typography>
            {target === "industrialWarehouse" ?
            <>
              {semiTitle &&
                <Typography
                  variant="body2"
                  style={themeDark ? {color: "#ffffff"} : undefined}
                  className={cn(
                    semiTitleSolid ? styles.semiTitleSolid : undefined,
                    noOverflow ? styles.propertyTitleNoOverflow : undefined
                  )}
                >
                  {semiTitle}
                </Typography>
              }
              {semiTitle2 &&
              <Typography
                variant="body2"
                style={themeDark ? {color: "#ffffff"} : undefined}
                className={cn(
                  noOverflow ? styles.propertyTitleNoOverflow : undefined
                )}
              >
                {semiTitle2}
              </Typography>
              }
            </>
            : semiTitleFinal && (
              <Typography
                variant="body2"
                style={themeDark ? {color: "#ffffff"} : undefined}
                className={cn(
                  semiTitleSolid ? styles.semiTitleSolid : undefined,
                  noOverflow ? styles.propertyTitleNoOverflow : undefined
                )}
              >
                {semiTitleFinal}
              </Typography>
            )
            }
            {tag && <span className={cn(styles.tag, tagTheme === "brand" ? styles.brand : undefined)}>{tag}</span>}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  render() {
    const {
      propertyID,
      route,
      routeNewTab,
      routeSuffix = '',
      children,
      isDummyViewActive,
      gridStyle
    } = this.props;

    if(isDummyViewActive) {
      return(
        <a
          href={`${ROUTES.REDD_TOWER_REDIRECT_WWW2}`}
          target={"_blank"}
          ref="noopener noreferrer"
          style={{
            display: "block",
            ...gridStyle
          }}
        >
          {children ? children : this.renderPreview()}
        </a>
      );
    }
    else if (propertyID) {
      return (
        <RouterLink
          to={propertyID ? `${route}/${propertyID}${routeSuffix}` : false}
          target={routeNewTab ? "_blank" : undefined}
          ref="noopener noreferrer"
          style={{
            display: "block",
            ...gridStyle
          }}
        >
          {children ? children : this.renderPreview()}
        </RouterLink>
      );
    }
    else if (children) {
      return children;
    }
    return this.renderPreview();
  }
}

export default injectIntl(
  connect()(withRouter(PropertyCardPreview))
);

import React from "react";
import PropertySectionTitle from "app/pages/common/components/PropertyPage/PropertySectionTitle";
import styles from "app/pages/common/components/PropertyPage/index.module.scss";
import AMENITIES from "../../../../../constants/AMENITIES";
import {FormattedMessage} from "react-intl";
import _ from "lodash";
import ReactVisibilitySensor from "react-visibility-sensor";
import LoadingScreen from "../../../../../partials/components/LoadingScreen";
import NoData from "../../../../../partials/components/NoData/NoData";
import {snakeToCamelCase} from "../../../../../utils/helpers";
import cn from "classnames";
import EditModal from "../../../../../partials/components/EditModal";
import IsAdmin from "../../../../../utils/IsAdmin";
import Grid from "@material-ui/core/Grid";

export default class PropertyAmenities extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initLoaded: false
    }
  }

  // Handlers
  handleOnViewportFetch = (isVisible) => {
    const {fetchOfficeAmenities, amenities, amenitiesLoading, match, dummyData} = this.props;
    const {initLoaded} = this.state;
    const {id} = match?.params;

    if(isVisible && !initLoaded && !amenitiesLoading && _.isEmpty(amenities) && id && !dummyData) {
      this.setState({
        initLoaded: true,
      }, () => fetchOfficeAmenities(id));
    }
  }
  // Helpers
  isAmenitiesEnable = () => {
    const {amenities} = this.props;
    if(amenities) {
      return Object.keys(amenities)?.filter(amenity => amenities?.[amenity])?.length > 0;
    }
    return false;
  }

  render() {
    const {parentRef, amenities, amenitiesLoading, basic} = this.props;
    const {initLoaded} = this.state;
    const isOfficeEditable = basic?.isEditable;

    if(initLoaded && !amenitiesLoading && !this.isAmenitiesEnable()) {
      return null;
    }
    return(
      <Grid item xs={12}>
        <div className={styles.amenitiesContainer}>
          <ReactVisibilitySensor
          onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
          scrollCheck
          partialVisibility
        >
          <PropertySectionTitle
            parentRef={parentRef}
            title={<FormattedMessage id="VOCABULARY.Amenities"/>}
            titleActions={
            <IsAdmin markup="svg">
              <EditModal
                target="office"
                parentData={basic}
                isPropertyEditable={isOfficeEditable}
                initialTab="amenities"
              />
            </IsAdmin>
            }
            style={{marginBottom: "0"}}
          />
        </ReactVisibilitySensor>
        {
          amenitiesLoading ? <LoadingScreen/> :
            amenities ?
              <div className={styles.propertyAmenitiesWrapper}>
                {Object.keys(amenities)?.map((amenity, index) => {
                  const icon = AMENITIES?.filter(item => item?.id === amenity)?.[0]?.icon;
                  const isActive = amenities?.[amenity];

                  return(
                    <span
                      key={index}
                      className={cn(
                        styles.amenityWrapper,
                        isActive ? styles.active : undefined
                      )}
                    >
                      {icon && <span className={styles.amenityIcon}>{icon}</span>}
                      <FormattedMessage id={snakeToCamelCase(amenity)}/>
                    </span>
                  );
                })}
              </div> :
              <NoData/>
          }
        </div>
      </Grid>
    );
  }
}
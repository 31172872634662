import React from "react";
import LoadingScreen from "app/partials/components/LoadingScreen";
import {withRouter} from "react-router-dom";
import NoData from "../../../partials/components/NoData/NoData";
import ShortlistsLandlordList from "app/pages/offices/ShortlistsLandlordPage/components/ShortlistsLandlordList";
import {FormattedMessage} from "react-intl";
import {Grid} from "@material-ui/core";
import ReactVisibilitySensor from "react-visibility-sensor";
import Button from "../../../partials/components/Button";

class ShortlistsLandlordPageIndustrial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      totalPages: Math.ceil(props.landlordItemsCount/props.landlordItemsPerPage),
      initLoaded: false
    };
  }
  componentDidMount() {
    const {filterReset} = this.props;
    filterReset();
  }
  componentDidUpdate(prevProps, prevState) {
    if(prevProps.landlordItemsPerPage !== this.props.landlordItemsPerPage || prevProps.landlordItemsCount !== this.props.landlordItemsCount) {
      this.setState({
        totalPages: Math.ceil(this.props.landlordItemsCount/this.props.landlordItemsPerPage)
      });
    }
  }

  // Handle fetch data on enter viewport
  handleOnViewportFetch = (isVisible) => {
    const {fetchShortlistsIndustrialLandlord, landlordLoading} = this.props;
    const {initLoaded} = this.state;
    const params = {
      page: 1
    };

    if(isVisible && !landlordLoading && !initLoaded) {
      this.setState({
        initLoaded: true
      }, () => {
         fetchShortlistsIndustrialLandlord({params});
      });
    }
  };
  // Function to load data from next page (pagination based)
  handleLoadMore = () => {
    const { fetchMoreShortlistsIndustrialLandlord } = this.props;
    // Get current pagination page
    const currentPage = this.state.page;
    // Set next pagination page
    const nextPage = currentPage + 1;
    // Update page state and fetch more Analytics Stats
    this.setState({
      page: nextPage
    }, () => {const params = {page: nextPage}; fetchMoreShortlistsIndustrialLandlord({params})});
  };

  render() {
    const {
      landlordItems,
      landlordLoading,
      landlordShortlistUnits,
      landlordShortlistUnitsLoading,
      fetchShortlistIndustrialUnitsLandlord,
      fetchShortlistIndustrialUnitsLandlordReset,
      loadingMore
    } = this.props;
    const {page, totalPages} = this.state;

    return (
      <ReactVisibilitySensor
        onChange={(isVisible) => this.handleOnViewportFetch(isVisible)}
        scrollCheck
        partialVisibility
      >
      {landlordLoading ? <LoadingScreen/> : landlordItems && landlordItems?.length > 0 ?
        <>
        <ShortlistsLandlordList
          data={landlordItems}
          landlordShortlistUnits={landlordShortlistUnits}
          landlordShortlistUnitsLoading={landlordShortlistUnitsLoading}
          fetchShortlistUnitsLandlord={fetchShortlistIndustrialUnitsLandlord}
          fetchShortlistUnitsLandlordReset={fetchShortlistIndustrialUnitsLandlordReset}
          target="industrial"
          />
        {page+1 <= totalPages && totalPages > 1 && (
          <Grid container spacing={2} justifyContent="center" alignItems="center" style={{marginTop: "1rem"}}>
          {
            loadingMore ? <LoadingScreen/> :
              <Button
                onClick={() => this.handleLoadMore()}
              >
                <FormattedMessage id="GENERAL.PHRASE.LOAD_MORE"/>
              </Button>
          }
        </Grid>
        )}
        </> : <NoData/>
      }
      </ReactVisibilitySensor>
    );
  }
}

export default withRouter(ShortlistsLandlordPageIndustrial);
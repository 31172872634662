import React from "react";
import styles from "../../../../../common/external/components/index.module.scss";
import {FormattedMessage} from "react-intl";
import SliderCustom from "../../../../../../partials/components/SliderCustom";
import ModalCustom from "../../../../../../partials/components/ModalCustom";
import IndustrialPreviewIPI from "../../../../components/IndustrialPreviewIPI";
import IPI_PLAN_SOURCE from "../../../../../../constants/IPI_PLAN_SOURCE";

export default class OffersOnlineSingleSiteplan extends React.Component {
    render() {
        const {planImage, name, ipiPlanImage, ipi, id} = this.props;

        return (
            <div ref={this.props.reference} className={styles.boxContainer}>
                <div className={styles.titleWrapper}>
                    <h2><FormattedMessage id="PROPERTY_PARTICULARS.TAB.SITEPLAN"/></h2>
                </div>
                <div>
                    {ipiPlanImage ?
                    <IndustrialPreviewIPI
                        title={name}
                        planSource={IPI_PLAN_SOURCE.PARK}
                        planPark={ipiPlanImage}
                        parkID={id}
                        units={ipi}
                        triggerStyle={{height: "40rem"}}
                        triggerBtnStyle={{backgroundColor: "var(--brand-btn-bg-color)", color: "var(--brand-btn-bg-font-color)"}}
                    /> : planImage &&
                    <ModalCustom
                        btn={
                            <img
                                src={planImage}
                                alt={`${name}`}
                                style={{
                                    width: "100%",
                                    cursor: "zoom-in"
                                }}
                            />
                        }
                        title=""
                        btnReject={<FormattedMessage
                            id="GENERAL.PHRASE.CLOSE"/>}
                        fullScreen
                    >
                        <SliderCustom images={[planImage]} controls={false}/>
                    </ModalCustom>
                    }
                </div>
            </div>
        );
    }
}
import {Grid} from "@material-ui/core";
import React from "react";
import PropertyCardPreview from "./PropertyCardPreview";

export default class PropertyExpandedCardPreview extends React.Component {
  render() {
    const {children, propertyID, marketID, title, semiTitle, semiTitle2, semiTitleSolid, thumb, route} = this.props;

    return (
      <Grid container spacing={4} alignItems="center">
        <Grid item>
            <PropertyCardPreview
                propertyID={propertyID}
                marketID={marketID}
                title={title}
                semiTitle={semiTitle}
                semiTitle2={semiTitle2}
                semiTitleSolid={semiTitleSolid}
                thumb={thumb}
                route={route}/>
        </Grid>
        {children}
      </Grid>
    );
  }
}
import React from 'react';
import ModalCustom from "app/partials/components/ModalCustom";
import InputIcon from "app/partials/components/InputIcon";
import {addIndustrialWarehouse, fetchIndustrialParksSimpleList} from "app/crud/estates/industrial/industrial.crud";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import LoadingScreen from "app/partials/components/LoadingScreen";
import {Grid} from '@material-ui/core';
import Icon from "@material-ui/core/Icon/Icon";
import SelectSearch from "../../../../SelectSearch";
import {createVisibleArray, sortArrayAlph} from "../../../../../../utils/helpers";
import InputAdornments from "../../../../InputAdornments";
import Button from "../../../../Button";
import IconHandmade from "app/partials/components/Icon";

class IndustrialParkWarehouseAdd extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
        name: '',
        park: props.parkID || null,
        docks: '',
        nameError: false,
        parkError: false
    };
    const params = {
      isEditable: 'true'
    };
    props.fetchIndustrialParksSimpleList({params});
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {addingBuilding, buildingAdded} = this.props;

    if(prevProps?.addingBuilding && !addingBuilding && buildingAdded) {
      this.handleCloseModal();
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  handleCloseModal = () => {
    this.child.current.handleClose();
  };
  handleAutocompleteChange = (target) => {
    this.setState({
      [target.name]: target.value[0]
    });
  };
  handleSave = () => {
    const {addIndustrialWarehouse, ordering} = this.props;
    const {name, park, docks} = this.state;
    this.setState({
      nameError: !name.length,
      parkError: park === null,
    }, () => {
      if(name.length && park) {
        const data = {
          name,
          park,
          docks: docks || null
        };
        addIndustrialWarehouse(data, ordering);
        return true;
      }
    });
  };

  render() {
    const {parks, loading, parkID, btnTrigger, addingBuilding} = this.props;
    const {name, park, docks, nameError} = this.state;
    let visibleParks = [];
    let sortedVisibleParks = [];
    if(loading) {
      return <LoadingScreen/>;
    }
    if(parks) {
      visibleParks = parks ? createVisibleArray(parks, ["id", "name"]) : [];
      sortedVisibleParks = parks ? sortArrayAlph(createVisibleArray(parks, "name")) : [];
    }
    return(
      <ModalCustom
        ref={this.child}
        btn={
        btnTrigger ? btnTrigger :
          <Button
            color="success"
            textIcon={<IconHandmade icon={"add-clean"}/>}
          >
            <FormattedMessage id="INDUSTRIAL.PHRASE.ADD_WAREHOUSE"/>
          </Button>
        }
        btnConfirm={addingBuilding ? <LoadingScreen loaderStyle={{width: "1.9rem", height: "1.9rem"}}/> : <FormattedMessage id="GENERAL.PHRASE.ADD"/>}
        title={<FormattedMessage id="INDUSTRIAL.PHRASE.ADD_WAREHOUSE"/>}
        handleSave={this.handleSave}
        btnConfirmDisabled={addingBuilding}
      >
        <Grid container spacing={2} direction="column">
          <Grid item>
            <InputIcon
                label={<FormattedMessage id="INDUSTRIAL.PHRASE.BUILDING_NAME"/>}
                name="name"
                value={name}
                error={nameError}
                onChange={this.handleChange}
            />
          </Grid>
          <Grid item>
            <InputAdornments label={<FormattedMessage id="INDUSTRIAL.PHRASE.DOCKS"/>} name="docks" value={docks} onChange={this.handleChange}/>
          </Grid>
          {!parkID && (
          <Grid item>
            <SelectSearch
              width={300}
              name="park"
              value={park}
              label={<FormattedMessage id="GENERAL.PHRASE.PARK"/>}
              icon={<Icon color="primary">warehouse</Icon>}
              options={sortedVisibleParks}
              optionsFull={visibleParks}
              optionFullLabel="name"
              optionFullValue="id"
              onChange={this.handleAutocompleteChange}
            />
          </Grid>
          )}
        </Grid>
      </ModalCustom>
    );
  }
}

const mapStateToProps = store => ({
  parks: store.industrial.parksSimpleList,
  loading: store.industrial.parksSimpleListLoading,
  addingBuilding: store.industrial.addingBuilding,
  buildingAdded: store.industrial.buildingAdded
});
const mapDispatchToProps = {
  addIndustrialWarehouse: (data, ordering) => addIndustrialWarehouse(data, ordering),
  fetchIndustrialParksSimpleList: ({params}) => fetchIndustrialParksSimpleList(params),
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(IndustrialParkWarehouseAdd)
);
import React from "react";
import ModalCustom from "app/partials/components/ModalCustom";
import {FormattedMessage} from "react-intl";
import connect from "react-redux/es/connect/connect";
import Icon from "app/partials/components/Icon";
import Button from "app/partials/components/Button";
import {Grid, Typography} from "@material-ui/core";
import infoStyles from "../../../../common/components/LeadsPages/index.module.scss";
import cn from "classnames";
import CompetitionCompareManageItem from "./CompetitionCompareManageItem";
import {updateOfficeCompetitors} from "../../../../../crud/estates/offices/officeBuilding.crud";

class CompetitionCompareManage extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      open: false,
      selectedProperties: []
    };
  }
  componentDidMount() {
    if(this.props.properties) {
      this.setState({
        selectedProperties: this.props.properties.map(property => property?.id)
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(!prevProps?.properties && this.props.properties) {
      this.setState({
        selectedProperties: this.props.properties.map(property => property?.id)
      })
    }
  }

  // Handlers
  handleSelectedProperties = (initialProperty, prevProperty, newProperty) => {
    const {selectedProperties} = this.state;
    let updatedProperties = selectedProperties;
    //
    if(newProperty) {
      const foundIndex = updatedProperties.findIndex(id => id === initialProperty || id === prevProperty);
      updatedProperties[foundIndex] = newProperty;
    }
    else {
      const foundIndex = updatedProperties.findIndex(id => id === prevProperty);
      updatedProperties[foundIndex] = initialProperty;
    }

    this.setState({
      selectedProperties: updatedProperties
    });
  }
  handleProceed = () => {
    const {updateOfficeCompetitors, officeID} = this.props;
    const {selectedProperties} = this.state;

    if(updateOfficeCompetitors && officeID && selectedProperties.length > 0) {
      updateOfficeCompetitors(officeID, {ids: selectedProperties});
    }
  };

  render() {
    const {properties, updating} = this.props;

    return (
      <ModalCustom
        ref={this.child}
        btn={
        <Button
          color={"simple"}
          textIcon={<Icon icon={"edit"}/>}
        >
          <FormattedMessage id="VOCABULARY.Change buildings"/>
        </Button>
        }
        title={<FormattedMessage id="VOCABULARY.Comparable properties"/>}
        maxWidth="sm"
        saving={updating}
        btnConfirm={<FormattedMessage id="GENERAL.PHRASE.SAVE"/>}
        btnReject={<FormattedMessage id="GENERAL.PHRASE.CANCEL"/>}
        handleSave={this.handleProceed}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={cn(infoStyles.titleDescriptionWrapper, infoStyles.warning)}>
              <Icon icon={"info-circle"} size={"1.8rem"}/>
              <Typography variant="body1">
                <FormattedMessage id="VOCABULARY.The revised list will be visible only to you."/>
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              {properties && properties.length > 0 &&
                properties.map((item, index) => {
                  return(
                    <React.Fragment key={index}>
                      <CompetitionCompareManageItem
                        property={item}
                        onPropertiesUpdate={this.handleSelectedProperties}
                        {...this.props}
                      />
                    </React.Fragment>
                  );
                })
              }
            </div>
          </Grid>
        </Grid>
      </ModalCustom>
    );
  }
}

const mapStateToProps = (store) => ({
  offices: store?.offices?.officesMinified,
  loading: store?.offices?.officesMinifiedLoading,
  updating: store?.officeBuilding?.competitorsUpdating
});
const mapDispatchToProps = {
  updateOfficeCompetitors: (officeID, data) => updateOfficeCompetitors(officeID, data)
};
export default connect(mapStateToProps, mapDispatchToProps)(CompetitionCompareManage);
import React from "react";
import styles from "./index.module.scss";
import IconColorMUI from "../../../../../partials/components/IconColorMUI";
import QRCode from "react-qr-code";
import {FormattedMessage} from "react-intl";
import {formatPhoneNumber} from "../../../../../utils/helpers";

export default function LeadInfoBox({title, children, contactName, contactEmail, contactPhone}) {
  return(
    <div className={styles.infoBoxWrapper}>
      <div className={styles.infoBoxHeader}>{
        title ? <h3>{title}</h3> : (
          contactName || contactEmail || contactPhone ?
            <div className={styles.infoBoxContactWrapper}>
              <div className={styles.contactDetailsWrapper}>
                {contactName && <p>{contactName}</p>}
                {(contactEmail || contactPhone) &&
                <div className={styles.detailsWrapper}>
                  {contactPhone &&
                    <a href={`tel:${contactPhone}`}>
                      <IconColorMUI icon="call" size="16px" style={{paddingRight: "5px"}}/>{formatPhoneNumber(contactPhone)}
                    </a>
                  }
                  {contactEmail &&
                    <a href={`mailto:${contactEmail}`}>
                      <IconColorMUI icon="mail" size="16px" style={{paddingRight: "5px"}}/>{contactEmail}
                    </a>
                  }
                </div>
                }
              </div>
              <div className={styles.contactQRWrapper}>
                <p><IconColorMUI icon="call" size="14px" style={{paddingRight: "5px", lineHeight: "0"}}/><FormattedMessage id="GENERAL.PHRASE.SCAN"/></p>
                <div className={styles.qrWrapper}>
                  <QRCode
                  size={56}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={contactPhone}
                  viewBox={`0 0 56 56`}
                  />
                </div>
              </div>
            </div>
            : ""
        )
      }</div>
      <div className={styles.infoBoxBody}>{children}</div>
    </div>
  );
}
import React from "react";
import {withRouter} from "react-router-dom";
import TableProperties from "app/partials/components/TableProperties/TableProperties";
import {TABLE_CELLS} from "../../../partials/components/TableProperties";
import store from "../../../store/store";
import {FormattedMessage} from "react-intl";
import {Grid} from "@material-ui/core";
import _ from "lodash";
import Button from "../../../partials/components/Button";

class ShortlistLandlordPage extends React.Component {
  constructor(props) {
    super(props);
    //
    this.state = {
      isReady: false,
      initLoaded: false,
      periodLastYear: false
    }
  }
  componentDidMount() {
    const {setLatestActiveTablePage, filterReset} = this.props;
    setLatestActiveTablePage(0);
    filterReset(["market"]);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const isActiveFilterLastYear = !_.isEmpty(store.getState().filters?.year);
    if(!_.isEqual(isActiveFilterLastYear, this.state.periodLastYear)) {
      this.setState({
        periodLastYear: isActiveFilterLastYear
      });
    }
  }

  // Handlers
  handleOnViewportFetch = (isVisible) => {
    const {
      fetchShortlistsLandlord,
      callReddClickEvent,
      loading
    } = this.props;
    const {initLoaded} = this.state;

    if(isVisible && !loading && !initLoaded) {
      this.setState({
        initLoaded: true
      }, () => {
        const params = {
          isFilterSet: 0,
          forceUpdateChilds: [],
          ...store.getState().filters
        };
        fetchShortlistsLandlord({params});
      });
    }
    if (isVisible && !this.state.isReady) {
      this.setState({
        isReady: true
      }, () => {
        callReddClickEvent(
          "offices-interested-in-my-buildings-page-enter",
          "user"
        );
      })
    }
  };
  handleOnViewport = (isInViewport) => {
    this.handleOnViewportFetch(isInViewport);
  }
  handleChangePeriod = () => {
    this.setState({
      periodLastYear: !this.state.periodLastYear
    }, () => this.handleFilterByPeriod());
  }
  handleFilterByPeriod = () => {
    const {filterByModules, fetchShortlistsLandlord} = this.props;
    const {periodLastYear} = this.state;
    const currenYear = new Date().getFullYear();
    const params = {
      ...store.getState().filters,
      ...periodLastYear ? {year: `${currenYear}`} : {year: ""}
    };
    filterByModules(params);
    fetchShortlistsLandlord({params});
  }

  render() {
    const {
      items,
      itemsPerPage,
      itemsCount,
      loading,
      fetchShortlistsLandlord
    } = this.props;
    const {periodLastYear} = this.state;

    return (
        <TableProperties
          tableLabel={<FormattedMessage id="VOCABULARY.My units added to offers"/>}
          headRows={[
            {
              id: 'asset_name',
              numeric: false,
              disablePadding: false,
              label: 'VOCABULARY.Building',
              sort: false
            },
            {
              id: 'area',
              numeric: true,
              disablePadding: false,
              label: 'VOCABULARY.Area',
              sort: false
            },
            {
              id: 'company_name',
              numeric: false,
              disablePadding: false,
              label: 'VOCABULARY.Company name',
              sort: false
            },
            {
              id: 'created_at',
              numeric: false,
              disablePadding: false,
              label: 'GENERAL.PHRASE.CREATED_AT',
              sort: false
            }
          ]}
          bodyRows={items}
          dataTarget={TABLE_CELLS.SHORTLIST_LANDLORD}
          itemsPerPage={itemsPerPage}
          itemsCount={itemsCount}
          totalItems={itemsCount}
          loading={loading}
          filterBarEnable
          handleFetchData={fetchShortlistsLandlord}
          onEnterViewport={this.handleOnViewport}
          toolbar={
          <Grid container spacing={1} justifyContent="flex-end" alignItems="center">
            <Grid item>
              <Button
                color={periodLastYear ? "simple" : "danger"}
                disabled={!periodLastYear}
                onClick={() => this.handleChangePeriod()}
              >
                <FormattedMessage id="VOCABULARY.All"/>
              </Button>
            </Grid>
            <Grid item>
              <Button
                color={periodLastYear ? "danger" : "simple"}
                disabled={periodLastYear}
                onClick={() => this.handleChangePeriod()}
              >
                <FormattedMessage id="VOCABULARY.Current year"/>
              </Button>
            </Grid>
          </Grid>
          }
        />
    );
  }
}

export default withRouter(ShortlistLandlordPage);
import React from "react";
import styles from "../../../../../common/external/components/index.module.scss";
import GoogleMap from "./GoogleMap";
import {Marker} from "react-google-maps";
import GOOGLE_MAPS_OPTIONS from "../../../../../../constants/GOOGLE_MAPS_OPTIONS";
import IconColorMUI from "../../../../../../partials/components/IconColorMUI";
import {FormattedMessage} from "react-intl";
import {handleEmptyValue, toNumberFormat} from "../../../../../../utils/helpers";

export default class PropertyParticularsSingleLocation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lat: props.data && Number(props.data.geoLatitude),
            lng: props.data && Number(props.data.geoLongitude)
        };
    }
    render() {
        const {data, intl} = this.props;
        const {lat, lng} = this.state;
        // Map settings
        const options = {
          minZoom: 3,
          maxZoom: 20,
          styles: GOOGLE_MAPS_OPTIONS.EXTERNAL_STYLES,
          scrollwheel: false
        };
        const pos = { lat, lng };
        const zoom = 11;
        // Data values
        const officeAddress = [
            ...data.addressStreet ? [data.addressStreet] : [],
            ...data.district ? [data.district] : [],
            ...data.addressCity ? [data.addressCity] : []
        ];
        const poisMerge = [
            ...data.pois["buildings"] ? [data.pois["buildings"]] : [],
            ...data.pois["hubs"] ? [data.pois["hubs"]] : []
        ].flat();
        const poisSorted = poisMerge.reduce(function (r, a) {
            r[a.category] = r[a.category] || [];
            r[a.category].push(a);
            return r;
        }, Object.create(null));
        const isPOISExists = poisSorted && typeof poisSorted === 'object' && Object.entries(poisSorted).length > 0;

        return(
            <div ref={this.props.reference} className={styles.boxContainer}>
                <div className={styles.titleWrapper}>
                    <h2><FormattedMessage id="PROPERTY_PARTICULARS.TAB.LOCATION"/></h2>
                    <p>{officeAddress.join(", ")}</p>
                </div>
                {lat && lng && (
                <div className={isPOISExists && styles.locationWrapper}>
                    <GoogleMap
                      options={options}
                      center={pos}
                      zoom={zoom}
                    >
                      <Marker position={pos} />
                    </GoogleMap>
                </div>
                )}
                {isPOISExists && <h2><FormattedMessage id="PROPERTY_PARTICULARS.PHRASE.POI"/></h2>}
                {isPOISExists && (
                <div className={styles.poisWrapper}>
                    <table className={styles.poiTable}>
                        <tbody>
                        {poisSorted && Object.entries(poisSorted).map((poi, index) => {
                            const categoryName = Object.keys(poisSorted)[index];
                            return(
                              <tr key={index}>
                                <td>
                                  <table>
                                    <tbody>
                                      <tr className={styles.tHeadRow}>
                                          <th className={styles.alignLeft}>{intl.formatMessage({id: categoryName})}</th>
                                          <th><IconColorMUI icon="route"/></th>
                                          <th><IconColorMUI icon="directions_walk"/></th>
                                          <th><IconColorMUI icon="directions_car"/></th>
                                          <th><IconColorMUI icon="departure_board"/></th>
                                      </tr>
                                      {poi && poi[1] && poi[1].map((item, index) => {
                                      return(
                                          <tr key={index}>
                                              <td data-label={categoryName}>{item.name}</td>
                                              <td data-label={intl.formatMessage({id: "PROPERTY_PARTICULARS.PHRASE.Distance"})}>{toNumberFormat(item.distance)} m</td>
                                              <td data-label={intl.formatMessage({id: "PROPERTY_PARTICULARS.PHRASE.Time by foot"})}>{handleEmptyValue(toNumberFormat(item.timeOnFoot, 'minutes'))}</td>
                                              <td data-label={intl.formatMessage({id: "PROPERTY_PARTICULARS.PHRASE.Time by car"})}>{handleEmptyValue(toNumberFormat(item.timeWithCar, 'minutes'))}</td>
                                              <td data-label={intl.formatMessage({id: "PROPERTY_PARTICULARS.PHRASE.Time by public transport"})}>{handleEmptyValue(toNumberFormat(item.timeWithPublicTransport, 'minutes'))}</td>
                                          </tr>
                                      );
                                      })}
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                )}
            </div>
        );
    }
}
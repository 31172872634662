import React, {useEffect, useState} from "react";
import {Grid, Typography} from "@material-ui/core";
import AccountRequestDecided from "./AccountRequestDecided";
import {FormattedMessage, injectIntl} from "react-intl";
import {rejectAccountRequest} from "../../../../crud/accountRequest.crud";
import connect from "react-redux/es/connect/connect";
import {ROUTES} from "../../../../constants";
import ConfirmationDialog from "../../../../partials/components/ConfirmationDialog";
import Button from "../../../../partials/components/Button";

function AccountRequestDecide({isAccepted = false, isRejected = false, processing, ...props}) {
  const [accepted, setAccepted] = useState(isAccepted);
  const [rejected, setRejected] = useState(isRejected);
  const [rejecting, setRejecting] = useState(false);
  const [confirmRedirectOpen, setConfirmRedirectOpen] = useState(false);
  const isDecided = accepted || rejected;

  //
  useEffect(() => {
    if(!processing) {
      setRejecting(processing);
    }
  }, [processing]);

  // Handlers
  const handleDecide = (option) => {
    const {rejectAccountRequest, row} = props;

    if(option === "rejected") {
      const requestID = row?.id;
      // Actions
      setRejecting(true);
      setRejected(true);
      setConfirmRedirectOpen(false);
      if(requestID) {
        rejectAccountRequest(requestID);
      }
    }
    else if(option === "accepted") {
      setAccepted(false);
      const requestData = [
        ...row?.name ? [`name=${row?.name}`] : [],
        ...row?.email ? [`mail=${row?.email}`] : [],
        ...row?.phone ? [`phone=${row?.phone}`] : [],
        ...row?.language ? [`lang=${row?.language}`] : [],
        `request=true`
      ];
      const requestQuery = requestData.join("&");
      window.location.href = `${ROUTES.ADMIN_ADD_USER}?${requestQuery}`;
    }
  }

  return(
    <>
      <Grid container spacing={1} justifyContent="flex-end" wrap="nowrap">
        {isDecided &&
        <Grid item>
          <AccountRequestDecided
            rejecting={rejecting}
          >
            <Typography
              variant="body1"
              style={{
                textTransform: "uppercase",
                lineHeight: "1.6rem",
                padding: "1.1rem 1.2rem 1.2rem",
                color: accepted ? "#0abb87" : "#EC5638"
              }}
            >
              <strong><FormattedMessage id={accepted ? "AR.PHRASE.ACCOUNT_CREATED" : "AR.PHRASE.REJECTED"}/></strong>
            </Typography>
          </AccountRequestDecided>
        </Grid>
        }
        {!isDecided &&
        <Grid item>
          <Button
            onClick={() => setConfirmRedirectOpen(true)}
          >
            <FormattedMessage id="AR.PHRASE.REJECT"/>
          </Button>
        </Grid>
        }
        {!isDecided &&
        <Grid item>
          <Button
            color="success"
            onClick={() => handleDecide("accepted")}
          >
            <FormattedMessage id="AR.PHRASE.CREATE_ACCOUNT"/>
          </Button>
        </Grid>
        }
      </Grid>
      <ConfirmationDialog
        open={confirmRedirectOpen}
        onClose={() => setConfirmRedirectOpen(false)}
        onConfirm={() => handleDecide("rejected")}
        message={
        <>
          <FormattedMessage id="AR.PHRASE.REJECT_CONFIRM_MESSAGE"/>
          <br/><br/>
          <FormattedMessage id="AR.PHRASE.REJECT_CONFIRM_MESSAGE_2"/>
          <br/><br/>
          <FormattedMessage id="GENERAL.PHRASE.ACTION_CONFIRMATION_MESSAGE"/>
        </>
        }
      />
    </>
  );
}

const mapStateToProps =({accountRequest}) => ({
  processing: accountRequest?.rejecting
});
const mapDispatchToProps = {
  rejectAccountRequest: (requestID) => rejectAccountRequest(requestID)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AccountRequestDecide)
);
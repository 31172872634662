import React from "react";
import styles from "../index.module.scss";
import {ReactComponent as IconTime} from "app/media/icons/icon-time.svg";
import {getBannerDataValue} from "../helpers";

const PaywallContactRequested = ({data}) => {
  return(
    <div className={styles.paywallContactRequestedWrapper}>
      <span className={styles.icon}><IconTime/></span>
      <h2>{getBannerDataValue(data, "title")}</h2>
      <p>{getBannerDataValue(data, "list_1")}</p>
    </div>
  );
};

export default PaywallContactRequested;
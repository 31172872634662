import React from "react";
import {FormattedMessage} from "react-intl";
import styles from "./index.module.scss";
import clsx from "clsx";
import {TableCell, TableHead, TableRow, TableSortLabel, Tooltip, Typography} from "@material-ui/core";
import CheckboxCustom from "../../CheckboxCustom";

export default class TableHeadMUI extends React.Component {
  // Helpers
  renderLabelTooltip = (label, tooltip) => {
    if (tooltip) {
      return (
        <Tooltip
          placement="top"
          title={
            <Typography variant="body1">
              <FormattedMessage id={tooltip}/>
            </Typography>
          }
        >
          <FormattedMessage id={label}/>
        </Tooltip>
      );
    }
    else if(label) {
      return <FormattedMessage id={label}/>;
    }
    return <span/>;
  };
  // Handlers
  handleChecked = (event) => {
    const {onSelectAll} = this.props;
    const isChecked = event?.target?.checked;
    onSelectAll && onSelectAll(isChecked);
  }

  render() {
    const {data, orderBy, order, onRequestSort, checkable, selected, totalItems} = this.props;

    return (
      <TableHead>
        <TableRow>
          {checkable &&
          <TableCell style={{width: "3.5rem", paddingRight: "0"}}>
            <CheckboxCustom
              checked={selected?.length  === totalItems}
              onClick={this.handleChecked}
            />
          </TableCell>
          }
          {data.map((column, index) => {
            const isActive = orderBy === column?.id;
            const isSortInactive = column?.sort === "inactive";
            return (
              <TableCell
                key={column?.id || index}
                align={column?.numeric ? "right" : "left"}
                padding={column?.disablePadding ? "none" : "normal"}
                sortDirection={orderBy && order ? order : false}
                style={column?.stickyColumn ? {
                  position: "sticky",
                  right: 0
                } : undefined}
              >
                {column?.sort ? (
                  <TableSortLabel
                    active={isActive}
                    direction={order}
                    style={{
                      flexDirection: 'row',
                      ...isSortInactive ? {cursor: "not-allowed"} : {}
                    }}
                    onClick={() => onRequestSort && !isSortInactive ? onRequestSort(column?.id, order) : undefined}
                  >
                    <p
                      className={clsx(
                        styles.headCell
                      )}
                    >
                      {this.renderLabelTooltip(column?.label, column?.infoTooltip)}
                    </p>
                  </TableSortLabel>
                ) : (
                  <p
                    className={clsx(
                      styles.headCell
                    )}
                  >
                    {this.renderLabelTooltip(column?.label, column?.infoTooltip)}
                  </p>
                )}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }
}
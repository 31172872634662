import React from "react";
import TableCell from "@material-ui/core/TableCell/TableCell";
import UnitAreaPreview from "app/partials/components/_DataParts/UnitAreaPreview";
import UnitFloorPreview from "app/partials/components/_DataParts/UnitFloorPreview";
import UnitTypePreview from "app/partials/components/_DataParts/UnitTypePreview";
import UnitStatusPreview from "app/partials/components/_DataParts/UnitStatusPreview";
import UnitAvailabilityPreview from "app/partials/components/_DataParts/UnitAvailabilityPreview";
import UpdatedAtPreview from "app/partials/components/_DataParts/UpdatedAtPreview";
import {UNIT_STATUS} from "app/constants";
import {Grid} from "@material-ui/core";
import UnitForecastPremium from "app/partials/components/Units/UnitForecastPremium";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import EditModal from "../../EditModal";
import UnitAddToOffer from "../../Units/UnitAddToOffer";
import IsPro from "../../../../utils/IsPro";
import Button from "../../Button";
import Icon from "../../Icon";

class TableOfficeUnitsCells extends React.Component {
  render() {
    const {
      row,
      parentData
    } = this.props;
    const isOfficeEditable = parentData && parentData?.isEditable;

    return (
      <>
        <TableCell component="th" scope="row" align="right">
          <UnitAreaPreview
            data={row}
            parentData={parentData}
            isOfficeEditable={isOfficeEditable}
            flexibleUnitForm={
              <EditModal
                target="unit"
                parentData={row}
                triggerDots
                isPropertyEditable={isOfficeEditable}
                initialTab="flexibleUnits"
                extraData={{
                  officeId: parentData?.id
                }}
                triggerCustom={
                <Button
                  type={"icon"}
                  color={row?.canBeDivided ? "danger" : "simple"}
                  tooltip={<FormattedMessage id="UNITS.PHRASE.FLEXIBLE_UNITS" />}
                >
                  <Icon icon={"units-brick"}/>
                </Button>
                }
              />
            }
          />
        </TableCell>
        <TableCell>{row?.entrance ? row?.entrance : "-"}</TableCell>
        <TableCell align="right">
          <UnitFloorPreview floor={row?.floor} />
        </TableCell>
        <TableCell>
          <UnitTypePreview
            unitType={row?.unitType}
            isSublease={row?.isSublease}
          />
        </TableCell>
        <TableCell>
          <UnitStatusPreview unitStatus={row?.status} />
        </TableCell>
        <TableCell>
          <UnitAvailabilityPreview
            date={row?.availableFrom}
            isReserved={row?.status === UNIT_STATUS.RESERVED}
            isUnavailable={row?.status === UNIT_STATUS.UNAVAILABLE}
            availableInMonths={row?.availableFromAdditionalTime}
            parentStatus={parentData?.status}
          />
        </TableCell>
        <TableCell>
          <UpdatedAtPreview level={row?.updateLevel} date={row?.updatedAt} alternative />
        </TableCell>
        <TableCell align="right">
          <UnitForecastPremium
            unit={row}
            area={row?.area}
            officeData={parentData}
          />
        </TableCell>
        <TableCell align="right">
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center" wrap="nowrap">
            <IsPro>
              {row?.status !== UNIT_STATUS.UNAVAILABLE &&
                <Grid item>
                  <UnitAddToOffer
                    propertyID={parentData?.id}
                    unitId={row?.id}
                    source="officeUnitsUnitCell"
                  />
                </Grid>
              }
            </IsPro>
            <Grid item>
              <EditModal
                target="unit"
                parentData={row}
                triggerDots
                isPropertyEditable={isOfficeEditable}
                initialTab={isOfficeEditable ? "basic" : "note"}
                extraData={{
                  officeId: parentData?.id,
                  officeName: parentData?.officeName
                }}
              />
            </Grid>
          </Grid>
        </TableCell>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  permOffers: state.units.officeUnitsPermOffers,
});
export default injectIntl(
  connect(mapStateToProps, null)(TableOfficeUnitsCells)
);

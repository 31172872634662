import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import { fetchOfficesTransactions } from "../../../crud/estates/offices/offices.crud";
import { fetchIndustrialTransactions } from "../../../crud/estates/industrial/industrial.crud";
import LatestTransactionsPage from "./LatestTransactionsPage";
import {callReddClickEvent, fetchMarkets, fetchMarketsIndustrial} from "app/crud/user.crud";
import {filterByModules, filterReset} from "../../../crud/estates/filters.crud";
import {setLatestActiveTablePage} from "../../../crud/tablePage.crud";

const mapStateToProps = (store) => ({
  officesTransactions: store.offices.officesTransactions,
  officesTransactionsLoading: store.offices.officesTransactionsLoading,
  industrialTransactions: store.industrial.industrialTransactions,
  industrialTransactionsLoading: store.industrial.industrialTransactionsLoading,
  user: store.user.data,
  markets: store?.user?.markets,
  marketsLoading: store?.user?.marketsLoading,
  marketsIndustrial: store?.user?.marketsIndustrial,
  marketsIndustrialLoading: store?.user?.marketsIndustrialLoading
});
const mapDispatchToProps = {
  fetchOfficesTransactions: ({params}) => fetchOfficesTransactions(params),
  fetchIndustrialTransactions: ({params}) => fetchIndustrialTransactions(params),
  fetchMarkets: fetchMarkets,
  fetchMarketsIndustrial: fetchMarketsIndustrial,
  filterReset: (filtersToOmit) => filterReset(filtersToOmit),
  filterByModules: (params) => filterByModules(params),
  setLatestActiveTablePage: (page) => setLatestActiveTablePage(page),
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params) => callReddClickEvent(clickType, contentType, appLabel, objectId, params)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LatestTransactionsPage)
);
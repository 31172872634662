import React from "react";
import {Carousel} from "react-bootstrap";
import styles from "./index.module.scss";
import clsx from "clsx";

export default class SliderHtml extends React.Component {
  state = {
    index: 0,
    direction: null
  };
  render() {
    const {data, multipleItemsPerSlide, theme, alternative, style} = this.props;
    const {index, direction} = this.state;
    const handleSelect = (selectedIndex, e) => {
      this.setState({
        index: selectedIndex,
        direction: e.direction
      });
    };

    return (
        <Carousel
          controls={false}
          // bsPrefix="stringCarousel"
          activeIndex={index}
          direction={direction}
          onSelect={handleSelect}
          className={clsx(
            styles.slider,
            theme ? styles[theme] : undefined,
            alternative ? styles.alternative : undefined
          )}
          style={style}
          interval={5000}
        >
          {data.length && data.map((item, index) => {
            return(
              <Carousel.Item key={index}>
                {multipleItemsPerSlide ? (
                <div className={styles.multipleItemsContainer}>
                  {item}
                </div>
                ) : item}
              </Carousel.Item>
            );
          })}
        </Carousel>
    );
  }
}
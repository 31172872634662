import React from "react";
import {Grid, Typography} from "@material-ui/core";
import {fetchOfficeLeasingTeams, updateOfficeLeasingTeam} from "app/crud/estates/offices/offices.crud";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import LoadingScreen from "../../../LoadingScreen";
import styles from "../../../Offices/OfficeAdminSettings/index.module.scss";
import SelectSearch from "../../../SelectSearch";
import Button from "../../../Button";

class EditOfficeContacts extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      leasingTeam: props.initLeasingTeam || null,
    };

    const {fetchOfficeLeasingTeams, officeId} = this.props;
    fetchOfficeLeasingTeams(officeId);
  }

  // Handlers
  handleSaveLeasing = () => {
    const {officeId, updateOfficeLeasingTeam} = this.props;
    const {leasingTeam} = this.state;
    if(leasingTeam && typeof leasingTeam !== "object") {
      const data = {
        newTeam: leasingTeam
      };
      updateOfficeLeasingTeam(officeId, data);
    }
  };
  handleAutocompleteChange = (target) => {
    this.setState({
      [target.name]: target.value,
    });
  };

  render() {
    const {leasingTeams, leasingTeamsLoading} = this.props;
    const {leasingTeam} = this.state;
    const baseUrl = `${process.env.REACT_APP_API_HOST}admin`;

    return(
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" style={{color: "#ff4626"}}><FormattedMessage id="VOCABULARY.Lease and contact"/></Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h4"><FormattedMessage id="BUILDING.PHRASE.CHANGE_LEASING_TEAM"/></Typography>
          <Typography variant="body2" className={styles.remember}><FormattedMessage id="BUILDING.PHRASE.CHANGE_LEASING_TEAM_DESC"/></Typography>
          {leasingTeamsLoading ? <LoadingScreen/> :
            <Grid container spacing={2} justifyContent="space-between" alignItems="flex-end">
              <Grid item xs={10}>
                <SelectSearch
                  name="leasingTeam"
                  value={leasingTeam}
                  label={<FormattedMessage id="BUILDING.PHRASE.LEASING_TEAMS"/>}
                  options={leasingTeams}
                  optionLabel="name"
                  optionValue="id"
                  onChange={this.handleAutocompleteChange}
              />
              </Grid>
              <Grid item xs={2}>
                <Button onClick={this.handleSaveLeasing}>
                  <FormattedMessage id="GENERAL.PHRASE.SAVE"/>
                </Button>
              </Grid>
            </Grid>
          }
        </Grid>
        <Grid item xs={12}/>
        <Grid item xs={6}>
          <Typography variant="h4"><FormattedMessage id="BUILDING.PHRASE.CHANGE_CONTACT_PERSONS"/></Typography>
          <Typography variant="h4" style={{marginTop: "2rem", color: "#ff4626", fontStyle: "italic"}}>Osoby kontaktowe należy wybrać z poziomu Django.</Typography>
          <Typography variant="body1" style={{marginTop: "2rem", fontStyle: "italic"}}>Pomocne linki:</Typography>
          <Typography variant="h4" style={{marginTop: "1rem"}}>
            <a
              href={`${baseUrl}/contacts/contactperson`}
              target="_blank"
              rel="noreferrer noopener"
              style={{fontSize: "inherit"}}
               className={styles.remember}
            >1. Dodaj nowy kontakt</a>
          </Typography>
          <Typography variant="h4">
            <a
              href={`${baseUrl}/contacts/contactrelation `}
              target="_blank"
              rel="noreferrer noopener"
              style={{fontSize: "inherit"}}
              className={styles.remember}
            >2. Przypisz istniejący kontakt do nieruchomości</a>
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  leasingTeams: state.offices.officeLeasingTeams,
  leasingTeamsLoading: state.offices.officeLeasingTeamsLoading,
});
const mapDispatchToProps = {
    fetchOfficeLeasingTeams: (officeId) => fetchOfficeLeasingTeams(officeId),
    updateOfficeLeasingTeam: (officeId, data) => updateOfficeLeasingTeam(officeId, data),
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditOfficeContacts)
);
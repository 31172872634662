import React from 'react';
import styles from "../index.module.scss";
import GenerateOfferPdf from "../../OffersPage/pdf/GenerateOfferPdf";
import OfferShare from "../../OffersPage/components/OfferShare";

export default function OfferOptions({offerID, offerHash, offerName, ...props}) {
  const inactive = props?.data?.records?.length <= 0;

  return(
    <div className={styles.offerOptionsWrapper}>
      <GenerateOfferPdf
        offerID={offerID}
        inactive={inactive}
      />
      <OfferShare
        offerHash={offerHash}
        offerName={offerName}
        inactive={inactive}
      />
    </div>
  );
}

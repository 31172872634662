import React from "react";
import {Grid, Typography} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import {setTeamMemberForActiveSubscription,} from "../../../../../crud/team.crud";
import Button from "../../../Button";

class EditTeamMemberActiveSubscription extends React.Component {
  handleSave = () => {
    const {setTeamMemberForActiveSubscription, onActiveSubsChanged, data} = this.props;
    const memberId = data?.id;
    if(memberId) {
      setTeamMemberForActiveSubscription({memberId});
      onActiveSubsChanged();
    }
  };

  render() {
    const {updatingTeamMember, data, team} = this.props;
    const activeSubs = team?.activeSubs || 999;
    const isActiveSubscriber = data?.isActiveSubscriber;
    const isActivateSubscriptionLimitReached = team?.maxActiveSubs !== 0 && activeSubs >= team?.maxActiveSubs;

    return(
      <Grid container spacing={2} justifyContent="center" alignItems="center" style={{height: "100%"}}>
        {isActiveSubscriber ?
        <Grid item style={{textAlign: "center"}}>
          <Typography variant="h3">
            <FormattedMessage id="TEAM.PHRASE.Selected team member has an active subscription."/>
          </Typography>
        </Grid> :
        isActivateSubscriptionLimitReached ?
        <Grid item style={{textAlign: "center"}}>
          <Typography variant="h3">
            <FormattedMessage id="TEAM.PHRASE.Your team reached active subscriptions limit."/>
          </Typography>
        </Grid> :
        <Grid item style={{textAlign: "center"}}>
          <Typography variant="h3" style={{marginBottom: "2rem"}}>
            <FormattedMessage id="TEAM.PHRASE.SET_ACTIVE_SUBSCRIPTION_MESSAGE"/><br/>
            <FormattedMessage id="GENERAL.PHRASE.ACTION_CONFIRMATION_MESSAGE"/>
          </Typography>
          <Button
            loading={updatingTeamMember}
            onClick={() => this.handleSave()}
          >
            <FormattedMessage id="TEAM.PHRASE.ACTIVATE_SUBSCRIPTION"/>
          </Button>
        </Grid>
        }
      </Grid>
    );
  }
}

const mapStateToProps = store => ({
  updatingTeamMember: store.team.updatingTeamMember,
  team: store.team
});
const mapDispatchToProps = {
  setTeamMemberForActiveSubscription: memberId => setTeamMemberForActiveSubscription(memberId)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditTeamMemberActiveSubscription)
);
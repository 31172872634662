import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {put, takeLatest} from "redux-saga/effects";
import axios from "axios";
import {resetFilters, setFilters} from "app/utils/filters";

const initialState = {
  items: {},
  loading: false,
  loaded: false,
  itemsCount: 0,
  itemsPerPage: 20
};

export const reducer = persistReducer(
  { storage, key: "officesPremium" },
  (state = initialState, action) => {
    switch (action.type) {
      // RESET OFFICE ANALYTICS LOADING STATES
      case 'RESET_OFFICE_ANALYTICS_LOADING_STATES_REQUEST': {
        return {
          ...state,
          loading: false
        };
      }
      //
      case 'FETCH_OFFICE_PREMIUM_LIST_LATEST_RELEASES_REQUEST': {
        return { ...state, loading: true }
      }
      case 'FETCH_OFFICE_PREMIUM_LIST_LATEST_RELEASES_RESET_REQUEST': {
        return { ...state, loading: true };
      }
      case 'FETCH_OFFICE_PREMIUM_LIST_LATEST_RENT_CHANGED_REQUEST': {
        return { ...state, loading: true }
      }
      case 'FETCH_OFFICE_PREMIUM_LIST_LATEST_RENT_CHANGED_RESET_REQUEST': {
        return { ...state, loading: true };
      }
      case 'FETCH_OFFICE_PREMIUM_LIST_LATEST_RENTED_REQUEST': {
        return { ...state, loading: true }
      }
      case 'FETCH_OFFICE_PREMIUM_LIST_LATEST_RENTED_RESET_REQUEST': {
        return { ...state, loading: true };
      }
      case 'FETCH_OFFICE_PREMIUM_LIST_LATEST_SOLD_OFFICES_REQUEST': {
        return { ...state, loading: true }
      }
      case 'FETCH_OFFICE_PREMIUM_LIST_LATEST_SOLD_OFFICES_RESET_REQUEST': {
        return { ...state, loading: true };
      }

      case 'FETCH_OFFICE_PREMIUM_LIST_SUCCESS': {
        const { items, itemsCount, itemsPerPage } = action.payload;
        return { ...state, items, itemsCount, itemsPerPage, loaded: true, loading: false }
      }

      default:
        return state;
    }
  }
);

// FETCH DATA
// Latest Releases
function* fetchOfficePremiumListLatestReleases(action) {
  const params = action.payload.params;
  const filters = setFilters(params);
  try {
    const latestReleases = yield axios.get(`/market-insights/latest_releases/${filters}`);
    yield put({ type: "FETCH_OFFICE_PREMIUM_LIST_SUCCESS", payload: latestReleases.data || [{ error: latestReleases.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchOfficePremiumListLatestReleasesReset() {
  const filtersReset = resetFilters();
  try {
    const premium = yield axios.get(`/market-insights/latest_releases/${filtersReset}`);
    yield put({ type: "FETCH_OFFICE_PREMIUM_LIST_SUCCESS", payload: premium.data || [{ error: premium.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
// Latest Rent Changed
function* fetchOfficePremiumListLatestRentChanged(action) {
  const params = action.payload.params;
  const filters = setFilters(params, 'recent_rent_change');
  try {
    const premium = yield axios.get(`/market-insights/latest_rent_changed/${filters}`);
    yield put({ type: "FETCH_OFFICE_PREMIUM_LIST_SUCCESS", payload: premium.data || [{ error: premium.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchOfficePremiumListLatestRentChangedReset() {
  const filtersReset = resetFilters('recent_rent_change');
  try {
    const premium = yield axios.get(`/market-insights/latest_rent_changed/${filtersReset}`);
    yield put({ type: "FETCH_OFFICE_PREMIUM_LIST_SUCCESS", payload: premium.data || [{ error: premium.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
// Latest Rented
function* fetchOfficePremiumListLatestRented(action) {
  const params = action.payload.params;
  const filters = setFilters(params);
  try {
    const premium = yield axios.get(`/market-insights/latest_rented/${filters}`);
    yield put({ type: "FETCH_OFFICE_PREMIUM_LIST_SUCCESS", payload: premium.data || [{ error: premium.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchOfficePremiumListLatestRentedReset() {
  const filtersReset = resetFilters();
  try {
    const premium = yield axios.get(`/market-insights/latest_rented/${filtersReset}`);
    yield put({ type: "FETCH_OFFICE_PREMIUM_LIST_SUCCESS", payload: premium.data || [{ error: premium.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
// Latest Sold Offices
function* fetchOfficePremiumListLatestSoldOffices(action) {
  const params = action.payload.params;
  const filters = setFilters(params, 'last_transaction_date');
  try {
    const premium = yield axios.get(`/market-insights/latest_sold_offices/${filters}`);
    yield put({ type: "FETCH_OFFICE_PREMIUM_LIST_SUCCESS", payload: premium.data || [{ error: premium.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}
function* fetchOfficePremiumListLatestSoldOfficesReset() {
  const filtersReset = resetFilters('last_transaction_date');
  try {
    const premium = yield axios.get(`/market-insights/latest_sold_offices/${filtersReset}`);
    yield put({ type: "FETCH_OFFICE_PREMIUM_LIST_SUCCESS", payload: premium.data || [{ error: premium.statusText }] });
  }
  catch(err) {
    console.log(err);
  }
}

export function* saga() {
  yield takeLatest('FETCH_OFFICE_PREMIUM_LIST_LATEST_RELEASES_REQUEST', fetchOfficePremiumListLatestReleases);
  yield takeLatest('FETCH_OFFICE_PREMIUM_LIST_LATEST_RELEASES_RESET_REQUEST', fetchOfficePremiumListLatestReleasesReset);
  yield takeLatest('FETCH_OFFICE_PREMIUM_LIST_LATEST_RENT_CHANGED_REQUEST', fetchOfficePremiumListLatestRentChanged);
  yield takeLatest('FETCH_OFFICE_PREMIUM_LIST_LATEST_RENT_CHANGED_RESET_REQUEST', fetchOfficePremiumListLatestRentChangedReset);
  yield takeLatest('FETCH_OFFICE_PREMIUM_LIST_LATEST_RENTED_REQUEST', fetchOfficePremiumListLatestRented);
  yield takeLatest('FETCH_OFFICE_PREMIUM_LIST_LATEST_RENTED_RESET_REQUEST', fetchOfficePremiumListLatestRentedReset);
  yield takeLatest('FETCH_OFFICE_PREMIUM_LIST_LATEST_SOLD_OFFICES_REQUEST', fetchOfficePremiumListLatestSoldOffices);
  yield takeLatest('FETCH_OFFICE_PREMIUM_LIST_LATEST_SOLD_OFFICES_RESET_REQUEST', fetchOfficePremiumListLatestSoldOfficesReset);
}

import React from "react";
import {FormattedMessage} from "react-intl";

const editNavigation = {
  OFFICE: [
    {
      label: <FormattedMessage id="VOCABULARY.Basic data"/>,
      id: "basic",
      editableRequired: true
    },
    {
      label: <FormattedMessage id="VOCABULARY.Lease terms"/>,
      id: "leaseTerms",
      editableRequired: true
    },
    {
      label: <FormattedMessage id="VOCABULARY.Location"/>,
      id: "location",
      editableRequired: true
    },
    {
      label: <FormattedMessage id="VOCABULARY.Photos"/>,
      id: "images",
      editableRequired: true
    },
    {
      label: <FormattedMessage id="VOCABULARY.Commercialization"/>,
      id: "commercialization",
      editableRequired: true
    },
    {
      label: <FormattedMessage id="VOCABULARY.Amenities"/>,
      id: "amenities",
      adminOnly: true
    },
    {
      label: <FormattedMessage id="VOCABULARY.Owner"/>,
      id: "owner",
      adminOnly: true
    },
    {
      label: <FormattedMessage id="VOCABULARY.Lease and contact"/>,
      id: "contacts",
      adminOnly: true
    },
    {
      label: <FormattedMessage id="VOCABULARY.Manual refresh"/>,
      id: "refresh",
      adminOnly: true
    },
    {
      label: <FormattedMessage id="VOCABULARY.Delete building"/>,
      id: "deleteProperty",
      adminOnly: true
    }
  ],
  UNIT: [
    {
      label: <FormattedMessage id="VOCABULARY.Basic data"/>,
      id: "basic",
      editableRequired: true
    },
    {
      label: <FormattedMessage id="VOCABULARY.Flexible units"/>,
      id: "flexibleUnits"
    },
    {
      label: <FormattedMessage id="VOCABULARY.Note"/>,
      id: "note"
    },
    {
      label: <FormattedMessage id="VOCABULARY.Plan"/>,
      id: "plan"
    },
    {
      label: <FormattedMessage id="VOCABULARY.Sublease terms"/>,
      id: "subleaseTerms"
    },
    {
      label: <FormattedMessage id="VOCABULARY.Contact"/>,
      id: "contact"
    },
    {
      label: <FormattedMessage id="LM.PHRASE.ADD_TO_LEAD"/>,
      id: "leadsManagement",
      adminOnly: true
    },
    {
      label: <FormattedMessage id="VOCABULARY.Delete unit"/>,
      id: "deleteUnit",
      adminOnly: true
    }
  ],
  PARK: [
    {
      label: <FormattedMessage id="VOCABULARY.Basic data"/>,
      id: "basic",
      editableRequired: true
    },
    {
      label: <FormattedMessage id="VOCABULARY.Lease terms"/>,
      id: "leaseTerms",
      editableRequired: true
    },
    {
      label: <FormattedMessage id="VOCABULARY.Location"/>,
      id: "location",
      editableRequired: true
    },
    {
      label: <FormattedMessage id="VOCABULARY.Photos"/>,
      id: "images",
      editableRequired: true
    },
    {
      label: <FormattedMessage id="VOCABULARY.Manual refresh"/>,
      id: "refresh",
      adminOnly: true
    },
    {
      label: <FormattedMessage id="VOCABULARY.Delete park"/>,
      id: "deleteProperty",
      adminOnly: true
    }
  ],
  WAREHOUSE: [
    {
      label: <FormattedMessage id="VOCABULARY.Basic data"/>,
      id: "basic",
      editableRequired: true
    },
    {
      label: <FormattedMessage id="VOCABULARY.Lease terms"/>,
      id: "leaseTerms",
      editableRequired: true
    },
    {
      label: <FormattedMessage id="VOCABULARY.Technical spec"/>,
      id: "technicalDetails",
      editableRequired: true
    },
    {
      label: <FormattedMessage id="VOCABULARY.Location"/>,
      id: "location",
      editableRequired: true
    },
    {
      label: <FormattedMessage id="VOCABULARY.Photos"/>,
      id: "images",
      editableRequired: true
    },
    {
      label: <FormattedMessage id="VOCABULARY.Amenities"/>,
      id: "amenities",
      editableRequired: true
    },
    {
      label: <FormattedMessage id="VOCABULARY.Owner"/>,
      id: "owner",
      editableRequired: true
    },
    {
      label: <FormattedMessage id="VOCABULARY.Lease and contact"/>,
      id: "contacts",
      adminOnly: true
    },
    {
      label: <FormattedMessage id="VOCABULARY.Manual refresh"/>,
      id: "refresh",
      adminOnly: true
    }
  ],
  WAREHOUSE_UNIT: [
    {
      label: <FormattedMessage id="VOCABULARY.Basic data"/>,
      id: "basic",
      editableRequired: true
    },
    {
      label: <FormattedMessage id="VOCABULARY.Flexible units"/>,
      id: "flexibleUnits"
    },
    {
      label: <FormattedMessage id="VOCABULARY.Note"/>,
      id: "note"
    },
    {
      label: <FormattedMessage id="VOCABULARY.Plan"/>,
      id: "plan"
    },
    {
      label: <FormattedMessage id="VOCABULARY.Delete unit"/>,
      id: "deleteUnit",
      adminOnly: true
    }
  ],
  TEAM_MEMBER: [
    {
      label: <FormattedMessage id="VOCABULARY.Basic data"/>,
      id: "basic",
      adminOnly: true
    },
    {
      label: <FormattedMessage id="TEAM.PHRASE.Team owner"/>,
      id: "teamOwner",
      editableRequired: true
    },
    {
      label: <FormattedMessage id="TEAM.PHRASE.Subscription"/>,
      id: "subscription",
      editableRequired: true,
      proRequired: true
    },
    {
      label: <FormattedMessage id="TEAM.PHRASE.Remove member"/>,
      id: "removeMember",
      editableRequired: true
    }
  ],
};

export default editNavigation;
import React from "react";
import {Grid, TableCell, Typography} from "@material-ui/core";
import LeadsManagementStatus from "../../../../pages/common/LeadsManagementPage/components/LeadsManagementStatus";
import LeadsManagementInterview from "../../../../pages/common/LeadsManagementPage/components/LeadsManagementInterview";
import LeadsManagementProperties
  from "../../../../pages/common/LeadsManagementPage/components/LeadsManagementProperties";
import LeadsManagementSend from "../../../../pages/common/LeadsManagementPage/components/LeadsManagementSend";
import LeadsManagementDetails from "../../../../pages/common/LeadsManagementPage/components/LeadsManagementDetails";
import {handleEmptyValue, theExcerpt, toDateFormat} from "../../../../utils/helpers";
import TooltipCustom from "../../TooltipCustom";
import {FormattedMessage} from "react-intl";

export default class TableLeadsManagementCells extends React.Component {
  render() {
    const {row} = this.props;
    const areaRange = [
      ...row?.crmAreaFrom ? [row?.crmAreaFrom] : [],
      ...row?.crmAreaTo ? [row?.crmAreaTo] : []
    ].join("-");
    const location = [
      ...row?.market ? [row?.market] : [],
      ...row?.region ? [row?.region] : []
    ].join(", ");
    const isAvailableUnitsRequest = row?.crmAvailableUnitsRequest;
    const availableUnitsRequestStyles = {
      backgroundColor: "rgba(255, 145, 34, 0.1)"
    }

    return(
      <>
        <TableCell
          component="th"
          scope="row"
          style={{
            ...isAvailableUnitsRequest ? availableUnitsRequestStyles : undefined
          }}
        >
          <LeadsManagementStatus leadID={row?.id} status={row?.offerStatus}/>
        </TableCell>
        <TableCell
          style={{
            ...isAvailableUnitsRequest ? availableUnitsRequestStyles : undefined
          }}
        >
          <Typography variant="body1">{handleEmptyValue(row?.crmEmail)}</Typography>
        </TableCell>
        <TableCell
          style={{
            ...isAvailableUnitsRequest ? availableUnitsRequestStyles : undefined
          }}
        >
          {isAvailableUnitsRequest ?
          <TooltipCustom title={<FormattedMessage id="Contact form: Available units"/>}>
            <Typography variant="body1">{handleEmptyValue(row?.crmLeadType)}</Typography>
          </TooltipCustom> :
          <Typography variant="body1">{handleEmptyValue(row?.crmLeadType)}</Typography>
          }
        </TableCell>
        <TableCell
          style={{
            maxWidth: "25%",
            ...isAvailableUnitsRequest ? availableUnitsRequestStyles : undefined
          }}
        >
          <Typography variant="body1">{theExcerpt(row?.leadName, 25, '-', true)}</Typography>
        </TableCell>
        <TableCell
          style={{
            ...isAvailableUnitsRequest ? availableUnitsRequestStyles : undefined
          }}
        >
          <Typography variant="body1">{location}</Typography>
        </TableCell>
        <TableCell
          align="right"
          style={{
            ...isAvailableUnitsRequest ? availableUnitsRequestStyles : undefined
          }}
        >
          <Typography variant="body1">{handleEmptyValue(areaRange)}</Typography>
        </TableCell>
        <TableCell
          align="right"
          style={{
            ...isAvailableUnitsRequest ? availableUnitsRequestStyles : undefined
          }}
        >
          <Typography variant="body1">{toDateFormat(row?.createdAt, true)}</Typography>
        </TableCell>
        <TableCell
          align="right"
          style={{
            ...isAvailableUnitsRequest ? availableUnitsRequestStyles : undefined
          }}
        >
          <Grid container spacing={1} justifyContent="flex-end" wrap="nowrap">
            <Grid item>
              <LeadsManagementDetails leadID={row?.id}/>
            </Grid>
            <Grid item>
              <LeadsManagementInterview leadID={row?.id}/>
            </Grid>
            <Grid item>
              <LeadsManagementProperties leadID={row?.id}/>
            </Grid>
            <Grid item>
              <LeadsManagementSend leadID={row?.id}/>
            </Grid>
          </Grid>
        </TableCell>
      </>
    );
  }
}
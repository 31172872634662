import React from "react";
import {Grid, Icon, Tooltip, Typography, withStyles} from "@material-ui/core";
import styles from "./index.module.scss";

export default class FinancesTooltip extends React.Component {
    render() {
        const {children, operation, var_1, var_2, var_3} = this.props;
        const HtmlTooltip = withStyles((theme) => ({
          tooltip: {
            maxWidth: 600,
          },
        }))(Tooltip);

        const renderTooltipContent = () => {
            const isOperationDivide = operation === "divide";
            return(
                <Grid container direction="row" justifyContent="center" alignItems="center" wrap="nowrap">
                    <Grid item>
                        <Typography style={{marginRight: 3}}>{var_1} = </Typography>
                    </Grid>
                    <Grid item>
                        <Grid container direction={isOperationDivide ? "column" : "row"} justifyContent={isOperationDivide ? "flex-start" : "center"} alignItems="center" wrap="nowrap">
                            <Grid item>
                                <Typography style={{whiteSpace: "nowrap"}}>{var_2}</Typography>
                            </Grid>
                            <Grid item className={isOperationDivide && styles.financesSeparatorContainer}>
                                {isOperationDivide ? <span className={styles.financesSeparator}/> : <span className={styles.financesSeparatorPlus}>+</span>}
                            </Grid>
                            <Grid item>
                                <Typography>{var_3}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            );
        }
        return(
            <HtmlTooltip placement="right" title={renderTooltipContent()}>
                <span style={{display: 'inline-block'}}>
                    {children}
                    <Icon fontSize="small" color="error" style={{position: "relative", top: 5}}>help_outline</Icon>
                </span>
            </HtmlTooltip>
        );
    }
}
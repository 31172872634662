import React from "react";
import {Checkbox, FormControlLabel, Grid, Typography} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import InputAdornments from "app/partials/components/InputAdornments";
import SingleSelect from "app/partials/components/SingleSelect";
import {FILTERS_SELECTS} from "app/partials/components/FiltersDrawer/constants";
import {onlyNumbers} from "app/utils/helpers";
import _ from "lodash";
import {updateIndustrialWarehouse} from "../../../../../crud/estates/industrial/industrial.crud";
import format from "date-fns/format";

class EditWarehouseBasics extends React.Component {
  constructor(props) {
    super(props);
    const {
      name,
      status,
      buildingClass,
      buildingType,
      dgnbCertification,
      hqeCertification,
      wellCertification,
      breeamCertification,
      leedCertification,
      commissioningYear,
      commissioningQuarter,
      buildingAvailableFrom,
      availableFromAdditionalTime,
      totalWarehouseAreaInBuilding,
      totalOfficeAreaInBuilding,
      contactPersonName,
      contactPersonPhone
    } = props.data;
    this.state = {
      name: name || '',
      status,
      buildingClass: buildingClass || '',
      buildingType,
      dgnbCertification,
      hqeCertification,
      wellCertification,
      breeamCertification,
      leedCertification,
      commissioningYear,
      commissioningQuarter,
      buildingAvailableFrom: buildingAvailableFrom ? format(new Date(buildingAvailableFrom), "yyyy-MM-dd") : "",
      availableFromAdditionalTime,
      totalWarehouseAreaInBuilding,
      totalOfficeAreaInBuilding,
      contactPersonName,
      contactPersonPhone
    };
    this.initialState = this.state;
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    // Handle Change Detect
    this.props.onUnsavedData(!_.isEqual(this.initialState, this.state));
    // Handle Save
    if(!prevProps.requestSave && this.props.requestSave) {
      this.handleSave();
    }
    // Detect Office Updating
    if(prevProps.warehouseUpdateLoading !== this.props.warehouseUpdateLoading) {
      this.props.onRequestSaving(this.props.warehouseUpdateLoading);
    }
  }

  // Handlers
  handleChange = (event) => {
    const onlyNums = onlyNumbers(event.target.value);
    const allowOnlyNumbers = event.target.name === "area" || event.target.name === "floor";
    this.setState({
      [event.target.name]: allowOnlyNumbers ? onlyNums : event.target.value
    });
  };
  handleChangeSelect = (selected, target) => {
    this.setState({
      [target]: selected
    });
  };
  handleChangeDate = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  handleCheckboxChange = e => {
    this.setState({
      [e.target.id]: !this.state[e.target.id]
    });
  };
  handleSave = () => {
    const {data: {id}, updateIndustrialWarehouse} = this.props;
    const {
      name,
      status,
      buildingClass,
      buildingType,
      dgnbCertification,
      hqeCertification,
      wellCertification,
      breeamCertification,
      leedCertification,
      commissioningYear,
      commissioningQuarter,
      buildingAvailableFrom,
      availableFromAdditionalTime,
      totalWarehouseAreaInBuilding,
      totalOfficeAreaInBuilding,
      contactPersonName,
      contactPersonPhone
    } = this.state;
    this.initialState = this.state;
    const data = {
      name,
      status: status === "" ? null : status,
      buildingClass,
      buildingType: buildingType || null,
      dgnbCertification,
      hqeCertification,
      wellCertification,
      breeamCertification,
      leedCertification,
      commissioningYear: commissioningYear || null,
      commissioningQuarter: _.isNumber(commissioningQuarter) ? Number(commissioningQuarter) : null,
      buildingAvailableFrom: buildingAvailableFrom ? format(new Date(buildingAvailableFrom), "yyyy-MM-dd") : null,
      availableFromAdditionalTime: availableFromAdditionalTime !== '' ? availableFromAdditionalTime : null,
      totalWarehouseAreaInBuilding: totalWarehouseAreaInBuilding ? Number(totalWarehouseAreaInBuilding) : 0,
      totalOfficeAreaInBuilding: totalOfficeAreaInBuilding ? Number(totalOfficeAreaInBuilding) : 0,
      contactPersonName,
      contactPersonPhone
    };
    updateIndustrialWarehouse(id, data, "basic");
  };

  render() {
    const {warehouseUpdateLoading} = this.props;
    const {
      name,
      status,
      buildingClass,
      buildingType,
      commissioningYear,
      commissioningQuarter,
      buildingAvailableFrom,
      availableFromAdditionalTime,
      totalWarehouseAreaInBuilding,
      totalOfficeAreaInBuilding,
      dgnbCertification,
      hqeCertification,
      wellCertification,
      breeamCertification,
      leedCertification,
      contactPersonName,
      contactPersonPhone
    } = this.state;

    return(
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" style={{color: "#ff4626"}}><FormattedMessage id="VOCABULARY.Basic data"/></Typography>
        </Grid>
        <Grid item xs={12}>
          <InputAdornments
              label={<FormattedMessage id="INDUSTRIAL.PHRASE.BUILDING_NAME"/>}
              name="name"
              value={name}
              onChange={this.handleChange}
              disabled={warehouseUpdateLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <InputAdornments
              label={<FormattedMessage id="INDUSTRIAL.PHRASE.INDUSTRIAL_TOTAL_AREA"/>}
              name="totalWarehouseAreaInBuilding"
              value={totalWarehouseAreaInBuilding}
              onChange={this.handleChange}
              disabled={warehouseUpdateLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <InputAdornments
              label={<FormattedMessage id="INDUSTRIAL.PHRASE.OFFICE_TOTAL_AREA"/>}
              name="totalOfficeAreaInBuilding"
              value={totalOfficeAreaInBuilding}
              onChange={this.handleChange}
              disabled={warehouseUpdateLoading}
          />
        </Grid>
        <Grid item xs={4}>
          <SingleSelect
              label={<FormattedMessage id="INDUSTRIAL.PHRASE.BUILDING_STATUS"/>}
              inputName="status"
              options={FILTERS_SELECTS.BUILDINGS_STATUSES}
              optionsValue="id"
              optionsLabel="label"
              defaultSelect={status}
              onSelectedValues={this.handleChangeSelect}
              disabled={warehouseUpdateLoading}
          />
        </Grid>
        <Grid item xs={4}>
          <SingleSelect
              label={<FormattedMessage id="INDUSTRIAL.PHRASE.BUILDING_TYPE"/>}
              inputName="buildingType"
              options={FILTERS_SELECTS.INDUSTRIAL_WAREHOUSE_TYPE}
              optionsValue="id"
              optionsLabel="label"
              defaultSelect={buildingType}
              onSelectedValues={this.handleChangeSelect}
              disabled={warehouseUpdateLoading}
          />
        </Grid>
        <Grid item xs={4}>
          <SingleSelect
              label={<FormattedMessage id="INDUSTRIAL.PHRASE.BUILDING_CLASS"/>}
              inputName="buildingClass"
              options={FILTERS_SELECTS.BUILDINGS_CLASSES}
              defaultSelect={buildingClass}
              onSelectedValues={this.handleChangeSelect}
              disabled={warehouseUpdateLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <InputAdornments
              type="date"
              label={<FormattedMessage id="INDUSTRIAL.PHRASE.BUILDING_AVAILABILITY_DATE"/>}
              name="buildingAvailableFrom"
              value={buildingAvailableFrom}
              onChange={this.handleChangeDate}
              disabled={warehouseUpdateLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <InputAdornments
              label={<FormattedMessage id="BUILDING.PHRASE.AVAILABLE_FROM_ADDITIONAL_TIME"/>}
              name="availableFromAdditionalTime" value={availableFromAdditionalTime}
              onChange={this.handleChange}
              disabled={warehouseUpdateLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <InputAdornments
              label={<FormattedMessage id="INDUSTRIAL.PHRASE.BUILDING_COMMISSIONING_YEAR"/>}
              name="commissioningYear"
              value={commissioningYear}
              onChange={this.handleChange}
              disabled={warehouseUpdateLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <SingleSelect
              label={<FormattedMessage id="INDUSTRIAL.PHRASE.BUILDING_COMMISSIONING_QUARTER"/>}
              inputName="commissioningQuarter"
              options={FILTERS_SELECTS.BUILDINGS_COMMISIONING_QUARTERS}
              optionsValue="id"
              optionsLabel="label"
              defaultSelect={commissioningQuarter}
              onSelectedValues={this.handleChangeSelect}
              disabled={warehouseUpdateLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography style={{marginTop: 15}}><FormattedMessage id="BUILDING.PHRASE.CERTIFICATE"/></Typography>
          <FormControlLabel
            control={
              <Checkbox checked={dgnbCertification} id="dgnbCertification" onChange={this.handleCheckboxChange} value="dgnbCertification" color="primary"/>
            }
            label={FILTERS_SELECTS.BUILDINGS_CERTIFICATES_LANDLORD[0]["label"]}
            disabled={warehouseUpdateLoading}
          />
          <FormControlLabel
            control={
              <Checkbox checked={hqeCertification} id="hqeCertification" onChange={this.handleCheckboxChange} value="hqeCertification" color="primary"/>
            }
            label={FILTERS_SELECTS.BUILDINGS_CERTIFICATES_LANDLORD[1]["label"]}
            disabled={warehouseUpdateLoading}
          />
          <FormControlLabel
            control={
              <Checkbox checked={wellCertification} id="wellCertification" onChange={this.handleCheckboxChange} value="wellCertification" color="primary"/>
            }
            label={FILTERS_SELECTS.BUILDINGS_CERTIFICATES_LANDLORD[2]["label"]}
            disabled={warehouseUpdateLoading}
          />
          <FormControlLabel
            control={
              <Checkbox checked={breeamCertification} id="breeamCertification" onChange={this.handleCheckboxChange} value="breeamCertification" color="primary"/>
            }
            label={FILTERS_SELECTS.BUILDINGS_CERTIFICATES_LANDLORD[3]["label"]}
            disabled={warehouseUpdateLoading}
          />
          <FormControlLabel
            control={
              <Checkbox checked={leedCertification} id="leedCertification" onChange={this.handleCheckboxChange} value="leedCertification" color="primary"/>
            }
            label={FILTERS_SELECTS.BUILDINGS_CERTIFICATES_LANDLORD[4]["label"]}
            disabled={warehouseUpdateLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <InputAdornments
              label={<FormattedMessage id="INDUSTRIAL.PHRASE.CONTACT_MAILS"/>}
              name="contactPersonName"
              value={contactPersonName}
              onChange={this.handleChange}
              disabled={warehouseUpdateLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <InputAdornments
              label={<FormattedMessage id="INDUSTRIAL.PHRASE.CONTACT_PHONES"/>}
              name="contactPersonPhone"
              value={contactPersonPhone}
              onChange={this.handleChange}
              disabled={warehouseUpdateLoading}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = store => ({
  warehouseUpdateLoading: store.industrial.warehouseUpdateLoading
});
const mapDispatchToProps = {
  updateIndustrialWarehouse: (warehouseID, data, backgroundRefresh) => updateIndustrialWarehouse(warehouseID, data, backgroundRefresh)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditWarehouseBasics)
);
import React from "react";
import HighlightLabel from "app/partials/components/HighlightLabel";

export default class BuildingUnitsCountPreview extends React.Component {
  render() {
    const {units, label, disableHover, color = "success", tooltip} = this.props;
    return (
        <HighlightLabel color={color} hover={!disableHover} tooltip={tooltip}>
          {label && label}
          {label && ': '}
          {units}
        </HighlightLabel>
    );
  }
}
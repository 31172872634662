import React from "react";
import {FormattedMessage} from "react-intl";
import {TABLE_CELLS} from "../../../partials/components/TableProperties";
import ListView from "../../offices/components/ListView/ListView";
import IsAdmin from "../../../utils/IsAdmin";

export default class LeadsManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      initLoaded: false
    };
  }

  // Handle fetch data on enter viewport
  handleOnViewportFetch = (isVisible) => {
    const {fetchLeads, leadsLoading} = this.props;
    const {initLoaded} = this.state;
    const params = {
      page: 1
    };
    if(isVisible && !leadsLoading && !initLoaded) {
      this.setState({
        initLoaded: true
      }, () => fetchLeads({params}));
    }
  };
  handleOnViewport = (isInViewport) => {
    this.handleOnViewportFetch(isInViewport);
  }

  render() {
    const {leads, leadsLoading, itemsPerPage, itemsCount, totalItems, fetchLeads} = this.props;
    const tHead = [
      {
        id: "lm_status",
        label: "LM.PHRASE.STATUS",
        numeric: false
      },
      {
        id: "lm_mail",
        label: "LM.PHRASE.EMAIL_ADDRESS",
        numeric: false
      },
      {
        id: "lm_lead_type",
        label: "LM.PHRASE.LEAD_TYPE",
        numeric: false
      },
      {
        id: "lm_lead_name",
        label: "LM.PHRASE.LEAD_NAME",
        numeric: false
      },
      {
        id: "lm_location",
        label: "LM.PHRASE.LOCATION",
        numeric: false
      },
      {
        id: "lm_area_range",
        label: "GENERAL.PHRASE.AREA",
        numeric: true
      },
      {
        id: "lm_created_at",
        label: "LM.PHRASE.CREATED_AT",
        numeric: true
      },
      {
        id: "lm_options",
        label: "",
        numeric: true
      }
    ];

    return(
      <IsAdmin markup="section">
        <ListView
          tableLabel={<FormattedMessage id="LM.PHRASE.LEADS_MANAGEMENT"/>}
          tableHeadRows={tHead}
          tableBodyRows={leads}
          tableDataSource="leadsManagement"
          tableDataTarget={TABLE_CELLS.LEADS_MANAGEMENT}
          itemsPerPage={itemsPerPage}
          itemsCount={itemsCount}
          totalItems={totalItems}
          loading={leadsLoading}
          handleFetchData={fetchLeads}
          onEnterViewport={this.handleOnViewport}
        />
      </IsAdmin>
    );
  }
}
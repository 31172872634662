import React, {useState} from "react";
import styles from "../index.module.scss";
import PaywallModal from "../components/PaywallModal";
import PaywallLogo from "../components/PaywallLogo";
import cn from "classnames";
import {ReactComponent as IconTime} from "app/media/icons/icon-time.svg";
import connect from "react-redux/es/connect/connect";
import _ from "lodash";
import {fetchBannerData, getBannerDataValue} from "../helpers";
import ReactVisibilitySensor from "react-visibility-sensor";
import {FormattedMessage} from "react-intl";
import LoadingScreen from "../../LoadingScreen";

const PaywallBannerRowTable = (
  {
    proAccessRequestSent,
    userAccountType,
    bannerVariant = "row_table-properties",
    modalVariant = "default"
  }
) => {
  const [bannerData, setBannerData] = useState(null);
  const [bannerDataLoading, setBannerDataLoading] = useState(false);

  // Handlers
  const handleOnViewportFetch = async (isVisible) => {
    if(isVisible && !bannerDataLoading && _.isEmpty(bannerData)) {
      setBannerDataLoading(true);
      const finalBannerVariant = proAccessRequestSent ? "row_table-contact_request_sent" : bannerVariant;
      const bannerData = await fetchBannerData(finalBannerVariant, userAccountType);
      if(bannerData) {
        setBannerData(bannerData);
      }
      setBannerDataLoading(false);
    }
  };

  return(
    <div className={cn(
      styles.paywallBannerTableRowWrapper,
      proAccessRequestSent ? styles.contactRequestedWrapper : undefined
    )}>
      <div className={styles.rowLeftWrapper}>
        <div className={styles.paywallHeader}>
          <ReactVisibilitySensor
            onChange={(isVisible) => handleOnViewportFetch(isVisible)}
            scrollCheck
            partialVisibility
          >
            <PaywallLogo/>
          </ReactVisibilitySensor>
        </div>
        {bannerDataLoading ? <LoadingScreen/> :
        <div className={styles.paywallBody}>
          {proAccessRequestSent && <span className={styles.icon}><IconTime/></span>}
          <div className={styles.contentWrapper}>
            {getBannerDataValue(bannerData, "title") && <p><strong>{getBannerDataValue(bannerData, "title")}</strong></p>}
            {getBannerDataValue(bannerData, "description") && <p>{getBannerDataValue(bannerData, "description")}</p>}
          </div>
        </div>
        }
      </div>
      <div className={styles.paywallFooter}>
        <div className={styles.paywallPrice}>
          {getBannerDataValue(bannerData, "price") &&
          <>
          <label><FormattedMessage id="VOCABULARY.from"/></label>
          <p>{getBannerDataValue(bannerData, "price")} PLN/<FormattedMessage id="mth"/></p>
          </>
          }
        </div>
        <div className={styles.paywallActions}>
          {!proAccessRequestSent &&
          <PaywallModal
            triggerLabel={getBannerDataValue(bannerData, "button")}
            bannerVariant={modalVariant}
            triggerBannerVariant={bannerVariant}
          />
          }
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = store => ({
  proAccessRequestSent: store?.user?.data?.proAccessRequestSent,
  userAccountType: store?.user?.data?.proData?.proGroup
});
export default connect(mapStateToProps)(PaywallBannerRowTable);
import React from "react";
import styles from "../index.module.scss";
import cn from "classnames";
import PaywallBannerDefault from "../banners/PaywallBannerDefault";
import PaywallDirectContact from "../components/PaywallDirectContact";
import ModalCustom from "../../ModalCustom";
import {FormattedMessage} from "react-intl";
import {callReddClickEvent} from "../../../../crud/user.crud";
import connect from "react-redux/es/connect/connect";
import REDD_TOWER_DUMMY_DATA from "../../../../pages/offices/external/PropertyPageDemo/REDD_TOWER_DUMMY_DATA";
import {ROUTES} from "../../../../constants";
import Button from "../../Button";
import Icon from "../../Icon";

const PaywallModal = (
  {
    triggerLabel,
    triggerBtn,
    triggerSlim,
    bannerVariant = "default",
    triggerBannerVariant,
    triggerButtonVariant,
    btnStyle,
    withIcon,
    user,
    callReddClickEvent,
    ...props
  }
) => {
  const handleOnOpen = (e) => {
    const isDummyViewActive = props?.isDummyViewActive;
    const eventParams = {
      click_page: window.location.href,
      trigger_banner_variant: triggerBannerVariant || bannerVariant,
      trigger_button: triggerButtonVariant || (typeof triggerLabel === "string" ? triggerLabel : null)
    };

    if(isDummyViewActive) {
      e.preventDefault();
      return window.open(ROUTES.REDD_TOWER_REDIRECT_WWW2, "_blank");
    }
    else if(user?.pk) {
      callReddClickEvent(
        "paywall-banner-initial-click",
        "user",
        null,
        user?.pk,
        {...eventParams},
        true
      );
    }
  }
  return(
    <ModalCustom
      btn={
      triggerBtn ? triggerBtn :
        <Button
          textIcon={<Icon icon={"lock"}/>}
          style={btnStyle}
        >
          {triggerLabel ? triggerLabel : <FormattedMessage id="VOCABULARY.Find out more"/>}
        </Button>
      }
      maxWidth="sm"
      btnCloseAlt
      inactive={props?.isDummyViewActive}
      dialogStyle={{
        zIndex: 1301,
      }}
      paperStyle={{
        backgroundColor: "transparent",
      }}
      onOpen={handleOnOpen}
    >
      <div className={cn(styles.paywallContainer, styles.paywallModalContainer)}>
        <div className={styles.paywallWrapper}>
          <PaywallBannerDefault bannerVariant={bannerVariant} isDummyViewActive={props?.isDummyViewActive}/>
          <PaywallDirectContact/>
        </div>
      </div>
    </ModalCustom>
  );
};

const mapStateToProps = (store, ownProps) => ({
  user: ownProps?.isDummyViewActive ? REDD_TOWER_DUMMY_DATA.user : store?.user?.data
});
const mapDispatchToProps = {
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params, notification) => callReddClickEvent(clickType, contentType, appLabel, objectId, params, notification)
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaywallModal);
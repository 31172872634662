import React from "react";
import clsx from "clsx";
import {Grid, Icon, Typography} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import InputSwitcher from "app/partials/components/InputSwitcher";
import TooltipCustom from "../TooltipCustom";

export default class SimpleDataPreviewItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMonthlyPayments: props.value === "years"
    }
  }
  handleChangeSwitcher = name => event => {
    const newPeriod = event.target.checked ? "y" : "m";
    this.setState({
      [name]: event.target.checked
    }, () => this.props.onSwitch(newPeriod));
  };

  render() {
    const {title, tooltip, style, valueStyle, solid, featured, small, switcherMode, value} = this.props;
    const {isMonthlyPayments} = this.state;
    return (
      <div className={clsx("kt-widget4__item", solid && "solid", featured && "featured", small && "small")}>
      {tooltip ? (
        <TooltipCustom placement="top" title={<FormattedMessage id={tooltip}/>}>
            <span style={{display: 'flex', alignItems: 'center'}}>
                <span className="kt-widget4__title">{title}</span>
                <Icon fontSize="small" color="error">help_outline</Icon>
            </span>
        </TooltipCustom>
        ) : <span className="kt-widget4__title" style={{color: "#74788d"}}>{title}</span>}
        <span
          className={clsx("kt-widget4__number", style === "success" ? "kt-font-success" : style === "danger" ? "kt-font-danger" : "kt-font-secondary")}
          style={valueStyle}
        >
          {switcherMode ? (
            <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
              <Grid item><Typography style={{textTransform: "uppercase"}}><FormattedMessage id="monthly"/></Typography></Grid>
              <Grid item>
                <InputSwitcher
                  checked={isMonthlyPayments}
                  value={isMonthlyPayments}
                  label={<Typography style={{textTransform: "uppercase"}}><FormattedMessage id="annually"/></Typography>}
                  onChange={this.handleChangeSwitcher("isMonthlyPayments")}
                  style={{marginRight: 0}}
                />
              </Grid>
            </Grid>
          ) : value}
        </span>
      </div>
    );
  }
}
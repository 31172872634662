import React from "react";
import {Portlet, PortletBody, PortletHeader} from "app/partials/content/Portlet";
import {Formik} from "formik";
import {TextField, Typography} from "@material-ui/core";
import clsx from "clsx";
import {FormattedMessage} from 'react-intl';

export default class UserChangePassword extends React.Component {
  handleSave = (values) => {
    const {onSave} = this.props;
    const data = {
      oldPassword: values.oldPassword,
      newPassword1: values.newPassword1,
      newPassword2: values.newPassword2
    };
    onSave(data);
  };
  render() {
    const {updateLoading} = this.props;
    return(
      <Portlet fluidHeight>
        <PortletHeader title={<Typography variant="h4"><FormattedMessage id="USER.PHRASE.CHANGE_PASSWORD_TOUPPER"/></Typography>}/>
        <PortletBody>
          <Formik
            initialValues={{
              oldPassword: '',
              newPassword1: '',
              newPassword2: ''
            }}
            onSubmit={(values) => this.handleSave(values)}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit
            }) => (
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                {status && (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                )}
                <div className="form-group">
                  <TextField
                    type="password"
                    margin="normal"
                    label={<FormattedMessage id="USER.PHRASE.CURRENT_PASSWORD"/>}
                    className="kt-width-full"
                    name="oldPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.oldPassword}
                  />
                  <TextField
                    type="password"
                    margin="normal"
                    label={<FormattedMessage id="USER.PHRASE.NEW_PASSWORD"/>}
                    className="kt-width-full"
                    name="newPassword1"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.newPassword1}
                  />
                  <TextField
                    type="password"
                    margin="normal"
                    label={<FormattedMessage id="USER.PHRASE.NEW_PASSWORD_CONFIRM"/>}
                    className="kt-width-full"
                    name="newPassword2"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.newPassword2}
                  />
                </div>

                <div className="kt-login__actions">
                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={updateLoading}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": updateLoading
                      }
                    )}`}
                  >
                    {<FormattedMessage id="USER.PHRASE.CHANGE_PASSWORD"/>}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </PortletBody>
      </Portlet>
    );
  }
}
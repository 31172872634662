import React from "react";
import styles from "./index.module.scss";
import ROUTES from "../../../../constants/ROUTES";
import {toAbsoluteUrl} from "../../../../../_metronic";

export default class PropertyParticularsFooter extends React.Component {
    render() {
        const {intl} = this.props;
        const isLangPL = intl && intl.locale === 'pl';
        const redirectPath = isLangPL ? 'platforma' : 'en/platform';

        return(
            <>
                <div className={styles.footerSection}>
                    <span><a href={`${ROUTES.REDD_LANDING_PAGE}/${redirectPath}`} target="_blank" rel="noreferrer noopener">Powered by <img src={toAbsoluteUrl("media/pp-mockup-media/logo-redd.png")} alt="redd property particulars"/></a></span>
                </div>
            </>
        );
    }
}
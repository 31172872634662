import {fetchSubscribers} from "app/crud/subscribers.crud";
import {injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import SubscribersPage from './SubscribersPage';
import {setLatestActiveTablePage} from "app/crud/tablePage.crud";

const mapStateToProps = (state) => ({
  subscribers: state.subscribers,
  loading: state.subscribers.loading,
  user: state.user.data
});
const mapDispatchToProps = {
  fetchSubscribers: ({params}) => fetchSubscribers(params),
  setLatestActiveTablePage: (page) => setLatestActiveTablePage(page)
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SubscribersPage)
);

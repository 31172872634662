import React from "react";
import {FormattedMessage} from "react-intl";
import styles from "app/pages/offices/OwnerPage/index.module.scss";
import {Grid} from "@material-ui/core";
import TableCustom from "app/partials/components/TableCustom";
import NoData from "app/partials/components/NoData/NoData";

export default class OwnerLeasingTeams extends React.Component {
  render() {
    const {ownerLeasingTeams} = this.props;
    const tHead = [
      {
        "label": "#",
        "number": false
      },
      {
        "label": <FormattedMessage id="DASHBOARD.PHRASE.BUILDINGS" values={{br: ""}}/>,
        "number": false
      },
      {
        "label": <FormattedMessage id="GENERAL.PHRASE.LEASING"/>,
        "number": false
      }
    ];
    let tBody = [];
    let tBodyIndex = 0;
    ownerLeasingTeams.map(team => {
      const { name, shortName, logo, buildings } = team;
      const teamName = shortName !== null ? shortName : name !== null ? name : 'N/A';
      const teamLogo = logo !== null ? <img src={logo} className={styles.logo} alt=""/> : '';
      const teamFullName = <Grid container spacing={1} wrap="wrap" alignItems="center"><Grid item>{teamLogo}</Grid><Grid item><span style={{whiteSpace: 'normal'}}>{teamName}</span></Grid></Grid>;
      buildings.map(office => {
        const row = {"data": [tBodyIndex + 1, <span style={{whiteSpace: 'normal'}}>{office}</span>, teamFullName]};
        tBody.push(row);
        tBodyIndex = tBodyIndex+1;
        return true;
      });
      return true;
    });
    if(ownerLeasingTeams.length) {
      return(
        <TableCustom tHead={tHead} tBody={tBody} stickyHeader maxHeight={450} theadStyleOrigin/>
      );
    }
    return <NoData/>;
  }
}
import React, {useState} from "react";
import styles from "../index.module.scss";
import {ReactComponent as IconCheck} from "app/media/icons/round-check.svg";
import PaywallContactRequested from "../components/PaywallContactRequested";
import PaywallLogo from "../components/PaywallLogo";
import connect from "react-redux/es/connect/connect";
import {updateUserData} from "../../../../crud/user.crud";
import ReactVisibilitySensor from "react-visibility-sensor";
import _ from "lodash";
import LoadingScreen from "../../LoadingScreen";
import {FormattedMessage} from "react-intl";
import {fetchBannerData, getBannerDataValue} from "../helpers";
import {callReddClickEvent} from "../../../../crud/user.crud";
import PaywallModal from "../components/PaywallModal";
import Button from "../../Button";

const PaywallBannerDefault = (
  {
    style,
    user,
    userAccountType,
    proAccessRequestSent,
    updateUserData,
    bannerVariant = "default",
    callReddClickEvent,
    ...props
  }
) => {
  const [bannerData, setBannerData] = useState(null);
  const [bannerDataLoading, setBannerDataLoading] = useState(false);
  const [contactRequested, setContactRequested] = useState(proAccessRequestSent);
  const CheckIcon = () => {
    return(
      <span className={styles.iconCheck}><IconCheck/></span>
    );
  }

  // Handlers
  const handleOnViewportFetch = async (isVisible) => {
    if(isVisible && !bannerDataLoading && _.isEmpty(bannerData)) {
      const variant = contactRequested ? "default-contact_request_sent" : bannerVariant;
      setBannerDataLoading(true);
      const bannerData = await fetchBannerData(variant, userAccountType);
      setBannerData(bannerData);
      setBannerDataLoading(false);
    }
  };
  const handleRequestContact = () => {
    const data = {
      proAccessRequestSent: true
    };
    const eventParams = {
      click_page: window.location.href,
      trigger_banner_variant: bannerVariant
    };
    if(user?.pk) {
      callReddClickEvent(
        "paywall-banner-contact-request-click",
        "user",
        null,
        user?.pk,
        {...eventParams},
        true
      );
    }
    updateUserData && updateUserData(data);
    setContactRequested(true);
  }

  if(contactRequested) {
    return(
      <ReactVisibilitySensor
        onChange={(isVisible) => handleOnViewportFetch(isVisible)}
        scrollCheck
        partialVisibility
      >
        <PaywallContactRequested
          data={bannerData}
        />
      </ReactVisibilitySensor>
    );
  }
  return(
    <div
      className={styles.paywallBannerDefaultWrapper}
      style={style}
    >
      <ReactVisibilitySensor
        onChange={(isVisible) => handleOnViewportFetch(isVisible)}
        scrollCheck
        partialVisibility
      >
        <PaywallLogo/>
      </ReactVisibilitySensor>
      {bannerDataLoading ? <LoadingScreen/> :
      <>
      <div className={styles.paywallBody}>
        {getBannerDataValue(bannerData, "title") && <h2>{getBannerDataValue(bannerData, "title")}</h2>}
        <ul>
          {getBannerDataValue(bannerData, "list_1") && <li><CheckIcon/>{getBannerDataValue(bannerData, "list_1")}</li>}
          {getBannerDataValue(bannerData, "list_2") && <li><CheckIcon/>{getBannerDataValue(bannerData, "list_2")}</li>}
          {getBannerDataValue(bannerData, "list_3") && <li><CheckIcon/>{getBannerDataValue(bannerData, "list_3")}</li>}
          {getBannerDataValue(bannerData, "list_4") && <li><CheckIcon/>{getBannerDataValue(bannerData, "list_4")}</li>}
        </ul>
      </div>
      <div className={styles.paywallFooter}>
        <div className={styles.paywallPrice}>
          {getBannerDataValue(bannerData, "price") &&
          <>
          <label><FormattedMessage id="VOCABULARY.from"/></label>
          <p>{getBannerDataValue(bannerData, "price")} PLN/<FormattedMessage id="mth"/></p>
          </>
          }
        </div>
        <div className={styles.paywallActions}>
          {bannerVariant === "default" ?
            <Button onClick={() => handleRequestContact()}>
              {getBannerDataValue(bannerData, "button")}
            </Button> :
            <PaywallModal
              triggerLabel={getBannerDataValue(bannerData, "button")}
              triggerBannerVariant={bannerVariant}
            />
          }
        </div>
      </div>
      </>
      }
    </div>
  );
};

const mapStateToProps = store => ({
  proAccessRequestSent: store?.user?.data?.proAccessRequestSent,
  userAccountType: store?.user?.data?.proData?.proGroup,
  user: store?.user?.data
});
const mapDispatchToProps = {
  updateUserData: (data) => updateUserData(data),
  callReddClickEvent: (clickType, contentType, appLabel, objectId, params, notification) => callReddClickEvent(clickType, contentType, appLabel, objectId, params, notification)
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaywallBannerDefault);
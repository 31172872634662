import React from "react";
import Slider from "react-slick";
import cn from "classnames";
import styles from "./index.module.scss";

export default class SlickSlider extends React.Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.newSlideRequest < this.props.newSlideRequest) {
      this.refSlider.slickNext();
    }
    if(prevProps.newSlideRequest > this.props.newSlideRequest) {
      this.refSlider.slickPrev();
    }
  }

  render() {
    const {children, autoplay, dots, infinite, speed, slidesToShow, slidesToScroll, className} = this.props;
    const settings = {
      autoplay: autoplay,
      dots: dots,
      arrows: true,
      infinite: infinite,
      speed: speed || 500,
      slidesToShow: slidesToShow || 1,
      slidesToScroll: slidesToScroll || 1
    };
    return (
      <Slider
        ref={(slider) => (this.refSlider = slider)}
        className={cn(styles.slickSliderWrapper, className)}
        {...settings}
      >
        {children}
      </Slider>
    );
  }
}
import _ from "lodash";

const setParams = (param = '', paramName) => {
  const booleanParams = ["onlyPromoted", "isOffice"];
  if(param !== '' && param.length) {
    const paramArray = param.toString().split(',');
    if(paramArray.length) {
      return paramArray.map(item => {
        const itemEncoded = item && (_.includes(booleanParams, item) ? true : encodeURIComponent(item));
        return `&${paramName}=${itemEncoded}`;
      }).join('');
    }
  }
  return "";
};

export const setFilters = (params, orderByInit, pagesDisabled) => {
  const isMapFilters = orderByInit === 'map';
  const page = isMapFilters ? '' : pagesDisabled ? '?' :  (params.page !== null && !isNaN(params.page) ? `?page=${params.page}` : '?page=1');
  const rowsPerPage = params.size && params.size !== 20 ? `&size=${params.size}` : '';
  const order = params.order === '' ? '' : '-';
  const orderBy = params.orderBy || orderByInit;
  const withoutOrder = orderBy === undefined || isMapFilters;
  const ordering = withoutOrder ? '' : `${pagesDisabled ? '' : '&'}ordering=${order}${orderBy}`;

  // BUILDING DETAILS
  const buildingName = setParams(params.buildingName, 'building_name');
  const teamLandlord = setParams(params.teamLandlord, 'team_landlord');
  const addressStreet = setParams(params.addressStreet, 'address_street');
  const buildingClass = setParams(params.buildingClass, 'building_class');
  const buildingStatus = setParams(params.buildingStatus, 'building_status');
  const buildingType = setParams(params.buildingType, 'building_type');
  const commissioningYearFrom = setParams(params.commissioningYearFrom, 'commissioning_year_from');
  const commissioningYearTo = setParams(params.commissioningYearTo, 'commissioning_year_to');
  const rentFrom = setParams(params.rentFrom, 'rent_from');
  const rentTo = setParams(params.rentTo, 'rent_to');
  const certification = setParams(params.certification, 'certification');
  const district = setParams(params.district, 'district');
  const neighborhood = setParams(params.neighborhood, 'neighborhood');
  const market = setParams(params.market, 'market');
  const isEditable = setParams(params.isEditable, 'is_editable');

  // UNIT DETAILS
  const floorFrom = setParams(params.floorFrom, 'floor_from');
  const floorTo = setParams(params.floorTo, 'floor_to');
  const publishDateFrom = setParams(params.publishDateFrom, 'publish_date_from');
  const publishDateTo = setParams(params.publishDateTo, 'publish_date_to');
  const availableFrom = setParams(params.availableFrom, 'available_from');
  const availableTo = setParams(params.availableTo, 'available_to');
  const unitType = setParams(params.unitType, 'unit_type');
  const status = setParams(params.status, 'status');
  const is_sublease = setParams(params.is_sublease, 'is_sublease');
  const onlyPromoted = setParams(params.onlyPromoted, 'only_promoted');

  // COWORK DETAILS
  const hotDesks = setParams(params.hotDesks, 'hot_desks');
  const privateDesks = setParams(params.privateDesks, 'private_desks');
  const amentities = setParams(params.amentities, 'amentities');
  const roomTypeFrom = setParams(params.roomTypeFrom, 'room_type_from');
  const roomTypeTo = setParams(params.roomTypeTo, 'room_type_to');
  const roomPriceFrom = setParams(params.roomPriceFrom, 'room_price_from');
  const roomPriceTo = setParams(params.roomPriceTo, 'room_price_to');

  // RENT TERMS
  const minRentalYearsFrom = setParams(params.minRentalYearsFrom, 'min_rental_years_from');
  const minRentalYearsTo = setParams(params.minRentalYearsTo, 'min_rental_years_to');
  const serviceChargeFrom = setParams(params.serviceChargeFrom, 'service_charge_from');
  const serviceChargeTo = setParams(params.serviceChargeTo, 'service_charge_to');
  const commonAreaFactorFrom = setParams(params.commonAreaFactorFrom, 'common_area_factor_from');
  const commonAreaFactorTo = setParams(params.commonAreaFactorTo, 'common_area_factor_to');
  const parkingGroundRentFrom = setParams(params.parkingGroundRentFrom, 'parking_ground_rent_from');
  const parkingGroundRentTo = setParams(params.parkingGroundRentTo, 'parking_ground_rent_to');
  const parkingUndergroundRentFrom = setParams(params.parkingUndergroundRentFrom, 'parking_underground_rent_from');
  const parkingUndergroundRentTo = setParams(params.parkingUndergroundRentTo, 'parking_underground_rent_to');
  const parkingFactorFrom = params.parkingFactorAreaFrom ? `&parking_factor_number_from=1&parking_factor_area_from=${params.parkingFactorAreaFrom}` : '';
  const parkingFactorTo = params.parkingFactorAreaTo ? `&parking_factor_number_to=1&parking_factor_area_to=${params.parkingFactorAreaTo}` : '';

  // AREA FILTERS
  const totalAvailableAreaFrom = setParams(params.totalAreaAvailableFrom, 'total_area_available_from');
  const totalAvailableAreaTo = setParams(params.totalAreaAvailableTo, 'total_area_available_to');
  const totalAreaBruttoFrom = setParams(params.totalAreaBruttoFrom, 'total_area_brutto_from');
  const totalAreaBruttoTo = setParams(params.totalAreaBruttoTo, 'total_area_brutto_to');
  const areaFrom = setParams(params.areaFrom, 'area_from');
  const areaTo = setParams(params.areaTo, 'area_to');
  const isOffice = setParams(params.isOffice, "is_office");

  // PREMIUM FILTERS
  const vacancyRateFrom = setParams(params.vacancyRateFrom, 'vacancy_rate_from');
  const vacancyRateTo = setParams(params.vacancyRateTo, 'vacancy_rate_to');
  const owners = setParams(params.owners, 'owner');

  // OWNERS FILTERS
  const totalAreaFrom = setParams(params.totalAreaFrom, 'total_area_from');
  const totalAreaTo = setParams(params.totalAreaTo, 'total_area_to');
  const totalVacancyFrom = setParams(params.totalVacancyFrom, 'total_vacancy_from');
  const totalVacancyTo = setParams(params.totalVacancyTo, 'total_vacancy_to');
  const ownerName = setParams(params.ownerName, 'owner_name');

  // ACQUISITIONS FILTERS
  const transactionCompany = setParams(params.company, 'company');
  const transactionPriceFrom = setParams(params.priceFrom, 'price_from');
  const transactionPriceTo = setParams(params.priceTo, 'price_to');
  const transactionDateFrom = setParams(params.transactionDateFrom, 'transaction_date_from');
  const transactionDateTo = setParams(params.transactionDateTo, 'transaction_date_to');

  // SHORTLISTS FILTER
  const shortlistName = setParams(params.name, 'name');
  const shortlistLandlordAssetId = setParams(params.assetId, 'asset_id');
  const shortlistLandlordYear = setParams(params.year, 'year');

  // INDUSTRIAL FILTERS
  const industrialParkName = setParams(params.parkName, 'name');
  const industrialWarehouseParkName = setParams(params.warehouseParkName, 'park');
  const industrialWarehouseAreaAvailableFrom = setParams(params.totalAreaWarehouseAvailableFrom, 'total_area_warehouse_available_from');
  const industrialWarehouseAreaAvailableTo = setParams(params.totalAreaWarehouseAvailableTo, 'total_area_warehouse_available_to');
  const industrialOfficeAreaAvailableFrom = setParams(params.totalAreaOfficeAvailableFrom, 'total_area_office_available_from');
  const industrialOfficeAreaAvailableTo = setParams(params.totalAreaOfficeAvailableTo, 'total_area_office_available_to');
  const industrialMarketID = setParams(params.marketId, 'market_id');
  const industrialDocks = setParams(params.docks, 'docks');
  const industrialAddressCity = setParams(params.addressCity, 'address_city');

  // INDUSTRIAL RENT TERMS
  const industrialRentWarehouseFrom = setParams(params.warehouseRentFrom, 'rent_warehouse_from');
  const industrialRentWarehouseTo = setParams(params.warehouseRentTo, 'rent_warehouse_to');
  const industrialRentOfficeFrom = setParams(params.officeRentFrom, 'rent_office_from');
  const industrialRentOfficeTo = setParams(params.officeRentTo, 'rent_office_to');
  const industrialServiceWarehouseFrom = setParams(params.warehouseServiceChargeFrom, 'service_warehouse_from');
  const industrialServiceWarehouseTo = setParams(params.warehouseServiceChargeTo, 'service_warehouse_to');
  const industrialServiceOfficeFrom = setParams(params.officeServiceChargeFrom, 'service_office_from');
  const industrialServiceOfficeTo = setParams(params.officeServiceChargeTo, 'service_office_to');
  const industrialMinAreaForRentFrom = setParams(params.minAreaForRentFrom, 'min_area_for_rent_from');
  const industrialMinAreaForRentTo = setParams(params.minAreaForRentTo, 'min_area_for_rent_to');

  // INDUSTRIAL TECHNICAL DETAILS
  const industrialStorageHeightFrom = setParams(params.storageHeightFrom, 'storage_height_from');
  const industrialStorageHeightTo = setParams(params.storageHeightTo, 'storage_height_to');
  const industrialFloorLoadingCapacityFrom = setParams(params.floorLoadingCapacityFrom, 'flooring_carrier_from');
  const industrialFloorLoadingCapacityTo = setParams(params.floorLoadingCapacityTo, 'flooring_carrier_to');
  const industrialParkingTruckSlotsFrom = setParams(params.parkingTruckSlotsFrom, 'parking_slots_truck_from');
  const industrialParkingTruckSlotsTo = setParams(params.parkingTruckSlotsTo, 'parking_slots_truck_to');
  const industrialParkingCarSlotsFrom = setParams(params.parkingCarSlotsFrom, 'parking_slots_car_from');
  const industrialParkingCarSlotsTo = setParams(params.parkingCarSlotsTo, 'parking_slots_car_to');
  const industrialLoadingDocksFrom = setParams(params.docksFrom, 'docks_from');
  const industrialLoadingDocksTo = setParams(params.docksTo, 'docks_to');
  const industrialColumnGridFrom = setParams(params.columnGridFrom, 'column_grid_from');
  const industrialColumnGridTo = setParams(params.columnGridTo, 'column_grid_to');
  const industrialAmenities = setParams(params.amenities, 'amenities');

  // FINNE LEADS
  const createdAt = setParams(params?.createdAt, 'created_at');
  const area = setParams(params?.area, 'area');

  // SYMMCO
  const zone = setParams(params?.zone, 'zone');
  const netDeal = setParams(params?.netDeal, 'net_deal_size');
  const dealType = setParams(params?.dealType, 'deal_type');
  const quater = setParams(params?.quater, 'quater');

  return `${page}
  ${rowsPerPage}
  ${ordering}
  ${buildingName}
  ${teamLandlord}
  ${addressStreet}
  ${buildingClass}
  ${buildingStatus}
  ${buildingType}
  ${commissioningYearFrom}
  ${commissioningYearTo}
  ${district}
  ${neighborhood}
  ${market}
  ${isEditable.includes('true') ? isEditable : ''}
  ${rentFrom}
  ${rentTo}
  ${floorFrom}
  ${floorTo}
  ${publishDateFrom}
  ${publishDateTo}
  ${availableFrom}
  ${availableTo}
  ${unitType}
  ${status}
  ${is_sublease}
  ${onlyPromoted}
  ${hotDesks}
  ${privateDesks}
  ${amentities}
  ${roomTypeFrom}
  ${roomTypeTo}
  ${roomPriceFrom}
  ${roomPriceTo}
  ${minRentalYearsFrom}
  ${minRentalYearsTo}
  ${serviceChargeFrom}
  ${serviceChargeTo}
  ${commonAreaFactorFrom}
  ${commonAreaFactorTo}
  ${parkingFactorFrom}
  ${parkingFactorTo}
  ${parkingGroundRentFrom}
  ${parkingGroundRentTo}
  ${parkingUndergroundRentFrom}
  ${parkingUndergroundRentTo}
  ${certification}
  ${totalAvailableAreaFrom}
  ${totalAvailableAreaTo}
  ${totalAreaBruttoFrom}
  ${totalAreaBruttoTo}
  ${areaFrom}
  ${areaTo}
  ${isOffice}
  ${vacancyRateFrom}
  ${vacancyRateTo}
  ${owners}
  ${totalAreaFrom}
  ${totalAreaTo}
  ${totalVacancyFrom}
  ${totalVacancyTo}
  ${ownerName}
  ${transactionCompany}
  ${transactionPriceFrom}
  ${transactionPriceTo}
  ${transactionDateFrom}
  ${transactionDateTo}
  ${shortlistName}
  ${industrialParkName}
  ${industrialWarehouseParkName}
  ${industrialWarehouseAreaAvailableFrom}
  ${industrialWarehouseAreaAvailableTo}
  ${industrialOfficeAreaAvailableFrom}
  ${industrialOfficeAreaAvailableTo}
  ${industrialMarketID}
  ${industrialDocks}
  ${industrialAddressCity}
  ${industrialRentWarehouseFrom}
  ${industrialRentWarehouseTo}
  ${industrialRentOfficeFrom}
  ${industrialRentOfficeTo}
  ${industrialServiceWarehouseFrom}
  ${industrialServiceWarehouseTo}
  ${industrialServiceOfficeFrom}
  ${industrialServiceOfficeTo}
  ${industrialMinAreaForRentFrom}
  ${industrialMinAreaForRentTo}
  ${industrialStorageHeightFrom}
  ${industrialStorageHeightTo}
  ${industrialFloorLoadingCapacityFrom}
  ${industrialFloorLoadingCapacityTo}
  ${industrialParkingTruckSlotsFrom}
  ${industrialParkingTruckSlotsTo}
  ${industrialParkingCarSlotsFrom}
  ${industrialParkingCarSlotsTo}
  ${industrialLoadingDocksFrom}
  ${industrialLoadingDocksTo}
  ${industrialAmenities}
  ${industrialColumnGridFrom}
  ${industrialColumnGridTo}
  ${createdAt}
  ${area}
  ${zone}
  ${netDeal}
  ${dealType}
  ${quater}
  ${shortlistLandlordAssetId}
  ${shortlistLandlordYear}
  `.replace(/\s/g,'');
};

export const resetFilters = (orderByInit, isEditable) => {
  const isMapFilters = orderByInit === 'map';
  const orderingDisabled = orderByInit === undefined;
  const page = `?page=1`;
  const order = "-";
  const ordering = `&ordering=${order}${orderByInit}`;
  const filtering = isEditable ? '&is_editable=true' : '';
  return isMapFilters ? '' : orderingDisabled ? `${page}${filtering}` : `${page}${ordering}${filtering}`;
};
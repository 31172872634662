import React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import connect from "react-redux/es/connect/connect";
import _ from "lodash";
import {updateUnitPlan} from "../../../../../crud/estates/offices/units.crud";
import {InputUpload} from "../../../InputUpload";
import styles from "../../../PartialComponents.module.scss";
import {Grid, Typography} from "@material-ui/core";

class EditUnitPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      planImage: props.planImage,
      file: "",
    };
    this.initialState = this.state;
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    // Handle Change Detect
    this.props.onUnsavedData(!_.isEqual(this.initialState, this.state));
    // Handle Save
    if (!prevProps.requestSave && this.props.requestSave) {
      this.handleSave();
    }
    // Update Plan
    if (prevState.planImage !== this.props.planImage) {
      this.setState({
        planImage: this.props.planImage,
      });
    }
    // Detect Office Updating
    if(prevProps.unitUpdating !== this.props.unitUpdating) {
      this.props.onRequestSaving(this.props.unitUpdating);
    }
  }

  // Handlers
  handleChange = (event) => {
    this.setState({
      planImage: event.target.value,
    });
  };
  handlePlanUpload = (file) => {
    this.setState({
      file,
    });
  };
  handleSave = () => {
    const { unitId, updateUnitPlan } = this.props;
    const { file } = this.state;
    const backgroundRefreshObject = {
      target: "plan"
    };
    this.initialState = this.state;
    updateUnitPlan(unitId, file, backgroundRefreshObject);
  };

  render() {
    const {
      planImage,
      isOfficeEditable
    } = this.props;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" style={{color: "#ff4626"}}><FormattedMessage id="VOCABULARY.Plan"/></Typography>
        </Grid>
        <Grid item xs={12}>
          {isOfficeEditable ? (
            <InputUpload
              currentFile={planImage}
              onFileUpload={this.handlePlanUpload}
            />
          ) : (
            <img src={planImage} alt="" className={styles.planImage} />
          )}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  unitUpdating: state.units.officeUnitsUpdateLoading,
});
const mapDispatchToProps = {
  updateUnitPlan: (unitId, data, backgroundRefreshObject) => updateUnitPlan(unitId, data, backgroundRefreshObject),
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditUnitPlan)
);
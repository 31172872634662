import React from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from "prop-types";
import styles from "./index.module.scss";
import cn from "classnames";
import TooltipCustom from "../TooltipCustom";
import LoadingScreen from "../LoadingScreen";

const Button = (
  {
    onClick,
    redirect,
    redirectTarget,
    children,
    textIcon,
    textIconStyle,
    style,
    size,
    type = "text",
    variant = "filled",
    color = "danger",
    btnType = "button",
    inactive = false,
    disabled = false,
    trackingData = null,
    loading = false,
    // Tooltip
    tooltip = null,
    tooltipPlacement = "top",
    tooltipInactive = false,
    ...props
  }
) => {
  const history = useHistory();
  // Handlers
  const handleClick = (e) => {
    // Handle Tracking Event
    if (trackingData && typeof props.callReddClickEvent === "function") {
      props.callReddClickEvent(
        trackingData?.clickType,
        trackingData?.contentType,
        trackingData?.appLabel,
        trackingData?.objectId,
        trackingData?.params
      )
    }
    // Handle OnClick event
    onClick && onClick(e);
    // Handle redirect
    if(redirect) {
      if(redirectTarget === "_blank") {
        window.open(redirect, '_blank');
      }
      else {
        history.push(redirect);
      }
    }
  }

  return(
    <TooltipCustom
      title={tooltip}
      placement={tooltipPlacement}
      disabled={!tooltip || tooltipInactive}
      underlineDisabled
      childStyle={{
        cursor: "inherit",
      }}
    >
      <button
        type={btnType}
        className={cn(
          styles.button,
          styles?.[type],
          styles?.[variant],
          styles?.[color],
          size ? styles?.[size] : undefined,
          inactive ? styles.inactive : undefined,
          disabled ? styles.disabled : undefined,
          loading ? styles.loading : undefined
        )}
        style={style}
        onClick={inactive ? undefined : handleClick}
      >
        {children}
        {textIcon &&
          <span
            className={styles.textIcon}
            style={textIconStyle}
          >{textIcon}</span>
        }
        {loading &&
          <span className={styles.loaderWrapper}>
            <LoadingScreen spinner />
          </span>
        }
      </button>
    </TooltipCustom>
  );
}

Button.propTypes = {
  // Button
  onClick: PropTypes.func,
  style: PropTypes.object,
  inactive: PropTypes.bool, // Button disabled with styles applied
  disabled: PropTypes.bool, // Button disabled without styles modification
  loading: PropTypes.bool,
  textIcon: PropTypes.object,
  textIconStyle: PropTypes.object,
  trackingData: PropTypes.object,
  type: PropTypes.oneOf([
    "text",
    "icon",
    "plainText"
  ]),
  variant: PropTypes.oneOf([
    "filled",
    "outlined"
  ]),
  color: PropTypes.oneOf([
    "simple",
    "danger",
    "warning",
    "success",
    "neutral",
    "admin"
  ]),
  size: PropTypes.oneOf(["slim"]),
  btnType: PropTypes.oneOf(["button", "submit"]),
  // Redirect
  redirect: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  redirectTarget: PropTypes.oneOf(["_blank"]),
  // Tooltip
  tooltip: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.object
  ]),
  tooltipPlacement: PropTypes.oneOf(["top", "bottom", "right", "left"]),
}

export default Button;
import React from "react";
import {FormattedMessage} from "react-intl";
import ACQUISITIONS_AGREEMENT_TYPES from "../../../constants/ACQUISITIONS_AGREEMENT_TYPES";
import styles from "./index.module.scss";
import Button from "../Button";

export default class TransactionTypePreview extends React.Component {
  renderTransactionType = (type) => {
    let color = "";
    let tooltip = "";

    if (type === ACQUISITIONS_AGREEMENT_TYPES.OTHER) {
      color = "warning";
      tooltip = "ACQUISITIONS.PHRASE.AGREEMENT_TYPE_OTHER";
    } else if (type === ACQUISITIONS_AGREEMENT_TYPES.PRELIMINARY_AGREEMENT) {
      color = "neutral";
      tooltip = "ACQUISITIONS.PHRASE.AGREEMENT_TYPE_PRELIMINARY";
    } else if (type === ACQUISITIONS_AGREEMENT_TYPES.FINAL_AGREEMENT) {
      color = "success";
      tooltip = "ACQUISITIONS.PHRASE.AGREEMENT_TYPE_FINAL";
    }

    if (color !== "" && tooltip !== "") {
      return (
        <Button
          type={"icon"}
          color={color}
          tooltip={<FormattedMessage id={tooltip}/>}
          style={{cursor: "default"}}
        >
          <span className={styles.unitStatusDot}/>
        </Button>
      );
    }
    return null;
  };

  render() {
    const {type} = this.props;
    return this.renderTransactionType(type);
  }
}
import React from "react";
import {FormattedMessage} from "react-intl";
import TableCustom from "app/partials/components/TableCustom";
import NoData from "app/partials/components/NoData/NoData";

export default class OwnerBoard extends React.Component {
  render() {
    const {ownerBoard} = this.props;
    const tHead = [
      {
        "label": <FormattedMessage id="FORM.FIELD.FULLNAME"/>,
        "number": false
      },
      {
        "label": <FormattedMessage id="FORM.FIELD.POSITION"/>,
        "number": false
      }
    ];
    const tBody = ownerBoard.map((member) => {
      const { firstName, lastName, position } = member;
      const memberName = firstName && lastName ? `${firstName} ${lastName}` : firstName === null ? lastName : lastName === null ? firstName : 'N/A';
      return {
        "data": [memberName, position]
      }
    });
    if(ownerBoard.length) {
      return(
        <TableCustom tHead={tHead} tBody={tBody}/>
      );
    }
    return <NoData/>;
  }
}
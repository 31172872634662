import React from "react";
import {withRouter} from "react-router-dom";
import TableProperties from "app/partials/components/TableProperties/TableProperties";
import {TABLE_CELLS} from "../../../partials/components/TableProperties";
import store from "../../../store/store";
import LoadingScreen from "../../../partials/components/LoadingScreen";
import {FormattedMessage} from "react-intl";
import {VIEW_TYPES} from "../../../constants";
import MapView from "./components/MapView";
import {Portlet, PortletBody, PortletHeader, PortletHeaderToolbar} from "../../../partials/content/Portlet";
import {isFiltersActive} from "../../../partials/components/FiltersManageBar/constants";
import styles from "../../offices/BuildingsPage/components/index.module.scss";
import {Grid, Typography} from "@material-ui/core";
import tableStyles from "../../../partials/components/TableProperties/index.module.scss";
import MapLegend from "../../offices/components/MapView/MapLegend";
import IsPro from "../../../utils/IsPro";
import ReactVisibilitySensor from "react-visibility-sensor";

let prevLocation; // Hold previous route 'pathname'
class ParksPage extends React.Component {
  constructor(props) {
    super(props);
    // Set previous route 'pathname'
    props.history.listen(nextLocation => {
      prevLocation = nextLocation.pathname;
    });
    //
    this.state = {
      viewType: props.viewType,
      isReady: false,
      initLoaded: false,
      markerFilters: []
    }
  }
  componentDidMount() {
    const {setLatestActiveTablePage, filterReset} = this.props;
    const updateData = prevLocation === undefined || !prevLocation.includes('/industrial-park/');
    if(updateData) {
      setLatestActiveTablePage(0);
      filterReset(["market", "isEditable"]);
    }
  }
  componentDidUpdate() {
    const {viewType, fetchIndustrialParks, fetchIndustrialParksClusters} = this.props;
    // Check if Offices data should be update based on previous location
    const updateData = prevLocation === undefined || !prevLocation.includes('/industrial-park/');
    // Flag to check if current page view is a Map View
    const isMapView = viewType === VIEW_TYPES.MAP_VIEW;
    // Execute this if current View Type is different then View Type before component Update
    if (viewType !== this.state.viewType) {
      let params = {
        page: 1,
        ...store.getState().filters
      };
      // Change params set if current page view is not a Map View
      if (!isMapView) {
        params = {
          page: this.state.page,
          ...store.getState().filters,
          order: '-',
          orderBy: 'updated_at_by_user'
        };
      }
      // Update View Type
      this.setState({
        viewType
      }, () => {
        if (updateData) {
          if (viewType === VIEW_TYPES.MAP_VIEW) {
            // Update Office Clusters if View Type is Map View
            fetchIndustrialParksClusters({params});
          }
          else {
            // Update Offices List if View Type is not Map View
            fetchIndustrialParks({params});
          }
        }
      });
    }
  }

  // Handlers
  handleOnViewportFetch = (isVisible) => {
    const {
      fetchIndustrialParks,
      fetchIndustrialParksClusters,
      callReddClickEvent,
      filterByModules,
      location,
      history,
      loading
    } = this.props;
    const {initLoaded, viewType} = this.state;
    const isMapView = viewType === VIEW_TYPES.MAP_VIEW;
    const queryParams = new URLSearchParams(location.search)
    const isQueryParamIsEditable = queryParams.has("is_editable");
    // Check if Offices data should be update based on previous location
    const updateData = prevLocation === undefined || !prevLocation.includes('/industrial-park/');

    if(isVisible && !loading && !initLoaded) {
      this.setState({
        initLoaded: true
      }, () => {
        // Params to update data: new page number, all selected filters
        if (updateData) {
          const params = {
            isFilterSet: 0,
            forceUpdateChilds: [],
            ...store.getState().filters,
            orderBy: 'updated_at_by_user',
            ...isQueryParamIsEditable ? {isEditable: "true"} : {}
          };
          if(isQueryParamIsEditable) {
            // update filters
            filterByModules(params);
            // remove is_editable from queryParams
            queryParams.delete('is_editable')
            history.replace({
              search: queryParams.toString(),
            })
          }
          if (isMapView) {
            fetchIndustrialParksClusters({params});
          } else {
            fetchIndustrialParks({params});
          }
        }
      });
    }
    if (isVisible && !this.state.isReady) {
      this.setState({
        isReady: true
      }, () => {
        callReddClickEvent(
          "industrial-park-list",
          "park"
        );
      })
    }
  };
  handleOnViewport = (isInViewport) => {
    this.handleOnViewportFetch(isInViewport);
  }
  // Handle filter markers
  handleFilterMarkers = (filters) => {
    this.setState({
      markerFilters: filters
    });
  }

  // Helpers
  renderMapView = () => {
    const {
      clusters,
      clustersLoaded,
      fetchIndustrialPark,
      fetchIndustrialParkWarehouses,
      park,
      parkLoading,
      parkWarehouses,
      parkWarehousesLoading,
      clustersLoading,
      allUserStates
    } = this.props;
    const {markerFilters} = this.state;
    const {markers} = clusters;
    const totalBuildings = markers.length;

    return (
      <Portlet style={{marginBottom: 0}} className={isFiltersActive() && styles.filterBarEnable}>
        <PortletHeader
          title={
            <Typography variant="h3">
              <FormattedMessage id="INDUSTRIAL.PHRASE.INDUSTRIAL_PARKS"/>
              {totalBuildings && <FormattedMessage id="GENERAL.PHRASE.TOTAL_RESULTS" values={{
                results: <span className={tableStyles.resultsValue}>{totalBuildings}</span>,
                prefix: " - "
              }}/>}
            </Typography>
          }
          toolbar={
            <PortletHeaderToolbar>
              <MapLegend markerFilters={markerFilters} onFilter={this.handleFilterMarkers} />
            </PortletHeaderToolbar>
          }
        />
        <PortletBody fit>
          <ReactVisibilitySensor
            onChange={(isVisible) => this.handleOnViewport(isVisible)}
            scrollCheck
            partialVisibility
          >
            <Grid container spacing={0} direction="column">
              <Grid item xs={12}>
                {clustersLoaded ?
                  <MapView
                    getProperty={fetchIndustrialPark}
                    getPropertyChilds={fetchIndustrialParkWarehouses}
                    markers={markers}
                    property={park}
                    propertyLoading={parkLoading}
                    propertyChilds={parkWarehouses}
                    propertyChildsLoading={parkWarehousesLoading}
                    clustersLoading={clustersLoading}
                    allUserStates={allUserStates}
                    markerFilters={markerFilters}
                  /> : <LoadingScreen/>}

              </Grid>
            </Grid>
          </ReactVisibilitySensor>
        </PortletBody>
      </Portlet>
    );
  };

  render() {
    const {
      items,
      itemsPerPage,
      itemsCount,
      total,
      loading,
      fetchIndustrialParks,
      clustersLoaded
    } = this.props;
    const {viewType} = this.state;
    const isMapView = viewType === VIEW_TYPES.MAP_VIEW;

    return (
      isMapView ? (clustersLoaded ? this.renderMapView() : <LoadingScreen/>) :
        <TableProperties
          tableLabel={<FormattedMessage id="VOCABULARY.Properties"/>}
          headRows={[
            ...IsPro({}) ? [{
              id: 'checkable',
              numeric: false,
              disablePadding: false,
              label: 'INDUSTRIAL.TABLE.PARK_NAME',
              sort: false
            }] : [],
            {
              id: 'park_name',
              numeric: false,
              disablePadding: false,
              label: 'INDUSTRIAL.TABLE.PARK_NAME',
              sort: false
            },
            {
              id: 'park_rent_details',
              numeric: false,
              disablePadding: false,
              label: 'VOCABULARY.Lease terms',
              sort: false
            },
            {
              id: 'total_area_available',
              numeric: true,
              disablePadding: false,
              label: 'VOCABULARY.Available space',
              sort: false
            },
            {
              id: 'updated_at_by_user',
              numeric: false,
              disablePadding: false,
              label: 'BUILDING.PHRASE.UPDATED_AT',
              sort: true
            },
            {
              id: 'actions',
              numeric: true,
              disablePadding: false,
              label: '',
              sort: false
            }
          ]}
          bodyRows={items}
          dataSource="industrial"
          dataTarget={TABLE_CELLS.INDUSTRIAL_PARKS}
          itemsPerPage={itemsPerPage}
          itemsCount={itemsCount}
          totalItems={total}
          loading={loading}
          filterBarEnable
          handleFetchData={fetchIndustrialParks}
          onEnterViewport={this.handleOnViewport}
        />
    );
  }
}

export default withRouter(ParksPage);